App.controller('flightDetailLinkFlightController', ['$scope', '$uibModalInstance', 'plannedFlight', 'activeFlight', 'aircraftRestService', 'flightNgStore', 'customerNgStore', function ($scope, $uibModalInstance, plannedFlight, activeFlight, aircraftRestService, flightNgStore, customerNgStore) {
        'use strict';
  
    $scope.plannedFlight = plannedFlight;
    $scope.activeFlight = activeFlight;

    $scope.apply = function() {

        flightNgStore.getAircraftPlannedFlightPlans($scope.plannedFlight)
            .then(flightPlans => {
              
                let flightPlanIds = [];

                _.forEach(flightPlans, function (flightPlan) { flightPlanIds.push(flightPlan.flightPlanXmlId) });

                aircraftRestService.associatePlannedFlightPlansWithFlight(customerNgStore.customer.id, $scope.activeFlight.id, flightPlanIds).then((result) => {
                    $scope.$close(result);
            });           
        });

    };

    $scope.cancel = function() {
        $uibModalInstance.close();
    };
  
  }]);
  