/*jslint browser: true, vars: true, nomen: true */
/*global App, d3, angular, _, moment, console */

App.controller('ReplayControlCtrl', ['$element', '$scope', 'flightReplayStore', function ($element, $scope, flightReplayStore) {
  'use strict';

  var margin = {
      top: 20,
      right: 10,
      bottom: 10,
      left: 10
    },
    x,
    y,
    xAxis,
    yAxis,
    area,
    line;

  function click() {
    //console.log(x.invert(d3.event.offsetX - margin.right - margin.left));
    //console.log(x.invert(d3.event.offsetX - margin.left));
    //console.log(x.invert(d3.event.offsetX - margin.right));
    //console.log(x.invert(d3.mouse(d3.select($element[0]).select('svg'))[0]));
    //console.log(x.invert(d3.event.clientX - 200));
    var graph = d3.select($element[0]).select('svg').select('g')[0][0];
    $scope.progressBar.selectedTime = x.invert(d3.mouse(graph)[0]);
    $scope.$evalAsync();
  }

  function drawAltitudeGraph(positionsHistory) {
    var outerWidth = $element[0].offsetWidth,
      outerHeight = $element[0].offsetHeight,
      width = outerWidth - margin.left - margin.right,
      height = outerHeight - margin.top - margin.bottom;
    var flFormat = d3.format('-03d');

    x = d3.time.scale.utc()
      .range([0, width]);

    y = d3.scale.linear()
      .nice(100)
      .range([height, 0]);

    xAxis = d3.svg.axis()
      .scale(x)
      .ticks(5)
      .tickSize(height)
      .tickFormat(d3.time.format.utc('%H:%M'))
      .orient('top');

    yAxis = d3.svg.axis()
      .scale(y)
      .ticks(0)
      .tickSize(width)
      .tickFormat(function (d) {
        return 'FL' + flFormat(d);
      })
      .orient('right');

    area = d3.svg.area()
      .x(function (d) {
        return x(d.timestamp);
      })
      .y0(height)
      .y1(function (d) {
        return y(Math.round(d.value[2] / 100));
      });

    line = d3.svg.line()
      .x(function (d) {
        return x(d.timestamp);
      })
      .y(function (d) {
        return y(Math.round(d.value[2] / 100));
      });

    var viewBox = '0 0 ' + outerWidth + ' ' + outerHeight;



    if (d3.select($element[0]).select('svg')) {
      d3.select($element[0]).select('svg').remove();
    }

    var svg = d3.select($element[0]).append('svg')
      .attr('viewBox', viewBox).on('click', click);
    var defs = svg.append('defs');

    /*
      gradient
    */
    var linearGradient = defs.append('linearGradient')
      .attr('id', 'fillGradient')
      .attr('gradientUnits', 'userSpaceOnUse')
      .attr('x1', '0')
      .attr('y1', '0')
      .attr('x2', '0')
      .attr('y2', height);
    linearGradient.append('stop')
      .attr('offset', '0')
      .attr('style', 'stop-color: rgb(0, 133, 173);');
    linearGradient.append('stop')
      .attr('offset', '0.95')
      .attr('style', 'stop-color: rgb(255, 255, 255);');


    /*
    drawing area
  */
    var graph = svg.append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
    /*
        X axis
      */
    graph.append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis);

    /*
      Y axis
    */
    graph.append('g')
      .attr('class', 'y axis')
      .call(yAxis);


    /*
      Path
    */
    graph.append('path')
      .attr('class', 'area')
      .attr('style', 'fill: url(#fillGradient); opacity: 0.8;');

    graph.append('path')
      .attr('class', 'line');

    graph.append('g')
      .attr('class', 'base')
      .attr('transform', 'translate(0, ' + (height + 5) + ')')
      .append('line')
      .attr('x2', width)
      .attr('y2', 0);

    var data = positionsHistory,
      dateRange;

    if (positionsHistory.length === 0) {
      graph.remove();
      d3.select($element[0]).select('svg').append('foreignObject')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', '400')
        .append("xhtml:body")
        .html('<span>No position data to display</span>');
      return;
    }

    // var graph = svg.select('g')
    dateRange = d3.extent(data.concat($scope.logs), function (d) {
      return d.timestamp || d.eventDate;
    });
    
    x.domain(dateRange);
    y.domain([0, d3.max(data, function (d) {
      return Math.round(d.value[2] / 100);
    })]);

    graph.select(".x.axis")
      .call(xAxis);
    graph.select(".y.axis")
      .call(yAxis);

    graph.select(".line")
      .attr('d', line(data));

    graph.select(".area")
      .attr('d', area(data));
    var alerts = {};
    var color;
    var level = 0;
    $scope.logs.forEach(function (log, id) {
      if (log.subType.indexOf('WARN_CREATED') === 0) {
        if (!alerts[log.sourceId]) {
          alerts[log.sourceId] = {
            type: 'warn',
            start: log.eventDate,
            level: level
          };
          level += 1;
        }
      } else if (log.subType.indexOf('WARN_CLOSED') === 0) {
        if (alerts[log.sourceId]) {
          alerts[log.sourceId].end = log.eventDate;
          level -= 1;
        }
      }
      if (log.subType.indexOf('CAUT_CREATED') === 0) {
        if (!alerts[log.sourceId]) {
          alerts[log.sourceId] = {
            type: 'caut',
            start: log.eventDate,
            level: level
          };
          level += 1;
        }
      } else if (log.subType.indexOf('CAUT_CLOSED') === 0) {
        if (alerts[log.sourceId]) {
          alerts[log.sourceId].end = log.eventDate;
          level -= 1;
        }

      }


    });
    _.forEach(alerts, function (alert) {
      var color = (alert.type === 'warn') ? 'red' : 'orange';
      var end = alert.end ? x(alert.end) : width;
      if (end > width) {
        end = width;
      }
      graph //.append('g')
        //.call(zoom)
        //.attr('class', 'base')
        .append('line')
        .attr('class', 'log-event')
        .attr('y1', height + 2 - (alert.level * 4))
        .attr('y2', height + 2 - (alert.level * 4))
        .attr('stroke', color)
        .attr('stroke-width', 4)
        .attr('x1', x(alert.start)).attr('x2', end);

    });
    graph //.append('g')
      //.call(zoom)
      //.attr('class', 'base')
      .append('line')
      .attr('class', 'time-cursor')
      .attr('x1', 0)
      // .attr('y1', height+10)
      .attr('x2', 0)
      .attr('y1', -10)
      .attr('y2', height + 10)
      .attr('stroke', 'green')
      .attr('stroke-width', 2);
    /*    .on('mouseover', function () {
          d3.select(this)
            .transition()
            .duration(500)
            .attr('stroke-width', 5);
        }).on('mouseout', function () {
          d3.select(this)
            .transition()
            .duration(500)
            .attr('stroke-width', 2);
        })*/
    //;

  }


  var positionsHistory = [];
  var timestampUnwatch;
  var initialPositions;
  drawAltitudeGraph([]);

  $scope.$watch('progressBar.displayedPosition', function (position) {
    if (position && position.timestamp) {
      var graph = d3.select($element[0]).select('svg').select('g');
      graph.select('.time-cursor')
        .transition()
        .duration(50)
        .attr('x2', x(position.timestamp)).attr('x1', x(position.timestamp));
    } else if (position && position.eventDate) {
      var graph = d3.select($element[0]).select('svg').select('g');
      graph.select('.time-cursor')
        .transition()
        .duration(50)
        .attr('x2', x(position.eventDate)).attr('x1', x(position.eventDate));
    }
  });

  $scope.$watch('flightInfo', function (newFlight) {
    if (newFlight) {
      initialPositions = newFlight.positions;
      if (_.isArray(initialPositions)) {
        positionsHistory = initialPositions;
      }
      drawAltitudeGraph(positionsHistory);
      $scope.progressBar.loading = false;
    }
  });

  function resize() {
    drawAltitudeGraph($scope.flightInfo.positions);
  }
  d3.select(window).on('resize', resize);
  //drawAltitudeGraph();
}]);
