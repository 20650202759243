/*jslint browser: true, nomen: true */
/*global App, Plumbing, _ , Promise */

App.provider('flightReplayStore', ['customerId', function FlightReplayStoreProvider(customerId) {
  'use strict';

  var opts, getPath, store;

  opts = {
    path: {
      customer: '/customer/',
      flightReplay: '/flight-replay',
      getEport: '/get-export/',
      get: '/get/',
      saveArchive: '/archive/',
      deleteArchive: '/'
    }
  };

  getPath = function () {
    if (_.isFinite(customerId.get())) {
      return window.backendPrefix + opts.path.customer + customerId.get() + opts.path.flightReplay;
    }
  };

  store = new Plumbing({
    headers: opts.headers,
    url: getPath,
    delay: 30000
  });

  customerId.on('update', function (oldId, newId) {
    if (oldId && oldId !== newId) {
      if (_.isUndefined(newId)) {
        store.adapter.stop();
      } else {
        store.refresh();
      }
    }
  }, this);


  store.getDownloadFlightUrl = function () {
    return getPath() + opts.path.getEport;
  };

  store.getCompleteFlightReplay = function (flightId) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + opts.path.get + flightId, {
        credentials: 'include'
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, function () {
            resolve([]);
          });
        }
      }, reject);
    }.bind(this));
  };

  this.$get = [function flightReplayStoreFactory() {
    return store;
  }];

}]);
