import Dms from '../dms'

(function (global) {
  'use strict';

  var categoryLabels = {
    'C': 'Civil',
    'M': 'Military',
    'P': 'Private - Open to public'
  }
  var notDefined = 'Not Defined';
  var M_TO_FEET = 3.28084;
  var coordinateFormatOptions = {
    format:'dm',
    separator: ' ',
    degreeChar: '',
    minuteChar: '',
    secondChar: '',
    suffixed: false,
    prefixed: true
  }

  function formatNavaidFreq(props) {
    var result = '';
    if (props.freq && props.freq !== '{NULL}'){
      // suppression des {}
      var freqs = props.freq.substring(1, props.freq.length -1);
      var tabFreq = freqs.split(",");
      result += parseFloat(tabFreq[0]).toFixed(2);
      for (var i = 1; i< tabFreq.length; i++){
        result += ' / ' + parseFloat(tabFreq[i]).toFixed(2);
      }
    }
    if (props.primnavaid){
      result += ' ' + props.primnavaid;
    }
    if (!props.freq && !props.primnavaid){
      result = 'Not Defined';
    }
    //return (props.freq || '')  + ' ' + (props.primnavaid || '') : null;
    return result;
  }

  global.AirportFeatureFormatter = {
    format: function (adFeature, projection) {

      var props = adFeature.properties || {};
      var properties = [{
          label: 'Feature',
          value: '<b>AERODROME</b>'
        },
        {
          label: 'ICAO',
          value: props.icao_name || notDefined
        },
        {
          label: 'IATA',
          value: props.iata_name || notDefined
        },
        {
          label: 'Name',
          value: _.startCase(_.lowerCase(props.descr_name)) || notDefined
        },
        {
          label: 'Type',
          value: categoryLabels[props.category] || notDefined
        },
        {
          label: 'Long RWY',
          value: props.longestrwy ? (Math.round(props.longestrwy * M_TO_FEET) + 'ft / ' + Math.round(props.longestrwy) + 'm') : notDefined
        },
        {
          label: 'MagVar',
          value: Utils.explicitSignedString(props.mag_var, 'deg') || notDefined
        },
        {
          label: 'Prim Navaid',
          value: formatNavaidFreq(props) || notDefined
        },
        {
          label: 'Lat',
          value: Dms.toLat(props.lat, coordinateFormatOptions) || notDefined
        },
        {
          label: 'Long',
          value:  Dms.toLon(props.long, coordinateFormatOptions) || notDefined
        },
      ];

      return {
        type: 'AD',
        title: (props.icao_name || '–') + ' / ' + (props.iata_name || '–'),
        subtitle: _.startCase(_.lowerCase(props.descr_name)),
        properties: properties,
      }

    }
  }
}(window));
