/*jslint browser: true, plusplus:true, nomen:true*/
/*global _, angular, ol, App, ErrorCode */


App.controller('ReplaySearchCtrl', [
  '$rootScope',
  '$scope',
  '$state',
  '$transitions',
  'flightReplayNgStore',
  'alertService',
  'dashFilter',
  'timeFilter',
  function ReplaySearchCtrl($rootScope, $scope, $state, $transitions, flightReplayNgStore, alertService, dashFilter, timeFilter) {
    'use strict';
    // Aicrafts list
    this.exportUrl = flightReplayNgStore.getDownloadFlightUrl();
    this.filter = {
      startDate: moment().subtract(1, 'month'),
      endDate: moment(),
      minDate: moment().subtract(6, 'month'),
      maxDate:  moment()
    }
   
    this.flightReplays =  flightReplayNgStore.searchEntities;
    

    this.formatFromTo = function (airportIcao, time) {
      return '<div class="from-to-cell"><span class="airport">' + dashFilter(airportIcao) + '</span><span class="time">' + timeFilter(time, 'HH:mm') + '</span></div>';
    };

    this.filterReplays = function () {

      var filters = {};
      filters.minDate = this.filter.startDate;
      filters.maxDate = this.filter.endDate;

      flightReplayNgStore.search(filters, 'fcdate');
    };

    $transitions.onSuccess({
      to: 'replay.**'
    }, function (trans) {
      this.filterReplays();
    }.bind(this));

    // on select
    this.selectFlight = function (flight) {
      $state.go('replay.map', {
        id: flight.id
      });
    };

    this.filterReplays();
  }
]);
