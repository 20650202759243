/*jslint browser: true, plusplus:true*/
/*global angular, ol, App */

(function () {
  'use strict';

  function ReplayPageCtrl($rootScope, $scope, $state, $transitions) {
    if (!$rootScope.isLogged()) {
      var currentState = $state.current.name,
        currentParam = $state.params;
      $state.go('login', {
        returnTo: currentState,
        returnToParams: currentParam
      });
      return;
    }
  }

  App
    .controller('ReplayPageCtrl', ['$rootScope', '$scope', '$state', '$transitions', ReplayPageCtrl]);
}());
