/*jslint browser: true, nomen:true */
/*global _, App, Promise, Plumbing, angular, moment */

App.provider('intersectionStore', ['customerId', function intersectionStoreProvider(customerId) {
  'use strict';

  var opts, getPlumbingPath, getAirspaceIntersectionsPath, store;

  opts = {
    path: {
      intersection: '/intersection',
      customer: '/customer/'
    }
  };

  getPlumbingPath = function () {
    if (_.isFinite(customerId.get())) {
      return window.backendPrefix + opts.path.customer + customerId.get() + opts.path.intersection;
    }
  };
  
  getAirspaceIntersectionsPath = function () {
    return window.backendPrefix + opts.path.intersection;
  };

  store = new Plumbing({
    headers: opts.headers,
    url : getPlumbingPath,
    delay: 30000
  });

  store.fetchIntersectionsByAirspace = function (airspaceId) {
    return new Promise(function (resolve, reject) {
      window.fetch(getAirspaceIntersectionsPath() + '/' + airspaceId, {
        credentials: 'include'
      }).then(function (response) {
        response.json().then(function (json) {
          resolve(json);
        }, function () {
          resolve([]);
        });
      });
    }.bind(this));
  };

  this.$get = [function intersectionStoreFactory() {
    return store;
  }];

}]);
