/*jslint browser: true, nomen: true */
/*global angular, App, _ */

(function (global) {
  'use strict';

  function formatFL(flightLevel) {
    flightLevel = _.isString(flightLevel) ? parseInt(flightLevel, 10) : flightLevel;
    if (_.isFinite(flightLevel)) {
      if (flightLevel < 0) {
        if (flightLevel > -10) {
          return '-FL00' + (-flightLevel);
        } else if (flightLevel > -100) {
          return '-FL0' + (-flightLevel);
        } else {
          return '-FL' + (-flightLevel);
        }
      } else {
        if (flightLevel < 10) {
          return 'FL00' + flightLevel;
        } else if (flightLevel < 100) {
          return 'FL0' + flightLevel;
        } else {
          return 'FL' + flightLevel;
        }
      }
    } else {
      return 'FLUNKN';
    }
  }

  function formatAlt(alt) {
    return formatFL(Math.round(alt / 100.0));
  }

  function format(state, alt) {
    if (state !== 'IN_FLIGHT') {
      return 'GROUND';
    } else if (angular.isNumber(alt)) {
      return formatAlt(alt);
    }
  }

  App.filter('flightLevel', function () {
    return formatAlt;
  });



  global.FlightLevelFormatter = {
    formatFL: formatFL,
    formatAlt: formatAlt,
    format: format
  };

}(window));
