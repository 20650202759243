/*jslint browser: true, plusplus:true*/
/*global angular, ol, App */

// Fake data
var alerts = [];

(function (global) {
  'use strict';

  function AdminAlertCtrl($rootScope, $scope, $state, $uibModal, alertNgStore) {

    // Alerts list
    alertNgStore.refresh();
    $scope.alerts = alertNgStore.alerts;
    $scope.findAlert = function (id) {
      return alertNgStore.findAlertById(id);
    };

    // Edit alert dialog
    $scope.editAlert = function (alert) {

      $scope.editedAlert = {
        id: alert.id,
        level: alert.level,
        customerId: alert.customerId,
        reasonMessage: alert.reasonMessage,
        criteria: alert.criteria,
        parameter: alert.parameter,
        unit: alert.unit,
        isMailActive: alert.isMailActive,
        emails: alert.emails,
        isSmsActive: alert.isSmsActive,
        phones: alert.phones,
        isActive: alert.isActive,
        parametrable: alert.parametrable
      };

      $uibModal.open({
        size:"modal-large",
        templateUrl: 'views/partials/admin/admin-alert-form.html',
        resolve: {
          alert: function () {
            return $scope.editedAlert;
          },
          alerts: function () {
            return $scope.alerts;
          }
        },
        controller: 'AdminAlertFormCtrl'
      }).result['finally'](function () {
        $state.go('admin.alert');
      });
    };

    // Active alert dialog
    $scope.activeAlert = function (alert) {

      $scope.editedAlert = {
        id: alert.id,
        level: alert.level,
        customerId: alert.customerId,
        reasonMessage: alert.reasonMessage,
        criteria: alert.criteria,
        parameter: alert.parameter,
        unit: alert.unit,
        isMailActive: alert.isMailActive,
        emails: alert.emails,
        isSmsActive: alert.isSmsActive,
        phones: alert.phones,
        isActive: alert.isActive,
        parametrable: alert.parametrable
      };

      alertNgStore.set($scope.editedAlert);
    };
  }

  App.controller('AdminAlertCtrl', ['$rootScope', '$scope', '$state', '$uibModal', 'alertNgStore', AdminAlertCtrl]);
}(window));
