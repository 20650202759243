App.factory('airportCapacityService',['airlineRestService',function (airlineRestService) {
'use strict';
    function populate(airlineDataStore) {

        airlineRestService.getAirlines().then((results) => {
            var newAirlineData = {};
            if (!results) {
              return;
            }
            results.forEach(airline => {
                newAirlineData[airline.icao] = {
                    "iata": airline.iata,
                    "icao": airline.icao,
                    "name": airline.name,
                    "callsign": airline.callSign
                };
            });
            Object.getOwnPropertyNames(airlineDataStore.airlines).forEach(function (prop) {
                delete airlineDataStore.airlines[prop];
            });
            Object.assign(airlineDataStore.airlines, newAirlineData);
        });
    }
    return{
            'populateAirlines':function(){
                var airlineDataStore = {airlines: {}};
                populate(airlineDataStore);
                return airlineDataStore;
            }
    };
}]);