/*jslint browser: true, nomen: true */
/*global App, Plumbing, Promise, _ */

App.provider('customerSuperStore', [function customerSuperStoreProvider(customerId) {
  'use strict';

  var opts, getPath, store;

  opts = {
    path: {
      customer: '/customer',
      customerOptions: '/customer/options',
      customerWeatherPackage: '/customer/weatherpackage',
      aircraft: '/aircraft',
      aircraftbulk: '/aircraft-bulk',
      user: '/user'
    }
  };

  getPath = function () {
    return window.backendPrefix + opts.path.customer;
  };

  store = new Plumbing({
    headers: opts.headers,
    url: getPath,
    delay: 5000
  });

 store.fetchCustomerWeatherPackage = function () {
	    return new Promise(function (resolve, reject) {
	      window.fetch(window.backendPrefix + opts.path.customerWeatherPackage, {
	        credentials: 'include'
	      }).then(function (response) {
	        response.json().then(function (json) {
	          resolve(json);
	        }, function () {
	          resolve([]);
	        });
	      }, reject);
	    }.bind(this));
	  };

  store.fetchAircrafts = function (customerId) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + customerId + opts.path.aircraft, {
        credentials: 'include',
        headers: {
          'Pragma': 'no-cache',
          'Cache-Control': 'no-cache'
        }
      }).then(function (response) {
        response.json().then(function (json) {
          resolve(json);
        }, function () {
          resolve([]);
        });
      }, reject);
    }.bind(this));
  };


  store.bulkAddAircrafts = function (customerId, formData) {
    return window.fetch(getPath() + '/' + customerId + opts.path.aircraftbulk, {
        credentials: 'include',
        method: 'post',
        body: formData,
        headers: {
          'Pragma': 'no-cache',
          'Cache-Control': 'no-cache'
        }
      })
      .then(function (response) {
        if (response.ok) {
          return response.json()
            .catch(function () {
              return Promise.resolve([]);
            });
        } else {
          return Promise.reject(response);
        }
      });
  };

  store.addAircraft = function (customerId, aircraft) {
    aircraft.dateAdded = new Date()
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + customerId + opts.path.aircraft, {
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(aircraft)
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, function () {
            resolve([]);
          });
        } else {
          response.json().then(function (json) {
            reject(json);
          }, function () {
            reject([]);
          });
        }
      }, reject);
    }.bind(this));
  };
  store.updateAircraft = function (customerId, aircraft) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + customerId + opts.path.aircraft + '/' + aircraft.id, {
        method: 'put',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(aircraft)
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, function () {
            resolve([]);
          });
        } else {
          response.json().then(function (json) {
            reject(json);
          }, function () {
            reject([]);
          });
        }
      }, reject);
    }.bind(this));
  };

  store.deleteAircraft = function (customerId, aircraft) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + customerId + opts.path.aircraft + '/' + aircraft.id, {
        method: 'delete',
        credentials: 'include'
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, function () {
            resolve([]);
          });
        } else {
          response.json().then(function (json) {
            reject(json);
          }, function () {
            reject([]);
          });
        }
      }, reject);
    }.bind(this));
  };

  store.fetchUsers = function (customerId) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + customerId + opts.path.user, {
        credentials: 'include',
		headers: {
          'Cache-Control': 'no-cache'
        }
      }).then(function (response) {
        response.json().then(function (users) {
          _.forEach(users, function (user) {
            if (!!user.configJson) {
              user.config = JSON.parse(user.configJson);
            }
          });
          resolve(users);
        }, function () {
          resolve([]);
        });
      }, reject);
    }.bind(this));
  };

  store.updateUser = function (customerId, user) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + customerId + opts.path.user + '/' + user.id, {
        method: 'put',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, function () {
            resolve([]);
          });
        } else {
          response.json().then(function (json) {
            reject(json);
          }, function () {
            reject([]);
          });
        }
      }, reject);
    }.bind(this));
  };

  store.addUser = function (customerId, user) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + customerId + opts.path.user, {
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, function () {
            resolve([]);
          });
        } else {
          response.json().then(function (json) {
            reject(json);
          }, function () {
            reject([]);
          });
        }
      }, reject);
    }.bind(this));
  };

  store.deleteUser = function (customerId, user) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + customerId + opts.path.user + '/' + user.id, {
        method: 'delete',
        credentials: 'include'
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, function () {
            resolve([]);
          });
        } else {
          response.json().then(function (json) {
            reject(json);
          }, function () {
            reject([]);
          });
        }
      }, reject);
    }.bind(this));
  };
  this.$get = [function userStoreFactory() {
    return store;
  }];

}]);
