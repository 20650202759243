/*jslint browser: true, vars: true, nomen: true */
/*global App, d3, angular, _ */

App.controller('FlightManualAlertCtrl', ['$element', '$scope', function ($element, $scope) {
  'use strict';

  $scope.level = 1;
  $scope.possibleAlertLvl = [{
    name: 'warning',
    label: 'Warning',
    value: 2
  }, {
    name: 'caution',
    label: 'Caution',
    value: 1
  }];

}]);
