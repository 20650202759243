(function (global) {
  'use strict';

  var notDefined = 'Not Defined';

  var categoryLevel = {
    'OH': 'High',
    'BH': 'High & Low',
    'BL': 'High & Low',
    'OL': 'Low',
  };

  function formatSubtitle(props) {
    var res = '';
    if (props.class1 === 'V') {
      res = 'VOR';
    } else if (props.class1 === 'H') {
      res = 'NDB';
    }

    res += "/";

    if (props.class2 === 'T' || props.class2 === 'M') {
      res += 'TACAN';
    } else if (props.class2 === 'D') {
      res += 'DME';
    } else {
      res += 'unknown';
    }
    return res;
  }

  function formatClass(props) {

    var res;
    if (props.class1 === 'V') {
      res = 'VOR';
    } else if (props.class1 === 'H') {
      res = 'NDB';
    } else {
      res = notDefined;
    }

    if (props.class2 === 'T' || props.class2 === 'M') {
      res += '/TACAN';
    } else if (props.class2 === 'D') {
      res += '/DME';
    }

    if (props.class4 === 'A') {
      res += '/Automatic WC Brdcst';
    } else if (props.class1 === 'B') {
      res += '/Scheduled WX Brdcst';
    }

    return res;
  }

  function round(value, decimals) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
  }

  function formatFrequency(props) {
    console.log(props.class1)
    var res;
    if (props.class1 === 'V') {
      return round(props.freq, 2);
    } else if (props.class1 === 'H') {
      return round(props.freq, 0);
    } else {
      return notDefined;
    }
  }

  global.NavaidFeatureFormatter = {
    format: function (adFeature, projection) {
      var props = adFeature.properties || {};

      var properties = [{
          label: 'Feature',
          value: '<b>NAVAID</b>'
        },
        {
          label: 'Ident',
          value: props.ident || notDefined
        },
        {
          label: 'Name',
          value: _.startCase(_.lowerCase(props.descr_name)) || notDefined
        },
        {
          label: 'Class',
          value: formatClass(props)
        },
        {
          label: 'Frequency',
          value: formatFrequency(props)
        },
        {
          label: 'Level',
          value: categoryLevel[props.level] || notDefined
        },
        {
          label: 'Country',
          value: props.country || notDefined
        },
        {
          label: 'LAT',
          value: props.latitude || notDefined
        },
        {
          label: 'LONG',
          value: props.longitude || notDefined
        },
        {
          label: 'Remark',
          value: props.lim ? '<i>' + props.lim + '</i>' : ''
        },
      ];

      return {
        type: 'NAV',
        title: props.ident,
        subtitle: formatSubtitle(props),
        properties: properties,
      };

    }
  };
}(window));
