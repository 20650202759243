/*jslint browser: true, nomen: true */
/*global App, Promise, _ */

App.controller('SettingsTemplateAllocationCtrl', ['$scope', 'aircraftInfoNgStore', 'customerNgStore', 'userNgStore', 'flightfieldDefinitions', 'userProfileNgStore', function ($scope,
  aircraftInfoNgStore,
  customerNgStore,
  userNgStore, 
  flightfieldDefinitions, 
  userProfileNgStore) {
  'use strict';

  var ctrl = this;
  this.isLoading = true;
  this.availableTemplates = [];
  this.globalTemplates = [];
  this.defaultTemplate = undefined;
  this.selectedTemplates = {}; //map <registration, tpl>
  this.customerAssociations = {} //map <registration, tpl>
  this.defaultCustomerTemplate = undefined;
  this.infoMsg = "";

  this.loadUserTemplates = function () {
    return userNgStore.getUserTemplates().then(function (templates) {
      templates.forEach(function (t) {
        t.templateType = "Your custom templates";
        t.fields = _.map(t.fields, function (key) {
          return flightfieldDefinitions[key];
        });
      })
      templates.push({
        id: "",
        templateName: "-- Use default template --"
      });
      return templates;
    });
  };

  this.getUserTemplateAssociation = function () {
    return userNgStore.getUserTemplateAssociation();
  }

  this.getDefaultTemplate = function () {
    return userNgStore.getDefaultTemplate();
  };

  this.getCustomerDefaultTemplate = function () {
     var template = customerNgStore.getDefaultTemplate().then(function (template){
          template.fields = _.map(template.fields, function (key) {
                return flightfieldDefinitions[key];
              });
             return template;
     });
    return template;
  };

  this.loadCustomerTemplates = function () {
    return customerNgStore.getTemplates().then(function (templates) {
      templates.forEach(function (t) {
        t.templateType = "Airline templates";
        t.fields = _.map(t.fields, function (key) {
          return flightfieldDefinitions[key];
        });
      })
      return templates;
    });
  };

  this.getCustomerTemplateAssociations = function () {
    return customerNgStore.getTemplateAssociation().then(function (templatesAssociation){
        _.forEach(templatesAssociation, function(value,key){
            value.fields = _.map(value.fields, function (key) {
                  return flightfieldDefinitions[key];
                });
            
          });
          return templatesAssociation;
        });
    };

  this.init = function () {
    this.isLoading = true;
    this.availableTemplates = [];
    Promise.all([
        this.loadCustomerTemplates(),
        this.loadUserTemplates()
      ])
      .then(function (templates) {
        this.availableTemplates = _.flatten(templates);
        return this.availableTemplates;
      }.bind(this))
      .then(function () {
        //load aircraft and assign default values
        this.aircrafts = aircraftInfoNgStore.aircrafts;
        this.aircrafts.forEach(function (aircraft) {
          this.selectedTemplates[aircraft.registration] = this.selectedTemplates[aircraft.registration] || {};
          this.customerAssociations[aircraft.registration] = this.selectedTemplates[aircraft.registration] || {};
        }, this);
      }.bind(this))
      .then(this.getDefaultTemplate)
      .then(function (userDefaultSelected) {
        this.defaultTemplate = userDefaultSelected;
        if (this.defaultTemplate.id == undefined) {
          this.defaultTemplate.id = "";
        }
      }.bind(this))
      .then(this.getCustomerDefaultTemplate)
      .then(function (customerDefaultSelected) {
        this.defaultCustomerTemplate = customerDefaultSelected;
        if (this.defaultCustomerTemplate.id == undefined) {
          this.defaultCustomerTemplate.id = "";
        }
      }.bind(this))
      .then(this.getCustomerTemplateAssociations)
      .then(function (customerAssociations) {
        this.customerAssociations = customerAssociations;
         _.assign(this.customerAssociations, customerAssociations);
        this.isLoading = false;
      }.bind(this))
      .then(this.getUserTemplateAssociation)
      .then(function (userSelected) {
        _.assign(this.selectedTemplates, userSelected);
        this.isLoading = false;
        $scope.$evalAsync();
      }.bind(this))
      .catch(function (error) {
        console.error(error);
        this.isLoading = false;
        $scope.$evalAsync();
      }.bind(this))

  }

  this.resetTemplates = function () {
    this.isLoading = true;
    this.aircrafts.forEach(function (aircraft) {
      this.selectedTemplates[aircraft.registration] = {};
    }, this);
    this.isLoading = false;
    $scope.$applyAsync();
  };

  this.submitTemplateAllocation = function () {
    var toSubmit = [userNgStore.saveTemplateAllocation(this.selectedTemplates)];
    if (this.defaultTemplate != undefined) {
      toSubmit.push(userNgStore.saveDefaultTemplate(this.defaultTemplate));
    }

    return Promise
      .all(toSubmit)
      .then(function (results) {
        _.assign(this.selectedTemplates, results[0])
        userProfileNgStore.refresh();
        $scope.$applyAsync();
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });

  };

  this.init();
  aircraftInfoNgStore.refresh();

}]);
