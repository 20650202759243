/*jslint browser: true, nomen:true */
/*global App, angular, Promise, $, _ */

App.factory('navLayersService', [
  'navLayers',
  'generalConstants',
  'groundVehicleService',
  function navLayersService(navLayers, generalConstants, groundVehicleService) {
    'use strict';

    var layerTypeTiled = 'tiled';
    var layerTypeVehicle = 'vehicle';
    var layerTypeImage = 'image';

    var formatters = {
      AIRPORT: AirportFeatureFormatter,
      SPECWAYP: WaypointFeatureFormatter,
      AWY_SEGS: AirwayFeatureFormatter,
      CTA: AirspaceFeatureFormatter,
      SPECNAVAID: NavaidFeatureFormatter,
      FIR: FirFeatureFormatter,
      RA: restrictiveAirspacesFeatureFormatter,
      ENRHOLD: HoldingsFeatureFormatter,
    };
    var defaultFormat = {
      format: function (f) {
        return {
          type: f.id.substr(0, 2),
          title: f.id,
          subtitle: f.properties.type,
          properties: _.map(f.properties, function (e, k) {
            return {
              label: _.startCase(_.lowerCase(k)),
              value: e
            };
          }),
        };
      }
    };

    /**
     * Format each feature info according to its type
     * @param {String} projection
     * @param {Array} featureCollection
     */
    function formatNavFeatures(projection, featureCollection) {
      var features = featureCollection.features;
      featureCollection.features = _.map(features, function (feature) {
        var formatter = formatters[feature.properties.type] || defaultFormat;
        var data = formatter.format(feature, projection);
        data.id = feature.id;
        data.feature = feature;
        return data;
      });
      featureCollection.features = _.uniqBy(featureCollection.features, function (f) {
        return f.title;
      });
      return featureCollection;
    }

    function affectSearchScore(searchParams, searchText, featuresCollection) {
      _.forEach(featuresCollection.features, function (currentFeature) {
        currentFeature.isExactMatch = false;
        _.forEach(searchParams.fields, function (searchParam) {
          var value = currentFeature.feature.properties[searchParam];
          if (currentFeature.feature.properties[searchParam] === searchText) {
            currentFeature.isExactMatch = true;
            return false;
          }
        });
      });
      return featuresCollection;
    }

    /**
     * Get the cql filter
     * @returns {String} cqlFilter
     */
    function getCqlFilter(airports) {
      var icaoIataList = [],
        icaoList = [],
        iataList = [];

      //get only words and put it in an array
      icaoIataList = _.words(airports);

      //find only iata and icao codes from the user preferences
      _.each(icaoIataList, function (airport) {
        switch (airport.length) {
          case 3:
            iataList.push('\'' + airport.toUpperCase() + '\'');
            break;
          case 4:
            icaoList.push('\'' + airport.toUpperCase() + '\'');
            break;
        }
      });

      //get unique values for iataList and icaoList
      iataList = _.uniq(iataList);
      icaoList = _.uniq(icaoList);

      //put the icao and icao list into a string
      var icaoListString = _.join(icaoList, ',');
      var iataListString = _.join(iataList, ',');

      //initialize the cql_filter
      var cqlFilter = '';

      cqlFilter = 'icao_name in (';
      if (!_.isEmpty(icaoListString)) {
        cqlFilter += icaoListString;
      } else {
        cqlFilter += '\'\'';
      }
      cqlFilter += ')';
      if (!_.isEmpty(iataListString)) {
        cqlFilter += ' OR iata_name in (' + iataListString + ')';
      } else if (!_.isEmpty(iataList)) {
        cqlFilter = 'iata_name in (' + iataListString + ')';
      }
      return cqlFilter;

    }

    function NavLayerService() {
      //init fields
      this.selectedNavLayers = [];
    }



    // Methods addition
    _.assign(NavLayerService.prototype, {
      // override constructor
      constructor: NavLayerService,

      /**
       * Send a getFeatureInfo request and format the result for display in left Panel
       * @param {String} coordinate point clicked at
       * @param {String} resolution map resolution
       * @param {String} projection map projection
       * @param {Array<String>} layers list of displayed layers' names
       */
      requestFeaturesInfo: function (coordinate, resolution, projection, olLayers) {
        var wmsSource = new ol.source.ImageWMS({
          url: navblueGeoserver + '/geoserver/navblue/wms',
          serverType: 'geoserver',
          crossOrigin: 'anonymous'
        });
        var cqlFilterArray = [];

        // var airportsLayer = _.find(olLayers, function (layer) {
        //   return layer.getProperties().key === navLayers.staticLayers.airports.key;
        // });

        var layers = olLayers.map(function (l) {
          cqlFilterArray.push(l.getSource().getParams().cql_filter || 'INCLUDE');
          return l.getSource().getParams().layers;
        });

        // this cql filter permits to not select an non favorite airport if the airport layer is not displayed
        var cql_filter = _.join(cqlFilterArray, ';');

        var url = wmsSource.getGetFeatureInfoUrl(coordinate, resolution, projection, {
          query_layers: layers,
          layers: layers,
          info_format: 'application/json',
          exceptions: 'application/json',
          buffer: 10, //10pixel radius,
          feature_count: 50,
          cql_filter: cql_filter
        });
        return fetch(url)
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            if (response.exceptions && response.exceptions.length) {
              return Promise.reject(_.map(response.exceptions, 'text'));
            } else {
              return response;
            }
          })
          .then(formatNavFeatures.bind(this, projection))
          .then(function (data) {
            return data;
          });
      },



      requestFeaturesInfoForSearch: function (projection, searchParams, searchText) {
        var arrayoffilter = [];
        var filter;
        var filters = searchParams.fields.map(function (attribute) {
          return ol.format.filter.like(attribute, searchText + '*');
        });

        // filters = filters.concat(searchParams.fields.map(function (attribute) {
        //   return ol.format.filter.like(attribute, searchText + '*');
        // }));

        if (filters.length > 1) {
          filter = ol.format.filter.or.apply(null, filters);
        } else {
          filter = filters[0];
        }

        var featureRequest = new ol.format.WFS().writeGetFeature({
          srsName: 'EPSG:4326',
          featureNS: '',
          featurePrefix: '',
          featureTypes: searchParams.layers,
          outputFormat: 'application/json',
          // maxFeatures: 100,
          filter: filter,

        });

        var uniqId = _.partial(_.get, _, 'properties.feature_id');

        // then post the request and add the received features to a layer
        return fetch(navblueGeoserver + '/geoserver/navblue/wfs', {
          method: 'POST',
          body: new XMLSerializer().serializeToString(featureRequest)
        }).then(function (response) {
          return response.json();
        })
          .then(function (json) {
            return json;
          })
          .then(function (collection) {
            // add only uniq features
            collection.features = _.uniqBy(collection.features, uniqId);
            return collection;
          })
          .then(formatNavFeatures.bind(this, projection))
          .then(affectSearchScore.bind(this, searchParams, searchText))
          .then(function (data) {
            // _.forEach(data.features, function (f) {
            //   f.foundBy = searchParams.fields;
            // });
            return data.features;
          })
          .catch(function () {
            throw 'There is no data';
          });
      },


      createAirportLayerForReplay: function () {
        return this.createLayer(navLayers.staticLayers.airports);
      },

      createAirportLayerForSubscribed: function (airports) {
        var subscribedOverlay = _.assign({}, navLayers.staticLayers.airports, {
          type: 'image',
          label: 'Subscribed Airports',
        });
        var layer = this.createLayer(subscribedOverlay);
        layer.getSource().updateParams({
          // rgb(0,128,128)
          env: 'color:#008080',
        });
        return layer;
      },

      updateLayersLevel: function (navLayersGroup, level) {
        _.forEach(navLayersGroup.getLayers().getArray(), function (layer) {
          var definition = layer.getSource().get('definition');
          var source = layer.getSource();
          // add high/low prefix to layers according to level selected by user
          if (_.has(navLayers.highLowLayers, layer.get('key'))) {
            source.updateParams({
              layers: definition.params.layers + "_" + level,
            });
          }
        });
      },

      createLayer: function (layerDefinition, level) {
        // Check the type of a Nav Layer
        //
        var layerType;
        var sourceType;
        var serverType;

        if (layerDefinition.type == layerTypeTiled) {
          layerType = ol.layer.Tile;
          sourceType = ol.source.TileWMS;
          serverType = 'geoserver';
        }
        else if (layerDefinition.type == layerTypeVehicle) {
          return groundVehicleService.createLayer(layerDefinition);
        }
        else if (layerDefinition.type == layerTypeImage) {
          layerType = ol.layer.Image;
          sourceType = ol.source.ImageWMS;
          serverType = 'geoserver';
        }

        var olSource = new sourceType({
          url: layerDefinition.url,
          params: _.assign({}, layerDefinition.params),
          crossOrigin: 'anonymous',
          serverType: serverType
        });
        // add high/low prefix to layers according to level selected by user
        if (_.has(navLayers.highLowLayers, layerDefinition.key)) {
          olSource.updateParams({
            layers: layerDefinition.params.layers + "_" + level,
          });
        }

        olSource.set('definition', layerDefinition);
        var olLayer = new layerType({
          title: layerDefinition.label,
          name: generalConstants.navLayerName,
          source: olSource,
          visible: true,
          // low number: on top, High number: below
          zIndex: 10 - layerDefinition.zIndex,
          maxResolution: layerDefinition.maxResolution
        });
        olLayer.set('key', layerDefinition.key);
        return olLayer;
      },

      updateAirportLayer: function (layerSource, airports) {
        layerSource.updateParams({
          'cql_filter': getCqlFilter(airports)
        });
        return layerSource;
      },
    });
    return new NavLayerService();
  }
]);
