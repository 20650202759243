/*jslint browser: true, nomen:true */
/*global _, App, angular, $, Promise*/

App.controller('AdminAcarsCtrl', ['$scope', 'customerNgStore', function ($scope, customerNgStore) {
  'use strict';

  customerNgStore.refresh();
  var customer = customerNgStore.customer,
    parameters = customer.parameters;

  $scope.acarsSettings = {
    timeFrame: parameters.timeFrame || null
  };


  //
  // SUBMIT
  $scope.submitAcarsConfiguration = function () {

    return new Promise(function (resolve, reject) {
      if (isNaN($scope.acarsSettings.timeFrame) || $scope.acarsSettings.timeFrame <= 0) {
        /*        $('.modal-dialog').addClass('shake animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                  $('.modal-dialog').removeClass('shake animated');
                });*/
        reject("The time frame must be a positive number");
      } else {
        parameters.timeFrame = parseInt($scope.acarsSettings.timeFrame, 10);
        customer.parameters = parameters;
        resolve(customerNgStore.set(customer));
      }
    }.bind(this));
  };

}]);
