/*jslint browser: true */
/*global angular, ol */

(function (global) {
  'use strict';
  var
    ratioKmNm = 0.539957,
    EARTH_RADIUS_IN_KM = 6378.137, // 6371,
    EARTH_RADIUS_IN_NAUTIC_MILES = 3440,
    wgs84Sphere = new ol.Sphere(EARTH_RADIUS_IN_KM);

  function toRadians(angleInDegrees) {
    return angleInDegrees * Math.PI / 180;
  }

  function computeOthodromicDistance(latitude1, longitude1, latitude2, longitude2, isKm) {
    var ratio = isKm ? 1 : ratioKmNm,
      a = [longitude1, latitude1],
      b = [longitude2, latitude2];
    return Math.round(ratio * wgs84Sphere.haversineDistance(a, b)).toLocaleString();
  }
  
  function computePreciseOthodromicDistance(latitude1, longitude1, latitude2, longitude2, isKm) {
    var ratio = isKm ? 1 : ratioKmNm,
      a = [longitude1, latitude1],
      b = [longitude2, latitude2];
    return ratio * wgs84Sphere.haversineDistance(a, b);
  }

  function arcToDistance(arcLength, isKm) {
    var radius = isKm ? EARTH_RADIUS_IN_KM : EARTH_RADIUS_IN_NAUTIC_MILES;
    return Math.round(toRadians(arcLength) * radius);
  }

  function clampCoordinate(bounds, coordinates) {
    var clampCoords = [coordinates[0], coordinates[1]];
    var boundsWidth = bounds[2] - bounds[0];
    var boundsHeight = bounds[3] - bounds[1];

    if (clampCoords[0] < bounds[0]) {
      clampCoords[0] = clampCoords[0] + boundsWidth;
    }
    else if (clampCoords[0] > bounds[2]) {
      clampCoords[0] = clampCoords[0] - boundsWidth;
    }

    if (clampCoords[1] < bounds[1]) {
      clampCoords[1] = clampCoords[1] + boundsHeight;
    }
    else if (clampCoords[1] > bounds[3]) {
      clampCoords[1] = clampCoords[1] - boundsHeight;
    }

    // the user is able to pan the equivalent of multiple extents
    // we need to iterate until we have projected the coordinate back to the original view extent
    return (bounds[0] <= clampCoords[0]) && (clampCoords[0] <= bounds[2]) &&
      (bounds[1] <= clampCoords[1]) && (clampCoords[1] <= bounds[3]) ?
      clampCoords : clampCoordinate(bounds, clampCoords);
  }

  global.Map = {
    computeOthodromicDistance: computeOthodromicDistance,
    computePreciseOthodromicDistance: computePreciseOthodromicDistance,
    toRadians: toRadians,
    arcToDistance: arcToDistance,
    clampCoordinate: clampCoordinate
  };

}(window));
