App.factory('definedAreaRestService', ['$http', function($http) {

  return {
    getAreas: function() {
      return $http({
        method: 'GET',
        url: window.backendPrefix + '/area',
        withCredentials: true,
        responseType: 'json'
      }).then( function successCallback( response ) {
          return response.data;
      }, function errorCallback( response ) {
        console.log( 'Error fetching areas; error code ' + response.status );
      });
    },
    saveDefinedArea: function( definedArea ) {
      return $http({
        method: 'POST',
        url: window.backendPrefix + '/area',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'json',
        data: definedArea
      }).then( function successCallback( response ) {
          return response.data;
      }, function errorCallback(response) {
        if(response.data !== null && typeof response.data.code !== 'undefined') {
          return Error( 'Area did not save correctly. Message: ' + ErrorCode.getMessage(response.data.code));
        } else {
          return Error( 'Area did not save correctly. Error: ' + response.status + ' ' + response.statusText );
        }
      });
    },
    updateDefinedArea: function( definedArea ) {
      return $http({
        method: 'PUT',
        url: window.backendPrefix + '/area/' + definedArea.id,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'json',
        data: definedArea
      }).then( function successCallback( response ) {
          return response.data;
      }, function errorCallback(response) {
        if(response.data !== null && typeof response.data.code !== 'undefined') {
          return Error( 'Area did not update correctly. Message: ' + ErrorCode.getMessage(response.data.code));
        } else {
          return Error( 'Area did not update correctly. Error: ' + response.status + ' ' + response.statusText );
        }
      });
    },
    deleteDefinedArea: function( definedArea ) {
      return $http({
        method: 'DELETE',
        url: window.backendPrefix + '/area/' + definedArea.id,
        withCredentials: true,
        responseType: 'json'
      }).then( function successCallback( response ) {
          return response.data;
      }, function errorCallback(response) {
        if(response.data !== null && typeof response.data.code !== 'undefined') {
          return Error( 'Area did not delete correctly. Message: ' + ErrorCode.getMessage(response.data.code));
        } else {
          return Error( 'Area did not delete correctly. Error: ' + response.status + ' ' + response.statusText );
        }
      });
    },
    associationsState: function( definedArea ) {
      return $http({
        method: 'GET',
        url: window.backendPrefix + '/area/' + definedArea.id + '/associations-state',
        withCredentials: true,
        responseType: 'json'
      }).then( function successCallback( response ) {
          return response.data;
      }, function errorCallback( response ) {
        console.log( 'Error fetching area associations state; error code ' + response.status );
      });
    }
  };

}]);
