/*jslint browser: true */
/*global console */

(function () {
  'use strict';
  if ('serviceWorker' in navigator) {
    // Remove any previous service worker.
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      registrations.forEach(function (registration) {
        registration.unregister();
      });
    });
  }

}(window));
