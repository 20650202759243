/*jslint browser: true, nomen: true */
/*global App, PubSub */

(function (global) {
  'use strict';
  
  var config = new PubSub(),
    _customerId = global.localStorage.getItem('customerId');

  config.get = function () {
    return _customerId;
  };
  config.set = function (customerId) {
    var oldCustomerId = _customerId,
      newCustomerId = customerId;
    if (oldCustomerId !== newCustomerId) {
      _customerId = newCustomerId;
      this.trigger('update', oldCustomerId, newCustomerId);
    }
    global.localStorage.setItem('customerId', _customerId);
  };

  App.constant('customerId', config);
}(window));
