const { map } = require("angular-ui-router");

App.factory('mapPositionService', [function mapPositionServiceFactory() {
    'use strict';

    var mapPositionService;

    function MapPositionService() {
        this.map = null;
        this.boundingBox = '';
        this.showOthers = false;
        this.filtered = false;
    }

    angular.extend(MapPositionService.prototype, PubSub.prototype, {

        constructor: MapPositionService,

        registerMap: function (map) {
            this.map = map;
            this.refreshMap();
        },

        refreshMap: function () {
            if (this.map !== null) {
                var mapExtent = ol.proj.get('EPSG:4326').getExtent();
                var viewExtent = this.map.getView().calculateExtent(this.map.getSize());
                this.boundingBox = ol.proj.transformExtent(viewExtent, 'EPSG:3857', 'EPSG:4326');

                // if the bounding box is wider than the extent
                if ((this.boundingBox[2] - this.boundingBox[0]) >= (mapExtent[2] - mapExtent[0])) {
                    // set bounding box min/max longitude to extent min/max longitude
                    this.boundingBox[0] = mapExtent[0];
                    this.boundingBox[2] = mapExtent[2];
                }
                // check if either bounding box corner is outside the extent
                var i = 0;
                while (i < 4) {
                    // longitude wraps so we can shift the corner left or right as necessary
                    if (this.boundingBox[i] < mapExtent[0]) {
                        // left of extent, shift right
                        this.boundingBox[i] = this.boundingBox[i] + mapExtent[2] - mapExtent[0];
                    }
                    if (this.boundingBox[i] > mapExtent[2]) {
                        // right of extent, shift left
                        this.boundingBox[i] = this.boundingBox[i] - mapExtent[2] + mapExtent[0];
                    }
                    // latitude does not wrap, set the corner to extent limit if it is outside
                    if (this.boundingBox[i+1] < mapExtent[1]) {
                        this.boundingBox[i+1] = mapExtent[1];
                    }
                    if (this.boundingBox[i+1] > mapExtent[3]) {
                        this.boundingBox[i+1] = mapExtent[3];
                    }
                    // only lognitude may require multiple shifts
                    if (this.boundingBox[i] >= mapExtent[0] && this.boundingBox[i] <= mapExtent[2]) {
                        // longitude is inside extent, move to next point
                        i += 2;
                    }
                }

                this.trigger('updateBoundingBox', this.boundingBox);
            }
        },

        getBoundingBoxString: function () {
            return this.boundingBox.join();
        },

        fetchOthers: function () {
            return this.showOthers || this.filtered;
        },

        updateFetchOthersAndTrigger(showOthers, filtered) {
            var oldFetchOthers = this.fetchOthers();
            this.showOthers = showOthers;
            this.filtered = filtered;
            if (this.fetchOthers() !== oldFetchOthers) {
                this.trigger('updateFetchOthers', this.fetchOthers());
            }
        },

        setShowOthers: function (showOthers) {
            this.updateFetchOthersAndTrigger(showOthers, this.filtered);
        },

        setFiltered: function (filtered) {
            this.updateFetchOthersAndTrigger(this.showOthers, filtered);
        }
    });

    mapPositionService = new MapPositionService();

    return mapPositionService;
}])