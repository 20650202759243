/*jslint browser: true, plusplus:true, nomen:true*/
/*global _, angular, App, $ */

App
  .controller("SuperAircraftSummaryCtrl", [
    '$scope',
    'customerSuperNgStore',
    function SuperAircraftSummary($scope, customerSuperNgStore) {
      "use strict";

      this.aircraftSummary = () => {
        const promises = [];
        this.loaded = false;
        $scope.totalAircrafts = 0;
        var aircraftPerCustomer = 0;
        this.aircraftSummaryList = [];
        
        waitForCustomers().then(() => {
          const customers = customerSuperNgStore.customers.filter(customer => customer.name !== "Api");
          customers.forEach((customer) => {
            promises.push(customerSuperNgStore.getAircrafts(customer).then((aircrafts) => {
              aircraftPerCustomer = aircrafts.length;
              $scope.totalAircrafts += aircrafts.length;
              aircrafts.forEach((aircraft) => {
                populateAircraftSummaryList(customer, aircraft, aircraftPerCustomer);  //If a customer doesn't have any aircrafts, they won't be included in this list
              });
            }));
          });
          Promise.all(promises).then(() => {
            this.loaded = true;
          });
        });
      };

      let waitForCustomers = () => {
        return waitUntil(() => customerSuperNgStore.customers.length > 0, 100, 10000)
      }

      let waitUntil = (condition, interval, timeout) => {
        var elapsed = 0;
        return new Promise((resolve) => {
          const intervalId = setInterval(() => {
            elapsed += interval;
            if (condition() || elapsed > timeout) {
              clearInterval(intervalId);
              resolve();
            }
          }, interval);
        });
      }

      let populateAircraftSummaryList = (customer, aircraft, aircraftPerCustomer) => {
        this.aircraftSummaryList.push({
          registration: aircraft.registration,
          customerName: customer.name,
          spaceBasedActivated: JSON.stringify(aircraft.spaceBasedActivated),
          dateAdded: aircraft.dateAdded,
          customerWeatherPackage: customer.customerWeatherPackage[0],
          surfaceTracking: JSON.stringify(customer.parameters.surfaceTracking),
          aircraftPerCustomer: aircraftPerCustomer
        });
      }

      this.aircraftSummary();
    }
]);