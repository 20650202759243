App.factory('routeLegModelFactory', [function() {
  'use strict';

  class RouteLeg {

    constructor(name, longitude, latitude) {
      this.name = name;
      this.longitude = longitude;
      this.latitude = latitude;
    }

    toString() {
      return JSON.stringify(this);
    }
  }

  return {
    routeLeg: function (name, longitude, latitude) {
      return new RouteLeg(name, longitude, latitude);
    }
  };

}]);
