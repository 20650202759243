/*jslint browser: true, nomen: true */
/*global _ */

(function (global) {
  'use strict';

  function ErrorCode(message) {
    this.message = message;
  }

  _.assign(ErrorCode.prototype, {
    /**
     * return the formatted message with params.
     * @param params the array of parameters
     */
    format: function (params) {
      var r = this.message;
      if (_.isArray(params)) {
          params.map(function (p, i) {
              r = r.replace('{' + i + '}', p);
          });
          if(r.indexOf('${values}') > -1)r = params.join(',<br />');
      }
      return r;
    }
  });

  // Should be the same as com.airbus.prosky.smarthub.service.exception.ErrorCode Enum
  _.assign(ErrorCode, {

    /**
     * Message: "Customer '{0:name}' already exists"
     */
    DUPLICATE_CUSTOMER: new ErrorCode('Customer "{0}" already exists'),

    /**
     * Message: "User '{0:login}' already exists"
     */
    DUPLICATE_LOGIN: new ErrorCode('User "{0} already exists'),

    /**
     * Message: "User '{0:email}' already exists"
     */
    DUPLICATE_EMAIL: new ErrorCode('User with "{0}" as email already exists'),

    /**
     * Message: "The old password is not correct"
     */
    OLD_PASSWORD_INVALID: new ErrorCode('The old password is not correct'),

    /**
     * Message: "The new password must have {0:nb_char} characters"
     */
    NEW_PASSWORD_INVALID_LENGTH: new ErrorCode('The new password must have {0} characters'),

    /**
     * Message: "The new password must not be an email"
     */
    NEW_PASSWORD_INVALID_EMAIL: new ErrorCode('The new password must not be an email'),

    /**
     * Message: "The new password must not be the login"
     */
    NEW_PASSWORD_INVALID_LOGIN: new ErrorCode('The new password must not be the login'),

    /**
     * Message: "The new password must have at least 1 letter and 1 number"
     */
    NEW_PASSWORD_INVALID_PATTERN: new ErrorCode('The new password must have at least 1 letter and 1 number'),

    /**
     * Message: "Customer '{0:customerId}' not found"
     */
    CUSTOMER_NOT_FOUND: new ErrorCode('Customer "{0}" not found'),

    /**
     * Message: "Alert '{0:alertId}' not found"
     */
    ALERT_NOT_FOUND: new ErrorCode('Alert "{0}" not found'),

    /**
     * Message: "User '{0:userId|login|email}' not found"
     */
    USER_NOT_FOUND: new ErrorCode('User not found'),

    /**
     * Message: "Alert rule not found"
     */
    ALERT_RULE_NOT_FOUND: new ErrorCode('Alert rule not found'),

    /**
     * Message:
     * "An aircraft with registration '{0:registration}' already exists"
     */
    DUPLICATE_REGISTRATION: new ErrorCode('An aircraft with registration "{0}" already exists'),

    /**
     * Message:
     * "An aircraft with address modeS '{0:addressModeS}' already exists"
     */
    DUPLICATE_ADDRESSMODES: new ErrorCode('An aircraft with address modeS "{0}" already exists'),

    /**
     * Message: "Aircraft not found"
     */
    AIRCRAFT_NOT_FOUND: new ErrorCode('Aircraft not found'),
    /**
     * Message: "Airport not found"
     */
    AIRPORT_NOT_FOUND: new ErrorCode('Airport not found, {0}'),

    /**
     * Message: "Flight not found"
     */
    FLIGHT_NOT_FOUND: new ErrorCode('Flight not found'),

    /**
     * Message: "Chat message not fount"
     */
    CHAT_MSG_NOT_FOUND: new ErrorCode('Chat message not found'),

     /**
     * Message:
     * "Chat is not configured for aircraft '{0:registration}'"
     */
    CHAT_NOT_CONFIGURED: new ErrorCode ('Chat is not configured for aircraft "{0}"'),
    /**
     * Message:
     * "Invalid file format"
     */
    INVALID_FILE_FORMAT: new ErrorCode ('Invalid file format: {0}'),

    /**
     * Message:
     * "Invalid ModeS - Registration for the couple: {0:modeS} - {1:registration}"
     */
    INVALID_MODES_REGISTRATION: new ErrorCode('Invalid ModeS - Registration for the couple: {0} - {1}'),

    MANUAL_REPORT_ERROR: new ErrorCode('<span class="title">To save manual report, fix the following elements :</span></br> {0}'),

    /**
     * Message:
     * ${values}
     */
    INVALID_SIZE: new ErrorCode('${values}'),

    DEFINED_AREA_USER_NOT_AUTHOR: new ErrorCode('AuthorId must match the userId of the logged in user or the logged in user must be an admin'),

    DEFINED_AREA_DUPLICATE: new ErrorCode('Area Name must be unique for the given author'),

    DEFINED_AREA_BAD_REQUEST: new ErrorCode('Area Data is malformed'),

    DEFINED_AREA_BAD_GEOJSON: new ErrorCode('Area Data is malformed'),

    FAVORITE_DUPLICATE: new ErrorCode('Favorite name must be unique for the given owner'),

    FAVORITE_USER_NOT_OWNER:  new ErrorCode('OwnerId must match the userId of the logged in user'),

    FAVORITE_BAD_REQUEST:  new ErrorCode('Favorite Data is malformed'),

    FAVORITE_NOT_FOUND: new ErrorCode('Favorite not found'),

    FAVORITE_PRIVATE_DEFINED_AREA_EXISTS: new ErrorCode('Private Area(s) are preventing this operation!'),

    ROUTE_ANALYSIS_INVALID_REGISTRATION: new ErrorCode('Please enter a configured aircraft\'s registration.'),

    NFP_CONFIG_EXISTS_FOR_AIRCRAFT: new ErrorCode('The aircraft is associated with the customer\'s NFP configuration.'),
  });

  ErrorCode.getMessage = function (code, values) {
    if (ErrorCode[code]) {
      return ErrorCode[code].format(values);
    } else {
      return code;
    }
  };
  /*
    values is used to have a ErrorCode from its index
  */
  ErrorCode.values = [
    ErrorCode.DUPLICATE_CUSTOMER,
    ErrorCode.DUPLICATE_LOGIN,
    ErrorCode.DUPLICATE_EMAIL,
    ErrorCode.OLD_PASSWORD_INVALID,
    ErrorCode.NEW_PASSWORD_INVALID_LENGTH,
    ErrorCode.NEW_PASSWORD_INVALID_EMAIL,
    ErrorCode.NEW_PASSWORD_INVALID_LOGIN,
    ErrorCode.NEW_PASSWORD_INVALID_PATTERN,
    ErrorCode.CUSTOMER_NOT_FOUND,
    ErrorCode.ALERT_NOT_FOUND,
    ErrorCode.USER_NOT_FOUND,
    ErrorCode.ALERT_RULE_NOT_FOUND,
    ErrorCode.DUPLICATE_REGISTRATION,
    ErrorCode.DUPLICATE_ADDRESSMODES,
    ErrorCode.AIRCRAFT_NOT_FOUND,
    ErrorCode.AIRPORT_NOT_FOUND,
    ErrorCode.FLIGHT_NOT_FOUND,
    ErrorCode.CHAT_MSG_NOT_FOUND,
    ErrorCode.CHAT_NOT_CONFIGURED,
    ErrorCode.INVALID_FILE_FORMAT,
    ErrorCode.INVALID_MODES_REGISTRATION,
    ErrorCode.INVALID_SIZE,
    ErrorCode.DEFINED_AREA_USER_NOT_AUTHOR,
    ErrorCode.DEFINED_AREA_DUPLICATE,
    ErrorCode.DEFINED_AREA_BAD_REQUEST,
    ErrorCode.DEFINED_AREA_BAD_GEOJSON,
    ErrorCode.FAVORITE_DUPLICATE,
    ErrorCode.FAVORITE_USER_NOT_OWNER,
    ErrorCode.FAVORITE_BAD_REQUEST,
    ErrorCode.FAVORITE_NOT_FOUND,
    ErrorCode.FAVORITE_PRIVATE_DEFINED_AREA_EXISTS,
    ErrorCode.ROUTE_ANALYSIS_INVALID_REGISTRATION,
    ErrorCode.NFP_CONFIG_EXISTS_FOR_AIRCRAFT
  ];

  global.ErrorCode = ErrorCode;

}(window));
