/*jslint browser: true, nomen:true */
/*global App, angular, Promise, $, _ */
App.factory('alertService', [
  '$uibModal',
  '$templateCache',
  function AlertServiceFactory($uibModal, $templateCache) {
    'use strict';
    //
    // Class AlertService 
    function AlertService() {}

    function openModal(title, msg, buttonLabels, level) {
      var modalInstance = $uibModal.open({
        animation: true,
        controller: 'AlertServiceModalCtrl',
        templateUrl: 'views/components/alerts/' + level + '.html',
        resolve: {
          title: _.constant(title),
          message: _.constant(msg),
          buttonLabels: _.constant(buttonLabels)
        }
      });
      return modalInstance.result;
    }

    //
    // Methods addition
    _.assign(AlertService.prototype, {
      // override constructor
      constructor: AlertService,

      error: function (title, msg, buttonLabel) {
        var buttonLabels = [];
        buttonLabels.push(buttonLabel || 'Ok');
        return openModal(title, msg, buttonLabels, 'error');
      },
      warning: function (title, msg, buttonLabel) {
        var buttonLabels = [];
        buttonLabels.push(buttonLabel || 'Ok');
        return openModal(title, msg, buttonLabels, 'warning');
      },
      message: function (title, msg, buttonLabel) {
        var buttonLabels = [];
        buttonLabels.push(buttonLabel || 'Ok');
        return openModal(title, msg, buttonLabels, 'message');
      },
      confirm: function (title, msg, okLabel, noLabel) {
        var buttonLabels = [];
        buttonLabels.push(okLabel || 'Yes');
        buttonLabels.push(noLabel || 'No');
        return openModal(title, msg, buttonLabels, 'confirm');
      }


    });

    return new AlertService();

  }
]).controller('AlertServiceModalCtrl', [
  '$scope',
  'title',
  'message',
  'buttonLabels',
  function ($scope, title, message, labels) {
    'use strict';

    $scope.title = title;
    $scope.buttonLabels = labels;
    $scope.message = message;
  }
]);
