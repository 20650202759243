/*jslint browser: true, plusplus:true*/
/*global angular, ol, App, UserService, ErrorCode */

App
  .controller('AdminTemplateListCtrl', [
    '$rootScope',
    '$scope',
    '$state',
    '$uibModal',
    'userNgStore',
    'customerNgStore',
    'alertService',
    'userService',
    'userProfileNgStore',
    'flightfieldDefinitions',
    function AdminTemplateListCtrl($rootScope, $scope, $state, $uibModal, userNgStore, customerNgStore, alertService, userService, userProfileNgStore, flightfieldDefinitions) {

      'use strict';
      
      $scope.isLoading=true;

      function getTemplates(){
        
        var templates = customerNgStore.getTemplates().then(function(templates){
          templates.forEach(function(t){
              t.fields = _.map(t.fields, function(key){
                var def = flightfieldDefinitions[key]; 
                return _.assign({ key: key }, def);
              });
            })
            $scope.templates = templates;
            $scope.isLoading = false;
            $scope.availableFlightfieldDefinitions = [];
        });
      };

      getTemplates();

      $scope.checkNumberOfTags = function($query){
        if($query && $query.length >= 10){
          return false;
        }
        return true;
      }
      
      $scope.addTemplate = function () {
        $scope.templates = _.compact($scope.templates);
        $scope.templates.push({
          name: '',
          labels: ''
        });
      };

     $scope.isFormValid = function () {
        return $scope.manageTemplateForm.$valid;
      };

      $scope.loadAutoCompleteLabels =function ($query) { 
      return _.chain(flightfieldDefinitions)
          .map(function (def, key) {
            return _.assign({ key: key }, def);
          })
          .filter(function (def, key) {
            return ((def.label.toLowerCase().indexOf($query.toLowerCase()) != -1) && def.displayInTemplate && (_.isUndefined(def.roles) || $rootScope.hasAnyRole(def.roles)) && (_.isUndefined(def.options) || $rootScope.hasAnyOption(def.options)))
          })
          .values()
          .value();
      }

      $scope.deleteTemplate = function (index) {
        $scope.templates.splice(index, 1);
      };

      $scope.submitTemplateChange = function () {
        $scope.isLoading = true;
        var templates = $scope.templates.concat();

        //{name:'', tags:[]}
        templates.forEach(function(t){
          t.fields = _.map(t.fields, 'key');
        })
        return customerNgStore.saveTemplates(templates).then(
          function (templates) {
            templates.forEach(function (t) {
              t.fields = _.map(t.fields, function (key) {
                var def = flightfieldDefinitions[key];
                return _.assign({ key: key }, def);
              });
            })
             $scope.templates = templates;
             $scope.isLoading = false;
             $scope.$applyAsync();
          },
          function (response) {
            $scope.isLoading = false;
            return response.json()
              .then(
              function (errors) {
                var error = errors.length && errors[0],
                  msg = (error && ErrorCode.getMessage(error.code, error.values)) || "";
                alertService.error('Modification failed', msg);
                reject(msg);
              },
              function () {
                alertService.error('Update failed', "Server Internal");
                reject("Unknow error");
              }
              );
          }
        );
        
      };
      $scope.hasAdminRole = function (user) {
        return user.roles.indexOf(App.ADMIN_ROLE) !== -1;
      };
    }
  ]);
