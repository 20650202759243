/*jslint browser: true, plusplus:true*/
/*global App, $, angular */

(function (global) {
  'use strict';

  function AdminAlertMailFormCtrl($scope, $state, $stateParams, $uibModalInstance, mail, mailList) {

    var defaultNewMail = function () {
      return {
        name: '',
        contactInfo: ''
      };
    };

    $scope.newMail = defaultNewMail();

    $scope.editedMail = null;
    // Mail to edit (if edition mode)
    $scope.mail = _.clone(mail);

    //modify to the real data holder
    $scope.modifyItem = function modifyItem(mailToAdd) {

      $scope.editedMail = mailToAdd;

      // check if all informations are filled
      mailToAdd.name = document.getElementById('name').value;
      mailToAdd.contactInfo = document.getElementById('value').value;

      _.assign(mail, $scope.editedMail);
      $uibModalInstance.close();
    };

    //add to the real data holder
    $scope.addItem = function addItem() {

      // check if all informations are filled
      var mailToAdd = {
        name: document.getElementById('name').value,
        contactInfo: document.getElementById('value').value
      };

      if ((document.getElementById('name') !== null && document.getElementById('name').value === "") ||
        (document.getElementById('value') !== null && document.getElementById('value').value === "")) {
        $('.modal-dialog').addClass('shake animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
          $('.modal-dialog').removeClass('shake animated');
        });
        return;
      }

      var regex = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/i;
      var testMailRegex = regex.exec(document.getElementById('value').value);

      if (testMailRegex === null) {
        $('.modal-dialog').addClass('shake animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
          $('.modal-dialog').removeClass('shake animated');
        });
        return;
      }

      mailList.push(mailToAdd);
      $uibModalInstance.close();
    };

    // Cancel action
    $scope.cancelItem = function cancelItem() {
      var form = document.getElementById("formAdminAddMailAlert"),
        elements = form.elements,
        i;
      for (i = 0; i < elements.length; ++i) {
        elements[i].required = false;
      }
      $uibModalInstance.close();
    };
  }

  App
    .controller('AdminAlertMailFormCtrl', ['$scope', '$state', '$stateParams', '$uibModalInstance', 'mail', 'mailList', AdminAlertMailFormCtrl]);

}(window));
