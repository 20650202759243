App.controller('routeListController', ['$scope', '$rootScope', '$element', 'routeRestService', 'alertService', '$window', '$uibModal', function ($scope, $rootScope, $element, routeRestService, alertService, $window, $uibModal) {
  'use strict';

  var vm = this;
  var w = angular.element($window);

  w.bind('resize', function () {
    vm.resize();
  });

  vm.routes = [];
  vm.origin = "";
  vm.destination = "";
  vm.registration = "";

  vm.loading = false;
  vm.loaderCssClass = '';
  vm.isVisibleAll = true;

  var zoomLevelToShowWaypoints = 7;
  var selectInteraction = null;

  const ROUTES_LAYER = 'RouteAnalysis';
  const POINTS_LAYER = 'RouteAnalysisPointsLayer';
  const LINES_LAYER = 'RouteAnalysisLinesLayer';
  var currentZoomLevel = map.getView().getZoom();
  const NFP_ROUTE_NAME='NFP';

  map.on('moveend', function (evt) {
    var newZoomLevel = map.getView().getZoom();
    if (newZoomLevel !== currentZoomLevel) {
      vm.displayOrHideWaypointName (currentZoomLevel, newZoomLevel);
      currentZoomLevel = newZoomLevel;
    }
  });

  const LINE_STYLE_UNSELECTED_ROUTE = new ol.style.Style({
    zIndex: 0,
    stroke: new ol.style.Stroke({
      color: 'white',
      width: 3
    })
  });

  const LINE_STYLE_SELECTED_ROUTE = new ol.style.Style({
      zIndex: 1,
      stroke: new ol.style.Stroke({
        color: 'yellow',
        width: 3
      })
  });

  const LINE_STYLE_NFP_SELECTED_ROUTE = new ol.style.Style({
      zIndex: 1,
      stroke: new ol.style.Stroke({
        color: '#B3B3B3',
        width: 3
      })
  });
  const POINT_CIRCLE = new ol.style.Circle({
    radius: 5,
    fill: new ol.style.Fill({
      color: 'orange'
    }),
    stroke: new ol.style.Stroke({
      color: 'black',
      width: 1
    })
  });

  vm.getPointStyle = function (waypointName) {
    return new ol.style.Style({
      image: POINT_CIRCLE,
      text: new ol.style.Text({
        text: waypointName,
        font: 'normal 16px "Source Sans Pro"',
        fill: new ol.style.Fill({
          color: 'white'
        }),
        offsetY: -20
      })
    });
  };

  vm.$onInit = function () {
    vm.opened = false;
    vm.resize();
  };

  $rootScope.$on("ToggleRouteList", function (event) {
    vm.opened = !vm.opened;
  });

$rootScope.$on("ToggleRouteListWithValues", function (event, ori, dst, registration) {
    vm.origin = ori;
    vm.destination = dst;
    vm.registration = registration;
    vm.opened = true;
});

  vm.resize = function () {
    let padding = 115;

    let max = $(document).height() - $('#routeList').offset().top;
    $('#routeList').css('max-height', max);

    let innerMax = $(document).height() - $('#routeListScroller').offset().top;
    $('#routeListScroller').css('max-height', innerMax - padding);
  };


  vm.close = function () {
    vm.opened = false;
  };

  vm.findRoutes = function () {
    vm.loaderCssClass = 'route-loader';
    vm.loading = true;
    routeRestService.getRoutes(vm.origin.toUpperCase(), vm.destination.toUpperCase(), vm.registration.toUpperCase()).then((result) => {
      if (result instanceof Error) {
        alertService.error(result.name, result.message);
        vm.routes = [];
        vm.removeAllRoutesFromMap();
      } else {
        vm.routes = result;
        vm.isVisibleAll = true;
        vm.routes.forEach(route => {
          route.isVisible = true;
          route.isSelected = false;
        });
        vm.drawToggledOnRoutesOnMap();
      }
      vm.loaderCssClass = '';
      vm.loading = false;
    });
  };

  vm.drawToggledOnRoutesOnMap = function () {

    vm.removeAllRoutesFromMap();

    let vectorSourceLine = new ol.source.Vector({});
    let vectorSourcePoint = new ol.source.Vector({});

    let vectorSourceLineLayer = new ol.layer.Vector({
      name: ROUTES_LAYER,
      type: LINES_LAYER,
      source: vectorSourceLine
    });

    let vectorSourcePointLayer = new ol.layer.Vector({
      name: ROUTES_LAYER,
      type: POINTS_LAYER,
      source: vectorSourcePoint
    });

    vm.routes.forEach(route => {
      if(route.isVisible) {
        let coordinates = [];
        let routeString = route.routeString;

        let prevWaypointLong;
        route.routeLegDTOS.forEach(routeLeg => {
            if(prevWaypointLong) {
                routeLeg.longitude = window.GeometryUtils.adjustLongitudeIfCrossedDateLine(prevWaypointLong, routeLeg.longitude);
            }
            prevWaypointLong = routeLeg.longitude;
            coordinates.push(vm.createPointFeatureForRouteLeg(routeLeg, vectorSourcePoint, routeString));
        });

        vm.createLineFeatureForRoute(coordinates, vectorSourceLine, route)
      }
    });

    map.addLayer(vectorSourceLineLayer);
    map.addLayer(vectorSourcePointLayer);
    vm.addSelectInteraction(map, vectorSourceLineLayer);
  };

  vm.removeAllRoutesFromMap = function () {
    let layers = map.getLayers().getArray();
    for (let i = layers.length - 1; i >= 0; i--) {
      let layer = layers[i];
      if (layer.get('name') === ROUTES_LAYER) {
        map.removeLayer(layer);
        if (layer.get('type') === LINES_LAYER) {
            map.removeInteraction(selectInteraction);
        }
      }
    }
  };

  vm.createPointFeatureForRouteLeg = function (routeLeg, vectorSourcePoint, routeString) {
    let lon = routeLeg.longitude;
    let lat = routeLeg.latitude;
    let waypointName = routeLeg.name;

    let coordinate = ol.proj.transform([lon, lat], 'EPSG:4326', 'EPSG:3857');
    let point = new ol.geom.Point(coordinate);

    let pointFeature = new ol.Feature({
      waypointName: waypointName,
      routeString: routeString,
      geometry: point
    });
    if (currentZoomLevel >= zoomLevelToShowWaypoints) {
      pointFeature.setStyle(vm.getPointStyle(waypointName));
    } else {
      pointFeature.setStyle(vm.getPointStyle(''));
    }
    vectorSourcePoint.addFeature(pointFeature);

    return coordinate;
  };

  vm.createLineFeatureForRoute = function (coordinates, vectorSourceLine, route) {
    let lineString = new ol.geom.LineString(coordinates);
    let lineFeature = new ol.Feature({
      routeString: route.routeString,
      geometry: lineString
    });
    lineFeature.setStyle(route.isSelected ?(route
    .routeType!=NFP_ROUTE_NAME?LINE_STYLE_SELECTED_ROUTE:LINE_STYLE_NFP_SELECTED_ROUTE):LINE_STYLE_UNSELECTED_ROUTE);
    vectorSourceLine.addFeature(lineFeature);
  };

  vm.setVisiblityForRoute = function (route) {
    if(route.isSelected) {
        route.isSelected = !route.isSelected;
    }
    vm.drawToggledOnRoutesOnMap();
    vm.syncGlobalVisibilityToggle();
  };

  vm.setVisiblityForAllRoutes = function () {
    vm.routes.forEach(route => {
       route.isVisible = vm.isVisibleAll;
       if(!vm.isVisibleAll) {
        route.isSelected = vm.isVisibleAll;
       }
    });
    if (vm.isVisibleAll) {
       vm.drawToggledOnRoutesOnMap();
    } else {
       vm.removeAllRoutesFromMap();
    }
  };

    vm.syncGlobalVisibilityToggle = function () {
        let visibleRoutes = [];
        let invisibleRoutes = [];

        vm.routes.forEach(route => {
            route.isVisible ? visibleRoutes.push(route) : invisibleRoutes.push(route);
        });
        if (visibleRoutes.length === vm.routes.length) {
            vm.isVisibleAll = true;
        }
        if (invisibleRoutes.length === vm.routes.length) {
            vm.isVisibleAll = false;
        }
    };

  vm.shouldUpdateWaypointName = function (currentZoomLevel, newZoomLevel)  {
    return (currentZoomLevel >= zoomLevelToShowWaypoints && newZoomLevel < zoomLevelToShowWaypoints) ||
                   (currentZoomLevel < zoomLevelToShowWaypoints && newZoomLevel >= zoomLevelToShowWaypoints);
  };

  vm.displayOrHideWaypointName = function (currentZoomLevel, newZoomLevel) {
    if (vm.shouldUpdateWaypointName(currentZoomLevel, newZoomLevel)) {
       map.getLayers().forEach(layer => {
            if (layer.get('name') === ROUTES_LAYER && layer.get('type') === POINTS_LAYER) {
            layer.getSource().getFeatures().forEach( feature => {
                if (newZoomLevel >= zoomLevelToShowWaypoints) {
                    feature.getStyle().getText().setText(feature.get('waypointName'));
                } else {
                    feature.getStyle().getText().setText('');
                }
            });
            layer.getSource().changed();
            }
       });
    }
  };

  vm.openRouteInfo = function (route) {

    var modalInstance = $uibModal.open({
        animation: true,
        controller: 'routeInfoController',
        controllerAs: 'routeInfoCtrl',
        templateUrl: 'views/partials/map/route-info.template.html',
        resolve: {
          title: _.constant("Additional Info"),
          routeInfo: route,
        }
      });
      return modalInstance.result;
  }

  vm.setStyleForRoute = function (route, lineStyle) {
    map.getLayers().forEach(layer => {
      if (layer.get('name') === ROUTES_LAYER && layer.get('type') === LINES_LAYER) {
        layer.getSource().getFeatures().forEach(feature => {
          if(route.routeString === feature.get('routeString')){
            feature.setStyle(lineStyle);
          }
        });
        layer.getSource().changed();
      }
    });
  };

  vm.deselectRoute = function (route) {
    route.isSelected = false;
    vm.setStyleForRoute(route, LINE_STYLE_UNSELECTED_ROUTE);
  };

  vm.selectRoute = function (route) {
    route.isSelected = true;
    vm.setStyleForRoute(route, (route.routeType!=NFP_ROUTE_NAME? LINE_STYLE_SELECTED_ROUTE:LINE_STYLE_NFP_SELECTED_ROUTE));
  };

  vm.deselectAllRoutes = function () {
    var previousSelectedRoutes = vm.routes.filter(route => route.isSelected);
    if (previousSelectedRoutes.length !== 0) {
        previousSelectedRoutes.forEach(route => {vm.deselectRoute(route)});
    }
  };

  vm.toggleRouteSelection = function (route) {
    if(!route.isSelected && route.isVisible) {
        vm.deselectAllRoutes();
        vm.selectRoute(route);
    } else if (route.isSelected && route.isVisible) {
        vm.deselectRoute(route);
    }
  };

  vm.addSelectInteraction = function (map, lineLayer) {
      selectInteraction = new ol.interaction.Select({
          layers: [lineLayer],
          condition: ol.events.condition.click,
          toggleCondition: ol.events.condition.click
      });
      selectInteraction.on('select', function (event) {
          var selected = event.selected;
          if (selected.length !== 0) {
              selectInteraction.getFeatures().clear();
              selected.forEach(feature => {
                  var previousSelectedRoutes = vm.routes.filter(route => route.isSelected && route.routeString !== feature.get('routeString'));
                  if (previousSelectedRoutes.length !== 0) {
                    previousSelectedRoutes.forEach(route => {vm.deselectRoute(route)});
                  }
                  var routeToSelect = vm.routes.filter(route => route.routeString === feature.get('routeString'))[0];
                  feature.setStyle(routeToSelect.isSelected ? LINE_STYLE_UNSELECTED_ROUTE : (routeToSelect.routeType!=NFP_ROUTE_NAME? LINE_STYLE_SELECTED_ROUTE:LINE_STYLE_NFP_SELECTED_ROUTE));
                  routeToSelect.isSelected = !routeToSelect.isSelected;
              });
          }
          lineLayer.getSource().changed();
      });
      map.addInteraction(selectInteraction);
  };

  vm.copyRouteStringtoClipboard = function (routeString) {
    Utils.copyToClipboard(routeString);
  };

}]);
