/*jslint browser: true, nomen:true */
/*global _, App, Promise, Plumbing, angular, moment */

App.provider('flightStore', ['customerId', function flightStoreProvider(customerId) {
  'use strict';

  var opts, getBasePath, getPlumbingPath, getPath, getAirportFlightsPath, store, getPlannedFlightPlanParams;

  opts = {
    path: {
      customer: '/customer/',
      aircraft: '/aircraft-flight',
      airport: '/airport-flights',
      position: '/position',
      manualAlert: '/manual-alert',
      alert: '/alert',
      msg: '/message',
      note: '/note',
      eta: '/eta-history',
      flightPlan: '/latest-flight-plans',
      plannedFlightPlans: 'planned-flight-plans',
      editParam: '/edit-param',
      plumbing: '/aircraft-flight',
      closed: '?closed=true',
      user: '/user/',
      manualReport: '/manual-report',
      surfaceTracking: '/surface-tracking'
    },
    airline: [],
    boundingBox: '',
    fetchOthers: false
  };

  getPlannedFlightPlanParams = function (flight) {
    return '?ori=' + flight.ori + '&dst=' + flight.dst + '&reg=' + flight.acn + '&call-sign=' + flight.flightId + '&flight-origin-date=' + flight.flightOriginDate;
  };

  getPlumbingPath = function () {
    if (_.isFinite(customerId.get())) {
      var url = window.backendPrefix + opts.path.customer + customerId.get() + opts.path.plumbing;
      var params = {};
      if (opts.airline !== []) {
        params.airline = opts.airline;
      }
      if (opts.boundingBox !== '' && opts.fetchOthers) {
        params.bbox = opts.boundingBox;
      }
      return url += Utils.getParamString(params);
    }
  };

  getPath = function () {
    if (_.isFinite(customerId.get())) {
      return window.backendPrefix + opts.path.customer + customerId.get() + opts.path.aircraft;
    }
  };

  getAirportFlightsPath = function () {
    if (_.isFinite(customerId.get())) {
      return window.backendPrefix + opts.path.customer + customerId.get() + opts.path.airport;
    }
  };

  store = new Plumbing({
    headers: opts.headers,
    url: getPlumbingPath
  });

  store.showClosedFlights = function (showClosedFlights) {
    opts.path.plumbing = opts.path.aircraft + (showClosedFlights ? opts.path.closed : '');
    store.refresh();
  };

  store.setAirlinePart = function (airlineArray) {
    opts.airline = airlineArray;
  }

  store.setBoundingBoxPart = function (boundingBoxString) {
    opts.boundingBox = boundingBoxString;
    if (opts.fetchOthers) {
      store.refresh();
    }
  }

  store.setFetchOthers = function (fetchOthers) {
    opts.fetchOthers = fetchOthers;
    store.refresh();
  }

  customerId.on('update', function (oldId, newId) {
    if (oldId && oldId !== newId) {
      if (_.isUndefined(newId)) {
        store.adapter.stop();
      } else {
        store.refresh();
      }
    }
  }, this);

  this.setCustomerPart = function (customer) {
    opts.path.customer = customer;
  };

  this.setAircraftPart = function (aircraft) {
    opts.path.aircraft = aircraft;
  };

  store.fetchAircraftPositionsHistory = function (flightId) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + flightId + opts.path.position, {
        credentials: 'include'
      }).then(function (response) {
        response.json().then(function (json) {
          resolve(json);
        }, function () {
          resolve([]);
        });
      });
    }.bind(this));
  };

  store.fetchAircraftPositionsForAirport = function (airportIcao) {
    return new Promise(function (resolve, reject) {
      window.fetch(window.backendPrefix + "/airport/" + airportIcao + opts.path.surfaceTracking , {
        credentials: 'include'
      }).then(function (response) {
        response.json().then(function (json) {
          resolve(json);
        }, function () {
          resolve([]);
        });
      });
    }.bind(this));
  };

  store.fetchAircraftFlightPlan = function (flightId) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + flightId + opts.path.flightPlan, {
        credentials: 'include'
      }).then(function (response) {
        response.json().then(function (json) {
          resolve(json);
        }, function () {
          resolve([]);
        });
      });
    }.bind(this));
  };

  store.fetchAircraftPlannedFlightPlans = function (flight) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + opts.path.plannedFlightPlans + getPlannedFlightPlanParams(flight), {
        credentials: 'include'
      }).then(function (response) {
        response.json().then(function (json) {
          resolve(json);
        }, function () {
          resolve([]);
        });
      });
    }.bind(this));
  };

  store.fetchAircraftFlightEtaHistory = function (flightId) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + flightId + opts.path.eta, {
        credentials: 'include'
      }).then(function (response) {
        response.json().then(function (json) {
          resolve(json);
        }, function () {
          resolve([]);
        });
      });
    }.bind(this));
  };

  store.addFlightManualAlert = function (flightId, alert) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + flightId + opts.path.manualAlert, {
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(alert)
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, reject);
        } else {
          reject(response.statusText);
        }
      }, reject);
    }.bind(this));
  };

  store.closeFlightManualAlert = function (flightId, alertId) {
    return window.fetch(getPath() + '/' + flightId + opts.path.manualAlert + '/' + alertId, {
      method: 'delete',
      credentials: 'include'
    });
  };

  store.closeFlight = function (flightId) {
    return window.fetch(getPath() + '/' + flightId, {
      method: 'delete',
      credentials: 'include'
    });
  };

  store.acknowledgeAlert = function (flightId, alert) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + flightId + opts.path.alert + '/' + alert.id + '?ack', {
        method: 'put',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(alert)
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, reject);
        } else {
          reject(response.statusText);
        }
      }, reject);
    }.bind(this));
  };

  store.sendMessage = function (flightId, msg) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + flightId + opts.path.msg, {
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(msg)
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, reject);
        } else {
          reject(response.statusText);
        }
      }, reject);
    }.bind(this));
  };

  store.acknowledgeMessage = function (flightId, msg) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + flightId + opts.path.msg + '/' + msg.id + '?ack', {
        method: 'put',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(msg)
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, reject);
        } else {
          reject(response.statusText);
        }
      }, reject);
    }.bind(this));
  };

  store.addNote = function (flightId, note) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + flightId + opts.path.note, {
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(note)
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, reject);
        } else {
          reject(response.statusText);
        }
      }, reject);
    }.bind(this));
  };

  store.setNote = function (flightId, note) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + flightId + opts.path.note + '/' + note.id, {
        method: 'put',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(note)
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, reject);
        } else {
          reject(response.statusText);
        }
      }, reject);
    }.bind(this));
  };

  store.removeNote = function (flightId, note) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + flightId + opts.path.note + '/' + note.id, {
        method: 'delete',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(note)
      }).then(function (response) {
        if (response.ok) {
          resolve(response);
        } else {
          reject(response.statusText);
        }
      }, reject);
    }.bind(this));
  };

  store.fetchAirportFlightsOnArrival = function (airportId) {
    return new Promise(function (resolve, reject) {
      window.fetch(getAirportFlightsPath() + '/' + airportId, {
        credentials: 'include'
      }).then(function (response) {
        response.json().then(function (json) {
          resolve(json);
        }, function () {
          resolve([]);
        });
      });
    }.bind(this));
  };

  store.editParam = function (flightId, newParam, type) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + flightId + opts.path.editParam + '/' + type, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newParam)
      });
    }.bind(this));
  };

  this.$get = [function flightStoreFactory() {
    return store;
  }];

  store.addManualReport = function (flightManualReport) {
    return new Promise(function (resolve, reject) {
      var path = window.backendPrefix + opts.path.customer + customerId.get() +opts.path.user+flightManualReport.userId+opts.path.manualReport;
      window.fetch(path, {
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(flightManualReport)
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, reject);
        } else {
          reject(response);
        }
      }, reject);
    }.bind(this));
  };

}]);
