App.controller('sideLayersInfoController', ['$uibModal', '$scope', function ($uibModal, $scope) {
  'use strict';
  var vm = this;
  vm.closePopup = closePopup;


  $scope.$on('popup:info', function (event, args) {
    setPopupContent(args);
  });

  this.toggleSelection = function (featureId) {
    if (this.selectedId === featureId) {
      this.selectedId = null;
    } else {
      this.selectedId = featureId;
    }
    this.onFeatureSelected({
      selected: this.selectedId
    });
  }

  function setPopupContent(data) {
    if (data) {
      var tabsTitles = _.keys(data);
      if (tabsTitles.length > 0) {
        vm.tabs = tabsTitles.map(function (key, i) {
          vm.opened = true;
          vm.onOpen();
          var tabDef = {
            title: key,
            content: '',
            features: [],
            loading: true,
            key: key
          };

          var tabData = data[key];
          tabData
            .then(function (result) {
              _.assign(tabDef, result, {
                loading: false
              });
              tabDef.title = result.title || key;
              if (tabDef.content.length === 0 && tabDef.features.length === 0) {
                tabDef.content = "No available data";
              }
              $scope.$applyAsync();
            })
            .catch(function (error) {
              tabDef.content = "No available data";
              console.log("error", error);
              tabDef.loading = false;
            });

          return tabDef;
        });
        vm.onFeatureSelected({
          selected: null
        });
      } else {
        closePopup();
      }
    } else {
      closePopup();
    }

  }

  function closePopup() {
    vm.onFeatureSelected({
      selected: null
    });
    vm.opened = false;
    vm.onClosed();
  }
}]);

App.component('sideLayersInfoComponent', {
  bindings: {
    onFeatureSelected: '&',
    onClosed: '&',
    onOpen: '&',
  },
  controller: 'sideLayersInfoController',
  controllerAs: 'ctrl',
  templateUrl: "views/partials/map/widgets/side-layers-info.template.html",
});
