(function (global) {
  'use strict';

  var notDefined = 'Not Defined';

  /**
   * returns the low limit with the high limit
   * @param {string} loTyp
   * @param {number} loLim
   * @param {string} hiTyp
   * @param {number} hiLim
   */
  function hiLoLimit(props) {
    var lowLimit = '';

    var loTyp = _.toLower(props.lo_typ);
    var loLim = props.lo_lim;

    //LOW
    if (loTyp == 'altitude' && loLim != 2147483647)
      lowLimit = loLim + 'ft';
    else if (loTyp == 'flightlevel' && loLim != 2147483647)
      lowLimit = 'FL' + _.toString(loLim / 100);
    else if (loTyp == 'unknown' && loLim == 2147483647)
      lowLimit = 'GND';
    else
      lowLimit = notDefined;

    var highLimit = '';

    var hiTyp = _.toLower(props.hi_typ);
    var hiLim = props.hi_lim;

    //HIGH
    if (hiTyp == 'altitude' && hiLim != 2147483647)
      highLimit = hiLim + ' ft';
    else if (hiTyp == 'flightlevel' && hiLim != 2147483647)
      highLimit = 'FL' + _.toString(hiLim / 100);
    else if (hiTyp == 'unlimited' && hiLim == 2147483647)
      highLimit = 'UNL';
    else
      highLimit = notDefined;

    // RETURN LOW - HIGH
    return lowLimit + ' - ' + highLimit;
  }
  /**
   * returns a string for the corresponding level
   * @param {string} level
   */
  function displayLevel(level) {
    if (level == 'B') return 'High and Low';
    if (level == 'L') return 'Low';
    if (level == 'H') return 'High';
    return notDefined;
  }

  /**
   * returns a string for the corresponding turn
   * @param {string} turn
   */
  function displayTurn(turn) {
    if (turn == 'R') return 'Right Turn';
    if (turn == 'L') return 'Left Turn';
    return notDefined;
  }

  global.HoldingsFeatureFormatter = {
    format: function (adFeature, projection) {
      var props = adFeature.properties || {};

      var properties = [{
          label: 'Feature',
          value: "<b>ENROUTE HOLDING</b>"
        }, {
          label: 'Fix Ident',
          value: props.fixident || notDefined
        },
        {
          label: 'Level',
          value: displayLevel(props.level)
        },
        {
          label: 'Country',
          value: props.fixcountry || notDefined
        },
        {
          label: 'Turn',
          value: displayTurn(props.turn)
        },
        {
          label: 'Inbd. Course',
          value: props.inmagcrse || notDefined
        },
        {
          label: 'Speed',
          value: (props.speed < 0) ? notDefined : _.toString(_.round(props.speed)) + ' kt'
        },
        {
          label: 'Leg Time',
          value: (props.leg_time == -1) ? notDefined : _.toString(_.round(props.leg_time, 1)) + ' min'
        },
        {
          label: 'Leg Length',
          value: (props.leg_length <= 0) ? notDefined : _.toString(_.round(props.leg_length)) + ' nm'
        },
        {
          label: 'Min/Max Alt',
          value: hiLoLimit(props)
        }

      ];

      return {
        type: 'HLDG',
        title: props.fixident,
        subtitle: props.fixcountry,
        properties: properties,
      };

    }
  };
}(window));
