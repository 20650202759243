/*jslint browser: true, nomen: true */
/*global _ */

(function (global) {
  'use strict';

  var customMapInfoControl = function customMapInfoControl() {
    var element = document.createElement('div');
    element.className = 'zoom-info-control ol-unselectable ol-control';
    ol.control.Control.call(this, {
      element: element,
    });
  };
  ol.inherits(customMapInfoControl, ol.control.Control);

  customMapInfoControl.prototype.updateZoomData = function () {
    // calculate scale
    var resolution = this.getMap().getView().getResolution();
    var units = this.getMap().getView().getProjection().getUnits();
    var dpi = 25.4 / 0.28;
    var mpu = ol.proj.METERS_PER_UNIT[units];
    var scale = resolution * mpu * 39.37 * dpi;
    if (scale >= 9500 && scale <= 950000) {
      scale = Math.round(scale / 1000) + "K";
    } else if (scale >= 950000) {
      scale = Math.round(scale / 1000000) + "M";
    } else {
      scale = Math.round(scale);
    }
    // create map infos content
    var text = "<span >Zoom level : " + Math.round(this.getMap().getView().getZoom()) + "</span>";
    text += "<span >Resolution : " + Math.round(resolution) + "</span>";
    text += "<span >Scale = 1 : " + scale + "</span>";
    // set map infos  content
    var zoomLevelDiv = this.element;
    zoomLevelDiv.innerHTML = text;
  };

  customMapInfoControl.prototype.setMap = function (map) {
    if (this.getMap()) {
      this.getMap().off('change:resolution', this.updateZoomData, this);
    }
    ol.control.Control.prototype.setMap.apply(this, arguments);
    map.getView().on('change:resolution', this.updateZoomData, this);
    this.updateZoomData();
  };




  global.mapControl = {
    zoomInfoControl: customMapInfoControl
  };

}(window));
