(function (global) {
  'use strict';

  var notDefined = 'Not Defined';

  var categoryLevel = {
    'H': 'High',
    'A': 'High & Low',
    'L': 'Low',
  };

  function calculateFromTo(from, to) {
    return (from || notDefined) + '-' + (to || notDefined);
  }


  function calculateDirection(from, to, direction) {
    var result = "";
    // Direction: IF {DIRECTION} = "F" show "One way " {FROM_IDENT} " -> " {TO_IDENT}, e.g. "One way LALOM -> RR"$
    if (direction === "F") {
      result = "One way " + calculateFromTo(from, to);
    }
    //   ELIF {DIRECTION} = " B" show "One way " {TO_IDENT} " -> " {FROM_IDENT}, e.g. "One way RR -> LALOM"
    else if (direction === "B") {
      result = "One way " + calculateFromTo(to, from);
    }
    //   ELSE show "No restriction"
    else {
      result = "No restriction";
    }
    return result;
  }

  function calculateFwdCourse(courseF, to) {
    var result;
    // FWD Course: IF [COURSE_F] = empty or null and [TO_IDENT] = empty or null show "->"
    if ((!courseF) && (!to)) {
      result = "->";
      // ELIF [COURSE_F] = empty or null show "-> " [TO_IDENT], where [COURSE_F] is displayed with 3 significant digits, e.g., "009"
    } else if ((!courseF) && (to)) {
      result = "-> " + to;
      // ELIF [TO_IDENT] = empty or null show [COURSE_F] "deg ->"
    } else if ((!to) && (courseF)) {
      result = courseF + "deg ->";
      // ELSE show [COURSE_F] "deg -> " [TO_IDENT], where [COURSE_F] is displayed with 3 significant digits, e.g., "009deg -> RR"
    } else {
      result = courseF + "deg -> " + to;
    }
    return result;
  }

  function calculateBwdCourse(courseB, from) {
    var result;
    // BWD Course: IF [COURSE_B] = empty or null and [FROM_IDENT] = empty or null show "->"
    if ((!courseB) && (!from)) {
      result = "->";
      // ELIF [COURSE_B] = empty or null show "-> " [FROM_IDENT], where [COURSE_B] is displayed with 3 significant digits, e.g., "009"
    } else if ((!courseB) && (from)) {
      result = "-> " + from;
      // ELIF [FROM_IDENT] = empty or null show [COURSE_B] "deg ->"
    } else if ((!from) && (courseB)) {
      result = courseB + "deg ->";
      // ELSE show [COURSE_B] "deg -> " [FROM_IDENT], where [COURSE_B] is displayed with 3 significant digits, e.g., "009deg -> RR"
    } else {
      result = courseB + "deg -> " + from;
    }
    return result;
  }

  //   Here is the new rule for area value (narrow):
  // else If(direction == “B”) then from_ident leftward arrow to_ident
  // else from_ident bi-directional arrow to_ident
  // it means:
  // if ( direction == “F”) then [FROM_IDENT] -> [TO_IDENT]
  // elif (DIRECTION] == "B") then [TO_IDENT] -> [FROM_IDENT]
  // else "[FROM_IDENT] <-> [TO_IDENT]"

  // ( direction == “F”) and (direction == “B”) are done, the double arrow has to be done.

  function calculateArea(direction, from, to) {
    var result = "";
    // if [DIRECTION] == "F" then "[FROM_IDENT] -> [TO_IDENT]"
    if (direction === "F") {
      result = ((from || notDefined) + " -> " +
        (to || notDefined));
    }
    // elif [DIRECTION] == "B" then "[TO_IDENT] -> [FROM_IDENT]"
    else if (direction === "B") {
      result = ((from || notDefined) + " <- " +
        (to || notDefined));
    }
    // else "[FROM_IDENT] unknown [TO_IDENT]"
    else {
      result = ((from || notDefined) + " <-> " +
        (to || notDefined));
    }
    return result;
  }

  function calculateLowLimits(lowLimit, lowLimitType) {
    var result;
    //   IF {LO_LIM_TYP} = "Altitude" AND {LO_LIM} > -1 set {LOW_LIMIT} = {LO_LIM} "ft", e.g "3000ft"
    if ((lowLimitType === "Altitude") && (lowLimit > -1)) {
      result = lowLimit + "ft";
    }
    //     ELIF {LO_LIM_TYP} = "Flightlevel" AND {LO_LIM} > -1 set {LOW_LIMIT} = "FL" {LO_LIM}/100, e.g "FL195"
    else if ((lowLimitType === "FlightLevel") && (lowLimit > -1)) {
      result = "FL" + Math.round(lowLimit / 100);
    }
    //     ELIF {LO_LIM_TYP} = "NotEstablished" AND {LO_LIM} = -1 set {LOW_LIMIT} = "Not established", e.g "Not established"
    else if ((lowLimitType === "NotEstablished") && (lowLimit === -1)) {
      result = "Not established";
      //     ELSE set {LOW_LIMIT} = "Not defined"
    } else {
      result = notDefined;
    }
    return result;
  }

  function calculateHighLimits(highLimit, highLimitType) {
    var result;
    //   IF {HI_LIM_TYP} = "Altitude" AND {HI_LIM} > -1 set {HIGH_LIMIT} = {HI_LIM} "ft", e.g "9000ft"
    if ((highLimitType === "Altitude") && (highLimit > -1)) {
      result = highLimit + "ft";
    }
    //     ELIF {HI_LIM_TYP} = "Flightlevel" AND {HI_LIM} > -1 set {HIGH_LIMIT} = "FL" {HI_LIM}/100, e.g "FL350"
    else if ((highLimitType === "FlightLevel") && (highLimit > -1)) {
      result = "FL" + Math.round(highLimit / 100);
    }
    //     ELIF {HI_LIM_TYP} = "Unlimited" AND {HI_LIM} = -1 set {HIGH_LIMIT} = "UNL", e.g "UNL"
    else if ((highLimitType === "Unlimited") && (highLimit === -1)) {
      result = "UNL";
      //     ELSE set {HIGH_LIMIT} = "Not defined"
    } else {
      result = notDefined;
    }
    return result;
  }


  global.AirwayFeatureFormatter = {
    format: function (adFeature, projection) {
      var props = adFeature.properties || {};


      var properties = [{
          label: 'Feature',
          value: '<b>AIRWAY</b>'
        },
        {
          label: 'Level',
          value: categoryLevel[props.level] || notDefined
        },
        {
          label: 'Ident',
          value: props.awy_ident || notDefined
        },
        {
          label: 'Type',
          value: props.awy_type || notDefined
        },
        {
          label: 'Area',
          value: props.awy_area || notDefined
        },
        {
          label: 'From-To',
          value: calculateFromTo(props.from_ident, props.to_ident)
        },
        {
          label: 'Distance',
          value: props.distance ? props.distance + "nm" : notDefined
        },
        {
          label: 'Direction',
          value: calculateDirection(props.from_ident, props.to_ident, props.direction)
        },
        {
          label: 'FWD Course',
          value: calculateFwdCourse(props.course_f, props.to_ident)
        },
        {
          label: 'BWD Course',
          value: calculateBwdCourse(props.course_b, props.from_ident)
        },
        {
          label: 'MOCA',
          value: props.moca ? props.moca * 100 + "ft" : notDefined
        },
        {
          label: 'Limit Class',
          value: props.lo_lim_cls || notDefined
        },
        {
          label: 'Limits',
          value: calculateLowLimits(props.lo_lim, props.lo_lim_typ) + "-" + calculateHighLimits(props.hi_lim, props.hi_lim_typ)
        },
      ];

      return {
        type: 'AWY',
        title: props.awy_ident || '–',
        subtitle: calculateArea(props.direction, props.from_ident, props.to_ident),
        properties: properties,
      };

    }
  };
}(window));
