/*jslint browser: true, plusplus:true, nomen:true*/
/*global _, angular, ol, App, $, Validator, UserService */

App
  .controller('FlightAddManualReportCtrl', [
    '$rootScope',
    '$scope',
    'flight',
    'flightNgStore',
    'customerNgStore',
    'aircraftRestService',
    '$uibModalInstance',
    'alertService',


    function FlightAddManualReportCtrl($rootScope, $scope, flight, flightNgStore, customerNgStore, aircraftRestService, $uibModalInstance, alertService) {
      'use strict';

      const SOURCE_NFP = 'NAVBLUE_FLIGHT_PLANNING';
      const UNLINK_MSG = 'This will unlink linked flight plans. Are you sure ?';
      const UNLINK_TITLE = 'Unlink flight plan'

      this.flightReport = {};
      this.minDate = moment().add(-1, 'day');
      this.maxDate = moment();
      this.minDateEta = moment();
      this.maxDateEta = moment().add(1, 'day');

      $scope.flight = flight;
      $scope.NFPPlanIds = [];
      getNFPFlightPlans(flight);
      this.flightReport.addressModeS = flight.addrModeS;

      if (flight.originAirport && flight.originAirport.icao) {
        this.flightReport.departureAirportIcaoIata = flight.originAirport.icao;
      }

      if (flight.destinationAirport && flight.destinationAirport.icao) {
        this.flightReport.arrivalAirportIcaoIata = flight.destinationAirport.icao;
      }

      this.flightReport.userId = $scope.user.id;

      this.flightReport.unitFuel = "lb";
      this.flightReport.unitAltitude = "fl";
      this.flightReport.isExitPoint = "false";
      this.flightReport.userFuelEnduranceDays = 0;

      this.flightReport.clientTimeZone = moment.tz.guess();

      this.flightReport.dateRadio = moment();

      // calculate local time
      var date = new Date();
      var offset = date.getTimezoneOffset();
      var now = moment();
      now.add(offset * -1, 'minutes');
      this.flightReport.userTimeRadio = now;

      this.flightReport.callSign = flight.flightId;
      this.flightReport.flightNumber = flight.flightNumber;

      var timeZones = moment.tz.names();
      this.flightReport.offsetTmz = timeZones;

      // to make inputs in position tab required if one input of this tab is not empty
      this.isOneInputInPositionFilled = function () {
        try {
          if (this.flightReport.positionTime || this.flightReport.positionLatitude || this.flightReport.positionLongitude || this.flightReport.positionSpeed ||
            this.flightReport.positionTrack || this.flightReport.altitude) {
            return true
          } else {
            return false;
          }
        } catch (e) {
          return false;
        }

      }

      this.isFormValid = function () {
        if ($scope.formFlightManaulReport.$valid) {
          return true;
        } else {
          return false
        };
      };

      //(to delete)
      // validate time picker's inputs
      this.inputsValidator = function () {
        if (this.flightReport.userTimeRadio && !(moment(this.flightReport.userTimeRadio, "DD/MM/YYYY HH:mm:ss").isValid())) {
          this.flightReport.userTimeRadio = null;
          return false;
        }
        if (this.flightReport.blockStartTime != null && !(moment(this.flightReport.blockStartTime, "DD/MM/YYYY HH:mm:ss").isValid())) {
          this.flightReport.blockStartTime = null;
          return false;
        }
        if (this.flightReport.taxiStartTime != null && !(moment(this.flightReport.taxiStartTime, "DD/MM/YYYY HH:mm:ss").isValid())) {
          return false;
        }
        if (null != this.flightReport.actualTimeDeparture && !(moment(this.flightReport.actualTimeDeparture, "DD/MM/YYYY HH:mm:ss").isValid())) {
          return false;
        }
        if (this.flightReport.outTime != null && !(moment(this.flightReport.outTime, "DD/MM/YYYY HH:mm:ss").isValid())) {
          return false;
        }
        if (this.flightReport.offTime != null && !(moment(this.flightReport.offTime, "DD/MM/YYYY HH:mm:ss").isValid())) {
          return false;
        }
        if (this.flightReport.blockStopTime != null && !(moment(this.flightReport.blockStopTime, "DD/MM/YYYY HH:mm:ss").isValid())) {
          return false;
        }
        if (this.flightReport.taxiStopTime != null && !(moment(this.flightReport.taxiStopTime, "DD/MM/YYYY HH:mm:ss").isValid())) {
          return false;
        }
        if (this.flightReport.actualArrivalTime != null && !(moment(this.flightReport.actualArrivalTime, "DD/MM/YYYY HH:mm:ss").isValid())) {
          return false;
        }
        if (this.flightReport.estimatedArrivalTime != null && !(moment(this.flightReport.estimatedArrivalTime, "DD/MM/YYYY HH:mm:ss").isValid())) {
          return false;
        }
        if (this.flightReport.onTime != null && !(moment(this.flightReport.onTime, "DD/MM/YYYY HH:mm:ss").isValid())) {
          return false;
        }
        if (this.flightReport.inTime != null && !(moment(this.flightReport.inTime, "DD/MM/YYYY HH:mm:ss").isValid())) {
          return false;
        }
        if (this.flightReport.userFuelEndurance != null && !(moment(this.flightReport.userFuelEndurance, "DD/MM/YYYY HH:mm").isValid())) {
          return false;
        }
        if (this.flightReport.timeEntry != null && !(moment(this.flightReport.timeEntry, "DD/MM/YYYY HH:mm:ss").isValid())) {
          return false;
        }
        if (this.flightReport.timeExit != null && !(moment(this.flightReport.timeExit, "DD/MM/YYYY HH:mm:ss").isValid())) {
          return false;
        }
        if (this.flightReport.positionTime != null && !(moment(this.flightReport.positionTime, "DD/MM/YYYY HH:mm:ss").isValid())) {
          return false;
        }

        return true;
      }

      this.changeUnitFuel = function (unit) {
        if (unit === "lb") {
          this.flightReport.unitFuel = 'lb';
        } else if (unit === "kg") {
          this.flightReport.unitFuel = 'kg';
        }
      }

      this.changeUnitAltitude = function (unit) {
        if (unit === "fl") {
          this.flightReport.unitAltitude = 'fl';
        } else if (unit === "feet") {
          this.flightReport.unitAltitude = 'feet';
        }
      }

      // calculate time according to time zone
      function calculateTimeWithTimeZone(utcTime, timeZone) {
        var time;
        var localTime = moment.utc(utcTime).format();
        var localTimeWithTimeZone = moment(localTime).tz(timeZone);

        var date = localTimeWithTimeZone.format('YYYY-MM-DD HH:mm Z');
        var offset = moment.parseZone(date).utcOffset();

        localTimeWithTimeZone.add(offset * -1, 'minutes');
        time = localTimeWithTimeZone;
        // var unix = moment(time).unix();
        return time;
      }

      function getNFPFlightPlans(flight) {
        if (flight.isPlanned) {
            return;
        }

        var flightPlanIds = [];
        flightNgStore.getAircraftFlightPlan(flight.id).then(function (data) {
             if (_.isEmpty(data)) {
               // do nothing
             } else {
               _.forEach(data, function (flightPlan) {
                   if (flightPlan.source === SOURCE_NFP) {
                       flightPlanIds.push(flightPlan.flightPlanXmlId)
                   }
               });
             }

             $scope.NFPPlanIds = flightPlanIds;
        });
      }

      // verify if the moment was passed or not, if !true subtract 1 day
      this.verifIfMomementInTheFuture = function (momentTime) {
        var now = moment();
        if (now > momentTime) {
          return momentTime;
        } else {
          momentTime.add(-1, 'day');
          return momentTime;
        }
      }

      // angular moment time picker lib return times in string format, this function convert it to moments objects
      this.convertInputsTimeToMoments = function (flightReport) {
        var inputTime;
        if (flightReport.userTimeRadio) {
          inputTime = moment(flightReport.dateRadio + " " + flightReport.userTimeRadio, "DD/MM/YYYY HH:mm:ss");
          flightReport.manualEntryDate = this.verifIfMomementInTheFuture(inputTime);
        }

        if (flightReport.blockStartTime) {
          inputTime = moment(flightReport.dateRadio + " " + flightReport.blockStartTime, "DD/MM/YYYY HH:mm:ss");
          flightReport.blockStartTime = this.verifIfMomementInTheFuture(inputTime);
        }

        if (flightReport.taxiStartTime) {
          inputTime = moment(flightReport.dateRadio + " " + flightReport.taxiStartTime, "DD/MM/YYYY HH:mm:ss");
          flightReport.taxiStartTime = this.verifIfMomementInTheFuture(inputTime);
        }

        if (flightReport.actualTimeDeparture) {
          inputTime = moment(this.flightReport.dateRadio + " " + flightReport.actualTimeDeparture, "DD/MM/YYYY HH:mm:ss");
          flightReport.actualTimeDeparture = this.verifIfMomementInTheFuture(inputTime);
        }

        if (flightReport.outTime) {
          inputTime = moment(flightReport.dateRadio + " " + flightReport.outTime, "DD/MM/YYYY HH:mm:ss");
          flightReport.outTime = this.verifIfMomementInTheFuture(inputTime);
        }

        if (flightReport.offTime) {
          inputTime = moment(flightReport.dateRadio + " " + flightReport.offTime, "DD/MM/YYYY HH:mm:ss");
          flightReport.offTime = this.verifIfMomementInTheFuture(inputTime);
        }

        if (flightReport.blockStopTime) {
          inputTime = moment(flightReport.dateRadio + " " + flightReport.blockStopTime, "DD/MM/YYYY HH:mm:ss");
          flightReport.blockStopTime = this.verifIfMomementInTheFuture(inputTime);
        }

        if (flightReport.taxiStopTime) {
          inputTime = moment(flightReport.dateRadio + " " + flightReport.taxiStopTime, "DD/MM/YYYY HH:mm:ss");
          flightReport.taxiStopTime = this.verifIfMomementInTheFuture(inputTime);
        }

        if (flightReport.actualArrivalTime) {
          inputTime = moment(flightReport.dateRadio + " " + flightReport.actualArrivalTime, "DD/MM/YYYY HH:mm:ss");
          flightReport.actualArrivalTime = this.verifIfMomementInTheFuture(inputTime);
        }

        if (flightReport.estimatedArrivalTime) {
          inputTime = moment(flightReport.dateRadio + " " + flightReport.estimatedArrivalTime, "DD/MM/YYYY HH:mm:ss");
          flightReport.estimatedArrivalTime = this.verifIfMomementInTheFuture(inputTime);
        }

        if (flightReport.onTime) {
          inputTime = moment(flightReport.dateRadio + " " + flightReport.onTime, "DD/MM/YYYY HH:mm:ss");
          flightReport.onTime = this.verifIfMomementInTheFuture(inputTime);
        }

        if (flightReport.inTime) {
          inputTime = moment(flightReport.dateRadio + " " + flightReport.inTime, "DD/MM/YYYY HH:mm:ss");
          flightReport.inTime = this.verifIfMomementInTheFuture(inputTime);
        }

        if (flightReport.timeEntry) {
          inputTime = moment(flightReport.dateRadio + " " + flightReport.timeEntry, "DD/MM/YYYY HH:mm:ss");
          flightReport.timeEntry = this.verifIfMomementInTheFuture(inputTime);
        }

        if (flightReport.timeExit) {
          inputTime = moment(flightReport.dateRadio + " " + flightReport.timeExit, "DD/MM/YYYY HH:mm:ss");
          flightReport.timeExit = this.verifIfMomementInTheFuture(inputTime);
        }

        if (flightReport.positionTime) {
          inputTime = moment(flightReport.dateRadio + " " + flightReport.positionTime, "DD/MM/YYYY HH:mm:ss");
          flightReport.positionTime = this.verifIfMomementInTheFuture(inputTime);
        }

        if (flightReport.userFuelEndurance) {
          flightReport.userFuelEndurance = moment(flightReport.dateRadio + " " + flightReport.userFuelEndurance, "DD/MM/YYYY HH:mm:ss");
        }

        return flightReport;

      }

      this.addManualReport = function (flightReport) {
        flightNgStore.addManualReport(flightReport)
          .then(function () {
            // alertService
            //   .message('Save', 'Manual report is successfuly saved');
            $scope.errorText = null;
            // $('.input-manual-report-callsign').removeClass('border-color-red');
            this.cancel();
          }.bind(this))
          .catch(function (result) {
            result.json().then(function (errors) {
              var msg = errors.map(function (e) {
                return ErrorCode.getMessage(e.code, [e.values.join('<br>')]);
              });
              var level = (result.status == 409) ? 'warning' : 'error';
              var errorText = msg.join(',<br>');
              $scope.errorText = errorText;
              $scope.$applyAsync();
            });
          })
      }.bind(this);

      this.submit = function () {
        var inputTime;
        var now = moment();

        this.flightReport = this.convertInputsTimeToMoments(this.flightReport);

        // convert time according to time zone
        this.flightReport.manualEntryDate = calculateTimeWithTimeZone(this.flightReport.manualEntryDate, this.flightReport.clientTimeZone);

        // transform fuel endurance to minutes
        if (this.flightReport.userFuelEndurance) {
          var m = moment(this.flightReport.userFuelEndurance);
          var minutes = (m.hour() * 60) + m.minute();
          this.flightReport.fuelEndurance = minutes;
          if (this.flightReport.userFuelEnduranceDays != 0) {
            this.flightReport.fuelEndurance += this.flightReport.userFuelEnduranceDays * 60 * 24
          }
        } else if (this.flightReport.userFuelEnduranceDays != 0) {
          this.flightReport.fuelEndurance = this.flightReport.userFuelEnduranceDays * 60 * 24
        }

        // convert fuel to KG
        if (this.flightReport.unitFuel === "lb" && this.flightReport.userFuelOnBoard != null) {
          this.flightReport.fuelOnBoard = this.flightReport.userFuelOnBoard / 2.2046226218488
        } else {
          this.flightReport.fuelOnBoard = this.flightReport.userFuelOnBoard;
        }
        // convert altitude to feet
        if (this.flightReport.unitAltitude === "fl" && this.flightReport.altitude != null) {
          this.flightReport.altitude = this.flightReport.altitude * 100
        } else {
          this.flightReport.altitude = this.flightReport.altitude;
        }

        // validation and verification of callsign if was changed
        if (this.isFormValid() && this.flightReport.callSign === flight.flightId) {
          this.addManualReport(this.flightReport);
          // if callsign has changed
        } else if (this.isFormValid() && this.flightReport.callSign != flight.flightId) {
          alertService
            .confirm('Callsign was changed', 'The callsign was changed, the current flight will be closed to open a new one with the new callsign, do you confirm that ?')
            .then(function () {
              // if user click on "yes"
              this.addManualReport(this.flightReport);
              this.cancel();
              // if user click on "no"
            }.bind(this)).catch(function () {
              $('.manual-report-input-callsign').addClass('border-color-red');
              $scope.errorText = "The callsign has changed";
            }.bind(this));
        } else {
          $('.form-flight-manual-report').addClass('shake animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
            $('.modal-dialog').removeClass('shake animated');
          });
          return;
        }
      }

      // display entry or exit point time
      this.setInputToShow = function () {
        if (this.flightReport.isExitPoint) {
          this.flightReport.isExitPoint = false;
          this.flightReport.timeExit = ''
        } else {
          this.flightReport.isExitPoint = true;
          this.flightReport.timeEntry = ''
        }

      }

      // Cancel action
      this.cancel = function cancelItem() {
        var form = document.getElementById("formFlightManaulReport"),
          elements = form.elements,
          i;
        for (i = 0; i < elements.length; ++i) {
          elements[i].required = false;
        }
        $uibModalInstance.close();
      };


      this.$dismiss = function () {
      }

      $scope.$dismiss = function () {
      }

      this.unlinkPlannedFlight = function() {
          alertService.confirm(UNLINK_TITLE, UNLINK_MSG, 'Yes', 'Cancel').then( function() {
              aircraftRestService.unassociatePlannedFlightPlansWithFlight(customerNgStore.customer.id, $scope.NFPPlanIds, $scope.flight.id).then((result) => {
                   if (result instanceof Error) {
                      alertService.error(result.name, result.message);
                   }
                   $scope.$close(result);
              });
          });
       }

    }
  ]);
