/*jslint browser: true, plusplus:true */
/*global ol, App */

(function (global) {
  'use strict';

  App.constant('mapPreferences', {
    maps: {
      navblue: {
        name: 'navblue',
        title: 'NAVBLUE',
        type: 'base',
        preload: 3,
        source: new ol.source.TileWMS({
          crossOrigin: 'anonymous',
          url: window.navblueGeoserver + '/geoserver/airline1/wms',
          params: {
            'LAYERS': 'world:world2',
            'TILED': true
          },
          serverType: 'geoserver'
        })
      }
    },
      // has to be outside of the 'maps' field above as the user settings menu will read all maps inside of it
      airportMaps: {
          url: window.osmTileServer,
          attributions: '<p style="font-size: 12px;">&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors</p>'
      },
      defaultColors: {
          warning: '#FF0000',
          caution: '#FF6600',
          myfleet: '#0077C8',
          tracked: '#1DB1D2',
          other: '#B3B3B3',
          closed: '#DDDDDD',
          planned: '#000000'
      },
      majorCities: {
        name: 'navblue',
        title: 'NAVBLUE',
        type: 'base',
        preload: 3,
        source: new ol.source.TileWMS({
          crossOrigin: 'anonymous',
          url: window.navblueGeoserver + '/geoserver/airline1/wms',
          params: {
            'LAYERS': 'airline1:populated_places'
          },
          serverType: 'geoserver'
        })
      }
  });

  App.constant('seWeatherRefs', {
    seWeather: {
        lightningStrokesRef: [5, 15, 30, 60],
        flightLevelsRef: ['050', '100', '125', '180', '240', '270', '300', '320', '340', '370', '390', '450', '530'],
        edrTurbHoursRef: ['Current', '03', '06', '09', '12', '15', '18', '21', '24'],
        windFlightLevelsRef: ['010', '030', '050', '100', '180', '240','300','340','390','450','530'],
        windFlightLevelsTimeEnabledRef: ['050', '100', '180', '240','300','340','390','450','530'],
        ashForceastHoursRef:   ['Current','06', '12', '18'],
        sixStepsHoursRef: ['06', '12', '18','24'],
  	    globalHoursRef:  ['06', '09','12','15','18','21','24','27','30','33','36'],
        namHoursRef: ['01', '02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18'],
        eighteenHoursRef: ['00', '01', '02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18'],
  	    flightLevelsRefIcing: ['050', '100', '140', '180', '240', '270', 'MAX'],
  	    oneStepHoursRef: ['00', '01', '02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
        thirtyStepMinsRef: ['00','30','60','90','120','150','180','210','240','270','300','330'],
        weatherRadarRef: ['00', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80', '85', '90', '95', '100',
                            '105', '110', '115', '120', '125', '130', '135', '140', '145', '150', '155', '160', '165', '170', '175'],
        flightLevelsMax: ['010','030','050','100','140','180','240','270','300','320','330','340','350','360','370','380','390','400','410','430','450','530'],
        flightLevelShort: ['010','030','050','080','100','140','180','240','270','MAX'],
        aircraftSize: ['SMALL', 'MEDIUM', 'LARGE']
                        
  }
  });

}(window));
