App.controller('navSearchController', ['navLayers', '$scope', '$element', 'navLayersService', function (navLayers, $scope, $element, navLayersService) {
  'use strict';
  // var map;
  var vm = this;
  vm.navLayers = navLayers;
  vm.opened = false;
  vm.loading = false;
  vm.searchFilters = _initFilters();
  vm.searchResults = {};
  vm.enabled = true;
  vm.activeFilter = null; //null is ALL
  vm.searchInputText = "";
  vm.oldSearchInputText = "";
  vm.allTabDataLength = 0;
  vm.originMapCenterCoords = null;
  vm.originMapCenterZoom = null;
  vm.noDataMessage = false;
  vm.tabsContent = [];
  // is used to verify if feature was already clicked (level 2 is selected) when clicking on zoom
  vm.isTitleClicked = false;
  // these 3 vars contains the same value 'featureid' but they are used for differente functions
  vm.selectedId = null;
  vm.featureId = "";
  vm.elementFeatureId = "";

  vm.updateMapCenter = function ($event, feature) {
    vm.toggleSelection(feature);
    if (vm.isTitleClicked && isOpen() && feature.id === vm.elementFeatureId) {
      $event.preventDefault();
      $event.stopPropagation();
    } else {
      vm.elementFeatureId = feature.id;
    }
    vm.isTitleClicked = false;
    vm.centerMap(feature);

  }

  function isOpen() {
    return vm.tabsContent.some(function (item) {
      return item.isOpen;
    });
  }

  function closeLevel2() {
    vm.tabsContent.some(function (item) {
      item.isOpen = false;
    });
  }

  vm.toggleSelectionTitle = function (feature) {
    if (feature == null) {
      return;
    }
    vm.elementFeatureId = feature.id;
    vm.isTitleClicked = true;
    if (isOpen()) {
      if (vm.selectedId) {
        vm.map.getView().setCenter(vm.originMapCenterCoords);
        vm.map.getView().setZoom(vm.originMapCenterZoom);
        vm.featureId = "";
      }
      vm.selectedId = null;
      vm.featureId = "";
    }
    vm.onFeatureSelected({
      selected: vm.selectedId
    });
  }



  vm.toggleSelection = function (featureId) {
    if (vm.selectedId === featureId) {
      vm.selectedId = null;
    } else {
      vm.selectedId = featureId;
    }
    vm.onFeatureSelected({
      selected: vm.selectedId
    });
  }

  function getExtentFromFeature(feature) {
    var arrayOfPositions = [];
    var coordinatesConvertedArray = [];
    if (feature.type === navLayers.staticLayers.firuir.shortLabel) {
      arrayOfPositions = feature.feature.geometry.coordinates[0][0];
    } else if (feature.type === navLayers.highLowLayers.airways.shortLabel) {
      arrayOfPositions = feature.feature.geometry.coordinates[0];
    } else if (feature.type === navLayers.staticLayers.airports.shortLabel || feature.type === navLayers.highLowLayers.waypoints.shortLabel ||
      feature.type === navLayers.highLowLayers.navaids.shortLabel) {
      arrayOfPositions = feature.feature.geometry.coordinates;
    }
    // convert coordinates from EPSG:4326 to EPSG:3857 (case of many coordinates)
    if (Array.isArray(arrayOfPositions[0])) {
      _.forEach(arrayOfPositions, function (current, i) {
        coordinatesConvertedArray[i] = ol.proj.transform(current, 'EPSG:4326', 'EPSG:3857');
      });
      // convert coordinates from EPSG:4326 to EPSG:3857 (case of 1 coordinate)
    } else {
      coordinatesConvertedArray[0] = ol.proj.transform(arrayOfPositions, 'EPSG:4326', 'EPSG:3857');
      // vm.map.getView().setCenter(coordinatesConvertedArray[0]);
    }
    return ol.extent.boundingExtent(coordinatesConvertedArray);
  }

  vm.centerMap = function (feature) {
    if (!isOpen()) {
      vm.originMapCenterCoords = vm.map.getView().getCenter();
      vm.originMapCenterZoom = vm.map.getView().getZoom();
    }

    if (vm.featureId != feature.id) {

      var extent = getExtentFromFeature(feature);
      centerMapOnPoint(extent);

      // zoom is always set to 10 only in the case of fir/uir and airways
      if (feature.type != navLayers.staticLayers.firuir.shortLabel) {
        vm.map.getView().setZoom(10);
      }
      if (feature.type === navLayers.highLowLayers.airways.shortLabel) {
        vm.map.getView().setZoom(8);
      }

      vm.featureId = feature.id;
    } else {
      returnToFirstPositionAndZoom();
    }
  }

  function returnToFirstPositionAndZoom() {
    vm.map.getView().setCenter(vm.originMapCenterCoords);
    vm.map.getView().setZoom(vm.originMapCenterZoom);
    vm.featureId = "";
  }

  function centerMapOnPoint(extent) {
    if (extent) {
      var view = vm.map.getView(),
        size = /** @type {ol.Size} */ (map.getSize()),
        duration = 1000,
        start = +new Date();

      view.animate({
        duration: duration,
        source: /** @type {ol.Coordinate} */ (view.getCenter()),
        start: start
      });
      view.fit(extent, vm.map.getSize());
    }
  };

  function sortResults(results) {
    var sortedResults = [];
    sortedResults = _.sortBy(results, ['distance']);
    sortedResults = _.sortBy(sortedResults, function (feature) {
      return !feature.isExactMatch;
    });
    // sort by text equal/contains, to result with equal should be first

    return sortedResults;
  }


  function computeDistanceFromCenter(features) {
    var mapCenter = vm.map.getView().getCenter();
    var size = vm.map.getSize();
    var mapCenterConverted = ol.proj.transform(mapCenter, 'EPSG:3857', 'EPSG:4326');
    var coordinates = [];
    _.forEach(features, function (currentFeature) {
      var extent = getExtentFromFeature(currentFeature);
      if (extent) {
        var coordinates = ol.extent.getCenter(extent);
        var wgs84Sphere = new ol.Sphere(6378137);
        var convertedCoordinates = ol.proj.transform(coordinates, 'EPSG:3857', 'EPSG:4326');
        var distanceToCenter = wgs84Sphere.haversineDistance(mapCenterConverted, convertedCoordinates);
        var distanceConvToNM = Math.round(distanceToCenter / 1852);
        currentFeature.distance = distanceConvToNM;
      }
    });
    return features;
  }

  vm.doSearch = _.debounce(function () {
    clearSelectedFeature();
    if (vm.searchInputText.length > 2) {
      // display loading spinner
      var activeFilter = vm.activeFilter;
      // clear old results
      vm.tabsContent = [];
      var searchLayers = [activeFilter];
      // send query to geoserver
      if (!activeFilter) {
        searchLayers = vm.searchFilters;
      }
      var pendingSearchs = _.map(searchLayers, function (current, i) {
        return navLayersService
          .requestFeaturesInfoForSearch(vm.map.getView().getProjection(), current, _.toUpper(vm.searchInputText))
          .then(computeDistanceFromCenter)
          .then(function (features) {
            vm.searchResults[current.label] = {
              count: features.length,
              features: features
            };
            return features;
          });
      });
      Promise
        .all(pendingSearchs)
        .then(function (results) {
          if (results == null) {
            return;
          }

          vm.tabsContent = _.flatten(results);
          vm.tabsContent = sortResults(vm.tabsContent);
          // set count result indicator for all tab
          if (vm.activeFilter == null) {
            vm.allTabDataLength = vm.tabsContent.length;
          }
          // hide/display no features msg
          if (vm.tabsContent.length < 1) {
            vm.noDataMessage = true;
          } else {
            vm.noDataMessage = false;
          }
          // refresh
          $scope.$applyAsync();
          // hide loading spinner
          vm.loading = false;
        }).catch(function (error) {
          console.log(error);
          vm.loading = false;
          vm.noDataMessage = true;
        });

    } else if (vm.searchInputText.length < 3) {
      vm.loading = false;
      vm.clearSearchResults();
      $scope.$applyAsync();
    }
  }, 2000);

  vm.startSearch = function () {
    // Detect that the user type at least 3 letters
    if ((vm.searchInputText.length > 2) && (vm.oldSearchInputText != vm.searchInputText || vm.allTabDataLength === 0)) {
      vm.oldSearchInputText = vm.searchInputText;
      vm.clearSearchResults();
      vm.loading = true;
      vm.doSearch();
    } else if (vm.searchInputText.length > 2 && vm.oldSearchInputText === vm.searchInputText) {
      //If search text did not change, simply display the results. Do not search again
      if (vm.activeFilter != null) {
        vm.tabsContent = sortResults(vm.searchResults[vm.activeFilter.label].features);
      } else {
        var results = _.flatMap(vm.searchResults, function (f) {
          return f.features;
        });
        vm.tabsContent = sortResults(results);
      }
      vm.loading = false;
      if (vm.tabsContent.length < 1) {
        vm.noDataMessage = true;
      } else {
        vm.noDataMessage = false;
      }
    } else if (vm.searchInputText.length < 3) {
      vm.oldSearchInputText = vm.searchInputText;
      vm.clearSearchResults();
    }

  };


  function _initFilters() {
    var staticLayers = _.values(navLayers.staticLayers);
    var highLowLayers = _.values(navLayers.highLowLayers);
    var searchables = _.concat(staticLayers, highLowLayers).filter(function (l) {
      return !!l.search;
    });
    return searchables.map(function (layer) {
      var searchLayers = layer.search.layers || [layer.params.layers];
      if (_.has(navLayers.highLowLayers, layer.key)) {
        //Add high,low declinaison if the layer has H/L parameters
        searchLayers = _.flatten(_.map(searchLayers, function (l) {
          return [l + '_high', l + '_low'];
        }));
      }
      return {
        fields: layer.search.fields,
        label: layer.search.label || layer.shortLabel,
        layers: searchLayers
      };
    });
  }

  vm.$onInit = function () {
    vm.map = window.map;
    // map.getView().on('change:resolution', vm.updateResolution, vm);
  };


  vm.$onDestroy = function () {
    // map.getView().un('change:resolution', vm.updateResolution, vm);
    // map = null;
  };
  vm.$onChanges = function (changesObj) {
    //listen for preference changes for auto update ?
    vm.enabled = vm.enabled != null ? vm.enabled : true;
  };

  vm.closeSearch = function () {
    vm.activeFilter = null;
    vm.opened = false;
    vm.onClosed();
    // clear count result indicator
    vm.clearSearch();
  };
  vm.openSearch = function () {
    vm.opened = true;
    $element.find('[type="search"]').focus();
    vm.onOpened();
  };
  vm.clearSearch = function () {
    //TODO empty search field and clear results
    vm.loading = false;
    vm.searchInputText = "";
    vm.clearSearchResults();
  };

  vm.clearSearchResults = function () {
    vm.noDataMessage = false;
    vm.tabsContent = [];
    // set layers count indicator to 0
    vm.searchResults = {};
    // set 'all' layer count indicator to 0
    vm.allTabDataLength = 0;
    clearSelectedFeature();
    $scope.$applyAsync();
  };

  function clearSelectedFeature() {
    if (vm.selectedId) {
      vm.selectedId = null;
      vm.onFeatureSelected({
        selected: vm.selectedId
      });
    }
  }

  vm.isActiveFilter = function (filter) {
    return (filter === vm.activeFilter) ? true : false;
  };

  vm.setFilter = function (filter) {
    // when changing tab, if we are already in zoom mode (user has clicked on type feature) return to initial position and zoom
    if (isOpen() && vm.selectedId) {
      returnToFirstPositionAndZoom();
    }
    // when changing tab level 2 should be closed
    closeLevel2();
    // when changing tab unselect feature
    clearSelectedFeature();
    vm.activeFilter = filter;
    // start search after selecting another tab
    vm.startSearch();
  }.bind(vm);

}]);

App.component('navSearchComponent', {
  bindings: {
    onOpened: '&',
    onClosed: '&',
    enabled: '<',
    onFeatureSelected: '&',
  },
  controller: 'navSearchController',
  controllerAs: 'ctrl',
  templateUrl: "views/partials/map/widgets/nav-search.template.html",
});
