/*jslint browser: true, plusplus:true, nomen:true*/
/*global _, angular, ol, App, Promise */

(function (global) {
  'use strict';

  function SettingsAircraftsCtrl($rootScope, $scope, $state, userService, flightfieldDefinitions, defaultPreferences) {

    $scope.dndSmall = false;
    $scope.setDndSmall = function (dndSmall) {
      $scope.dndSmall = dndSmall;
    };

    var init = function (useDefaultPrefs) {
      // Aircraft settings model
      $scope.prefsSmallTable = useDefaultPrefs ? defaultPreferences.smallTableColumns : $scope.user.preferences.smallTableColumns;
      $scope.prefsExtendedTable = useDefaultPrefs ? defaultPreferences.extendedTableColumns : $scope.user.preferences.extendedTableColumns;

      $scope.availableFlightfieldDefinitions = [];
      $scope.smallTableFlightfieldDefinitions = [];
      $scope.extendedTableFlightfieldDefinitions = [];

      _.map(flightfieldDefinitions, function (def, key) {
        if (def.displayInTable && (_.isUndefined(def.roles) || $rootScope.hasAnyRole(def.roles))) {
          var keyIndex = $scope.prefsSmallTable.indexOf(key);
          def.key = key;
          if (keyIndex > -1) {
            $scope.smallTableFlightfieldDefinitions[keyIndex] = def;
          } else {
            keyIndex = $scope.prefsExtendedTable.indexOf(key);
            if (keyIndex > -1) {
              $scope.extendedTableFlightfieldDefinitions[keyIndex] = def;
            } else {
              $scope.availableFlightfieldDefinitions.push(def);
            }
          }
        }
      });

      $scope.availableFlightfieldDefinitions = _.sortBy($scope.availableFlightfieldDefinitions, 'label');
    };

    $scope.submitAircraftsSettings = function () {
      return new Promise(function (resolve, reject) {
        userService.savePreferences({
          smallTableColumns: _.map($scope.smallTableFlightfieldDefinitions, 'key'),
          extendedTableColumns: _.map($scope.extendedTableFlightfieldDefinitions, 'key')
        }).then(resolve, function () {
          //reload last preferences in DB
          init();
          reject(arguments);
        });
      });
    };

    $scope.resetSettings = function () {
      init(true);
    };

    init();
  }

  App
    .controller('SettingsAircraftsCtrl', ['$rootScope', '$scope', '$state', 'userService', 'flightfieldDefinitions', 'defaultPreferences', SettingsAircraftsCtrl]);
}(window));
