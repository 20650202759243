/*jslint browser: true, nomen:true*/
/*global App, $, Promise, _ */
App.controller('ChatWindowCtrl', [
  '$scope',
  '$element',
  '$timeout',
  'chatService',
  'userService',
  function ($scope, $element, $timeout, chatService, userService) {
    'use strict';
    var readMarkTimeout, self = this;

    this.handleOpening = function (id) {
      if ($scope.flight.id === id) {
        $scope.scrollToLast();
      }
    };
    $scope.flight = $scope.getFlight($scope.chatId);
    $scope.markAsRead = function () {
      var flight = $scope.flight,
        msgs,
        readPromises;
      if (flight && chatService.states[flight.id] === chatService.OPEN) {
        msgs = $scope.flight.messages || [];
        readPromises = msgs.map(function (msg) {

          if (!msg.sender) { // msg coming from aircraft
            if (msg.readBy.indexOf(userService.user.login) === -1) {
              return chatService.markAsRead(flight, msg);
            }
          }
          return Promise.resolve(msg);
        });
        Promise.all(readPromises).then(function () {
          $scope.$evalAsync(_.noop); //force digest evaluation
        });
      }
    };
    $scope.scrollToLast = function () {
      var container = $($element[0]).find('.messages-container');
      container.animate({
        scrollTop: container.children(':first').height()
      }, 550);
      $timeout.cancel(readMarkTimeout);
      readMarkTimeout = $timeout($scope.markAsRead, 4000);
    };
    $timeout($scope.scrollToLast, 500);
    chatService.on("chat:maximise chat:open", this.handleOpening, self);
    $scope.$watch('flight.messages.length', $scope.scrollToLast);
    $scope.$on('$destroy', function () {
      $timeout.cancel(readMarkTimeout);
      chatService.off(null, null, self);
    });
  }
]);


App.controller('ChatWindowFormCtrl', [
  '$scope',
  'chatService',
  function ($scope, chatService) {
    'use strict';
    $scope.msg = {
      txt: ''
    };

    $scope.sendMessage = function (msg) {
      $scope.sending = true;
      $scope.error = void 0;
      $scope.markAsRead();
      chatService.sendMessage($scope.flight, msg)
        .then(function (msg) {
          $scope.sending = false;
          $scope.msg.txt = '';
          $scope.send_message_form.$setPristine(true);
          $scope.$applyAsync();
        }, function (error) {
          $scope.sending = false;
          $scope.$applyAsync();
          $scope.error = 'Failed to send message : ' + error;
          $scope.$applyAsync();
        });
    };


  }
]);
