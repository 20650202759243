/*jslint browser: true, plusplus:true, nomen:true*/
/*global _, angular, ol, App, ErrorCode */


App.controller('AdminAircraftCtrl', [
  '$rootScope',
  '$scope',
  '$state',
  '$uibModal',
  'aircraftInfoNgStore',
  'alertService',
  function AdminAircraftCtrl($rootScope, $scope, $state, $uibModal, aircraftInfoNgStore, alertService) {
    'use strict';
    // Aicrafts list
    aircraftInfoNgStore.refresh();
    $scope.aircrafts = aircraftInfoNgStore.aircrafts;


    // Edit aircraft dialog
    $scope.editAircraft = function (aircraft) {

      $uibModal.open({
        templateUrl: 'views/partials/admin/admin-aircraft-form.html',
        resolve: {
          fullEdit: _.constant(false),
          aircraft: function () {
            return aircraft;
          },
          aircrafts: function () {
            return $scope.aircrafts;
          }
        },
        controller: 'AdminAircraftFormCtrl'
      }).result.then(function (aircraft) {
        aircraftInfoNgStore
          .set(aircraft)
          .then(
            function (data) {
              aircraftInfoNgStore.refresh();
            },
            function (response) {
              response.json()
                .then(
                  function (errors) {
                    var error = errors.length && errors[0],
                      msg = (error && ErrorCode.getMessage(error.code, error.values)) || "";
                    alertService.error('Update failed', msg)
                      .then(function () {
                        //reopen edition
                        $scope.editAircraft(aircraft);
                      });
                  },
                  function () {
                    alertService.error('Update failed', "Server Internal")
                      .then(function () {
                        //reopen edition
                        $scope.editAircraft(aircraft);
                      });
                  }
                );
            }
          );
      });
    };


  }
]);
