/*jslint browser: true, plusplus: true, nomen: true */
/*global App, ServerTime,  _ */

import moment from 'moment-timezone';

(function (global) {
  'use strict';

  App.factory('flightfieldDefinitions', ['$injector', function($injector) {
    var formatTimestamp = function (d) {
      if (d) {
        return $injector.invoke(['userService', function (userService) {
          return moment(d).tz(userService.getUserPreference('timeZone')).format('HHmm[Z]');
        }])
      }
      return null;
    };

    var formatRelativeTimestamp = function (v) {
      return moment(v).from(ServerTime.now());
    }
    var flightfieldDefinitions = {
      acn: {
        type: 'string',
        shortLabel: 'Reg.',
        label: 'Registration',
        filter: 'string',
        displayInTable: true,
        displayInTemplate: true,
        description: 'Tail number i.e. \'F-GLZQ\''
      },
      acType: {
        type: 'string',
        shortLabel: 'Type',
        label: 'Aircraft Type',
        filter: 'string',
        displayInTable: true,
        displayInTemplate: true,
        description: 'Aircraft type'
      },
      addrModeS: {
        type: 'string',
        filter: 'string',
        shortLabel: 'ModeS Address',
        label: 'ModeS Address',
        displayInTable: true,
        displayInTemplate: true,
        description: 'Aircraft transponder address'
      },
      airline: {
        type: 'string',
        filter: 'string',
        shortLabel: 'Airline',
        label: 'Airline',
        displayInTable: true,
        displayInTemplate: true,
        description: 'ICAO 3-letter code for airline'
      },
      alertLvl: {
        filter: 'enum',
        type: 'enum',
        shortLabel: 'Alert Lvl',
        label: 'Alert Level',
        list: [0, 1, 2],
        description: 'Alert level among Normal, Caution, Warning',
        formatFn: function (v) {
          var labels = ['Normal', 'Caution', 'Warning'];
          return labels[v];
        }
      },
      alt: {
        type: 'number',
        filter: 'range',
        min: -2000,
        max: 45000,
        step: 1000,
        shortLabel: 'Altitude',
        label: 'Altitude',
        unit: ' ft',
        description: 'Aicraft Altitude',
        formatFilter: 'flightLevel'
      },
      cat: {
        type: 'enum',
        filter: 'enum',
        label: 'Category',
        list: ['L', 'M', 'H'],
        description: 'The aircraft categorie among light (L), medium (M), heavy (H)'
      },
      ci: {
        id:'ci',
        shortLabel: 'CI',
        label: 'Cost Index',
        type: 'number',
        min: 0,
        max: 200,
        displayInDetails: true,
        description: 'Aircraft cost index'
      },
      destinationAirport: {
        type: 'to-cell',
        shortLabel: 'To',
        label: 'To',
        displayInTable: true,
        displayInTemplate: true,
        description: 'ICAO code of the destination airport',
        formatFn: function (v) {
          return v && v.icao;
        }
      },
      dst: {
        type: 'string',
        shortLabel: 'To',
        label: 'To (ICAO)',
        filter: 'string',
        max: 4,
        description: 'ICAO code of the arrival airport'
      },
      eta: {
        type: 'date',
        filter: 'time',
        shortLabel: 'ETA',
        label: 'ETA',
        displayInTable: true,
        displayInTemplate: true,
        formatFn: formatTimestamp,
        description: 'Estimated Time for Arrival'
      },
      eibt: {
        type: 'date',
        shortLabel: 'EIBT',
        label: 'EIBT',
        description: 'Estimated In Block Time'
      },
      flightId: {
        type: 'string',
        filter: 'string',
        shortLabel: 'Flight ID',
        label: 'Flight ID',
        description: 'Flight id. i.e. \'DY31X\'',
        displayInTemplate: true
      },
      flightLevel: {
        id:'altitude',
        type: 'string',
        shortLabel: 'Altitude',
        label: 'Altitude',
        displayInTable: true,
        displayInTemplate: true,
        displayInDetails: true,
        description: 'Altitude in Flight Level'
      },
      flightNumber: {
        type: 'string',
        shortLabel: 'Flight Num',
        label: 'Flight number',
        description: 'Flight number i.e. \'DY311\'',
        displayInTable: true,
        displayInTemplate: true,
        displayInDetails: true
      },
      flownDistanceKm: {
        type: 'number',
        shortLabel: 'Flown dist.',
        label: 'Flown distance',
        unit: ' km',
        description: 'Distance from departure airport in kilometers',
        displayInDetails: true
      },
      flownDistanceNm: {
        type: 'number',
        shortLabel: 'Flown dist.',
        label: 'Flown distance',
        unit: ' NM',
        description: 'Distance from departure airport in nautical miles',
        displayInDetails: true
      },
      gw: {
        id:'gw',
        type: 'string',
        shortLabel: 'GW',
        label: 'GW',
        unit: '',
        description: 'Gross weight',
        displayInDetails: true
      },
      id: {
        type: 'string',
        shortLabel: 'ID',
        label: 'ID',
        description: 'Aircraft id for the application, used in path for query building'
      },
      'in': {
        type: 'date',
        shortLabel: 'IN',
        label: 'IN',
        description: 'IN Time'
      },
      lastUpdate: {
        type: 'date',
        shortLabel: 'Updated',
        label: 'Updated',
        description: 'Last position date',
        displayInDetails: true,
        formatFn: formatRelativeTimestamp,
        caution: 'Signal lost',
        warning: 'Signal lost'
      },
      lastEstimatedUpdate: {
        type: 'date',
        shortLabel: 'Last Est. Update',
        label: 'Last Est. Update',
        description: 'Last estimated position date',
        displayInDetails: false,
        formatFn: formatRelativeTimestamp,
        caution: 'Signal lost',
        warning: 'Signal lost'
      },
      lat: {
        type: 'number',
        shortLabel: 'Lat.',
        label: 'Latitude',
        displayInTable: true,
        displayInTemplate: true,
        description: 'Latitude in degrees',
        formatFn: function (v) {
          return v.toFixed(5);
        }
      },
      long: {
        type: 'number',
        shortLabel: 'Long.',
        label: 'Longitude',
        displayInTable: true,
        displayInTemplate: true,
        description: 'Longitude in degrees',
        formatFn: function (v) {
          return v.toFixed(5);
        }
      },
      mach: {
        type: 'number',
        shortLabel: 'Mach',
        label: 'Mach number',
        displayInTable: false,
        displayInDetails: false,
        displayInTemplate: false,
        description: 'Mach number'
      },
      off: {
        type: 'date',
        filter: 'time',
        shortLabel: 'OFF',
        label: 'OFF',
        displayInTable: true,
        displayInTemplate: true,
        formatFn: formatTimestamp,
        description: 'Take Off Time'
      },
      on: {
        type: 'date',
        filter: 'time',
        shortLabel: 'ON',
        label: 'ON',
        // displayInTable: true, displayInTemplate: true,
        description: 'Time of landing'
      },
      ori: {
        type: 'string',
        shortLabel: 'From',
        label: 'From (ICAO)',
        filter: 'string',
        max: 4,
        description: 'ICAO code of the departure airport'
      },
      originAirport: {
        type: 'from-cell',
        shortLabel: 'From',
        label: 'From',
        displayInTable: true,
        displayInTemplate: true,
        description: 'ICAO code of the departure airport',
        formatFn: function (v) {
          return v && v.icao;
        }
      },
      out: {
        type: 'date',
        shortLabel: 'OUT',
        label: 'OUT',
        description: 'Out Time'
      },
      phase: {
        filter: 'enum',
        shortLabel: 'Tendency',
        label: 'Flight Tendency',
        list: ['CLIMB', 'CRUISE', 'DESCENT'],
        description: 'Current flight tendency'
      },
      remainingDistanceKm: {
        type: 'number',
        /*shortLabel: 'Remaining dist.',*/
        shortLabel: 'RMNG dist.',
        label: 'Remaining distance',
        unit: ' km',
        description: 'Distance to arrival airport in kilometers',
        displayInDetails: true
      },
      remainingDistanceNm: {
        type: 'number',
        shortLabel: 'RMNG dist.',
        /*shortLabel: 'Remaining dist.',*/
        label: 'Remaining distance',
        unit: ' NM',
        description: 'Distance to arrival airport in nautical miles',
        displayInDetails: true
      },
      speed: {
        id:'groundSpeed',
        type: 'number',
        filter: 'range',
        min: 0,
        max: 600,
        step: 100,
        shortLabel: 'GS',
        label: 'Ground Speed',
        unit: ' kt',
        displayInTable: true,
        displayInTemplate: true,
        displayInDetails: true,
        description: 'Speed in knots (kt)'
      },
      squawk: {
        type: 'string',
        filter: 'string',
        shortLabel: 'Squawk',
        label: 'Squawk',
        displayInTable: true,
        displayInTemplate: true,
        description: 'Squawk hexa string code'
      },
      src: {
        id:'positionSource',
        type: 'enum',
        filter: 'enum',
        shortLabel: 'Position src',
        label: 'Position Source',
        list: ['ACARS', 'ADSB'],
        displayInTable: true,
        displayInDetails: true,
        displayInTemplate: true,
        description: 'Source of position data ADS-B or ACARS',
      },
      sta: {
        type: 'date',
        filter: 'time',
        shortLabel: 'STA',
        label: 'STA',
        displayInTable: false,
        formatFn: formatTimestamp,
        description: 'Standard Time for Arrival'
      },
      std: {
        type: 'date',
        filter: 'time',
        shortLabel: 'STD',
        label: 'STD',
        displayInTable: false,
        formatFn: formatTimestamp,
        description: 'Standard Time for Departure'
      },
      tow: {
        id:'tow',
        shortLabel: 'TOW',
        label: 'Takeoff Weight',
        type: 'string',
        unit: '',
        displayInDetails: true,
        description: 'Flight takeoff weight'
      },
      track: {
        id:'track',
        type: 'number',
        min: 0,
        max: 359,
        shortLabel: 'Track',
        label: 'Track',
        unit: '°',
        displayInTable: true,
        displayInTemplate: true,
        displayInDetails: true,
        description: 'Orientation in degrees included in [0;359]'
      },
      userTags: {
        filter: 'enum',
        type: 'enum',
        shortLabel: 'User tags',
        label: 'User tags',
        listFn: function ($scope) {
          return _.union.apply(_, _.values($scope.user.preferences.userTags));
        },
        //listFnDisplayValue:'text',
        description: 'User tags among the Aircraft tags set',
        displayInDetails: true,
        roles: ['TEST']
      },
      vspeed: {
        id:'verticalSpeed',
        type: 'number',
        filter: 'range',
        min: -5000,
        max: 6000,
        step: 10,
        shortLabel: 'V/S',
        label: 'Vertical Speed',
        longLabel: 'Vertical Speed',
        unit: ' fpm',
        formatFn: function (v) {
          return (v && v > 0) ? '+' + v : v;
        },
        displayInTable: true,
        displayInTemplate: true,
        displayInDetails: true,
        description: 'Vertical speed in feet per minute (fpm)'
      },
      desk: {
        type: 'string',
        shortLabel: 'Desk',
        label: 'Desk',
        displayInTable: false,
        displayInTemplate: false,
        displayInDetails: false,
        description: 'Desk group assigned to flight'
      },
      customerID: {
        shortLabel: 'Customer',
        label: 'Customer',
        type: 'string',
        displayInDetails: false,
      },
      crew: {
        shortLabel: 'Crew',
        label: 'Crew',
        type: 'integer',
        displayInDetails: false,
      },
      adult: {
        shortLabel: 'Adult',
        label: 'Adult',
        type: 'integer',
        displayInDetails: false,
      },
      infant: {
        shortLabel: 'Infant',
        label: 'Infant',
        type: 'integer',
        displayInDetails: false,
      },
      child: {
        shortLabel: 'Child',
        label: 'Child',
        type: 'integer',
        displayInDetails: false,
      },
      captain: {
        shortLabel: 'Captain',
        label: 'Captain',
        type: 'string',
        displayInDetails: false,
      },
      firstOfficer: {
        shortLabel: 'First Officer',
        label: 'First Officer',
        type: 'string',
        displayInDetails: false,
      }
    };
    //auto assign 'key' to each field
    _.mapValues(flightfieldDefinitions, function (v, k) {
      v.key = k;
      return v;
    });
    return flightfieldDefinitions;
  }]);

  App.constant('flightSectionDefinitions', [{
    label: 'Warning',
    name: 'warning',
    testFn: function (flight) {
      return !flight.isOther && flight.alertLvl === 2 && !flight.isClosed;
    },
    alertLvl: 2
  }, {
    label: 'Caution',
    name: 'caution',
    testFn: function (flight) {
      return !flight.isOther && flight.alertLvl === 1 && !flight.isClosed;
    },
    alertLvl: 1
  }, {
    label: 'My Fleet',
    name: 'myfleet',
    testFn: function (flight) {
      return !flight.isOther && flight.alertLvl === 0 && !flight.isClosed && flight.isInMyFleet && !flight.isPlanned;
    },
    alertLvl: 0,
    isHideable: true,
    isFlightPlanToggleVisible: true
  },{
    label: 'Tracked',
    name: 'tracked',
    testFn: function (flight) {
      return !flight.isOther && flight.alertLvl === 0 && !flight.isClosed && !flight.isInMyFleet && !flight.isPlanned;
    },
    alertLvl: 0,
    isHideable: true
  },{
    label: 'Planned',
    name: 'planned',
    testFn: function (flight) {
      return flight.isPlanned;
    },
    isHideable: true,
    visibleOnMap: false
  },{
    label: 'Others',
    name: 'other',
    testFn: function (flight) {
      return flight.isOther && !flight.isClosed && !flight.isPlanned;
    },
    alertLvl: -1,
    isHideable: true,
    isListHidden: true,
    pinned: true
  },{
    label: 'Closed',
    name: 'closed',
    testFn: function (flight) {
      return flight.isClosed;
    },
    alertLvl: -2
  }
  ]);


}(window));
