/*jslint browser: true, plusplus:true, nomen:true*/
/*global angular, _, App, ErrorCode, Promise */


App
  .controller('CustomerEditPageCtrl', [
    '$rootScope',
    '$scope',
    '$state',
    '$uibModal',
    '$http',
    '$timeout',
    'customerSuperNgStore',
    'alertService',
    'customerNfpConfigRestService',
    'airlineRestService',
    function CustomerEditPageCtrl($rootScope, $scope, $state, $uibModal, $http, $timeout, customerSuperNgStore, alertService, customerNfpConfigRestService, airlineRestService) {
      'use strict';
	  var vm = this;

      vm.refreshUserList = function () {
        customerSuperNgStore.getUsers($scope.customer).then(function (users) {
          users.forEach(user => {
            user.camelCaseLogin = _.trim(_.camelCase(user.login));
          });
          $scope.allUsers = users;
          $scope.$evalAsync();
        });
      };

      function refreshCustomerNfpConfigRestService() {
        customerNfpConfigRestService.getCustomerNfpConfig($scope.customer.id).then(function (customerNfpServiceConfig) {
          $scope.customerNfpServiceConfig = customerNfpServiceConfig;
        });
      }

      $scope.customerNfpServiceConfig = {};
      $scope.aircrafts = [];
      $scope.selectedWeatherPackageOptionModel ={key:"Basic"};
      $scope.allUsers = [];
      $scope.customerAirline = {};

      customerSuperNgStore.getCustomerWeatherPackage().then(function (options) {
    	  $scope.customerWeatherPackage = options;
          $scope.$evalAsync();
        });

        this.refresh = function () {
          if ($state.params.customer !== 'new') {
            $scope.customer = _.clone(customerSuperNgStore.find($state.params.customer));
            if (!$scope.customer) {
              customerSuperNgStore.once('sync', this.refresh, this);
            } else {
              var customerParameters = $scope.customer.parameters;
              $scope.selectedWeatherPackageOptionModel.key = $scope.customer.customerWeatherPackage[0];
              $scope.customer.routeAnalysis = customerParameters.routeAnalysis || false;
              $scope.customer.surfaceTracking = customerParameters.surfaceTracking || false;

              refreshCustomerNfpConfigRestService();

              $scope.airports = _.map($scope.customer.airports, function (a) {
                return {
                  code: a
                };
              });

            customerSuperNgStore.getAircrafts($scope.customer).then(function (aircrafts) {
              $scope.aircrafts = aircrafts;
              $scope.$evalAsync();
              vm.refreshAirlineLogos();
            });

            vm.refreshUserList();
          }
        } else {
          $scope.customer = {};
        }
      };
      this.refresh();

      $scope.isNew = function () {
        return _.isUndefined($scope.customer) || _.isUndefined($scope.customer.id) || _.isNull($scope.customer.id);
      };

      $scope.addAirport = function () {
        $scope.airports = _.compact($scope.airports);
        $scope.airports.push({
          code: ''
        });
      };
      $scope.addAirline = function () {
        $scope.airlines = _.compact($scope.airlines);
        $scope.airlines.push({
          code: ''
        });
      };


      //check if customer has option
      $scope.hasOption = function (option) {
        return !$scope.customer.options || $scope.customer.options.indexOf(option) >= 0;
      };

      //Add option to customer
      $scope.toggleOption = function (option) {
        if ($scope.hasOption(option)) {
          $scope.customer.options.splice($scope.customer.options.indexOf(option), 1);
        } else {
          $scope.customer.options.push(option);
        }
      };


      //remove to the real data holder
      $scope.removeAircraft = function (aircraft) {
        alertService
          .confirm('Deletion', 'Are you sure to delete this aircraft ?')
          .then(function () {
            customerSuperNgStore.deleteAircraft($scope.customer, aircraft)
                .then(
                    (response) => {
                      $scope.aircrafts.splice($scope.aircrafts.indexOf(aircraft), 1);
                      $scope.$evalAsync();
                      vm.refreshAirlineLogos();
                    },
                    (errors) => {
                      let error = errors.length && errors[0];
                      let msg = (error && ErrorCode.getMessage(error.code, error.values)) || "";
                      alertService.error('Aircraft Deletion Failed', msg);
                    }
                );
          });
      };

      // Create aircraft dialog
      $scope.createAircraft = function (aircraft) {
        $uibModal.open({
          templateUrl: 'views/partials/admin/admin-aircraft-form.html',
          resolve: {
            fullEdit: _.constant(true),
            aircraft: function () {
              return aircraft;
            },
            aircrafts: function () {
              return $scope.aircrafts;
            }
          },
          controller: 'AdminAircraftFormCtrl'
        }).result.then(function (aircraft) {

          customerSuperNgStore.addAircraft($scope.customer, aircraft)
            .then(
              function (response) {
                $scope.aircrafts.push(response);
                $scope.$evalAsync();
                vm.refreshAirlineLogos();
              },
              function (errors) {
                var error = errors.length && errors[0],
                  msg = (error && ErrorCode.getMessage(error.code, error.values)) || "";
                alertService.error('Error Creating Aircraft', msg)
                  .then(function () {
                    // reopen form
                    $scope.createAircraft(aircraft);
                  });
              }
            );
        });
      };

      // Edit aircraft dialog
      $scope.editAircraft = function (editedAircraft) {
        $uibModal.open({
          templateUrl: 'views/partials/admin/admin-aircraft-form.html',
          resolve: {
            fullEdit: _.constant(true),
            aircraft: function () {
              return editedAircraft;
            },
            aircrafts: function () {
              return $scope.aircrafts;
            }
          },
          controller: 'AdminAircraftFormCtrl'
        }).result.then(function (aircraft) {

          customerSuperNgStore.updateAircraft($scope.customer, aircraft)
            .then(
              function (response) {
                // replace the edited aircraft
                _.assign(editedAircraft, response);
                $scope.$evalAsync();
                vm.refreshAirlineLogos();
              },
              function (errors) {
                var error = errors.length && errors[0],
                  msg = (error && ErrorCode.getMessage(error.code, error.values)) || "";
                alertService.error('Error Updating Aircraft', msg)
                  .then(function () {
                    // reopen form
                    $scope.editAircraft(aircraft);
                  });
              }
            );
        });
      };


      // Create user dialog
      $scope.createUser = function () {
        console.log("creation user")
        $uibModal.open({
          templateUrl: "views/partials/admin/admin-user-form.html",
          resolve: {
            allUsers: function () {
              return $scope.allUsers;
            },
            userInForm: function () {
              return null;
            },
            isUpdate: function () {
              return false;
            },
            customer: function () {
              return $scope.customer;
            }
          },
          controller: 'AdminUserFormCtrl'
        }).result.then(function (user) {
          if (!!user.config) {
            user.configJson = JSON.stringify(user.config);
          }
          customerSuperNgStore.addUser($scope.customer, user)
            .then(
              function (response) {
                vm.refreshUserList();
              },
              function (errors) {
                var error = errors.length && errors[0],
                  msg = (error && ErrorCode.getMessage(error.code, error.values)) || "";
                alertService.error('Insertion failed', msg)
                  .then(function () {
                    //reopen edition
                    $scope.editUser(user);
                  });
              }
            );
        });
      };



      // Edit user dialog
      $scope.editUser = function (userInForm) {
        $uibModal.open({
          templateUrl: "views/partials/admin/admin-user-form.html",
          resolve: {
            allUsers: function () {
              return $scope.allUsers;
            },
            userInForm: function () {
              return userInForm;
            },
            isUpdate: function () {
              return true;
            },
            customer: function () {
              return $scope.customer;
            }
          },
          controller: 'AdminUserFormCtrl'
        }).result.then(function (user) {
          user.configJson = JSON.stringify(user.config);
          customerSuperNgStore.updateUser($scope.customer, user)
            .then(
              function (response) {
                vm.refreshUserList();
              },
              function (response) {
                response.json()
                  .then(
                    function (errors) {
                      var error = errors.length && errors[0],
                        msg = (error && ErrorCode.getMessage(error.code, error.values)) || "";
                      alertService.error('Update failed', msg)
                        .then(function () {
                          //reopen edition
                          $scope.createUser(user);
                        });
                    },
                    function () {
                      alertService.error('Update failed', "Server Internal")
                        .then(function () {
                          //reopen edition
                          $scope.createUser(user);
                        });
                    }
                  );
              }
            );
        });
      };

      // Delete user functionnality
      $scope.deleteUser = function (userToDelete) {
        alertService
          .confirm('User Deletion', "Do you really want to delete this user ?")
          .then(function () {
            customerSuperNgStore.deleteUser($scope.customer, userToDelete).then(function () {
              vm.refreshUserList();
            });
          });
      };

      $scope.hasAdminRole = function (user) {
        return user.roles.indexOf(App.ADMIN_ROLE) !== -1;
      };

      $scope.submitCustomerChange = function () {
        var customer = $scope.customer,
          op = 'set',
          customerParameters = $scope.customer.parameters ? $scope.customer.parameters : {};
        if (!customer.id) {
          op = 'add';
          var options = [];
          angular.forEach($scope.customerOptions, function (value, key) {

            if (angular.element("#" + key)[0].checked) {
              options.push(key);
            }
          });
          customerParameters.timeFrame = 10;
          customer.options = options;

        }

        var selectedWeatherPackageOptionKey =  $scope.selectedWeatherPackageOptionModel.key;
		customer.customerWeatherPackage = [ selectedWeatherPackageOptionKey ];

        customer.airports = _.map($scope.airports, 'code');
        customer.airlines = _.map($scope.airlines, 'code');
        customerParameters.routeAnalysis = $scope.customer.routeAnalysis;
        customerParameters.surfaceTracking = $scope.customer.surfaceTracking;
        customer.parameters = customerParameters;
        console.log(customer);
        return customerSuperNgStore[op](customer)
          .then(
            function (cust) {
              $scope.customer = cust;
              customerParameters = $scope.customer.parameters;
              $scope.customer.routeAnalysis = customerParameters.routeAnalysis || false;
              $scope.customer.surfaceTracking = customerParameters.surfaceTracking || false;

              // refresh aircrafts
              customerSuperNgStore.getAircrafts($scope.customer).then(function (aircrafts) {
                $scope.aircrafts = aircrafts;
                $scope.$evalAsync();
              });

              $timeout(function () {
                $state.go('super.customeredit', {
                  customer: cust.id
                });
              });
            },
            function (response) {
              return new Promise(function (resolve, reject) {
                response.json()
                  .then(
                    function (errors) {
                      var error = errors.length && errors[0],
                        msg = (error && ErrorCode.getMessage(error.code, error.values)) || "";
                      alertService.error('Modification failed', msg);
                      reject(msg);
                    },
                    function () {
                      alertService.error('Update failed', "Server Internal");
                      reject("Unknow error");
                    }
                  );
              });
            }
          );
      };

      $scope.uploadCsvAircrafts = function () {
        $scope.uploading = true;
        var jsonFile = document.getElementById("csvAircraftInputFile");
        // the file is the first element in the files property
        var file = jsonFile.files[0];
        var formData = new FormData();
        formData.append('file', file);
        customerSuperNgStore
          .bulkAddAircrafts($scope.customer, formData)
          .then(function (uploadDto) {
            alertService
            .message('Upload', 'Aircrafts list successfuly updated. <br/>' + uploadDto.additionalInfos.join('<br/>'));
            console.log(uploadDto.aircrafts);
            $scope.aircrafts = uploadDto.aircrafts;
            $scope.uploading = false;
            $scope.$evalAsync();
          })
          .catch(function (result) {
            result.json().then(function (errors) {
              var msg = errors.map(function (e) {
                return ErrorCode.getMessage(e.code, e.values);
              });
              var level = (result.status == 409) ? 'warning' : 'error';
              alertService[level]("Import failed", msg.join(',<br>'));
            });
            $scope.uploading = false;
            customerSuperNgStore.getAircrafts($scope.customer).then(function (aircrafts) {
              $scope.aircrafts = aircrafts;
              $scope.$evalAsync();
            });
          })
      };


      $scope.submitCustomerNfpServiceConfigForm = function () {

        if($scope.customerNfpServiceConfig.id == null) {
            return customerNfpConfigRestService.createCustomerNfpConfig($scope.customer.id, $scope.customerNfpServiceConfig).then((result) => {
              if (result instanceof Error) {
                alertService.error(result.name, result.message);
              }
              else {
                refreshCustomerNfpConfigRestService();
              }
            })
        } else {
          return customerNfpConfigRestService.updateCustomerNfpConfig($scope.customer.id, $scope.customerNfpServiceConfig).then((result) => {
            if (result instanceof Error) {
              alertService.error(result.name, result.message);
            }
            else {
              refreshCustomerNfpConfigRestService();
            }
          })
        }
      };

      vm.refreshAirlineLogos = function () {

        let airlines = [];
        let uniqueAirlines = [];

        $scope.aircrafts.forEach( function (aircraft) {
            airlines.push(aircraft.operatorIcao);
        });

        airlines = Array.from(new Set(airlines));

        airlines.forEach(function(airline) {
            airlineRestService.getIcaoLogo(airline).then((result) => {
                uniqueAirlines.push({'code':airline, 'imageFileUrl':result});
            });
        });

        $scope.airlines = uniqueAirlines;
      };

      $scope.uploadLogo = function (icao) {

        var imageFile = document.getElementById("logoUpload" + icao);

        if(imageFile.files.length === 0) {
            alertService['warning']("No File Selected", "Please select a file before uploading.");
        } else if (imageFile.files[0].size > 60000) {
            alertService['warning']("File To Large", "File must be less than 60kb.");
        } else {
            var file = imageFile.files[0];
            var formData = new FormData();
            formData.append('imagefile', file);

            airlineRestService.updateIcaoLogo(icao, formData).then((response) => {
                if(response.status === 202) {
                    alertService.message('Upload Succeeded', 'Warning: New image might take up to 15 minutes to display.');
                } else {
                    alertService.error('Upload Failed', 'There was a problem uploading the new image.');
                }
                vm.refresh();
            });
        }
      };

    }
  ]);
