/*jslint browser: true, nomen: true
 */
/*global _, $, App, moment, util */
App.controller('AirportTaxiTimeController', ['$scope', 'airportDataNgStore', function ($scope, airportDataNgStore) {
  'use strict';

  $scope.isAdminUser = $scope.user.roles.indexOf('ADMIN') > -1;
  $scope.isFormValid = function () {
    var $form = $('#airportTaxiTimeTabForm');
    return $form.length === 0 || $form[0].checkValidity();
  };

  $scope.getTimeDisplay = function (time) {
    if (time || time === 0) {
      var suffix = time === 0 || time === 1 ? " minute" : " minutes";
      return time + suffix;
    } else {
      return "- -";
    }
  };

  $scope.save = function () {
    airportDataNgStore.setTaxiTimes($scope.airport.icao, $scope.taxiTimes).then(function (taxiTimes) {
      $scope.taxiTimes = taxiTimes;
    });
  };

  function _refreshTaxiTimes() {
    airportDataNgStore.getTaxiTimes($scope.airport.icao).then(function (results) {
      $scope.taxiTimes = results;
    });
  }

  $scope.refresh = function () {
    _refreshTaxiTimes();
  };

  function updateTaxiTimes() {
    if ($scope.airport) {
      _refreshTaxiTimes();
    } else {
      $scope.taxiTimes = [];
    }
  }

  $scope.$watch('airport', function () {
    updateTaxiTimes();
  }, false);

  updateTaxiTimes();



}]);
