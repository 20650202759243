/*jslint browser: true, nomen:true */
/*global _, App, angular */

App.controller('AdminColorCtrl', ['$scope', 'customerNgStore', 'mapPreferences', function ($scope, customerNgStore, mapPreferences) {
  'use strict';

  customerNgStore.refresh();
  var customer = customerNgStore.customer,
    parameters = customer.parameters,
    customerColors = parameters.customColors;

  if (angular.isUndefined(customerColors)) {
    customerColors = parameters.customColors = {};
  }


  $scope.isActive = {
    warning: !!customerColors.warning,
    caution: !!customerColors.caution,
    myfleet: !!customerColors.myfleet,
    tracked: !!customerColors.tracked,
    other: !!customerColors.other,
    planned: !!customerColors.planned
  };

  $scope.defaultColors = mapPreferences.defaultColors;
  customerColors = _.defaults(customerColors, $scope.defaultColors);
  $scope.colors = _.clone(customerColors);
  $scope.currentColors = _.clone(customerColors);

  //
  // watch checkbox checking
  $scope.$watch('isActive', function () {
    if ($scope.isActive.warning) {
      $scope.currentColors.warning = $scope.colors.warning;
    } else {
      $scope.currentColors.warning = $scope.defaultColors.warning;
    }
    if ($scope.isActive.caution) {
      $scope.currentColors.caution = $scope.colors.caution;
    } else {
      $scope.currentColors.caution = $scope.defaultColors.caution;
    }
    if ($scope.isActive.myfleet) {
      $scope.currentColors.myfleet = $scope.colors.myfleet;
    } else {
      $scope.currentColors.myfleet = $scope.defaultColors.myfleet;
    }
    if ($scope.isActive.tracked) {
      $scope.currentColors.tracked = $scope.colors.tracked;
    } else {
      $scope.currentColors.tracked = $scope.defaultColors.tracked;
    }
    if ($scope.isActive.planned) {
           $scope.currentColors.planned = $scope.colors.planned;
        } else {
           $scope.currentColors.planned = $scope.defaultColors.planned;
        }
    if ($scope.isActive.other) {
      $scope.currentColors.other = $scope.colors.other;
    } else {
      $scope.currentColors.other = $scope.defaultColors.other;
    }

  }, true);

  //
  // Watch colors edited by user to update checkbox bg
  $scope.$watch('colors', function () {
    if ($scope.isActive.warning) {
      $scope.currentColors.warning = $scope.colors.warning;
    }
    if ($scope.isActive.caution) {
      $scope.currentColors.caution = $scope.colors.caution;
    }
    if ($scope.isActive.myfleet) {
      $scope.currentColors.myfleet = $scope.colors.myfleet;
    }
    if ($scope.isActive.tracked) {
      $scope.currentColors.tracked = $scope.colors.tracked;
    }
    if ($scope.isActive.other) {
      $scope.currentColors.other = $scope.colors.other;
    }
    if ($scope.isActive.planned) {
      $scope.currentColors.planned = $scope.colors.planned;
    }
  }, true);

  //
  // SUBMIT
  $scope.submitAdminColorConfig = function () {
    if ($scope.isActive.warning) {
      customerColors.warning = $scope.colors.warning;
    } else {
      delete customerColors.warning;
    }

    if ($scope.isActive.caution) {
      customerColors.caution = $scope.colors.caution;
    } else {
      delete customerColors.caution;
    }
    
    if ($scope.isActive.myfleet) {
      customerColors.myfleet = $scope.colors.myfleet;
    } else {
      delete customerColors.myfleet;
    }

    if ($scope.isActive.tracked) {
      customerColors.tracked = $scope.colors.tracked;
    } else {
      delete customerColors.tracked;
    }

    if ($scope.isActive.other) {
      customerColors.other = $scope.colors.other;
    } else {
      delete customerColors.other;
    }

    if ($scope.isActive.planned) {
      customerColors.planned = $scope.colors.planned;
    } else {
      delete customerColors.planned;
    }

    customer.parameters = parameters;
    return customerNgStore.set(customer);
  };

}]);
