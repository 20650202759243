/*jslint browser: true, plusplus: true, nomen:true */
/*global _, angular, App, moment*/
(function () {
  'use strict';
  App.controller('MapSearchCtrl', ['$scope', '$filter', 'possibleFilters', function ($scope, $filter, possibleFilters) {

    $scope.possibleFilters = possibleFilters;
    $scope.newFilter = null;
    $scope.filterValue = null;
    $scope.formatLabel = function (value) {
      var label = value,
        def = $scope.newFilter;
      if (def) {
        if (_.isString(def.formatFilter)) {
          //format values that need to be formatted.
          label = $filter(def.formatFilter)(value);
        } else if (_.isFunction(def.formatFn)) {
          label = def.formatFn.call(value, value);
        }
      }
      return label;
    };
    $scope.$watch('newFilter', function (newValue, oldValue) {
      if (newValue) {
        //init range with full range
        if (newValue.filter === 'range') {
          $scope.filterValue = {
            min: newValue.min,
            max: newValue.max
          };
        } else if (newValue.filter === 'enum') {
          //init enum values to all enabled
          if (_.isFunction(newValue.listFn)) {
            newValue.list = newValue.listFn.call($scope, $scope);
          }
          $scope.filterValue = _.zipObject(newValue.list, _.fill(new Array(newValue.list.length), false));
        } else if (newValue.filter === 'objectEnum') {
          //init object enum values to all enabled

          if (_.isFunction(newValue.listFn)) {
            var displayedValue = newValue.listFnDisplayValue;
            newValue.list = _.map(newValue.listFn.call($scope, $scope), function (n) {
              return {
                displayedValue: n[this],
                id: n.id
              };
            }.bind(displayedValue));
          }
          $scope.filterValue = _.zipObject(newValue.list, _.fill(new Array(newValue.list.length), false));
        } else if (newValue.filter === 'time') {
          $scope.filterValue = {
            minTime: moment.utc({
              hour: 0
            }),
            maxTime: moment.utc({
              hour: 24
            })
          };
        } else {
          $scope.filterValue = null;
        }
      }
    });

    $scope.submitFilter = function () {
      var filter = $scope.newFilter,
        displayed,
        value = $scope.filterValue,
        key = filter.key,
        b,
        a;
      if (filter.filter === 'enum') {
        value = _.keys(_.pickBy(value, _.identity));
      } else if (filter.filter === 'objectEnum') {
        displayed = _.filter(filter.list, function (o) {
          if (value[o.id]) {
            return true;
          }
          return false;
        }, value);
        displayed = _.map(displayed, 'displayedValue');
        value = _.keys(_.pickBy(value, _.identity));
      }

      /* if (filter.filter === 'time') {
         value = _.mapValues(value, _.add); //convert dates to timestamp
       }*/
      $scope.$close({
        property: key,
        displayed: displayed || value,
        value: value
      });
    };
    return true;

  }]);

}());
