App.factory('supportRestService', ['$http', function($http) {

    return {
        getAcarsMessages: function() {
            return $http({
                method: 'GET',
                url: window.backendPrefix + '/support/acars',
                withCredentials: true,
                responseType: 'json',
            }).then(function successCallback(response) {
                return response.data;
            }, function errorCallback(response) {
                if (response.data !== null && typeof response.data.code !== 'undefined') {
                    return Error('Error fetching acars messages. Message: ' + response.data.values[0]);
                } else {
                    return Error('Error fetching acars messages. Error: ' + response.status + ' ' + response.statusText);
                }
            });
        }
    };
    }]);