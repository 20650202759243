/*jslint browser: true, nomen: true */
/*global _ */

(function () {
  'use strict';

  function ServerTime() {
    this.offset = 0;
  }

  _.assign(ServerTime.prototype, {
    
    constructor: ServerTime,
    
    computeOffset: function (serverDateString) {
      if (!!serverDateString) {
        this.offset = Date.now() - (new Date(serverDateString)).getTime();
      }
    },
    
    now: function () {
      return new Date(Date.now() - this.offset);
    }
  });

  window.ServerTime = new ServerTime();
}());
