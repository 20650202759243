App.factory('areaModelFactory', [function() {
  'use strict';

  class DefinedArea {

    constructor(id, name, authorId, geoJsonData, colour, shared) {
      this.id = id;
      this.name = name;
      this.authorId = authorId;
      this.geoJsonData = geoJsonData;
      this.colour = colour;
      this.shared = shared;
    }

    toString() {
      return JSON.stringify(this);
    }
  }

  return {
    definedArea: function (id, name, authorId, geoJsonData, colour, shared) {
      return new DefinedArea(id, name, authorId, geoJsonData, colour, shared);
    }
  };

}]);
