(function (global) {
  'use strict';

  var notDefined = 'Not Defined';

  var indicatorTypes = {
    'B': 'FIR/UIR',
    'F': 'FIR',
    'U': 'UIR'
  };

  function calculateLimits(prop) {
    var result = "";
    if (prop.indicator === "F") {
      // IF {INDICATOR} = ""F"" AND {FIR_HI_TYP} = ""Unlimited"" show ""GND - UNL""
      if (prop.fir_hi_typ === "Unlimited") {
        result = "GND - UNL";
      } else if (prop.fir_hi_typ === "FlightLevel") {
        // ELIF {INDICATOR} = ""F"" AND {FIR_HI_TYP} = ""FlightLevel"" show ""GND - FL"" {FIR_HI_LIM}/100, e.g. ""GND - FL195""
        result = "GND - FL" + Math.round(prop.fir_hi_lim / 100);
      } else if (prop.fir_hi_typ === "Altitude") {
        // ELIF {INDICATOR} = ""F"" AND {FIR_HI_TYP} = ""Altitude"" show ""GND - "" {FIR_HI_LIM} ""ft"", e.g. ""GND - 18000ft""
        result = "GND - " + prop.fir_hi_lim + "ft";
      } else {
        result = notDefined;
      }

    } else if (prop.indicator === "U") {
      // ELIF {INDICATOR} = ""U"" AND {UIR_LO_TYP} = ""FlightLevel"" AND {UIR_HI_TYP} = ""FlightLevel"" show ""FL"" {UIR_LO_LIM}/100 "" - FL"" {UIR_HI_LIM}/100, e.g. ""FL245 - FL600""
      if ((prop.uir_lo_typ === "FlightLevel") && (prop.uir_hi_typ === "FlightLevel")) {
        result = "FL" + Math.round(prop.uir_lo_lim / 100) + " - FL" + Math.round(prop.uir_hi_lim / 100)
        // ELIF {INDICATOR} = ""U"" AND {UIR_LO_TYP} = ""Unknown"" AND {UIR_HI_TYP} = ""Unknown"" show ""Not defined"", e.g. ""Not defined""
      } else if ((prop.uir_lo_typ === "Unknown") && (prop.uir_hi_typ === "Unknown")) {
        result = notDefined;
        // ELIF {INDICATOR} = ""U"" AND {UIR_LO_TYP} = ""FlightLevel"" AND {UIR_HI_TYP} = ""Unlimited"" show ""FL"" {UIR_LO_LIM}/100 "" - UNL"", e.g. ""FL180 - UNL""
      } else if ((prop.uir_lo_typ === "FlightLevel") && (prop.uir_hi_typ === "Unlimited")) {
        result = "FL" + Math.round(prop.uir_lo_lim / 100) + " - UNL";
      } else {
        result = notDefined;
      }

    } else if (prop.indicator === "B") {
      // ELIF {INDICATOR} = ""B"" AND {UIR_LO_TYP} = ""FlightLevel"" AND {UIR_HI_TYP} = ""FlightLevel"" show ""FL"" {UIR_LO_LIM}/100 "" - FL"" {UIR_HI_LIM}/100, e.g. ""FL245 - FL600""
      if ((prop.uir_lo_typ === "FlightLevel") && (prop.uir_hi_typ === "FlightLevel")) {
        result = "FL" + Math.round(prop.uir_lo_lim / 100) + " - FL+" + Math.round(prop.uir_hi_lim / 100);
        // ELIF {INDICATOR} = ""B"" AND {UIR_LO_TYP} = ""FlightLevel"" AND {UIR_HI_TYP} = ""Unlimited"" show ""FL"" {UIR_LO_LIM}/100 "" - UNL"", e.g. ""FL180 - UNL""
      } else if ((prop.uir_lo_typ === "FlightLevel") && (prop.uir_hi_typ === "Unlimited")) {
        result = "FL" + Math.round(prop.uir_lo_lim / 100) + " - UNL";
        // ELIF {INDICATOR} = ""B"" AND {UIR_LO_TYP} = ""Altitude"" AND {UIR_HI_TYP} = ""Unlimited"" show {UIR_LO_LIM} ""ft - UNL"", e.g. ""18000ft - UNL""
      } else if ((prop.uir_lo_typ === "Altitude") && (prop.uir_hi_typ === "Unlimited")) {
        result = prop.uir_lo_lim + "ft" / +" - UNL";
      }
      // ELIF {INDICATOR} = ""B"" AND {UIR_LO_TYP} = ""NotSpecified"" AND {UIR_HI_TYP} = ""NotSpecified"" show ""Not defined"", e.g. ""Not defined""
      else if ((prop.uir_lo_typ === "NotSpecified") && (prop.uir_hi_typ === "NotSpecified")) {
        result = notDefined;
      } else {
        result = notDefined;
      }
    }
    return result;
  }

  function calculateEntryClearance(clearance) {
    var result = "";
    if (clearance === "F") {
      result = "Not required";
    } else if (clearance === "T") {
      result = "Required";
    } else {
      result = notDefined;
    }
    return result;
  }


  global.FirFeatureFormatter = {
    format: function (adFeature, projection) {
      var props = adFeature.properties || {};
      var properties = [{
          label: 'Feature',
          value: '<b>FIR</b>'
        },
        {
          label: 'Ident',
          value: props.identifier || notDefined
        },
        {
          label: 'Name',
          value: props.name || notDefined
        },
        {
          label: 'Type',
          value: indicatorTypes[props.indicator] || notDefined
        },
        {
          label: 'Address',
          value: props.address || notDefined
        },
        {
          label: 'Limits',
          value: calculateLimits(props) || notDefined
        },
        {
          label: 'Entry Clearance',
          value: calculateEntryClearance(props.ent_clear) || notDefined
        },

      ];

      return {
        type: 'FIR',
        title: props.identifier || '–',
        subtitle: props.identifier || '-',
        properties: properties,
      };

    }
  };
}(window));
