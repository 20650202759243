/*jslint browser: true, plusplus:true*/
/*global App, $, angular */

(function (global) {
  'use strict';

  function ResetPasswordFormCtrl($scope, $state, $stateParams, $uibModal) {
    // Reste Password
    $scope.resetPassword = function resetPassword() {
      if (document.getElementById('mail') !== null && document.getElementById('mail').value !== "") {
        var email = document.getElementById('mail').value,
          i,
          request;

        // Save new password
        request = $.ajax({
          dataType: "json",
          headers: {
            'Content-Type': "application/json"
          },
          type: "POST",
          url: window.backendPrefix + "/auth/password",
          data: email,
          statusCode: {
            200: function (response) {
              $("#resetPasswordFailed").html("");
              $("#resetPasswordSuccess").html("Password reset successfully");
              alert("The new password has been sent to mail");
              $uibModal.close();
            },
            400: function (response) {
              $("#resetPasswordSuccess").html("");
              $("#resetPasswordFailed").html("Password reset failed !");
              alert("The password could not be changed");
              $uibModal.close();
            }
          }
        });
      }
    };

    // Cancel action
    $scope.cancel = function cancel() {
      var form = document.getElementById("formResetPassword"),
        elements = form.elements,
        i;
      for (i = 0; i < elements.length; ++i) {
        elements[i].required = false;
      }
      $uibModal.close();
    };
  }

  App
    .controller('ResetPasswordFormCtrl', ['$scope', '$state', '$stateParams', '$uibModalInstance', ResetPasswordFormCtrl]);

}(window));
