/*jslint browser: true, nomen: true */
/*global App, Promise, fetch */

App.provider('serverInstance', [function serveInstanceProvider() {
  'use strict';

  var _currentInstance, _checkStatus, _checkIsCurrent, _findServerInstances, sipSingleton;

  _currentInstance = function () {
    return [{
      name: location.hostname,
      host: location.host
    }];
  };

  _checkStatus = function (response) {
    if (response.status >= 200 && response.status < 300) {
      return response.json();
    } else {
      return Promise.resolve(_currentInstance());
    }
  };
  
  _checkIsCurrent = function (instances) {
    instances.forEach(function (instance) {
      instance.isCurrent = instance.host === location.host;
    });
    return Promise.resolve(instances);
  };

  _findServerInstances = function () {
    var url = '/instances.json';
    return fetch(url, {
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(_checkStatus)
      .then(_checkIsCurrent);
  };

  sipSingleton = {
    findServerInstances: _findServerInstances
  };

  this.$get = [function serveInstance() {
    return sipSingleton;
  }];

}]);
