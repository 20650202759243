/*jslint browser: true, nomen: true */
/*global App, Promise, _ */

App.controller('SettingsTagsCtrl', ['$scope', 'aircraftInfoNgStore', 'userService', function ($scope, aircraftInfoNgStore, userService) {
  'use strict';

  var g = $scope.g = {
    userTags: {}
  };
  
  $scope.loadTags = function (query) {
    var listAutocompleteTags = [];
    var arrayUniqueTags = _.uniqWith(g.availableTags, _.isEqual)
    _.forEach(arrayUniqueTags, function (tag) {
      if (tag.indexOf(query) != -1) {
        listAutocompleteTags.push(tag);
      }
    });
    return listAutocompleteTags;
  };



  function init() {
    g.userTags = $scope.user.preferences.userTags || {};
    g.aircrafts = aircraftInfoNgStore.aircrafts;
    g.availableTags = _.union.apply(undefined, _.map($scope.user.preferences.userTags));
    _.forEach(g.aircrafts, function (aircraft) {
      g.userTags[aircraft.registration] = g.userTags[aircraft.registration] || [];
    });
    $scope.$applyAsync();
  }

  $scope.resetTags = function () {
    _.forEach(g.aircrafts, function (aircraft) {
      g.userTags[aircraft.registration] = [];
    });
    $scope.$applyAsync();
  };

  $scope.addTag = function (newTag) {
    if (!!newTag && g.availableTags.indexOf(newTag) === -1) {
      g.availableTags.push(newTag.text);
    }
    return newTag;
  };

  $scope.submitChangeTags = function () {
    return new Promise(function (resolve, reject) {
      userService.savePreferences({
        userTags: g.userTags
      }).then(resolve, function () {
        //reload last preferences in DB
        init();
        reject(arguments);
      });
    });
  };

  aircraftInfoNgStore.on('sync', init);
  aircraftInfoNgStore.refresh();

}]);
