/*jslint browser: true */
/*global App, Plumbing, ServerTime, MimeTypeHelper, Promise */

App.provider('userProfileStore', [function userProfileStoreProvider() {
  'use strict';

  var opts, getPath, store;

  opts = {
    path: {
      me: '/me'
    }
  };

  getPath = function () {
    return window.backendPrefix + opts.path.me;
  };

  store = new Plumbing({
    headers: opts.headers,
    url: getPath,
    delay: 10000,
    processResponse: function (response) {
      // update time server time offset
      ServerTime.computeOffset(response.headers.get('Date'));
      if (response.ok) {
        var mimeType = MimeTypeHelper.parse(response.headers.get('Content-Type'));
        if (mimeType.baseType === 'application/json') {
          return response.json();
        } else {
          // caller will receive the response
          return Promise.resolve(response);
        }
      } else {
        return Promise.reject(response);
      }
    }
  });

  this.setPrefixPart = function (prefix) {
    window.backendPrefix = prefix;
  };

  this.setMePart = function (me) {
    opts.path.me = me;
  };

  this.$get = [function userProfileStoreFactory() {
    return store;
  }];

}]);
