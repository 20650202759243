/*jslint browser: true, nomen: true */
/*global App, Promise, Plumbing, _ */

App.provider('notificationStore', ['customerId', function notificationStoreProvider(customerId) {
  'use strict';

  var opts, getPath, store;

  opts = {
    path: {
      customer: '/customer/',
      notifications: '/notifications'
    }
  };

  getPath = function () {
    if (_.isFinite(customerId.get())) {
      return window.backendPrefix + opts.path.customer + customerId.get() + opts.path.notifications;
    }
  };

  store = new Plumbing({
    headers: opts.headers,
    url: getPath,
    delay: 60000
  });

  customerId.on('update', function (oldId, newId) {
    if (oldId && oldId !== newId) {
      if (_.isUndefined(newId)) {
        store.adapter.stop();
      } else {
        store.refresh();
      }
    }
  }, this);

  this.setCustomerPart = function (customer) {
    opts.path.customer = customer;
  };

  this.$get = [function notificationStoreFactory() {
    return store;
  }];

}]);
