/*jslint browser: true, nomen: true */
/*global App, Plumbing, GenericStore, angular */

App.provider('aircraftInfoNgStore', [function aircraftInfoNgStoreProvider() {
  'use strict';

  function AircraftInfoNgStore() {
    GenericStore.call(this);
  }

  AircraftInfoNgStore.prototype = Object.create(GenericStore.prototype);

  angular.extend(AircraftInfoNgStore.prototype, {

    //
    // Constructor

    constructor: AircraftInfoNgStore,

    //
    // Overridable

    newInstance: function (values) {
      return {
        id: values.id,
        addrModeS: values.addrModeS,
        registration: values.registration,
        acType: values.acType,
        state: values.state,
        operatorIcao: values.operatorIcao,
        bridgeId: values.bridgeId,
        positionUplinkType: values.positionUplinkType || 'NONE',
        chatUplinkType: values.chatUplinkType || 'NONE',
        dateAdded: values.dateAdded,
        spaceBasedActivated: values.spaceBasedActivated,
      };
    },

    updateInstance: function (entity, values) {
      entity.id = values.id;
      entity.addrModeS = values.addrModeS;
      entity.registration = values.registration;
      entity.acType = values.acType;
      entity.state = values.state;
      entity.operatorIcao = values.operatorIcao;
      entity.bridgeId = values.bridgeId;
      entity.positionUplinkType = values.positionUplinkType || 'NONE';
      entity.chatUplinkType = values.chatUplinkType || 'NONE';
      entity.dateAdded = values.dateAdded;
      entity.spaceBasedActivated = values.spaceBasedActivated;
    }

  });

  Object.defineProperties(AircraftInfoNgStore.prototype, {
    aircrafts: {
      get: function () {
        return this._entities;
      }
    }
  });

  var aircraftInfoNgStore = new AircraftInfoNgStore();

  this.$get = ['aircraftInfoStore', 'userService', function aircraftInfoNgStoreFactory(aircraftInfoStore, userService) {
    aircraftInfoNgStore.store = aircraftInfoStore;
    userService.off('login', aircraftInfoNgStore.refresh, aircraftInfoNgStore);
    userService.on('login', aircraftInfoNgStore.refresh, aircraftInfoNgStore);
    return aircraftInfoNgStore;
  }];

}]);
