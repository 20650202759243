App.directive('latitudeValidate', function() {
  'use strict';
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, element, attrs, ngModelCtrl) {

      // Latitude should be a number with up to 10 digits of precision
      // Should be between 90 and -90

      let latLongPattern = /^(-|)\d{1,2}(\.\d{1,10}|)$/;

      function testLatitude(latitude) {
        let parsedLatitude = parseFloat(latitude);
        return !Number.isNaN(parsedLatitude) && Math.abs(parsedLatitude) <= 90;
      }

      function validateLatitude(value) {
        let matchesPattern = latLongPattern.test(value);
        let withinRange = testLatitude(value);

        ngModelCtrl.$setValidity('latitudeValidatePattern', matchesPattern);
        ngModelCtrl.$setValidity('latitudeValidateRange', withinRange);
        ngModelCtrl.$setValidity('latitudeValidate', (matchesPattern && withinRange));

        return (matchesPattern && withinRange) ? value : undefined;
      }

      ngModelCtrl.$parsers.unshift(function(value) {
        return validateLatitude(value);
      });

      ngModelCtrl.$formatters.unshift(function(value) {
        return validateLatitude(value);
      });

    }
  };
});
