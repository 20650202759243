/*jslint browser: true, nomen: true
 */
/*global _, $, App, moment */

App.controller('flightDetailCrewController', ['$rootScope', '$scope', 'flightNgStore', function ($rootScope, $scope, flightNgStore) {
    'use strict';
  
    function updateCrew() {
        if ($scope.selectedFlight) {
          $scope.flight = $scope.selectedFlight;
        } 
      }
    
      $scope.$watch('selectedFlight', function () {
        updateCrew();
      }, false);
    
      updateCrew();
  
  }]);
  