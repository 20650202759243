/*jslint browser: true, plusplus:true*/
/*global angular, ol, App */

(function () {
  'use strict';
  function AdminPageCtrl($rootScope, $scope, $state) {
    if (!$rootScope.isLogged() || !$rootScope.isAdmin()) {
      var currentState = $state.current.name,
        currentParam = $state.params;
      $state.go('login', {
        returnTo: currentState,
        returnToParams: currentParam
      });
      return;
    }
  }

  App
    .controller('AdminPageCtrl', ['$rootScope', '$scope', '$state', AdminPageCtrl]);
}());
