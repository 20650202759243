(function (global) {
    'use strict';
    let generalConstants = {
        planeFeatureName : "planeFeature",
        aircraftLabelPositionKey : "labelPositions",
        navLayerName: 'navLayer',
        weatherLayerTitle: 'SE layers',
        deskFieldName: 'desk',
        otherFieldName: 'isOther'
    };

    App.constant('generalConstants', generalConstants);
}(window));
