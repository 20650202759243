/*jslint browser: true, plusplus:true*/
/*global App, $, angular */

(function (global) {
  'use strict';

  function StatisticsAlertsCtrl($scope, $state, $uibModal, $stateParams, dataStore, aircraftInfoNgStore) {

    // Select aircraft dialog
    $scope.selectAircraft = function () {
      $uibModal.open({
        templateUrl: 'views/partials/statistics/select-aircraft-form.html',
        controller: 'SelectAircraftFormCtrl'
      }).result['finally'](function () {
        $state.go('stats.alerts');
      });
    };

    //Date Picker configuration
    $scope.today = function () {
      $scope.dt1 = new Date();
      $scope.dt2 = new Date();
    };
    $scope.today();

    $scope.clear = function () {
      $scope.dt1 = null;
      $scope.dt2 = null;
    };

    $scope.aircrafts = aircraftInfoNgStore.aircrafts;

    //    $scope.aircraftsSelected = "0 aircraft ";
    //    $scope.countSelectedAircrafts = function () {
    //      $scope.count = $('#aircraftList li input:checked').length;
    //      $scope.aircraftsSelected = $scope.count + " aircraft ";
    //    };

    // Disable weekend selection
    $scope.disabled = function (date, mode) {
      return (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6));
    };

    $scope.open1 = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();

      $scope.opened1 = true;
    };

    $scope.open2 = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();

      $scope.opened2 = true;
    };

    $scope.dateOptions = {
      formatYear: 'yy',
      startingDay: 1
    };

    $scope.formats = ['dd MMMM yyyy'];
    $scope.format = $scope.formats[0];

    var tomorrow = new Date(),
      afterTomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    afterTomorrow.setDate(tomorrow.getDate() + 2);
    $scope.events = [{
      date: tomorrow,
      status: 'full'
    }, {
      date: afterTomorrow,
      status: 'partially'
    }];

    $scope.getDayClass = function (date, mode) {
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0, 0, 0, 0),
          i,
          currentDay;

        for (i = 0; i < $scope.events.length; i++) {
          currentDay = new Date($scope.events[i].date).setHours(0, 0, 0, 0);

          if (dayToCheck === currentDay) {
            return $scope.events[i].status;
          }
        }
      }

      return '';
    };
  }

  App
    .controller('StatisticsAlertsCtrl', ['$scope', '$state', '$uibModal', '$stateParams', 'dataStore', 'aircraftInfoNgStore', StatisticsAlertsCtrl]);

}(window));
