/*jslint browser: true */
/* global _ */

(function (global) {
  'use strict';

  global.MimeTypeHelper = {
    parse: function (rawData) {
      var mimeType, parts, types;

      mimeType = {};
      if (rawData) {
        parts = rawData.split(';');
        // base type
        mimeType.baseType = parts[0];
        types = parts[0].split('/');
        mimeType.primaryType = types[0];
        mimeType.subType = types[1];
        parts.shift();
        mimeType.parameters = {};
        parts.map(function (part) {
          var partSplit = part.split('=');
          mimeType.parameters[partSplit[0]] = partSplit[1];
        });
      }
      return mimeType;
    }
  };

}(window));
