/*jslint browser: true, plusplus:true, nomen:true*/
/*global angular, ol, App, Promise, _ */

(function () {
  'use strict';

  function AirspacePageCtrl($rootScope, $scope, $state, $stateParams, $q, $filter, featureNgStore) {
    var currentState, currentParam, tafUrl, metarUrl, filter, notamUrl;
    
    $scope.isShowPolygon = false;

    if (!$rootScope.isLogged()) {
      currentState = $state.current.name;
      currentParam = $state.params;
      $state.go('login', {
        returnTo: currentState,
        returnToParams: currentParam
      });
      return;
    }
    filter = $filter('filter');
    $scope.showCapacity = false;

    function updateFeatures(updated, resized) {
      $scope.features = featureNgStore.features;
      $scope.myFeatures = featureNgStore.myFeatures;
      $scope.myAirspaces = featureNgStore.airspaceFeatures;
      $scope.othersFeatures = featureNgStore.othersFeatures;
      $scope.$applyAsync();
    }

    //
    // Current airspace
    // ------------------------------------------------------------------------

    $scope.airspaceUpdate = function (id) {
      $scope.airspace = null;
      if (!!id) {
        // try to find details
        featureNgStore.loadData(true).then(function (airspaces) {
          var airspace;
          airspace = featureNgStore.findById(id);
          if (_.isUndefined(airspace)) {
            //we did not find a match
            // Search state 
            $scope.airspaceId = null;
            $state.go('airspace.search');
          } else {
            $scope.airspace = airspace;
            if (!!airspace.olFeature.get('C')) {
              $scope.airspace.center = airspace.olFeature.get('C').label;
            }
            
            //$scope.mapCenter = featureNgStore.featuresById[$scope.airspace.id].olFeature.get('C').data;
          }
        });

      } else {
        // Search state 
        $scope.airspace = null;
        $state.go('airspace.search');
      }
    };
    
    
    
    //
    // Current flightIdsTab
    // ------------------------------------------------------------------------

    $scope.flightIdsTabUpdate = function (tab) {
      $scope.flightIdsTab = null;
      if (!!tab) {
        $scope.flightIdsTab = tab;

      }
    };
    

    // airspace coordinates on map
    $scope.showOnMap = function () {
      // set airspace coordinates
      var extent = featureNgStore.featuresById[$scope.airspace.id].olFeature.getGeometry().getExtent();
      localStorage.setItem('mapCenter', JSON.stringify(ol.extent.getCenter([134533.947096178, 6087139.184518291, 452817.732875652, 6088362.176970857])));
      // DO NOT CHANGE RESOLUTION AT THIS TIME
      // BECAUSE WE NEED TO KNOWN THE MAP SIZE TO COMPUTE ACCURATE ONE
      // localStorage.setItem('mapResolution', 20);
      // go to map
      $state.go('map');
    };
    
    $scope.showPolygon = function () {
      $scope.isShowPolygon = !$scope.isShowPolygon;
    };

    //listen for drawing
    featureNgStore.on('sync', updateFeatures, $scope);
    featureNgStore.refresh();

    $scope.$on('$destroy', function () {
      featureNgStore.off('sync', updateFeatures, $scope);
    });

  }
  App
    .controller('AirspacePageCtrl', ['$rootScope', '$scope', '$state', '$stateParams', '$q', '$filter', 'featureNgStore', AirspacePageCtrl]);
}());
