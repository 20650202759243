/*jslint browser: true, nomen: true */
/*global $, angular, ol, _, drawTooltipBg, FlightLevelFormatter */

(function (global) {
  'use strict';

  var defaulttpl = {
    fields: ['flightId', 'flightLevel']
  };


  function AircraftTooltipProvider(colors, generalConstants) {
    this.defaultColors = colors;
    this.generalConstants = generalConstants;
  }

  AircraftTooltipProvider.prototype = Object.create({
    /*
     *Feature styles provider
     */
    updateColors: function (colors) {
      this.opts = colors || {};
      // if(this.opts !=  {}){
      this.tooltipImgs = this.createTooltipImgs();
      this.tooltipImgs.id = _.uniqueId();
      // }
    },

    getTooltipText: function (feature) {
      var alt = feature.get('alt');
      var fl = feature.get('flightLevel');
      var phase = feature.get('phase');
      var textLines = [];
      var flightId = feature.get('flightId') || '--';
      var deviation = '';
      if (phase === 'CLIMB') {
        deviation = '\u2197\ufe0e';
      } else if (phase === 'DESCENT') {
        deviation = '\u2198\ufe0e';
      } else if (phase === 'CRUISE') {
        deviation = '\u279d\ufe0e';
      }
      var userProfile = userProfileStore.userProfile;
      var defaultTemplate = userProfile.defaultLabelTemplate;
      var specificTemplate = userProfile.specificLabelTemplates[feature.get('acn')];

      var tpl = [];
      if (specificTemplate && specificTemplate.fields) {
        tpl = specificTemplate;
      } else if (defaultTemplate && defaultTemplate.fields) {
        tpl = defaultTemplate;
      } else {
        tpl = defaulttpl;
      }

      tpl.fields.forEach(function (field) {
        var def = fieldDefinitions[field];
        var value = feature.get(field);
        if (def.formatFn) {
          value = def.formatFn.call(value, value);
        }
        if (field == 'flightLevel') {
          textLines.push(value + deviation || '--');
        } else {
          textLines.push(_.isNil(value) ? '--' : value);
        }
      });

      return textLines;
    },

    createTooltipImgs : function(){

      //#238 générer avec une boucle toutes les images, et les mettre dans un tableau pour chaque type
      var size = 10;
      var totalNumberOfPositions = 4;
      var myfleetTooltipImg = [];
      var trackedTooltipImg = [];
      var otherTooltipImg = [];
      var closedTooltipImg = [];
      var cautionTooltipImg = [];
      var warningTooltipImg = [];
      var tooltip = {
        'myfleetTooltipImg': myfleetTooltipImg,
        'trackedTooltipImg': trackedTooltipImg,
        'otherTooltipImg': otherTooltipImg,
        'closedTooltipImg': closedTooltipImg,
        'cautionTooltipImg': cautionTooltipImg,
        'warningTooltipImg': warningTooltipImg
      };
      //#238
      // 0: other, closed, tracked, myfleet
      // 1 : caution
      // 2 : warning

      for (var i = 0; i < size; i++) {
        for (var j = 0; j < totalNumberOfPositions; j++) {
            var image1 = drawTooltipBg('rgba(0,0,0,0.2)', false, i + 1, j);
            tooltip.myfleetTooltipImg.push(image1);
            var imageTrackedLabelBg = drawTooltipBg(this.defaultColors.trackedLabelBg, false, i + 1, j);
            tooltip.trackedTooltipImg.push(imageTrackedLabelBg);
            tooltip.otherTooltipImg.push(image1);
            tooltip.closedTooltipImg.push(image1);
            var image2 = drawTooltipBg(this.opts.cautionColor || this.defaultColors.caution, false, i + 1, j);
            var image3 = drawTooltipBg(this.opts.warningColor || this.defaultColors.warning, true, i + 1, j);
            tooltip.cautionTooltipImg.push(image2);
            tooltip.warningTooltipImg.push(image3);
        }
      }

      return [
        tooltip.trackedTooltipImg,
        tooltip.cautionTooltipImg,
        tooltip.warningTooltipImg,
        tooltip.myfleetTooltipImg,          
        tooltip.otherTooltipImg,
        tooltip.closedTooltipImg,
      ];
    },

    getSurfaceTrackingTooltipOLStyle : function(feature, userProfile) {
    	var tooltips, zIndex;
        tooltips = new Array(2);
        zIndex = 0;
        var labelFont = 'small';

        if (tooltips) {
                tooltips[0] = new ol.style.Style({
                                        zIndex: 1
                                      });
                                      tooltips[0].setImage(new ol.style.Icon({
                                        anchor: [25,70],
                                        size: [104, 3 * 12 + 22],
                                        anchorOrigin: 'bottom-left',
                                        anchorXUnits: 'pixels',
                                        anchorYUnits: 'pixels',
                                        src: drawTooltipBg(this.defaultColors.other, false, 3, 2)
                                      }));
                tooltips[1] = new ol.style.Style({
                                            zIndex: 1
                                          });
                                        tooltips[1].setImage(new ol.style.Icon({
                                          anchor: [25,70],
                                          size: [70, 3 * 12 + 22],
                                          anchorOrigin: 'bottom-left',
                                          anchorXUnits: 'pixels',
                                          anchorYUnits: 'pixels',
                                          src: drawTooltipText(feature.get('fieldsToDisplay'), labelFont, 'black')
                                        }));
      }
      return tooltips;
    },

    getTooltipOLStyle : function(feature, userProfile) {
        let aircraftLabelPositions = angular.fromJson(sessionStorage.getItem(this.generalConstants.aircraftLabelPositionKey)) || {};

        let anchorCoordinates = [[25,-15], [-20,20], [25,60], [100,20]];//top, right, bottom and left with respect to aircraft icon
        let anchorCoordinatesIndex = aircraftLabelPositions[feature.get('id')] ? aircraftLabelPositions[feature.get('id')] - 1 : 0;
        var totalNumberOfPositions = 4;

    	 var ttstyles, phase, deviation, alt, fl, flightId, background, state, alertLvl, zIndex, textLines, lastUpdate;
         ttstyles = feature.tooltip;
         lastUpdate = feature.get('lastUpdate');
         var labelFont = JSON.parse(userProfileStore.userProfile.preferencesJson).mapOptions.labels.font;
         alertLvl = !!feature.get('closed') ? 4 : !!feature.get('customerAircraft') ? feature.get('alertLvl') || 0 : /* others */ 3;
         zIndex = (alertLvl + 1) % 4;
         var alertLevelOfFlight = feature.get('alertLvl');
		 textLines = this.getTooltipText(feature);
         if (textLines.length === 0) {
           return [];
         }
         else {
           let multiplier = textLines.length > 2 ? 10 : 1;
           //bottom position adjustment according to number of lines
           anchorCoordinates[2] = [anchorCoordinates[2][0], anchorCoordinates[2][1] + textLines.length * multiplier];

           //left and right position adjustment according to number of lines
           multiplier = textLines.length > 2 ? 5 : 1;
           anchorCoordinates[1] = [anchorCoordinates[1][0], anchorCoordinates[1][1] + textLines.length * multiplier];
           anchorCoordinates[3] = [anchorCoordinates[3][0], anchorCoordinates[3][1] + textLines.length * multiplier];
         }

         if (ttstyles === undefined) {
           ttstyles = new Array(2);
         }

        let isDifferentAnchorForLabel = ttstyles.anchorCoordinates && ttstyles.anchorCoordinates.toString() !== anchorCoordinates[anchorCoordinatesIndex].toString();

        if (isDifferentAnchorForLabel || this.tooltipImgs.id !== ttstyles.tooltipId || alertLvl !== ttstyles.alertLvl || textLines.length !== _.size(ttstyles && ttstyles.text)) {
            //check if alert lvl has changed
		   ttstyles[0] = ttstyles[0]||  new ol.style.Style({
             zIndex: zIndex
           });
           ttstyles[0].setImage(new ol.style.Icon({
             anchor: anchorCoordinates[anchorCoordinatesIndex],
             size: [104, textLines.length * 12 + 22],
             anchorOrigin: 'bottom-left',
             anchorXUnits: 'pixels',
             anchorYUnits: 'pixels',
             src: this.tooltipImgs[alertLvl][totalNumberOfPositions*(textLines.length - 1)+anchorCoordinatesIndex]
           }));
           ttstyles.alertLvl = alertLvl;
           ttstyles.tooltipId = this.tooltipImgs.id;
           ttstyles.anchorCoordinates = anchorCoordinates[anchorCoordinatesIndex];
         }

         if (isDifferentAnchorForLabel || !_.isEqual(textLines, ttstyles && ttstyles.text)) {

			 ttstyles[1] =   ttstyles[1]|| new ol.style.Style({
               zIndex: zIndex
             });
		   //no need to rerender the exact same text
           ttstyles[1].setImage(new ol.style.Icon({
             anchor: anchorCoordinates[anchorCoordinatesIndex],
             size: [70, textLines.length * 12 + 22],
             anchorOrigin: 'bottom-left',
             anchorXUnits: 'pixels',
             anchorYUnits: 'pixels',
             src: drawTooltipText(textLines, labelFont.toLowerCase(), 'white')
           }));
           ttstyles.text = textLines;
         }
         ttstyles.update = lastUpdate;
         feature.tooltip = ttstyles; //cache computed style
        //}

      return ttstyles;

    },

    setDefaultColors : function(colors){
      this.defaultColors = colors;
    }
  });

  var tooltipStyleInstance = null;
  var userProfileStore = null;
  var fieldDefinitions = null;

  App.factory('aircraftTooltipProvider', ['userProfileNgStore', 'generalConstants', 'flightfieldDefinitions', 'defaultColors', function aircraftTooltipProvider(userProfileNgStore, generalConstants, flightfieldDefinitions, defaultColors) {
    tooltipStyleInstance = tooltipStyleInstance || new AircraftTooltipProvider(defaultColors, generalConstants);
    userProfileStore = userProfileNgStore;
    fieldDefinitions = flightfieldDefinitions;
    return tooltipStyleInstance;
  }]);
})();
