/*jslint browser: true, nomen: true */
/*global App, Plumbing, _ */

App.provider('customerStore', ['customerId', function customerStoreProvider(customerId) {
  'use strict';

  var opts, getPath, store;

  opts = {
    path: {
      customer: '/customer/',
      customerLabelTemplate: '/customer-label-templates/', 
      templateAssociation: '/aircraft-customer-template-allocation/',
      defaultTemplate: '/customer-default-template/'
    }
  };

  getPath = function () {
    if (_.isFinite(customerId.get())) {
      return window.backendPrefix + opts.path.customer + customerId.get();
    }
  };

  store = new Plumbing({
    headers: opts.headers,
    url: getPath,
    delay: 120000
  });

  customerId.on('update', function (oldId, newId) {
    if (oldId && oldId !== newId) {
      if (_.isUndefined(newId)) {
        store.adapter.stop();
      } else {
        store.refresh();
      }
    }
  }, this);

  this.setCustomerPart = function (customer) {
    opts.path.customer = customer;
  };

  store.getTemplates = function(templates) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + opts.path.customerLabelTemplate, {
        method: 'get',
        credentials: 'include'
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, reject);
        } else {
          reject(response.statusText);
        }
      }, reject);
    }.bind(this));
  };

  store.getTemplateAssociation = function () {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + opts.path.templateAssociation, {
        method: 'get',
        credentials: 'include'
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, reject);
        } else {
          reject(response.statusText);
        }
      }, reject);
    }.bind(this));
  };

  store.getDefaultTemplate = function () {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + opts.path.defaultTemplate, {
        method: 'get',
        credentials: 'include'
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, reject);
        } else {
          reject(response.statusText);
        }
      }, reject);
    }.bind(this));
  };

  store.saveTemplateAllocation = function (input) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + opts.path.templateAssociation, {
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(input)
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, reject);
        } else {
          reject(response.statusText);
        }
      }, reject);
    }.bind(this));
  };

  store.saveDefaultTemplate = function (input) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + opts.path.defaultTemplate, {
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(input.id)
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, reject);
        } else {
          reject(response.statusText);
        }
      }, reject);
    }.bind(this));
  };

  store.saveTemplates = function(templates) {
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + opts.path.customerLabelTemplate, {
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(templates)
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, reject);
        } else {
          reject(response.statusText);
        }
      }, reject);
    }.bind(this));
  };

  this.$get = [function customerStoreFactory() {
    return store;
  }];

}]);
