/*jslint browser: true, nomen: true, plusplus:true */
/*global _, App, Plumbing, Promise, ol, angular, GenericFilteredStore, moment, FlightLevelFormatter, Deviation */
App.controller('activeWeatherLayersController', ['$scope', '$rootScope', 'seLayers', '$timeout', '$window', 'userService', function ($scope, $rootScope, seLayers, $timeout, $window, userService) {
    'use strict';
    
    var vm = this;
    var angular_window = angular.element($window);

    // Resize on window resize
    angular_window.bind('resize', function () {
        vm.resize();
    });

    // Resize on page ready
    angular.element(function () {
        vm.resize();
    });

    vm.$onInit = function() {
        vm.resize();
    };

    $scope.weatherLayers = [];
    $scope.open = true;

    vm.updateList =  function(weatherLayers, hiddenLayers, weatherOptions) {

        $scope.weatherLayers = [];

        for (const layer of weatherLayers){

            if(seLayers[layer] && !hiddenLayers.includes(layer)) {

                if(seLayers[layer].displayName) {
                    let activeLayer = {displayName:seLayers[layer].displayName, id:layer};

                    if(layer == 'forecastWindsTempAloft' || layer == 'forecastWindsSpeed') {
                        activeLayer.flightLevel = 'FL ' + weatherOptions.forecastWindsSpeed.param;
                    }
                    else if(layer == 'currentWindsTempAloft' || layer == 'currentWindsSpeed') {
                        activeLayer.flightLevel = 'FL ' + weatherOptions.currentWindsTempAloft.param;
                    }
                    else if(layer == 'icingForecastGlobal') {
                        activeLayer.flightLevel = 'FL ' + weatherOptions.icingForecastGlobal.param;
                        activeLayer.aircraftSize = ' ' + weatherOptions.icingForecastGlobal.param3;
                    }
                    else if(layer == 'icingForecastNam') {
                        activeLayer.flightLevel = 'FL ' + weatherOptions.icingForecastNam.param;
                        activeLayer.aircraftSize = ' ' + weatherOptions.icingForecastNam.param3;
                    }    
                    else if(layer == 'edrTurbulenceGlobal') {
                        activeLayer.flightLevel = 'FL ' + weatherOptions.edrTurbulenceGlobal.param;
                    }
                    else if(layer == 'edrTurbulenceNam') {
                        activeLayer.flightLevel = 'FL ' + weatherOptions.edrTurbulenceNam.param;
                    }

                    $scope.weatherLayers.push(activeLayer);
                }
            }
        }

        $timeout(vm.updateValidTime, 5000, true,weatherOptions);
    }

    vm.updateValidTime = function (weatherOptions) {

        let animatedLayerList = ["radarUsAnimated", "radarEuAnimated", "radarCnAnimated", "radarAuAnimated", "radarJaAnimated"];
        let animatedCookieList = ["RADAR_US_CURRENT", "RADAR_EU_CURRENT", "RADAR_CN_CURRENT", "RADAR_AU_CURRENT", "RADAR_JA_CURRENT"];

        $scope.weatherLayers.forEach( function(activeLayer) {

            let seLayer = getSeLayers(activeLayer.id, weatherOptions);
            let cookies = get_cookies_array();
            let cookieKeys = Object.keys(cookies);

            cookieKeys.forEach( function (key) {

                if(animatedLayerList.indexOf(seLayer.layerName) >= 0) {
                    if(key.includes(animatedCookieList[animatedLayerList.indexOf(seLayer.layerName)])) {
                        activeLayer.validTime = parseDate(cookies[key]);
                    }
                } else {
                    if(key.includes(seLayer.name)) {
                        activeLayer.validTime = parseDate(cookies[key]);
                    }
                }

            });

        });
    }

    $rootScope.$on("WeatherUpdated", function (event, selected, hidden, weatherOptions) {
        vm.resize();
        vm.updateList(selected, hidden, weatherOptions);
    });


    vm.updateList($rootScope.user.preferences.mapOptions.se.selectedSeLayers, $rootScope.user.preferences.mapOptions.se.hiddenLayers, $rootScope.user.preferences.mapOptions.se.weather);


function parseDate(date) {

        var timestamp = date.split(' ')[0];

        var myDate = new Date(timestamp);
        var minutes = moment(myDate).tz(userService.getUserPreference('timeZone')).format('mm');
        var hours = moment(myDate).tz(userService.getUserPreference('timeZone')).format('HH');
        return hours + ':' + minutes;

    }


    function getSeLayers(layerName, weatherOptions) {

        var def = seLayers[layerName];

        if (def) {

            if (weatherOptions[layerName]) {

                var param;
                var param2;
                var param3;

                if(def.apiVersion && def.apiVersion === 2) {
                    // If layer has both time and flight level, the first parameter is flight level.
                    if(weatherOptions[layerName].param && weatherOptions[layerName].param2) {
                        param = weatherOptions[layerName].param;
                        param2 = weatherOptions[layerName].param2;
                        param3 = weatherOptions[layerName].param3;

                        var flightLevel = isNaN(param) ? param : 'FL' + param;

                        return _.assign({}, def, {
                            layerName: layerName,
                            queryName: def.name,
                            name: def.name,
                            time: getUTCTime(param2),
                            flightLevel: flightLevel,
                            aircraftSize: param3
                        });
                    // If layer has only time, the first parameter is time.
                    } else {
                        param = weatherOptions[layerName].param;

                        return _.assign({}, def, {
                            layerName: layerName,
                            queryName: def.name,
                            name: def.name,
                            time: getUTCTime(param),
                            aircraftSize: param3,
                        });
                    }
                } else {

                    if (weatherOptions[layerName]) {
                        param = weatherOptions[layerName].param;
                        param2 = getLayerTimePart(weatherOptions[layerName].param2);
                        param3 = weatherOptions[layerName].param3;
                    }
                }
            }

            var name = typeof def.name === 'function' ? def.name(param, param2) : def.name;
            return _.assign({}, def, {
                layerName: layerName,
                queryName: name,
                name: name
            });
            
        } else {
            return null;
        } 
    }

    function getLayerTimePart(timeVal) {
        var timePart = 'CURRENT';
        if (timeVal != "Current") {
          timePart = timeVal + "HR";
        }
        return timePart;
      }

  function getUTCTime(hours) {
        var date = new Date();

        if (!isNaN(hours)) {
            date.setHours(date.getHours() + parseInt(hours, 10));
        }

        var utcDateTime = date.toISOString();
        utcDateTime = moment(utcDateTime).tz(userService.getUserPreference('timeZone'));
        return encodeURI(utcDateTime);
    }

    function get_cookies_array() {

        var cookies = { };
    
        if (document.cookie && document.cookie != '') {
            var split = document.cookie.split(';');
            for (var i = 0; i < split.length; i++) {
                var name_value = split[i].split("=");
                name_value[0] = name_value[0].replace(/^ /, '');
                cookies[decodeURIComponent(name_value[0])] = decodeURIComponent(name_value[1]);
            }
        }
    
        return cookies;
       
    }

    vm.resize = function() {
        let windowBottomPadding = 150;
        let widgetBottomPadding = 20;
    
        let max = $(document).height() - $('#active-weather-layers').offset().top;
        $('#active-weather-layers').css('max-height', max - windowBottomPadding);
    
        let innerMax = $(document).height() - $('#active-weather-layers-scroller').offset().top;
        $('#active-weather-layers-scroller').css('max-height', innerMax - windowBottomPadding - widgetBottomPadding);
    };
    
    $scope.toggleVisibility = function () {

        if($scope.open) {
            $('#active-weather-layers-scroller').css('display', 'none');
            $('#active-weather-layers-hr').css('display', 'none');
        } else {
            $('#active-weather-layers-scroller').css('display', 'block');
            $('#active-weather-layers-hr').css('display', 'block');
        }

        $scope.open = !$scope.open;
    };

}]);
  