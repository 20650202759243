/*jslint browser: true, nomen: true */
/*global _, App, Plumbing, Promise, PubSub, angular */

(function (global) {
  'use strict';

  function GenericStore() {
    PubSub.call(this);
    this._useItemId = true;
    this._entities = [];
    this._entityById = {};
  }

  GenericStore.prototype = Object.create(PubSub.prototype);

  angular.extend(GenericStore.prototype, {

    //
    // Constructor

    constructor: GenericStore,

    //
    // Overridable

    newInstance: function (values) {
      return {
        id: values.id.toString()
      };
    },

    updateInstance: function (entity, values, changed) {},


    getId: function (entity) {
      // deskassignment doesn't have id
      return entity.id !== undefined ? entity.id.toString() : undefined;
    },

    find: function (key) {
      if (_.isObject(key)) {
        return _.find(this._entities, key);
      }
      return this._entityById[key];
    },

    //
    // Config

    setUseItemId: function (useItemId) {
      this._useItemId = useItemId;
    },

    //
    // CRUD

    add: function (entity) {
      var _this0 = this,
        promise = new Promise(function (resolve, reject) {
          _this0._store.add(entity).then(function (entity) {
            // Create new entity
            var id = _this0.getId(entity),
              e = _this0.newInstance(entity);
            _this0._entities.push(e);
            _this0._entityById[id] = e;
            _this0.trigger('add', e);
            _this0.trigger('sync', false, true);
            resolve(e);
            // _this0.refresh();
          }, function (error) {
            reject(error);
          });
        });
      return promise;
    },
    set: function (entity) {
      var _this0 = this,
        promise = new Promise(function (resolve, reject) {
          var itemId = _this0._useItemId ? _this0.getId(entity) : undefined;
          _this0._store.set(entity, {
            itemId: itemId
          }).then(function (entity) {
            var id = _this0.getId(entity),
              e = _this0._entityById[id];
            // Update existing
            _this0.updateInstance(e, entity, true);
            _this0.trigger('update', e);
            _this0.trigger('sync', true, false);
            resolve(e);
            //_this0.refresh();
          }, function (error) {
            reject(error);
          });
        });
      return promise;
    },
    remove: function (entity) {
      var _this0 = this,
        promise = new Promise(function (resolve, reject) {
          var itemId = _this0._useItemId ? _this0.getId(entity) : undefined;
          _this0._store.remove(entity, {
            itemId: itemId
          }).then(function () {
            var e = _this0._entityById[_this0.getId(entity)];
            _this0._entities.splice(_this0._entities.indexOf(e), 1);
            delete _this0._entityById[_this0.getId(entity)];
            _this0.trigger('remove', e);
            _this0.trigger('sync', false, true);
            resolve();
            //_this0.refresh();
          }, function (error) {
            reject(error);
          });
        });
      return promise;
    },
    get: function (itemId) {
      var _this0 = this,
        promise = new Promise(function (resolve, reject) {
          _this0._store.get({
            itemId: itemId
          }).then(function (entity) {
            var id = _this0.getId(entity),
              e = _this0._entityById[id];
            // Update existing
            if (_this0.isEntityUpdated(id, entity, e)) {
              _this0.handleUpdate(id, entity, e);
            } else {
              _this0.handleSame(id, entity, e);
            }
            _this0.trigger('sync', true, false);
            resolve(entity);
          }, function (error) {
            reject(error);
          });
        });
      return promise;
    },

    //
    // force refresh

    refresh: function () {
      if (this._store) {
        this._store.refresh();
      }
    },

    unload: function () {
      this.store = void 0;
      this.trigger('sync', true, true);
    },

    //
    // modification management

    handleAddition: function (id, values) {
      var e = this.newInstance(values);
      this._entities.push(e);
      this._entityById[id] = e;
      this.trigger('add', e);
      return e;
    },
    handleDeletion: function (id) {
      var e = this._entityById[id];
      this._entities.splice(this._entities.indexOf(e), 1);
      delete this._entityById[id];
      this.trigger('remove', e);
      return e;
    },
    handleUpdate: function (id, values, entity) {
      this.updateInstance(entity, values, true);
      this.trigger('update', entity);
      return entity;
    },
    handleSame: function (id, values, entity) {
      this.updateInstance(entity, values, false);
      this.trigger('same', entity);
      return entity;
    },
    isEntityUpdated: function (id, values, entity) {
      return true;
    },
    _onStoreDataChange: function (error, entitiesData) {
      if (error) {
        this.trigger('error', error);
        return;
      }
/*      if (window.map) {
        window.map.beginBatchUpdate();
      }*/
      var valueChanged, entityIds, lengthChanged, entities = entitiesData;
      if (!(entities instanceof Array)) {
        entities = [entitiesData];
      }
      valueChanged = false;
      lengthChanged = false;
      // get all previous entities
      entityIds = Object.keys(this._entityById);
      entities.forEach(function (entity) {
        // use index as id for deskassignment
        var id = this.getId(entity) !== undefined ? this.getId(entity) : entities.indexOf(entity),
        e = this._entityById[id];
        if (typeof e === 'undefined') {
          // Create new entity
          this.handleAddition(id, entity);
          lengthChanged = true;
        } else {
          // Update existing
          entityIds.splice(entityIds.indexOf(id), 1);
          if (this.isEntityUpdated(id, entity, e)) {
            this.handleUpdate(id, entity, e);
            valueChanged = true;
          } else {
            this.handleSame(id, entity, e);
          }
        }

      }, this);
      // Remove unused entities
      entityIds.forEach(this.handleDeletion, this);
      lengthChanged = lengthChanged || entityIds.length > 0;
      this.trigger('sync', valueChanged, lengthChanged);
/*      if (window.map) {
        window.map.endBatchUpdate();
      }*/
    }

  });

  Object.defineProperties(GenericStore.prototype, {
    entities: {
      get: function () {
        return this._entities;
      }
    },
    store: {
      set: function (store) {
        if (typeof this._store !== 'undefined') {
          if (this._store === store) {
            return;
          }
          this._store.off('change', this._onStoreDataChange, this);
        }
        if (this._entities) {
            this._entities.splice(0, this._entities.length); //clear
        }
        this._store = store;
        this._entityById = {};
        if (typeof store !== 'undefined' && store instanceof Plumbing) {
          store.on('change', this._onStoreDataChange, this);
        }
      }
    }
  });

  global.GenericStore = GenericStore;

}(window));
