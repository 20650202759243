/*jslint browser: true, plusplus: true, nomen: true */
/*global App, ServerTime, moment, _ */

(function (global) {
    'use strict';
    var defaultColors = {
        warning: '#FF0000',
        caution: '#FF6600',
        myfleet: '#0077C8',
        tracked: '#1DB1D2',
        other: '#B3B3B3',
        trackedLabelBg: '#383636',
        closed: '#FFFFFF',
        acars: '#FFFFFF',
        trajectory: '#74CEDE',
        external: [0, 0, 0, 0.5],
        otherPlanes: '#7472b3',
        waypoint: {
          normal: '#B3B3B3',
          crossed: '#74CEDE',
          fill: '#373737'
        },
        groundVehicle: '#B3B3B3'
      };
    App.constant('defaultColors', defaultColors);
}());