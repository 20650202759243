/*jslint browser: true, nomen: true
 */
/*global _, $, App, moment */

App.controller('flightDetailNotesController', ['$rootScope', '$scope', 'flightNgStore', function ($rootScope, $scope, flightNgStore) {
  'use strict';

  $scope.addNote = function () {
    $scope.creatingNote = true;
    var note = {
      content: '',
      sender: $rootScope.user.login,
      date: Date.now(),
      editable: true,
      isOpen: true
    };
    $scope.isEditing = true;
    $scope.notes.unshift(note);
    window.setTimeout(function () {
      $('#flightDetailsNotesTab textarea:not([disabled])').focus();
    }, 0);
  };

  $scope.remove = function (note) {
    flightNgStore.removeNote($scope.selectedFlight, note);
    var index = $scope.notes.indexOf(note);
    if (index !== -1) {
      $scope.notes.splice(index, 1);
    }
  };

  $scope.edit = function (note) {
    $scope.creatingNote = false;
    note.editable = !note.editable;
    $scope.isEditing = true;
    window.setTimeout(function () {
      $('#flightDetailsNotesTab textarea:not([disabled])').focus();
    }, 0);
  };

  $scope.save = function (note) {
    note.editable = !note.editable;
    if ($scope.creatingNote) {
      flightNgStore.addNote($scope.selectedFlight, note).then(function (savedNote) {
        note.id = savedNote.id;
      });
    } else {
      flightNgStore.setNote($scope.selectedFlight, note).then(function (savedNote) {
        note = savedNote;
      });
    }
    $scope.isEditing = false;
  };

  $scope.cancel = function (note) {
    if ($scope.creatingNote) {
      $scope.notes.shift();
    } else {
      note.editable = !note.editable;
    }
    $scope.isEditing = false;
  };

  $scope.isVisibleForSender = function (note) {
    return note.sender === $rootScope.user.login;
  };

  function updateNotes() {
    $scope.isEditing = false;
    $scope.creatingNote = false;
    if ($scope.selectedFlight) {
      $scope.notes = $scope.selectedFlight.notes;
      _.forEach($scope.notes, function (note) {
        note.editable = false;
      });
    } else {
      $scope.notes = [];
    }
  }

  $scope.$watch('selectedFlight', function () {
    updateNotes();
  }, false);

  updateNotes();


}]);
