/*jslint browser:true, vars: true */

(function (global) {
    'use strict';
    
    class OpacityLayer {
        constructor(brightness, zIndex) {
            this.projExtent = ol.proj.get('EPSG:3857').getExtent();

            this.opacityStyle = [new ol.style.Style({
                fill: new ol.style.Fill({
                    color: [0, 0, 0, 1 - brightness]
                })
            })];

            this.opacityFeature = new ol.Feature({
                id: 'opacityFeature',
                geometry: new ol.geom.Polygon([[
                    ol.extent.getBottomLeft(this.projExtent),
                    ol.extent.getBottomRight(this.projExtent),
                    ol.extent.getTopRight(this.projExtent),
                    ol.extent.getTopLeft(this.projExtent),
                    ol.extent.getBottomLeft(this.projExtent)
                ]])
            });
        
            this.opacitySource = new ol.source.Vector({
                features: new ol.Collection([this.opacityFeature])
            });

            this.opacityLayer = new ol.layer.Vector({
                zIndex: zIndex,
                visible: true,
                source: this.opacitySource,
                style: this.opacityStyle,
                updateWhileAnimating: true,
                updateWhileInteracting: true
            });
        }

        getLayer() {
            return this.opacityLayer;
        }

        changeBrightness(newBrightness) {
            this.opacityStyle[0].getFill().setColor(
                [0, 0, 0, 1 - newBrightness]
            );
            this.opacityFeature.changed();
        }

        setVisible(visible) {
            this.opacityLayer.setVisible(visible);
        }
    }

    function generator(brightness, zIndex = undefined) {
        return new OpacityLayer(brightness, zIndex)
    }

    global.generateOpacityLayer = generator;
}(window));