/*jslint browser: true, nomen: true, vars: true */
/*global App, _, ol, Intl */
(function () {
  'use strict';

  var coordRegExp, iso6709DRegExp, interpolate, transform, transformReverse, wgs84Sphere, locationOnMap, formatGeoJSON;

  wgs84Sphere = new ol.Sphere(6378137);
  coordRegExp = new RegExp('\\s*(-?[0-9]+(?:\\.[0-9]+)?\\s*),\\s*(-?[0-9]+(?:\\.[0-9]+)?)\\s*', 'i');
  iso6709DRegExp = new RegExp('\\s*([0-9]{1,2})\u00B0([0-9]{2})\u0027([0-9]{2}(?:\\.[0-9]*)?)\u0022([NS])\\s*([0-9]{1,3})\u00B0([0-9]{2})\u0027([0-9]{2}(?:\\.[0-9]*)?)\u0022([EW])\\s*');

  transform = ol.proj.getTransform('EPSG:4326', 'EPSG:3857');
  transformReverse = ol.proj.getTransform('EPSG:3857', 'EPSG:4326');


  interpolate = function (c1, c2, fraction) {
    var DEG2RAD = Math.PI / 180;
    var lat1 = DEG2RAD * c1[1];
    var lon1 = DEG2RAD * c1[0];
    var lat2 = DEG2RAD * c2[1];
    var lon2 = DEG2RAD * c2[0];
    var cosLat1 = Math.cos(lat1);
    var sinLat1 = Math.sin(lat1);
    var cosLat2 = Math.cos(lat2);
    var sinLat2 = Math.sin(lat2);
    var cosDeltaLon = Math.cos(lon2 - lon1);
    var d = sinLat1 * sinLat2 + cosLat1 * cosLat2 * cosDeltaLon;
    if (1 <= d) {
      return c2.slice();
    }
    d = fraction * Math.acos(d);
    var cosD = Math.cos(d);
    var sinD = Math.sin(d);
    var y = Math.sin(lon2 - lon1) * cosLat2;
    var x = cosLat1 * sinLat2 - sinLat1 * cosLat2 * cosDeltaLon;
    var theta = Math.atan2(y, x);
    var lat = Math.asin(sinLat1 * cosD + cosLat1 * sinD * Math.cos(theta));
    var lon = lon1 + Math.atan2(Math.sin(theta) * sinD * cosLat1,
      cosD - sinLat1 * Math.sin(lat));
    return [lon / DEG2RAD, lat / DEG2RAD];
  };

  locationOnMap = function (name, coordinate, layer) {
    var feature, geometry;

    geometry = new ol.geom.Point(coordinate);
    feature = new ol.Feature({
      geometry: geometry,
      'class': 'location',
      name: name
    });
    layer.getSource().addFeature(feature);
    return feature;
  };

  formatGeoJSON = new ol.format.GeoJSON({
    defaultDataProjection: 'EPSG:4326'
  });

  App.controller('MapToolboxPanelCtrl', ['$scope', function ($scope) {

    $scope.$watch('toolbox.isMeasure', function (newValue) {
      $scope.layers.measureLayer.setVisible($scope.toolbox.isMeasure);
    });

  }]);

  App.controller('MapMeasureCtrl', ['$scope', '$element', 'airportDataNgStore', 'flightNgStore', 'iso6709Filter', function ($scope, $element, airportDataNgStore, flightNgStore, iso6709Filter) {

    var locA, locB, measure, field, pointerEventHandler, pointerModeOn, pointerModeOff;

    $scope.candidates = function ($viewValue) {
      var matches = [],
        viewValue = $viewValue.toUpperCase(),
        coords,
        lat,
        long;

      if ($viewValue) {
        if (coordRegExp.test($viewValue)) {
          coords = coordRegExp.exec($viewValue);
          lat = parseFloat(coords[1]);
          long = parseFloat(coords[2]);
          matches.push({
            data: $viewValue,
            label: $viewValue,
            lat: lat,
            long: long
          });
        } else if (iso6709DRegExp.test($viewValue)) {
          coords = iso6709DRegExp.exec($viewValue);
          lat = (parseInt(coords[1], 10) + parseInt(coords[2], 10) / 60 + parseFloat(coords[3]) / 3600) * (coords[4] === 'N' ? 1 : -1);
          long = (parseInt(coords[5], 10) + parseInt(coords[6], 10) / 60 + parseFloat(coords[7]) / 3600) * (coords[8] === 'E' ? 1 : -1);
          matches.push({
            data: $viewValue,
            label: $viewValue,
            lat: lat,
            long: long
          });
        } else if ($viewValue.length > 2) {
          _.forEach(airportDataNgStore.allAirports, function (airport) {
            if ((airport.icao && airport.icao.indexOf(viewValue) >= 0) || (airport.iata && airport.iata.indexOf(viewValue) >= 0)) {
              matches.push({
                data: airport,
                label: airport.icao + '/' + airport.iata,
                lat: airport.latitude,
                long: airport.longitude
              });
            }
          });
          _.forEach(flightNgStore.flights, function (flight) {
            if ((flight.acn && flight.acn.indexOf(viewValue)) >= 0 || (flight.flightId && flight.flightId.indexOf(viewValue) >= 0)) {
              matches.push({
                data: flight,
                label: flight.flightId + '(' + flight.acn + ')',
                lat: flight.lat,
                long: flight.long
              });
            }
          });
        }

      }
      return matches;
    };

    $scope.formatter = function ($model) {
      return $model ? iso6709Filter($model.lat, 'lat') + ' ' + iso6709Filter($model.long, 'long') : undefined;
    };

    $scope.unitDefs = [{
      label: 'Nautical Miles',
      short: 'NM',
      selected: true,
      format: function (meters) {
        return Math.round(meters * 0.000539957).toLocaleString();
      }
    }, {
      label: 'Kilometers',
      short: 'km',
      format: function (meters) {
        return Math.round(meters / 1000).toLocaleString();
      }
    }];
    $scope.unitSelected = $scope.unitDefs[0];
    $scope.selectUnit = function (unitDef) {
      if ($scope.unitSelected !== unitDef) {
        delete $scope.unitSelected.selected;
        unitDef.selected = true;
        $scope.unitSelected = unitDef;
        if ($scope.distanceRaw !== null) {
          $scope.distance = $scope.unitSelected.format($scope.distanceRaw);
        }
      }
    };

    $scope.selectedA = null;
    $scope.selectedB = null;
    $scope.isPointerModeA = false;
    $scope.isPointerModeB = false;
    $scope.distanceRaw = null;
    $scope.distance = null;

    function drawDistance() {
      var a, b, i, coords = [],
        geometry, feature;
      $scope.distance = null;
      if (measure) {
        $scope.layers.measureLayer.getSource().removeFeature(measure);
        measure = null;
      }
      if (_.isPlainObject($scope.selectedA) && _.isPlainObject($scope.selectedB)) {
        a = [$scope.selectedA.long, $scope.selectedA.lat];
        b = [$scope.selectedB.long, $scope.selectedB.lat];
        for (i = 0.0; i < 1.001; i = i + 0.002) {
          coords.push(transform(interpolate(a, b, i)));
        }
        geometry = new ol.geom.LineString(coords);
        measure = feature = new ol.Feature({
          geometry: geometry,
          'class': 'measure'
        });
        $scope.layers.measureLayer.getSource().addFeature(feature);
        window.map.getView().fit(geometry, window.map.getSize());
        $scope.distanceRaw = wgs84Sphere.haversineDistance(a, b);
        $scope.distance = $scope.unitSelected.format($scope.distanceRaw);
      }
    }

    pointerEventHandler = function (e) {
      $scope.$applyAsync(function () {
        var coords = transformReverse(e.coordinate);
        $scope['selected' + field] = {
          data: e.coordinate,
          long: coords[0],
          lat: coords[1]
        };
        $scope['selected' + field].label = $scope.formatter($scope['selected' + field]);
        $scope.togglePointerMode(field);
      });
      // Disable default actions
      e.stopPropagation();
      e.preventDefault();
    };

    $scope.isPointerMode = false;

    pointerModeOn = function () {
      window.map.on('pointerdown', pointerEventHandler);
    };

    pointerModeOff = function () {
      window.map.un('pointerdown', pointerEventHandler);
    };

    $scope.togglePointerMode = function (newField) {
      var oldField = field;
      if (!!field) {
        $scope['isPointerMode' + field] = !$scope['isPointerMode' + field];
        pointerModeOff();
        field = null;
      }
      if (oldField !== newField) {
        $scope['isPointerMode' + newField] = !$scope['isPointerMode' + newField];
        if ($scope['isPointerMode' + newField]) {
          pointerModeOn();
          field = newField;
        } else {
          pointerModeOff();
          field = null;
        }
      }
    };

    $scope.$watch('selectedA', function (newValue) {
      if (locA) {
        $scope.layers.measureLayer.getSource().removeFeature(locA);
        locA = null;
      }
      if (_.isPlainObject(newValue)) {
        $element.find('input[ng-model=selectedA]')[0].setCustomValidity('');
        // add location on map
        locA = locationOnMap('A', transform([newValue.long, newValue.lat]), $scope.layers.measureLayer);
      } else {
        $element.find('input[ng-model=selectedA]')[0].setCustomValidity('Select an object');
      }
      drawDistance();
    });

    $scope.$watch('selectedB', function (newValue) {
      if (locB) {
        $scope.layers.measureLayer.getSource().removeFeature(locB);
        locB = null;
      }
      if (_.isPlainObject(newValue)) {
        $element.find('input[ng-model=selectedB]')[0].setCustomValidity('');
        // add location on map
        locB = locationOnMap('B', transform([newValue.long, newValue.lat]), $scope.layers.measureLayer);
      } else {
        $element.find('input[ng-model=selectedB]')[0].setCustomValidity('Select an object');
      }
      drawDistance();
    });

  }]);

  App.controller('MapDrawCtrl', ['$scope', '$rootScope', '$element', 'airportDataNgStore', 'flightNgStore', 'iso6709Filter', 'featureNgStore', 'userService', function ($scope, $rootScope, $element,
    airportDataNgStore, flightNgStore,
    iso6709Filter, featureNgStore, userService) {

    var pointerEventHandler, pointerModeOn, pointerModeOff, locC, drawShape, drawRegularPolygon, drawConvexPolygon, drawLocC, mapOptions, onDrawEnd;

    mapOptions = $scope.user.preferences.mapOptions;

    $scope.shape = {};

    drawConvexPolygon = function () {
      if ($scope.shape.olFeature) {
        if (_.includes($scope.layers.drawLayer.getSource().getFeatures(), $scope.shape.olFeature)) {
          $scope.layers.drawLayer.getSource().removeFeature($scope.shape.olFeature);
        }
        $scope.layers.drawLayer.getSource().addFeature($scope.shape.olFeature);
        $scope.shape.olFeature.set('color', $scope.shape.color);
      }
    };

    drawShape = drawRegularPolygon = function () {
      var c, r, s, o, coords = [],
        geometry, feature;
      if ($scope.shape.olFeature && _.includes($scope.layers.drawLayer.getSource().getFeatures(), $scope.shape.olFeature)) {
        $scope.layers.drawLayer.getSource().removeFeature($scope.shape.olFeature);
        $scope.shape.olFeature = null;
      }
      if (_.isPlainObject($scope.shape.coordinates) && _.isFinite($scope.shape.radius)) {
        coords = transform([$scope.shape.coordinates.long, $scope.shape.coordinates.lat]);
        r = $scope.unitSelected.toMeters($scope.shape.radius);
        s = _.isFinite($scope.shape.sides) ? $scope.shape.sides : 128;
        o = Math.PI * (_.isFinite($scope.shape.orientation) ? $scope.shape.orientation : 0) / 180;
        geometry = ol.geom.Polygon.fromCircle(new ol.geom.Circle(coords, r), s, o);
        $scope.shape.olFeature = feature = new ol.Feature({
          geometry: geometry,
          'class': 'shape',
          'R': $scope.shape.radius,
          'unit': $scope.unitSelected.short,
          'S': $scope.shape.sides,
          'O': $scope.shape.orientation,
          'color': $scope.shape.color,
          'C': $scope.shape.coordinates
        });
        $scope.layers.drawLayer.getSource().addFeature(feature);
        //window.map.getView().fit(geometry, window.map.getSize());
      }
      drawLocC($scope.shape.coordinates);
    };

    drawLocC = function (coords) {
      if (locC && _.includes($scope.layers.drawLayer.getSource().getFeatures(), locC)) {
        $scope.layers.drawLayer.getSource().removeFeature(locC);
      }
      locC = null;
      if (_.isPlainObject(coords)) {
        $element.find('input[ng-model="shape.coordinates"]')[0].setCustomValidity('');
        // add location on map
        locC = locationOnMap('C', transform([coords.long, coords.lat]), $scope.layers.drawLayer);
      } else {
        $element.find('input[ng-model="shape.coordinates"]')[0].setCustomValidity('Select an object');
      }
    };

    //
    // Center (typeahead, or pointer mode)

    $scope.candidates = function ($viewValue) {
      var matches = [],
        viewValue = $viewValue.toUpperCase(),
        coords,
        lat,
        long;

      if ($viewValue) {
        if (coordRegExp.test($viewValue)) {
          coords = coordRegExp.exec($viewValue);
          lat = parseFloat(coords[1]);
          long = parseFloat(coords[2]);
          matches.push({
            data: $viewValue,
            label: $viewValue,
            lat: lat,
            long: long
          });
        } else if (iso6709DRegExp.test($viewValue)) {
          coords = iso6709DRegExp.exec($viewValue);
          lat = (parseInt(coords[1], 10) + parseInt(coords[2], 10) / 60 + parseFloat(coords[3]) / 3600) * (coords[4] === 'N' ? 1 : -1);
          long = (parseInt(coords[5], 10) + parseInt(coords[6], 10) / 60 + parseFloat(coords[7]) / 3600) * (coords[8] === 'E' ? 1 : -1);
          matches.push({
            data: $viewValue,
            label: $viewValue,
            lat: lat,
            long: long
          });
        } else if ($viewValue.length > 2) {
          _.forEach(airportDataNgStore.allAirports, function (airport) {
            if (airport.icao.indexOf(viewValue) >= 0 || airport.iata.indexOf(viewValue) >= 0) {
              matches.push({
                data: airport,
                label: airport.icao + '/' + airport.iata,
                lat: airport.latitude,
                long: airport.longitude
              });
            }
          });
          _.forEach(flightNgStore.flights, function (flight) {
            if ((flight.acn && flight.acn.indexOf(viewValue)) >= 0 || (flight.flightId && flight.flightId.indexOf(viewValue) >= 0)) {
              matches.push({
                data: flight,
                label: flight.flightId + '(' + flight.acn + ')',
                lat: flight.lat,
                long: flight.long
              });
            }
          });
        }

      }
      return matches;
    };
    $scope.formatter = function ($model) {
      return $model ? iso6709Filter($model.lat, 'lat') + ' ' + iso6709Filter($model.long, 'long') : undefined;
    };
    $scope.shape.coordinates = null;

    pointerEventHandler = function (e) {
      $scope.$applyAsync(function () {
        var coords = transformReverse(e.coordinate);
        $scope.shape.coordinates = {
          data: e.coordinate,
          long: coords[0],
          lat: coords[1]
        };
        $scope.shape.coordinates.label = $scope.formatter($scope.shape.coordinates);
        $scope.togglePointerMode();
      });
      // Disable default actions
      e.stopPropagation();
      e.preventDefault();
    };

    $scope.isPointerMode = false;

    pointerModeOn = function () {
      window.map.on('pointerdown', pointerEventHandler);
    };

    pointerModeOff = function () {
      window.map.un('pointerdown', pointerEventHandler);
    };

    $scope.togglePointerMode = function (field) {
      $scope.isPointerMode = !$scope.isPointerMode;
      if ($scope.isPointerMode) {
        pointerModeOn();
      } else {
        pointerModeOff();
      }
    };

    //
    // Radius and Unit

    $scope.unitDefs = [{
      label: 'Nautical Miles',
      short: 'NM',
      selected: true,
      format: function (meters) {
        return Math.round(meters * 0.000539957).toLocaleString();
      },
      toMeters: function (kilometers) {
        return kilometers / 0.000539957;
      }
    }, {
      label: 'Kilometers',
      short: 'km',
      format: function (meters) {
        return Math.round(meters / 1000).toLocaleString();
      },
      toMeters: function (kilometers) {
        return kilometers * 1000;
      }
    }];
    $scope.unitSelected = $scope.unitDefs[0];
    $scope.selectUnit = function (unitDef) {
      if ($scope.unitSelected !== unitDef) {
        delete $scope.unitSelected.selected;
        unitDef.selected = true;
        $scope.unitSelected = unitDef;
        $scope.shape.unit = unitDef;
        drawShape();
      }
    };

    onDrawEnd = function (drawEvent) {
      var feature = $scope.shape.olFeature = drawEvent.feature;
      feature.set('color', $scope.shape.color);
      feature.set('type', $scope.geometryTypeSelected.value);
    };

    $scope.geometryTypeDefs = [{
      label: 'Regular Polygon',
      value: 'Circle',
      selected: true,
      fields: {
        coordinates: true,
        radius: true,
        sides: true,
        orientation: true,
        color: true
      },
      onSelect: function () {
        window.map.draw.removeInteraction();
        drawShape = drawRegularPolygon;
      }
    }, {
      label: 'Convex Polygon',
      value: 'Polygon',
      fields: {
        color: true
      },
      onSelect: function () {
        if (!$scope.shape.featureNg || !$scope.shape.featureNg.isEdited) {
          window.map.draw.addInteraction('Polygon', onDrawEnd);
        }
        drawShape = drawConvexPolygon;
      }
    }, {
      label: 'Line',
      value: 'LineString',
      fields: {
        color: true,
        inverse: true
      },
      onSelect: function () {
        if (!$scope.shape.featureNg || !$scope.shape.featureNg.isEdited) {
          window.map.draw.addInteraction('LineString', onDrawEnd);
        }
        drawShape = drawConvexPolygon;
      }
    }];
    $scope.geometryTypeSelected = $scope.geometryTypeDefs[0];
    $scope.selectGeometryType = function (geometryTypeDef, reset) {
      if ($scope.geometryTypeSelected !== geometryTypeDef) {
        delete $scope.geometryTypeSelected.selected;
        geometryTypeDef.selected = true;
        $scope.geometryTypeSelected = geometryTypeDef;
        if (reset) {
          $scope.select();
        }
        $scope.geometryTypeSelected.onSelect();
        $scope.$applyAsync();
      }
    };

    //
    // Drawing properties

    $scope.$watch('shape.name', function (newValue) {
      drawShape();
    });

    $scope.$watch('shape.coordinates', function (newValue) {
      drawShape();
    });

    $scope.$watch('shape.radius', function (newValue) {
      drawShape();
    });

    $scope.$watch('shape.sides', function (newValue) {
      drawShape();
    });

    $scope.$watch('shape.orientation', function (newValue) {
      drawShape();
    });

    $scope.$watch('shape.color', function (newValue) {
      drawShape();
    });

    $scope.isSaving = false;
    $scope.save = function () {
      var featureNg = {
          id: $scope.shape.id,
          name: $scope.shape.name,
          authorId: $rootScope.user.id,
          customerId: $rootScope.user.customerId,
          type: 'geojson',
          data: formatGeoJSON.writeFeature($scope.shape.olFeature, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
          }),
          shared: $scope.shape.shared
        },
        method = 'add';
      if ($scope.shape.id) {
        method = 'set';
      }
      $scope.isSaving = true;
      featureNgStore[method](featureNg).then(function (savedFeatureNg) {
        $scope.shape.id = savedFeatureNg.id;
        $scope.isSaving = false;
        savedFeatureNg.isEdited = true;
        if (method === 'add') {
          if (_.includes($scope.layers.drawLayer.getSource().getFeatures(), savedFeatureNg.olFeature)) {
            $scope.layers.drawLayer.getSource().removeFeature(savedFeatureNg.olFeature);
          }
          $scope.shape.featureNg = savedFeatureNg;
        }
      })['catch'](function (error) {
        window.console.error(error);
        $scope.isSaving = false;
      });
    };

    $scope.remove = function (feature) {
      if ($scope.shape.id === feature.id) {
        $scope.select();
      }
      featureNgStore.remove(feature)['catch'](function (error) {
        featureNgStore.refresh();
      });
    };
    
    $scope.inverse = function () {
      var geometry, coords;
      if ($scope.shape.olFeature) {
        geometry = $scope.shape.olFeature.getGeometry();
        coords = geometry.getCoordinates().reverse();
        geometry.setCoordinates(coords);
      }
    };

    $scope.select = function (featureNg) {
      var unit, type;

      if ($scope.shape.featureNg) {
        if (!_.includes($scope.layers.drawLayer.getSource().getFeatures(), $scope.shape.featureNg.olFeature) && $scope.shape.featureNg.isVisible) {
          $scope.layers.drawLayer.getSource().addFeature($scope.shape.featureNg.olFeature);
        }
        delete $scope.shape.featureNg.isEdited;
      }

      if (featureNg) {
        $scope.shape.id = featureNg.id;
        $scope.shape.name = featureNg.name;
        $scope.shape.shared = featureNg.shared;
        $scope.shape.radius = featureNg.olFeature.get('R');
        $scope.shape.sides = featureNg.olFeature.get('S');
        $scope.shape.orientation = featureNg.olFeature.get('O');
        $scope.shape.color = featureNg.olFeature.get('color');
        $scope.shape.featureNg = featureNg;
        $scope.shape.featureNg.isEdited = true;
        if (_.includes($scope.layers.drawLayer.getSource().getFeatures(), featureNg.olFeature)) {
          $scope.layers.drawLayer.getSource().removeFeature(featureNg.olFeature);
        }
        unit = featureNg.olFeature.get('unit');
        _.forEach($scope.unitDefs, function (unitDef) {
          if (unitDef.short === unit) {
            $scope.unitSelected = unitDef;
            $scope.shape.unit = unitDef;
          }
        });
        type = featureNg.olFeature.get('type') || 'Circle';
        _.forEach($scope.geometryTypeDefs, function (geometryTypeDef) {
          if (geometryTypeDef.value === type) {
            $scope.selectGeometryType(geometryTypeDef);
          }
        });
        $scope.shape.olFeature = featureNg.olFeature.clone();
        $scope.shape.coordinates = featureNg.olFeature.get('C');
        window.map.getView().fit(featureNg.olFeature.getGeometry(), window.map.getSize());
      } else {
        // reset all fields
        featureNgStore.refresh();
        $scope.shape = {};
      }
      drawShape();
      $scope.$applyAsync();
    };
    
    $scope.clear = function () {
      $scope.select();
      $scope.geometryTypeSelected.onSelect();
    }

    $scope.center = function (featureNg) {
      window.map.getView().fit(featureNg.olFeature.getGeometry(), window.map.getSize());
    };

    $scope.toggleShared = function (featureNg) {
      featureNgStore.set(featureNg)['catch'](function () {
        featureNgStore.refresh();
      });
    };

    $scope.toggleVisible = function (featureNg) {
      if (featureNg.isVisible) {
        delete mapOptions.hiddenDrawing[featureNg.id];
      } else {
        mapOptions.hiddenDrawing[featureNg.id] = true;
      }
      userService.savePreferences({
        mapOptions: mapOptions
      }).then(function () {
        featureNgStore.refresh();
      })['catch'](function () {
        //reload last preferences in DB
        mapOptions = $scope.user.preferences.mapOptions;
        featureNgStore.refresh();
      });
    };


    function updateFeatures(updated, resized) {
      $scope.features = featureNgStore.features;
      $scope.myFeatures = featureNgStore.myFeatures;
      $scope.othersFeatures = featureNgStore.othersFeatures;
      drawShape();
      $scope.$applyAsync();
    }

    //listen for drawing
    featureNgStore.on('sync', updateFeatures, $scope);
    featureNgStore.refresh();
    
    $scope.$watch('toolbox.isDraw', function (newValue) {
      if (!newValue) {
        $scope.clear();
        window.map.draw.removeInteraction();
      } else {
        $scope.geometryTypeSelected.onSelect();
      }
    });

    $scope.$on('$destroy', function () {
      $scope.select();
      featureNgStore.off('sync', updateFeatures, $scope);
      window.map.draw.removeInteraction();
    });
  }]);

}());
