/*jslint browser: true, plusplus:true */
/*global $, angular, App, moment */

(function (global) {
  'use strict';


  /**
   * Custom filter that handles number range, date range, string comparison
   *
   * If expected value is a string, a simple lowercase comparison is done.
   * If expected value is an object :
   *   expected.before/expected.after is used for date comparison
   *   expected.lower/expected.higher is used for range number comparison
   */
  App.filter('flightFilter', ['$filter', 'generalConstants', function ($filter, generalConstants) {
    var filterFilter = $filter('filter');
    var standardComparator = function standardComparator(obj, text) {
      text = ('' + text).toLowerCase();
      return !!text && ('' + obj).toLowerCase().indexOf(text) > -1;
    };
    var customComparator = function customComparator(actual, expected) {
      var length;
      if (angular.isObject(expected)) {
        if (angular.isArray(expected)) {
          length = expected.length;
          while (length--) {
            if (standardComparator(expected[length], actual)) {
              return true;
            }
          }
          return false;
        } else if (expected.minTime || expected.maxTime) {
          //time range
          return testTime(actual, expected);
        } else if (expected.minDate || expected.maxDate) {
          //date range
          return testDate(actual, expected);
        } else if (expected.min || expected.max) {
          //number range
          return testRange(actual, expected.min, expected.max);
        } else if (expected.list) {
          return !!expected.list[actual];
        }
        //etc
        return true;
      }
      return standardComparator(actual, expected);
    };


    return function customFilter(array, expression) {

      function predicateFn(item) {
        var key, expectedVal, actualVal;
        for (key in expression) {
          if (expression.hasOwnProperty(key)) {
            if (key !== '$') {
              actualVal = item[key];
              expectedVal = expression[key];
              if (key === generalConstants.deskFieldName) {
                if (!customComparator(actualVal, expectedVal) && !item[generalConstants.otherFieldName]) {
                  return false;
                }
              } else if (!customComparator(actualVal, expectedVal)) {
                return false;
              }
            }
          }
        }
        return true;
      }
      array = array.filter(predicateFn);
      if (angular.isDefined(expression.$)) {
        array = filterFilter(array, {
          $: expression.$
        });
      }
      return array;

    };
  }]);

  function testTime(actual, expected) {
    var itemDate,
      min = expected.min,
      max = expected.max;
    if (!actual) {
      return false;
    }
    //time range
    itemDate = moment(actual);
    min = max = itemDate;
    try {
      if (expected.minTime) {
        min = moment(expected.minTime).day(itemDate.day()).month(itemDate.month()).year(itemDate.year());
      }
      if (expected.maxTime) {
        max = moment(expected.maxTime).day(itemDate.day()).month(itemDate.month()).year(itemDate.year()).add(1, 'm');
      }

      if (max && max.isBefore(min)) {
        if (itemDate.isBetween(max, min)) {
          return false;
        }
      } else if (!itemDate.isBetween(min, max)) {
        return false;
      }

      return true;
    } catch (e) {
      return false;
    }
  }

  function testDate(actual, expected) {
    var itemDate,
      min = expected.min,
      max = expected.max;
    if (!actual) {
      return false;
    }
    //time range
    itemDate = moment.utc(actual);
    min = max = itemDate;
    try {
      if (expected.minDate) {
        min = moment.utc(expected.minDate).startOf('day');
      }
      if (expected.maxDate) {
        max = moment.utc(expected.maxDate).endOf('day');
      }

      if (max && max.isBefore(min)) {
        if (itemDate.isBetween(max, min)) {
          return false;
        }
      } else if (!itemDate.isBetween(min, max)) {
        return false;
      }

      return true;
    } catch (e) {
      return false;
    }
  }

  function testRange(actual, min, max) {
    if (max) {
      if (actual > max) {
        return false;
      }
    }
    if (min) {
      if (actual < min) {
        return false;
      }
    }
    return true;
  }
}(window));
