/*jslint browser: true, plusplus:true*/
/*global angular, ol, App, Promise */

(function () {
  'use strict';

  function SettingsMapCtrl($rootScope, $scope, $state, mapPreferences, userService, defaultPreferences, customerNgStore) {

    var vm = this;
    vm.resolutions = [
                2,
                3,
                5,
                10,
                20,
                39,
                77,
                153,
                306,
                612,
                1223,
                2446,
                4892,
                9784,
                19568,
                39136
            ];
    vm.nauticalMiles = [
                0.05,
                0.1,
                0.2,
                0.5,
                1,
                2,
                5,
                5,
                10,
                20,
                50,
                100,
                200,
                500,
                1000,
                2000
            ];
    $scope.availableMaps = mapPreferences.maps;
    // Map settings model
    $scope.selectedMap = $rootScope.user.preferences.map;

    vm.init = function () {
                $scope.displayMajorCities = defaultPreferences.mapOptions.displayMajorCities;
                $scope.displayAirportSurfaceMap = defaultPreferences.mapOptions.displayAirportSurfaceMap;
                vm.mapOptions = userService.getUserPreference('mapOptions');
                $scope.displayMajorCities.displayMajorCities = vm.mapOptions.displayMajorCities.displayMajorCities;
                $scope.displayAirportSurfaceMap.displayAirportSurfaceMap = vm.mapOptions.displayAirportSurfaceMap.displayAirportSurfaceMap;
                $scope.displayMajorCities.maxResolution = vm.mapOptions.displayMajorCities.maxResolution;
                $scope.displayMajorCities.zoomIndex = vm.mapOptions.displayMajorCities.zoomIndex;

                let customer = customerNgStore.customer;
                let parameters = customer.parameters;
                        
                $scope.surfaceTrackingEnabled = parameters.surfaceTracking ? parameters.surfaceTracking  : false ;              
            }
    $scope.getNauticalMiles = function (zoomIndex) {
                return vm.nauticalMiles[zoomIndex];
    }

    $scope.submitSettings = function () {   
      return new Promise(function (resolve, reject) {
        //console.log($scope.formSettingsMap)
        $scope.displayMajorCities.maxResolution = vm.resolutions[$scope.displayMajorCities.zoomIndex];
        vm.mapOptions.displayMajorCities = $scope.displayMajorCities;
        vm.mapOptions.displayAirportSurfaceMap = $scope.displayAirportSurfaceMap;

        userService.savePreferences({
          map: $scope.selectedMap
        }).then(resolve, function () {
          //reload last preferences in DB
          $scope.selectedMap = $rootScope.user.preferences.map;
          $scope.$apply();
          reject(arguments);
        });
        userService.savePreferences({
                            mapOptions: vm.mapOptions
                        }).then(function () {
                            resolve("Successfully updated");
                        })['catch'](function (error) {
                            reject("Error updating");
                        });
      });
    };
    $scope.selectMap = function (map) {
      $scope.selectedMap = map.name;
      //$scope.$applyAsync();
    };
    vm.init();
  }

  App
    .controller('SettingsMapCtrl', ['$rootScope', '$scope', '$state', 'mapPreferences', 'userService','defaultPreferences','customerNgStore', SettingsMapCtrl]);
}());
