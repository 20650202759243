/*jslint browser: true, nomen: true */
/*global App, ServerTime, d3, angular, _, moment */

App.controller('FlightDetailEtaGraphCtrl', ['$element', '$scope', 'flightNgStore','userService', function ($element, $scope, flightNgStore,userService) {
  'use strict';

  var x, y, xAxis, yAxis, svg, line, area, idealArea, margin, width, height, outerWidth, outerHeight;

  margin = {
    top: 20,
    right: 10,
    bottom: 20,
    left: 30
  };
  width = 250; // 3/5 aspect ratio that match CSS rule
  height = 150;
  outerWidth = width + margin.left + margin.right;
  outerHeight = height + margin.top + margin.bottom;
  svg = d3.select($element[0]).append('svg')
    .attr('viewBox', '0 0 ' + outerWidth + ' ' + outerHeight)
    .attr('preserveAspectRatio', 'xMidYMid meet')
    .append('g')
    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');


  function remainingTime(estimation) {
    return (estimation.timeOfArrival - estimation.estimationDate) / 60000;
  }

  function idealLineFn(data) {
    return function (d) {
      var idealRemaining = 0;
      if (!_.isEmpty(data)) {
        idealRemaining = (data[0].timeOfArrival - d.estimationDate) / 60000;
      }
      return y(idealRemaining);
    };
  }

  x = d3.time.scale()
    .range([0, width]);

  y = d3.scale.linear()
    .range([height, 0]);

  xAxis = d3.svg.axis()
    .scale(x)
    .ticks(8)
    .tickFormat(function(d){
                 return moment(d).tz(userService.getUserPreference('timeZone')).format('hh:mm');
           })
    .orient("bottom");

  yAxis = d3.svg.axis()
    .scale(y)
    .tickSize(width)
    .ticks(5)
    .orient("left");

  line = d3.svg.line()
    .interpolate("cardinal").tension(0.9)
    .x(function (d) {
      return x(moment(d.estimationDate).tz(userService.getUserPreference('timeZone')));
    })
    .y(function (d) {
      return y(moment(remainingTime(d)).tz(userService.getUserPreference('timeZone')));
    });

  area = d3.svg.area()
    .interpolate("cardinal").tension(0.9)
    .x(function (d) {
      return x(moment(d.estimationDate).tz(userService.getUserPreference('timeZone')));
    })
    .y1(function (d) {
      return y(moment(remainingTime(d)).tz(userService.getUserPreference('timeZone')));
    });

  idealArea = d3.svg.area()
    .interpolate("cardinal").tension(0.9)
    .x(function (d) {
      return x(moment(d.estimationDate).tz(userService.getUserPreference('timeZone')));
    })
    .y1(function (d) {
      return y(moment(remainingTime(d)).tz(userService.getUserPreference('timeZone')));
    });


  //x axis
  svg.append("g")
    .attr("class", "x axis")
    .attr("transform", "translate(0," + height + ")")
    .call(xAxis);

  //y axis
  svg.append("g")
    .attr("class", "y axis")
    .call(yAxis)
    .attr("transform", "translate(" + width + ", 0)")
    .append("text")
    .attr("transform", "translate(" + (-width - margin.left) + ",0) rotate(-90)")
    .attr("y", 6)
    .attr("dy", ".71em")
    .style("text-anchor", "end")
    .text("Remaining Time (minutes)");

  //define area clippings
  svg.append("clipPath")
    .attr("id", "clip-below")
    .append("path");
  svg.append("clipPath")
    .attr("id", "clip-above")
    .append("path");


  svg.append("path")
    .attr("class", "area above")
    .attr("clip-path", "url(#clip-above)");

  svg.append("path")
    .attr("class", "area below")
    .attr("clip-path", "url(#clip-below)");

  //ideal line (green dashes)
  svg.append("path")
    .attr("class", "line ideal");
  //estimations (blue line)
  svg.append("path")
    .attr("class", "line estimation");

  this.redrawGraph = function (data) {
    var idealData;

    if (data.length > 0) {
      idealData = [data[0], {
        timeOfArrival: moment(data[0].timeOfArrival).tz(userService.getUserPreference('timeZone')),
        estimationDate: moment(data[0].timeOfArrival).tz(userService.getUserPreference('timeZone'))
      }];
    } else {
      idealData = [];
    }


    //update domain range
    x.domain(
      [
        d3.min(data, function (d) { // from departure (first estimation)
          return moment(d.estimationDate).tz(userService.getUserPreference('timeZone'));
        }),
        d3.max(data, function (d) { // to eta max
          return moment(d.timeOfArrival).tz(userService.getUserPreference('timeZone'));
        })
      ]
    );

    y.domain([
      0,
      d3.max(data, remainingTime)
    ]);

    //svg.datum(data);
    //update axis
    svg.select(".x.axis")
      .call(xAxis);
    svg.select(".y.axis")
      .call(yAxis);

    svg.select('#clip-above path').attr("d", idealArea.y0(height).y1(idealLineFn(data))(data));
    svg.select('#clip-below path').attr("d", idealArea.y0(0)(data));

    svg.select('.line.ideal')
      .attr("d", line(idealData));

    svg.select('.line.estimation')
      .attr("d", line(data));

    svg.select('.area.above').attr("d", area.y0(0)(data));
    svg.select('.area.below').attr("d", area.y0(height)(data));

  };

  this.refreshData = function (flight) {
    if (flight) {
      $element.addClass('loading');
      $element.find('svg').hide();//SMHUB-110 hide to force chrome svg rerendering after update
      flightNgStore.getAircraftFlightEtaHistory(flight.id).then(function (etas) {
        if (_.isArray(etas)) {
          this.data = etas;
        } else {
          this.data = [];
        }
        this.redrawGraph(this.data);
        $element.find('svg').show();
        $element.removeClass('loading');
      }.bind(this), function (e) {
        window.console.warn('Fail to load flight eta history', e);
        $element.find('svg').show();
        $element.removeClass('loading');
      });
    } else {
      this.data = [];
      this.redrawGraph(this.data);
    }
  };

  this.refreshData($scope.selectedFlight);

  // $scope.$watch('selectedFlight', function (newFlight) {
  //   this.refreshData(newFlight);
  // }.bind(this));

  $scope.$watch('selectedFlight.eta', function (eta, oldEta) {
    var etaTs = +eta;
    if (etaTs && (etaTs !== +oldEta) && this.data && this.data.length > 0) {
      var newEta = {
        estimationDate: +moment(ServerTime.now()).tz(userService.getUserPreference('timeZone')),
        timeOfArrival: moment(etaTs).tz(userService.getUserPreference('timeZone'))
      };
      this.data.push(newEta);
      this.redrawGraph(this.data);
    }
  }.bind(this));

}]);
