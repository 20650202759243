App.controller('favoritesListController', ['$scope', '$rootScope', '$state', '$uibModal', 'favoriteRestService',
    'userRestService', 'alertService', 'favoriteDefaultService', 'defaultPreferences', 'userService', 'definedAreaService', 'flightSearchService',
    function ($scope, $rootScope, $state,
              $uibModal, favoriteRestService, userRestService, alertService, favoriteDefaultService, defaultPreferences, userService, definedAreaService, flightSearchService) {
        'use strict';

        this.favorites = [];
        this.isFavDropdownOpened = false;

        this.$onInit = function () {
            if (favoriteDefaultService.getIsDefaultFavoriteApplied()) {
                this.findDefaultFavoriteAndApply();
                favoriteDefaultService.setIsDefaultFavoriteApplied(false);
            } else {
                this.refreshFavoriteList();
            }
        };

        this.addFavorite = function () {
            var newFav = {
                    name: '',
                    center: map.getView().getCenter(),
                    resolution: map.getView().getResolution(),
                    size: map.getSize(),
                    extent: map.getView().calculateExtent(map.getSize()),
                    defaultFavorite: this.favorites.length === 0 ? true : false,
                    shared: false,
                    mapLayers: {},
                    flightFilters: [],
                    shownSections: []
                },
                favList = this.favorites;
            $uibModal.open({
                templateUrl: 'views/partials/map/favorites-add.template.html',
                resolve: {
                    fav: function () {
                        return newFav;
                    },
                    favList: function () {
                        return favList;
                    }
                },
                controller: 'FavoritesAddFormCtrl',
                controllerAs: 'favoritesAddCtrl'
            }).result.then((results) => {
                this.refreshFavoriteList();
            }, () => {
                // Do nothing on cancel
            });
        };

        this.goto = function (favorite) {

            var view = map.getView();
            var mapCenter = map.getSize().slice();
            var padding = $('#flight-details').width();
            // compute viewport center with detail pane shown
            mapCenter[0] = (mapCenter[0] - padding) / 2 + padding;
            mapCenter[1] = mapCenter[1] / 2;
            var transform = ol.proj.getTransform('EPSG:4326', 'EPSG:3857');
            var coordinate = [favorite.centerLongitude, favorite.centerLatitude];

            var viewCenter = this.findOffsettedCenter(transform(coordinate), mapCenter, favorite.resolution);

            view.setCenter(viewCenter);
            view.setResolution(favorite.resolution);

            var mapLayers = _.cloneDeep(favorite.mapLayers);
            Object.freeze(mapLayers);

            $rootScope.$emit("UpdateMapLayers", mapLayers);

            var flightToggles = _.cloneDeep(favorite.flightToggleStates);

            $rootScope.$emit("UpdateFlightToggles", flightToggles);

            definedAreaService.toggleAreasByIds(favorite.definedAreaIds);

            flightSearchService.replaceFilters(favorite.flightFilters);

            this.isFavDropdownOpened = false;
        };

        /**
         * Get Center on coordinate and view position.
         * @param {ol.Coordinate} coordinate Coordinate of the feature to center.
         * @param {ol.Pixel} position Position on the view to center on.
         */
        this.findOffsettedCenter = function (coordinate, position, resolution) {
            // calculate rotated position
            var rotation = map.getView().getRotation();
            var size = map.getSize();
            var cosAngle = Math.cos(-rotation);
            var sinAngle = Math.sin(-rotation);
            var rotX = coordinate[0] * cosAngle - coordinate[1] * sinAngle;
            var rotY = coordinate[1] * cosAngle + coordinate[0] * sinAngle;
            rotX += (size[0] / 2 - position[0]) * resolution;
            rotY += (position[1] - size[1] / 2) * resolution;

            // go back to original angle
            sinAngle = -sinAngle; // go back to original rotation
            var centerX = rotX * cosAngle - rotY * sinAngle;
            var centerY = rotY * cosAngle + rotX * sinAngle;
            return [centerX, centerY];
        };

        this.populateIdsAndOwners = function (favorites) {
            // TODO : have Favorites API return owner name after sharing is implemented and replace unnecessary user service call
            let customerId = $rootScope.user.customerId;
            userRestService.getUsers(customerId).then((result) => {
                if (result instanceof Error) {
                    alertService.error(result.name, result.message);
                    favorites.forEach(favorite => {
                        favorite.ownerName = 'Unknown';
                        favorite.favElementId = _.trim(_.camelCase(favorite.name) + '_' + _.camelCase(favorite.ownerName));
                    });
                } else {
                    let userMap = _.keyBy(result, 'id');
                    favorites.forEach(favorite => {
                        let owner = userMap[favorite.ownerId];
                        if (_.isEmpty(owner, true)) {
                            favorite.ownerName = 'Unknown';
                        } else {
                            favorite.ownerName = owner.login;
                        }
                        favorite.favElementId = _.trim(_.camelCase(favorite.name) + '_' + _.camelCase(favorite.ownerName));
                    });
                }
            });
        };

        this.refreshFavoriteList = function () {
            this.populateFavoritesAndFindDefault();
        };

        this.findDefaultFavoriteAndApply = function () {
            this.populateFavoritesAndFindDefault().then((defaultFav) => {
                this.goto(defaultFav || defaultPreferences.mapOptions.centerMaxZoom);
            });
        }

        this.populateFavoritesAndFindDefault = function () {
            return favoriteRestService.getFavorites().then((results) => {
                let defaultFav = null;
                this.favorites = [];
                results.forEach(fav => {
                    if (fav.id === userService.getUserPreference('defaultFavoriteId')) {
                        defaultFav = fav;
                        fav.isDefaultFavorite = true;
                    } else {
                        fav.isDefaultFavorite = false;
                    }
                    this.favorites.push(fav);
                });
                this.populateIdsAndOwners(this.favorites);
                return defaultFav;
            });
        }

        this.$onDestroy = function () {
        };

        this.deleteFav = function deleteFav(fav) {
            let alertMsg;
            if (fav.shared) {
                alertMsg = `Favorite may be in use by other users. Do you really want to delete this favorite: ${fav.name}?`;
            } else {
                alertMsg = `Do you really want to delete this favorite: ${fav.name}?`;
            }

            alertService
                .confirm('Delete Favorite', alertMsg)
                .then(() => {
                    if (fav.id === userService.getUserPreference('defaultFavoriteId')) {
                        fav.isDefaultFavorite = false;
                        userService.savePreferences({
                            defaultFavoriteId: ''
                        })
                    }
                    favoriteRestService.deleteFavorite(fav).then((result) => {
                        if (result instanceof Error) {
                            alertService.error(result.name, result.message);
                        }
                        this.refreshFavoriteList();
                    });
                });
        };

        this.toggleDefaultFav = function (fav) {
            this.favorites.forEach(favorite => {
                favorite.isDefaultFavorite = false;
            });

            if (fav.id === userService.getUserPreference('defaultFavoriteId')) {
                fav.isDefaultFavorite = false;
                userService.savePreferences({
                    defaultFavoriteId: ''
                })
            } else {
                userService.savePreferences({
                    defaultFavoriteId: fav.id
                })
                fav.isDefaultFavorite = true;
            }
        };

        this.favIsMine = function (fav) {
            return $rootScope.user.id === fav.ownerId;
        };

        this.toggleSharedFav = function (fav) {

            let privateFavorite = (!fav.shared) ? true : false;

            if (privateFavorite) {
                let privateAreaNames = this.getPrivateAreasForFavorite(fav.definedAreaIds).map(pa => pa.name);

                if (privateAreaNames.length > 0) {
                    let errorTitle = "Error";
                    let errorMsg = 'Private Area(s) are preventing this operation : ';

                    privateAreaNames.forEach(function (privateAreaName, index) {

                        if (index === privateAreaNames.length - 1) {
                            errorMsg = errorMsg.concat(privateAreaName, '.');
                        } else {
                            errorMsg = errorMsg.concat(privateAreaName, ', ');
                        }

                    });

                    alertService.error(errorTitle, errorMsg);
                    return;
                }
            }

            fav.shared = !fav.shared;
            favoriteRestService.updateFavorite(fav).then((result) => {
                if (result instanceof Error) {
                    alertService.error(result.name, result.message);
                    this.refreshFavoriteList();
                }
            });
        };

        /**
         * Get privateAreas defined for a favorite
         * @param areaIds areaIds associated with the favorite
         * @returns {*} privateAreas associated with the favorite
         */

        this.getPrivateAreasForFavorite = function (areaIds) {

            let privateAreas = definedAreaService.definedAreas.filter(area => !area.shared);
            return privateAreas.filter(pa => areaIds.includes(pa.id));
        };

    }
]);
