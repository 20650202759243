App.factory('favoriteRestService', ['$http', function($http) {

  return {
    getFavorites: function() {
      return $http({
        method: 'GET',
        url: window.backendPrefix + '/favorites',
        withCredentials: true,
        responseType: 'json'
      }).then( function successCallback( response ) {
          return response.data;
      }, function errorCallback( response ) {
        console.log( 'Error fetching favorites; error code ' + response.status );
      });
    },
    saveFavorite: function( favorite ) {
      return $http({
        method: 'POST',
        url: window.backendPrefix + '/favorites',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'json',
        data: favorite
      }).then( function successCallback( response ) {
          return response.data;
      }, function errorCallback(response) {
        if(response.data !== null && typeof response.data.code !== 'undefined') {
          return Error( 'Favorite did not save correctly. Message: ' + ErrorCode.getMessage(response.data.code));
        } else {
          return Error( 'Favorite did not save correctly. Error: ' + response.status + ' ' + response.statusText );
        }
      });
    },
    updateFavorite: function( favorite ) {
      return $http({
        method: 'PUT',
        url: window.backendPrefix + '/favorites/' + favorite.id,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'json',
        data: favorite
      }).then( function successCallback( response ) {
          return response.data;
      }, function errorCallback(response) {
        if(response.data !== null && typeof response.data.code !== 'undefined') {
          return Error( 'Favorite did not update correctly. Message: ' + ErrorCode.getMessage(response.data.code));
        } else {
          return Error( 'Favorite did not update correctly. Error: ' + response.status + ' ' + response.statusText );
        }
      });
    },
    deleteFavorite: function( favorite ) {
      return $http({
        method: 'DELETE',
        url: window.backendPrefix + '/favorites/' + favorite.id,
        withCredentials: true,
        responseType: 'json'
      }).then( function successCallback( response ) {
          return response.data;
      }, function errorCallback(response) {
        if(response.data !== null && typeof response.data.code !== 'undefined') {
          return Error( 'Favorite did not delete correctly. Message: ' + ErrorCode.getMessage(response.data.code));
        } else {
          return Error( 'Favorite did not delete correctly. Error: ' + response.status + ' ' + response.statusText );
        }
      });
    }
  };

}]);
