App.factory('airlineRestService', ['$http', function($http) {

  return {
    getAirlines: function() {
      return $http({
        method: 'GET',
        url: window.backendPrefix + '/airline',
        withCredentials: true,
        responseType: 'json'
      }).then( function successCallback( response ) {
          return response.data;
      }, function errorCallback( response ) {
        console.log( 'Error fetching airlines; error code ' + response.status );
      });
    },
    getIcaoLogo: function(icao) {
        return $http({
            method: 'GET',
            url: window.backendPrefix + '/airline/logo/' + icao,
            withCredentials: true,
            responseType : 'arraybuffer'
        }).then( function successCallback( response ) {

            var file = new Blob([ response.data ], {
                type : 'image/png'
            });

            var fileURL = URL.createObjectURL(file);
            return fileURL;
            
        }, function errorCallback( response ) {
            console.log( 'Error fetching airline logo; error code ' + response.status );
        });
    },
    updateIcaoLogo: function(icao, imageFile) {
        return $http({
            method: 'POST',
            url: window.backendPrefix + '/airline/logo/' + icao,
            withCredentials: true,
            responseType: 'json',
            data: imageFile,
            headers: {'Content-Type': undefined}
        }).then( function successCallback( response ) {
            return response;
        }, function errorCallback( response ) {
          console.log( 'Error updating airline logo; error code ' + response.status );
        });
    }
  };

}]);
