/*jslint browser: true, plusplus:true, nomen:true*/
/*global _, angular, ol, App, Promise */

App.controller('AdminFlightPlanCtrl', ['$scope', 'customerNgStore', function ($scope, customerNgStore) {
  'use strict';

  customerNgStore.refresh();
  let customer = customerNgStore.customer;
  let parameters = customer.parameters;

  $scope.flightPlan = {
    relaxedNFPFlightPlanMatching: parameters.relaxedNFPFlightPlanMatching
  };

  $scope.saveFlightPlanConfiguration = function () {

    return new Promise(function (resolve, reject) {
      parameters.relaxedNFPFlightPlanMatching = $scope.flightPlan.relaxedNFPFlightPlanMatching;
      customer.parameters = parameters;
      resolve(customerNgStore.set(customer));
    }.bind(this));
  };

}]);