/*jslint browser: true, nomen:true */
/*global App, angular, Promise, PubSub, fetch, $, _ */

App.factory('userService', ['$rootScope', '$state', 'customerId', 'defaultPreferences', 'userProfileNgStore', 'customerNgStore', 'flightfieldDefinitions', function userServiceFactory($rootScope, $state, customerId, defaultPreferences, userProfileNgStore, customerNgStore, flightfieldDefinitions) {
  'use strict';

  var userService;

  //
  // User Model
  // --------------------

  function User(profile) {
    this.updateFromProfile(profile);
  }
  
  function hasAnyRole(roles) {
    return $rootScope.user && _.intersection($rootScope.user.roles, roles).length > 0;
  }

  function hasAnyOption(options) {
    return customerNgStore.customer && _.intersection(customerNgStore.customer.options, options).length > 0;
  }
  
  User.prototype.updateFromProfile = function (profile) {
    var defaultPrefs = _.clone(defaultPreferences),
      customer = customerNgStore.customer,
      config = (profile.configJson && JSON.parse(profile.configJson)) || {};
    this.login = profile.login;
    this.id = profile.id;
    this.customerId = profile.customerId;
    this.roles = Object.freeze(profile.roles || []);
    this.assignedAircrafts = _.map(profile.assignedAircrafts, 'registration');
    this.timeZone = profile.timeZone;

    defaultPrefs.displayedData = _.filter(defaultPrefs.displayedData, function (datum) {
      var def = flightfieldDefinitions[datum];
      return (_.isUndefined(def.roles) || hasAnyRole(def.roles)) && (_.isUndefined(def.options) || hasAnyOption(def.options));
    });
    
    this.preferences = (profile.preferencesJson && JSON.parse(profile.preferencesJson)) || {};
    _.defaults(this.preferences, defaultPrefs); //Use default app preferences for undefined prefs.
    _.defaultsDeep(this.preferences.mapOptions, defaultPrefs.mapOptions); //Do deeply only where it is needed, because it adds missing elements on arrays if array exists.
 
    config.displayedAirlines = _.intersection(config.displayedAirlines, customer.airlines || []);
    config.displayedAirports = _.intersection(config.displayedAirports, customer.airports || []);

    this.preferences.extendedTableColumns = _.compact(this.preferences.extendedTableColumns);
    this.preferences.smallTableColumns = _.compact(this.preferences.smallTableColumns);
    
    this.preferences.displayedAirlines = _.difference(config.displayedAirlines || [], this.preferences.hiddenAirlines);
    this.preferences.displayedAirports = _.difference(config.displayedAirports || [], this.preferences.hiddenAirports);
    _.defaults(this.preferences, config); //copy default properties from configuration
    Object.freeze(this.preferences); //they cannot be updated directly, you must you userService.savePreferences
    this.configuration = Object.freeze(config);
  };

  Object.defineProperties(User.prototype, {
    favorites: {
      get: function () {
        //shorthand for preferences favs.
        return this.preferences.favorites;
      }
    },
    layers: {
      get: function () {
        return this.preferences.layers;
      }
    }
  });

  //
  // User Service
  // -------------------
  function UserService() {
    this.profile = null;
    this.user = null;
    //force profile loading inside this context
    this.updateUserProfile = this.updateUserProfile.bind(this);
    userProfileNgStore.on('error', function (response) {
      if (response.status === 401) {
        var currentState = $state.current.name,
          currentParam = $state.params;
        window.console.warn('Current session is unauthorized. Force logout !');
        this.logout();
        if (currentState !== 'login' && currentState !== 'logout') {
          window.console.warn('Going to login page...', currentState, currentParam);
          // session expired or something goes wrong with session
          $state.go('login', {
            returnTo: currentState,
            returnToParams: currentParam
          });
        }
      }
    }, this);

  }

  angular.extend(UserService.prototype, PubSub.prototype, {

    constructor: UserService,

    /**
     * Get the preference value for the given key/path.
     * It support child path like "favorites[0].name"
     * @param {Object} preferences - Preferences to be saved
     */
    getUserPreference: function (propertyName) {
      return _.get(this.user.preferences, propertyName);
    },

    /**
     * Add and save the given preferences keys/values in current user profile.
     * Update the current scope user accordingly
     * @param {Object} preferences - Preferences to be saved
     */
    savePreferences: function (preferences) {
      return new Promise(function (resolve, reject) {
        var defaultPrefs = defaultPreferences,
          updatedPreferences,
          config;
        if (this.profile) {
          config = JSON.parse(this.profile.configJson) || {};
          updatedPreferences = angular.copy(preferences);
          // add the preferences
          _.defaults(updatedPreferences, this.user.preferences);

          //remove values that are default in user's configuration
          updatedPreferences = _.omit(updatedPreferences, function (value, key) {
            return _.isUndefined(defaultPrefs[key]) || defaultPrefs[key] === value;
          });
          userProfileNgStore.set({
            login: this.profile.login,
            preferencesJson: JSON.stringify(updatedPreferences)
              //^this will save and update active profile
          }).then(resolve, reject); //convert Promise to $Deferred
        } else {
          reject();
        }
      }.bind(this));
    },
    updateCustomerId: function () {
      if (userProfileNgStore.userProfile) {
        customerId.set(userProfileNgStore.userProfile.customerId);
      }
    },

    updateUserProfile: function () {
      var defaultPrefs = defaultPreferences,
        profile = userProfileNgStore.userProfile;
      this.updateCustomerId();
      this.profile = profile;
      if (profile && profile.id) {
        if (this.user && this.user.id === profile.id) {
          //update user
          this.user.updateFromProfile(profile);
        } else {
          this.user = new User(profile);
        }
      } else {
        this.user = null;
      }
      $rootScope.user = this.user;
      this.trigger('update', this.user);
      return this.user;
    },

    loadUserProfile: function () {
      return new Promise(function (resolve, reject) {
        var timeout;
        timeout = setTimeout(function () {
          reject('Connection timeout');
        }, 20000);
        if (this.user) {
          resolve(this.user);
        } else {
          userProfileNgStore.once('sync', function () {
            this.updateCustomerId();
            customerNgStore.once('sync', function () {
              this.updateUserProfile();
              this.trigger('login', this.user);
              clearTimeout(timeout);
              if (this.user) {
                resolve(this.user);
              } else {
                reject();
              }
            }, this);
            if (_.isFinite(customerId.get())) {
              customerNgStore.refresh();
            } else {
              //super admin does not have customer.
              customerNgStore.trigger('sync', true, true);
            }
          }, this);

          userProfileNgStore.refresh();
        }
      }.bind(this));
    },

    login: function (credentials) {
      var self = this,
        data = 'j_username=' + encodeURIComponent(credentials.username) + '&j_password=' + encodeURIComponent(credentials.password) + '&j_remember=' + credentials.remember;
      return window.fetch(window.backendPrefix + '/auth/login', {
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        credentials: 'include',
        body: data
      }).then(function (response) {
        return new Promise(function (resolve, reject) {
          if (response.status !== 200) {
            reject(response);
          } else {
            self.user = null;
            self.loadUserProfile().then(resolve, reject);
          }
        });
      })['catch'](function (error) {
        return Promise.reject(error);
      });
    },

    logout: function () {
      //userProfileNgStore.off(null, null, this);
      userProfileNgStore.backupProfile({});
      this.user = null;
      this.profile = null;
      $rootScope.user = null;
      $rootScope.selectedDeskName = undefined;
      window.fetch(window.backendPrefix + '/auth/logout', {
        method: 'post',
        credentials: 'include'
      });
      this.trigger('logout');
    }

  });

  userService = new UserService();
  //listen for profile change or user change.
  userProfileNgStore.on('update add', userService.updateUserProfile, userService);
  customerNgStore.on('update', userService.updateUserProfile, userService);

  return userService;

}]);
