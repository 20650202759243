App.controller('AdminMapCtrl', ['$scope', 'customerNgStore', function ($scope, customerNgStore) {
  'use strict';

  customerNgStore.refresh();
  let customer = customerNgStore.customer;
  let parameters = customer.parameters;

  $scope.mapSettings = {
    mapScaleLineUnits: parameters.mapScaleLineUnits
  };

  $scope.submitMapConfiguration = function () {

    return new Promise(function (resolve, reject) {
      parameters.mapScaleLineUnits = $scope.mapSettings.mapScaleLineUnits;
      customer.parameters = parameters;
      resolve(customerNgStore.set(customer));
    }.bind(this));
  };

}]);
