/*jslint browser: true, plusplus:true*/
/*global angular, ol, App, UserService, ErrorCode */

App
  .controller('AdminUserListCtrl', [
    '$rootScope',
    '$scope',
    '$state',
    '$uibModal',
    'userNgStore',
    'customerNgStore',
    'alertService',
    'userService',
    'userProfileNgStore',
    'userRestService',
    function AdminUserListCtrl($rootScope, $scope, $state, $uibModal, userNgStore, customerNgStore, alertService, userService, userProfileNgStore, userRestService) {
      'use strict';
      var vm = this;

      vm.refreshUserList = function () {
        userNgStore.refresh();
        $scope.allUsers = userNgStore.users;
        $scope.$evalAsync();
      };

      vm.refreshUserList();

      $scope.hasAdminRole = function (user) {
        return user.roles.indexOf(App.ADMIN_ROLE) !== -1;
      };

      // Create user dialog
      $scope.createUser = function (userInForm) {
        $uibModal.open({
          templateUrl: 'views/partials/admin/admin-user-form.html',
          resolve: {
            allUsers: function () {
              return $scope.allUsers;
            },
            userInForm: function () {
              return userInForm;
            },
            isUpdate: function () {
              return false;
            },
            customer: function () {
              return customerNgStore.customer;
            }
          },
          controller: 'AdminUserFormCtrl'
        }).result.then(function (user) {
		  user.configJson = JSON.stringify(user.config);
          userRestService.createUser(customerNgStore.customer.id, user).then((result) => {
            if (result instanceof Error) {
              alertService.error(result.name, result.message).then(function () {
                // reopen edition
                $scope.createUser(user);
              });
            } else {
              vm.refreshUserList();
            }
          });
        });
      };

      // Edit user dialog
      $scope.editUser = function (userInForm) {
        $uibModal.open({
          templateUrl: 'views/partials/admin/admin-user-form.html',
          resolve: {
            allUsers: function () {
              return $scope.allUsers;
            },
            userInForm: function () {
              return userInForm;
            },
            isUpdate: function () {
              return true;
            },
            customer: function () {
              return customerNgStore.customer;
            }
          },
          controller: 'AdminUserFormCtrl'
        }).result.then(function (user) {
		  user.configJson = JSON.stringify(user.config);	
          userRestService.updateUser(customerNgStore.customer.id, user).then((result) => {
            if (result instanceof Error) {
              alertService.error(result.name, result.message).then(function () {
                // reopen edition
                $scope.editUser(user);
              });
            } else {
              vm.refreshUserList();
              if (user.id === userService.user.id) {
                // force userprofile reload if we are editing ourself
                userProfileNgStore.refresh();
              }
            }
          });
        });
      };

      // Delete user functionnality
      $scope.removeUser = function removeUser(userToDelete) {
        alertService
          .confirm('User Deletion', 'Do you really want to delete this user?')
          .then(function () {
            userNgStore.remove(userToDelete);
          });
      };
    }
  ]);
