/*jslint browser:true, vars: true */

(function (global) {
  'use strict';
  var canvas, ctx, x, y;

  canvas = document.createElement('canvas');
  canvas.width = 40;
  canvas.height = 40;
  ctx = canvas.getContext('2d');
  ctx.mozImageSmoothingEnabled = false;
  ctx.webkitImageSmoothingEnabled = false;
  ctx.msImageSmoothingEnabled = false;
  ctx.imageSmoothingEnabled = false;

  function generator( /*ol.style.Text*/ textIcon, size) {
    size = size || 40;
    canvas.width = size;
    canvas.height = size;
    var center = Math.round(size / 2);
    ctx.clearRect(0, 0, size, size);
    ctx.fillStyle = textIcon.getFill() && textIcon.getFill().getColor();
    ctx.strokeStyle = textIcon.getStroke() && textIcon.getStroke().getColor();
    ctx.lineWidth = textIcon.getStroke() && textIcon.getStroke().getWidth();
    ctx.font = textIcon.getFont();
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.strokeText(textIcon.getText(), center, center);
    ctx.fillText(textIcon.getText(), center, center);
    return canvas.toDataURL();
  }

  function olIcon(textIcon, size) {
    var dataUrl = generator(textIcon, size);
    //console.debug('icon', dataUrl);
    return new ol.style.Icon({
      src: dataUrl
    });
  }
  global.generateTextIcon = olIcon;

}(window));
