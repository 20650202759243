/*jslint browser: true, vars: true, plusplus: true, nomen: true */
/*global MapStyleProvider, App, $, angular, ol, moment, _, console, Promise, ServerTime, Papa, Map  */

(function () {
  'use strict';

  /**
   * Map View Controller
   **/
  function ReplayMapCtrl($scope, $rootScope, $state, $transitions, mapPreferences, flightReplayNgStore, userService, customerNgStore, airportDataNgStore, dashFilter, timeFilter, navLayersService, mapStyle) {
    var mc = this,
      realPositionTrailFeat,
      customer = customerNgStore.customer,
      parameters = customer.parameters,
      customerColors = parameters.customColors || {},
      styleProvider,
      map,
      displayedBackground = $scope.user.preferences.map,
      viewExtent = ol.proj.get('EPSG:3857').getExtent(),
      aircraftFeature,
      pointIndexMap = [];

    $scope.logs = [];
    $scope.exportUrl = flightReplayNgStore.getDownloadFlightUrl();
    $scope.currentPositionId = 0;
    //Shared with replay-control-controller
    $scope.progressBar = {
      displayedPosition: null,
      selectedTime: null,
      loading: true
    };


    styleProvider = mapStyle;
    // mapStyle.init({
    //   myfleetColor: customerColors.myfleet,
    //   trackedColor: customerColors.tracked,
    //   cautionColor: customerColors.caution,
    //   warningColor: customerColors.warning,
    //   othersColor: customerColors.other,
    //   closedColor: customerColors.closed,
    //   airportPinColor: '#84BD00',
    //   favoriteAirportPinColor: customerColors.tracked
    // });


    var mapOptions = $scope.mapOptions = $scope.user.preferences.mapOptions;

    styleProvider.setFlightInfoVisible(mapOptions.labels.displayFlightInfo);

    var aircraftVector = new ol.source.Vector();
    var planeLayer = new ol.layer.Vector({
      title: 'Aircraft',
      source: aircraftVector,
      style: styleProvider.trailStyle.bind(styleProvider)
    });

    // var mainAirportVector = new ol.source.Vector({
    //   features: _.map(airportDataNgStore.largeAirports, 'olFeature')
    // });
    // var othersAirportVector = new ol.source.Vector({
    //   features: _.map(airportDataNgStore.mediumAirports, 'olFeature')
    // });

    var trailVector = new ol.source.Vector({
      projection: 'EPSG:4326'
    });

    var waypointVector = new ol.source.Vector({
      projection: 'EPSG:4326'
    });

    var flightPlanVector = new ol.source.Vector({
      projection: 'EPSG:4326'
    });

    var backgroundlayers = new ol.layer.Group({
      'title': 'Base maps',
      layers: [
        new ol.layer.Tile(mapPreferences.maps[displayedBackground])
      ]
    });

    var opacityBgLayer = generateOpacityLayer(mapOptions.brightness);

    function displayPositionTrail(flightPositionTrail) {
      var i,
        point,
        firstPoint, lastPoint, middlePoint,
        aircraftPosition,
        transform,
        flightLineString,
        lastPosTime,
        positions = [],
        pos,
        acarsFeature,
        posOld;
      transform = ol.proj.getTransform('EPSG:4326', 'EPSG:3857');
      trailVector.clear();
      for (i = 0; i < flightPositionTrail.length; i = i + 1) {
        pos = flightPositionTrail[i];

        if (posOld) {
          pos.value[0] = window.GeometryUtils.adjustLongitudeIfCrossedDateLine(posOld[0], pos.value[0]);
        }
        posOld = pos.value;

        point = transform([pos.value[0], pos.value[1]]);
        pointIndexMap[i] = point;
        positions.push(point);
        lastPosTime = pos.timestamp;

        if ("ACARS" === pos.source) {
          acarsFeature = new ol.Feature({
            name: 'acarsPosition',
            geometry: new ol.geom.Point(point)
          });
          trailVector.addFeature(acarsFeature);
        }

        if (!firstPoint) {
          // calculate first point position
          firstPoint = new ol.geom.Point(point);
          aircraftFeature = new ol.Feature({
            name: 'aircraftReplayPosition',
            geometry: new ol.geom.Point(point),
            track: pos.value[5]
          });
          $scope.progressBar.displayedPosition = pos;
        }

        var cursorInMiddleOfTraitment = Math.floor((flightPositionTrail.length - 1) / 2);
        // calculate middle point position
        if (i === cursorInMiddleOfTraitment) {
          middlePoint = new ol.geom.Point(point);
        }
        // calculate last point posistion
        if (i === flightPositionTrail.length - 1) {
          lastPoint = new ol.geom.Point(point);
        }
      } // end of for
      flightLineString = new ol.geom.LineString(positions);
      flightLineString.trail = true;

      realPositionTrailFeat = new ol.Feature({
        name: 'trail',
        geometry: flightLineString,
        state: 0
      });

      //Move aircraft to last position
      trailVector.addFeature(realPositionTrailFeat);
      trailVector.addFeature(aircraftFeature);

      if (firstPoint === undefined || middlePoint === undefined || lastPoint === undefined) {
        throw "could not define a first middle and last point"
      }

      // get coordinates from the 3 points created
      var coordinatesFirstPoint = firstPoint.getFirstCoordinate();
      var coordinatesMiddlePoint = middlePoint.getFirstCoordinate();
      var coordinatesLastPoint = lastPoint.getFirstCoordinate();

      // Create extent with first, middle and last coordinates
      return ol.extent.boundingExtent([coordinatesFirstPoint, coordinatesMiddlePoint, coordinatesLastPoint]);
    }

    var trailLayer = new ol.layer.Vector({
      title: 'Trail',
      source: trailVector,
      style: styleProvider.trailStyle.bind(styleProvider),
      updateWhileAnimating: true
    });

    var waypointsLayer = new ol.layer.Vector({
      title: 'Waypoints',
      source: waypointVector,
      style: styleProvider.waypointStyle.bind(styleProvider),
      updateWhileAnimating: true
    });

    var flightPlanLayer = new ol.layer.Vector({
      title: 'FlightPlan',
      source: flightPlanVector,
      style: styleProvider.flightPlanStyle.bind(styleProvider),
      updateWhileAnimating: true
    });

    var layers = [];

    layers.push(backgroundlayers);
    layers.push(opacityBgLayer.getLayer());

    layers.push(navLayersService.createAirportLayerForReplay());

    //layers.push(othersAirportLayer);
    //layers.push(mainAirportLayer);
    layers.push(planeLayer);
    layers.push(flightPlanLayer);
    layers.push(waypointsLayer);
    layers.push(trailLayer);

    var mapCenterJSON = localStorage.getItem('mapCenter') || '[1037104, 4393016]';
    var mapCenter = JSON.parse(mapCenterJSON);
    var mapResolutionJSON = localStorage.getItem('mapResolution') || '9784';
    var mapResolution = JSON.parse(mapResolutionJSON);

    let scaleUnits = parameters.mapScaleLineUnits || 'nautical';
    let scaleLineControl = new ol.control.ScaleLine();
    scaleLineControl.setUnits(scaleUnits);
    let mapControls = new ol.Collection([
      scaleLineControl
    ]);

    map = mc.map = $scope.map = window.map = new ol.Map({
      target: 'map',
      layers: layers,
      interactions: ol.interaction.defaults({
        altShiftDragRotate: false,
        pinchRotate: false
      }),
      controls: mapControls,
      view: new ol.View({
        center: mapCenter,
        minZoom: 2,
        maxZoom: 17,
        resolution: mapResolution,
        extent: viewExtent,
        projection: 'EPSG:3857'
      })
    });

    map.getView().on('change:center', function (newCenter) {
      localStorage.setItem('mapCenter', JSON.stringify(map.getView().getCenter()));
    });

    /*
    map.getView().on('change:resolution', function (newResolution) {
      localStorage.setItem('mapResolution', JSON.stringify(map.getView().getResolution()));
    });
    */

    // var airportPopover = new ol.Overlay({
    //   element: document.getElementById('airport-popover')
    // });
    // map.addOverlay(airportPopover);

    mc.selectedFeatureOverlay = new ol.layer.Vector({
      map: map,
      source: new ol.source.Vector({
        features: new ol.Collection(),
        useSpatialIndex: false // optional, might improve performance
      }),
      style: styleProvider.selectedPlaneStyle.bind(styleProvider),
      updateWhileAnimating: true, // optional, for instant visual feedback
      updateWhileInteracting: true // optional, for instant visual feedback
    });

    // function displayAirportTooltip(airportFeature, coordinate) {
    //   var airport,
    //     element = airportPopover.getElement(),
    //     tooltipCoordinate;

    //   $(element).popover('destroy');

    //   if (airportFeature) {
    //     airport = airportDataNgStore.findByIcao(airportFeature.getId());
    //     tooltipCoordinate = airportFeature.getGeometry().getCoordinates();
    //     if (coordinate[0] < viewExtent[0]) {
    //       tooltipCoordinate[0] -= viewExtent[2] - viewExtent[0];
    //     } else if (coordinate[0] > viewExtent[2]) {
    //       tooltipCoordinate[0] += viewExtent[2] - viewExtent[0];
    //     }
    //     airportPopover.setPosition(tooltipCoordinate);
    //     // the keys are quoted to prevent renaming in ADVANCED mode.
    //     $(element).popover({
    //       placement: 'top',
    //       animation: false,
    //       ocontainer: '.map-area',
    //       oviewport: {
    //         selector: 'body',
    //         padding: 0
    //       },
    //       'html': true,
    //       'title': '<a>' + airport.icao + ' - ' + airport.iata + '</a>',
    //       'content': '<p>' + (airport.city || airport.name) + '</p>'
    //     });
    //     $(element).popover('show');
    //   }
    // }

    mc.centerMapOnPoint = function (extent) {
      if (extent) {
        var view = map.getView(),
          size = /** @type {ol.Size} */ (map.getSize()),
          duration = 1000,
          start = +new Date();

        view.animate({
          duration: duration,
          source: /** @type {ol.Coordinate} */ (view.getCenter()),
          start: start

        });
        //$airportPopover.hide();

        // Zoom on extent created from 3 aircraft positions  (first, middle and last points)
        view.fit(extent, map.getSize());

      }
    };


    mc.findClosestPositionIndex = function (coordinate, resolution) {
      var distance = 15 * resolution,
        coord = Map.clampCoordinate(viewExtent, coordinate),
        x = coord[0],
        y = coord[1],
        closestPoint = null,
        extent = [x - distance, y - distance, x + distance, y + distance],
        index,
        value,
        diff,
        previousDiff,
        selectIndex;

      if (trailLayer.getMaxResolution() > map.getView().getResolution()) {
        trailVector.forEachFeatureInExtent(extent, function (feature) {
          var geometry = feature.getGeometry();
          // ext = geometry.getExtent(),
          // minFeatureDistance;
          if (geometry.trail) {
            closestPoint = geometry.getClosestPoint(x, y);
          }
        });
      }
      if (closestPoint) {
        _.forEach(pointIndexMap, function (value, index) {
          diff = mc.distanceBetweenPoints(coord, value);
          if (previousDiff && previousDiff > diff) {
            previousDiff = diff;
            selectIndex = index;
          } else if (!previousDiff) {
            previousDiff = diff;
            selectIndex = index;
          }
        });
      }
      return selectIndex;
    };

    // mc.findClosestAirport = function (coordinate, resolution) {
    //   var distance = 15 * resolution,
    //     coord = mc.clampCoordinate(coordinate),
    //     x = coord[0],
    //     y = coord[1],
    //     closestFeature = null,
    //     previousFeatureDistance = Infinity,
    //     extent = [x - distance, y - distance, x + distance, y + distance];

    //   if (mainAirportLayer.getMaxResolution() > map.getView().getResolution()) {
    //     mainAirportVector.forEachFeatureInExtent(extent, function (feature) {
    //       var geometry = feature.getGeometry(),
    //         ext = geometry.getExtent(),
    //         minFeatureDistance;
    //       if (ext[0] === ext[2] && ext[1] === ext[3]) {

    //         minFeatureDistance = Math.abs(x - ext[0]) + Math.abs(y - ext[1]);
    //         if (minFeatureDistance < previousFeatureDistance) {
    //           previousFeatureDistance = minFeatureDistance;
    //           closestFeature = feature;
    //         }
    //       }
    //     });
    //   }
    //   if (othersAirportLayer.getMaxResolution() > map.getView().getResolution()) {
    //     othersAirportVector.forEachFeatureInExtent(extent, function (feature) {
    //       var geometry = feature.getGeometry(),
    //         ext = geometry.getExtent(),
    //         minFeatureDistance;
    //       if (ext[0] === ext[2] && ext[1] === ext[3]) {
    //         minFeatureDistance = Math.abs(x - ext[0]) + Math.abs(y - ext[1]);
    //         if (minFeatureDistance < previousFeatureDistance) {
    //           previousFeatureDistance = minFeatureDistance;
    //           closestFeature = feature;
    //         }
    //       }
    //     });
    //   }
    //   return closestFeature;
    // };

    var zoomByDelta = function (delta) {
      var map = mc.map;
      var view = map.getView();
      var currentResolution = view.getResolution();

      view.animate({
        duration: 250,
        easing: ol.easing.easeOut,
        resolution: currentResolution
      });
      var newResolution = view.constrainResolution(currentResolution, delta);
      view.setResolution(newResolution);
    };

    $scope.zoomIn = function () {
      zoomByDelta(1);
    };

    $scope.zoomOut = function () {
      zoomByDelta(-1);
    };

    $scope.goto = function (place) {
      var map = mc.map;
      var view = map.getView();
      var currentResolution = view.getResolution();
      var currentCenter = view.getCenter();
      var duration = 800;


      view.animate({
        source: currentCenter,
        duration: duration,
        easing: ol.easing.easeOut
      }, {
          duration: 2 * duration,
          resolution: 19784

        });

      view.setResolution(place.resolution);
      //view.fitExtent(place.extent, map.getSize());
      view.setCenter(place.center);
    };

    //
    //Register for change listeners
    //
    $scope.$watch('mapOptions', function (newValue, oldValue) {
      if (!_.eq(newValue, oldValue)) {
        if (newValue.brightness !== oldValue.brightness) {
          opacityBgLayer.changeBrightness(newValue.brightness);
        }
        styleProvider.setFlightInfoVisible(mapOptions.labels.displayFlightInfo);
        //planesLayers.changed(); //force rerender
        userService.savePreferences({
          mapOptions: newValue
        })['catch'](function () {
          //reload last preferences in DB
          mapOptions = $scope.user.preferences.mapOptions;
        });
      }
    }, true);

    $scope.displayFromTo = function (airportIcao, time) {
      return '<div class="from-to-cell"><span class="airport">' + dashFilter(airportIcao) + '</span><span class="time">' + timeFilter(time, 'HH:mm') + '</span></div>';
    };

    $scope.displayRegAcType = function (registration, acType) {
      return '<div class="from-to-cell"><span class="">' + registration + '</span><span class="time">' + acType + '</span></div>';
    };

    //listen for admin configuration updates
    customerNgStore.on('update', function (updatedCustomer) {
      customer = customerNgStore.customer;
      parameters = customer.parameters;
      if (!_.isEqual(customerColors, parameters.customColors)) {
        customerColors = parameters.customColors;
        styleProvider.init({
          myfleetColor: customerColors.myfleet,
          trackedColor: customerColors.tracked,
          cautionColor: customerColors.caution,
          warningColor: customerColors.warning,
          othersColor: customerColors.other,
          closedColor: customerColors.closed,
        });
      }
    }, mc);
    //listen for user preferences changes
    userService.on('update', function () {
      if (displayedBackground !== $scope.user.preferences.map) {
        displayedBackground = $scope.user.preferences.map;
        backgroundlayers.getLayers().clear();
        backgroundlayers.getLayers().push(new ol.layer.Tile($scope.configuration.maps[displayedBackground]));
      }
    }, mc);

    // airportDataNgStore.on('sync', function () {
    //   othersAirportVector.clear();
    //   mainAirportVector.clear();
    //   othersAirportVector.addFeatures(_.map(airportDataNgStore.mediumAirports, 'olFeature'));
    //   mainAirportVector.addFeatures(_.map(airportDataNgStore.largeAirports, 'olFeature'));
    // }, mc);





    var animateAircraft = null;

    $scope.deleteFlight = function (flight) {
      flightReplayNgStore.deleteFlight(flight).then(
        function (response) {
          //replace the edited user

          _.assign(flight, response);
          if ($scope.onlyArchived) {
            $scope.flightReplays.splice($scope.flightReplays.indexOf(flight), 1);
          }
          $scope.numberOfArchive--;
          $scope.updateSlotProgress();
          $scope.$evalAsync();
        },
        function (response) {
          /*          response.json()
                      .then(
                        function (errors) {
                        //  var error = errors.length && errors[0],
                        //    msg = (error && ErrorCode.getMessage(error.code, error.values)) || "";
                        //  alertService.error('Update failed', msg);
                        },
                        function () {
                         // alertService.error('Update failed', "Server Internal");
                        }
                      );*/
        }
      );
    };

    $scope.estimatedRemainingDistance = function () {

      if ($scope.flightInfo) {
        var lastKnownPosition = _.last($scope.flightInfo.positions);
        var actualFlownDistance = 0;
        if ($scope.flightInfo.positions[$scope.currentPositionId]) {
          actualFlownDistance = $scope.flightInfo.positions[$scope.currentPositionId].value[6];
        }
        var airport = airportDataNgStore.findByIcao($scope.flightInfo.to);
        if (airport && lastKnownPosition) {
          var remainingOthodromicDistance = Map.computeOthodromicDistance(lastKnownPosition.value[1], lastKnownPosition.value[0], airport.latitude, airport.longitude,
            true);
          var flownDistance = lastKnownPosition.value[6];
          return parseFloat(flownDistance) + parseFloat(remainingOthodromicDistance) - parseFloat(actualFlownDistance);
        }
      }
      return 0;

    };

    var stopAnimation = function () {
      if ($scope.playing) {
        $scope.playing = false;
        clearInterval(animateAircraft);
        $scope.$evalAsync();
      }
    };

    function moveAircraft(diff) {
      if (!diff) {
        diff = 1;
      }

      if ($scope.flightInfo.positions.length > 0) {
        var flightPositionTrail = $scope.flightInfo.positions;
        var transform = ol.proj.getTransform('EPSG:4326', 'EPSG:3857');
        var nextPosition;
        $scope.currentPositionId += diff;
        nextPosition = flightPositionTrail[$scope.currentPositionId];
        if ($scope.currentPositionId > 0 && !nextPosition) {
          stopAnimation();
          return;
        } else {
          $scope.progressBar.displayedPosition = nextPosition;
        }
        //$scope.$evalAsync();
        aircraftFeature.getGeometry().setCoordinates(transform([$scope.progressBar.displayedPosition.value[0], $scope.progressBar.displayedPosition.value[1]]));
        aircraftFeature.set('track', $scope.progressBar.displayedPosition.value[5]);

        var found = false;
        var closest = null;
        $scope.logs.forEach(function (log) {
          log.active = false;
          if (log.eventDate < nextPosition.timestamp) closest = log
        });
        closest.active = true;
        $scope.$evalAsync();
        return aircraftFeature.getGeometry().clone();
      }
    }

    function moveAircraftToPositionId(positionId) {
      $scope.currentPositionId = positionId;
      return moveAircraft(0);
    }

    var startAnimation = function () {
      if (!$scope.playing) {
        $scope.playing = true;
        animateAircraft = setInterval(moveAircraft, 400);
      }
    };

    $scope.nextPosition = function () {
      moveAircraft(1);
    };

    $scope.previousPosition = function () {
      moveAircraft(-1);
    };


    $scope.runPause = function () {
      if ($scope.playing) {
        stopAnimation();
      } else {
        startAnimation();
      }
    };

    $scope.stop = function () {
      if ($scope.playing) {
        stopAnimation();
      }
      moveAircraftToPositionId(0);
    };


    var getClosestPositionIndex = function (timeToCheck, indexToStart) {
      if ($scope.flightInfo.positions.length > 0) {
        var time = +moment(timeToCheck);
        var positions = $scope.flightInfo.positions;
        var nextPositionIdx = _.findIndex(positions, function (position) {
          return position.timestamp > time;
        });
        if (nextPositionIdx === 0) {
          return nextPositionIdx;
        } else if (nextPositionIdx < 0) {
          return positions.length - 2;
        }
        else if (positions[nextPositionIdx].timestamp - time > time - positions[nextPositionIdx - 1].timestamp) {
          return nextPositionIdx - 1;
        }

        else {
          return nextPositionIdx;
        }
      }
    };


    function getClosestlogIndex(timeclicked) {
      if ($scope.flightInfo.positions.length > 0) {
        var time = +moment(timeclicked);
        var logs = $scope.logs;
        var nextEventIdx = _.findIndex(logs, function (log) {
          return log.eventDate > time;
        });
        if (nextEventIdx === 0) {
          return nextEventIdx;
        } else if (nextEventIdx < 0) {
          return logs.length - 1;
        }
        else if (logs[nextEventIdx].eventDate - time > time - logs[nextEventIdx - 1].eventDate) {
          return nextEventIdx - 1;
        } else {
          return nextEventIdx;
        }
      }
    };

    var goToClosestPosition = function () {
      if ($scope.flightInfo.positions.length > 0) {
        var id = getClosestPositionIndex($scope.selectedTime);
        mc.centerMapOnPoint(moveAircraftToPositionId(id));
        var idlog = getClosestlogIndex($scope.selectedTime)
        $scope.progressBar.displayedPosition = $scope.logs[idlog];
        $scope.logs.forEach(function (log) {
          log.active = false;
        });
        $scope.logs[idlog].active = true;
      }
    };

    function refreshFlight(flightId) {
      //Remove trail from map
      trailVector.clear();
      waypointVector.clear();
      flightPlanVector.clear();
      //Display loader
      $scope.progressBar.loading = true;
      $scope.$evalAsync();
      if (flightId) {
        //Retrieve complete flightData
        flightReplayNgStore.getCompleteFlightReplay(flightId).then(function (data) {
          $scope.logs = [];
          $scope.flightInfo = {
            flightNumber: data.flightNumber,
            callSign: data.callSign,
            registration: data.registration,
            on: data.on,
            off: data.off,
            from: data.from,
            to: data.to,
            fcdate: data.fcdate,
            id: data.id,
            archived: data.archived,
            positions: _.sortBy(data.positions, 'timestamp') || [],
            fullDistance: data.positions ? _.last(data.positions).value[6] : 0,
            acType: data.aircraftType
          };
          if ($scope.flightInfo.acType === undefined) {
            $scope.flightInfo.acType = "";
          }

          if (data.positions) {
            mc.centerMapOnPoint(displayPositionTrail(data.positions));
          }
          var previousLogs = [];
          var previousIndex;
          _.forEach(data.logs, function (log, index) {
            var closestPositionIndex = getClosestPositionIndex(log.eventDate, previousIndex);
            previousIndex = closestPositionIndex;
            var newLog = {
              id: index,
              eventDate: log.eventDate,
              message: log.message,
              subType: log.subType,
              type: log.type,
              sourceId: log.sourceId,
              active: false,
              activeStart: closestPositionIndex
            };
            $scope.logs.push(newLog);
            if (previousLogs.length > 0) {
              if (previousLogs[0].activeStart !== closestPositionIndex) {
                previousLogs.forEach(function (previousLog) {
                  previousLog.activeEnd = closestPositionIndex;
                });
                previousLogs = [];
              }

            }
            previousLogs.push(newLog);
          });

          //If there are still some previous log it means that they will be active till the end of the flight
          previousLogs.forEach(function (log, index) {
            log.activeEnd = $scope.flightInfo.positions.length;
          });

          $scope.currentPositionId = 0;
          $scope.selectedLog = $scope.logs[0];
          $scope.selectedLog.active = true;
          //$scope.selectLog();

          if (data.waypoints) {
            var i, waypoint, waypointOld, waypointFeature, waypoints = data.waypoints,
              transform = ol.proj.getTransform('EPSG:4326', 'EPSG:3857'),
              coordinates = [],
              flightPlanFeature;
            for (i = 0; i < waypoints.length; i++) {
              waypoint = waypoints[i];
              waypointFeature = new ol.Feature({
                name: waypoint.name,
                geometry: new ol.geom.Point(transform([waypoint.longitude, waypoint.latitude]))
              });
              waypointVector.addFeature(waypointFeature);

              if (waypointOld) {
                waypoint.longitude = window.GeometryUtils.adjustLongitudeIfCrossedDateLine(waypointOld.longitude, waypoint.longitude);
              }
              waypointOld = waypoint;

              coordinates.push(transform([waypoint.longitude, waypoint.latitude]));
            }

            flightPlanFeature = new ol.Feature({
              name: 'flightPlanFuture',
              geometry: new ol.geom.LineString(coordinates)
            });
            flightPlanVector.addFeature(flightPlanFeature);
          }


          $scope.$evalAsync();
        });
      }
    }


    $transitions.onSuccess({}, function (trans) {
      //$scope.onlyArchived = toState.name.indexOf('replay.archive') === 0;
      if (trans.to().name.indexOf('replay.map') === 0) {
        refreshFlight(trans.params('to').id);
      } else {
        stopAnimation();
      }
    });

    $scope.selectLog = function (log) {

      $scope.selectedTime = log.eventDate;
      goToClosestPosition();
      $scope.selectedLog = log;
      $scope.logs.forEach(function (log) {
        log.active = false;
      });
      log.active = true;
      $scope.$evalAsync();
    };




    $scope.$watch('progressBar.selectedTime', function (newValue) {
      if (newValue) {
        $scope.selectedTime = newValue;
        goToClosestPosition();
      }
    });

    mc.distanceBetweenPoints = function (latlng1, latlng2) {
      var line = new ol.geom.LineString([latlng1, latlng2]);
      return line.getLength();
    };


    mc.map.on('click', function (evt) {
      var resolution = map.getView().getResolution(),
      //  airport,
        selectIndex;
      //airport = mc.findClosestAirport(evt.coordinate, resolution);
      //displayAirportTooltip(airport, evt.coordinate);
      selectIndex = mc.findClosestPositionIndex(evt.coordinate, resolution);
      if (selectIndex) {
        moveAircraftToPositionId(selectIndex);
      }

    });

    $scope.$on('$destroy', function () {
      userService.off(null, null, mc);
      customerNgStore.off(null, null, mc);
      airportDataNgStore.off(null, null, mc);
      flightReplayNgStore.off(null, null, mc);
      if ($scope.playing) {
        clearInterval(animateAircraft);
      }
    });
    refreshFlight($state.params.id);
  }

  App
    .controller('ReplayMapCtrl', ['$scope', '$rootScope', '$state', '$transitions', 'mapPreferences', 'flightReplayNgStore', 'userService', 'customerNgStore', 'airportDataNgStore', 'dashFilter',
      'timeFilter',
      'navLayersService',
      'mapStyle', ReplayMapCtrl
    ]);



}());
