/*jslint browser: true, plusplus:true */
/*global App*/

(function () {
  'use strict';

  App.constant('defaultPreferences', {
    smallTableColumns: ['originAirport', 'destinationAirport'],
    extendedTableColumns: ['acn', 'acType', 'flightLevel', 'vspeed', 'eta', 'airline'],
    //displayedData: ['flightLevel', 'speed', 'vspeed', 'src', 'track', 'nextIAA', 'nextOAA', 'gw', 'tow', 'wxUpdate', 'ci'],
    displayedData: ['flightLevel', 'vspeed', 'track','speed', 'tow', 'gw', 'src'],
    displayedTabs: ['altitude', 'eta', 'notes'],
    hiddenAirlines: [],
    hiddenAirports: [],
    favorites: [],
    defaultFavoriteId: '',
    favoriteAirports: '',
    timeZone: 'UTC',
    mapOptions: {
      timeForHover: 600,
      brightness: 0.5,
      labels: {
        displayFlightInfo: true,
        autoArrangeLabels: false,
        runwayDetectionZone: false,
        font: 'Small'
      },
      weather: {
        IRSat: false,
        lightning: false,
        airmet: {
          airmet: false,
          turbLo: false,
          turbHi: true,
          llws: true,
          sfcWnd: true,
          icing: true,
          frz: true,
          ifr: true,
          mtnOb: true
        },
        pirep: {
          pirep: false,
          turb: true,
          ice: true,
          wind: false
        },
        sigmet: {
          sigmet: false,
          isigmet: true,
          cwa: true
        }
      },
      se: {
        hiddenLayers: [],
        selectedSeLayers: [],
        // params :{
        //   infraredSatN1 : 2,
        // },
        weather: {
          stormEchoTopsUS: {
            value: 0,
            label: "Current",
            param: "_CURRENT",
            index: 0
          },
          // lightning
          lightning: {
            lightning: false
          },
          lightningCloudStrokes: {
            //value: 5,
            index: 0,
            param: 5
          },
          lightningGroundStrokes: {
            //value: 5,
            index: 0,
            param: 5
          },
          // radar
          //radar : false,
          radar: {
            radar: false,
            coverageArea: false
          },
          radarUS: {
            value: 0,
            label: "Current",
            param: "_CURRENT",
            index: 0
          },
          radarEU: {
            value: 0,
            label: "Current",
            param: "_CURRENT",
            index: 0
          },
          radarCN: {
            value: 0,
            label: "Current",
            param: "_CURRENT",
            index: 0
          },
          radarAU: {
            value: 0,
            label: "Current",
            param: "_CURRENT",
            index: 0
          },
          radarJA: {
            value: 0,
            label: "Current",
            param: "_CURRENT",
            index: 0
          },

          // NWS bulletins
          nws: {
            nws: false,
            warn: false,
            warnAMI: false,
            advisories: false,
            advisoriesAMI: false,
            watches: false
          },
          // EUMetNet MeteoAlarm bulletins
          meteoAlarm: false,
          // Infrared satellite (clouds)
          infraredSat: {
            infraredSat: false
          },
          infraredSatN1: {
            value: 0,
            label: "Current",
            param: "_CURRENT",
            index: 0
          },
          infraredSatN2: {
            value: 0,
            label: "Current",
            param: "_CURRENT",
            index: 0
          },
          infraredSatN3: {
            value: 0,
            label: "Current",
            param: "_CURRENT",
            index: 0
          },
          infraredSatN4: {
            value: 0,
            label: "Current",
            param: "_CURRENT",
            index: 0
          },
          infraredSatN5: {
            value: 0,
            label: "Current",
            param: "_CURRENT",
            index: 0
          },
          infraredSatS1: {
            value: 0,
            label: "Current",
            param: "_CURRENT",
            index: 0
          },
          infraredSatS2: {
            value: 0,
            label: "Current",
            param: "_CURRENT",
            index: 0
          },
          infraredSatS3: {
            value: 0,
            label: "Current",
            param: "_CURRENT",
            index: 0
          },
          infraredSatS4: {
            value: 0,
            label: "Current",
            param: "_CURRENT",
            index: 0
          },
          infraredSatS5: {
            value: 0,
            label: "Current",
            param: "_CURRENT",
            index: 0
          },
          visibleSat: {
              visibleSat: false
          },
          visibleSatN1: {
            value: 0,
            label: "Current",
            param: "_CURRENT",
            index: 0
          },
          visibleSatN2: {
            value: 0,
            label: "Current",
            param: "_CURRENT",
            index: 0
          },
          visibleSatN3: {
            value: 0,
            label: "Current",
            param: "_CURRENT",
            index: 0
          },
          visibleSatN5: {
            value: 0,
            label: "Current",
            param: "_CURRENT",
            index: 0
          },
          tropopause: {
            tropopause: false
          },
          tropopauseHeight: {
            param: "06",
            index: 0
          },
          tropopauseLow: {
            param: "06",
            index: 0
          },
          edrTurbulenceGlobal: {
            // fl
            param: '010',
            index: 0,
            // hours
            param2: "00",
            index2: 0
          },
          edrTurbulenceNam: {
            // fl
            param: '010',
            index: 0,
            // hours
            param2: "00",
            index2: 0
          },
          icingForecastGlobal: {
            // fl
            param: '010',
            index: 0,
            // hours
            param2: "00",
            index2: 0, 
            // aicraft size
            param3: "SMALL",
            index3: 0
          },
          icingForecastNam: {
            // fl
            param: '010',
            index: 0,
            // hours
            param2: "00",
            index2: 0,
            //aircraft size
            param3: "SMALL",
            index3: 0
          },
          edrThunderstormGlobal: {
         	// hours
            param: '00',
            index: 0
          },
          edrThunderstormNam: {
            // hours
            param: '00',
            index: 0
          },
          // Tropical track forecasts
          tropicalForecast: false,
          forecastWindsTempAloft: {
              // fl
              param: '010',
              index: 0,
              // hours
              param2: "06",
              index2: 0
           },
          forecastWindsSpeed: {
              // fl
              param: '010',
              index: 0,
              // hours
              param2: "06",
              index2: 0
          },
          currentWindsTempAloft: {
                // fl
                param: '050',
                index: 0,
          },
          currentWindsSpeed: {
                // fl
                param: '050',
                index: 0,
          },
          // Global Ozone Forecast
          ozoneForecast: {
            ozoneForecast: false,
            param: "06",
            index: 0
          },
          ashForeCast: {
            ashForeCast: false,
            param: "Current",
            index: 0
            },
          jetStreamForeCast: {
            jetStreamForeCast: false,
            param: "06",
            index: 0
           },
          forecastCeiling: {
            forecastCeiling: false,
            param: "00",
            index: 0
           },
          forecastSurfaceFronts: {
             forecastSurfaceFronts: false,
             param: "06",
             index: 0
          },
          surfaceVisibilityForeCast: {
            surfaceVisibilityForeCast: false,
            param: "00",
            index: 0
          }
        },
        aviation: {
          weatherDepiction: false,
          /*weatherDepiction: {
            displayed: false,
            params: []
          },*/
          //metarTaf: false,
          metar: false,
          taf: false,
          currentSurfaceFronts: false,
          currentMETARWeatherDepiction: false,
          stormEchoTops: false,
          tempFlightRestriction: false,
          pireps: false,
          ceilingCategory: false,
          surfaceVisibility: false,
          currentVolcanoes: false,
          volcanicAsh: false,
          forecastWinds: {
        	  forecastWinds: false,
        	  windsTempAloft: {
                hours: {
                  value: '06',
                  index: 0,
                },
                fl: {
                  value: '010',
                  index: 0,
                }
              },
              windsSpeed: {
                hours: {
                  value: '06',
                  index: 0,
                },
                fl: {
                  value: '010',
                  index: 0,
                }
              }
            },

          currentWinds: {
          	  currentWinds: false,
          	  windsTempAloft: {
                 fl: {
                   value: '050',
                   index: 0,
                 }
              },
              windsSpeed: {
               fl: {
                 value: '050',
                 index: 0,
               }
            }
          },
          windTemp: false,
          windSpeed: false,
          globalOzone: false,
          // forecasts
          forecasts: {
            forecasts: false,
            front: false,
            highLowSurface: false,
            jetstream: false,
            surfaceVisibilityForeCast: false,
            tropopause: false
          },
          // sigmets
          sigmets: false,
          // airmets
          airmets: false,
          // EDR Turbulence
          edrTurbulence: {
            edrTurbulence: false,
            globalEDR: {
              hours: {
                value: 'Current',
                index: 0,
              },
              fl: {
                value: '050',
                index: 0,
              }
            },
            namEDR: {
              hours: {
                value: 'Current',
                index: 0,
              },
              fl: {
                value: '050',
                index: 0,
              }
            }
          },
          icingForecastGlobal: {
            icingForecastGlobal: false,
              globalIcing: {
                hours: {
                  value: '06',
                  index: 0,
                },
                fl: {
                  value: '050',
                  index: 0,
                }
              },
            },
            icingForecastNam: {
              icingForecastNam: false,
                namIcing: {
                  hours: {
                    value: '01',
                    index: 0,
                  },
                  fl: {
                    value: '050',
                    index: 0,
                  }
                }
              },
          // EDR Thunderstorm
          edrThunderstorm: {
            edrThunderstorm: false,
            globalEDR: {
              hours: {
                value: '06',
                index: 0,
              }
            },
            namEDR: {
              hours: {
                value: '01',
                index: 0,
              }
            }
          },
          // Global icing
          globalIcing: {
            globalIcing: false,
            small: false,
            medium: false,
            large: false
          },
          // NAM Icing
          namIcing: {
            namIcing: false,
            small: false,
            medium: false,
            large: false
          },
          // Thunderstorm
          thunderstorm: {
            thunderstorm: false,
            nam: false,
            global: false
          }
        }
      },
      // airports: {
      //   large: true,
      //   medium: true,
      //   favorite: false
      // },
      fir: {
        show: false,
        selectable: false
      },
      uir: {
        show: false,
        selectable: false
      },
      navLayers: {
        selectedLevel: 'high',
        selectedLayers: []
      },
      draw: false,
      hiddenDrawing: {},
      notifs: {
        volume: 1.0
      },
      hiddenDefinedAreas : [],
      centerMaxZoom: {
        centerLongitude: 0,
        centerLatitude: 0,
        resolution: 39135.75848201024
      },
      displayMajorCities: {
        displayMajorCities:false,
        maxResolution: 2446,
        zoomIndex: 11
      },
      displayAirportSurfaceMap: {
        displayAirportSurfaceMap:false,
      },      
      maxResolutions: {
        firuir: {
            maxResolution: 9784,
            zoomIndex: 13
        },
        gridMoras: {
            maxResolution: 4892,
            zoomIndex: 12
        },
        airports: {
            maxResolution: 4892,
            zoomIndex: 12
        },
        favouriteAirports: {
            maxResolution: 4892,
            zoomIndex: 12
        },
        airways: {
            maxResolution: 4892,
            zoomIndex: 12
        },
        waypoints: {
            maxResolution: 4892,
            zoomIndex: 12
        },
        holdings: {
            maxResolution: 1223,
            zoomIndex: 10
        },
        navaids: {
            maxResolution: 4892,
            zoomIndex: 12
        },
        restrictiveAirspace: {
            maxResolution: 4892,
            zoomIndex: 12
        },
        controlledAirspace: {
            maxResolution: 2446,
            zoomIndex: 11
        },
        comAreas: {
            maxResolution: 2446,
            zoomIndex: 11
        }
      }
    },
    map: 'navblue',
    airportThresholds: {},
    userTags: {},

  });

}());
