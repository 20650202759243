App.service('favoriteDefaultService', [ 'favoriteRestService', 'userRestService', function(favoriteRestService, userRestService) {

var isDefaultFavoriteApplied =  false ;

        return {
            getIsDefaultFavoriteApplied: function () {
                return isDefaultFavoriteApplied;
            },
            setIsDefaultFavoriteApplied: function(value) {
                isDefaultFavoriteApplied = value;
            },
        };
}]);