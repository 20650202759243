/*jslint browser: true, nomen: true */
/*global App, angular */

App.provider('dataStore', [function dataStoreProvider() {
  'use strict';
  var dataService, _aircraftModels, _airlines, _airportsByIata;

  function DataService() {}

  _aircraftModels = {
    '*': {
      'names': [
        'Other type'
      ],
      'size': 'M'
    },
    'A3ST': {
      'names': [
        'Beluga'
      ],
      'size': 'H'
    },
    'A30B': {
      'names': [
        'A300-B2',
        'B4'
      ],
      'size': 'H'
    },
    'A35K': {
      'names': [
        'A350-1000 XWB'
      ],
      'size': 'H'
    },
    'A306': {
      'names': [
        'A306 AIRBUS',
        'A-300F4-600 L2J H'
      ],
      'size': 'H'
    },
    'A310': {
      'names': [
        'A310 AIRBUS',
        'A-310 L2J H'
      ],
      'size': 'H'
    },
    'A318': {
      'names': [
        'A318 AIRBUS',
        'A-318 L2J M'
      ],
      'size': 'M'
    },
    'A319': {
      'names': [
        'A319 AIRBUS',
        'ACJ L2J M'
      ],
      'size': 'M'
    },
    'A320': {
      'names': [
        'A320 AIRBUS',
        'A-320 L2J M'
      ],
      'size': 'M'
    },
    'A321': {
      'names': [
        'A321 AIRBUS',
        'A-321 L2J M'
      ],
      'size': 'M'
    },
    'A332': {
      'names': [
        'A332 AIRBUS',
        'A-330-200 L2J H'
      ],
      'size': 'H'
    },
    'A333': {
      'names': [
        'A333 AIRBUS',
        'A-330-300 L2J H'
      ],
      'size': 'H'
    },
    'A342': {
      'names': [
        'A342 AIRBUS',
        'A-340-200 L4J H'
      ],
      'size': 'H'
    },
    'A343': {
      'names': [
        'A343 AIRBUS',
        'A-340-300 L4J H'
      ],
      'size': 'H'
    },
    'A345': {
      'names': [
        'A345 AIRBUS',
        'A-340-500 L4J H'
      ],
      'size': 'H'
    },
    'A346': {
      'names': [
        'A346 AIRBUS',
        'A-340-600 L4J H'
      ],
      'size': 'H'
    },
    'A358': {
      'names': [
        'A358 AIRBUS',
        'Prestige (A-350-800) L2J H'
      ],
      'size': 'H'
    },
    'A359': {
      'names': [
        'A359 AIRBUS',
        'A-350-900 XWB Prestige L2J H'
      ],
      'size': 'H'
    },
    'A388': {
      'names': [
        'A380 AIRBUS'
      ],
      'size': 'H'
    },
    'A400': {
      'names': [
        'A400M'
      ],
      'size': 'H'
    },
    'AN3 ': {
      'names': [
        'AN3 ANTONOV',
        'An-3 L1T L'
      ],
      'size': 'L'
    },
    'AN8 ': {
      'names': [
        'AN8 ANTONOV',
        'An-8 L2T M'
      ],
      'size': 'M'
    },
    'AN12': {
      'names': [
        'AN12 ANTONOV',
        'An-12 L4T M'
      ],
      'size': 'M'
    },
    'AN22': {
      'names': [
        'AN22 ANTONOV',
        'An-22 Antheus L4T H'
      ],
      'size': 'H'
    },
    'AN24': {
      'names': [
        'AN24 ANTONOV',
        'An-24 L2T M'
      ],
      'size': 'M'
    },
    'AN26': {
      'names': [
        'AN26 ANTONOV',
        'An-26 L2T M'
      ],
      'size': 'M'
    },
    'AN28': {
      'names': [
        'AN28 ANTONOV',
        'An-28 L2T L'
      ],
      'size': 'L'
    },
    'AN30': {
      'names': [
        'AN30 ANTONOV',
        'An-30 L2T M'
      ],
      'size': 'M'
    },
    'AN32': {
      'names': [
        'AN32 ANTONOV',
        'An-32 L2T M'
      ],
      'size': 'M'
    },
    'AN38': {
      'names': [
        'AN38 ANTONOV',
        'An-38 L2T M'
      ],
      'size': 'M'
    },
    'AN70': {
      'names': [
        'AN70 ANTONOV',
        'An-70 L4T M'
      ],
      'size': 'M'
    },
    'AN72': {
      'names': [
        'AN72 ANTONOV',
        'An-74-200 L2J M'
      ],
      'size': 'M'
    },
    'AS32': {
      'names': [
        'AS32 AS-332L1 Super Puma H2T M'
      ],
      'size': 'M'
    },
    'AS50': {
      'names': [
        'AS50 AEROSPATIALE',
        'Ecureuil H1T L'
      ],
      'size': 'L'
    },
    'AS55': {
      'names': [
        'AS55 AEROSPATIALE',
        'AS-355 Ecureuil 2 H2T L'
      ],
      'size': 'L'
    },
    'AS65': {
      'names': [
        'AS65 EUROCOPTER',
        'Atalef H2T L'
      ],
      'size': 'L'
    },
    'AT43': {
      'names': [
        'AT43 ATR',
        'ATR-42-320 L2T M'
      ],
      'size': 'M'
    },
    'AT44': {
      'names': [
        'AT44 ATR',
        'ATR-42-400 L2T M'
      ],
      'size': 'M'
    },
    'ATR,': {
      'names': [
        'ATR',
        'ATR-42-400 Surveyor L2T M'
      ],
      'size': 'M'
    },
    'AT45': {
      'names': [
        'AT45 ATR',
        'Surveyor (ATR-42-500) L2T M'
      ],
      'size': 'M'
    },
    'AT46': {
      'names': [
        'AT46 ATR',
        'ATR-42-600 L2T M'
      ],
      'size': 'M'
    },
    'AT72': {
      'names': [
        'AT72 ATR',
        'ATR-72-200 L2T M'
      ],
      'size': 'M'
    },
    'AT73': {
      'names': [
        'AT73 ATR',
        'ATR-72-210 L2T M'
      ],
      'size': 'M'
    },
    'AT75': {
      'names': [
        'AT75 ATR',
        'ATR-72-210A L2T M'
      ],
      'size': 'M'
    },
    'AT76': {
      'names': [
        'AT76 ATR',
        'ATR-72-600 L2T M'
      ],
      'size': 'M'
    },
    'B06 ': {
      'names': [
        'B06 AGUSTA',
        'JetRanger H1T L'
      ],
      'size': 'L'
    },
    'B74D': {
      'names': [
        'B74D BOEING',
        '747-400 (domestic',
        'no winglets) L4J H'
      ],
      'size': 'H'
    },
    'B74R': {
      'names': [
        'B74R BOEING',
        '747SR L4J H'
      ],
      'size': 'H'
    },
    'B74S': {
      'names': [
        'B74S BOEING',
        '747SP L4J H'
      ],
      'size': 'H'
    },
    'B77L': {
      'names': [
        'B77L BOEING',
        '777-200LR L2J H'
      ],
      'size': 'H'
    },
    'B77W': {
      'names': [
        'B77W BOEING',
        '777-300ER L2J H'
      ],
      'size': 'H'
    },
    'B78X': {
      'names': [
        'B78X BOEING',
        '787-10 Dreamliner L2J H'
      ],
      'size': 'H'
    },
    'B461': {
      'names': [
        'B461 BRITISH AEROSPACE',
        'BAe-146-100 L4J M'
      ],
      'size': 'M'
    },
    'B462': {
      'names': [
        'B462 BRITISH AEROSPACE',
        'BAe-146-200 Quiet Trader L4J M'
      ],
      'size': 'M'
    },
    'B463': {
      'names': [
        'B463 BRITISH AEROSPACE',
        'BAe-146-300 L4J M'
      ],
      'size': 'M'
    },
    'B732': {
      'names': [
        'B732 BOEING',
        'VC-96 L2J M'
      ],
      'size': 'M'
    },
    'B733': {
      'names': [
        'B733 BOEING',
        '737-300 L2J M'
      ],
      'size': 'M'
    },
    'B734': {
      'names': [
        'B734 BOEING',
        '737-400 L2J M'
      ],
      'size': 'M'
    },
    'B735': {
      'names': [
        'B735 BOEING',
        '737-500 L2J M'
      ],
      'size': 'M'
    },
    'B736': {
      'names': [
        'B736 BOEING',
        '737-600 L2J M'
      ],
      'size': 'M'
    },
    'B737': {
      'names': [
        'B737 BOEING',
        'Clipper L2J M'
      ],
      'size': 'M'
    },
    'B738': {
      'names': [
        'B738 BOEING',
        '737-800 L2J M'
      ],
      'size': 'M'
    },
    'B739': {
      'names': [
        'B739 BOEING',
        '737-900 BBJ3 L2J M'
      ],
      'size': 'M'
    },
    'B741': {
      'names': [
        'B741 BOEING',
        '747-100 L4J H'
      ],
      'size': 'H'
    },
    'B742': {
      'names': [
        'B742 BOEING',
        'VC-25 L4J H'
      ],
      'size': 'H'
    },
    'B743': {
      'names': [
        'B743 BOEING',
        '747-300 L4J H'
      ],
      'size': 'H'
    },
    'B744': {
      'names': [
        'B744 BOEING',
        '747-400 (international',
        'winglets) L4J H'
      ],
      'size': 'H'
    },
    'B748': {
      'names': [
        'B748 BOEING',
        '747-8 L4J H'
      ],
      'size': 'H'
    },
    'B752': {
      'names': [
        'B752 BOEING',
        'C-32 L2J M'
      ],
      'size': 'M'
    },
    'B753': {
      'names': [
        'B753 BOEING',
        '757-300 L2J M'
      ],
      'size': 'M'
    },
    'B762': {
      'names': [
        'B762 BOEING',
        '767-200 L2J H'
      ],
      'size': 'H'
    },
    'B763': {
      'names': [
        'B763 BOEING',
        '767-300 L2J H'
      ],
      'size': 'H'
    },
    'B764': {
      'names': [
        'B764 BOEING',
        '767-400 L2J H'
      ],
      'size': 'H'
    },
    'B772': {
      'names': [
        'B772 BOEING',
        '777-200 L2J H'
      ],
      'size': 'H'
    },
    'B773': {
      'names': [
        'B773 BOEING',
        '777-300 L2J H'
      ],
      'size': 'H'
    },
    'B788': {
      'names': [
        'B788 BOEING',
        '787-8 Dreamliner L2J H'
      ],
      'size': 'H'
    },
    'B789': {
      'names': [
        'B789 BOEING',
        'Dreamliner (Srs.9) L2J H'
      ],
      'size': 'H'
    },
    'E110': {
      'names': [
        'E110 EMBRAER',
        'SC-95 Bandeirante L2T L'
      ],
      'size': 'L'
    },
    'E120': {
      'names': [
        'E120 EMBRAER',
        'Brasilia L2T M'
      ],
      'size': 'M'
    },
    'E121': {
      'names': [
        'E121 EMBRAER',
        'VU-9 Xingu L2T L'
      ],
      'size': 'L'
    },
    'E135': {
      'names': [
        'E135 EMBRAER',
        'EMB-135LR L2J M'
      ],
      'size': 'M'
    },
    'E145': {
      'names': [
        'E145 EMBRAER',
        'EMB-145RS L2J M'
      ],
      'size': 'M'
    },
    'E170': {
      'names': [
        'E170 EMBRAER',
        '175 L2J M'
      ],
      'size': 'M'
    },
    'E190': {
      'names': [
        'E190 EMBRAER',
        'ERJ-190 L2J M'
      ],
      'size': 'M'
    },
    'EC30': {
      'names': [
        'EC30 AIRBUS HELICOPTERS',
        'EC-130 H1T L'
      ],
      'size': 'L'
    },
    'EC35': {
      'names': [
        'EC35 AIRBUS HELICOPTERS',
        'EC-635 H2T L'
      ],
      'size': 'L'
    },
    'EC45': {
      'names': [
        'EC45 AIRBUS HELICOPTERS-KAWASAKI',
        'EC-145 H2T L'
      ],
      'size': 'L'
    },
    'EC55': {
      'names': [
        'EC55 AIRBUS HELICOPTERS',
        'EC-155 H2T L'
      ],
      'size': 'L'
    },
    'EC75': {
      'names': [
        'EC75 AIRBUS HELICOPTERS-HARBIN',
        'Z-15 H2T L'
      ],
      'size': 'L'
    },
    'F15 ': {
      'names': [
        'F15 BOEING',
        'F-15 Raam L2J M '
      ],
      'size': 'M'
    },
    'F16 ': {
      'names': [
        'F16 FOKKER',
        'F-16 Fighting Falcon L1J M'
      ],
      'size': 'M'
    },
    'F16X': {
      'names': [
        'F16X GENERAL DYNAMICS',
        'F-16XL Fighting Falcon L1J M'
      ],
      'size': 'M'
    },
    'F18 ': {
      'names': [
        'F18 ASTA',
        'ATF-18 Hornet L2J M'
      ],
      'size': 'M'
    },
    'FA5X': {
      'names': [
        'FA5X DASSAULT',
        'Falcon 5X L2J M'
      ],
      'size': 'M'
    },
    'FA7X': {
      'names': [
        'FA7X DASSAULT',
        'Falcon 7X L3J M'
      ],
      'size': 'M'
    },
    'FA8X': {
      'names': [
        'FA8X DASSAULT',
        'Falcon 8X L3J M'
      ],
      'size': 'M'
    },
    'FA20': {
      'names': [
        'FA20 DASSAULT',
        'Mystère 20 L2J M'
      ],
      'size': 'M'
    },
    'FA50': {
      'names': [
        'FA50 DASSAULT',
        'Mystère 50 L3J M'
      ],
      'size': 'M'
    },
    'G250': {
      'names': [
        'G250 GULFSTREAM AEROSPACE',
        'Gulfstream G250 L2J M'
      ],
      'size': 'M'
    },
    'G280': {
      'names': [
        'G280 GULFSTREAM AEROSPACE',
        'Gulfstream G280 L2J M'
      ],
      'size': 'M'
    },
    'GA5C': {
      'names': [
        'GA5C GULFSTREAM AEROSPACE',
        'Gulfstream G500 (G-7) L2J M'
      ],
      'size': 'M'
    },
    'GLF3': {
      'names': [
        'GLF3 GULFSTREAM AEROSPACE',
        'C-20B Gulfstream 3 L2J M'
      ],
      'size': 'M'
    },
    'GLF4': {
      'names': [
        'GLF4 GULFSTREAM AEROSPACE',
        'G-4 Gulfstream 4SP L2J M'
      ],
      'size': 'M'
    },
    'GLF5': {
      'names': [
        'GLF5 GULFSTREAM AEROSPACE',
        'G-5SP Gulfstream G550 L2J M'
      ],
      'size': 'M'
    },
    'BCS1': {
      'names': [
        'BCS1 BOMBARDIER',
        'BD-500 CSeries CS100 L2J M'
      ],
      'size': 'M'
    },
    'BCS3': {
      'names': [
        'BCS3 BOMBARDIER',
        'BD-500 CSeries CS300 L2J M'
      ],
      'size': 'M'
    },
    'CL30': {
      'names': [
        'CL30 BOMBARDIER',
        'Challenger 300 L2J M'
      ],
      'size': 'M'
    },
    'CL35': {
      'names': [
        'CL35 BOMBARDIER',
        'Challenger 350 L2J M'
      ],
      'size': 'M'
    },
    'CL60': {
      'names': [
        'CL60 BOMBARDIER',
        'Challenger 650 L2J M'
      ],
      'size': 'M'
    },
    'CRJX': {
      'names': [
        'CRJX BOMBARDIER',
        'CL-600 Regional Jet CRJ-1000 L2J M'
      ],
      'size': 'M'
    },
    'GL5T': {
      'names': [
        'GL5T BOMBARDIER',
        'BD-700 Global 5000 L2J M'
      ],
      'size': 'M'
    },
    'GLEX': {
      'names': [
        'GLEX BOMBARDIER',
        'BD-700 Global Express L2J M'
      ],
      'size': 'M'
    },
    'SU95': {
      'names': [
        'SU95 SUKHOI',
        'Superjet 100-95 L2J M'
      ],
      'size': 'M'
    },
    'SU31': {
      'names': [
        'SU31 SUKHOI',
        'Su-31 L1P L'
      ],
      'size': 'L'
    },
    'SU38': {
      'names': [
        'SU38 SUKHOI',
        'Su-38 L1P L'
      ],
      'size': 'L'
    },
    'SU80': {
      'names': [
        'SU80 SUKHOI',
        'Su-80 L2T M'
      ],
      'size': 'M'
    },
    'AJ27': {
      'names': [
        'AJ27 COMAC',
        'ARJ-21-700 Xiangfeng L2J M'
      ],
      'size': 'M'
    },
    'SB20': {
      'names': [
        'SB20 SAAB',
        '2000 L2T M'
      ],
      'size': 'M'
    },
    'DH8A': {
      'names': [
        'DH8A DE HAVILLAND CANADA',
        'CT-142 Dash 8 L2T M'
      ],
      'size': 'M'
    },
    'DH8B': {
      'names': [
        'DH8B DE HAVILLAND CANADA',
        'DHC-8-200 Dash 8 L2T M'
      ],
      'size': 'M'
    },
    'DH8C': {
      'names': [
        'DH8C DE HAVILLAND CANADA',
        'Dash 8 (300) L2T M'
      ],
      'size': 'M'
    },
    'DH8D': {
      'names': [
        'DH8D DE HAVILLAND CANADA',
        'Dash 8 (400) L2T M'
      ],
      'size': 'M'
    }
  };

  Object.defineProperties(DataService.prototype, {
    aircraftModels: {
      value: _aircraftModels
    },
    aircraftModelCodes: {
      get: function () {
        return Object.keys(_aircraftModels);
      }
    }
  });

  dataService = new DataService();

  this.$get = [function dataStorefactory() {
    return dataService;
  }];

}]);
