/*jslint browser: true, vars: true, nomen:true */
/*global $,_ , angular, Promise */

(function () {
  'use strict';

  angular.module('SmartHub').directive('settingsPanel', [function () {
    return {
      restrict: 'E',
      transclude: true,
      templateUrl: '/views/components/settings-panel.html',
      replace: true,
      scope: {
        method: '&submitFn',
        submitLabel: '@',
        submitIcon: '@',
        displayedBars: '@'
      },
      link: function ($scope, $elem, $attr) {
        var $el = $($elem),
          $form = $el.find('form');
        if (angular.isUndefined($scope.displayedBars)) {
          $scope.displayedBars = 'both';
        }
        if (angular.isUndefined($scope.submitIcon)) {
          $scope.submitIcon = 'glyphicon glyphicon-ok';
        }

        $scope.topIsDisplayed = $scope.displayedBars === 'both' || $scope.displayedBars === 'top';
        $scope.bottomIsDisplayed = $scope.displayedBars === 'both' || $scope.displayedBars === 'bottom';

        function disableForm() {
          $el.find('.label.state').remove();
          $el.find('.spinner').removeClass('hide');
          $el.find('input:not([disabled])').add($el.find('button:not([disabled])')).attr('disabled', true).addClass('disabledWaiting');
        }

        function enableForm() {
          $el.find('.spinner').addClass('hide');
          $el.find('.disabledWaiting').attr('disabled', false);
        }
        
        //Cest Sylvain qui a fait ce "truc"
        $scope.isFormValid = $scope.$parent.isFormValid  || function () {
          return $form.length === 0 || $form[0].checkValidity();
        };


        function submit() {
          var promise;
          //disable forms
          disableForm();
          promise = $scope.$apply($scope.method);
          if (!_.isFunction(promise.then)) {
            promise = Promise.resolve();
          }
          promise.then(function (successMsg) {
            enableForm();
            var label = $('<span class="label state"></span>').appendTo($el.find('.bar'));
            label.one('animationend', function () {
              label.remove();
            });
            label.addClass('label-success').html(_.isString(successMsg) ? successMsg : 'Successfully updated');
          }, function (msg) {
            enableForm();
            var label = $('<span class="label state"></span>').appendTo($el.find('.bar'));
            label.one('animationend', function () {
              label.remove();
            });
            label.addClass('label-danger').html(_.isString(msg) ? msg : 'Update failed !');
          });
          //show success
          //enable form
          return false;
        }

        $el.on('click', '.submit', submit);
        if ($form.length === 1) {
          //add submit hidden button to allow ENTER key submission
          $form.append('<button type="submit" style="display:none!important"></button>');
          $form.on('submit', submit);
        }
      }
    };
  }]);

}());
