/*jslint browser: true, nomen:true */
/*global App, angular, Promise, PubSub, moment, fetch, $, _ */

App.factory('chatService', [
  'userService',
  'flightNgStore',
  function userServiceFactory(userService, flightNgStore) {
    'use strict';

    var instance,
      OPEN = 1,
      MINIMISED = 0;

    function ChatService() {
      PubSub.apply(this);
      this.openedWindows = []; // list of opened chat in the order of opening
      this.states = {};
    }

    angular.extend(ChatService.prototype, PubSub.prototype, {
      OPEN: OPEN,
      MINIMISED: MINIMISED,
      openChat: function (flightId) {
        if (flightNgStore.find(flightId)) {
          var idx = this.openedWindows.indexOf(flightId);
          if (idx !== -1) {
            //remove it to push back at the end
            this.openedWindows.splice(idx, 1);
          }
          this.openedWindows.push(flightId);

          _(this.openedWindows)
            .take(this.openedWindows.length - 2)
            .each(this.reduceChat.bind(this));
          this.states[flightId] = OPEN;
          this.trigger('chat:open', flightId);
        }
      },
      reduceChat: function (flightId) {
        var idx = this.openedWindows.indexOf(flightId);
        if (idx !== -1) {
          this.states[flightId] = MINIMISED;
          this.trigger('chat:reduce', flightId);
        }
      },
      maximiseChat: function (flightId) {
        var idx = this.openedWindows.indexOf(flightId);
        if (idx !== -1) {
          this.states[flightId] = OPEN;
          this.trigger('chat:maximise', flightId);
        }
      },
      closeChat: function (flightId) {
        var idx = this.openedWindows.indexOf(flightId);
        if (idx !== -1) {
          this.openedWindows.splice(idx, 1);
          delete this.states[flightId];
          this.trigger('chat:close', flightId);
        }
      },
      sendMessage: function (flight, msg) {
        var message = {
          msg: msg.toUpperCase()
        };
        return flightNgStore.sendMessage(flight, message);
      },
      markAsRead: function (flight, msg) {
        return flightNgStore.acknowledgeMessage(flight, msg).then(function (msg) {
          this.trigger('chat:read', flight.id, msg);
          return msg;
        }.bind(this));
      },
      closeAll: function () {
        this.openedWindows.length = 0; //clear the array
        this.states = {};
      },
      notifyNewMsg: function (flight, msg) {
        this.trigger('chat:new', flight.id, msg);
      }
    });

    instance = new ChatService();
    userService.on('logout', instance.closeAll, instance);
    flightNgStore.on('updated:newchat', instance.notifyNewMsg, instance);
    return instance;
  }
]);
