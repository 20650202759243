/*jslint browser: true, vars: true, nomen: true */
/*global App, d3, angular, _, moment */

App.controller('FlightDetailAltitudeGraphCtrl', ['$element', '$scope', 'flightNgStore', 'userService', function ($element, $scope, flightNgStore, userService) {
  'use strict';

  var margin = {
      top: 20,
      right: 30,
      bottom: 20,
      left: 10
    },
    width = 250, // 3/5 aspect ratio that match CSS rule
    height = 150,
    outerWidth = width + margin.left + margin.right,
    outerHeight = height + margin.top + margin.bottom;

  var flFormat = d3.format('-03d');

  var x = d3.time.scale()
    .range([0, width]);

  var y = d3.scale.linear()
    .nice(100)
    .range([height, 0]);

  var xAxis = d3.svg.axis()
    .scale(x)
    .ticks(3)
    .tickSize(height)
    .tickFormat(function(d){
                               return moment(d).tz(userService.getUserPreference('timeZone')).format('hh:mm');
                         })
    .orient('top');

  var yAxis = d3.svg.axis()
    .scale(y)
    .ticks(4)
    .tickSize(width)
    .tickFormat(function (d) {
      return 'FL' + flFormat(d);
    })
    .orient('right');

  var area = d3.svg.area()
    .x(function (d) {
      return x(d.timestamp);
    })
    .y0(height)
    .y1(function (d) {
      return y(Math.round(d.value[2] / 100));
    });

  var line = d3.svg.line()
    .x(function (d) {
      return x(d.timestamp);
    })
    .y(function (d) {
      return y(Math.round(d.value[2] / 100));
    });

  var viewBox = '0 0 ' + outerWidth + ' ' + outerHeight;

  var svg = d3.select($element[0]).append('svg')
    .attr('viewBox', viewBox)
    .attr('preserveAspectRatio', 'xMidYMid meet');

  var defs = svg.append('defs');

  /*
    gradient
  */
  var linearGradient = defs.append('linearGradient')
    .attr('id', 'fillGradient')
    .attr('gradientUnits', 'userSpaceOnUse')
    .attr('x1', '0')
    .attr('y1', '0')
    .attr('x2', '0')
    .attr('y2', height);
  linearGradient.append('stop')
    .attr('offset', '0')
    .attr('style', 'stop-color: rgb(0, 133, 173);');
  linearGradient.append('stop')
    .attr('offset', '0.95')
    .attr('style', 'stop-color: rgb(255, 255, 255);');

  /*
    drawing area
  */
  var graph = svg.append('g')
    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
  /*
      X axis
    */
  graph.append('g')
    .attr('class', 'x axis')
    .attr('transform', 'translate(0,' + height + ')')
    .call(xAxis);

  /*
    Y axis
  */
  graph.append('g')
    .attr('class', 'y axis')
    .call(yAxis);

  /*
    Path
  */
  graph.append('path')
    .attr('class', 'area')
    .attr('style', 'fill: url(#fillGradient); opacity: 0.8;');

  graph.append('path')
    .attr('class', 'line');

  graph.append('g')
    .attr('class', 'base')
    .attr('transform', 'translate(0, ' + (height + 5) + ')')
    .append('line')
    .attr('x2', width)
    .attr('y2', 0);

  function updateFlight(positionsHistory) {

    var data = positionsHistory,
      dateRange;

    data = _.filter(data, function (o) {
      return o.source !== 'PROJECTION';
    });

    dateRange = d3.extent(data, function (d) {
      return d.timestamp;
    });
    dateRange[0] = Math.min(dateRange[0], $scope.selectedFlight.off || Infinity);
    dateRange[1] = Math.max(dateRange[1], $scope.selectedFlight.eta || 0);
    x.domain(dateRange);
    y.domain([0, d3.max(data, function (d) {
      return Math.round(d.value[2] / 100);
    })]);

    graph.select(".x.axis")
      .call(xAxis);
    graph.select(".y.axis")
      .call(yAxis);

    graph.select(".line")
      .attr('d', line(data));

    graph.select(".area")
      .attr('d', area(data));

  }

  var positionsHistory = [];
  var timestampUnwatch;

  function refreshData() {
    $element.addClass('loading');
    $element.find('svg').hide();
    flightNgStore.getAircraftPositionTrail($scope.selectedFlight.id).then(function (initialPositions) {
      if (_.isArray(initialPositions)) {
        positionsHistory = initialPositions;
      } else {
        positionsHistory = [];
      }
      $element.find('svg').show();
      $element.removeClass('loading');
      updateFlight(positionsHistory);
    });
  }
  refreshData();
  $scope.$watch('selectedFlight.lastUpdate', function (newTimestamp) {
    if (!!newTimestamp) {
      var newPosition = {
        source: $scope.selectedFlight.src,
        timestamp: $scope.selectedFlight.lastUpdate,
        value: [
          $scope.selectedFlight.long,
          $scope.selectedFlight.lat,
          $scope.selectedFlight.alt
        ]
      };

      positionsHistory.push(newPosition);
      console.log("New FL postition:" + newPosition);
      updateFlight(positionsHistory);
    }
  });
}]);
