App.controller('definedAreaEditController', ['$scope', '$rootScope', 'areaModelFactory', 'definedAreaService', 'alertService', 'definedAreaRestService', '$window', '$timeout', function ($scope, $rootScope, areaModelFactory, definedAreaService, alertService, definedAreaRestService, $window, $timeout) {
  'use strict';

  var vm = this;
  var w = angular.element($window);

  w.bind('resize', function () {
    vm.resize();
  });

  definedAreaService.registerEditController(this);

  const blankArea = areaModelFactory.definedArea(null, null, null, null, null);
  const defaultColour = 'rgba(255,255,255,0.7)';

  class CoordinatePair {
    constructor(latitude, longitude) {
      this.latitude = latitude;
      this.longitude = longitude;
    }
  }

  this.$onInit = function () {
    this.opened = false;
    this.resetEditWindow();
    this.resize();
  };

  this.$onDestroy = function () {
    definedAreaService.unregisterEditController();
  };

  this.close = function () {
    this.opened = false;
    this.resetEditWindow();
  };

  this.open = function () {
    this.opened = true;
  };

  this.resize = function () {
    let padding = 115;

    let max = $(document).height() - $('#udaEditBox').offset().top;
    $('#udaEditBox').css('max-height', max);

    let innerMax = $(document).height() - $('#udaEditScroller').offset().top;
    $('#udaEditScroller').css('max-height', innerMax - padding);
  };

  this.resetEditWindow = function () {
    this.title = "NEW DEFINED AREA";
    this.definedArea = blankArea;
    this.definedArea.name = "";
    this.definedArea.shared = false;
    this.definedArea.coordinatePairs = [
      new CoordinatePair(null, null),
      new CoordinatePair(null, null),
      new CoordinatePair(null, null)
    ];
    this.definedArea.colour = defaultColour;
  };

  this.save = function () {
    if (this.definedArea.name !== '') {
      var coordinatePairs = this.formatToValidGeoJson(this.definedArea.coordinatePairs);
      var area = areaModelFactory.definedArea(this.definedArea.id, this.definedArea.name, $rootScope.user.id, this.getGeoJsonFromCoordinates(coordinatePairs), this.definedArea.colour, this.definedArea.shared);

      if (area.id !== null && this.isShared) {
        alertService
          .confirm('Edit Area', `Area may be in use by other users. Do you really want to update this area: ${area.name}?`)
          .then(() => {
            this.updateDefinedArea(area);
          });
      } else if (area.id !== null) {
        this.updateDefinedArea(area);
      } else {
        this.createDefinedArea(area);
      }
    } else {
      alertService.error('Error', 'Area requires a name!');
    }
  };

  this.updateDefinedArea = function (area) {
    definedAreaRestService.updateDefinedArea(area).then((result) => {
      if (result instanceof Error) {
        alertService.error(result.name, result.message);
      } else {
        toastr.info('Area ' + result.name + ' has updated successfully!', 'Update Successful');
        this.close();
        definedAreaService.refreshAreaModel();
      }
    });
  };

  this.createDefinedArea = function (area) {
    definedAreaRestService.saveDefinedArea(area).then((result) => {
      if (result instanceof Error) {
        alertService.error(result.name, result.message);
      } else {
        toastr.info('Area ' + result.name + ' has saved successfully!', 'Save Successful');
        this.close();
        definedAreaService.refreshAreaModel();
      }
    });
  };

  this.loadArea = function (area) {
    this.resetEditWindow();
    this.definedArea = definedAreaService.getActiveEditArea();
    this.definedArea.coordinatePairs = this.getCoordinatesFromGeoJson(this.definedArea.geoJsonData);
    this.title = "EDIT DEFINED AREA";
    this.isShared = this.definedArea.shared;
  };

  this.getCoordinatesFromGeoJson = function (geoJsonData) {
    var coordinates = [];

    geoJsonData.geometry.coordinates.forEach(shape => {
      shape.forEach(coordinatePair => {
          if (Math.abs(coordinatePair[0]) > 180) {
              coordinatePair[0] = parseFloat((coordinatePair[0] - 360 * Math.sign(coordinatePair[0])).toFixed(10));
          }
        // The GeoJSON spec requires coordinates in Long Lat order; as opposed to Lat Long, which is typical
        coordinates.push(new CoordinatePair(coordinatePair[1], coordinatePair[0]));
      });
    });
    coordinates.pop();
    return coordinates;
  };

  this.getGeoJsonFromCoordinates = function (coordinates) {

    var geoJson = {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": []
      },
      "properties": null
    },
      coordinatesForGeoJson = [];

    coordinates.forEach(coordinate => {
      // The GeoJSON spec requires coordinates in Long Lat order; as opposed to Lat Long, which is typical
      coordinatesForGeoJson.push([(coordinate.longitude),(coordinate.latitude)]);
    });
    geoJson.geometry.coordinates.push(coordinatesForGeoJson);

    return geoJson;
  };

  this.removeCoordinatePair = function (item) {
    if (this.definedArea.coordinatePairs.length > 3) {
      var index = this.definedArea.coordinatePairs.indexOf(item);
      this.definedArea.coordinatePairs.splice(index, 1);
    }
  };

  this.addCoordinatePair = function () {
    this.definedArea.coordinatePairs.push(new CoordinatePair(null, null));
    $timeout(function () {
      let coordinateList = document.getElementById('udaEditScroller');
      coordinateList.scrollTop = coordinateList.scrollHeight;
    }, 0, false);
  };

  this.formatToValidGeoJson = function (coordinatePairs) {
    var validCoordinatesForGeoJson = [];
    coordinatePairs.forEach(pair => {
      pair.latitude = parseFloat(pair.latitude);
      pair.longitude = parseFloat(pair.longitude);
      validCoordinatesForGeoJson.push(new CoordinatePair(pair.latitude, pair.longitude));
    });

    validCoordinatesForGeoJson = this.addMatchingLastCoordinateIfNecessary(validCoordinatesForGeoJson);

    return validCoordinatesForGeoJson;
  };

  this.addMatchingLastCoordinateIfNecessary = function (coordinatePairs) {
    let firstLatitude = this.definedArea.coordinatePairs[0].latitude;
    let firstLongitude = this.definedArea.coordinatePairs[0].longitude;

    let firstAndLastLatitudeMatch = firstLatitude === this.definedArea.coordinatePairs[this.definedArea.coordinatePairs.length - 1].latitude;
    let firstAndLastLongitudeMatch = firstLongitude === this.definedArea.coordinatePairs[this.definedArea.coordinatePairs.length - 1].longitude;

    if (!(firstAndLastLatitudeMatch && firstAndLastLongitudeMatch)) {
      coordinatePairs.push(new CoordinatePair(firstLatitude, firstLongitude));
    }

    return coordinatePairs;
  };

  $scope.onChangeShared = function () {
    if (vm.isShared === true && vm.definedArea.shared === false) {
      definedAreaRestService.associationsState(vm.definedArea).then((result) => {
        if (result instanceof Error) {
          alertService.error(result.name, result.message);
        } else {
          if (result.associatedWithNonOwnedFavorites) {
            alertService
              .confirm('Area In Use', `Area is in use by other users' favorite(s). Do you really want to un-share this area?`)
              .then(() => { }, () => { vm.definedArea.shared = true });
          }
        }
      });
    }
  };

}]);
