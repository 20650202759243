/*jslint browser: true, nomen:true */
/*global App, moment, $, _, console */


App.controller('NotificationsCenterCtrl', ['$scope', 'notificationNgStore', function ($scope, notificationNgStore) {
  'use strict';
  var self = this;
  $scope.notifs = [];

  $scope.getDate = function (notif) {
    var date = moment(notif.creationDate);
    return moment(notif.creationDate).format('HH:mm');
  };

  function refresh() {
    $scope.notifs = _.orderBy(notificationNgStore.notifications, 'creationDate', 'desc');
    $scope.$applyAsync();
  }

  function addNotification(notif) {
    if (notif.type === 'BRIDGE') {
      toastr.error(notif.properties.bridgeId, notif.properties.message);
    }
  }

  notificationNgStore.on('sync', refresh);

  notificationNgStore.on('add', addNotification);

  notificationNgStore.refresh();

  $scope.$on('$destroy', function () {
    notificationNgStore.off('sync', refresh);
    notificationNgStore.off('add', addNotification);
  });
}]);
