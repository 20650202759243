/*jslint browser: true, plusplus:true*/
/*global angular, ol, App, ErrorCode */


App
  .controller('CustomerListPageCtrl', [
    '$rootScope',
    '$scope',
    '$state',
    'customerSuperNgStore',
    'alertService',
    function CustomerListPageCtrl($rootScope, $scope, $state, customerSuperNgStore, alertService) {
      'use strict';
      
    }
  ]);
