/*jslint browser: true, nomen: true */
/*global App, Plumbing, GenericStore, angular */

App.provider("deskAssignmentNgStore", [
  function deskAssignmentNgStoreProvider() {
    "use strict";

    function DeskAssignmentNgStore() {
      GenericStore.call(this);
    }

    DeskAssignmentNgStore.prototype = Object.create(GenericStore.prototype);

    angular.extend(DeskAssignmentNgStore.prototype, {

      constructor: DeskAssignmentNgStore,

      newInstance: function (values) {
        var deskAssignment = {};
        this.updateInstance(deskAssignment, values);
        return deskAssignment;
      },
      updateInstance: function (entity, values) {
        entity.name = values.name;
        entity.flights = values.flights;
      }
    });

    Object.defineProperties(DeskAssignmentNgStore.prototype, {
        deskAssignments: {
          get: function () {
            return this._entities;
          }
        }
      });

    var deskAssignmentNgStore = new DeskAssignmentNgStore();

    this.$get = [
      "deskAssignmentStore",
      function deskAssignmentNgStoreFactory(deskAssignmentStore) {
        deskAssignmentNgStore.store = deskAssignmentStore;
        return deskAssignmentNgStore;
      },
    ];
  },
]);
