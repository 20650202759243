/*jslint browser: true, vars: true, plusplus: true, nomen: true */
/*global App, $, ol, _, console, Promise, ServerTime, Map, GeometryUtils  */

(function () {
  'use strict';

  /**
   * Map View Controller
   **/
  function MapCtrl(
    $scope,
    $rootScope,
    $state,
    $element,
    $uibModal,
    mapPreferences,
    seWeatherRefs,
    flightSectionDefinitions,
    flightNgStore,
    userService,
    userProfileNgStore,
    customerNgStore,
    featureNgStore,
    $timeout,
    mapStyle,
    aircraftTooltipProvider,
    seLayers,
    flightfieldDefinitions,
    navLayersService,
    navLayers,
    definedAreaService,
    defaultPreferences,
    generalConstants,
    mapPositionService
  ) {

    const SOURCE_NFP = 'NAVBLUE_FLIGHT_PLANNING';
    const SOURCE_FLIGHT_AWARE = 'FLIGHT_AWARE';
    const DISTANCE_ELEMENT = 'distance-overlay';
    const BEARING_ELEMENT = 'bearing-overlay';

    //Do not change this as DTN only has layers in 5 minute intervals
    const ANIMATED_LAYER_MINUTES_BETWEEN_FRAMES = 5;

    var FRAME_RATE = 1;
    var animateWeatherInterval;
    var animatedLayers = [];
    var animatedLayersMinutesIntoPast = 175;
    var animatedWeatherLayersToLoad = 36;
    var animatedLayerCollection = new ol.Collection();

    var MAX_LAYERS_PER_GROUP = 1; //12;
    definedAreaService.registerCloseNavLayer(function () { $scope.isDropdownOpened = false; });

    var mc = this,
      isSearchActive,
      realPositionTrailFeat,
      estimatedPositionTrailFeat,
      lastPositionFeat,
      flightPlanCrossedFeature,
      flightPlanFutureFeature,
      geoFlightLineString,
      flightPlanLineString,
      customer = customerNgStore.customer,
      parameters = customer.parameters,
      customerColors = parameters.customColors || {},
      styleProvider,
      map,
      displayedBackground = $scope.user.preferences.map,
      showFlownDistanceKm = $scope.user.preferences.displayedData.indexOf('flownDistanceKm') > -1,
      showFlownDistanceNm = $scope.user.preferences.displayedData.indexOf('flownDistanceNm') > -1,
      viewExtent = ol.proj.get('EPSG:3857').getExtent(),
      coordinateForTooltip,
      refreshTrailInterval = null,
      contentTimeoutId;
    $scope.isDropdownOpened = false;
    mc.flightPlanToggledList = [];

    mc.flightPlanToggleState = {
      warning: false,
      caution: false,
      myfleet: false,
      tracked: false,
      other: false,
      closed: false
    };

    $('body').click(function (evt) {
      if ($(evt.target).closest('.dropdown-toggle').length || $(evt.target).closest('.dropdown-menu').length || $('#formFavoriteAirports').length)
        return;
      $scope.isDropdownOpened = false;
    });

    $scope.enableSearch = true;
    styleProvider = mapStyle;

    mapStyle.updateColors({
      myfleetColor: customerColors.myfleet,
      trackedColor: customerColors.tracked,
      cautionColor: customerColors.caution,
      warningColor: customerColors.warning,
      othersColor: customerColors.other,
      closedColor: customerColors.closed,
      groundVehicleColor: customerColors.other
    });

    aircraftTooltipProvider.updateColors({
      myfleetColor: customerColors.myfleet,
      trackedColor: customerColors.tracked,
      cautionColor: customerColors.caution,
      warningColor: customerColors.warning,
      othersColor: customerColors.other,
      closedColor: customerColors.closed
    });


    var mapOptions = $scope.mapOptions = $scope.user.preferences.mapOptions;


    function syncAnimatedWeatherRadarButtonsState() {
      $scope.disableWeatherRadarSection = {
        radarUS: $scope.mapOptions.se.selectedSeLayers.includes("radarUsAnimated"),
        radarEU: $scope.mapOptions.se.selectedSeLayers.includes("radarEuAnimated"),
        radarCN: $scope.mapOptions.se.selectedSeLayers.includes("radarCnAnimated"),
        radarAU: $scope.mapOptions.se.selectedSeLayers.includes("radarAuAnimated"),
        radarJA: $scope.mapOptions.se.selectedSeLayers.includes("radarJaAnimated")
      };

      $scope.isWeatherRadarAnimatedPlaying = {
        radarUsAnimated: $scope.disableWeatherRadarSection.radarUS,
        radarEuAnimated: $scope.disableWeatherRadarSection.radarEU,
        radarCnAnimated: $scope.disableWeatherRadarSection.radarCN,
        radarAuAnimated: $scope.disableWeatherRadarSection.radarAU,
        radarJaAnimated: $scope.disableWeatherRadarSection.radarJA
      };
    }

    syncAnimatedWeatherRadarButtonsState();

    document.getElementById("audio-notification").volume = mapOptions.notifs.volume;

    styleProvider.setFlightInfoVisible(mapOptions.labels.displayFlightInfo);

    var planesData = {}; //map keyed by sections' names
    var planesLayers = [];
    //reverse iterator because warning must be on top
    _.forEachRight(flightSectionDefinitions, function (s) {
      var section = s.name,
        features,
        clusterSource,
        source,
        layer;
      features = _.chain(flightNgStore.flights)
        .filter('section', section)
        .map('olFeature')
        .value();
      source = new ol.source.Vector({
        features: features
      });
      /*FIXME Cluster is disabled*/
      /*
      if (section === 'other') {
        clusterSource = new ol.source.Cluster({
          //distance: 40,
          source: source
        });
      }
      */

      layer = new ol.layer.Vector({
        title: 'Planes ' + section,
        source: clusterSource || source,
        style: styleProvider.planeStyle.bind(styleProvider),
        zIndex: 10,
      });

      planesData[section] = {
        source: source,
        layer: layer
      }; //map
      planesLayers.push(layer);
    });


    var runwayZoneVector = new ol.source.Vector({
      url: 'data/runway-zones.json',
      format: new ol.format.GeoJSON()
    });

    var trailVector = new ol.source.Vector({
      projection: 'EPSG:4326'
    });

    var waypointVector = new ol.source.Vector({
      projection: 'EPSG:4326'
    });

    var flightPlanVector = new ol.source.Vector({
      projection: 'EPSG:4326'
    });

    var measureVector = new ol.source.Vector({
      projection: 'EPSG:4326'
    });

    var drawFeatures = new ol.Collection();
    var drawVector = new ol.source.Vector({
      features: drawFeatures,
      projection: 'EPSG:4326'
    });

    // Build Airports filter for each customer
    var backgroundlayers = buildMapByCustomer();
    var majorcitiesSource = mapPreferences['majorCities'];
    var majorcitiesLayer = new ol.layer.Group({
      'title': 'Major Cities',
      layers: [
        new ol.layer.Tile(majorcitiesSource)
      ],
      visible: false
    });

    var seLayersGroup = new ol.layer.Group({
      'title': 'SE layers',
      layers: getSeCollectionLayers()
    });

    // NAV Layers Group
    var navLayersGroup = new ol.layer.Group({
      'title': 'NAV layers',
      layers: new ol.Collection(),
    });

    var opacityBgLayer = generateOpacityLayer(mapOptions.brightness);
    var opacitySelectionLayer = generateOpacityLayer(mapOptions.brightness, 11);

    var wdtincWeatherData = {
      'globalirgrid': {
        frames: [],
        visible: mapOptions.weather.IRSat,
        layer: null,
        tileUrl: 'https://airbus-s.wdtinc.com/swarmweb/tile/globalirgrid/{frame}/{z}/{x}/{y}.png?style=enhanced',
        framesUrl: 'https://airbus-s.wdtinc.com/swarmweb/valid_frames?product=globalirgrid'
      },
      'lightning': {
        frames: [],
        visible: mapOptions.weather.lightning,
        layer: null,
        tileUrl: 'https://airbus-s.wdtinc.com/swarmweb/tile/lightning/{frame}/{z}/{x}/{y}.png',
        framesUrl: 'https://airbus-s.wdtinc.com/swarmweb/valid_frames?product=lightning'
      }
    };

    var weatherlayers = new ol.layer.Group({
      visible: true
    });

    function buildMapByCustomer() {

      // Layers array
      var olLayers = [];

      // get map from preferences
      var olMap = mapPreferences.maps[displayedBackground];

      // get airports available for the customer
      var airportListString = '';
      _.each(customer.airports, function (airport) {
        airportListString += '\'' + airport + '\',';
      });
      // delete last ','
      airportListString = airportListString.slice(0, -1);

      // if no airports for the customer : no CQL_FILTER
      if (!_.isEmpty(airportListString)) {
        // The parameter INCLUDE corresponds to CQL parameter to the first layer 'world2'
        // and idpart is the list of airports for Airport.Dynamo.AMDB_large
        olMap.source.updateParams({
          CQL_FILTER: 'INCLUDE;idarpt in (' + airportListString + ')'
        });
      } else {
        // update parameter LAYERS : don't get the airport (Airport.Dynamo.AMDB_large)
        olMap.source.updateParams({
          LAYERS: 'world:world2'
        });
      }

      // if user has enabled airport surface map
      // add the surface map layer
      if (mapOptions.displayAirportSurfaceMap.displayAirportSurfaceMap == true && parameters.surfaceTracking == true) {
        var olOSM = new ol.layer.Tile({
          maxResolution: 76.43,
          source: new ol.source.OSM(mapPreferences.airportMaps)
        });
        // set the minimum zoom level for the base map
        olMap.minResolution = 76.43;
        olLayers.push(olOSM);
      }
      else {
        olMap.minResolution = null;
      }

      var olMapLayer = new ol.layer.Tile(olMap)
      olLayers.push(olMapLayer);

      var backgroundLayers = new ol.layer.Group({
        'title': 'Base maps',
        layers: olLayers
      });

      return backgroundLayers;
    }

    // MxVision WeatherSentry (Schneider Electric)

    function getUTCTime(hours) {
      var date = new Date();

      if (!isNaN(hours)) {
        date.setHours(date.getHours() + parseInt(hours, 10));
      }

      var utcDateTime = date.toISOString();
      return encodeURI(utcDateTime);
    }


    function getSeLayers(layerName) {

      var def = seLayers[layerName];

      if (def) {

        if ($scope.mapOptions.se.weather[layerName]) {

          var param;
          var param2;
          var param3;

          if (def.apiVersion && def.apiVersion === 2) {
            // If layer has both time and flight level, the first parameter is flight level.
            if ($scope.mapOptions.se.weather[layerName].param && $scope.mapOptions.se.weather[layerName].param2) {
              param = $scope.mapOptions.se.weather[layerName].param;
              param2 = $scope.mapOptions.se.weather[layerName].param2;
              param3 = $scope.mapOptions.se.weather[layerName].param3;

              var flightLevel = isNaN(param) ? param : 'FL' + param;

              return _.assign({}, def, {
                layerName: layerName,
                queryName: def.name,
                name: def.name,
                time: getUTCTime(param2),
                flightLevel: flightLevel,
                aircraftSize: param3
              });
              // If layer has only time, the first parameter is time.
            } else {
              param = $scope.mapOptions.se.weather[layerName].param;

              return _.assign({}, def, {
                layerName: layerName,
                queryName: def.name,
                name: def.name,
                time: getUTCTime(param),
                aircraftSize: param3
              });
            }
          } else {

            if ($scope.mapOptions.se.weather[layerName]) {
              param = $scope.mapOptions.se.weather[layerName].param;
              param2 = getLayerTimePart($scope.mapOptions.se.weather[layerName].param2);
              param3 = $scope.mapOptions.se.weather[layerName].param3;
            }
          }
        }

        var name = typeof def.name === 'function' ? def.name(param, param2) : def.name;
        return _.assign({}, def, {
          layerName: layerName,
          queryName: name,
          name: name
        });

      } else {
        return null;
      }
    }

    function refreshSEWeatherLayers() {
      setTimeout(function () {
        seLightningCloudStrokesLayer.getSource().changed();
      }, 0);
      seaMetarTafLayer.getSource().changed();

      setTimeout(function () {
        refreshSEWeatherLayers();
      }, 5000);
    }

    function findFlightFeature(id) {
      var feature;
      _.forEach(planesData, function (data) {
        if (data.layer.getVisible()) {
          feature = data.source.getFeatureById(id);
          if (feature) {
            return false; // break forEachLoop
          }
        }
      });
      return feature;
    }

    function updateFlights(updated, resized) {

      if (updated || resized) {
        let updatedFlightIds = {};
        _.forEach(planesData, function (data) {
          data.source.clear();
        });
        _(flightNgStore.flights)
          .groupBy('section')
          .forEach(function (arrayofFlight, section) {
            var flights;
            if (section === 'closed') {
              flights = [];
            } else if (section === 'tracked') {
              flights = arrayofFlight;
            }
            else {
              flights = arrayofFlight;
            }

            addFeature(updatedFlightIds, section, flights);

          });

        //remove flightIds from flightPlan toggled list that are not returned in the updatedFlights
        for (let i = mc.flightPlanToggledList.length - 1; i >= 0; i--) {
          let flightId = mc.flightPlanToggledList[i];
          if (!updatedFlightIds[flightId]) {
            mc.flightPlanToggledList.splice(i, 1);
            mc.removeFlightPlansByFlightIdOrUntoggledState(flightId);
          }
        }
      }
    }

    function addFeature(updatedFlightIds, section, flights) {
      planesData[section].source.addFeatures(_.map(flights, 'olFeature'));

      if (!_.isEmpty(flights)) {
        flights.forEach(flight => {
          updatedFlightIds[flight.id] = flight.id;
        })
      }
    }

    function updateFeatures(updated, resized) {
      if (updated || resized) {
        drawVector.clear();
        _.forEach(featureNgStore.myFeatures, function (featureNg) {
          if (!featureNg.isEdited && featureNg.isVisible) {
            drawVector.addFeature(featureNg.olFeature);
          }
        });
        _.forEach(featureNgStore.othersFeatures, function (featureNg) {
          if (!featureNg.isEdited && featureNg.isVisible) {
            drawVector.addFeature(featureNg.olFeature);
          }
        });
      }
    }

    function displayPositionTrail(flightPositionTrail, trailVector, aircraftFeature) {
      var i,
        transform,
        flightLineString,
        estimatedFlightLineString,
        projectionLineString,
        lastPosTime,
        hasEstimate,
        positions = [],
        projectionPositions = [],
        estimatedPositions = [],
        geoPositions = [],
        pos,
        lastUpdate = aircraftFeature.get('lastUpdate'),
        acarsFeature,
        estimatedFeature,
        estimatedProjectionFeature,
        posOld,
        flownDistanceKm,
        flownDistanceNm,
        previousPos,
        selectedFlight = $scope.selectedFlight;


      if (!selectedFlight) {
        return;
      }

      transform = ol.proj.getTransform('EPSG:4326', 'EPSG:3857');

      // Init estimatedFlightLineString with last real position from flight
      estimatedPositions.push([selectedFlight.long, selectedFlight.lat]);
      trailVector.clear();
      flownDistanceKm = 0;
      flownDistanceNm = 0;
      for (i = 0; i < flightPositionTrail.length; i = i + 1) {

        pos = flightPositionTrail[i];

        //Get last position update
        if (i === 0) {
          lastPosTime = pos.timestamp;
        }
        if ('ACARS' === pos.source) {
          acarsFeature = new ol.Feature({
            name: 'acarsPosition',
            geometry: new ol.geom.Point(transform([pos.value[0], pos.value[1]]))
            //state: 1
            //state: !!aircraftFeature.get('customerAircraft') ? aircraftFeature.get('alertLvl') || 0 : /* others */ 3
          });
          trailVector.addFeature(acarsFeature);
        }

        if ('PROJECTION' === pos.source) {
          if (posOld) {
            projectionPositions.push([window.GeometryUtils.adjustLongitudeIfCrossedDateLine(posOld[0], pos.value[0]), pos.value[1]]);
          } else {
            projectionPositions.push([pos.value[0], pos.value[1]]);
          }
        } else if ('INTERNAL_ESTIMATED' === pos.source) {

          if (pos.timestamp > selectedFlight.lastUpdate) {
            // the position isEstimated, and fresher than last update : add estimate update to estimatedFlightLineString
            estimatedPositions.push([pos.value[0], pos.value[1]]);
          }
        } else {
          positions.push([pos.value[0], pos.value[1]]);
          geoPositions.push([pos.value[0], pos.value[1]]);

          if (showFlownDistanceKm || showFlownDistanceNm) {
            if (previousPos) {
              if (showFlownDistanceKm) {
                flownDistanceKm += Map.computePreciseOthodromicDistance(previousPos.value[1], previousPos.value[0], pos.value[1], pos.value[0], true);
              }
              if (showFlownDistanceNm) {
                flownDistanceNm += Map.computePreciseOthodromicDistance(previousPos.value[1], previousPos.value[0], pos.value[1], pos.value[0], false);
              }
            }
            previousPos = pos;
          }

          lastPosTime = pos.timestamp;
        }
        posOld = pos.value;
      }

      // this estimatedFlightLineString contains only 1 point (no estimated position) we close estimatedFlightLineString with aircraft position
      if (estimatedPositions.length === 1) {
        let currAircraftCoords = aircraftFeature.getGeometry().clone().transform('EPSG:3857', 'EPSG:4326').getLastCoordinate();
        estimatedPositions.push(currAircraftCoords);
      }

      //Add projection to destination airport
      if (projectionPositions.length > 0 && waypointVector.getFeatures().length === 0) {
        projectionLineString = createLineStringGeometry(projectionPositions);
        estimatedProjectionFeature = new ol.Feature({
          name: 'projectionTrail',
          geometry: projectionLineString
        });
        trailVector.addFeature(estimatedProjectionFeature);

        estimatedProjectionFeature = new ol.Feature({
          name: 'projectionCap',
          geometry: new ol.geom.Point(transform(projectionPositions[0]))
        });
        trailVector.addFeature(estimatedProjectionFeature);

        estimatedProjectionFeature = new ol.Feature({
          name: 'projectionCap',
          geometry: new ol.geom.Point(transform(projectionPositions[projectionPositions.length - 1]))
        });
        trailVector.addFeature(estimatedProjectionFeature);
      }
      if (positions.length === 0) {
        positions.push(aircraftFeature.getGeometry().getCoordinates());
      }
      flightLineString = createLineStringGeometry(positions);
      geoFlightLineString = createLineStringGeometry(geoPositions);
      estimatedFlightLineString = createLineStringGeometry(estimatedPositions);
      geoFlightLineString.transform("EPSG:3857", "EPSG:4326");
      if (showFlownDistanceKm) {
        selectedFlight.flownDistanceKm = Math.round(flownDistanceKm);
      }
      if (showFlownDistanceNm) {
        selectedFlight.flownDistanceNm = Math.round(flownDistanceNm);
      }
      realPositionTrailFeat = new ol.Feature({
        name: 'trail',
        geometry: flightLineString,
        state: !!aircraftFeature.get('customerAircraft') ? 0 : 1
      });
      lastPositionFeat = new ol.Feature({
        name: 'lastRealPosition',
        geometry: new ol.geom.Point(flightLineString.getLastCoordinate()),
        state: !!aircraftFeature.get('customerAircraft') ? 0 : 1 //!!aircraftFeature.get('closed') ? 4 : !!aircraftFeature.get('customerAircraft') ? aircraftFeature.get('alertLvl') || 0 : /* others */ 3
      });

      estimatedPositionTrailFeat = new ol.Feature({
        name: 'estimatedTrail',
        geometry: estimatedFlightLineString,
        state: !_.isUndefined(aircraftFeature.get('alertLvl')) ? aircraftFeature.get('alertLvl') : 3
      });

      trailVector.addFeature(realPositionTrailFeat);
      trailVector.addFeature(lastPositionFeat);
      trailVector.addFeature(estimatedPositionTrailFeat);
    }

    function displayFlightPlan(flightPlans, waypointVector, flightPlanVector, aircraftFeature, selectedFlightId) {
      var transform,
        waypointFeature,
        waypointState,
        lastCrossedWaypoint,
        flightId;

      if (aircraftFeature) {
        lastCrossedWaypoint = aircraftFeature.get('lastCrossedWaypoint');
        flightId = aircraftFeature.getId();
      } else {
        flightId = selectedFlightId;
      }

      transform = ol.proj.getTransform('EPSG:4326', 'EPSG:3857');
      mc.removeFlightPlansByFlightIdOrUntoggledState(flightId);

      flightPlans.forEach(flightPlan => {
        let crossedCoordinates = [];
        let futureCoordinates = [];
        let waypointOld;

        flightPlan.waypointDtoList.forEach(waypoint => {

          // The server is only keeping track of crossed waypoints for NFP flight plans. For other sources, act as crossed.
          if (flightPlan.source !== SOURCE_NFP) {
            waypoint.name = waypoint.name || ' ';
            waypoint.crossed = true;
            waypoint.waypointState = 'crossed';
            crossedCoordinates.push([waypoint.longitude, waypoint.latitude]);
          }
          // NFP flight plans
          else {
            if (lastCrossedWaypoint === waypoint.order) {
              futureCoordinates.push([waypoint.longitude, waypoint.latitude]);
            }

            if (lastCrossedWaypoint && waypoint.order <= lastCrossedWaypoint) {
              waypointState = 'crossed';
            } else {
              waypointState = 'future';
            }
            if (lastCrossedWaypoint && waypoint.order <= lastCrossedWaypoint) {
              crossedCoordinates.push([waypoint.longitude, waypoint.latitude]);
            } else {
              futureCoordinates.push([waypoint.longitude, waypoint.latitude]);
            }

          }

          waypointFeature = new ol.Feature({
            name: waypoint.name,
            state: waypointState,
            crossed: waypoint.isCrossed,
            geometry: new ol.geom.Point(transform([waypoint.longitude, waypoint.latitude])),
            flightId: flightId
          });
          waypointVector.addFeature(waypointFeature);

        });
        if (crossedCoordinates.length != 0) {
          var geometry = createLineStringGeometry(crossedCoordinates);
          flightPlanCrossedFeature = new ol.Feature({
            name: 'flightPlanCrossed',
            source: flightPlan.source,
            geometry: geometry,
            flightId: flightId
          });
          flightPlanVector.addFeature(flightPlanCrossedFeature);
        }
        if (futureCoordinates != 0) {
          var geometry = createLineStringGeometry(futureCoordinates);
          flightPlanFutureFeature = new ol.Feature({
            name: 'flightPlanFuture',
            source: flightPlan.source,
            geometry: geometry,
            flightId: flightId
          });
          flightPlanVector.addFeature(flightPlanFutureFeature);
        }
      });
    }

    function displayGreatCircle(flight, flightPlanVector, waypointVector) {
      if (flight.originAirport && flight.destinationAirport) {
        var transform = ol.proj.getTransform('EPSG:4326', 'EPSG:3857');
        var arcGenerator = new GreatCircle(
          { x: flight.originAirport.longitude, y: flight.originAirport.latitude },
          { x: flight.destinationAirport.longitude, y: flight.destinationAirport.latitude });
        var arcLine = arcGenerator.Arc(100, { offset: 10 });
        arcLine.geometries.forEach(lineGeometry => {
          var line = new ol.geom.LineString(lineGeometry.coords);
          line.transform('EPSG:4326', 'EPSG:3857');
          var lineFeature = new ol.Feature({
            name: 'flightPlanGreatCircle',
            geometry: line,
            finished: false
          });

          flightPlanVector.addFeature(lineFeature);
        });
        var featureOriginAirport = new ol.Feature({
          name: flight.originAirport.icao,
          geometry: new ol.geom.Point(transform([flight.originAirport.longitude, flight.originAirport.latitude]))
        });
        waypointVector.addFeature(featureOriginAirport);

        var featureDestinationAirport = new ol.Feature({
          name: flight.destinationAirport.icao,
          geometry: new ol.geom.Point(transform([flight.destinationAirport.longitude, flight.destinationAirport.latitude]))
        });
        waypointVector.addFeature(featureDestinationAirport);
      }
    }
    function createLineStringGeometry(points) {
      var pointsSplitted = [];
      var pointsArray = [];
      pointsSplitted.push(points[0]);
      var lastLambda = points[0][0];

      for (var i = 1; i < points.length; i++) {
        var lastPoint = points[i - 1];
        var nextPoint = points[i];
        if (Math.abs(nextPoint[0] - lastLambda) > 180) {
          var deltaX = xToValueRange(nextPoint[0] - lastPoint[0]);
          var deltaY = nextPoint[1] - lastPoint[1];
          var deltaXS = xToValueRange(180 - nextPoint[0]);
          var deltaYS;
          if (deltaX === 0) {
            deltaYS = 0;
          } else {
            deltaYS = deltaY / deltaX * deltaXS;
          }
          var sign = lastPoint[0] < 0 ? -1 : 1;
          pointsSplitted.push([180 * sign, nextPoint[1] + deltaYS]);
          pointsArray.push(pointsSplitted);
          pointsSplitted = [];
          pointsSplitted.push([-180 * sign, nextPoint[1] + deltaYS]);
        }
        pointsSplitted.push(nextPoint);
        lastLambda = nextPoint[0];
      }
      pointsArray.push(pointsSplitted);
      var geom = new ol.geom.MultiLineString(pointsArray);
      geom.transform("EPSG:4326", "EPSG:3857");
      return geom;
    }

    function xToValueRange(x) {
      if (Math.abs(x) > 180) {
        var sign = x < 0 ? -1 : 1;
        return x - 2 * 180 * sign;
      } else {
        return x;
      }
    }

    function updatePositionTrail(flight, state) {

      var realPosition = [flight.long, flight.lat];
      var estimated = [flight.estimateLong, flight.estimateLat];

      var lastRealPosition = lastPositionFeat.getGeometry().clone().transform('EPSG:3857', 'EPSG:4326').getLastCoordinate();
      let lastEstimatedPosition = estimatedPositionTrailFeat.getGeometry().clone().transform('EPSG:3857', 'EPSG:4326').getLastCoordinate();

      if (lastRealPosition) {
        realPosition[0] = window.GeometryUtils.adjustLongitudeIfCrossedDateLine(lastRealPosition[0], realPosition[0]);
      }
      if (lastEstimatedPosition) {
        estimated[0] = window.GeometryUtils.adjustLongitudeIfCrossedDateLine(lastEstimatedPosition[0], estimated[0]);
      }

      var transform = ol.proj.getTransform('EPSG:4326', 'EPSG:3857');
      realPosition = transform(realPosition);
      estimated = transform(estimated);

      lastPositionFeat.getGeometry().setCoordinates(realPosition);
      lastPositionFeat.set('state', state);

      // Define last real position
      var realPositionTrailLineFeat = realPositionTrailFeat.getGeometry().getLineString(0);
      realPositionTrailLineFeat.appendCoordinate(realPosition);

      if (flight.lastUpdate < flight.lastEstimatedUpdate) {
        // lastEstimatedUpdate fresher than lastUpdate : we add estimated position and display estimated track
        var estimatedPositionTrailLineFeat = estimatedPositionTrailFeat.getGeometry().getLineString(0);
        estimatedPositionTrailLineFeat.appendCoordinate(estimated);
        // Display estimated trail
        estimatedPositionTrailLineFeat.set('hidden', false);
      } else {
        // Re-init estimated trail with last real position
        var estimatedPositionTrailLineFeat = estimatedPositionTrailFeat.getGeometry().getLineString(0);
        estimatedPositionTrailLineFeat.setCoordinates([realPosition]);
        // Hide estimated trail
        estimatedPositionTrailLineFeat.set('hidden', true);
      }

    }

    function updateFlightFlownDistance(flight) {
      if (geoFlightLineString.getCoordinates() && (geoFlightLineString.getLastCoordinate()[0] !== flight.long && geoFlightLineString.getLastCoordinate()[1] !== flight.lat)) {
        geoFlightLineString.getLineString(0).appendCoordinate([flight.long, flight.lat]);
        if (showFlownDistanceKm) {
          flight.flownDistanceKm += Math.round(Map.computePreciseOthodromicDistance(geoFlightLineString.getLastCoordinate()[1], geoFlightLineString.getLastCoordinate()[0], flight.lat, flight.long,
            true));
        }
        if (showFlownDistanceNm) {
          flight.flownDistanceNm += Math.round(Map.computePreciseOthodromicDistance(geoFlightLineString.getLastCoordinate()[1], geoFlightLineString.getLastCoordinate()[0], flight.lat, flight.long,
            false));
        }
      }
    }

    function createWdtincLayerTile(layerName, layer_opts) {
      return new ol.layer.Tile({
        title: layerName,
        visible: false,
        source: new ol.source.XYZ({
          crossOrigin: 'anonymous',
          tileUrlFunction: function (tileCoord) {
            var url, frame = '';
            if (layer_opts.frames.length) {
              // alwaysTake the available frame
              frame = layer_opts.frames[layer_opts.frames.length - 1];
            }
            url = layer_opts.tileUrl;
            url = url
              .replace('{frame}', frame)
              .replace('{z}', tileCoord[0])
              .replace('{x}', tileCoord[1])
              .replace('{y}', (-tileCoord[2] - 1).toString());
            return url;
          }
        })
      });
    }

    function checkWdtincValidFrames(layer_opts, layerName) {
      if (layer_opts.visible) {
        window.fetch(layer_opts.framesUrl)
          .then(function (response) {
            return response.text();
          }).then(function (csv) {
            layer_opts.frames = csv.split(',');
            if (layer_opts.layer === null) {
              layer_opts.layer = createWdtincLayerTile(layerName, layer_opts);
              layer_opts.layer.setVisible(layer_opts.visible);
              weatherlayers.getLayers().push(layer_opts.layer);
            }
            layer_opts.layer.changed();
          });
      }
    }


    function checkwdtincWeather() {
      _.forIn(wdtincWeatherData, checkWdtincValidFrames);
    }


    var runwayZoneLayer = new ol.layer.Vector({
      title: 'Runway Zone',
      source: runwayZoneVector,
      style: styleProvider.runwayStyle.bind(styleProvider),
      maxResolution: 39,
      visible: mapOptions.labels.runwayDetectionZone
    });

    var trailLayer = new ol.layer.Vector({
      title: 'Trail',
      source: trailVector,
      style: styleProvider.trailStyle.bind(styleProvider),
      updateWhileAnimating: true,
      zIndex: 12
    });

    var waypointsLayer = new ol.layer.Vector({
      title: 'Waypoints',
      source: waypointVector,
      style: styleProvider.waypointStyle.bind(styleProvider),
      updateWhileAnimating: true
    });

    var flightPlanLayer = new ol.layer.Vector({
      title: 'FlightPlan',
      source: flightPlanVector,
      style: styleProvider.flightPlanStyle.bind(styleProvider),
      updateWhileAnimating: true
    });

    var measureLayer = new ol.layer.Vector({
      title: 'Measure',
      source: measureVector,
      style: styleProvider.measureStyle.bind(styleProvider),
      updateWhileAnimating: true,
      visible: false
    });

    var drawLayer = new ol.layer.Vector({
      title: 'Draw',
      source: drawVector,
      style: styleProvider.drawStyle.bind(styleProvider),
      updateWhileAnimating: true,
      visible: false
    });

    var selectionMarker = new ol.Feature(new ol.geom.Point([0, 0]));
    var selectionMarkerLayer = new ol.layer.Vector({
      title: 'Selection',
      source: new ol.source.Vector({
        features: new ol.Collection([selectionMarker])
      }),
      visible: false,
      style: new ol.style.Style({
        image: new ol.style.Circle({
          radius: 10,
          snapToPixel: false,
          fill: new ol.style.Fill({
            color: [0, 0, 0, 0.3]
          }),
          stroke: new ol.style.Stroke({
            color: [250, 250, 250, 0.3],
            width: 2
          })
        })
      })
    });
    var selectionHighlightLayer = new ol.layer.Image({
      title: "Selection highlight",
      source: new ol.source.ImageWMS({
        url: navLayers.url,
        params: {
          env: 'color:#ff6600'
        },
        serverType: 'geoserver',
      }),
      visible: false,
      zIndex: 13,
    });

    var navSelectionGroup = new ol.layer.Group({
      'title': 'NAV selection',
      layers: new ol.Collection([selectionMarkerLayer, selectionHighlightLayer]),
    });
    $rootScope.layers = {
      measureLayer: measureLayer,
      drawLayer: drawLayer
    };

    var layers = [];

    layers.push(backgroundlayers);
    layers.push(majorcitiesLayer);
    layers.push(weatherlayers);
    layers.push(opacityBgLayer.getLayer());
    layers.push(seLayersGroup);
    layers.push(runwayZoneLayer);
    // Add Nav layer group
    layers.push(navLayersGroup);

    //Add plane layers
    _.forEach(planesLayers, function (p) {
      layers.push(p);
    });

    layers.push(opacitySelectionLayer.getLayer());
    layers.push(flightPlanLayer);
    layers.push(trailLayer);
    layers.push(waypointsLayer);
    layers.push(drawLayer);
    layers.push(measureLayer);
    layers.push(navSelectionGroup);


    function getLayerWithName(name) {
      var layerToFind = null;
      if (layers) {
        _.forEach(layers, function (layer) {
          if (layer.getProperties().title === name) {
            layerToFind = layer;
          }
        });
      }
      return layerToFind;
    }

    let defaultMapPosition = defaultPreferences.mapOptions.centerMaxZoom;
    var mapCenterJSON = localStorage.getItem('mapCenter') || JSON.stringify([defaultMapPosition.centerLongitude, defaultMapPosition.centerLatitude]);
    var mapCenter = JSON.parse(mapCenterJSON);
    var mapResolutionJSON = localStorage.getItem('mapResolution') || JSON.stringify(defaultMapPosition.resolution);
    var mapResolution = JSON.parse(mapResolutionJSON);

    let scaleUnits = parameters.mapScaleLineUnits || 'nautical';
    let scaleLineControl = new ol.control.ScaleLine();
    scaleLineControl.setUnits(scaleUnits);

    var mapControls = new ol.Collection([
      scaleLineControl
    ]);
    if ($scope.hasRole(App.TEST_ROLE)) {
      // add map infos custom control to map
      mapControls.push(new mapControl.zoomInfoControl());
    }

    map = window.map = new ol.Map({
      target: 'map',
      //renderer: 'webgl',
      layers: layers,
      interactions: ol.interaction.defaults({
        doubleClickZoom: false,
        altShiftDragRotate: false,
        pinchRotate: false
      }),
      controls: mapControls,
      view: new ol.View({
        center: mapCenter,
        minZoom: 2,
        maxZoom: 17,
        resolution: mapResolution,
        extent: [
          Number.NEGATIVE_INFINITY, viewExtent[1], Number.POSITIVE_INFINITY, viewExtent[3]
        ],
        projection: 'EPSG:3857'
      })
    });

    definedAreaService.registerMap(map);
    mapPositionService.registerMap(map);

    map.on('moveend', function () {
      mapPositionService.refreshMap();
    });
    map.getView().on('change:center', function (newCenter) {
      localStorage.setItem('mapCenter', JSON.stringify(map.getView().getCenter()));
    });
    map.getView().on('change:resolution', function (newResolution) {
      localStorage.setItem('mapResolution', JSON.stringify(map.getView().getResolution()));
    });

    var planePopover = new ol.Overlay({
      element: document.getElementById('plane-popover')
    });
    map.addOverlay(planePopover);

    var pirepPopover = new ol.Overlay({
      element: document.getElementById('pirep-popover')
    });
    map.addOverlay(pirepPopover);

    var airmetPopover = new ol.Overlay({
      element: document.getElementById('airmet-popover')
    });
    map.addOverlay(airmetPopover);

    var sigmetPopover = new ol.Overlay({
      element: document.getElementById('sigmet-popover')
    });
    map.addOverlay(sigmetPopover);

    var weatherPopover = new ol.Overlay({
      element: document.getElementById('weather-popover')
    });
    map.addOverlay(weatherPopover);

    mc.selectedFeatureOverlay = new ol.layer.Vector({
      map: map,
      source: new ol.source.Vector({
        features: new ol.Collection(),
        useSpatialIndex: false // optional, might improve performance
      }),
      style: styleProvider.selectedPlaneStyle.bind(styleProvider),
      updateWhileAnimating: true, // optional, for instant visual feedback
      updateWhileInteracting: true // optional, for instant visual feedback
    });

    function displayPlaneTooltip(plane, coordinate, resolution) {
      var element = planePopover.getElement();
      $(element).attr('data-animation', 'false');
      var labels = mapOptions.labels;
      var labelFont = mapOptions.labels.font;
      var cssFontClass = "map-tooltip-" + labelFont.toLowerCase();
      if (plane) {
        performance.mark('hover-over-others-label:start');
        var isInMyfleet = _.map(userProfileNgStore.userProfile.assignedAircrafts, 'registration').indexOf(plane.get('acn')) > -1;
        var state = !!plane.get('closed') ? 5 : !!plane.get('customerAircraft') ? !!plane.get('alertLvl') ? plane.get('alertLvl') : isInMyfleet ? 3 : 0 : 4;
        if ((state < 1 && resolution > 9800) || !plane.get('customerAircraft') || (!labels.displayFlightInfo)) {
          planePopover.setPosition(coordinate);
          var textLines = aircraftTooltipProvider.getTooltipText(plane);
          $(element).attr('data-original-title', textLines.join('<br>') || '--')
            .tooltip({
              animation: false,
              html: true,
              container: '.map-area',
              viewport: {
                selector: 'body',
                padding: 0,
              },
              template: '<div class="' + cssFontClass + '" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
            }).tooltip('show');
        }

        performance.mark('hover-over-others-label:end');
        performance.measure("hover-over-others-label", "hover-over-others-label:start", "hover-over-others-label:end");
      } else {
        $(element).tooltip('hide');
      }
    }

    function JsonToTable(jsonObject) {
      let txt = "";
      txt += "<table>"
      let keys = Object.keys(jsonObject);

      keys.forEach(function (key) {

        let propertyText = "";

        if (jsonObject[key].length > 750) {
          propertyText = jsonObject[key].substring(0, 750) + "...";
        } else {
          propertyText = jsonObject[key];
        }

        txt += "<tr><td>" + key + "&nbsp;&nbsp;&nbsp;&nbsp;</td><td>" + propertyText + "</td></tr>";

      })

      txt += "</table>";

      return txt;
    }

    function tableToJson(table) {
      var data = [];

      // get the caption
      var captions = table.getElementsByTagName("caption");

      // first row needs to be headers
      var headers = [];
      for (var i = 0; i < table.rows[0].cells.length; i++) {
        headers[i] = table.rows[0].cells[i].innerHTML.toLowerCase().replace(/ /gi, '');
      }

      // go through cells
      for (var i = 1; i < table.rows.length; i++) {

        var tableRow = table.rows[i];
        var rowData = {};

        for (var j = 0; j < tableRow.cells.length; j++) {

          rowData[headers[j]] = tableRow.cells[j].innerHTML;

        }

        let jsonObject = Object.assign({ title: captions[0].innerHTML }, rowData);
        data.push(jsonObject);
      }

      return data;
    }

    function displayWeatherTooltip(coordinate, pixel) {

      var windowWidth = window.innerWidth;
      var windowHeight = window.innerHeight;
      var pointerX = pixel[0];
      var pointerY = pixel[1];

      if ($scope.isPointerOverMap) {
        var requests = {};

        map.getLayers().forEach(function (layer, i) {

          if (layer instanceof ol.layer.Group && layer.get('title') === 'SE layers') {

            var layers = layer.getLayers();
            if (layers.getArray().length > 0) {

              requests['Weather'] = getLayersInfosFromSchneider(coordinate, true).then(function (c) {
                return {
                  content: c
                };
              });
            }
          }
        });

        var tabData = requests['Weather'];

        if (tabData == null) {
          return;
        }

        tabData.then(function (result) {

          let popupContent = "";
          let center = map.getView().getCenter();
          let pointerIsRight = pointerX > (windowWidth / 2);
          let pointerIsBellow = pointerY > (windowHeight / 2);
          var element = weatherPopover.getElement();

          result.content.forEach(function (table, iteration, array) {
            popupContent += JsonToTable(table[0]);
            if (iteration !== array.length - 1) {
              popupContent += "<hr>";
            }
          });

          // Clear Style
          element.classList.remove("weather-popover-right");
          element.classList.remove("weather-popover-bottom");
          element.classList.remove("weather-popover-top");
          element.style.top = "";
          element.style.minWidth = "";

          if (pointerIsRight) {
            element.classList.add("weather-popover-right");
          }

          if (!pointerIsBellow) {
            element.classList.add("weather-popover-top");
          }

          weatherPopover.setPosition(center);
          element.innerHTML = popupContent;
          $(element).show();

          if (pointerIsBellow) {
            element.style.top = "-" + element.offsetHeight.toString() + "px";
            element.style.visibility = "";
          }

          if (pointerIsRight) {
            element.style.minWidth = element.offsetWidth.toString() + "px";
          }

          weatherPopover.setPosition(coordinate);

        }).catch(function (error) {
          // do nothing.
        });
      }
    };

    $scope.navLayerAdd = function (layer) {
      var olLayer = navLayersService.createLayer(layer, mapOptions.navLayers.selectedLevel);
      navLayersGroup.getLayers().push(olLayer);
      if (olLayer.getProperties().key == 'airports') {
        var subscribed = navLayersService.createAirportLayerForSubscribed(customer.airports);
        navLayersService.updateAirportLayer(subscribed.getSource(), customer.airports);
        navLayersGroup.getLayers().push(subscribed);
      }
      if (olLayer.getProperties().key == 'favouriteAirports') {
        navLayersService.updateAirportLayer(olLayer.getSource(), $scope.user.preferences.favoriteAirports);
      }
    };

    $scope.navLayerRemove = function (layer) {
      _.forEach(_.clone(navLayersGroup.getLayers().getArray()), function (item) {
        if (item.getProperties().key === layer.key) {
          navLayersGroup.getLayers().remove(item);
        }
      });
    };

    // function called by nav layer component when level was changed
    $scope.navLayerLevelChange = function (userLevel) {
      // to save user level in database
      mapOptions.navLayers.selectedLevel = userLevel;
      // update layer level to change displayed layers
      navLayersService.updateLayersLevel(navLayersGroup, userLevel);
    };

    $scope.onInfoPopupOpen = function () {
      // hide search btn
      $scope.enableSearch = false;
    };

    $scope.onSearchOpen = function () {
      isSearchActive = true;
      navSelectionGroup.setVisible(true);
    };

    $scope.onSearchClose = function () {
      isSearchActive = false;
      navSelectionGroup.setVisible(false);
      selectionHighlightLayer.setVisible(false);
    };

    $scope.updateNavSelectedFeature = function (feature, isSearch) {
      if (feature == null) {
        return;
      }
      var featureId = _.get(feature, 'feature.properties.feature_id');
      if (featureId) {
        var geoserverLayers = [];
        if (!isSearch) {
          geoserverLayers = _.chain(navLayersGroup.getLayers().getArray())
            .filter(function (olLayer) {
              var definition = olLayer.getSource().get('definition');
              return definition !== undefined && !definition.notQueryable && definition.maxResolution > map.getView().getResolution();
            })
            .map(function (l) {
              return l.getSource().getParams().layers;
            })
            .uniq()
            .value();
        } else {
          var allLayers = _.assign({}, navLayers.staticLayers, navLayers.highLowLayers);
          var navLayer = _.first(_.filter(allLayers, (function (current) {
            return current.shortLabel === feature.type;
          })));
          if (_.has(navLayers.highLowLayers, navLayer.key)) {
            geoserverLayers.push(navLayer.params.layers + '_high');
            geoserverLayers.push(navLayer.params.layers + '_low');
          } else {
            geoserverLayers.push(navLayer.params.layers);
          }
        }
        var cqlfilter = "feature_id = '" + featureId + "'";
        var filter = _.fill(new Array(geoserverLayers.length), cqlfilter).join(';');
        selectionHighlightLayer.getSource().updateParams({
          layers: geoserverLayers,
          //featureId: feature.id, would have been better than cqlfilter but impossible because of views in layers
          CQL_FILTER: filter
        });
        selectionHighlightLayer.setVisible(true);
      } else {
        selectionHighlightLayer.setVisible(false);
      }
    }

    $scope.onInfoPopupClose = function () {
      navSelectionGroup.setVisible(false);
      // display search btn
      $scope.enableSearch = true;
    };

    function requestAndDisplayFeatureInfo(coordinates) {
      if (coordinates) {
        // because we allow panning beyond the extent of the map,
        // we need to query coordinates inside the extent to get feature info
        var clampCoords = Map.clampCoordinate(viewExtent, coordinates);
        var requests = {};
        var resolution = map.getView().getResolution();
        var projection = map.getView().getProjection();
        var navLayers = _.filter(navLayersGroup.getLayers().getArray(), function (olLayer) {
          var definition = olLayer.getSource().get('definition');
          return definition !== undefined && !definition.notQueryable && definition.maxResolution > resolution;
        })
        if (navLayers.length > 0) {
          requests['NAV'] = navLayersService.requestFeaturesInfo(
            clampCoords,
            resolution,
            projection,
            navLayers);
        }

        if (getSeCollectionLayers().getLength()) {
          requests['Weather'] = getLayersInfosFromSchneider(clampCoords, false).then(function (c) {
            return {
              content: c
            };
          });
        }
        selectionMarker.getGeometry().setCoordinates(clampCoords);
        navSelectionGroup.setVisible(true);
        selectionMarkerLayer.setVisible(true);
        displayFeatureInfo(requests);
      } else {
        displayFeatureInfo(null);
        navSelectionGroup.setVisible(false);
        selectionMarkerLayer.setVisible(false);
      }
    }

    function displayFeatureInfo(data) {
      if (data) {
        $scope.enableSearch = false;
        $scope.$broadcast('popup:info', data);
      } else {
        $scope.$broadcast('popup:info', null);
      }
    }

    mc.findLabelPlaneFeature = function (pixel) {
      let closestLabelFeature = null;
      map.forEachFeatureAtPixel(pixel, function (feature) {
        if (feature.get('name') === generalConstants.planeFeatureName) {
          closestLabelFeature = feature;
        }
      });
      return closestLabelFeature;
    };

    mc.findClosestPlane = function (coordinate, resolution) {
      var distance = 15 * resolution,
        coord = Map.clampCoordinate(viewExtent, coordinate),
        x = coord[0],
        y = coord[1],
        closestFeature = null,
        previousFeatureDistance = Infinity,
        extent = [x - distance, y - distance, x + distance, y + distance];

      _.forEachRight(planesData, function (data) {
        var source = data.source;
        if (data.layer.getVisible()) {
          source.forEachFeatureInExtent(extent, function (feature) {
            var geometry = feature.getGeometry(),
              ext = geometry.getExtent(),
              minFeatureDistance;
            if (ext[0] === ext[2] && ext[1] === ext[3]) {

              minFeatureDistance = Math.abs(x - ext[0]) + Math.abs(y - ext[1]);
              if (minFeatureDistance < previousFeatureDistance) {
                previousFeatureDistance = minFeatureDistance;
                closestFeature = feature;
                return closestFeature; //break forEachFeatureInExtent loop
              }
            }
          });
          if (closestFeature) {
            return false; //break forEach loop
          }
        }
      });
      return closestFeature;

    };

    // Find label overlaps and move it to the better position so will have less overlap at the end
    mc.findIntersectedLabels = function (feature, planeLabelFeatures) {

      var results = [];
      var i;
      for (i = 0; i < 4; i++) {
        var totalIntersects = mc.findIntersectedLabelsForEachFeature(feature, planeLabelFeatures);
        var length = totalIntersects.length;
        if (length == 0) {
          i = 3;
        } else if (length > 1) {
          let planeId = feature.get('id');
          let aircraftLabelPositions = angular.fromJson(sessionStorage.getItem(generalConstants.aircraftLabelPositionKey)) || {};
          results.push([aircraftLabelPositions[planeId], length, totalIntersects[length - 1]]);
          mc.moveAircraftLabel(feature);
        }
      }
      if (results.length == 4) {
        var minLabelOverlapCount = 1000;
        var newPosition;
        results.forEach(entry => {
          if (entry[1] < minLabelOverlapCount) {
            minLabelOverlapCount = entry[1];
            newPosition = entry[0];
          } else if (entry[1] == minLabelOverlapCount && !entry[2]) {
            minLabelOverlapCount = entry[1];
            newPosition = entry[0];
          }
        })
        let aircraftLabelPositions = angular.fromJson(sessionStorage.getItem(generalConstants.aircraftLabelPositionKey)) || {};
        let planeId = feature.get('id');
        aircraftLabelPositions[planeId] = newPosition;
        let json = angular.toJson(aircraftLabelPositions);
        sessionStorage.setItem(generalConstants.aircraftLabelPositionKey, json);
        feature.changed();
      } else {
        feature.changed();
      }
    }

    // Find if there is any label overlap for a feature with it's closest features.
    mc.findIntersectedLabelsForEachFeature = function (featureToCompareWith, planeLabelFeatures) {

      let extents = [];
      var results = [];
      var hasCompleteOverlap = false;
      var geometryToCompareWith = featureToCompareWith.getGeometry();
      var coordinateToCompareWith = geometryToCompareWith.getCoordinates();
      var pixel1 = map.getPixelFromCoordinate(coordinateToCompareWith);
      let planeId = featureToCompareWith.get('id');
      let aircraftLabelPositions = angular.fromJson(sessionStorage.getItem(generalConstants.aircraftLabelPositionKey)) || {};
      let pixelFrom1 = [];
      let pixelFrom2 = [];
      var textLines1 = aircraftTooltipProvider.getTooltipText(featureToCompareWith);
      if (aircraftLabelPositions[planeId] == 1 || aircraftLabelPositions[planeId] == null) {
        pixelFrom1 = [pixel1[0] - 20,
        pixel1[1] - 15];
        pixelFrom2 = [pixel1[0] - 25 + 90,
        pixel1[1] - 15 - (textLines1.length - 1) * 12 - 24];
      } else if (aircraftLabelPositions[planeId] == 3) {
        pixelFrom1 = [pixel1[0] - 20,
        pixel1[1] + 20 + (textLines1.length - 1) * 12 + 24];
        pixelFrom2 = [pixel1[0] - 25 + 90,
        pixel1[1] + 15];
      } else if (aircraftLabelPositions[planeId] == 2) {
        pixelFrom1 = [pixel1[0] + 20,
        pixel1[1] + (((textLines1.length - 1) * 12 + 24) / 2)];
        pixelFrom2 = [pixel1[0] + 100,
        pixel1[1] + (((textLines1.length - 1) * 12 + 24) / 2) - (textLines1.length - 1) * 12 - 24];
      } else if (aircraftLabelPositions[planeId] == 4) {
        pixelFrom1 = [pixel1[0] - 100,
        pixel1[1] + (((textLines1.length - 1) * 12 + 24) / 2)];
        pixelFrom2 = [pixel1[0] - 20,
        pixel1[1] + (((textLines1.length - 1) * 12 + 24) / 2) - (textLines1.length - 1) * 12 - 24];

      }
      let coordinateFrom1 = map.getCoordinateFromPixel(pixelFrom1);
      let coordinateFrom2 = map.getCoordinateFromPixel(pixelFrom2);
      let extentFrom = [coordinateFrom1[0],
      coordinateFrom1[1],
      coordinateFrom2[0],
      coordinateFrom2[1]];
      extents.push(extentFrom);
      planeLabelFeatures.forEach(feature => {
        if (feature !== featureToCompareWith) {
          var geometry2 = feature.getGeometry();
          var coordinate2 = geometry2.getCoordinates();
          var pixel2 = map.getPixelFromCoordinate(coordinate2);
          var textLines2 = aircraftTooltipProvider.getTooltipText(feature);


          let pixelTo1 = [];
          let pixelTo2 = [];
          let planeIdForFeature = feature.get('id');
          if (aircraftLabelPositions[planeIdForFeature] == 1 || aircraftLabelPositions[planeIdForFeature] == null) {
            pixelTo1 = [pixel2[0] - 20,
            pixel2[1] - 15];
            pixelTo2 = [pixel2[0] - 25 + 90,
            pixel2[1] - 15 - (textLines2.length - 1) * 12 - 24];
          } else if (aircraftLabelPositions[planeIdForFeature] == 3) {
            pixelTo1 = [pixel2[0] - 20,
            pixel2[1] + 20 + (textLines2.length - 1) * 12 + 24];
            pixelTo2 = [pixel2[0] - 25 + 90,
            pixel2[1] + 15];

          } else if (aircraftLabelPositions[planeIdForFeature] == 2) {
            pixelTo1 = [pixel2[0] + 20,
            pixel2[1] + (((textLines2.length - 1) * 12 + 24) / 2)];
            pixelTo2 = [pixel2[0] + 100,
            pixel2[1] + (((textLines2.length - 1) * 12 + 24) / 2) - (textLines2.length - 1) * 12 - 24];

          } else if (aircraftLabelPositions[planeIdForFeature] == 4) {
            pixelTo1 = [pixel2[0] - 95,
            pixel2[1] + (((textLines2.length - 1) * 12 + 24) / 2)];
            pixelTo2 = [pixel2[0] - 100,
            pixel2[1] + (((textLines2.length - 1) * 12 + 24) / 2) - (textLines2.length - 1) * 12 - 24];
          }
          let coordinateTo1 = map.getCoordinateFromPixel(pixelTo1);
          let coordinateTo2 = map.getCoordinateFromPixel(pixelTo2);
          let extentTo = [coordinateTo1[0],
          coordinateTo1[1],
          coordinateTo2[0],
          coordinateTo2[1]];
          extents.push(extentTo);

          if (extents.length > 1) {
            let extentCompareTo = extentFrom;
            let extentCompareWith = extentTo;
            let coorindate = ol.extent.getCenter(extentCompareTo);

            let intersect = ol.extent.containsXY(extentCompareWith, coorindate[0], coorindate[1]);
            if (intersect) {
              hasCompleteOverlap = true;
            }

            intersect !== true ? coorindate = ol.extent.getCenter(extentCompareWith) : '';
            intersect = ol.extent.containsCoordinate(extentCompareTo, coorindate);
            if (intersect) {
              hasCompleteOverlap = true;
            }


            intersect !== true ? coorindate = ol.extent.getTopLeft(extentCompareTo) : '';
            intersect = ol.extent.containsXY(extentCompareWith, coorindate[0], coorindate[1]);

            intersect !== true ? coorindate = ol.extent.getTopRight(extentCompareTo) : '';
            intersect = ol.extent.containsXY(extentCompareWith, coorindate[0], coorindate[1]);

            intersect !== true ? coorindate = ol.extent.getBottomLeft(extentCompareTo) : '';
            intersect = ol.extent.containsXY(extentCompareWith, coorindate[0], coorindate[1]);

            intersect !== true ? coorindate = ol.extent.getBottomRight(extentCompareTo) : '';
            intersect = ol.extent.containsXY(extentCompareWith, coorindate[0], coorindate[1]);

            intersect !== true ? coorindate = ol.extent.getCenter(extentCompareWith) : '';
            intersect = ol.extent.intersects(extentCompareTo, extentCompareWith);

            intersect !== true ? coorindate = ol.extent.getTopLeft(extentCompareWith) : '';
            intersect = ol.extent.containsXY(extentCompareTo, coorindate[0], coorindate[1]);

            intersect !== true ? coorindate = ol.extent.getTopRight(extentCompareWith) : '';
            intersect = ol.extent.containsXY(extentCompareTo, coorindate[0], coorindate[1]);

            intersect !== true ? coorindate = ol.extent.getBottomLeft(extentCompareWith) : '';
            intersect = ol.extent.containsXY(extentCompareTo, coorindate[0], coorindate[1]);

            intersect !== true ? coorindate = ol.extent.getBottomRight(extentCompareWith) : '';
            intersect = ol.extent.containsXY(extentCompareTo, coorindate[0], coorindate[1]);


            if (intersect) {
              results.push(feature);
            }

          }
        }
      })
      results.push(hasCompleteOverlap);
      return results;
    };

    // Find the closest features around each feature with which there can be possible label overlap
    mc.findClosestPlaneForLableDecluttering = function (coordinate, resolution) {
      let closestLabelFeature = [];
      var distance = 180 * resolution,
        coord = Map.clampCoordinate(viewExtent, coordinate),
        x = coord[0],
        y = coord[1],
        extentForClosestLabels = [x - distance, y - distance, x + distance, y + distance];
      _.forEachRight(planesData, function (data) {
        var source = data.source;
        if (data.layer.getVisible()) {
          source.forEachFeatureInExtent(extentForClosestLabels, function (closestFeature) {
            var geometryForFeature = closestFeature.getGeometry(),
              extForFeature = geometryForFeature.getExtent();
            if (extForFeature[0] === extForFeature[2] && extForFeature[1] === extForFeature[3]) {
              closestLabelFeature.push(closestFeature);
            }
          });
        }
      });
      return closestLabelFeature;
    };

    mc.findClosestRouteIfPresent = function (pixel) {
      var closestFeature = null;
      map.forEachFeatureAtPixel(pixel, function (feature, layer) {
        if (layer.get('name') === "RouteAnalysis" &&
          layer.get('type') === "RouteAnalysisLinesLayer") {
          closestFeature = feature;
        }
      });
      return closestFeature;
    };

    /**
     * Get Center on coordinate and view position.
     * @param {ol.Coordinate} coordinate Coordinate of the feature to center.
     * @param {ol.Pixel} position Position on the view to center on.
     */
    mc.findOffsettedCenter = function (coordinate, position, resolution) {
      // calculate rotated position
      var rotation = map.getView().getRotation();
      var size = map.getSize();
      var cosAngle = Math.cos(-rotation);
      var sinAngle = Math.sin(-rotation);
      var rotX = coordinate[0] * cosAngle - coordinate[1] * sinAngle;
      var rotY = coordinate[1] * cosAngle + coordinate[0] * sinAngle;
      rotX += (size[0] / 2 - position[0]) * resolution;
      rotY += (position[1] - size[1] / 2) * resolution;

      // go back to original angle
      sinAngle = -sinAngle; // go back to original rotation
      var centerX = rotX * cosAngle - rotY * sinAngle;
      var centerY = rotY * cosAngle + rotX * sinAngle;
      return [centerX, centerY];
    }



    map.on('click', function (evt) {
      mc.eventInfos(evt, false);
    });

    mc.moveAircraftLabel = function (planeFeature) {
      let planeId = planeFeature.get('id');
      let aircraftLabelPositions = angular.fromJson(sessionStorage.getItem(generalConstants.aircraftLabelPositionKey)) || {};

      if (aircraftLabelPositions[planeId] === 4) {
        aircraftLabelPositions[planeId] = 1;//reset it back to position 1
      }
      else if (aircraftLabelPositions[planeId]) {
        aircraftLabelPositions[planeId] = aircraftLabelPositions[planeId] + 1;
      }
      else {
        aircraftLabelPositions[planeId] = 2;//move to second position on first click
      }

      let json = angular.toJson(aircraftLabelPositions);
      sessionStorage.setItem(generalConstants.aircraftLabelPositionKey, json);
      planeFeature.changed();
    };

    $scope.displayWeatherTip = _.debounce(displayWeatherTooltip, 500, {
      leading: false,
      trailing: true
    });


    if (!window.browsermobile) {

      $('#map').hover(
        function () {
          $scope.isPointerOverMap = true;
        }, function () {
          $scope.isPointerOverMap = false;
        }
      );

      /*
        Priority order on layers
          - first plane
          - then pirep
          - then airmet
          - then pirep
          - then weather
      */
      map.on('pointermove', _.throttle(function (evt) {

        var element = weatherPopover.getElement();
        $(element).hide();

        var resolution = map.getView().getResolution(),
          plane = mc.findClosestPlane(evt.coordinate, resolution),
          pirep,
          airmet,
          sigmet;
        displayPlaneTooltip(plane, evt.coordinate, resolution); // hide plane if none found
        if (mapOptions.displayMajorCities.displayMajorCities && mapOptions.displayMajorCities.maxResolution > resolution) {
          majorcitiesLayer.setVisible(true);
        } else {
          majorcitiesLayer.setVisible(false);
        }
        if (plane === null) {
          $scope.displayWeatherTip(evt.coordinate, evt.pixel);
        }

        if ($scope.selectedFlight && !$scope.selectedFlight.isPlanned) {
          let mouseCoord = evt.coordinate;
          let planeCoord = $scope.selectedFlight.olFeature.getGeometry().getCoordinates();
          let distance = GeometryUtils.distanceBetweenPoints(mouseCoord, planeCoord);
          let bearing = GeometryUtils.bearingFromPointToPoint(planeCoord, mouseCoord);
          let roundedBearing = Math.round(bearing);
          let distanceElement = document.getElementById(DISTANCE_ELEMENT);
          let scaleUnits = parameters.mapScaleLineUnits || 'nautical';

          if (scaleUnits === 'nautical') {
            distanceElement.innerHTML = GeometryUtils.metersToNauticalMiles(distance) + " NM";
          } else {
            distanceElement.innerHTML = GeometryUtils.metersToKilometers(distance) + " KM";
          }

          document.getElementById(BEARING_ELEMENT).innerHTML = roundedBearing + " &#176;"
        }
      }, 150));
    }

    mc.resetBearingDistance = function () {

      let distanceElement = document.getElementById(DISTANCE_ELEMENT);
      let bearingElement = document.getElementById(BEARING_ELEMENT);

      if (distanceElement) {
        distanceElement.innerHTML = '-';
      }

      if (bearingElement) {
        bearingElement.innerHTML = '-';
      }
    };

    mc.centerMapOnPlane = function (plane) {
      var view = map.getView(),
        point = /** @type {ol.geom.Point} */ (plane.getGeometry()),
        mapCenter = /** @type {ol.Size} */ (map.getSize().slice()),
        duration = 300,
        padding = 0;

      mapCenter[0] = (mapCenter[0] - padding) / 2 + padding;
      mapCenter[1] = mapCenter[1] / 2;
      /*change view.animate() to view.setCenter(), so plane icon snaps rather than pans*/
      view.setCenter(mc.findOffsettedCenter(point.getCoordinates(), mapCenter, map.getView().getResolution()));
    };

    mc.centerMapOnPlaneLocked = function () {
      if ($scope.mapLocked.state && !mc.selectedFeature.moving) {
        mc.selectedFeature.moving = true;
        $timeout(function () {
          delete mc.selectedFeature.moving;
          mc.centerMapOnPlane(mc.selectedFeature);
        }, 0);
      }
    };

    /**
     * @description shows or hide the wanted popup
     * @param {event} evt event
     * @param {boolean} isTriggeredHover if the user chooses to activate the popup with a hover
     * @returns {void} return void
     */
    mc.eventInfos = function (evt, isTriggeredHover) {
      let labelPlaneFeature = mc.findLabelPlaneFeature(evt.pixel);
      let resolution = map.getView().getResolution();
      let plane = mc.findClosestPlane(evt.coordinate, resolution);

      //since label and plane is of the same feature, we have to make sure that the click is on just the label and not the plane
      if (!plane && labelPlaneFeature) {
        mc.moveAircraftLabel(labelPlaneFeature);
        labelPlaneFeature.changed();
      }
      else {
        var hasClassOpen = $(".mapToolboxPanel").hasClass("open");
        var route = mc.findClosestRouteIfPresent(evt.pixel),
          isMenuPlaneOpened = $('#flight-details').find('.details-container').length > 0;
        var isClassNotWeather = false;

        $(".not-weather").each(function (elmt) {
          if ($(this).attr('aria-expanded') == 'true') {
            isClassNotWeather = true;
          }
        });
        clearInterval(refreshTrailInterval);

        $scope.selectPlane(!isTriggeredHover && plane && (plane.get('flightId') || plane.get('addrModeS')), plane && plane.get('id'), true);
        if (!isSearchActive) {
          if (plane === null && route === null && !isMenuPlaneOpened && !isClassNotWeather) {
            //requestAndDisplayFeatureInfo(evt.coordinate);
            if (!hasClassOpen) {
              requestAndDisplayFeatureInfo(evt.coordinate);
            } else {
              if (!isTriggeredHover) {
                clearTimeout(contentTimeoutId);
              }
              requestAndDisplayFeatureInfo(null);
            }
          } else {
            requestAndDisplayFeatureInfo(null);
          }
        }
      }
    };
    // Loop through each section of aircrafts and do label decluttering for all the features.
    mc.declutterLabels = function (resolution) {
      var j;
      var resolutionFlag = resolution < 9800;
      if (mapOptions.labels.autoArrangeLabels && resolutionFlag) {
        for (j = 0; j < 2; j++) {
          _.forEachRight(planesData, function (data) {
            var source = data.source;
            if (data.layer.getVisible() && data.layer.get('title') !== 'Planes other') {
              var i;
              for (i = 0; i < 4; i++) {
                source.forEachFeature(feature => {
                  var geometry = feature.getGeometry();
                  var coordinate = geometry.getCoordinates();
                  let planeLabelFeatures = mc.findClosestPlaneForLableDecluttering(coordinate, map.getView().getResolution());
                  if (planeLabelFeatures.length > 1) {
                    mc.findIntersectedLabels(feature, planeLabelFeatures);
                  }

                });
              }
            }
          });
        }
      }
    };
    mc.selectPlane = function (flightId, clickOnMap, destAirport, oriAirport) {

      if ($scope.selectedFlight) {
        $scope.selectedFlight.completeRouteString = "N/A";
        $scope.selectedFlight.routeStringBlock = "N/A";
        $scope.selectedFlight.hideCompleteRouteString = true;
      }

      var i, feats;
      trailVector.clear();
      mc.removeFlightPlansByFlightIdOrUntoggledState();
      mc.selectedFeatureOverlay.getSource().clear(true);
      opacityBgLayer.setVisible(true);
      opacitySelectionLayer.setVisible(false);
      if (mc.selectedFeature) {
        mc.selectedFeature.un('change', mc.centerMapOnPlaneLocked);
      }
      clearInterval(refreshTrailInterval);
      if (flightId) {
        $scope.selectedFlight.flightPlanToggleDisabled = true;

        if ($scope.selectedFlight && $scope.selectedFlight.isPlanned) {
          flightNgStore.getAircraftPlannedFlightPlans($scope.selectedFlight)
            .then(flightPlans => {
              displayFlightPlan(flightPlans, waypointVector, flightPlanVector, null, $scope.selectedFlight.id);
              mc.displayRouteString(flightPlans);
              mc.centerMapOnPlane(waypointVector.getFeatures()[0]);
            });
        }
        else {
          mc.selectedFeature = findFlightFeature(flightId);
          if (mc.selectedFeature) {
            $scope.selectedFlight.flightPlanToggleState = mc.flightPlanToggledList.includes(flightId);
            mc.selectedFeatureOverlay.getSource().addFeature(mc.selectedFeature);
            mc.centerMapOnPlane(mc.selectedFeature);
            mc.displayPositionTrail();
            flightPlanLineString = null;
            mc.displayFlightPlan(mc.selectedFeature);
            opacityBgLayer.setVisible(false);
            opacitySelectionLayer.setVisible(true);
            mc.selectedFeature.on('change', mc.centerMapOnPlaneLocked);
            refreshTrailInterval = setInterval(mc.displayPositionTrail, 20000);
          }
        }
      }
      mc.resetBearingDistance();
      //return true if found.
      return ($scope.selectedFlight && $scope.selectedFlight.isPlanned) || !!mc.selectedFeature;
    };

    //Display position trail for selected aircraft
    mc.displayPositionTrail = function () {
      var feature = mc.selectedFeature;
      flightNgStore.getAircraftPositionTrail(feature.getId()).then(function (data) {
        displayPositionTrail(data || [], trailVector, feature);
      });
    };

    //Display FlightPlan for flight plan toggled flight
    mc.updateFlightPlansForFlight = function (flightId) {
      let feature;
      feature = findFlightFeature(flightId);
      if (feature) {
        flightNgStore.getAircraftFlightPlan(flightId).then(function (data) {
          if (_.isEmpty(data)) {
            //remove flights from flightPlanToggledList that don't have flight plans
            let index = mc.flightPlanToggledList.indexOf(flightId);
            if (index > -1) {
              mc.flightPlanToggledList.splice(index, 1);
            }
            mc.removeFlightPlansByFlightIdOrUntoggledState(flightId);
          } else {
            displayFlightPlan(data, waypointVector, flightPlanVector, feature, null);
          }
        });
      }
    };

    // Display Waypoints for selected aircraft
    mc.displayFlightPlan = function (feature) {
      flightNgStore.getAircraftFlightPlan(feature.getId()).then(function (data) {
        if (!!mc.selectedFeature && mc.selectedFlightId !== null) {
          if (_.isEmpty(data)) {
            mc.removeFlightPlansByFlightIdOrUntoggledState();
            displayGreatCircle($scope.selectedFlight, flightPlanVector, waypointVector);
          } else {
            $scope.selectedFlight.flightPlanToggleDisabled = false;
            displayFlightPlan(data, waypointVector, flightPlanVector, feature, null);
            mc.displayRouteString(data);
          }
        }
      });
    };

    // Display route string for selected aircraft
    mc.displayRouteString = function (flightPlanData) {
      let fpRouteString = "N/A";
      flightPlanData.forEach(fpd => {
        if (fpd.routeString && (fpd.source === SOURCE_FLIGHT_AWARE || fpRouteString === "N/A")) {
          fpRouteString = fpd.routeString;
        }
      });

      $scope.selectedFlight.completeRouteString = fpRouteString;

      let maxBlockSize = 50;
      $scope.selectedFlight.routeStringBlock = "";
      let routeStringLength = $scope.selectedFlight.completeRouteString.length;

      //if the completeRouteString length > maxBlockSize, we will display only the first twenty and the last twenty chars in the block with the separator and display the complete route string on hover
      if ($scope.selectedFlight.completeRouteString !== "N/A" && routeStringLength > maxBlockSize) {
        let routeStringSeparator = " .... ";
        $scope.selectedFlight.hideCompleteRouteString = false;
        let routeStringBlockBeginning = $scope.selectedFlight.completeRouteString.substring(0, 20);
        let routeStringBlockEnding = $scope.selectedFlight.completeRouteString.substring(routeStringLength - 20, routeStringLength);
        $scope.selectedFlight.routeStringBlock = routeStringBlockBeginning + routeStringSeparator + routeStringBlockEnding;
      } else {
        $scope.selectedFlight.routeStringBlock = $scope.selectedFlight.completeRouteString;
      }
    };

    mc.updateSelectedPlane = function () {
      var selectedFlight = $scope.selectedFlight,
        selectedFlightId = selectedFlight && selectedFlight.id,
        destinationAirport, originAirport;
      planesData.closed.source.clear();
      if (selectedFlight && selectedFlight.isClosed) {
        planesData.closed.source.addFeature(selectedFlight.olFeature);
      }
      if (this.selectedFlightId !== selectedFlightId) {
        document.getElementsByClassName('map-toolbox')[0].style.bottom = "7em";
        document.getElementsByClassName('map-zoom')[0].style.bottom = "1em";
        if (!!selectedFlight) {
          destinationAirport = selectedFlight.destinationAirport;
          originAirport = selectedFlight.originAirport;
        }
        if (mc.selectPlane(selectedFlightId, $scope.fromClickOnMap, destinationAirport, originAirport)) {
          this.selectedFlightId = selectedFlightId;
        } else {
          this.selectedFlightId = null;
        }
        $timeout(map.updateSize.bind(map), 0);
      }
    };

    var zoomByDelta = function (delta) {

      var view = map.getView();
      var currentResolution = view.getResolution();
      var newResolution = view.constrainResolution(currentResolution, delta);
      view.animate({
        resolution: newResolution,
        duration: 250,
        easing: ol.easing.easeOut
      });
      if (mapOptions.displayMajorCities.displayMajorCities && newResolution < mapOptions.displayMajorCities.maxResolution) {
        majorcitiesLayer.setVisible(true);
      } else {
        majorcitiesLayer.setVisible(false);
      }
      mc.declutterLabels(newResolution);
    };



    $scope.zoomIn = function () {
      zoomByDelta(1);
    };

    $scope.zoomOut = function () {
      zoomByDelta(-1);
    };

    //listen for map resize
    $('#main-content').on('webkitAnimationEnd oanimationend msAnimationEnd animationend', function () {
      $timeout(map.updateSize.bind(map), 0);
    });

    //
    //Register for change listeners
    //

    //Listen for URL changes for plane selection
    $scope.$watch('selectedFlight', function () {
      mc.updateSelectedPlane();
    });

    $scope.$watch('shownSections', function (newValue, oldValue) {
      _.forEach(newValue, function (visible, section) {
        //performance : set Empty source instead ?
        let sectionDetails = flightSectionDefinitions.filter((sectionDetail) => {
          return sectionDetail.name === section;
        })[0];

        let visibleOnMap = sectionDetails.visibleOnMap !== undefined ? sectionDetails.visibleOnMap : visible;
        planesData[section].layer.setVisible(visibleOnMap);
      });
    }, true);

    $scope.$watch('user.preferences.favoriteAirports', function (newValue) {
      _.each(navLayersGroup.getLayers().getArray(), function (layer) {
        if (layer.getProperties().key == 'favouriteAirports') {
          navLayersService.updateLayer(layer.getSource(), $scope.user.preferences.favoriteAirports);
        }
      });
    });

    $rootScope.$on("UpdateMapLayers", function (event, data) {
      if (data) {
        $scope.mapOptions.weather = data.weather || $scope.mapOptions.weather;
        $scope.mapOptions.se = data.se || $scope.mapOptions.se;
        $scope.mapOptions.fir = data.fir || $scope.mapOptions.fir;
        $scope.mapOptions.uir = data.uir || $scope.mapOptions.uir;
        $scope.mapOptions.navLayers = data.navLayers || $scope.mapOptions.navLayers;
      }
      $scope.updateSeLayer();
      syncAnimatedWeatherRadarButtonsState();
    });

    $scope.updateSeLayer = _.debounce(immediateUpdateSEWeatherLayers, 600, {
      leading: true,
      maxWait: 2000
    });

    $scope.hideLayers = function () {
      var layers = Array.prototype.slice.call(arguments);
      var checked = layers[0]; // first argument is state checkbox
      layers.shift(); // others arguments are layers
      // if layers are hidden
      if (!checked) {
        // we hide layers that are selected
        var layersSelectedToHide = _.intersection(layers, $scope.mapOptions.se.selectedSeLayers);
        _.each(layersSelectedToHide, function (l) {
          $scope.mapOptions.se.hiddenLayers.push(l)
        });
        clearInterval(animateWeatherInterval);
      } else {
        _.each(layers, function (l) {
          _.remove($scope.mapOptions.se.hiddenLayers, function (hiddenLayers) {
            if (hiddenLayers === l) {
              //hide back any layers to hide
              $scope.hideLayerToHideFromLayer(hiddenLayers);
              return true;
            }
          });
        });
      }
      //console.log('layers to hide: ', $scope.mapOptions.se.hiddenLayers);
      $scope.updateSeLayer();
    };

    $scope.playAnimatedLayer = function (animatedLayer) {

      if (!$scope.isWeatherRadarAnimatedPlaying[animatedLayer]) {
        $scope.isWeatherRadarAnimatedPlaying[animatedLayer] = true;
        $scope.$evalAsync();
      }

      if (!$scope.mapOptions.se.selectedSeLayers.includes(animatedLayer)) {
        $scope.mapOptions.se.selectedSeLayers.push(animatedLayer);
      }

      $scope.hideLayerToHideFromLayer(animatedLayer);
      $scope.updateSeLayer();
    };

    $scope.hideLayerToHideFromLayer = function (layer) {
      //this property exists for animated layers only
      let layerToHide = getSeLayers(layer).layerToHide;

      if (layerToHide) {
        $scope.disableWeatherRadarSection[layerToHide] = true;
        let isHideLayer = $scope.mapOptions.se.selectedSeLayers.includes(layerToHide);
        //if layer to hide exists in selectedlayers remove it
        if (isHideLayer) {
          $scope.mapOptions.se.hiddenLayers.push(layerToHide);
        }
      }
    };

    $scope.stopAnimatedLayer = function (animatedLayer, indexOfAnimatedLayer) {

      if ($scope.isWeatherRadarAnimatedPlaying[animatedLayer]) {
        $scope.isWeatherRadarAnimatedPlaying[animatedLayer] = false;
        $scope.$evalAsync();
      }
      $scope.mapOptions.se.selectedSeLayers.splice(indexOfAnimatedLayer, 1);
      let layerToUnHide = getSeLayers(animatedLayer).layerToHide;
      $scope.disableWeatherRadarSection[layerToUnHide] = false;

      let index = $scope.mapOptions.se.hiddenLayers.indexOf(layerToUnHide);
      if (index > -1) {
        $scope.mapOptions.se.hiddenLayers.splice(index, 1);
      }
      $scope.updateSeLayer();
    };

    $scope.playOrStopAnimatedLayer = function (animatedLayer) {
      let indexOfAnimatedLayer = $scope.mapOptions.se.selectedSeLayers.indexOf(animatedLayer);
      let isPlay = indexOfAnimatedLayer <= -1;

      if (isPlay) {
        $scope.playAnimatedLayer(animatedLayer);
      } else {
        $scope.stopAnimatedLayer(animatedLayer, indexOfAnimatedLayer);
      }
    };

    function immediateUpdateSEWeatherLayers() {
      // clear existing layers
      seLayersGroup.getLayers().clear();
      seLayersGroup.setLayers(getSeCollectionLayers());
      $rootScope.$emit("WeatherUpdated", $scope.mapOptions.se.selectedSeLayers, $scope.mapOptions.se.hiddenLayers, $scope.mapOptions.se.weather);
    }

    function getSeCollectionLayers() {
      animatedLayers = [];

      var layerCollection = new ol.Collection();
      if ($scope.mapOptions.se.selectedSeLayers.length > 0) {
        var requestLayers = [];
        var requestLayersGroup = [];
        var requestLayersNoGroup = [];
        var requestLayersApiV2 = [];
        var layer;

        // check layers to add
        $scope.mapOptions.se.selectedSeLayers.filter(function (l) {

          // we check first if the layer is not in the layers to hide
          if (!_.includes($scope.mapOptions.se.hiddenLayers, l)) {
            layer = getSeLayers(l);

            if (layer) {

              if (layer.animate) {
                animatedLayers.push(layer)
              } else if (layer.apiVersion && layer.apiVersion === 2) {
                requestLayersApiV2.push(layer)
              } else if (layer.group) {
                requestLayersGroup.push(layer);
              } else {
                requestLayersNoGroup.push(layer);
              }
            }
          }
        });

        //group layers by pack of 8 to reduce number of request
        requestLayersGroup = _.orderBy(requestLayersGroup, 'depth');
        requestLayersNoGroup = _.orderBy(requestLayersNoGroup, 'depth');

        if (!_.isEmpty(animatedLayers)) {
          addAndLoadAnimationWeatherLayers(layerCollection);
        }
        else {
          clearInterval(animateWeatherInterval);
        }

        for (var i = 0; i < requestLayersApiV2.length; i++) {
          var layer = requestLayersApiV2[i];
          var parameters = {};

          if (layer.flightLevel) {
            parameters = {
              'LAYERS': layer.name,
              'NTCACHEBUST': new Date().toISOString().slice(11, -5),
              'dim_flight_level': layer.flightLevel,
              'dim_icing_aircraft_size': layer.aircraftSize,
              'time': layer.time
            }
          } else {
            parameters = {
              'LAYERS': layer.name,
              'NTCACHEBUST': new Date().toISOString().slice(11, -5),
              'dim_icing_aircraft_size': layer.aircraftSize,
              'time': layer.time
            }
          }

          var olSource = new ol.source.TileWMS({
            crossOrigin: 'anonymous',
            url: window.seWeatherHost + '/basic/wms_v1/wms.wsgi',
            params: parameters,
            hidpi: false,
            ratio: 1.1,
            serverType: 'geoserver',
            gutter: 30, //prevent label cuts on tiles edges
            tileGrid: generateTileGrid(viewExtent)
          });
          var olLayer = new ol.layer.Tile({
            source: olSource,
            // Add Transparency on SE layers. JIRA #191
            opacity: layer.opacity
          });
          layerCollection.push(olLayer);
        }
        addRequestLayers(layerCollection, requestLayersGroup, MAX_LAYERS_PER_GROUP);
        addRequestLayers(layerCollection, requestLayersNoGroup, 1);

      }
      else {
        clearInterval(animateWeatherInterval);
      }

      return layerCollection;
    }

    function addRequestLayers(layerCollection, requestLayers, layersPerGroup) {
      for (var i = 0; i < requestLayers.length; i = i + layersPerGroup) {
        var pack = requestLayers.slice(i, i + layersPerGroup);
        var querylayers = _.map(pack, 'name');
        var olSource = new ol.source.TileWMS({
          crossOrigin: 'anonymous',
          url: window.seWeatherHost + '/basic/wms_v1/wms.wsgi',
          params: {
            'LAYERS': querylayers.join(','),
            'NTCACHEBUST': new Date().toISOString().slice(11, -5)
          },
          hidpi: false,
          ratio: 1.1,
          serverType: 'geoserver',
          gutter: 30, //prevent label cuts on tiles edges
          tileGrid: generateTileGrid(viewExtent)
        });
        var olLayer = new ol.layer.Tile({
          source: olSource,
          // Add Transparency on SE layers. JIRA #191
          opacity: pack[0].opacity
        });
        if (requestLayers[i].zIndexLayer) {
          olLayer.setZIndex(1);
        }
        layerCollection.push(olLayer);
      }
    }

    function playPauseAnimatedWeatherLayers() {
      let currentLayer;
      let nextLayer;
      let nextLayerMinutes = animatedLayersMinutesIntoPast - ANIMATED_LAYER_MINUTES_BETWEEN_FRAMES;

      if (nextLayerMinutes < 0) {
        //reset it to 175 as 0 is the last/latest animated radar layer
        nextLayerMinutes = 175;
      }

      seLayersGroup.getLayers().forEach((layer) => {
        if (layer.get('title') === getAnimatedLayerTitleWithMinutes(animatedLayersMinutesIntoPast)) {
          currentLayer = layer;
        } else if (layer.get('title') === getAnimatedLayerTitleWithMinutes(nextLayerMinutes)) {
          nextLayer = layer;
        }
      });

      //currentLayer's visibility would be true so we change it to false and set the next layer's visibility to true
      currentLayer && currentLayer.setVisible(false);
      nextLayer && nextLayer.setVisible(true);

      if (animatedLayersMinutesIntoPast === 0) {
        //reset it to 175 as 0 is the last/latest animated radar layer
        animatedLayersMinutesIntoPast = 175;
      }
      else {
        animatedLayersMinutesIntoPast = animatedLayersMinutesIntoPast - ANIMATED_LAYER_MINUTES_BETWEEN_FRAMES;
      }
    }

    function resetAnimatedLayersVisibilityAndStartAnimation() {
      let isFirstLayer = true;

      animatedLayerCollection.forEach(layer => {
        //set all layers visibility to false except first layer
        if (!isFirstLayer) {
          layer.setVisible(false);
        }
        else {
          isFirstLayer = false;
        }

      });
      clearInterval(animateWeatherInterval);
      animateWeatherInterval = setInterval(playPauseAnimatedWeatherLayers, 1000 / FRAME_RATE);
    }

    function getAnimatedLayerNameWithMinutes(layerName, minutes) {
      return (minutes === 0) ? layerName + '_CURRENT' : layerName + '-' + minutes + 'MIN'
    }

    function getAnimatedLayerTitleWithMinutes(minutes) {
      return 'animatedWeatherLayer' + minutes;
    }

    function addAndLoadAnimationWeatherLayers(layerCollection) {
      let querylayers;
      let opacity = animatedLayers[0].opacity;
      let olSource;
      let olLayer;
      let cacheBustTime = new Date().toISOString().slice(11, -5);

      for (animatedLayersMinutesIntoPast = 175; animatedLayersMinutesIntoPast >= 0; animatedLayersMinutesIntoPast = animatedLayersMinutesIntoPast - ANIMATED_LAYER_MINUTES_BETWEEN_FRAMES) {
        querylayers = animatedLayers.map(layer => getAnimatedLayerNameWithMinutes(layer.name, animatedLayersMinutesIntoPast));

        olSource = new ol.source.ImageWMS({
          crossOrigin: 'anonymous',
          url: window.seWeatherHost + '/animated/basic/wms_v1/wms.wsgi',
          params: {
            'LAYERS': querylayers.join(','),
            'NTCACHEBUST': cacheBustTime,
          },
          hidpi: false,
          ratio: 1.1,
          serverType: 'geoserver',
          gutter: 20, //prevent label cuts on tiles edges
          imageLoadFunction: function (image, src) {
            let imageElement = image.getImage();
            imageElement.onload = function () {
              animatedWeatherLayersToLoad = animatedWeatherLayersToLoad - 1;
              //start animation only after all layers are loaded
              if (animatedWeatherLayersToLoad === 0) {
                resetAnimatedLayersVisibilityAndStartAnimation();
                animatedWeatherLayersToLoad = 36;//reset this to the max layers for new requests
              }
            };
            imageElement.src = src
          }
        });

        olLayer = new ol.layer.Image({
          'title': getAnimatedLayerTitleWithMinutes(animatedLayersMinutesIntoPast),
          source: olSource,
          opacity: opacity,
          visible: true//set visibility to true initially so that openlayers requests and loads image
        });
        animatedLayerCollection.push(olLayer);//adding to this collection would make it easier to toggle visibility of animation layers
        layerCollection.push(olLayer);
      }

      //reset this to 175 so that when we start toggling visibility for each of these layers we start from this layer
      animatedLayersMinutesIntoPast = 175
    }

    /**
     * Parse schneider request to get body
     * @param {* schneider request} r
     */
    function getBodyFromRequest(r) {
      // console.log('Request', r, url);

      var resultBody = '<div class="layerTooltip">';
      // get body content from request
      while ((r.indexOf("</body>")) !== -1) {
        var body;
        var indexOfBodyStart = r.indexOf("<body>");
        var indexOfBodyEnd = r.indexOf("</body>");
        body = r.substring(indexOfBodyStart + 6, indexOfBodyEnd);
        r = r.substring(indexOfBodyEnd + 7, r.length);
        resultBody = resultBody + body;
      }
      resultBody = resultBody + '</div>';

      return resultBody;
    }

    /**
     * Get infos Layers from Schneider electric service and display tootlip
     */
    var getLayersInfosFromSchneider = function (coordinate, jsonObjects) {
      return new Promise(function (resolve, reject) {
        var queries = [];
        var counter = 0;

        var viewResolution = /** @type {number} */ (map.getView().getResolution());
        var source = getSeCollectionLayers().forEach(function (layer, index, array) {
          var layerTitle = layer.get('title');
          if (angular.isUndefined(layerTitle) || !layerTitle.includes('animated') || layerTitle === 'animatedWeatherLayer0') {
            counter++;
            var url = layer.getSource().getGetFeatureInfoUrl(
              coordinate,
              viewResolution,
              map.getView().getProjection(), {
              'INFO_FORMAT': 'text/html'
            });
            var request = fetch(url)
              .then(function (response) {
                return response.text();
              })
              .then(function (r) {
                if (r.length) {
                  var body = getBodyFromRequest(r);

                  if ($(body).find('tbody').text().trim().length) {

                    if (jsonObjects) {

                      let doc = new DOMParser().parseFromString(body, "text/html");
                      let tables = doc.getElementsByTagName('table');
                      return tables;

                    } else {
                      return body;
                    }

                  } else {
                    return "";
                  }
                }
              });
            queries.push(request);
            // condition to ensure that we call display tooltip once time
          }
        });
        Promise
          .all(queries)
          .then(function (results) {
            results = _.reject(results, _.isEmpty);
            if (results.length > 0) {

              if (jsonObjects) {

                let tableResults = [];

                results.forEach(function (result) {

                  var arr = Array.from(result);
                  arr.forEach(function (table) {
                    tableResults.push(tableToJson(table));
                  });
                });

                resolve(tableResults);
              } else {
                resolve(results.join(' '));
              }

            } else {
              resolve("No available weather data");
            }
          })
          .catch(function (e) {
            console.error('error', e);
          });

      });
    };

    $scope.isChecked = function (layer) {
      return _.includes($scope.mapOptions.se.selectedSeLayers, layer);
    }

    $scope.$watch('mapOptions', function (newValue, oldValue) {
      if (!_.eq(newValue, oldValue)) {
        if (newValue.brightness !== oldValue.brightness) {
          opacityBgLayer.changeBrightness(newValue.brightness);
          opacitySelectionLayer.changeBrightness(newValue.brightness);
        }
        styleProvider.setFlightInfoVisible(mapOptions.labels.displayFlightInfo);

        // mc.updateAirportVisibility(newValue);

        if (wdtincWeatherData.globalirgrid.layer) {
          wdtincWeatherData.globalirgrid.layer.setVisible(newValue.weather.IRSat);
        }
        wdtincWeatherData.globalirgrid.visible = newValue.weather.IRSat;

        if (wdtincWeatherData.lightning.layer) {
          wdtincWeatherData.lightning.layer.setVisible(newValue.weather.lightning);
        }
        if (mapOptions.labels.autoArrangeLabels) {
          mc.declutterLabels(map.getView().getResolution());
        }
        wdtincWeatherData.lightning.visible = newValue.weather.lightning;

        // Runway
        runwayZoneLayer.setVisible(newValue.labels.runwayDetectionZone);

        // Populated Areas
        var displayMajorCities = mapOptions.displayMajorCities.displayMajorCities &&
          mapOptions.displayMajorCities.maxResolution > map.getView().getResolution();
        majorcitiesLayer.setVisible(displayMajorCities);

        // notifs
        document.getElementById("audio-notification").volume = newValue.notifs.volume;

        //planesLayers.changed(); //force rerender
        userService.savePreferences({
          mapOptions: newValue
        })['catch'](function () {
          //reload last preferences in DB
          mapOptions = $scope.user.preferences.mapOptions;
        });
      }
    }, true);


    //listen for flights data updates
    flightNgStore.on('sync:search', updateFlights, mc);
    //flightNgStore.on('add:search', addFlight, mc);
    //flightNgStore.on('remove:search', removeFlight, mc);
    //update the active flight
    flightNgStore.on('update same', function (flight) {
      //var transform = ol.proj.getTransform('EPSG:4326', 'EPSG:3857');
      //var estimated = false,
      var state;
      let flightId = flight.id;

      if (flightId === this.selectedFlightId) {
        //Update the selected aircraft trail
        if (trailVector.getFeatures().length > 0) {
          state = !!flight.olFeature.get('customerAircraft') ? 0 : 1; //!!flight.olFeature.get('closed') ? 4 : !!flight.olFeature.get('customerAircraft') ? flight.olFeature.get('alertLvl') || 0 : /* others */ 3;
          updateFlightFlownDistance(flight);
          //updatePositionTrail(transform([flight.long, flight.lat]), flight.olFeature.getGeometry().getCoordinates(), state, flight.src);
          //updatePositionTrail(transform([flight.long, flight.lat]), transform([flight.estimateLong, flight.estimateLat]), state, flight.src);
          updatePositionTrail(flight, state);
          if (!!mc.selectedFeature && mc.selectedFlightId !== null) {
            mc.displayFlightPlan(mc.selectedFeature);
          }
          /*else if (!!flight.crossedTrackPointLat && !!flight.crossedTrackPointLon) {
                     updateFlightPlanTrack(flight.crossedTrackPointLat, flight.crossedTrackPointLon);
                   }*/
        }
      }
      //update flightplans for toggled on list
      if (mc.flightPlanToggledList.includes(flightId)) {
        mc.updateFlightPlansForFlight(flightId)
      }
    }, mc);

    //listen for drawing
    featureNgStore.on('sync', updateFeatures, mc);

    //listen for admin configuration updates
    customerNgStore.on('update', function (updatedCustomer) {
      customer = customerNgStore.customer;
      parameters = customer.parameters;
      if (!_.isEqual(customerColors, parameters.customColors)) {
        customerColors = parameters.customColors;
        styleProvider.updateColors({
          myfleetColor: customerColors.myfleet,
          trackedColor: customerColors.tracked,
          cautionColor: customerColors.caution,
          warningColor: customerColors.warning,
          othersColor: customerColors.other,
          closedColor: customerColors.closed,
          groundVehicleColor: customerColors.other
        });
        aircraftTooltipProvider.updateColors({
          myfleetColor: customerColors.myfleet,
          trackedColor: customerColors.tracked,
          cautionColor: customerColors.caution,
          warningColor: customerColors.warning,
          othersColor: customerColors.other,
          closedColor: customerColors.closed
        });
      }
    }, mc);
    //listen for user preferences changes
    userService.on('update', function () {
      if (displayedBackground !== $scope.user.preferences.map) {
        displayedBackground = $scope.user.preferences.map;
        backgroundlayers.getLayers().clear();
        backgroundlayers.getLayers().push(new ol.layer.Tile($scope.configuration.maps[displayedBackground]));
      }
      showFlownDistanceKm = $scope.user.preferences.displayedData.indexOf('flownDistanceKm') > -1;
      showFlownDistanceNm = $scope.user.preferences.displayedData.indexOf('flownDistanceNm') > -1;
    }, mc);

    var checkwdtincWeatherInterval = setInterval(checkwdtincWeather, 300000);
    checkwdtincWeather();
    var refreshSEWeatherInterval = setInterval(function () { $scope.updateSeLayer(); }, 300000);
    setInterval(function () { mc.declutterLabels(map.getView().getResolution()); }, 600000);
    setTimeout(function () { mc.declutterLabels(map.getView().getResolution()); }, 10000);

    $scope.$root.toolbox = {
      active: false,
      isDraw: false,
      isMeasure: false,
      isRoute: false
    };

    $scope.$root.toolbox.route = function () {
      $scope.$root.toolbox.active = true;
      $scope.$root.toolbox.isDraw = false;
      $scope.$root.toolbox.isMeasure = false;
      $scope.$root.toolbox.isRoute = true;
    };

    $scope.$root.toolbox.draw = function () {
      $scope.$root.toolbox.active = true;
      $scope.$root.toolbox.isDraw = true;
      $scope.$root.toolbox.isMeasure = false;
      $scope.$root.toolbox.isRoute = false;
    };

    $scope.$root.toolbox.measure = function () {
      $scope.$root.toolbox.active = true;
      $scope.$root.toolbox.isDraw = false;
      $scope.$root.toolbox.isMeasure = true;
      $scope.$root.toolbox.isRoute = false;
    };

    $scope.$root.toolbox['default'] = function () {
      $scope.$root.toolbox.active = false;
      $scope.$root.toolbox.isDraw = false;
      $scope.$root.toolbox.isMeasure = false;
      $scope.$root.toolbox.isRoute = false;
    };

    $scope.openUDAWindow = function () {
      if (definedAreaService.definedAreaEditController.opened) {
        definedAreaService.definedAreaEditController.close();
      } else {
        definedAreaService.definedAreaEditController.open();
      }
    };

    $scope.openRouteList = function () {
      $rootScope.$emit("ToggleRouteList");
    };

    var modifyFeatures = new ol.Collection();
    var modify = new ol.interaction.Modify({
      features: modifyFeatures,
      // the SHIFT key must be pressed to delete vertices, so
      // that new vertices can be drawn at the same position
      // of existing vertices
      deleteCondition: function (event) {
        return ol.events.condition.shiftKeyOnly(event) &&
          ol.events.condition.singleClick(event);
      },
      wrapX: true
    });
    modify.on('modifyend', function (modifyEvent) {
      var i = modifyEvent.features.getLength();
      while (i--) {
        var feature = modifyEvent.features.item(i);
        var coords = feature.getGeometry().getCoordinates();
        feature.getGeometry().setCoordinates([GeometryUtils.grahamScan(coords[0])]);
      }
    });

    map.modify = {
      features: modifyFeatures,
      addInteraction: function () {
        map.addInteraction(modify);
      },
      removeInteraction: function () {
        map.removeInteraction(modify);
      }
    };

    //map.addInteraction(modify);

    var drawInteraction;
    var drawKeydownHandler;
    var addDrawInteraction = function (type, onDrawEnd) {

      var Constructor;
      var mode;
      var GeometryType = {
        POINT: 'Point',
        LINE_STRING: 'LineString',
        LINEAR_RING: 'LinearRing',
        POLYGON: 'Polygon',
        MULTI_POINT: 'MultiPoint',
        MULTI_LINE_STRING: 'MultiLineString',
        MULTI_POLYGON: 'MultiPolygon',
        GEOMETRY_COLLECTION: 'GeometryCollection',
        CIRCLE: 'Circle'
      };
      if (type === GeometryType.POINT || type === GeometryType.MULTI_POINT) {
        mode = GeometryType.POINT;
        Constructor = ol.geom.Point;
      } else if (type === GeometryType.LINE_STRING || type === GeometryType.MULTI_LINE_STRING) {
        mode = GeometryType.LINE_STRING;
        Constructor = ol.geom.LineString;
      } else if (type === GeometryType.POLYGON || type === GeometryType.MULTI_POLYGON) {
        mode = GeometryType.POLYGON;
        Constructor = ol.geom.Polygon;
      } else if (type === GeometryType.CIRCLE) {
        mode = GeometryType.CIRCLE;
        // XXX add circle support if needed
      }

      var geometryFunction = function (coordinates, opt_geometry) {
        var geometry = opt_geometry,
          grahamScanCoordinate;

        if (geometry) {
          grahamScanCoordinate = GeometryUtils.grahamScan(coordinates[0]);
          grahamScanCoordinate.push(grahamScanCoordinate[0]);
          geometry.setCoordinates([grahamScanCoordinate]);
        } else {
          geometry = new Constructor(coordinates);
        }
        return geometry;
      };

      drawInteraction = new ol.interaction.Draw({
        features: drawFeatures,
        type: type,
        wrapX: true,
        geometryFunction: mode === GeometryType.POLYGON ? geometryFunction : undefined
      });

      drawKeydownHandler = function (keydownEvent) {
        if (keydownEvent.keyCode === 8) {
          // BACKSPACE
          drawInteraction.removeLastPoint();
          keydownEvent.preventDefault();
          keydownEvent.stopPropagation();
        } else if (keydownEvent.keyCode === 13) {
          // ENTER
          drawInteraction.finishDrawing();
        } else if (keydownEvent.keyCode === 27) {
          // ESCAPE
          drawInteraction.setActive(false);
          drawInteraction.setActive(true);
        }
      };

      drawInteraction.on('drawstart', function (drawEvent) {
        $(document).on('keydown', drawKeydownHandler);
      });

      drawInteraction.on('drawend', function (drawEvent) {
        var feature = drawEvent.feature;
        feature.set('class', 'shape');
        $(document).off('keydown', drawKeydownHandler);
        map.removeInteraction(drawInteraction);
        if (typeof onDrawEnd === 'function') {
          onDrawEnd(drawEvent);
        }
      });

      map.addInteraction(drawInteraction);
    };

    map.draw = {
      addInteraction: function (type, onDrawEnd) {
        if (drawInteraction) {
          map.removeInteraction(drawInteraction);
          drawInteraction = null;
        }
        addDrawInteraction(type, onDrawEnd);
      },
      removeInteraction: function () {
        if (drawInteraction) {
          $(document).off('keydown', drawKeydownHandler);
          map.removeInteraction(drawInteraction);
          drawInteraction = null;
        }
      }
    };

    //addDrawInteraction(ol.geom.GeometryType.POLYGON);

    map._size = null;
    map.beginBatchUpdate = function () {
      var s = map.getSize();
      if (s) {
        map._size = s;
        map.setSize(undefined);
      }
    };

    map.endBatchUpdate = function () {
      if (map._size) {
        map.setSize(map._size);
        map._size = null;
      }
    };

    $scope.previousWeatherValue = function (obj, refArray) {
      var seWeatherArray = seWeatherRefs.seWeather[refArray];
      if (obj.index > 0) {
        obj.index = obj.index - 1;
        obj.param = seWeatherArray[obj.index];
        $scope.updateSeLayer();
      }
    };

    $scope.nextWeatherValue = function (obj, refArray) {
      var seWeatherArray = seWeatherRefs.seWeather[refArray];
      if (obj.index < seWeatherArray.length - 1) {
        obj.index = obj.index + 1;
        obj.param = seWeatherArray[obj.index];
        $scope.updateSeLayer();
      }
    };

    $scope.previousWeatherValue2 = function (obj, refArray) {
      var seWeatherArray = seWeatherRefs.seWeather[refArray];
      if (obj.index2 > 0) {
        obj.index2 = obj.index2 - 1;
        obj.param2 = seWeatherArray[obj.index2];
        $scope.updateSeLayer();
      }
    };

    $scope.nextWeatherValue2 = function (obj, refArray) {
      var seWeatherArray = seWeatherRefs.seWeather[refArray];
      if (obj.index2 < seWeatherArray.length - 1) {
        obj.index2 = obj.index2 + 1;
        obj.param2 = seWeatherArray[obj.index2];
        $scope.updateSeLayer();
      }
    };

    $scope.previousWeatherValue3 = function (obj, refArray) {
      var seWeatherArray = seWeatherRefs.seWeather[refArray];
      if (obj.index3 > 0) {
        obj.index3 = obj.index3 - 1;
        obj.param3 = seWeatherArray[obj.index3];
        $scope.updateSeLayer();
      }
    };

    $scope.nextWeatherValue3 = function (obj, refArray) {
      var seWeatherArray = seWeatherRefs.seWeather[refArray];
      if (obj.index3 < seWeatherArray.length - 1) {
        obj.index3 = obj.index3 + 1;
        obj.param3 = seWeatherArray[obj.index3];
        $scope.updateSeLayer();
      }
    };

    $scope.decrementSpinner = function (obj, refArray) {
      
      var seWeatherArray = seWeatherRefs.seWeather[refArray];

      if (obj.index > 0) {

        obj.index = obj.index - 1;
        obj.value = seWeatherArray[obj.index];
      }

      $scope.updateSpinnerLabel(obj);
      $scope.updateSeLayer();
    };

    $scope.incrementSpinner = function (obj, refArray) {

      var seWeatherArray = seWeatherRefs.seWeather[refArray];

      if (obj.index < seWeatherArray.length - 1) {

        obj.index = obj.index + 1;
        obj.value = seWeatherArray[obj.index];
      }

      $scope.updateSpinnerLabel(obj);
      $scope.updateSeLayer();
    };

    $scope.updateSpinnerLabel = function (obj) {

      if (obj.value == '00') {

        obj.param = '_CURRENT';
        obj.label = 'Current';

      } else {

        obj.label = obj.value + ' min';
        obj.param = '-' + obj.value + 'MIN';
      }
    };

    $scope.updateInputRange = function (obj) {
      obj.label = 'Current';
      if (obj.value == 0) {
        obj.param = '_CURRENT';
      } else {
        obj.label = obj.value + ' min';
        obj.param = '-' + obj.value + 'MIN';
      }
      $scope.updateSeLayer();
    }

    function getLayerTimePart(timeVal) {
      var timePart = 'CURRENT';
      if (timeVal != "Current") {
        timePart = timeVal + "HR";
      }
      return timePart;
    }

    $rootScope.$on("updateFlightPlanToggledList", function (event, flightId) {
      $scope.updateFlightPlanToggledList(flightId);
    });

    $rootScope.$on("updateFlightPlanToggledListBySection", function (event, flights, sectionName) {
      $scope.updateFlightPlanToggledListBySection(flights, sectionName);
    });

    $rootScope.$on("removeFlightPlansBySection", function (event, sectionName) {
      $scope.removeFlightPlansBySection(sectionName);
    });

    $scope.removeFlightPlansBySection = function (sectionName) {

      mc.flightPlanToggleState[sectionName] = false;

      planesData[sectionName].source.forEachFeature(feature => {
        let flightId = feature.getId();
        if (mc.flightPlanToggledList.includes(flightId)) {
          let index = mc.flightPlanToggledList.indexOf(flightId);
          mc.flightPlanToggledList.splice(index, 1);
          mc.removeFlightPlansByFlightIdOrUntoggledState(flightId);
        }
      });

    };

    $scope.updateFlightPlanToggledListBySection = function (flights, sectionName) {

      mc.flightPlanToggleState[sectionName] = !mc.flightPlanToggleState[sectionName];

      flights.forEach(flight => {
        let index = mc.flightPlanToggledList.indexOf(flight.id);
        let flightPlanToggledOn = index > -1;

        //add if section flight plan is on and flight plan for flight is not already toggled on
        if (mc.flightPlanToggleState[sectionName] && !flightPlanToggledOn) {
          mc.flightPlanToggledList.push(flight.id);
          mc.updateFlightPlansForFlight(flight.id);
        }
        //remove if section flight plan is off and flight plan for flight is toggled on
        else if (!mc.flightPlanToggleState[sectionName] && flightPlanToggledOn) {
          mc.flightPlanToggledList.splice(index, 1);
          mc.removeFlightPlansByFlightIdOrUntoggledState(flight.id);
        }
      })
    };

    $scope.updateFlightPlanToggledList = function (flightId) {
      let index = mc.flightPlanToggledList.indexOf(flightId);
      let flightPlanToggledOn = index > -1;
      if (flightPlanToggledOn) {
        //Already toggled on, so remove from the list
        mc.flightPlanToggledList.splice(index, 1);
      } else {
        mc.flightPlanToggledList.push(flightId);
      }
    };

    mc.removeFlightPlansByFlightIdOrUntoggledState = function (flightId) {
      let flightPlanFeatures = flightPlanVector.getFeatures() || [];
      let waypointFeatures = waypointVector.getFeatures() || [];

      flightPlanFeatures.forEach(flightPlanFeature => {
        if (mc.isRemoveFlightPlanFeature(flightId, flightPlanFeature)) {
          flightPlanVector.removeFeature(flightPlanFeature);
        }
      });

      waypointFeatures.forEach(waypointFeature => {
        if (mc.isRemoveFlightPlanFeature(flightId, waypointFeature)) {
          waypointVector.removeFeature(waypointFeature);
        }
      });
    };

    mc.isRemoveFlightPlanFeature = function (flightId, feature) {
      let featureFlightId = feature.get('flightId');

      // remove if flight plan is going to be redrawn or if flight is not selected and not toggled on
      return ((flightId === featureFlightId) || (!mc.flightPlanToggledList.includes(featureFlightId) && (!$scope.selectedFlight || featureFlightId !== $scope.selectedFlight.id)))
    };


    $scope.$on('$destroy', function () {
      userService.off(null, null, mc);
      userProfileNgStore.off(null, null, mc);
      flightNgStore.off(null, null, mc);
      customerNgStore.off(null, null, mc);
      featureNgStore.off(null, null, mc);
      featureNgStore.off(null, null, mc);
      clearInterval(checkwdtincWeatherInterval);
      clearInterval(refreshTrailInterval);
      clearInterval(refreshSEWeatherInterval);
    });

  }

  App
    .controller('MapCtrl', ['$scope',
      '$rootScope',
      '$state',
      '$element',
      '$uibModal',
      'mapPreferences',
      'seWeatherRefs',
      'flightSectionDefinitions',
      'flightNgStore',
      'userService',
      'userProfileNgStore',
      'customerNgStore',
      'featureNgStore',
      '$timeout',
      'mapStyle',
      'aircraftTooltipProvider',
      'seLayers',
      'flightfieldDefinitions',
      'navLayersService',
      'navLayers',
      'definedAreaService',
      'defaultPreferences',
      'generalConstants',
      'mapPositionService',
      MapCtrl
    ]);

}());