/*jslint browser: true, vars:true, nomen:true, plusplus:true*/
/*global App, ServerTime, moment, d3, _, $ */

App.controller('AirportCapacityCtrl',[
  '$scope',
  '$element',
  'airportDataNgStore',
  'flightNgStore',
  'flightfieldDefinitions',
  'userService',
  'airlineDataStore',
  'airportCapacityService',
  function ($scope, $element, airportDataNgStore, flightNgStore, flightfieldDefinitions, userService, airlineDataStore,airportCapacityService) {
    'use strict';

    var airport = $scope.airport,
        updater,
        animationDuration = 500,
        defaultThreshold = 15,
        minInterval = 15,
        startScale = 1,
        self = this,
        element = $element[0];

    // 0 for capacity/holding, 1 for average, 2 for max, 3 for total

    $scope.timeInterval = 60;
    $scope.definitions = flightfieldDefinitions;
    $scope.thresholds = [];
    $scope.allAirlines = airlineDataStore.airlines;

    if(_.isEmpty($scope.allAirlines)){
      airlineDataStore = airportCapacityService.populateAirlines();
      $scope.allAirlines = airlineDataStore.airlines;
    }
    $scope.displayedAirlines = _.clone(userService.user.preferences.displayedAirlines);
    $scope.availabledAirlines = _.clone(userService.user.preferences.displayedAirlines);
    $scope.otherAreDisplayed = true;
    $scope.graphTypes = [{
      type: 0,
      label: 'Capacity'
    }, {
      type: 1,
      label: 'Holding'
    }];

    $scope.extraDatas = [{
      type: 2,
      label: 'Average'
    }, {
      type: 3,
      label: 'Maximum'
    }, {
      type: 4,
      label: 'Total'
    }];

    $scope.refreshFilter = function () {
      self.updateGraphData(self.rawData);
    };
    $scope.graphType = $scope.graphTypes[0];
    $scope.extraData = $scope.extraDatas[0];
    var findAirline = function (flight) {
      var airline;
      if (flight.airline) {
        airline = $scope.allAirlines[flight.airline];
      }
      if (airline) {
        return airline;
      } else {
        var iata = flight.flightNumber && flight.flightNumber.slice(0, 2);
        if (iata) {
          return _.find({
            iata: iata
          });
        }
      }
    };
    var saveThresholds = function (thresholds) {
      var icao = $scope.airport.icao,
          t = userService.getUserPreference('airportThresholds');
      t[icao] = thresholds;
      $scope.thresholds = thresholds;
      userService.savePreferences({
        airportThresholds: t
      });
    };

    var computeTicks = function (minDate, maxDate, interval) {
      var firstDate,
          lastDate,
          ticks;
      firstDate = moment.utc(minDate).startOf('h'); //floor to nearest hour
      lastDate = moment.utc(maxDate).add(interval, 'm').add(1, 'h').startOf('h'); //ceil to hour
      return d3.time.minute.range(firstDate, lastDate, interval);

    };
    var findPreviousStep = d3.bisector(function (t) {
      return t.step;
    }).right;

    var hourOftheDate = function (date) {
      var dTick = moment(date);
      return dTick.hours() * 60; //ellapsed minutes of the day
    };

    var thresholdForStep = function (step) {
      var v,
          thresholdIndex,
          threshold = {
            value: 0,
            steps: []
          };
      // p = 1;
      if ($scope.thresholds.length === 0) {
        threshold.value = defaultThreshold;
        return threshold;
      }
      thresholdIndex = findPreviousStep($scope.thresholds, step);
      if (thresholdIndex === 0) {
        //no previous threshold, then wrap and use the last one
        thresholdIndex = $scope.thresholds.length;
      }
      v = $scope.thresholds[thresholdIndex - 1].value;
      threshold.value += v;
      threshold.steps.splice(0, 0, v);
      return threshold;
    };

    var thresholdFor = function (tick) {
      return thresholdForStep(hourOftheDate(tick));
    };

    var computeClass = function (histogramData) {
      var now = moment.utc(ServerTime.now());
      return function (d, i) {
        var threshold, classes = 'bar';
        var interval;
        var value = 0;
        var dataStartIndex = i;
        var iter = 1;
        var minutes;
        if (now.diff(d.x) > d.dx) {
          classes += ' past';
        } else {
          interval = d.dx / 60000;
          iter = 60 / interval;
          dataStartIndex = i - d.x.getMinutes() / interval;
          for (i = 0; i < iter; i++) {
            if (histogramData[dataStartIndex + i]) {
              value = value + histogramData[dataStartIndex + i].y;
            }
          }
          threshold = thresholdFor(d.x).value;
          if (value > threshold) {
            classes += ' danger';

          } else if (value === threshold) {
            classes += ' warning';
          }
          if ($scope.selected && +$scope.selected.x === +d.x) {
            classes += ' active';
          }
        }
        return classes;
      };
    };

    var margin = {
          top: 20,
          right: 20,
          bottom: 30,
          left: 20
        },
        width = 550, //wide aspect ratio that match CSS rule
        height = 210,
        outerWidth = width + margin.left + margin.right,
        outerHeight = height + margin.top + margin.bottom;
    var svg = d3.select($element.find('.graph')[0]).append('svg')
        .attr('viewBox', '0 0 ' + outerWidth + ' ' + outerHeight)
        .attr('preserveAspectRatio', 'xMidYMid meet')
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
    var formatCount = d3.format('d');

    var x = d3.time.scale.utc()
        .range([0, width]);

    var histogramData = d3.layout.histogram()
        .value(function (d) {
          if ($scope.graphType.type === 0) {
            return d.landingTime || d.sta;
          } else {
            return d.holdingLandingTime;
          }
        })
        .bins(function (dates) {
          return computeTicks(dates[0], dates[1], $scope.timeInterval);
        });

    var activeNumber = function (d) {
      var scheduled = _.filter(d, 'scheduledOnly').length;
      return (d.y - scheduled) || 0;
    };

    var y = d3.scale.linear()
        .nice(1)
        .range([height, 0]);

    var xAxis = d3.svg.axis()
        .scale(x)
        .tickFormat(d3.time.format.utc('%H:%M'))
        .orient('bottom');

    var yAxis = d3.svg.axis()
        .scale(y)
        .tickSize(width)
        .tickFormat(Math.round)
        .tickSubdivide(0)
        .orient('left');

    var zoom = d3.behavior.zoom()
        //.scaleExtent([1, 3])
        .scaleExtent([1, 3])
        .scale(startScale)
        .on('zoom', function () {
          zoom.translate([d3.event.translate[0], 0]);
          self.draw(false);
        })
        .on('zoomend', function () {
          var scale = zoom.scale(),
              //interval = Math.round((60 / scale) / minInterval) * minInterval;// 15 -30-45-60
              interval = Math.pow(2, Math.round(3 / scale) - 1) * minInterval,
              eventTranslate = d3.event.translate;

          if (!!eventTranslate && eventTranslate.lenght > 0) {
            zoom.translate([eventTranslate[0], 0]);
            self.draw(false);

            if (interval !== $scope.timeInterval) {
              //console.log('zoom', d3.event, interval);
              self.updateGraphInterval(interval);
            }
          }
        });


    var drag = d3.behavior.drag()
        .on('dragstart', function () {
          svg.select('.threshold').attr('class', 'threshold drag');
        })
        .on('dragend', function () {
          var i, step, currenValue,
              nb = $scope.thresholds.length;
          //remove dragging class
          svg.select('.threshold').attr('class', 'threshold');
          self.hideTooltip();
          //clean thresholds
          for (i = 0; i < $scope.thresholds.length; i++) {
            step = $scope.thresholds[i];
            if (currenValue && (step.value === currenValue)) {
              //remove this step because it's equal to the previous one
              $scope.thresholds.splice(i, 1);
            } else {
              currenValue = step.value;
            }
          }
          saveThresholds($scope.thresholds);
          if (nb !== $scope.thresholds.length) {
            self.draw(false);
          }

        })
        .on('drag', function (d, i) {
          var str,
              value,
              step = hourOftheDate(d),
              thresholdIndex = findPreviousStep($scope.thresholds, step),
              threshold = $scope.thresholds[thresholdIndex - 1];
          if (!threshold || threshold.step !== step) {
            //new step
            threshold = {
              step: step,
              value: 0
            };
            $scope.thresholds.splice(thresholdIndex, 0, threshold);
          }
          value = y.invert(d3.event.y);
          // value = value / ($scope.timeInterval / minInterval); // scale to current interval
          value = Math.max(0, Math.round(value)); //round and clamp
          threshold.value = value;

          //cumul = thresholdFor(d, $scope.timeInterval);

          self.draw(false);
          str = 'Threshold';
          self.showTooltip([x(d), y(threshold.value)], [str, threshold.value]);
        });


    var thresholdLine = d3.svg.line()
        .interpolate('step-after')
        .x(function (tick) {
          return x(tick);
        })
        .y(function (tick) {
          var t = thresholdFor(tick).value;
          return y(t);
        });

    //x axis
    svg.append('g')
        .attr('class', 'x axis')
        .attr('clip-path', 'url(#clip)')
        .attr('transform', 'translate(0,' + height + ')');

    //y axis
    svg.append('g')
        .attr('class', 'y axis')
        //.call(yAxis)
        .attr('transform', 'translate(' + width + ', 0)')
        .append('text')
        .attr('transform', 'translate(' + (-width - margin.left) + ',0) rotate(-90)')
        .attr('y', -2)
        .attr('dy', '.71em')
        .style('text-anchor', 'end')
        .text('Number of arrival');

    svg
        .append('rect')
        .attr('class', 'glass-pane')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', width)
        .attr('height', height + margin.bottom)
        .call(zoom)
        .on("mousewheel.zoom", null)
        .on("DOMMouseScroll.zoom", null) // disables older versions of Firefox
        .on("wheel.zoom", null); // disables newer versions of Firefox;

    svg.append('g')
        //.call(zoom)
        .attr('class', 'data-pane')
        .append('rect')
        .attr('class', 'time-cursor')
        .attr('x', -margin.left * 2)
        .attr('y', -margin.top)
        .attr('height', height + margin.top);
    //threshold line
    svg.append('g')
        .attr('class', 'threshold')
        .append('path')
        .attr('class', 'threshold-step');

    svg.append('g')
        .attr('class', 'svgtooltip')
        .attr('visibility', 'hidden')
        .append('g')
        .attr('class', 'area')
        .attr('transform', 'translate(-92,-10)')
        .append('rect')
        .attr('width', 40)
        .attr('height', 20)
        .attr('x', 0)
        .attr('y', 0)
        .attr('rx', 1);

    this.updateGraphData = function (rawData) {
      var now = moment(ServerTime.now()),
          icaos = $scope.availabledAirlines,
          iatas = _.map($scope.availabledAirlines, function (a) {
            return $scope.allAirlines[a].iata;
          }),
          displayAirlinesIatas = _.map($scope.displayedAirlines, function (a) {
            return $scope.allAirlines[a].iata;
          });

      this.rawData = rawData;
      //filter unwanted flight
      rawData = _.reject(rawData, function (f) {
        var reject = true;
        var airline = findAirline(f);
        if (airline && icaos.indexOf(airline.icao) !== -1) {
          reject = $scope.displayedAirlines.indexOf(airline.icao) === -1;
        } else if (airline && iatas.indexOf(airline) !== -1) {
          reject = displayAirlinesIatas.indexOf(airline) === -1;
        } else if ($scope.otherAreDisplayed) {
          reject = false;
        }
        return reject;
      });
      // remove past flight for the table
      $scope.nextArrivals = _(rawData)
          .reject(function (f) {
            return now.isAfter(f.landingTime);
          }).sortBy('landingTime').value();

      this.arrivalData = rawData;
      this.draw(true);
      //find selected bar and refresh selected
      svg.select('.data-pane')
          .selectAll('.active')
          .each(function (d) {
            $scope.selected = d;
          });
      $scope.$applyAsync();
    };

    this.updateGraphInterval = function (interval) {
      $scope.timeInterval = interval;
      $scope.$applyAsync(function () {
        self.draw(true);
      });
    };


    this.showTooltip = function (pixel, text) {
      var area, lines;
      if (!_.isArray(text)) {
        text = [text];
      }
      area = svg.select('.svgtooltip')
          .attr('transform', 'translate(' + pixel.join(',') + ')')
          .attr('visibility', 'visible')
          .select('.area')
          .attr('transform', 'translate(0,-' + (10 * text.length) + ')');
      area.select('rect').attr('height', 10 * text.length).attr('y', -2);
      lines = area.selectAll('text')
          .data(text);
      lines.enter()
          .append('text');
      lines.text(_.identity)
          .attr('x', 5)
          .attr('y', function (d, i) {
            return (i * 10) + 5;
          });
      lines.exit().remove();
    };
    this.hideTooltip = function () {
      svg.select('.svgtooltip')
          .attr('visibility', 'hidden');
    };

    this.getAverageHoldingText = function (d) {
      if (d.y > 0 && $scope.graphType.type === 1) {
        var m = moment(d.x);
        var holdingData = $scope.holdingDatas[m.format('DDHH')];
        if (holdingData) {
          //return 'Avg:' + moment.duration(holdingData.average, "minutes").humanize();
          return _.floor(holdingData.average, 0);
        }
      }
      return 0;
    };

    this.getMaxHoldingText = function (d) {
      if (d.y > 0 && $scope.graphType.type === 1) {
        var m = moment(d.x);
        var holdingData = $scope.holdingDatas[m.format('DDHH')];
        if (holdingData) {
          //return 'Max:' + moment.duration(holdingData.max, "minutes").humanize();
          return holdingData.max;
        }
      }
      return 0;
    };

    this.getTotalHoldingText = function (d) {
      if (d.y > 0 && $scope.graphType.type === 1) {
        var m = moment(d.x);
        var holdingData = $scope.holdingDatas[m.format('DDHH')];
        if (holdingData) {
          //return 'Tot:' + moment.duration(holdingData.total, "minutes").humanize();
          return holdingData.total;
        }
      }
      return 0;
    };

    this.getExtraDataText = function (d) {
      var value = 0;
      if ($scope.extraData.type === 2) {
        value = this.getAverageHoldingText(d);
      } else if ($scope.extraData.type === 3) {
        value = this.getMaxHoldingText(d);
      } else if ($scope.extraData.type === 4) {
        value = this.getTotalHoldingText(d);
      }
      if (value === 0) {
        return '';
      } else {
        return value + ' min';
      }
    };

    this.draw = function (animate) {
      var data,
          interval = $scope.timeInterval,
          now = ServerTime.now(),
          animDuration = animate ? animationDuration : 0,
          ticks,
          bars,
          scale,
          dots,
          translate,
          enterBars,
          exitBars,
          barWidth = 3,
          graphLimit;

      var getYValue = function (d, active) {
        if ($scope.graphType.type === 0 || $scope.graphType.type === 1) {
          if (active) {
            return activeNumber(d);
          } else {
            return d.y || 0;
          }
        }
        return 0;
      };
      var getYAxisLegend = function () {
        if ($scope.graphType.type === 0 || $scope.graphType.type === 1) {
          return 'Number of arrivals';
        }
        /*else {
                  return 'Number of minutes';
                }*/
      };



      //console.log('drawGraph', animate);
      svg.interrupt();

      data = histogramData(this.arrivalData);
      var currentThreshold = d3.max($scope.thresholds, _.property('value'));
      if (currentThreshold === undefined) {
        currentThreshold = defaultThreshold;
      }

      graphLimit = currentThreshold;
      var i;
      for (i = 0; i < data.length; i++) {
        if (data[i].y > graphLimit) {
          graphLimit = data[i].y;
        }
      }




      x.domain([d3.time.minute.offset(now, -1 * 60), d3.time.minute.offset(now, 12 * 60)]);
      //update domain range
      y.domain([0, graphLimit + 5]).nice(10);

      if (y.domain()[1] < 10) {
        //force number of Y ticks to the number of aircraft (integer only)
        // avoid ticks on fraction
        yAxis.ticks(y.domain()[1]);
      } else {
        yAxis.ticks(10);
      }
      xAxis.ticks(d3.time.minute, interval);

      translate = zoom.translate();
      scale = Math.round(zoom.scale());
      zoom.x(x).scale(scale).translate(translate).center([x(now), 0]);

      //update axis
      svg.select('.x.axis')
          .transition()
          .duration(animDuration)
          .call(xAxis);
      //rotate x-labels
      svg.select('.x.axis').selectAll('text')
          .attr('transform', 'rotate(-65)')
          .style('text-anchor', 'end')
          .attr('dx', '-.9em')
          .attr('dy', '-.2em');
      //update y-axis
      svg.select('.y.axis')
          .transition()
          .duration(animDuration)
          .call(yAxis).select('text')
          .transition()
          .duration(animDuration)
          .text(getYAxisLegend());
      //update time cursor position
      svg.select('.time-cursor')
          .transition()
          .duration(animDuration)
          .attr('width', Math.max(x(now) + margin.left * 2, 0));

      var thresholdVisitbility = function () {
        if ($scope.graphType.type === 0 || $scope.graphType.type === 1) {
          return '';
        } else {
          return 'hidden';
        }
      };

      ticks = computeTicks(x.domain()[0], x.domain()[1], 60);
      svg.select('.threshold-step')
          //.transition()  //no transition, it feels weird on lines
          //.duration(animDuration)
          .attr('d', thresholdLine(ticks))
          .attr('visibility', thresholdVisitbility());

      dots = svg.select('.threshold').selectAll('.dot')
          .data(ticks)
          .attr('visibility', thresholdVisitbility());
      dots.exit().remove();
      dots.enter()
          .append('circle')
          .attr('class', 'dot')
          .attr('r', 1.5)
          .call(drag);

      dots
          //.transition()  //no transition
          //.duration(animDuration)
          .attr('class', function (d) {
            var step = hourOftheDate(d),
                active = _.findIndex($scope.thresholds, {
                  step: step
                });
            return 'dot' + (active >= 0 ? ' active' : '');
          })
          .attr('cx', thresholdLine.x())
          .attr('cy', thresholdLine.y());


      if (data.length > 1) {
        barWidth = Math.max(Math.abs(x(data[1].x) - x(data[0].x)), 0);
      }

      bars = svg.select('.data-pane').selectAll('.bar').data(data, function (d) {
        return d.x;
      });

      //insert new data
      enterBars = bars.enter()
          .append('g')
          .attr('class', 'bar')
          .style('opacity', 0.2)
          .attr('transform', function (d) {
            //return 'translate(' + x(d3.time.second.offset(d.x, -d.dx / 1000)) + ', 0 )';
            return 'translate(' + x(d.x) + ', 0 )';
          }).on('click', function (d, i) {
            var node = d3.select(this);
            //deselect previous bar
            svg.select('.data-pane')
                .selectAll('.active').classed('active', false);
            if ($scope.selected && +$scope.selected.x === +d.x) {
              //unselect
              $scope.selected = [];
            } else {
              if (!node.classed('past')) {
                $scope.selected = d;
                node.classed('active', true);
              }
            }
            $scope.$applyAsync(function () {
              self.draw();
              setTimeout(function () {
                $('.schedule-container').scrollTo('.active', {
                  duration: 200,
                  toTop: true,
                  offset: {
                    top: 3
                  }
                });
              }, 10);
            });
          });
      enterBars.append('rect')
          .attr('class', 'scheduled')
          .attr('height', 0)
          .attr('x', 0.5)
          .attr('y', height || 0);
      enterBars.append('rect')
          .attr('class', 'flying')
          .attr('height', 0)
          .attr('x', 0.5)
          .attr('y',  height || 0);
      enterBars.append('text')
          .attr('class', 'value')
          .attr('text-anchor', 'middle')
          .attr('y',  height || 0)
          .attr('dy', '.75em');
      enterBars.append('text')
          .attr('class', 'extradata')
          .attr('text-anchor', 'start')
          .attr('y',  height || 0)
          .attr('dy', '.70em');


      //
      //remove sub division data
      //
      exitBars = bars.exit();
      exitBars.select('text').text('');
      exitBars
          .select('rect.scheduled')
          .transition()
          .duration(animDuration)
          .attr('width', barWidth - 1)
          .attr('y', function (d) {
            return y(getYValue(y));
          })
          .attr('height', function (d) {
            return height - y(getYValue(y));
          });
      exitBars.select('rect.flying')
          .transition()
          .duration(animDuration)
          .attr('width', barWidth - 1)
          .attr('y', function (d) {
            return y(getYValue(d, true));
          })
          .attr('height', function (d) {
            return height - y(getYValue(d, true));
          });
      exitBars
          .transition()
          .duration(animDuration)
          .style('opacity', 0.1)
          .attr('transform', function (d) { //move along x axis to
            return 'translate(' + x(d3.time.second.offset(d.x, -d.dx / 1000)) + ', 0 )';
          })
          .remove();

      //
      //update existing data
      //
      bars
          .transition()
          .duration(animDuration)
          .style('opacity', 1)
          .attr('class', computeClass(data))
          .attr('transform', function (d) { //move along x axis
            return 'translate(' + x(d.x) + ', 0 )';
          });

      bars.select('rect.scheduled') // update bar rectangle
          .transition()
          .duration(animDuration)
          .attr('width', barWidth - 2)
          .attr('y', function (d) {
            return y(getYValue(d));
          })
          .attr('height', function (d) {
            return height - y(getYValue(d));
          });
      bars.select('rect.flying') // update bar rectangle
          .transition()
          .duration(animDuration)
          .attr('width', barWidth - 2)
          .attr('y', function (d) {
            return y(getYValue(d, true));
          })
          .attr('height', function (d) {
            return height - y(getYValue(d, true));
          });

      bars.select('text.value')
          .text(function (d) { // update text
            return getYValue(d) > 0 ? getYValue(d) : '';
          })
          .transition()
          .duration(animDuration)
          .attr('x', barWidth / 2)
          .attr('y', function (d) {
            return y(getYValue(d)) - 10;
          });

      bars.select('text.extradata')
          .text(function (d) { // update text
            return this.getExtraDataText(d);
          }.bind(this))
          .transition()
          .duration(animDuration)
          .style('text-anchor', 'middle')
          .attr('x', barWidth / 2)
          .attr('y', function (d) {
            return y(d.y) - 20;
          });
      /* bars.select('text.avg')
         .text(function (d) { // update text
           return this.getAverageHoldingText(d);
         }.bind(this))
         .transition()
         .duration(animDuration)
         .attr('x', 1)
         .attr('y', function (d) {
           return y(d.y) + 1;
         });
       bars.select('text.max')
         .text(function (d) { // update text
           return this.getMaxHoldingText(d);
         }.bind(this))
         .transition()
         .duration(animDuration)
         .attr('x', 1)
         .attr('y', function (d) {
           return y(d.y) + 8;
         });
       bars.select('text.total')
         .text(function (d) { // update text
           return this.getTotalHoldingText(d);
         }.bind(this))
         .transition()
         .duration(animDuration)
         .attr('x', 1)
         .attr('y', function (d) {
           return y(d.y) + 16;
         });*/

      if (d3.event && d3.event.sourceEvent) {
        d3.event.sourceEvent.preventDefault();
        d3.event.sourceEvent.stopPropagation();
      }
    };

    this.refreshData = function (airport) {
      if (airport && airport.icao) {
        $scope.thresholds = userService.getUserPreference('airportThresholds')[airport.icao] || [];
        flightNgStore.getAirportFlightsOnArrival(airport.icao).then(function (rawFlights) {
          var filteredFlights, sortedFlights, now, previousLandingTime, holdingDatas,
              first = true;
          holdingDatas = {};
          now = moment();
          //remove flights without eta
          filteredFlights = _.filter(rawFlights, function (f) {
            return !!f.landingTime;
          });
          sortedFlights = _.sortBy(filteredFlights, function (flight) {
            return flight.landingTime || flight.sta;
          });

          function addHoldingLandingTime(element, index, array) {
            if (element.landingTime.isAfter(now)) {
              var threshold = 0,
                  holding,
                  holdingData,
                  nextLandingTime,
                  initLanding;
              //interval =  $scope.timeInterval;

              //Store landing time to be able to get holding length
              initLanding = (element.landingTime || element.sta);

              //Define next landingTime
              nextLandingTime = (previousLandingTime || initLanding).clone();
              threshold = thresholdForStep(nextLandingTime.hours() * 60).value;
              if (threshold !== 0) {
                if (first) {
                  holding = 0;
                  first = false;
                } else {
                  holding = _.ceil(60 / threshold, 2);
                }

                nextLandingTime.add(holding, 'minutes');
                threshold = thresholdForStep(nextLandingTime.hours() * 60).value;
              } else if (first) {
                first = false;
              }
              var i = 0;
              if (threshold === 0) {
                while (threshold === 0) {
                  threshold = thresholdForStep(nextLandingTime.hour() * 60).value;
                  if (threshold === 0) {
                    nextLandingTime.subtract(nextLandingTime.minutes(), 'minutes');
                    nextLandingTime.subtract(nextLandingTime.seconds(), 'seconds');
                    nextLandingTime.add(1, 'hour');
                  }
                  i++;
                  if (i > 24) {
                    return;
                  }
                }
              }

              if (!element.holdingLandingTime) {
                if (nextLandingTime) {
                  //lastEta.add(holding, 'minutes');
                  if (nextLandingTime.isBefore(initLanding)) {
                    element.holdingLandingTime = initLanding;
                  } else {
                    element.holdingLandingTime = nextLandingTime;
                  }
                } else {
                  element.holdingLandingTime = initLanding;
                }
              }
              //Store previous landing time
              previousLandingTime = element.holdingLandingTime.clone();

              //Calculate holding by  comparing the new landing time with initial landing time
              element.holding = element.holdingLandingTime.diff(initLanding, 'minutes', false);

              //Retrieve holding data and update it
              if (holdingDatas[initLanding.format('DDHH')]) {
                holdingData = holdingDatas[initLanding.format('DDHH')];
              } else {
                holdingData = {
                  total: 0,
                  max: 0,
                  number: 0,
                  average: 0
                };
                holdingDatas[initLanding.format('DDHH')] = holdingData;
              }
              holdingData.total += element.holding;
              holdingData.max = holdingData.max < element.holding ? element.holding : holdingData.max;
              holdingData.number++;
              holdingData.average = holdingData.total / holdingData.number;
            }
          }
          sortedFlights.forEach(addHoldingLandingTime);
          $scope.holdingDatas = holdingDatas;
          return sortedFlights;
        }).then(function (sortedFlights) {
          this.updateGraphData(sortedFlights);
        }.bind(this), function () {
          //on error
          this.updateGraphData([]);
        }.bind(this));
      } else {
        //empty the graph
        this.updateGraphData([]);
      }
    };



    $scope.zoom = function (direction) {
      var current = zoom.scale(),
          newZoom = current + direction;
      if (1 <= newZoom && newZoom <= 3) {
        $scope.selected = []; //clear selection
        zoom.scale(newZoom);
        self.updateGraphInterval(Math.pow(2, Math.round(3 / newZoom) - 1) * minInterval);
      }
    };
    $scope.reset = function () {
      zoom.translate([0, 0]);
      self.draw(true);
    };

    $scope.clear = function () {
      saveThresholds([]);
      self.draw();
    };

    $scope.isSelected = function (flight) {
      return $scope.selected && !_.isUndefined(_.find($scope.selected, {
        id: flight.id
      }));
    };

    $scope.selectGraphType = function (graphType) {
      $scope.graphType = graphType;
    };

    $scope.isGraphSelected = function (graphType) {
      return $scope.graphType.type === graphType.type;
    };

    $scope.selectExtraData = function (extraData) {
      $scope.extraData = extraData;
    };


    $scope.isExtraDataSelected = function (extraData) {
      return $scope.extraData.type === extraData.type;
    };


    //first data
    // updateData($scope.airport);
    //update every 5sec
    updater = setInterval(function () {
      this.refreshData($scope.airport);
    }.bind(this), 5000);

    $scope.$watch('airport', function (newAirport, oldAirport) {
      this.refreshData(newAirport);
    }.bind(this));
    $scope.$watch('otherAreDisplayed', $scope.refreshFilter);

    $scope.$watch('graphType', $scope.refreshFilter);

    $scope.$watch('extraData', $scope.refreshFilter);

    //clear update timer on destroy
    $scope.$on('$destroy', function () {
      clearInterval(updater);
    });


  }
]);
