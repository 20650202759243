/*jslint browser: true, plusplus:true*/
/*global App, $, angular */

(function (global) {
  'use strict';

  function AdminAlertFormCtrl($scope, $state, $stateParams, $uibModal, $uibModalInstance, alert, alertNgStore) {

//    // Add sms dialog
//    $scope.addSms = function () {
//      $uibModal.open({
//        templateUrl: 'views/partials/admin/admin-alert-add-form.html',
//        resolve: {
//          sms: function () {
//            return null;
//          },
//          smsList: function () {
//            return $scope.alert.phones;
//          }
//        },
//        controller: 'AdminAlertSmsFormCtrl'
//      }).result['finally'](function () {
//        $state.go('admin.alert');
//      });
//    };
//
//    // Edit sms dialog
//    $scope.editSms = function (sms) {
//
//      $scope.editedSms = sms;
//
//      $uibModal.open({
//        templateUrl: 'views/partials/admin/admin-alert-add-form.html',
//        resolve: {
//          sms: function () {
//            return $scope.editedSms;
//          },
//          smsList: function () {
//            return $scope.alert.phones;
//          }
//        },
//        controller: 'AdminAlertSmsFormCtrl'
//      }).result['finally'](function () {
//        $state.go('admin.alert');
//      });
//    };
//
//    //remove to the real data holder
//    $scope.removeSms = function removeItem(sms) {
//      var index, r = global.confirm('Are you sure to delete this Sms contact ?');
//      if (r) {
//        index = $scope.alert.phones.indexOf(sms);
//        if (index > -1) {
//          $scope.alert.phones.splice(index, 1);
//        }
//      }
//    };

    // Add mail dialog
    $scope.addMail = function () {
      $uibModal.open({
        templateUrl: 'views/partials/admin/admin-alert-add-mail-form.html',
        resolve: {
          mail: function () {
            return null;
          },
          mailList: function () {
            return $scope.alert.emails;
          }
        },
        controller: 'AdminAlertMailFormCtrl'
      }).result['finally'](function () {
        $state.go('admin.alert');
      });
    };

    // Edit mail dialog
    $scope.editMail = function (mail) {

      $scope.editedMail = mail;
      
      $uibModal.open({
        templateUrl: 'views/partials/admin/admin-alert-add-mail-form.html',
        resolve: {
          mail: function () {
            return $scope.editedMail;
          },
          mailList: function () {
            return $scope.alert.emails;
          }
        },
        controller: 'AdminAlertMailFormCtrl'
      }).result['finally'](function () {
        $state.go('admin.alert');
      });
    };

    //remove to the real data holder
    $scope.removeMail = function removeMail(mail) {
      var index, r = global.confirm('Are you sure to delete this Mail contact ?');
      if (r) {
        index = $scope.alert.emails.indexOf(mail);
        if (index > -1) {
          $scope.alert.emails.splice(index, 1);
        }
      }
    };

    $scope.editedAlert = null;
    // Alert to edit (if edition mode)
    $scope.alert = alert;
    $scope.alert.hasNoParameter = false;

    // Unit 
    if ($scope.alert.criteria === "Signal lost") {
      $scope.unit = "min   ( 1 <= value <= 15 )";
    } else if ($scope.alert.criteria === "New destination") {
      $scope.alert.hasNoParameter = true;
    } else if ($scope.alert.criteria === "Minimal flight level during cruise") {
      $scope.unit = "FL   ( 100 <= value <= 200 )";
    } else if ($scope.alert.criteria === "Squawk") {
      $scope.unit = "Squawk";
    } else if ($scope.alert.criteria === "Vertical speed") {
      $scope.unit = "fpm   ( 2500 <= |value| <= 8000 )";
    } else if ($scope.alert.criteria === "ETA") {
      $scope.unit = "min   ( 5 <= value <= 60 )";
    }

    //modify to the real data holder
    $scope.modifyItem = function modifyItem(alertToAdd) {

      $scope.editedAlert = alertToAdd;

      // check if all informations are filled
      alertToAdd.id = alert.id;
      alertToAdd.parameter = document.getElementById('threshold').value;
      alertToAdd.isMailActive = document.getElementById('mailActivated').checked;
      alertToAdd.emails = alert.emails;
//      alertToAdd.isSmsActive = document.getElementById('smsActivated').checked;
//      alertToAdd.phones = alert.phones;
      alertToAdd.isActive = document.getElementById('ruleActivated').checked;
      
      var index, r;
      if ($scope.alert.criteria === "Signal lost") {
        if (alertToAdd.parameter < 1 || alertToAdd.parameter > 15 || isNaN(alertToAdd.parameter)) {
          r = global.alert('Thresold must be between 1 and 15');
          return;
        }
      } else if ($scope.alert.criteria === "Minimal flight level during cruise") {
        if (alertToAdd.parameter < 100 || alertToAdd.parameter > 200 || isNaN(alertToAdd.parameter)) {
          r = global.alert('Thresold must be between 100 and 200');
          return;
        }
      } else if ($scope.alert.criteria === "Vertical speed") {
        if (alertToAdd.parameter > 8000 || alertToAdd.parameter < 2500 || isNaN(alertToAdd.parameter)) {
          r = global.alert('Thresold must be between 2500 and 8000');
          return;
        }
      } else if ($scope.alert.criteria === "ETA") {
        if (alertToAdd.parameter < 5 || alertToAdd.parameter > 60 || isNaN(alertToAdd.parameter)) {
          r = global.alert('Thresold must be between 5 and 60');
          return;
        }
      } else if ($scope.alert.criteria === "Flight Plan Horizontal") {
        if (alertToAdd.parameter < 5 || isNaN(alertToAdd.parameter)) {
          r = global.alert('The minimum thresold is 5NM');
          return;
        }
      } else if ($scope.alert.criteria === "Flight Plan Vertical") {
        if (isNaN(alertToAdd.parameter) || alertToAdd.parameter === '') {
          r = global.alert('Please, define a thresold');
          return;
        }
      }

      alertNgStore.set(alertToAdd);
      $uibModalInstance.close();
    };

    // Cancel action
    $scope.cancelItem = function cancelItem() {
      var form = document.getElementById("formAdminAlert"),
        elements = form.elements,
        i;
      for (i = 0; i < elements.length; ++i) {
        elements[i].required = false;
      }
      $uibModalInstance.close();
    };
  }

  App
    .controller('AdminAlertFormCtrl', ['$scope', '$state', '$stateParams', '$uibModal', '$uibModalInstance', 'alert', 'alertNgStore', AdminAlertFormCtrl]);

}(window));
