/*jslint browser: true, nomen: true */
/*global _, App, Plumbing, Promise, PubSub, GenericStore, angular */

(function (global) {
  'use strict';

  function matches(entity, predicate) {
    var keep = false;
    if (_.isObject(predicate)) {
      if (predicate.or) {
        return _.reduce(predicate.or, function (m, value, key) {
          var subPredicate = {};
          subPredicate[key] = value;
          return m || matches(entity, subPredicate);
        }, false);

      } else if (predicate.and) {
        return _.reduce(predicate.and, function (m, value, key) {
          var subPredicate = {};
          subPredicate[key] = value;
          return m && matches(entity, subPredicate);
        }, true);

      } else {
        _.forIn(predicate, function (wanted, key) {
          var v = _.get(entity, key);
          if (_.isArray(wanted)) {
            keep = _.includes(wanted, v) || keep;
          } else {
            if (v === wanted) {
              keep = true;
            }
          }
        });
        return keep;
      }
    } else {
      return !predicate || entity === predicate;
    }
  }

  function GenericFilteredStore() {
    GenericStore.apply(this);
    this._searchEntities = [];
    this._filteredEntities = [];
    this._filteredEntitiesByIds = {};
    this._searchEntitiesByIds = {};
    this.searchPredicateObj = {};
    this.filterPredicate = null;
    this.filterFunction = matches;
    this.on('sync', this.updateFilteredEntities, this);

    /*this.on('all', function (evt, e) {
      console.log(evt, e);
    }, this);

   this.on('sync:filtered sync:search sync', function (evt, e) {
      console.log('sync', this._entities.length, this._filteredEntities.length, this._searchEntities.length);
    }, this);*/
  }

  GenericFilteredStore.prototype = Object.create(GenericStore.prototype);
  GenericFilteredStore.prototype.constructor = GenericFilteredStore;

  /**
   * Handle entity addition
   */
  GenericFilteredStore.prototype.handleAddition = function (id, values) {
    var e = GenericStore.prototype.handleAddition.apply(this, arguments);
    if (this.filterFunction(e, this.filterPredicate)) {
      this._filteredEntitiesByIds[id] = e;
      this._filteredEntities.push(e);
      this.trigger('add:filtered', e);
    }
    return e;
  };

  /**
   * Handle entity deletion
   */
  GenericFilteredStore.prototype.handleDeletion = function (id) {
    var e = GenericStore.prototype.handleDeletion.apply(this, arguments);
    if (this._filteredEntitiesByIds[id]) {
      delete this._filteredEntitiesByIds[id];
      this._filteredEntities.splice(this._filteredEntities.indexOf(e), 1);
      this.trigger('remove:filtered', e);
    }
    return e;
  };

  /**
   * Handle entity update
   */
  GenericFilteredStore.prototype.handleUpdate = function (id, values, entity) {
    var e = GenericStore.prototype.handleUpdate.apply(this, arguments);
    if (this._filteredEntitiesByIds[id]) {
      if (!this.filterFunction(e, this.filterPredicate)) {
        delete this._filteredEntitiesByIds[id];
        this._filteredEntities.splice(this._filteredEntities.indexOf(e), 1);
        this.trigger('remove:filtered', e);
      }
    } else if (this.filterFunction(e, this.filterPredicate)) {
      this._filteredEntitiesByIds[id] = e;
      this._filteredEntities.push(e);
      this.trigger('add:filtered', e);
    }
    return e;
  };

  /**
   * Refresh the actual data set after a search change
   */
  GenericFilteredStore.prototype.updateFilteredEntities = function (valueChanged, lengthChanged) {
    if (valueChanged || lengthChanged) {
      var filtered = this.$filter ? this.$filter(this._filteredEntities, this.searchPredicateObj) : this._filteredEntities,
          added = _.difference(filtered, this._searchEntities),
          removed = _.difference(this._searchEntities, filtered);
        _.forEach(removed, function (entity) {
          this._searchEntities.splice(this._searchEntities.indexOf(entity), 1);
          this.trigger('remove:search', entity);
        }.bind(this));
        _.forEach(added, function (entity) {
          this._searchEntities.push(entity);
          this.trigger('add:search', entity);
        }.bind(this));

        this.trigger('sync:search', valueChanged, !!(added.length || removed.length));
        this.trigger('sync:filtered', valueChanged, !!(added.length || removed.length));
    } else {
      this.trigger('sync:search', valueChanged, lengthChanged);
      this.trigger('sync:filtered', valueChanged, lengthChanged);
    }
  };

  /**
   * Remove all search filter on a property
   */
  GenericFilteredStore.prototype.removeSearch = function (property) {
    var prop = property || '$';
    delete this.searchPredicateObj[prop];
    this.updateFilteredEntities(false, true);
  };

  /**
   * set a search filter on a property.
   * If property is empty, it will be applied on all properties
   */
  GenericFilteredStore.prototype.search = function (value, property) {
    var prop = property || '$';
    if (value) {
      value = _.isString(value) ? value.trim() : value;
      this.searchPredicateObj[prop] = value;
      this.updateFilteredEntities(false, true);
    } else {
      this.removeSearch(prop);
    }
  };

  /**
   * Set a filter predicate to filter data on the first level
   */
  GenericFilteredStore.prototype.filter = function (predicate) {
    if (!_.isEqual(predicate, this.filterPredicate)) {
      this.filterPredicate = predicate;
      if(this._store){
        this._store.filterPredicate = predicate;
      }
      //force full entities refresh/ filter recalculation
      _.forEach(this._entities, function (entity) {
        this.handleUpdate(this.getId(entity), entity, entity);
      }.bind(this));
    }
  };

  GenericFilteredStore.prototype.unload = function () {
    this._filteredEntitiesByIds = {};
    this._searchEntitiesByIds = {};
    this.filterPredicate = null;
    this._searchEntities.splice(0, this._entities.length); //clear
    this._filteredEntities.splice(0, this._entities.length); //clear
    GenericStore.prototype.unload.apply(this, arguments);
  };

  Object.defineProperties(GenericFilteredStore.prototype, {
    searchEntities: {
      get: function () {
        return this._searchEntities;
      }
    },
    filteredEntities: {
      get: function () {
        return this._filteredEntities;
      }
    }
  });

  global.GenericFilteredStore = GenericFilteredStore;
}(window));
