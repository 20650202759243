App.service('definedAreaService', ['areaModelFactory', 'alertService', 'definedAreaRestService', '$rootScope', 'userRestService', function(areaModelFactory, alertService, definedAreaRestService, $rootScope, userRestService) {
  this.definedAreaEditController = null;
  this.areaListController = null;
  this.closeNavLayer = null;
  this.activeEditArea = areaModelFactory.definedArea(null, null, null, null, null, null);
  this.definedAreas = null;
  this.map = null;
  var selectFeature = null;

  return {
    registerMap: function(map) {
      this.map = map;
    },
    registerEditController: function(areaEditController) {
      this.definedAreaEditController = areaEditController;
    },
    registerListController: function(areaEditController) {
      this.areaListController = areaEditController;
    },
    unregisterEditController: function() {
      this.definedAreaEditController = null;
    },
    unregisterListController: function() {
      this.areaListController = null;
    },
    registerCloseNavLayer: function(closeNavLayerFunction) {
      this.closeNavLayer = closeNavLayerFunction;
    },
    refreshAreaModel: function() {
      definedAreaRestService.getAreas().then((results) => {
        this.definedAreas = results;
        this.sortDefinedAreas(this.definedAreas);
        this.populateIdsAndAuthors(this.definedAreas);
        this.areaListController.populateVisibilityFromPreferences(this.definedAreas);
        this.areaListController.updateAreas(this.definedAreas);
        this.updateDefinedAreasOnMap(this.map, this.definedAreas.filter(area => area.isVisible == true));
      });
    },
    refreshMap: function() {
      this.areaListController.populateVisibilityFromPreferences(this.definedAreas);
      this.updateDefinedAreasOnMap(this.map, this.definedAreas.filter(area => area.isVisible == true));
    },
    setActiveEditArea: function(area) {
      this.activeEditArea = areaModelFactory.definedArea(area.id, area.name, area.authorId, area.geoJsonData, area.colour, area.shared);
      this.definedAreaEditController.open();
      this.definedAreaEditController.loadArea(this.activeEditArea);
      this.closeNavLayer();
    },
    cloneArea: function(area) {
      // TODO Clone area
      // TODO Show an alert window on a successful/unsuccessful clone
      // TODO Update the area list model
      console.log(`(TODO/REMOVE AFTER IMPLEMENTING) Clone area via service: ${area}`);
    },
    populateIdsAndAuthors: function(areas) {
      let customerId = $rootScope.user.customerId;
      userRestService.getUsers(customerId).then((result) => {
        if(result instanceof Error) {
          alertService.error(result.name, result.message);
          areas.forEach(area => {
            area.authorName = 'Unknown';
            area.areaElementId = _.trim(_.camelCase(area.name) + '_' + _.camelCase(area.authorName));
          });
        } else {
          let userMap = _.keyBy(result, 'id');
          areas.forEach(area => {
            let author = userMap[area.authorId];
            if(_.isEmpty(author, true)) {
              area.authorName = 'Unknown';
            } else {
              area.authorName = author.login;
            }
            area.areaElementId = _.trim(_.camelCase(area.name) + '_' + _.camelCase(area.authorName));
          });
        }
      });
    },
    toggleAreasByIds: function(areaIds) {
      if (!areaIds) {
        return;
      }

      let visibleAreas = [];

      this.definedAreas.forEach(area => {
        area.isVisible = areaIds.includes(area.id);
        if(area.isVisible) {
          visibleAreas.push(area);
        }
      });

      this.areaListController.updateAreas(this.definedAreas);
      this.areaListController.updateHiddenAreas();
      this.areaListController.saveHiddenDefinedAreas();
      this.updateDefinedAreasOnMap(this.map, visibleAreas);
    },
    getActiveEditArea: function() {
      return this.activeEditArea;
    },
    getPrivateAreaIds: function() {
      return this.definedAreas.filter(area => !area.shared).map(a => a.id);
    },
    getActiveAreaIds: function() {
      return this.definedAreas.filter(area => area.isVisible).map(a => a.id);
    },
    updateDefinedAreasOnMap: function(map, areas) {
      const layerName = 'UDA';

      function removeLayerFromMap(map, layerName) {
        map.getLayers().forEach(layer => {
          if (layer.get('name') === layerName) {
            map.removeLayer(layer);
            map.removeInteraction(selectFeature);
          }
        });
      }

      function createFeaturesFromGeoJson(areas) {
        let geoJsonFormatter = new ol.format.GeoJSON();
        let openLayersFeatures = [];

        areas.forEach(area => {
          var wrappedCoordinates = wrapCoordinatesAroundAntimeridian(area.geoJsonData.geometry.coordinates[0]);
          area.geoJsonData.geometry.coordinates.pop();
          area.geoJsonData.geometry.coordinates.push(wrappedCoordinates);

          let parsedFeature = geoJsonFormatter.readFeature(area.geoJsonData, {
            featureProjection: 'EPSG:3857',
            dataProjection: 'EPSG:4326'
          });
          parsedFeature.set('name', area.name);
          parsedFeature.setStyle(new ol.style.Style({
            stroke: new ol.style.Stroke({color: area.colour}),
            fill: new ol.style.Fill({color: area.colour}),
            text: new ol.style.Text({
                text: Utils.truncateLongName(area.name,12),
                font: 'normal 16px "Source Sans Pro"',
                fill: new ol.style.Fill({color: 'white'}),
                offsetY: -20
            })
          }));
          openLayersFeatures.push(parsedFeature);
        });
        

        return openLayersFeatures;
      }

      function wrapCoordinatesAroundAntimeridian(coordinates){
        var adjustedCoordinates = [];
        adjustedCoordinates.push(coordinates[0]);
        var firstLongSign = Math.sign(coordinates[0][0]);
        for(let i = 1; i<coordinates.length; i++){
          if( Math.abs(coordinates[i][0]-coordinates[i-1][0]) > 180 && Math.sign(coordinates[i][0]) != firstLongSign){
           coordinates[i][0] += 360 * firstLongSign;
          }
         adjustedCoordinates.push(coordinates[i]);
       }
       return adjustedCoordinates;
     }

      function addAreasToLayer(areas, map) {
        let vectorSource = new ol.source.Vector({projection: 'EPSG:4326'});
        let vectorLayer = new ol.layer.Vector({
          source: vectorSource,
          resolution: map.getView().getResolution()
        });
        let openLayersFeatures = createFeaturesFromGeoJson(areas);
 

        vectorLayer.set('name', layerName);
        vectorSource.addFeatures(openLayersFeatures);
        map.addLayer(vectorLayer);
        addSelectInteraction(map, vectorLayer);
      }

      function addSelectInteraction(map, areaLayer) {
        selectFeature = new ol.interaction.Select({
            layers: [areaLayer],
            condition: ol.events.condition.pointerMove
        });
        selectFeature.on('select', function(e) {
            var selected = e.selected;
            var deselected = e.deselected;
            if (selected.length) {
                selected.forEach(feature => {
                    feature.getStyle().getText().setText(feature.get('name'));
                });
            }
            if(deselected.length) {
                deselected.forEach(feature => {
                    feature.getStyle().getText().setText(Utils.truncateLongName(feature.get('name'), 12));
                });
            }
        });
        map.addInteraction(selectFeature);
      }

      function updateDefinedAreasOnMap(map) {
          removeLayerFromMap(map, layerName);
          addAreasToLayer(areas, map);
      }
     
      updateDefinedAreasOnMap(map);
    },
    sortDefinedAreas: function (definedAreas) {
      definedAreas.sort((definedArea1, definedArea2) => {
        // private defined areas to appear 1st in list
        if (definedArea1.shared < definedArea2.shared) return -1;
        if (definedArea1.shared > definedArea2.shared) return 1;

        // alphabetically sort
        if (definedArea1.name.toLowerCase() < definedArea2.name.toLowerCase()) return -1;
        if (definedArea1.name.toLowerCase() > definedArea2.name.toLowerCase()) return 1;

        return 0;
      });
    }
  };

}]);
