/*jslint browser: true, vars: true, plusplus: true, nomen: true */
/*global App, $, angular, ol, moment, _, console, Promise, ServerTime, Papa, Map, GeometryUtils  */

(function () {
  'use strict';

  /**
   * Map View Controller
   **/
  function AirspaceMapCtrl($scope, $rootScope, $state, $uibModal, mapPreferences, flightSectionDefinitions, flightNgStore, userService, customerNgStore, airportDataNgStore, featureNgStore, mapStyle) {

    var mc = this,
      realPositionTrailFeat,
      estimatedPositionTrailFeat,
      lastPositionFeat,
      geoFlightLineString,
      customer = customerNgStore.customer,
      parameters = customer.parameters,
      customerColors = parameters.customColors || {},
      styleProvider,
      map,
      displayedBackground = $scope.user.preferences.map,
      showFlownDistanceKm = $scope.user.preferences.displayedData.indexOf('flownDistanceKm') > -1,
      showFlownDistanceNm = $scope.user.preferences.displayedData.indexOf('flownDistanceNm') > -1,
      viewExtent = ol.proj.get('EPSG:3857').getExtent();

styleProvider = mapStyle;
    mapStyle.init({
      myfleetColor: customerColors.myfleet,
      trackedColor: customerColors.tracked,
      cautionColor: customerColors.caution,
      warningColor: customerColors.warning,
      othersColor: customerColors.other,
      closedColor: customerColors.closed,
    });

    var mapOptions = $scope.mapOptions = $scope.user.preferences.mapOptions;

    styleProvider.setFlightInfoVisible(mapOptions.labels.displayFlightInfo);

    var planesData = {}; //map keyed by sections' names
    var planesLayers = [];
    //reverse iterator because warning must be on top
    _.forEachRight(flightSectionDefinitions, function (s) {
      var section = s.name,
        features,
        source,
        layer,
        flights;

      features = _.chain(flightNgStore.flights)
        .filter('section', section)
        .map('olFeature')
        .value();
      source = new ol.source.Vector({
        features: features
      });
      layer = new ol.layer.Vector({
        title: 'Planes ' + section,
        source: source,
        style: styleProvider.planeStyle.bind(styleProvider)
      });
      planesData[section] = {
        source: source,
        layer: layer
      }; //map
      planesLayers.push(layer);
    });

    var mainAirportVector = new ol.source.Vector({
      features: _.map(airportDataNgStore.largeAirports, 'olFeature')
    });
    var othersAirportVector = new ol.source.Vector({
      features: _.map(airportDataNgStore.mediumAirports, 'olFeature')
    });
    var favoriteAirportVector = new ol.source.Vector();
    var runwayZoneVector = new ol.source.Vector({
      url: 'data/runway-zones.json',
      format: new ol.format.GeoJSON()
    });

    var trailVector = new ol.source.Vector({
      projection: 'EPSG:4326'
    });

    var waypointVector = new ol.source.Vector({
      projection: 'EPSG:4326'
    });

    var measureVector = new ol.source.Vector({
      projection: 'EPSG:4326'
    });

    var drawFeatures = new ol.Collection();
    var drawVector = new ol.source.Vector({
      features: drawFeatures,
      projection: 'EPSG:4326'
    });

    var backgroundlayers = new ol.layer.Group({
      'title': 'Base maps',
      layers: [
        new ol.layer.Tile(mapPreferences.maps[displayedBackground])
      ]
    });

    var projExtent = ol.proj.get('EPSG:3857').getExtent();

    var opacityColor = [0, 0, 0, 1 - mapOptions.brightness];
    var opacityStyle = [new ol.style.Style({
      fill: new ol.style.Fill({
        color: opacityColor
      })
    })];
    var getOpacityStyle = function () {
      return opacityStyle;
    };
    var opacityFeature = new ol.Feature({
      id: 'opacityFeature',
      geometry: new ol.geom.Polygon([
        [
          ol.extent.getBottomLeft(projExtent),
          ol.extent.getBottomRight(projExtent),
          ol.extent.getTopRight(projExtent),
          ol.extent.getTopLeft(projExtent),
          ol.extent.getBottomLeft(projExtent)
        ]
      ])
    });
    var opacitySource = new ol.source.Vector({
      features: new ol.Collection([opacityFeature])
    });

    var opacityBgLayer = new ol.layer.Vector({
      //opacity: 0.5,
      visible: true,
      source: opacitySource,
      style: getOpacityStyle,
      updateWhileAnimating: true,
      updateWhileInteracting: true
    });

    var opacitySelectionLayer = new ol.layer.Vector({
      //aopacity: 0.5,
      visible: false,
      source: opacitySource,
      style: getOpacityStyle,
      updateWhileAnimating: true,
      updateWhileInteracting: true
    });

    function findFlightFeature(id) {
      var feature;
      _.forEach(planesData, function (data) {
        if (data.layer.getVisible()) {
          feature = data.source.getFeatureById(id);
          if (feature) {
            return false; // break forEachLoop
          }
        }
      });
      return feature;
    }

    function updateFlights(tab) {

      if (!!tab) {
        _.forEach(planesData, function (data) {
          data.source.clear();
        });

        _(flightNgStore.flights)
          .groupBy('section')
          .forEach(function (arrayofFlight, section) {
            var flights = [];
            if (section === 'closed') {
              flights = [];
            } else {
              _.forEach(arrayofFlight, function (flight) {
                if (tab.indexOf(flight.flightId) !== -1) {
                  flights.push(flight);
                }
              });
            }
            planesData[section].source.addFeatures(_.map(flights, 'olFeature'));
          });
      }
    }

    function updateFeatures(updated, resized) {
      if (updated || resized) {
        drawVector.clear();
        _.forEach(featureNgStore.myFeatures, function (featureNg) {
          if (!featureNg.isEdited && featureNg.isVisible) {
            drawVector.addFeature(featureNg.olFeature);
          }
        });
        _.forEach(featureNgStore.othersFeatures, function (featureNg) {
          if (!featureNg.isEdited && featureNg.isVisible) {
            drawVector.addFeature(featureNg.olFeature);
          }
        });
      }
    }

    var mainAirportLayer = new ol.layer.Vector({
      title: 'Airports',
      source: mainAirportVector,
      style: styleProvider.airportStyle.bind(styleProvider),
      maxResolution: 2446
    });
    var othersAirportLayer = new ol.layer.Vector({
      title: 'Airports',
      source: othersAirportVector,
      style: styleProvider.airportStyle.bind(styleProvider),
      maxResolution: 612
    });
    var favoriteAirportLayer = new ol.layer.Vector({
      title: 'Airports',
      source: favoriteAirportVector,
      style: styleProvider.favoriteAirportStyle.bind(styleProvider),
      maxResolution: 2446
    });
    var runwayZoneLayer = new ol.layer.Vector({
      title: 'Runway Zone',
      source: runwayZoneVector,
      style: styleProvider.runwayStyle.bind(styleProvider),
      maxResolution: 39,
      visible: mapOptions.labels.runwayDetectionZone
    });

    var drawLayer = new ol.layer.Vector({
      title: 'Draw',
      source: drawVector,
      style: styleProvider.drawStyle.bind(styleProvider),
      updateWhileAnimating: true,
      visible: false
    });

    var layers = [];

    layers.push(backgroundlayers);
    layers.push(opacityBgLayer);
    layers.push(runwayZoneLayer);
    layers.push(othersAirportLayer);
    layers.push(mainAirportLayer);
    layers.push(favoriteAirportLayer);

    //Add plane layers
    _.forEach(planesLayers, function (p) {
      layers.push(p);
    });

    layers.push(opacitySelectionLayer);
    layers.push(drawLayer);


    var mapCenterJSON = /*localStorage.getItem('mapCenter') ||*/ '[1037104, 4393016]';
    var mapCenter = JSON.parse(mapCenterJSON);
    var mapResolutionJSON = /*localStorage.getItem('mapResolution') ||*/ '9784';
    var mapResolution = JSON.parse(mapResolutionJSON);

    map = mc.map = $scope.map = window.map = new ol.Map({
      target: 'map',
      layers: layers,
      interactions: ol.interaction.defaults({
        doubleClickZoom: false,
        altShiftDragRotate: false,
        pinchRotate: false
      }),
      controls: new ol.Collection([
        new ol.control.ScaleLine()
      ]),
      view: new ol.View({
        center: mapCenter,
        minZoom: 2,
        maxZoom: 17,
        resolution: mapResolution,
        extent: viewExtent,
        projection: 'EPSG:3857'
      })
    });


    var airportPopover = new ol.Overlay({
      element: document.getElementById('airport-popover')
    });
    map.addOverlay(airportPopover);

    var favoriteAirportPopover = new ol.Overlay({
      element: document.getElementById('favorite-airport-popover')
    });
    map.addOverlay(favoriteAirportPopover);

    var planePopover = new ol.Overlay({
      element: document.getElementById('plane-popover')
    });
    map.addOverlay(planePopover);

    var pirepPopover = new ol.Overlay({
      element: document.getElementById('pirep-popover')
    });
    map.addOverlay(pirepPopover);

    var airmetPopover = new ol.Overlay({
      element: document.getElementById('airmet-popover')
    });
    map.addOverlay(airmetPopover);

    var sigmetPopover = new ol.Overlay({
      element: document.getElementById('sigmet-popover')
    });
    map.addOverlay(sigmetPopover);


    mc.selectedFeatureOverlay = new ol.layer.Vector({
      map: map,
      source: new ol.source.Vector({
        features: new ol.Collection(),
        useSpatialIndex: false // optional, might improve performance
      }),
      style: styleProvider.selectedPlaneStyle.bind(styleProvider),
      updateWhileAnimating: true, // optional, for instant visual feedback
      updateWhileInteracting: true // optional, for instant visual feedback
    });

    var zoomByDelta = function (delta) {
      var map = mc.map;
      var view = map.getView();
      var currentResolution = view.getResolution();
      map.beforeRender(ol.animation.zoom({
        resolution: currentResolution,
        duration: 250,
        easing: ol.easing.easeOut
      }));
      var newResolution = view.constrainResolution(currentResolution, delta);
      view.setResolution(newResolution);
    };

    mc.getFavoriteAirports = function (text) {
      var regExp, icaoIata, icaoIataList = [];
      regExp = /\b\w{3,4}\b/g;
      while ((icaoIata = regExp.exec(text)) !== null) {
        if (icaoIata.index === regExp.lastIndex) {
          regExp.lastIndex++;
        }
        icaoIataList.push(icaoIata[0]);
      }
      return airportDataNgStore.findAirportsFromIcaoIataList(icaoIataList);
    };

    mc.updateAirportVisibility = function (option) {
      mainAirportLayer.setVisible(option.airports.large);
      othersAirportLayer.setVisible(option.airports.medium);
      favoriteAirportLayer.setVisible(option.airports.favorite);
    };

    mc.updateAirportVisibility(mapOptions);

    $scope.zoomIn = function () {
      zoomByDelta(1);
    };

    $scope.zoomOut = function () {
      zoomByDelta(-1);
    };


    //
    //Register for change listeners
    //

    //listen for drawing
    featureNgStore.on('sync', updateFeatures, mc);
    featureNgStore.refresh();

    //listen for admin configuration updates
    customerNgStore.on('update', function (updatedCustomer) {
      customer = customerNgStore.customer;
      parameters = customer.parameters;
      if (!_.isEqual(customerColors, parameters.customColors)) {
        customerColors = parameters.customColors;
        styleProvider.init({
          myfleetColor: customerColors.myfleet,
          trackedColor: customerColors.tracked,
          cautionColor: customerColors.caution,
          warningColor: customerColors.warning,
          othersColor: customerColors.other,
          closedColor: customerColors.closed,
        });
      }
    }, mc);
    //listen for user preferences changes
    userService.on('update', function () {
      if (displayedBackground !== $scope.user.preferences.map) {
        displayedBackground = $scope.user.preferences.map;
        backgroundlayers.getLayers().clear();
        backgroundlayers.getLayers().push(new ol.layer.Tile($scope.configuration.maps[displayedBackground]));
      }
      showFlownDistanceKm = $scope.user.preferences.displayedData.indexOf('flownDistanceKm') > -1;
      showFlownDistanceNm = $scope.user.preferences.displayedData.indexOf('flownDistanceNm') > -1;
    }, mc);

    airportDataNgStore.on('sync', function () {
      othersAirportVector.clear();
      mainAirportVector.clear();
      othersAirportVector.addFeatures(_.map(airportDataNgStore.mediumAirports, 'olFeature'));
      mainAirportVector.addFeatures(_.map(airportDataNgStore.largeAirports, 'olFeature'));
     // $scope.updateFavoriteAirports($scope.user.preferences.favoriteAirports);
    }, mc);


    $scope.$watch('flightIdsTab', function (newFlightIdsTab, oldFlightIdsTab) {
      updateFlights(newFlightIdsTab);
    }.bind(this));

    $scope.$watch('airspace', function (newAirspace, oldAirspace) {
      window.map.getView().fit(newAirspace.olFeature.getGeometry(), window.map.getSize());
    }.bind(this));

    $scope.$on('$destroy', function () {
      userService.off(null, null, mc);
      flightNgStore.off(null, null, mc);
      customerNgStore.off(null, null, mc);
      airportDataNgStore.off(null, null, mc);
      featureNgStore.off(null, null, mc);
      featureNgStore.off(null, null, mc);
    });

  }

  App
    .controller('AirspaceMapCtrl', ['$scope', '$rootScope', '$state', '$uibModal', 'mapPreferences', 'flightSectionDefinitions', 'flightNgStore', 'userService', 'customerNgStore', 'airportDataNgStore',
      'featureNgStore', 'mapStyle',
      AirspaceMapCtrl
      ]);

}());
