/*jslint browser: true, plusplus:true*/
/*global App, $, angular */

(function (global) {
  'use strict';

  function FavoriteAirportsFormCtrl($scope, $uibModalInstance, favoriteAirports, userService, navLayersService) {

    $scope.icaoIataList = favoriteAirports;

    $scope.save = function () {
      userService.savePreferences({
        favoriteAirports: $scope.icaoIataList
      })['catch'](function () {
        //reload last preferences in DB
        $scope.icaoIataList = $scope.user.preferences.favoriteAirports;
        $scope.$apply();
      });
      $uibModalInstance.close();
    };

    $scope.cancel = function () {
      userService.savePreferences({
        favoriteAirports: favoriteAirports
      })['catch'](function () {
        //reload last preferences in DB
        $scope.icaoIataList = $scope.user.preferences.favoriteAirports;
        $scope.$apply();
      });
      $uibModalInstance.close();
    };
  }

  App
    .controller('FavoriteAirportsFormCtrl', ['$scope', '$uibModalInstance', 'favoriteAirports', 'userService', 'navLayersService', FavoriteAirportsFormCtrl]);

}(window));
