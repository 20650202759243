/*jslint browser: true*/
/*global App */
App.directive('utcDisplay', function () {
  'use strict';
  return {
    restrict: 'A',
    priority: 1,
    require: ['ngModel', 'uibTimepicker'],
    link: function (scope, element, attrs, ctrl) {
      ctrl[0].$formatters.push(function (value) {
        var date = new Date(value);
        if (isNaN(date) || (value === null)) {
          return value; // needed for null/empty/undefined values
        }

        date.setMinutes(date.getTimezoneOffset()); // forces the "local" version of the date to match the intended UTC date
        return date;
      });

      ctrl[0].$parsers.push(function (value) {
        var date = new Date(value.getTime() - (60000 * value.getTimezoneOffset()));
        return date;
      });
    }
  };
});
