/*jslint browser: true, nomen: true */
/*global App, Plumbing, moment, Promise, GenericStore, angular, console */

App.provider('notificationNgStore', [function notificationNgStoreProvider() {
  'use strict';

  function NotificationNgStore() {
    GenericStore.call(this);
    this._useItemId = false;
  }

  NotificationNgStore.prototype = Object.create(GenericStore.prototype);

  angular.extend(NotificationNgStore.prototype, {
    //
    // Constructor

    constructor: NotificationNgStore,

    //
    // Overridable

    newInstance: function (values) {
      var notification = {
        id: values.id
      };
      this.updateInstance(notification, values);
      return notification;
    },

    updateInstance: function (entity, values) {
      entity.type = values.type;
      entity.properties = values.properties || '{}';
      entity.creationDate = values.creationDate && moment(values.creationDate);
    }

  });

  Object.defineProperties(NotificationNgStore.prototype, {
    notifications: {
      get: function () {
        return this._entities;
      }
    }
  });

  var notificationNgStore = new NotificationNgStore();

  this.$get = ['notificationStore', function notificationNgStoreFactory(notificationStore) {
    notificationNgStore.store = notificationStore;
    return notificationNgStore;
  }];

}]);
