/*jslint browser: true, nomen: true
 */
/*global _, $, App, moment, util */
App.controller('AirportSurfaceTrackingController', ['$scope', 'airportDataNgStore', '$state', '$stateParams', 'flightNgStore', 'defaultColors', 'customerNgStore', '$rootScope', 'mapPreferences', 'mapStyle', function ($scope, airportDataNgStore, $state, $stateParams, flightNgStore, defaultColors, customerNgStore, $rootScope, mapPreferences, mapStyle) {
    'use strict';

    const ST_PLANES_LAYER = 'surfacingTrackingPlanesLayer';

    var vm = this;
    vm.mapCenter;
    vm.defaultZoom = 13;
    vm.map;
    vm.params = $stateParams;
    vm.airport = {};
    vm.airportPlaneRetrievalInterval;
    vm.planes = [];
    vm.customer;
    vm.selectedPlanesRegistration = [];
    vm.select = new ol.interaction.Select();
    vm.planeColors ={};
    var styleProvider = mapStyle;

    vm.$onInit = function () {
        vm.customer = customerNgStore.customer;
        let customerParameters = vm.customer.parameters;
        let customColors = customerParameters.customColors || {};

        vm.planeColors.myfleet = customColors.myfleet || defaultColors.myfleet;
        vm.planeColors.tracked = customColors.tracked || defaultColors.tracked;
        vm.planeColors.other = customColors.other || defaultColors.other;

        if (!customerParameters.surfaceTracking) {
            $state.go('airport.search');
        }

        vm.loadMap().then(() => {
            vm.airportPlaneRetrievalInterval = Utils.runAndSetInterval(vm.retrieveAndDrawPlanesAtAirport, 5000);
            vm.addInteraction();
        });
    };

    vm.$onDestroy = function () {
        clearInterval(vm.airportPlaneRetrievalInterval);
    };

    vm.loadMap = function () {
        return airportDataNgStore.loadData(true).then(function () {

            vm.airport = airportDataNgStore.findByIcao(vm.params.iataOrIcao);
            vm.mapCenter = ol.proj.transform([vm.airport.longitude, vm.airport.latitude], 'EPSG:4326', 'EPSG:3857');
            let distanceFromCenter = 0.055;
            let minXminY = ol.proj.transform([vm.airport.longitude-distanceFromCenter, vm.airport.latitude-distanceFromCenter], 'EPSG:4326', 'EPSG:3857');
            let maxXmaxY = ol.proj.transform([vm.airport.longitude+distanceFromCenter, vm.airport.latitude+distanceFromCenter], 'EPSG:4326', 'EPSG:3857');

            vm.map = new ol.Map({
                target: 'surface-map',
                logo: false, // OpenLayers logo when turning on attributions
                layers: [
                    new ol.layer.Tile({
                        source: new ol.source.OSM(mapPreferences.airportMaps)
                    })
                ],
                interactions: ol.interaction.defaults({
                    doubleClickZoom: false,
                    altShiftDragRotate: false,
                    pinchRotate: false
                })
            });

            vm.map.setView(
                new ol.View({
                    center: vm.mapCenter,
                    extent: [minXminY[0], minXminY[1], maxXmaxY[0], maxXmaxY[1]],
                    zoom: vm.defaultZoom,
                    minZoom: vm.defaultZoom,
                    maxZoom: 18
                })
            );
        });

    };

    angular.element(document).ready(function () {
        vm.map.updateSize();
    });

    vm.retrieveAndDrawPlanesAtAirport = function () {
        vm.retrievePlanes().then(() => {
            vm.updateSelectedPlanesRegistrationList();
            vm.drawPlanesOnMap();
        });
    };

    vm.retrievePlanes = function () {
        return flightNgStore.getAircraftPostionsForAirport(vm.params.iataOrIcao).then(planeData => {
            vm.planes = planeData;
        });
    };

    vm.drawPlanesOnMap = function () {
        let planesSourceVector = new ol.source.Vector({});

        vm.planes.forEach(plane => {
            planesSourceVector.addFeature(vm.createPlaneFeature(plane));
        });
        let planesLayer = new ol.layer.Vector({
            name: ST_PLANES_LAYER,
            source: planesSourceVector,
            style: styleProvider.surfaceTrackingPlaneStyle.bind(styleProvider)
        });

        vm.removeAllPlanesFromMap();
        vm.map.addLayer(planesLayer);
    };

    vm.createPlaneFeature = function (plane) {
        let coordinate = ol.proj.transform([plane.longitude, plane.latitude], 'EPSG:4326', 'EPSG:3857');
        let point = new ol.geom.Point(coordinate);
        let fieldsToDisplay = [];
        let registration = plane.registration;
        let flightNumber = plane.flightNumber || '---';
        let ori = plane.ori || '--- ';
        let dst = plane.dst || ' ---';
        fieldsToDisplay.push(registration);
        fieldsToDisplay.push(flightNumber);
        fieldsToDisplay.push(ori+'-'+ dst);

        let planeColor = vm.planeColors.other;

        if (plane.airline && vm.customer.airlines && vm.customer.airlines.includes(plane.airline)) {
            planeColor = vm.planeColors.tracked
        }
        let planeFeature = new ol.Feature({
            geometry: point,
            registration: registration,
            fieldsToDisplay: fieldsToDisplay,
            planeColor: planeColor,
            track: plane.track,
            showPlaneLabel: false
        });
        if (vm.selectedPlanesRegistration.includes(registration)) {
            planeFeature.set('showPlaneLabel', true);
        }

        return planeFeature;
    };

    vm.removeAllPlanesFromMap = function () {
        let layers = vm.map.getLayers().getArray();
        for (let i = layers.length - 1; i >= 0; i--) {
            let layer = layers[i];
            if (layer.get('name') === ST_PLANES_LAYER) {
                vm.map.removeLayer(layer);
            }
        }
    };

    vm.createPlaneStyle = function (color, size) {
        return new ol.style.Style({
            image: generateTextIcon(new ol.style.Text({
                    text: '\ue604',
                    font: 'normal ' + size + ' AirFlight-Pictos',
                    fill: new ol.style.Fill({
                        color: color
                    }),
                    stroke: new ol.style.Stroke({
                        color: 'black',
                        width: 1
                    })
                })
            )
        });
    };

    vm.setTextToStyle = function (style) {
        let textToSet = new ol.style.Text({
            text: '',
            fill: new ol.style.Fill({color: 'black'}),
            stroke: new ol.style.Stroke({color: 'white', width: 1}),
            offsetX: -25,
            offsetY: 20
        });
        style.setText(textToSet);
    };
    vm.addInteraction = function () {
        vm.map.addInteraction(vm.select);
        vm.select.on('select', function (event) {
            vm.select.getFeatures().clear();
            let selectedFeature = event.selected[0];
            if (selectedFeature) {
                let registration = selectedFeature.get('registration');
                let fieldsToDisplay = selectedFeature.get('fieldsToDisplay');
                let index = vm.selectedPlanesRegistration.indexOf(registration);
                let registrationToggledOn = (index > -1);
                if (registrationToggledOn) {
                    vm.selectedPlanesRegistration.splice(index, 1);
                    selectedFeature.set('showPlaneLabel', false);
                } else {
                    if (registration) {
                        vm.selectedPlanesRegistration.push(registration);
                    }
                    selectedFeature.set('showPlaneLabel', true);
                }

                vm.map.getLayers().forEach(layer => {
                    if (layer.get('name') === ST_PLANES_LAYER) {
                        layer.getSource().changed();
                    }
                });
            }
        });
    };
    vm.updateSelectedPlanesRegistrationList = function () {
        if (vm.selectedPlanesRegistration) {
            let planesRegistration = [];
            vm.planes.forEach(plane => {
                if (plane.registration) {
                    planesRegistration.push(plane.registration);
                }
            });
            for (let i = vm.selectedPlanesRegistration.length - 1; i >= 0; i--) {
                let spr = vm.selectedPlanesRegistration[i];
                if (!planesRegistration.includes(spr)) {
                    let index = vm.selectedPlanesRegistration.indexOf(spr);
                    vm.selectedPlanesRegistration.splice(index, 1);
                }
            }
        }
    };

    $scope.recenterMap = function() {
        vm.map.getView().setCenter(vm.mapCenter);
        vm.map.getView().setZoom(vm.defaultZoom);
    }

}]);