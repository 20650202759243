/*jslint browser: true, nomen: true */
/*global App, Plumbing, Promise, _ */

App.provider('airportDataStore', ['customerId', function airportDataStoreProvider(customerId) {
  'use strict';

  var opts, getPath, getTaxiTimePath, store;

  opts = {
    path: {
      customer: '/customer/',
      airport: '/airport',
      taxitime: '/taxitime'
    },
    headers: {
      'Cache-Control': 'max-age=604800'
    }
  };

  customerId.on('update', function (oldId, newId) {
    if (oldId && oldId !== newId) {
      if (_.isUndefined(newId)) {
        store.adapter.stop();
      } else {
        store.refresh();
      }
    }
  }, this);

  getPath = function () {
    return window.backendPrefix + opts.path.airport;
  };

  store = new Plumbing({
    headers: opts.headers,
    url: getPath,
    delay: 12000000
  });

  getTaxiTimePath = function (airportIcao) {
    if (_.isFinite(customerId.get())) {
      return window.backendPrefix + opts.path.customer + customerId.get() + opts.path.airport + '/' + airportIcao + opts.path.taxitime;
    }
  };

  store.getTaxiTimes = function (airportIcao) {
    return new Promise(function (resolve, reject) {
      window.fetch(getTaxiTimePath(airportIcao), {
        method: 'get',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, reject);
        } else {
          reject(response.statusText);
        }
      }, reject);
    }.bind(this));
  };

  store.setTaxiTimes = function (airportIcao, taxiTimes) {
    return new Promise(function (resolve, reject) {
      window.fetch(getTaxiTimePath(airportIcao), {
        method: 'put',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(taxiTimes)
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, reject);
        } else {
          reject(response.statusText);
        }
      }, reject);
    }.bind(this));
  };

  this.$get = [function airportDataStoreFactory() {
    return store;
  }];

}]);
