/*jslint browser: true, nomen: true, plusplus:true */
/*global _, App, Plumbing, Promise, ol, angular, GenericFilteredStore, moment, FlightLevelFormatter, Deviation */

App.provider('intersectionNgStore', [function IntersectionNgStoreProvider() {
  'use strict';

  var transform, intersectionNgStore, DEG2RAD = 0.017453292519943295,
    KNOT2MPERMS = 5.14444444e-4;

  transform = ol.proj.getTransform('EPSG:4326', 'EPSG:3857');

  //Generic definition
  function IntersectionNgStore() {
    GenericFilteredStore.call(this);
  }
  IntersectionNgStore.prototype = Object.create(GenericFilteredStore.prototype);
  intersectionNgStore = new IntersectionNgStore();

  // Intersection Model object
  function IntersectionNg(properties) {
    angular.extend(this, properties);
  }



  //Intersection store definition

  angular.extend(IntersectionNgStore.prototype, {
    _super: GenericFilteredStore.prototype,

    //
    // Constructor

    constructor: IntersectionNgStore,

    //
    // new
    newInstance: function (values) {
      var id = values.id.toString(),
        i = new IntersectionNg({
          id: id
        });
      this.updateInstance(i, values, true);

      return i;
    },

    setPropertiesValues: function (entity, values) {
      entity.points = [];
      _.forEach(values.points, function (point) {
        var intersectionPoint = {
          airspaceId: values.airspaceZoneId,
          airspaceType: values.airspaceType,
          isScheduled: values.scheduled,
          flightId:  values.flightNumber,
          ori: values.originAirport,
          isClosed: values.closed,
          orientation: point.orientation,
          long: point.longitude,
          lat: point.latitude,
          rank: point.rank,
          date: point.intersectionDate && moment(point.intersectionDate),
          observed: point.observed
        };
        entity.points.push(intersectionPoint);
      });
      /*if (values.points.length === 2) {
        if (values.points[0].orientation === "IN") {
          entity.into = {
            orientation: values.points[0].orientation,
            long: values.points[0].longitude,
            lat: values.points[0].latitude,
            rank: values.points[0].rank,
            date: values.points[0].intersectionDate && moment(values.points[0].intersectionDate),
            observed: values.points[0].observed
          };
          entity.out = {
            orientation: values.points[1].orientation,
            long: values.points[1].longitude,
            lat: values.points[1].latitude,
            rank: values.points[1].rank,
            date: values.points[1].intersectionDate && moment(values.points[1].intersectionDate),
            observed: values.points[1].observed
          };
        } else {
          entity.into = {
            orientation: values.points[1].orientation,
            long: values.points[1].longitude,
            lat: values.points[1].latitude,
            rank: values.points[1].rank,
            date: values.points[1].intersectionDate && moment(values.points[1].intersectionDate),
            observed: values.points[1].observed
          };
          entity.out = {
            orientation: values.points[0].orientation,
            long: values.points[0].longitude,
            lat: values.points[0].latitude,
            rank: values.points[0].rank,
            date: values.points[0].intersectionDate && moment(values.points[0].intersectionDate),
            observed: values.points[0].observed
          };
        }
      }*/
    },

    getIntersectionsByAirspace: function (airspaceId) {
      return this._store.fetchIntersectionsByAirspace(airspaceId)
        .then(function (intersections) {
          return intersections.map(function (i) {
            var intersectionNg = new IntersectionNg({
              id: i.id
            });
            this.setPropertiesValues(intersectionNg, i);
            return intersectionNg;
          }, this);
        }.bind(this));
    }
  });

  this.$get = [
    'intersectionStore',
    function intersectionNgStoreFactory(intersectionStore) {
      intersectionNgStore.store = intersectionStore;
      return intersectionNgStore;
    }
  ];

}]);
