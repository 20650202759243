App.provider('airlineDataStore', [function airlineDataStoreProvider() {
  'use strict';

  this.$get = ['userService','airlineRestService','airportCapacityService',function airportDataStoreFactory(userService, airlineRestService,airportCapacityService) {
      var airlineDataStore;
      airlineDataStore = airportCapacityService.populateAirlines();
      return airlineDataStore;
    }];

}]);
