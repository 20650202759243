/*jslint browser: true, plusplus:true, nomen:true*/
/*global App, $, _, MimeTypeHelper, angular */

(function (global) {
  'use strict';

  App
    .controller('AdminAircraftFormCtrl', [
      '$scope',
      '$state',
      '$stateParams',
      '$uibModalInstance',
      'aircraft',
      'aircrafts',
      'fullEdit',
      'aircraftInfoNgStore',
      'dataStore',
      'alertService',
      function AdminAircraftFormCtrl($scope, $state, $stateParams, $uibModal, aircraft, aircrafts, fullEdit, aircraftInfoNgStore, dataStore, alertService) {
        $scope.fullEdit = fullEdit;
        if (!aircraft) {
          $scope.aircraft = _.clone({
            state: 'GROUND_PARK'
          });
        } else {
          // Aircraft to edit (if edition mode)
          $scope.aircraft = _.clone(aircraft);
        }
        
        $scope.editMode = !(!aircraft || (aircraft && aircraft.id === undefined));


        // Aircrafts type list
        $scope.aircraftTypes = dataStore.aircraftModelCodes;

        function checkValidity() {
          var aircraft = $scope.aircraft,
            valid = true;
          //check if another aircaft has the same registation/adressmodess
          _.each(aircrafts, function (a) {
            if (aircraft.id !== a.id && (aircraft.addrModeS === a.addrModeS || aircraft.registration === a.registration)) {
              valid = false;
              return false; //break forEach loop
            }
          });
          return valid;

        }

        $scope.formIsValid = function () {
          var $form = $('#formAdminAircraft');
          return $form.length === 0 || $form[0].checkValidity();
        };
        $scope.submitChanges = function () {
          if (checkValidity()) {
            $scope.$close($scope.aircraft);
          } else {
            $('.modal-dialog').addClass('shake animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
              $('.modal-dialog').removeClass('shake animated');
            });
            alertService.error('Duplicated aircraft', "An aircraft with the same Registration/AdressModeS already exists");
          }

        };

        // Cancel action
        $scope.cancelItem = function cancelItem() {
          var form = document.getElementById("formAdminAircraft"),
            elements = form.elements,
            i;
          for (i = 0; i < elements.length; ++i) {
            elements[i].required = false;
          }
          $scope.$dismiss();
        };
        $scope.isSuperUser = function () {
          return $scope.user.roles.indexOf('SUPER') > -1;
        };
      }
    ]);

}(window));
