/*jslint browser: true, nomen: true */
/*global App, Plumbing, GenericStore, angular */

App.provider('flightReplayNgStore', [function flightReplayNgStoreProvider() {
  'use strict';

  function FlightReplayNgStore() {
    GenericFilteredStore.call(this);
  }

  
  FlightReplayNgStore.prototype = Object.create(GenericFilteredStore.prototype);

  
  angular.extend(FlightReplayNgStore.prototype, {

    //
    // Constructor

    constructor: FlightReplayNgStore,

    //
    // Overridable

    newInstance: function (values) {
      return {
        id: values.id,
        flightNumber: values.flightNumber,
        callSign: values.callSign,
        registration: values.registration,
        from: values.from,
        to: values.to,
        on: values.on,
        off: values.off,
        fcdate: values.fcdate,
        archived: values.archived,
        acType: values.aircraftType
      };
    },

    updateInstance: function (entity, values) {
      entity.id = values.id;
      entity.flightNumber = values.flightNumber;
      entity.callSign = values.callSign;
      entity.registration = values.registration;
      entity.from = values.from;
      entity.to = values.to;
      entity.on = values.on;
      entity.off = values.off;
      entity.fcdate = values.fcdate;
      entity.archived = values.archived;
      entity.acType = values.aircraftType;
    },

    getDownloadFlightUrl: function () {
      return this._store.getDownloadFlightUrl();
    },
    getCompleteFlightReplay: function (flightId) {
      return this._store.getCompleteFlightReplay(flightId);
    }
    
  });



  Object.defineProperties(FlightReplayNgStore.prototype, {
    flightReplays: {
      get: function () {
        return this._entities;
      }
    }
    
  });

  var flightReplayNgStore = new FlightReplayNgStore();

  this.$get = ['$filter', 'flightReplayStore', 'userService', function flightReplayNgStoreFactory($filter,flightReplayStore, userService) {
    flightReplayNgStore.store = flightReplayStore;
    userService.off('login', flightReplayNgStore.refresh, flightReplayNgStore);
    userService.on('login', flightReplayNgStore.refresh, flightReplayNgStore);
    flightReplayNgStore.$filter = $filter('flightFilter');
    return flightReplayNgStore;
  }];

}]);
