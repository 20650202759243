/*jslint browser: true */
/* global _ */

(function (global) {
    'use strict';
    global.Utils = {
        getParamString: function (obj, existingUrl, uppercase) {
            var params = [];
            for (var i in obj) {
                params.push(encodeURI(uppercase ? i.toUpperCase() : i) + '=' + encodeURI(obj[i]));
            }
            return ((!existingUrl || existingUrl.indexOf('?') === -1) ? '?' : '&') + params.join('&');
        },
        explicitSignedString: function (number, unit) {
            var result = '';
            if (_.isNumber(number)) {
                if (number > 0) {
                    result = '+';
                }
                result += number;
                return result + (unit ? ' ' + unit : '');
            } else {
                return null;
            }
        },

        truncateLongName: function (name, maxLength) {
            var truncatedName;
            if (name.length > maxLength) {
                truncatedName = name.substring(0, maxLength) + '...';
            } else {
                truncatedName = name;
            }
            return truncatedName;
        },
        /**
         * Copies the string to clipboard
         * @param stringToCopy
         */
        copyToClipboard: function (stringToCopy) {
            var temp = document.createElement("textarea");
            temp.value = stringToCopy;
            document.body.appendChild(temp);
            temp.select();
            document.execCommand("copy");
            document.removeChild(temp);
        },

        runAndSetInterval: function(func, intervalMillis) {
            func();
            return setInterval(func, intervalMillis);
          }
    };
}(window));
