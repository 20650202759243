/*jslint browser: true, nomen: true */
/*global App, Promise, _ */

App.controller('AdminTemplateAllocationCtrl', ['$scope', 'aircraftInfoNgStore', 'customerNgStore', 'flightfieldDefinitions', 'userProfileNgStore', function (
                                                $scope, aircraftInfoNgStore, customerNgStore, flightfieldDefinitions, userProfileNgStore) {
  'use strict';

  var ctrl = this;
  this.isLoading = true;
  this.availableTemplates = [];
  this.globalTemplates = [];
  this.defaultTemplate = undefined;
  this.selectedTemplates = {}; //map <registration, tpl>


  this.getCustomerTemplateAssociation = function() {
    return customerNgStore.getTemplateAssociation();
  }

  this.getDefaultTemplate = function() {
    return customerNgStore.getDefaultTemplate();
  }

  this.loadCustomerTemplates = function () {
    return customerNgStore.getTemplates().then(function (templates) {
      templates.forEach(function (t) {
        t.templateType = "Airline templates";
        t.fields = _.map(t.fields, function (key) {
          var def = flightfieldDefinitions[key];
          return _.assign({
            key: key
          }, def);
        });
      })
      templates.push({
        id: "",
        templateName: "-- Use default template --"
      });
      return templates;
    });
  };


  this.init = function () {
    this.isLoading = true;
    this.availableTemplates = [];
    Promise.all([
        this.loadCustomerTemplates(),
      ])
      .then(function (templates) {
        this.availableTemplates = _.flatten(templates);
        return this.availableTemplates;
      }.bind(this))
      .then(function () {
        //load aircraft and assign default values
        this.aircrafts = aircraftInfoNgStore.aircrafts;
        this.aircrafts.forEach(function (aircraft) {
          this.selectedTemplates[aircraft.registration] = this.selectedTemplates[aircraft.registration] || {};
        }, this);
      }.bind(this))
      .then(this.getDefaultTemplate)
      .then(function (customerDefaultSelected) {
        this.defaultTemplate = customerDefaultSelected;
        if(this.defaultTemplate.id == undefined){
          this.defaultTemplate.id = "";
        }
      }.bind(this))
      .then(this.getCustomerTemplateAssociation)
      .then(function (customerSelected) {
        _.assign(this.selectedTemplates, customerSelected);
        this.isLoading = false;
        $scope.$evalAsync();
      }.bind(this))
      .catch(function (error) {
        console.error(error);
        this.isLoading = false;
        $scope.$evalAsync();
      }.bind(this))

  }

  this.resetTemplates = function () {
    this.isLoading = true;
    this.aircrafts.forEach(function (aircraft) {
      this.selectedTemplates[aircraft.registration] = {};
    }, this);
    this.isLoading = false;
    $scope.$applyAsync();
  };

  this.submitTemplateAllocation = function () {
    var toSubmit = [customerNgStore.saveTemplateAllocation(this.selectedTemplates)];
    if (this.defaultTemplate != undefined) { //TODO fix
      toSubmit.push(customerNgStore.saveDefaultTemplate(this.defaultTemplate));
    }

    return Promise
      .all(toSubmit)
      .then(function (results) {
       // this.selectedTemplates = results[0];
        _.assign( this.selectedTemplates, results[0])
        userProfileNgStore.refresh();
        $scope.$applyAsync();
      }.bind(this))
      .catch(function (error) {
        console.log(error);
      });
  };

  this.init();
  aircraftInfoNgStore.refresh();

}]);
