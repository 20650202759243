/*jslint browser: true, plusplus:true*/
/*global $, App, Validator, Promise */

(function () {
  'use strict';

  var settingsType = "Password";

  function check1CharAnd1Num(newPassword) {
    var regNum = /[0-9]+/,
      regAlpha = /[A-Za-z]+/;
    if (regNum.exec(newPassword) === null || regAlpha.exec(newPassword) === null) {
      return false;
    }
    return true;
  }

  function checkNotEmail(newPassword) {
    var reg = /^[a-z0-9._-]+@[a-z0-9.-]{2,}[.][a-z]{2,3}$/;
    if (reg.exec(newPassword) !== null) {
      return false;
    }
    return true;
  }

  function resetPasswordForm(passwordForm) {
    passwordForm.oldPassword = "";
    passwordForm.newPassword = "";
    passwordForm.confirmPassword = "";
  }

  function SettingsPasswordCtrl($rootScope, $scope, $state, userProfileNgStore) {

    $scope.passwordForm = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: ""
    };

    $scope.submitChangePassword = function () {
      return new Promise(function (resolve, reject) {
        // Validation
        var formChangePassword = document.getElementById('formChangePassword');
        // Check newPassword = confirmPassword
        if ($scope.passwordForm.newPassword !== $scope.passwordForm.confirmPassword) {
          reject("The new password is not well confirmed !");
        } else {
          // Check 8 characters
          if ($scope.passwordForm.newPassword.length < 8) {
            reject("The new password must have at least 8 characters !");
          } else {
            // Check at least 1 letter and 1 character
            if (!check1CharAnd1Num($scope.passwordForm.newPassword)) {
              reject("The new password must have at least 1 letter and 1 number !");
            } else {
              // Check cannot be an email adress
              if (!checkNotEmail($scope.passwordForm.newPassword)) {
                reject("The new password must not be an email !");
              } else {
                // Check cannot be the login
                if (userProfileNgStore.userProfile !== null && userProfileNgStore.userProfile.login !== null && userProfileNgStore.userProfile.login === $scope.passwordForm.newPassword) {
                  reject("The new password must not be the login !");
                } else {
                  // Save new password
                  $.ajax({
                    dataType: "json",
                    headers: {
                      'Content-Type': "application/json"
                    },
                    type: "PUT",
                    url: window.backendPrefix + "/me/password",
                    data: JSON.stringify({
                      oldPassword: $scope.passwordForm.oldPassword,
                      newPassword: $scope.passwordForm.newPassword
                    }),
                    statusCode: {
                      200: function (response) {
                        resolve("Password successfully updated");
                        resetPasswordForm($scope.passwordForm);
                      },
                      400: function (response) {
                        reject(response.responseText);
                        resetPasswordForm($scope.passwordForm);
                      }
                    },
                    error: function (xhr, status, error) {
                      reject(error);
                      resetPasswordForm($scope.passwordForm);
                    }
                  });
                }
              }
            }
          }
        }

      }.bind(this));
    };
  }

  App
    .controller('SettingsPasswordCtrl', ['$rootScope', '$scope', '$state', 'userProfileNgStore', SettingsPasswordCtrl]);
}());
