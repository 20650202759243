/*jslint browser: true */
/*global angular */

(function (global) {
  'use strict';


  function phase(state, vspeed) {
    var VSPEED_CLAMP = 500;
    if (state !== 'IN_FLIGHT') {
      return 'GROUND';
    } else {
      if (angular.isNumber(vspeed)) {
        if (vspeed > VSPEED_CLAMP) {
          return 'CLIMB';
        } else if (vspeed <= -VSPEED_CLAMP) {
          return 'DESCENT';
        } else {
          return 'CRUISE';
        }
      }
    }
  }

  global.Deviation = {
    phase: phase
  };

}(window));
