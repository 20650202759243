(function (global) {
  'use strict';

  var notDefined = 'Not specified';

  function formatSubtitleOrType(props) {
    if (props) {
      if (!_.isNull(props.class)) {
        return props.bndry_type + ' Class ' + props.class;
      } else {
        return props.bndry_type;
      }
    }
    return notDefined;
  }

  /**
   * return a string with the high or low limit
   * @param {string} limTyp
   * @param {string} limUni
   * @param {number} lim
   */
  function hiLoLimit(limTyp, limUni, lim, isHigh) {
    if (limTyp === 'Altitude') {
      if (limUni === 'AboveMeanSeaLevel') {
        return lim + 'ft AMSL';
      } else if (limUni === 'AboveGroundLevel') {
        return lim + 'ft AGL';
      }
    } else if (limTyp === 'FlightLevel') {
      return 'FL' + lim / 100;
    } else if (limTyp === 'Ground') {
      return 'GND';
    } else if (isHigh && limTyp === 'Unlimited' && lim === 2147483647) {
      return 'UNL';
    }
    return notDefined;
  }

  /**
   * return the limit
   * @param {array} props
   * @param {string} notDefined
   */
  function setLimit(props, notDefined) {
    return hiLoLimit(props.lo_lim_typ, props.lo_lim_uni, props.lo_lim, false) + ' - ' + hiLoLimit(props.up_lim_typ, props.up_lim_uni, props.up_lim, true);
  }

  global.AirspaceFeatureFormatter = {
    format: function (adFeature, projection) {
      var props = adFeature.properties || {};

      var properties = [{
          label: 'Feature',
          value: '<b>Controlled Airspace</b>'
        },
        {
          label: 'Name',
          value: props.name || notDefined
        },
        {
          label: 'Type',
          value: _.upperCase(props.bndry_type) || notDefined
        },
        {
          label: 'Class',
          value: _.upperCase(props.class) || notDefined
        },
        {
          label: 'Ident',
          value: _.upperCase(props.bndry_id) || notDefined
        },
        {
          label: 'Limits',
          value: setLimit(props, notDefined)
        },
        {
          label: 'Time Of Op',
          value: props.time_of_op
        },
      ];

      return {
        type: 'CTL',
        title: formatSubtitleOrType(props),
        subtitle: props.name || '-',
        properties: properties,
      };

    }
  };
}(window));
