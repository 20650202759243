/*jslint browser: true*/
/*global App, $ */

App.controller('AirportDetailsCtrl', ['$scope', '$rootScope', '$state', '$transitions', function ($scope, $rootScope, $state, $transitions) {
  'use strict';

  var unregister$stateChangeSuccess,
    unregister$viewContentLoaded,
    unregister$viewContentLoading;

  //
  // Update iataOrIcao update in parent controller 'AirportPageCtrl'

  $scope.$parent.iataOrIcaoUpdate($state.params.iataOrIcao, $state.params.runway);


  unregister$stateChangeSuccess = $transitions.onSuccess({
    to: 'airspace.capacity.**'
  }, function (trans) {
    if (trans.to().name.indexOf('airport.details') === 0 && $scope.$parent && $scope.$parent.iataOrIcaoUpdate) {
      $scope.$parent.iataOrIcaoUpdate(toParams.iataOrIcao, toParams.runway);
    }

  });

  unregister$viewContentLoading = $rootScope.$on('$viewContentLoading', function (event, viewName) {
    $('#airport-tab-content').removeClass('in active');
  });

  unregister$viewContentLoaded = $rootScope.$on('$viewContentLoaded', function (event, viewName) {
    $('#airport-tab-content').addClass('in active');
  });


  $scope.$on('$destroy', function () {
    unregister$stateChangeSuccess();
    unregister$viewContentLoading();
    unregister$viewContentLoaded();
  });

}]);
