(function (global) {
  'use strict';

  var notDefined = 'Not Defined';

  var categoryLevel = {
    'OHH': 'High',
    'BH': 'High & Low',
    'BL': 'High & Low',
    'OLL': 'Low',
  };

  function formatSubtitleOrType(props, def) {
    if (props) {
      return props === 'C' ? 'Compulsory' : 'Non-Compulsory';
    } else {
      return def;
    }
  }

  global.WaypointFeatureFormatter = {
    format: function (adFeature, projection) {
      var props = adFeature.properties || {};

      var properties = [{
          label: 'Feature',
          value: '<b>ENROUTE WAYPOINT</b>'
        },
        {
          label: 'Ident',
          value: props.ident || notDefined
        },
        {
          label: 'Name',
          value: props.descr_name || notDefined
        },
        {
          label: 'Level',
          value: categoryLevel[props.level] || notDefined
        },
        {
          label: 'Type',
          value: formatSubtitleOrType(props.compulsory, notDefined)
        },
        {
          label: 'Country',
          value: props.country || notDefined
        },
        {
          label: 'LAT',
          value: props.latitude || notDefined
        },
        {
          label: 'LONG',
          value: props.longitude || notDefined
        },
      ];

      return {
        type: 'WPT',
        title: props.ident || '–',
        subtitle: formatSubtitleOrType(props.compulsory, ""),
        properties: properties,
      };

    }
  };
}(window));
