/*jslint browser: true, plusplus:true, nomen:true*/
/*global _, angular, ol, App, Promise */

(function () {
    'use strict';
  
    function SettingsZoomLevelsCtrl($rootScope, $scope, $state, userService, navLayers, defaultPreferences) {

        var vm = this;

        vm.resolutions = [
            2,
            3,
            5,
            10,
            20,
            39,
            77,
            153,
            306,
            612,
            1223,
            2446,
            4892,
            9784,
            19568,
            39136
        ];

        vm.nauticalMiles = [
            0.05,
            0.1,
            0.2,
            0.5,
            1,
            2,
            5,
            5,
            10,
            20,
            50,
            100,
            200,
            500,
            1000,
            2000
        ];

        var vm = this;

        vm.init = function () {

            let staticLayers = navLayers.staticLayers;
            let highLowLayers = navLayers.highLowLayers;
            let bothObjects = {...staticLayers, ...highLowLayers};


            $scope.layerMaxResolutions = JSON.parse(JSON.stringify(defaultPreferences.mapOptions.maxResolutions));
            let maxResolutionKeys = Object.keys(defaultPreferences.mapOptions.maxResolutions);

            vm.mapOptions = userService.getUserPreference('mapOptions');

            maxResolutionKeys.forEach(function (layer) {
                $scope.layerMaxResolutions[layer].maxResolution = vm.mapOptions.maxResolutions[layer].maxResolution;
                $scope.layerMaxResolutions[layer].zoomIndex = vm.mapOptions.maxResolutions[layer].zoomIndex;
                $scope.layerMaxResolutions[layer].label = bothObjects[layer].label;
            });

        }

        $scope.getNauticalMiles = function (layer) {
            return vm.nauticalMiles[layer.zoomIndex];
        }

        $scope.resetToDefault = function () {

            var maxResolutionKeys = Object.keys(defaultPreferences.mapOptions.maxResolutions);

            maxResolutionKeys.forEach( function (key) {
                $scope.layerMaxResolutions[key].maxResolution = defaultPreferences.mapOptions.maxResolutions[key].maxResolution;
                $scope.layerMaxResolutions[key].zoomIndex = defaultPreferences.mapOptions.maxResolutions[key].zoomIndex;
            });
        };

        $scope.saveZoomLevels = function () {

            return new Promise(function (resolve, reject) {

                var maxResolutionKeys = Object.keys(defaultPreferences.mapOptions.maxResolutions);

                maxResolutionKeys.forEach( function (layer) {
                    $scope.layerMaxResolutions[layer].maxResolution = vm.resolutions[$scope.layerMaxResolutions[layer].zoomIndex];
                });

                vm.mapOptions.maxResolutions = $scope.layerMaxResolutions;

                userService.savePreferences({
                    mapOptions: vm.mapOptions
                }).then(function () {
                    resolve("Successfully updated");
                })['catch'](function (error) {
                    reject("Error updating");
                });

            }.bind(this));
        };
        
        vm.init();
    }
  
    App
      .controller('SettingsZoomLevelsCtrl', ['$rootScope', '$scope', '$state', 'userService', 'navLayers', 'defaultPreferences', SettingsZoomLevelsCtrl]);
  }());
  