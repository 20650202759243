App.directive('shDateInput', function () {
  return {
    restrict: 'E',
    scope: {
      onChange: '&',
      startDate: '=',
      endDate: '=?',
      minDate: '=',
      maxDate: '=?',
      weekPicker: '<',
      readonly: '<',
      top: '<'
    },


    template: '<div class="input-group">' +
      ' <input type="text" class="form-control daterange input-sm" ng-required="true" />' +
      // ' <div class="input-group-btn">' +
      // '     <button type="button" class="btn btn-default btn-sm" ng-click="openCalendar()"><i class="glyphicon glyphicon-calendar"></i></button>' +
      // ' </div>' +
      '</div>',
    link: function ($scope, element, attrs) {

      var input = $(element).find('.daterange');
      var options = _.assign({
        startDate: moment($scope.startDate),
        endDate: moment($scope.endDate),
        maxDate: moment($scope.maxDate),
        minDate: moment($scope.minDate),
        //drops: $scope.top ? 'up' : 'down',
        drops: 'down',
        opens: 'left',
        locale: {
          format: 'YYYY/MM/DD',
          firstDay: 1
        }
      }, $(element).data());
      if ($scope.readonly) {
        input.prop('readonly', true).val(options.startDate.format(options.locale.format));
        return;
      }
      input.daterangepicker(options, function (start, end, label) {
        $scope.startDate = start;
        $scope.endDate = end;
        $scope.$apply();
        if (typeof $scope.onChange === 'function') {
          $scope.onChange(start, end);
        }
      });
      if ($scope.weekPicker) {
        var picker = input.data('daterangepicker');
        picker.setStartDate = function (_super) {
          return function (startDate) {
            if (typeof startDate === 'string')
              startDate = moment(startDate, this.locale.format);
            if (typeof startDate === 'object')
              startDate = moment(startDate);
            startDate = startDate.isoWeekday('monday');
            return _super.call(this, startDate);
          };
        }(picker.setStartDate);
      }
      input.on('show.daterangepicker', function (ev, picker) {
        if ($scope.weekPicker) {
          $(picker.container).addClass('week-picker');
          $(picker.container).find('td.active').siblings().addClass('active');
        }

      });

      $scope.openCalendar = function () {
        input.data('daterangepicker').show();
      };
    }
  };
});
