App.factory('routeModelFactory', [function() {
  'use strict';

  class Route {

    constructor(routeString, routeType, routeLegDTOS) {
      this.routeString = routeString;
      this.routeType = routeType;
      this.routeLegDTOS = routeLegDTOS;
    }

    toString() {
      return JSON.stringify(this);
    }
  }

  return {
    route: function (routeString, routeType, routeLegDTOS) {
      return new Route(routeString, routeType, routeLegDTOS);
    }
  };

}]);
