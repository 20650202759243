/*jslint browser: true, plusplus:true */
/*global ol, App */

(function (global) {
  'use strict';
  var geoserverUrl = global.navblueGeoserver + '/geoserver/navblue/wms';
  var serverUrl = window.backendPrefix;
  var navLayersDefs = {
    // Geoserver NCDIS data URL (http://172.16.96.133:8080)

    // N-Tracking zoom level	Resolution (px/m)
    // 1
    // 2	                    39135,75
    // 3	                    19567,87
    // 4	                    9783,93
    // 5	                    4891,96
    // 6	                    2445,98
    // 7	                    1222,99
    // 8	                    611,49
    // 9	                    305,74
    // 10	                    152,87
    // 11	                    76,43
    // 12	                    38,21
    // 13	                    19,1
    // 14	                    9,55
    // 15	                    4,77
    // 16	                    2,38
    // 17	                    1,19

    url: window.navblueGeoserver + '/geoserver/navblue/wms',
    serverType: 'geoserver',
    // layers
    staticLayers: {
      firuir: {
        label: 'FIR/UIR',
        shortLabel: 'FIR',
        description: 'Fir/Uir',
        maxResolution: 2446,
        zIndex: 6,
        url: geoserverUrl,
        type: 'tiled',
        params: {
          layers: 'navblue:firsuirs'
        },
        search: {
          fields: ['identifier']
        }
      },
      gridMoras: {
        label: 'Grid MORAs',
        description: 'Grid MORAs',
        maxResolution: 306,
        zIndex: 9,
        url: geoserverUrl,
        type: 'tiled',
        notQueryable: true,
        params: {
          layers: 'navblue:gridmoras'
        }
      },
      airports: {
        label: 'Airports',
        shortLabel: 'AD',
        description: 'Airports',
        maxResolution: 2446,
        zIndex: 4,
        url: geoserverUrl,
        type: 'tiled',
        params: {
          layers: 'navblue:airports'
        },
        search: {
          fields: ['icao_name', 'iata_name']
        }
      },

      favouriteAirports: {
        label: 'Favorite airports',
        description: 'Favourite airports ',
        editable: true,
        maxResolution: 2446,
        zIndex: 3,
        url: geoserverUrl,
        type: 'tiled',
        params: {
          layers: 'navblue:airports',
          env: 'color:#64ffff'
        },
      },
      groundVehicles: {
        label: 'Ground Vehicles',
        description: 'Ground Vehicles',
        maxResolution: 76.43,
        notQueryable: true,
        zIndex: 3,
        url: serverUrl,
        type: 'vehicle',
        requiresSuperParameter: 'surfaceTracking',
        requiresUserParameter: 'displayAirportSurfaceMap'
      },
    },

    // High layers
    highLowLayers: {
      airways: {
        label: 'Airways',
        shortLabel: 'AWY',
        description: 'Show AWY segments with {LEVEL} "A" and "H" using high-level AWY symbology and colors',
        maxResolution: 612,
        zIndex: 4,
        url: geoserverUrl,
        type: 'tiled',
        params: {
          layers: 'navblue:airways',
          // env: 'color:FF0000',
        },
        search: {
          fields: ['awy_ident']
        }
      },
      waypoints: {
        label: 'Waypoints',
        shortLabel: 'WPT',
        description: 'Show all Waypoints with {LEVEL} “H”, “OH” using high-level AWY symbology and colors',
        maxResolution: 612,
        zIndex: 4,
        highlow: 'high',
        url: geoserverUrl,
        type: 'tiled',
        params: {
          layers: 'navblue:waypoints'
        },
        search: {
          fields: ['ident'],
          layers: ['navblue:waypoints', 'navblue:navaids'], //search both WPT and NAV together
          label: 'WPT/NAV'
        }
      },
      holdings: {
        label: 'Holdings',
        shortLabel: 'HLDG',
        description: 'Holdings',
        maxResolution: 306,
        highlow: 'high',
        url: geoserverUrl,
        type: 'tiled',
        zIndex: 5,
        params: {
          layers: 'navblue:holdings',
        },
      },
      navaids: {
        label: 'Navaids',
        shortLabel: 'NAV',
        description: 'Show all Navaids with {LEVEL} “H”, “OH” using high-level AWY symbology and colors',
        maxResolution: 612,
        zIndex: 4,
        highlow: 'high',
        url: geoserverUrl,
        type: 'tiled',
        params: {
          layers: 'navblue:navaids',
        },
        // searchFields: ['ident'],
      },
      restrictiveAirspace: {
        label: 'Restricted airspace',
        shortLabel: 'SUA',
        description: 'For AWY-HI only show features according the following Criteria: RestrictiveAirspace.dbf attribute [LEVEL] IF {LEVEL} = ”B”, “H”',
        maxResolution: 612,
        zIndex: 5,
        url: geoserverUrl,
        type: 'tiled',
        params: {
          layers: 'navblue:restrictive_airspaces',
        },
      },
      controlledAirspace: {
        label: 'Controlled airspace',
        description: 'Show all Controlled airspaces',
        maxResolution: 306,
        zIndex: 6,
        highlow: 'high',
        url: geoserverUrl,
        type: 'tiled',
        params: {
          layers: 'navblue:controlled_airspaces'
        }
      },
      comAreas: {
        label: 'COM areas',
        description: 'COM areas',
        maxResolution: 612,
        zIndex: 7,
        notQueryable: true,
        url: geoserverUrl,
        type: 'tiled',
        params: {
          layers: 'navblue:com_areas'
        },
      }
    },
  };

  function assignKey(obj, key) {
    obj.key = key;
  }
  //assign key field for all definitions
  _.forEach(navLayersDefs.staticLayers, assignKey);
  _.forEach(navLayersDefs.highLowLayers, assignKey);

  App.constant('navLayers', navLayersDefs);

}(window));
