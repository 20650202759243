/*jslint browser: true, nomen:true */
/*global App, angular, Promise, $, _ */

App.factory('groundVehicleService', [
    'mapStyle',
    'mapPositionService',
    'generalConstants',
    function groundVehicleService(mapStyle, mapPositionService, generalConstants) {
        'use strict';

        var groundVehicleService;

        function GroundVehicleService() {
            this.olFeatures = [];
            this.styleProvider = mapStyle;
            this.timer = null;
            this.lastUpdate = null;
        }

        _.assign(GroundVehicleService.prototype, {
            constructor: GroundVehicleService,

            createLayer: function(layerDefinition) {
              var olSource = new ol.source.Vector({
                loader: function() {
                  groundVehicleService.olFeatures.forEach(feature => {
                    var oldFeature = this.getFeatureById(feature.getId());
                    if (oldFeature === null) {
                      this.addFeature(feature);
                    }
                  });
                },
                strategy: function(extent) {
                  if ((Date.now() - groundVehicleService.lastUpdate) >= 5000) {
                    groundVehicleService.lastUpdate = Date.now();
                    var bbox = mapPositionService.getBoundingBoxString();
                    groundVehicleService.getFeatures(bbox).then(function() {
                      this.clear();
                    }.bind(this));
                  }
                  return [extent];
                }
              });

              var olLayer = new ol.layer.Vector({
                title: layerDefinition.label,
                name: generalConstants.navLayerName,
                source: olSource,
                style: this.styleProvider.groundVehicleStyle.bind(this.styleProvider),
                zIndex: 10 - layerDefinition.zIndex,
                maxResolution: layerDefinition.maxResolution,
                visible: true
              });
              olLayer.set('key', layerDefinition.key);

              this.lastUpdate = 0;
              if (this.timer) {
                clearInterval(this.timer);
              }
              this.timer = setInterval(function() {
                olSource.changed();
              }, 5000);

              return olLayer;
            },

            createFeatures: function(json) {
              if (this.olFeatures.length > 0) {
                this.olFeatures = [];
              }

              if (json !== [] && Array.isArray(json)) {
                var transform = ol.proj.getTransform('EPSG:4326', 'EPSG:3857');
                json.forEach(vehicle => {
                  var feature = new ol.Feature({
                    geometry: new ol.geom.Point(transform([vehicle.longitude, vehicle.latitude])),
                    track: vehicle.heading
                  });
                  feature.setId(vehicle.flightNumber);
                  this.olFeatures.push(feature);
                });
              }
            },

            getFeatures: async function(bbox) {
              var url = "/vehicles?bbox=" + bbox;
              const response = await window.fetch(window.backendPrefix + url, {
                credentials: 'include'
              });
              response.json().then(function (json) {
                groundVehicleService.createFeatures(json);
              }, function () {
                groundVehicleService.createFeatures([]);
              });
            }
        });

        groundVehicleService = new GroundVehicleService();
        return groundVehicleService;
    }
]);