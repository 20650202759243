App.directive('definedAreaNameValidate', function() {
  'use strict';
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, element, attrs, ngModelCtrl){

      function validateAreaName(areaName) {
        let valid = true;

        if (areaName.trim().length === 0) valid = false;
        if (areaName.length > 255) valid = false;

        ngModelCtrl.$setValidity('definedAreaNameValidate', valid);

        return valid ? areaName : undefined;
      }

      ngModelCtrl.$parsers.unshift(function(value){
        return validateAreaName(value);
      });

      ngModelCtrl.$formatters.unshift(function(value){
        return validateAreaName(value);
      });
    }
  };
});
