App.controller('FavoritesAddFormCtrl', ['$scope', '$rootScope','$state', '$stateParams', '$uibModalInstance', 'fav', 'favList', 'userService','favoriteModelFactory','favoriteRestService', 'alertService', 'definedAreaService', 'flightSearchService', function ($scope, $rootScope, $state, $stateParams, $uibModalInstance, fav, favList, userService, favoriteModelFactory, favoriteRestService, alertService, definedAreaService, flightSearchService) {
  'use strict';

  this.name = '';
  const blankFavorite = favoriteModelFactory.favorite(null, null, null, null, null, null, null, null, null);
  const blankMapLayers = favoriteModelFactory.mapLayers(null, null, null, null, null);
  this.activeFavorite = blankFavorite;
  this.activeMapLayers = blankMapLayers;

  this.addFavorite = function () {
    var favorite = this.getActiveFavorite();
    favorite.id =  this.findFavoriteIfExists(favorite, favList);
    if(favorite.id != null) {
          alertService
            .confirm('Save Favorite', `Favorite with name ${favorite.name} already exists. Do you want to overwrite?`)
            .then(() => {
              this.updateFavorite(favorite);
            });
    } else {
        this.createFavorite(favorite);
    }
  };

  this.createFavorite = function(favorite) {
      favoriteRestService.saveFavorite(favorite).then((result) => {
          if (result instanceof Error) {
              alertService.error(result.name, result.message);
              $uibModalInstance.dismiss();
          } else {
              if (favorite.defaultFavorite) {
                  userService.savePreferences({
                      defaultFavoriteId: result.id
                  }).then(function() {
                      $uibModalInstance.close();
                  })['catch'](function() {
                      // reload last preferences in DB
                      defaultFavoriteId = userService.user.preferences.defaultFavoriteId;
                      $uibModalInstance.close();
                  });
              } else {
                  $uibModalInstance.close();
              }
          }
      });
  };

  this.updateFavorite = function(favorite) {
      favoriteRestService.updateFavorite(favorite).then((result) => {
          if (result instanceof Error) {
              alertService.error(result.name, result.message);
              $uibModalInstance.dismiss();
          } else {
              $uibModalInstance.close();
          }
      });
  };

   this.getActiveFavorite = function () {
        if (this.name !== null && this.name === '') {
            $('.modal-dialog').addClass('shake animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
            $('.modal-dialog').removeClass('shake animated');
            });
            return;
        }
        var transform = ol.proj.getTransform('EPSG:3857', 'EPSG:4326');
        this.activeFavorite.name = this.name;
        this.activeFavorite.ownerId = $rootScope.user.id;
        this.activeFavorite.centerLatitude = transform(fav.center)[1];
        this.activeFavorite.centerLongitude = transform(fav.center)[0];
        this.activeFavorite.resolution = fav.resolution;
        this.activeFavorite.defaultFavorite = fav.defaultFavorite;
        this.activeFavorite.shared = fav.shared;

        this.activeMapLayers.weather = $rootScope.user.preferences.mapOptions.weather;
        this.activeMapLayers.se = $rootScope.user.preferences.mapOptions.se;
        this.activeMapLayers.fir = $rootScope.user.preferences.mapOptions.fir;
        this.activeMapLayers.uir = $rootScope.user.preferences.mapOptions.uir;
        this.activeMapLayers.navLayers = $rootScope.user.preferences.mapOptions.navLayers;

        this.activeFavorite.flightToggleStates = flightSearchService.getActiveToggles() === undefined ?
                                                    [] : flightSearchService.getActiveToggles();
        this.activeFavorite.mapLayers = this.activeMapLayers === undefined ? {} : this.activeMapLayers;
        this.activeFavorite.definedAreaIds = definedAreaService.getActiveAreaIds();
        this.activeFavorite.flightFilters = flightSearchService.getActiveFilters() === undefined ?
                                                    [] : flightSearchService.getActiveFilters();

        return this.activeFavorite;
   };


  // Cancel action
  this.cancelItem = function () {
    $uibModalInstance.dismiss();
  };

  this.findFavoriteIfExists = function(favorite, favoritesList) {
    var favIdFound = null;
        favoritesList.forEach(favoriteFromList => {
         if (favoriteFromList.name.toLowerCase() === favorite.name.toLowerCase() && favoriteFromList.ownerId === favorite.ownerId) {
            favIdFound = favoriteFromList.id;
         }
        });
        return favIdFound;
  };

}]);
