/*jslint browser: true, plusplus:true, nomen: true */
/*global angular, ol, App, UserService, ErrorCode */


App
  .controller('SuperPageCtrl', [
    '$rootScope',
    '$scope',
    '$state',
    'customerSuperNgStore',
    'alertService',
    function SuperPageCtrl($rootScope, $scope, $state, customerSuperNgStore, alertService) {
      'use strict';
      if (!$rootScope.isLogged() || !$rootScope.hasRole(App.SUPER_ROLE)) {
        var currentState = $state.current.name,
          currentParam = $state.params;
        $state.go('login', {
          returnTo: currentState,
          returnToParams: currentParam
        });
        return;
      }
  
      customerSuperNgStore.once('sync', function () {
        $scope.customers = customerSuperNgStore.customers;
        $scope.$applyAsync();
      });
      customerSuperNgStore.refresh();

      $scope.deleteCustomer = function (customer) {
        alertService.confirm('Deletion', 'Are you sure to delete this Customer and all of its users ?')
          .then(function () {
            customerSuperNgStore.remove(customer)
              .then(
                function (cust) {
                  //$scope.customer = cust;
                  alertService.message('Deletion', 'Deletion Done !').then(function () {
                    $state.go('super.customer');
                  });
                },
                function (response) {
                  response.json()
                    .then(
                      function (errors) {
                        var error = errors.length && errors[0],
                          msg = (error && ErrorCode.getMessage(error.code, error.values)) || "";
                        alertService.error('Deletion failed', msg);
                      },
                      function () {
                        alertService.error('Deletion failed', "Server Internal");
                      }
                    );
                }
              );
          });
      };
      $scope.$on('$destroy', function () {
        // clear the store
        customerSuperNgStore.off(null, null, null);
        customerSuperNgStore._store.adapter.stop();
      });
    }
  ]);
