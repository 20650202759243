/*jslint browser: true, nomen: true */
/*global App, Promise, _ */

App.controller('SettingsAirspaceCtrl', ['$scope', 'featureNgStore', function ($scope, featureNgStore) {
  'use strict';

  var syncFeaturesHandler, addFeatureHandler, removeFeatureHandler, update;

  update = {
    available: {},
    airspace: {}
  };

  //
  // on submit
  $scope.submitAirspace = function () {
    var promises = [];
    // Create a promise for each feature to update
    _.forEach(update.available, function (feature, featureId) {
      var promise;
      feature.airspace = false;
      promise = featureNgStore.set(feature);
      promises.push(promise);
    });
    _.forEach(update.airspace, function (feature, featureId) {
      var promise;
      feature.airspace = true;
      promise = featureNgStore.set(feature);
      promises.push(promise);
    });
    // Return a promise that wait for the other
    return Promise.all(promises);
  };

  //
  // on moved

  // from available
  $scope.availableFeatureMoved = function ($index) {
    var feature;
    if ($scope.airspaceFeatures.length <= 5) {
      feature = $scope.availableFeatures[$index];
      delete update.available[feature.id];
      update.airspace[feature.id] = feature;
      $scope.availableFeatures.splice($index, 1);
    } else {
      feature = $scope.availableFeatures[$index];
      $scope.airspaceFeatures.splice($scope.airspaceFeatures.findIndex(function (i) {return i.id === feature.id; }), 1);
    }
  };

  // from airspace
  $scope.airspaceFeatureMoved = function ($index) {
    var feature = $scope.airspaceFeatures[$index];
    delete update.airspace[feature.id];
    update.available[feature.id] = feature;
    $scope.airspaceFeatures.splice($index, 1);
  };

  //
  // Retrieve data

  $scope.availableFeatures = [];
  $scope.airspaceFeatures = [];

  syncFeaturesHandler = function () {
    $scope.$applyAsync(function () {
      var currentFeatures = {};
      _.forEach($scope.availableFeatures, function (feature) {
        currentFeatures[feature.id] = 1;
      });
      _.forEach($scope.airspaceFeatures, function (feature) {
        currentFeatures[feature.id] = 2;
      });
      _.forEach(featureNgStore.availableFeatures, function (feature) {
        if (!update.available[feature.id] && !update.airspace[feature.id]) {
          $scope.availableFeatures.push(feature);
          update.available[feature.id] = feature;
        }
        delete currentFeatures[feature.id];
      });
      _.forEach(featureNgStore.airspaceFeatures, function (feature) {
        if (!update.airspace[feature.id] && !update.available[feature.id]) {
          $scope.airspaceFeatures.push(feature);
          update.airspace[feature.id] = feature;
        }
        delete currentFeatures[feature.id];
      });
      _.remove($scope.availableFeatures, function (feature) {
        if (currentFeatures[feature.id]) {
          delete update.available[feature.id];
          return true;
        }
        return false;
      });
      
      _.remove($scope.airspaceFeatures, function (feature) {
        if (currentFeatures[feature.id]) {
          delete update.airspace[feature.id];
          return true;
        }
        return false;
      });
    });
  };

  featureNgStore.on('sync', syncFeaturesHandler);
  featureNgStore.refresh();

  $scope.$on('$destroy', function () {
    featureNgStore.off('sync', syncFeaturesHandler);
  });

}]);
