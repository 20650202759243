(function (global) {
  'use strict';

  var notDefined = 'Not defined';

  function calculateType(areaType) {
    var result = "";
    if (!areaType) {
      result = notDefined;
    } else if (areaType === "MilitaryOperationsArea") {
      result = "Mil Ops Area";
    } else {
      result = "Area";
    }
    return result;
  }

  function calculateLimits(props) {
    // Limits: {LOWER_LIMIT} " - " {UPPER_LIMIT}, where {LOWER_LIMIT} and {UPPER_LIMIT} are defined as follows:
    var lowerLimit = "";
    var upperLimit = "";

    // calculate lowerLimit
    // IF {LO_LIM_TYP} = "Altitude" AND {LO_LIM_UNI} = "AboveMeanSeaLevel" set {LOWER_LIMIT} = {LO_LIM} "ft AMSL", e.g "3000ft AMSL"
    if ((props.lo_lim_typ === "Altitude") && (props.lo_lim_uni === "AboveMeanSeaLevel")) {
      lowerLimit = props.lo_lim + "ft AMSL";
      // ELIF {LO_LIM_TYP} = "Altitude" AND {LO_LIM_UNI} = "AboveGroundLevel" set {LOWER_LIMIT} = {LO_LIM} "ft AGL", e.g "3000ft AGL"
    } else if ((props.lo_lim_typ === "Altitude") && (props.lo_lim_uni === "AboveGroundLeveel")) {
      lowerLimit = props.lo_lim + "ft AGL";
      // ELIF {LO_LIM_TYP} = "Altitude" set {LOWER_LIMIT} = {LO_LIM} "ft", e.g "3000ft"
    } else if ((props.lo_lim_typ === "Altitude") && (props.lo_lim_uni !== "AboveMeanSeaLevel") && (props.lo_lim_uni !== "AboveGroundLevel")) {
      lowerLimit = props.lo_lim + "ft";
      // ELIF {LO_LIM_TYP} = "Flightlevel" set {LOWER_LIMIT} = "FL" {LO_LIM}/100, e.g "FL195"
    } else if (props.lo_lim_typ === "FlightLevel") {
      lowerLimit = "FL" + Math.round(props.lo_lim / 100);
      // ELIF {LO_LIM_TYP} = "Ground" AND {LO_LIM} = 2147483647 set {LOWER_LIMIT} = "GND", e.g "GND"
    } else if ((props.lo_lim_typ === "Ground") && (props.lo_lim === 2147483647)) {
      lowerLimit = "GND";
    } else {
      lowerLimit = notDefined;
    }

    // calculate upperLimit
    //   IF {UP_LIM_TYP} = "Altitude" AND {UP_LIM_UNI} = "AboveMeanSeaLevel" set {UPPER_LIMIT} = {UP_LIM} "ft AMSL", e.g "3000ft AMSL"
    if ((props.up_lim_typ === "Altitude") && (props.up_lim_uni === "AboveMeanSeaLevel")) {
      upperLimit = props.up_lim + "ft AMSL";
      // ELIF {UP_LIM_TYP} = "Altitude" AND {UP_LIM_UNI} = "AboveGroundLevel" set {UPPER_LIMIT} = {UP_LIM} "ft AGL", e.g "3000ft AGL"
    } else if ((props.up_lim_typ === "Altitude") && (props.up_lim_uni === "AboveGroundLevel")) {
      upperLimit = props.up_lim + "ft AGL";
      // ELIF {UP_LIM_TYP} = "Altitude" set {UPPER_LIMIT} = {UP_LIM} "ft", e.g "3000ft"
    } else if ((props.up_lim_typ === "Altitude") && (props.up_lim_uni !== "AboveMeanSeaLevel") && (props.up_lim_uni !== "AboveGroundLevel")) {
      upperLimit = props.up_lim + "ft";
      // ELIF {UP_LIM_TYP} = "Flightlevel" set {UPPER_LIMIT} = "FL" {UP_LIM}/100, e.g "FL195"
    } else if (props.up_lim_typ === "FlightLevel") {
      upperLimit = "FL" + Math.round(props.up_lim / 100);
      // ELIF {UP_LIM_TYP} = "Unlimited" AND {UP_LIM} = 2147483647 set {UPPER_LIMIT} = "Unlimited", e.g "GND"
    } else if ((props.up_lim_typ === "Unlimited") && (props.up_lim === 2147483647)) {
      upperLimit = "Unlimited";
    } else {
      upperLimit = notDefined;
    }
    var result = lowerLimit + " - " + upperLimit;
    return result;
  }

  global.restrictiveAirspacesFeatureFormatter = {
    format: function (adFeature, projection) {
      var props = adFeature.properties || {};
      var properties = [{
          label: 'Feature',
          value: '<b>SPECIAL USE AIRSPACE</b>'
        },
        {
          label: 'Ident',
          value: props.identifier || notDefined
        },
        {
          label: 'Name',
          value: props.name || notDefined
        },
        {
          label: 'Type',
          value: calculateType(props.area_type) || notDefined
        },
        {
          label: 'Country',
          value: props.country_id || notDefined
        },
        {
          label: 'Limits',
          value: calculateLimits(props) || notDefined
        },
        {
          label: 'NOTAM',
          value: props.notam || notDefined
        },
        {
          label: 'Remark',
          value: props.notes || notDefined
        },

      ];


      return {
        type: 'SUA',
        title: props.identifier + ":" + props.name,
        subtitle: props.area_type || '-',
        properties: properties,
      };

    }
  };
}(window));
