/*jslint browser: true, plusplus:true*/
/*global angular, ol, App, Promise */

App.controller('SettingsTimeZoneCtrl',['$rootScope','$scope','$http','defaultPreferences','userService',function($rootScope,$scope,$http,defaultPreferences,userService){
    'use strict';

    let currentZone = $scope.user.preferences.timeZone;

    $scope.currentTime = moment().tz(currentZone).format('HH:mm z');
    $scope.timeZones = moment.tz.names();
    $scope.selectedZone = $scope.timeZones[$scope.timeZones.indexOf(currentZone)];

    $scope.selectTimeZone =  function ($item, $model) {
        $scope.currentTime = moment().tz($item).format('HH:mm z');
        $scope.selectedZone = $scope.timeZones[$scope.timeZones.indexOf($item)];
    };

    $scope.submitTimeZone = function() {

        let timeZone = $scope.selectedZone;

        return new Promise(function (resolve, reject) {
            userService.savePreferences({
                timeZone: timeZone
            }).then(resolve, function(){
                resolve("Successfully updated");
            })['catch'](function (error) {
                reject("Error updating");
            });
        });
    };
    
}]);