/*jslint browser: true, plusplus: true,  nomen:true */
/*global angular, App, console, $, _ */
(function () {
  'use strict';

  App.controller('FlightsTableCtrl', ['$rootScope', '$scope', '$parse', '$filter', '$uibModal', '$state', 'flightSearchService', 'flightSectionDefinitions', 'flightDataFilter', 'dashFilter', 'timeFilter', 'flightNgStore', 'mapPositionService', 'userService',
  function ($rootScope, $scope, $parse, $filter, $uibModal, $state, flightSearchService, flightSectionDefinitions, flightDataFilter, dashFilter, timeFilter, flightNgStore, mapPositionService) {
    var self = this,
      defName,
      def,
      i,
      orderBy = $filter('orderBy'),
      filteredFlagSetter = $parse('isFiltered').assign,
      filteredDeskOnlyFlagSetter = $parse('isFilteredDeskOnly').assign,
      notEmptySectionsSetter = $parse('notEmptySections').assign,
      bySectionFlightsSetter = $parse('flightsBySection').assign,
      bySectionAllFlightsSetter = $parse('allflightsBySection').assign,
      bySectionCountSetter = $parse('flightsCountBySection').assign;

    $scope.params = {
      displayClosedFlights: false
    };
    
    $scope.smallTableData = $scope.user.preferences.smallTableColumns;
    $scope.extendedTableData = $scope.user.preferences.extendedTableColumns;
    $scope.sections = flightSectionDefinitions;

    $scope.content = function (flight, column, isDisplayed) {
      if(isDisplayed){
      if (column === 'originAirport') {
        return '<div class="from-cell"><span class="airport">' + dashFilter(flight.ori) + '</span><span class="time">' + timeFilter(flight.off, 'HH:mm') + '</span></div>';
      } else if (column === 'destinationAirport') {
        return '<div class="to-cell"><span class="airport">' + dashFilter(flight.dst) + '</span><span class="time">' + timeFilter(flight.eta, 'HH:mm') + '</span></div>';
      } else {
        return dashFilter(flightDataFilter(flight[column], $scope.colDefs[column]));
      }
      }else{
        return '--';
      }
    };

    $scope.openSection = function (sectionName) {
      var tableScrollPane = $('#aircraft-selection .ps-container'),
        bodies = tableScrollPane.find('tbody'),
        section = tableScrollPane.find('.sticky-enabled .section-' + sectionName + ' .section-header'),
        index = bodies.index(section.parent('tbody'));
      tableScrollPane.scrollTo(section, {
        duration: 700,
        offset: {
          top: index * section.outerHeight()
        }
      });
    };

    this.setFilteredFlightList = function (filtered) {
      //Groupe flights by alert level/section
      var groupedFlights = _.groupBy(filtered, 'section'),
        groupedAllFlights = _.groupBy($scope.allFlights, 'section'),
        notEmptySections = _.filter($scope.sections, function (s) {
          //checking if warning or caution are empty and removing them
          if (!groupedFlights.warning){
            if(!groupedFlights.caution){
              return (s.name !== 'closed' || $scope.params.displayClosedFlights) && (_.has(groupedAllFlights, s.name) || s.pinned) && (s.name !== 'warning') && (s.name !== 'caution');
            }else{
              return (s.name !== 'closed' || $scope.params.displayClosedFlights) && (_.has(groupedAllFlights, s.name) || s.pinned) && (s.name !== 'warning');
            }
          }else if (!groupedFlights.caution){
            return (s.name !== 'closed' || $scope.params.displayClosedFlights) && (_.has(groupedAllFlights, s.name) || s.pinned) && (s.name !== 'caution');
          }else{
            return (s.name !== 'closed' || $scope.params.displayClosedFlights) && (_.has(groupedAllFlights, s.name) || s.pinned);
          }
        }),
        section,
        countBySection = _.mapValues(groupedFlights, _.size);
      let isFiltered = filtered.length !== $scope.allFlights.length;
      let isFilteredDeskOnly = flightSearchService.isFilteredDeskOnly;

      //update table model
      bySectionFlightsSetter($scope, groupedFlights);
      bySectionAllFlightsSetter($scope, groupedAllFlights);
      notEmptySectionsSetter($scope, notEmptySections);
      filteredFlagSetter($scope, isFiltered);
      filteredDeskOnlyFlagSetter($scope, isFilteredDeskOnly);
      bySectionCountSetter($scope, countBySection);

      if(!isFiltered) {
        groupedFlights.other = null;
      }

      mapPositionService.setFiltered(isFiltered && !isFilteredDeskOnly);
    };

    this.updateFlightList = function (tableState, tableCtrl) {
      $scope.tableState = tableState;
      var filtered = $scope.flights;
      if (tableState.sort.predicate) {
        filtered = orderBy(filtered, tableState.sort.predicate, tableState.sort.reverse);
      }

      self.setFilteredFlightList(filtered);
    };

    $scope.updateClosedFlightsVisibility = function () {
      flightNgStore.showClosedFlights($scope.params.displayClosedFlights);
      if ($scope.tableState) {
        self.updateFlightList($scope.tableState);
      } else {
        self.setFilteredFlightList($scope.flights);
      }
    };

    $scope.getFlightPaneParams = function (flight) {
      var tabDest = 'altitude';
      return { flightId: flight.flightId || flight.addrModeS || flight.acn, tab: tabDest, flightDbId: flight.id};
    }

    $scope.$watch('shownSections', function () {
      flightSearchService.displayedBySection = $scope.shownSections;
      flightSearchService.refresh();
    }, true);

    $scope.$watch('shownSections.other', function(newValue) {
      mapPositionService.setShowOthers(newValue);
    });

    $scope.$on('scrollToFlightinTable', function (n, o) {
      // if (o !== n) {
      var tableScrollPane = $('#aircraft-selection .ps-container'),
        bodies = tableScrollPane.find('tbody'),
        activeCell = tableScrollPane.find('.active'),
        index = bodies.index(activeCell.parent('tbody'));
      tableScrollPane.scrollTo(activeCell, {
        duration: 700,
        offset: {
          top: (index + 1) * activeCell.innerHeight(),
          bottom: 30
        }
      });
      // }
    });

    self.setFilteredFlightList($scope.flights);
  }]);


  App.directive('shStoreFilter', ['$timeout', '$injector', function ($timeout, $injector) {
    return {
      require: '^stTable',
      link: function (scope, element, attr) {
        var promise = null,
          throttle = 400,
          event = 'input',
          prop = attr.shFilterProp || '$',
          storeName = attr.shStoreFilter,
          filterService = $injector.get(storeName);


        // view -> table state
        element.on(event, function (evt) {
          evt = evt.originalEvent || evt;
          if (promise !== null) {
            $timeout.cancel(promise);
          }

          promise = $timeout(function () {
            filterService.addSearchFilter(prop, evt.target.value);
            promise = null;
          }, throttle);
        });
        element.on('$destroy', function () {
          filterService.off(null, null, element);
        });
        filterService.on('filter:add', function (filter) {
          if (filter.key === prop) {
            element.val(filter.wanted);
          }
        }, element);
        filterService.on('filter:remove', function (filterKey) {
          if (filterKey === prop) {
            element.val('');
          }
        }, element);

        element.parent().find('[role=clear]').on('click', function () {
          element.val('').triggerHandler('input');
        });
      }
    };
  }]).directive('stickyScrollHead', [function () {
    return {
      restrict: 'C',
      link: function (scope, element, attr, ctrl) {
        // Set widths
        var $el = $(element),
          $w = $(window),
          $t = $el.find('.sticky-enabled'),
          $stickyHeader = $el.find('.sticky-header'),
          $stickyHead = $el.find('.sticky-thead'),
          $stickyWrap = $el.find('.sticky-wrap'),
          $stickyCol = $el.find('.sticky-col'),
          $stickyInsct = $el.find('.sticky-intersect'),
          setWidths = function () {
            $t.find('tr:not(.section-header)').first().find('th, td').each(function (i) {
              $stickyHeader.find('th, td').eq(i).css({
                'max-width': $(this).width(),
                'width': $(this).width()
              });
            });
            $stickyHeader.css('min-width', $t.width());
          },
          updateStickyHeadVisibility = _.throttle(function () {
            var $sections = $t.find('.section-header'),
              $sectionsClones = $stickyHead.find('.section-header'),
              $sectionsIntClones = $stickyInsct.find('.section-header');
            if ($t.height() > $stickyWrap.height()) {
              $sections.each(function (index, el) {
                var $el = $(el),
                  scrollTop = $stickyWrap.scrollTop(),
                  roffset = $el.position();
                if (roffset.top < (index * $el.outerHeight())) {

                  $($sectionsClones[index]).add($sectionsIntClones[index]).css({
                    'pointer-events': 'auto'
                  }).show();
                } else {
                  $($sectionsClones[index]).add($sectionsIntClones[index]).css({
                    'pointer-events': 'none'
                  }).hide();
                }
              });
            } else {
              $sections.each(function (index, el) {
                $($sectionsClones[index]).add($sectionsIntClones[index]).css({
                  'pointer-events': 'none'
                }).hide();
              });
            }
          }, 60),
          repositionStickyCol = _.throttle(function () {
            if ($stickyWrap.scrollLeft() > 0) {
              // When left of wrapping parent is out of view
              $stickyCol.add($stickyInsct).css({
                opacity: 1,
                left: $stickyWrap.scrollLeft()
              });
              $stickyHeader.css('left', -$stickyWrap.scrollLeft());
            } else {
              // When left of wrapping parent is in view
              $stickyCol
                .css({
                  opacity: 0
                })
                .add($stickyInsct).css({
                  left: 0
                });
              $stickyHeader.css('left', 0);
            }
          }, 100),
          handleScroll = function () {
            updateStickyHeadVisibility();
            repositionStickyCol();
          },
          hideStickies = function () {
            $stickyHead.find('.section-header').add($stickyInsct.find('.section-header')).hide();
          },
          handleResize = _.throttle(function () {
            if (scope.tableExpanded) {
              setWidths();
            } else {
              if ($w.width() < 810) {
                scope.$applyAsync(function () {
                  scope.minimiseTable(true);
                });
              }
            }
            handleScroll();
          }, 65);

        setTimeout(hideStickies, 0);
        setTimeout(handleResize, 0);
        $t.css({
          margin: 0,
          width: '100%'
        });
        $w.on('resize', handleResize);
        $stickyWrap.on('scroll', handleScroll);
        scope.$on('$destroy', function () {
          $stickyWrap.off('scroll', handleScroll);
          $w.off('resize', handleResize);
        });

        scope.$watch('tableExpanded', function (newValue, oldValue) {
          if (newValue && newValue !== oldValue) {
            setTimeout(handleResize, 600); //resize after table expanded
          }
          if (!newValue && oldValue) {
            $stickyHeader.css('min-width', '');
          }
        });
        scope.$watchCollection('flights', handleScroll);
        scope.$watchCollection('allrunways', handleScroll);
        scope.$watch('flightsBySection', handleScroll);
        // FIXME [DAB] handleScroll on airportBySection
        // !!! deep
        scope.$watch('airportsBySection', handleScroll, true);
      }
    };
  }]);
}());
