/*jslint browser: true, nomen:true */
/*global App, ServerTime, moment, $, _ */


App.controller('ChatNotificationCtrl', [
  '$scope',
  'chatService',
  'flightNgStore',
  function ($scope, chatService, flightNgStore) {
    'use strict';
    var self = this;
    $scope.discutions = [];
    $scope.flights = flightNgStore.allFlights;

    function refresh() {
      $scope.discutions.length = 0; //clear
      $scope.flights.forEach(function (f) {
        if (_.size(f.messages)) {
          $scope.discutions.push({
            flight: f,
            messages: f.messages
          });
        }
      });

      $scope.discutions.forEach(function (d) {
        d.last = _.max(d.messages, 'date');
      });
      $scope.discutions = _($scope.discutions).sortBy('last.date').reverse().value();
      $scope.totalUnreadMessagesNb =
        _.reduce($scope.discutions, function (total, d) {
          return total + _.size($scope.unreadMessages(d));
        }, 0);
    }


    $scope.elapsedTime = function (date) {
      return moment(date).from(ServerTime.now());
    };

    $scope.unreadMessages = function (d) {
      var msgs = d.messages || [];
      return _.filter(msgs, function (m) {
        return _.isEmpty(m.sender) && _.size(m.readBy) === 0;
      });
    };
    $scope.hasUnreadMessages = function (d) {
      return $scope.unreadMessages(d).length > 0;
    };

    $scope.openChat = function (d) {
      chatService.openChat(d.flight.id);
    };

    refresh();
    //watch active flights to close inactive chat windows.
    $scope.$watch('flights.length', refresh);
    chatService.on('chat:read chat:new', refresh, this);

    $scope.$on('$destroy', function () {
      chatService.off(null, null, self);
    });
  }
]);
