/*jslint browser: true, plusplus: true, nomen:true */
/*global _, angular, App, moment, Map*/
(function () {
  'use strict';
  App.controller('FlightDetailCtrl', [
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    'flightNgStore',
    'airlineDataStore',
    'defaultPreferences',
    'flightfieldDefinitions',
    'alertService',
    'chatService',
    '$filter',
    '$uibModal',
    'customerNgStore',
    function ($rootScope, $scope, $state, $stateParams, flightNgStore, airlineDataStore, defaultPreferences, flightfieldDefinitions, alertService, chatService, $filter, $uibModal, customerNgStore) {
      var flight, flights, i, authorizedDefaultPreferences = [],
        userPreferences = [],
        key, def;

      userPreferences = angular.copy($scope.user.preferences.displayedData);
      $scope.displayedFlightfieldDefinitions = userPreferences;

      $scope.params = $stateParams;
      let customer = customerNgStore.customer;
      let customerParameters = customer.parameters;

      if(customerParameters.surfaceTracking) {
          $scope.showSurfaceTracking = customerParameters.surfaceTracking;
      } else {
          $scope.showSurfaceTracking = false;
      }

      var currentTab = $scope.params.tab;
      $scope.setCurrentTab = function (tab) {
        currentTab = tab;
      }
      $scope.showMore = false;
      $scope.manualAlert = {
        isOpen: false
      };
      $scope.sendingAlert = false;
      $scope.toggleMore = function () {
        $scope.showMore = !$scope.showMore;
      };

      $scope.hasAlert = function (level, active) {
        if (active) {
          return $scope.activeAlerts && $scope.activeAlerts[level] && $scope.activeAlerts[level].length > 0;
        } else {
          return $scope.alerts && $scope.alerts[level] && $scope.alerts[level].length > 0;
        }
      };

      $scope.openAlertEdit = function () {
        $scope.manualAlert.isOpen = true;
      };

      $scope.openConversation = function () {
        chatService.openChat($scope.flight.id);
      };
      $scope.closeAlertEdit = function () {
        $scope.manualAlert.isOpen = false;
      };
      $scope.addManualAlert = function (level, msg) {
        var flight = $scope.selectedFlight,
          message = msg.replace(/\r?\n|\r/g, ' ').trim(); //remove line breaks

        $scope.sendingAlert = true;
        flightNgStore.addManualAlert(flight, level, message).then(function () {
          $scope.closeAlertEdit();
          $scope.sendingAlert = false;
          $scope.$applyAsync();
        }, function (error) {
          alertService.error('Failed to add alert for ' + flight.flightId, error);
          $scope.sendingAlert = false;
          $scope.$applyAsync();
        });
      };

      $scope.dismissAlert = function (alert) {
        if (alert.manual) {
          alertService.confirm('Close alert', 'This will mark this alert as resolved and close it. Are you sure ?')
            .then(function () {
              flightNgStore.closeManualAlert($scope.selectedFlight, alert)['catch'](function (error) {
                alertService.error(error);
              });
            });
        }
      };

      $scope.acknowledgeAlert = function (alert) {
        flightNgStore.acknowledgeAlert($scope.selectedFlight, alert).then(function (alertBack) {
          alert.ackBy = alertBack.ackBy;
          $scope.$applyAsync();
        })['catch'](function (error) {
          alertService.error(error);
        });
      };

      $scope.isChatConfigured = function () {
        return ($scope.flight.aircraft.chatUplinkType === 'NONE') || !$scope.flight.aircraft.bridgeId;
      };

      $scope.closeFlight = function () {
        alertService
          .confirm('Close flight', 'Do you really want to close this flight?<br>All its alerts will be removed.')
          .then(function () {
            flightNgStore.closeFlight(flight);
          });
      };


      $scope.getActiveFlightForPlannedFlight = function (plannedFlight) {

        let activeFlights = _.filter(flightNgStore.allFlights, function(allFlight) { return allFlight.section != 'planned' && allFlight.section != 'other'; });
        let filteredByRegistration = _.find(activeFlights, function(activeFlight) { return activeFlight.acn == plannedFlight.acn; });

        return filteredByRegistration;
      };

      $scope.hasActiveFlight = function (plannedFlight) {

        let activeFlight = $scope.getActiveFlightForPlannedFlight(plannedFlight);

        if(activeFlight) {
            return true;
        } else {
            return false;
        }
      };

      $scope.linkPlannedFlight = function (plannedFlight) {

        let activeFlight = $scope.getActiveFlightForPlannedFlight(plannedFlight);

        $uibModal.open({
            templateUrl: "views/partials/flight-detail/flight-detail-link-flight.html",
            resolve: {
              plannedFlight : function () {
                return plannedFlight;
              }, 
              activeFlight  : function () {
                return activeFlight;
              }
            },
            controller: 'flightDetailLinkFlightController',
            controllerAs: 'flightLinkCtrl'
          }).result.then((result) => {
            if (result instanceof Error) {
                alertService.error(result.name, result.message)
            } 
        }, () => {
            // Do nothing on cancel
        });
    
      };

      $scope.isEditedCi = false;
      $scope.isEditedTow = false;
      $scope.isEditedGw = false;
      $scope.edit = function (type) {
        if (type == 'ci') {
          $scope.isEditedCi = true;
        } else if (type == 'tow') {
          $scope.isEditedTow = true;
        } else if (type == 'gw') {
          $scope.isEditedGw = true;
        }
      };
      $scope.validate = function (input, type) {
        if (type == 'ci') {
          if (!_.isUndefined(input)) {
            $scope.flight['ci'] = input;
            flightNgStore.updateCostIndex($scope.flight.id, input);
          }
          $scope.isEditedCi = false;
        } else if (type == 'tow') {
          if (!_.isUndefined(input)) {
            $scope.flight['tow'] = input;
            flightNgStore.updateTakeOffWeight($scope.flight.id, input);
          }
          $scope.isEditedTow = false;
        } else if (type == 'gw') {
          if (!_.isUndefined(input)) {
            $scope.flight['gw'] = input;
            flightNgStore.updateGrossWeight($scope.flight.id, input);
          }
          $scope.isEditedGw = false;
        }
      };

      function findAlertWithCriteria(lastUpdate){
        var arrayOfWarningsAndCautions = [];
        for (var i in  $scope.activeAlerts) {
          for (var j in  $scope.activeAlerts[i])
            arrayOfWarningsAndCautions.push($scope.alerts[i][j]);
        };
        if (arrayOfWarningsAndCautions.length > 0) {
          var alertOfType = _.find(arrayOfWarningsAndCautions, function (o) {
            return o.criterion === lastUpdate.warning || o.criterion === lastUpdate.caution;
          });
        }
        return alertOfType;
      }



       $scope.hasAlertofTypes = function (lastUpdate) {
        var isAlertExist = false;
        var alertOfType=findAlertWithCriteria(lastUpdate);
        // verifier si le type d'alerte exist et il s'agit d'un warning
        if (alertOfType) {
          isAlertExist = true;
        }
         else {
          isAlertExist = false;
        };
        return isAlertExist;
      };

      function updateFlight() {
        var airlineIcao,
          airline, i;
        flight = $scope.flight = $scope.selectedFlight;

        $scope.alerts = null;
        $scope.activeAlerts = null;
        $scope.delayStr = null;
        if (flight) {
          airlineIcao = flight.airline;
          if (airlineIcao) {
            airline = airlineDataStore.airlines[airlineIcao];
            if (airline) {
              $scope.airline = {
                name: airline.name,
                icaoCode: airlineIcao,
                iataCode: airline.iata
              };
            } else {
              $scope.airline = {
                name: airlineIcao,
                icaoCode: airlineIcao
              };
            }
          }

          if (flight.destinationAirport) {
            if ($scope.user.preferences.displayedData.indexOf('remainingDistanceKm') > -1) {
              flight.remainingDistanceKm = !!flight.destinationRunway ? 0 : Map.computeOthodromicDistance(flight.lat, flight.long, flight.destinationAirport.latitude, flight.destinationAirport.longitude,
                true);
            }
            if ($scope.user.preferences.displayedData.indexOf('remainingDistanceNm') > -1) {
              flight.remainingDistanceNm = !!flight.destinationRunway ? 0 : Map.computeOthodromicDistance(flight.lat, flight.long, flight.destinationAirport.latitude, flight.destinationAirport.longitude,
                false);
            }
          }
          if (!flight.isOther) {
            $scope.alerts = _.chain(flight.alerts).sortBy('startDate').reverse().map(function (a) {
              a.startDateObject = a.startDate && moment(a.startDate);
              a.endDateObject = a.endDate && moment(a.endDate);
              return a;
            }).groupBy('lvl').value();
            //number of inactive alerts
            $scope.inactiveAlerts = _.chain(flight.alerts).reject({
              'isActive': true
            }).size().value();
            //query for number of active alerts by lvl
            $scope.activeAlerts = _.chain(flight.alerts).filter({
              'isActive': true
            }).groupBy('lvl').value();
          }
          if (flight.delayEstimated) {
            let delayAbs = Math.abs(flight.delayEstimated);
            if (flight.delayEstimated > 0) {
              $scope.delayStr = '+' + Math.floor(delayAbs / 60) + ' : ' + (delayAbs % 60);
              $scope.isLate = true;
            } else {
              $scope.delayStr = '-' + Math.floor(delayAbs / 60) + ' : ' + (delayAbs % 60);
              $scope.isLate = false;
            }
          }
        }

        if (!!flight) {
          $scope.phaseId = 0;
          if (!!flight['in']) {
            $scope.phaseId = 4;
          } else if (!!flight.on) {
            $scope.phaseId = 3;
          } else if (!!flight.off) {
            $scope.phaseId = 2;
          } else if (!!flight.out) {
            $scope.phaseId = 1;
          }
        }

        $scope.outOfFp = false;
        if (!!flight && flight.alerts.length > 0 && flight.alertLvl > 0) {
          for (i = 0; i < flight.alerts.length; i++) {
            if (_.isUndefined(flight.alerts[i].endDateObject) && flight.alerts[i].msg.includes("Horizontal flight plan deviation")) {
              $scope.outOfFp = true;
            }
          }
        }
      }

      if (angular.isObject($scope.selectedFlight)) {
        // we already have an aircraft
        updateFlight();

      }


      $scope.$watch('selectedFlight', function () {
        updateFlight();
      }, true);
      $scope.$watch('selectedFlight.alerts', function () {
        updateFlight();
      }, true);

      $scope.colDefs = flightfieldDefinitions;
      $scope.$on('$destroy', function onDestroy() {
        //unwrap ac.
      });



       // add manual report
      $scope.addManualReport = function (userInForm) {
        $uibModal.open({
          templateUrl: "views/partials/flight-detail/flight-detail-add-manual-report.html",
          resolve: {
            flight  : function () {
              return $scope.selectedFlight;
            }
          },
          controller: 'FlightAddManualReportCtrl as reportCtrl',
          windowClass: 'modal-flight-form-manual-report'
        })
      };

      $scope.isReporterUser = function () {
        return $scope.user.roles.indexOf('REPORTER') > -1;
      }

      $scope.openSurfaceMapWindow = function(airportCode){
        window.open('/#!/airport/'+airportCode+'/surfacetracking',
                                 '','width=1000,height=750,top=75,left=75');
      }

      $scope.getLogoUrl = function (icao) {
        return window.backendPrefix + '/airline/logo/' + icao;
      }
    }
  ]);

}());
