App.controller('areaListController', ['$scope', '$rootScope', 'userNgStore', 'alertService', 'areaModelFactory', 'definedAreaService', 'definedAreaRestService', 'userService', function ($scope, $rootScope, userNgStore, alertService, areaModelFactory, definedAreaService, definedAreaRestService, userService) {
  'use strict';

  definedAreaService.registerListController(this);
  this.areas = [];
  var mapOptions = $rootScope.user.preferences.mapOptions;

  this.$onInit = function () {
    definedAreaService.refreshAreaModel();
  };

  this.$onDestroy = function () {
    definedAreaService.unregisterListController();
  };

  this.populateVisibilityFromPreferences = function (areas) {
    areas.forEach(area => {
      var index = mapOptions.hiddenDefinedAreas.indexOf(area.id);
      if (index > -1) {
        area.isVisible = false;
      } else {
        area.isVisible = true;
      }
    });
  };

  this.editArea = function (area) {
    // Send area model to the Drawing Edit menu
    $scope.isDropdownOpened = false;
    definedAreaService.setActiveEditArea(area);
  };

  this.copyArea = function (area) {
    // Show a warning window with a confirm/cancel to clone the shape
    alertService
      .confirm('Clone Area', `Do you really want to clone this area: ${area.name}?`)
      .then(() => {
        definedAreaService.cloneArea(area);
        definedAreaService.refreshAreaModel();
      });
  };

  this.attemptDeleteArea = function (area) {
    var alertMsg;
    if (area.shared) {
        alertMsg = `Area may be in use by other users. Do you really want to delete this area: ${area.name}?`;
    } else{
        alertMsg = `Do you really want to delete this area: ${area.name}?`;
    }
    alertService
      .confirm('Delete Area', alertMsg)
      .then( () => {
        definedAreaRestService.deleteDefinedArea(area).then( (result) => {
          if(result instanceof Error) {
            alertService.error(result.name, result.message);
          }
          this.removeFromHiddenAreas(area);
          this.saveHiddenDefinedAreas();
          definedAreaService.refreshAreaModel();
        });
      });
  };

  this.areaIsMine = function (area) {
    return $rootScope.user.id === area.authorId;
  };

  this.removeFromHiddenAreas = function (area) {
    mapOptions.hiddenDefinedAreas = mapOptions.hiddenDefinedAreas.filter(id => id !== area.id);
  };

  this.toggleVisible = function (area) {
    if (area.isVisible) {
      this.removeFromHiddenAreas(area);
    } else {
      mapOptions.hiddenDefinedAreas.push(area.id);
    }
    this.saveHiddenDefinedAreas();
  };

  this.updateHiddenAreas = function () {

    mapOptions.hiddenDefinedAreas.length = 0;

    this.areas.forEach(area => {
      if(area.isVisible === false) {
        mapOptions.hiddenDefinedAreas.push(area.id);
      }
    });
  };

  this.saveHiddenDefinedAreas = function () {
    userService.savePreferences({
      mapOptions: mapOptions
    }).then(function () {
      definedAreaService.refreshMap();
    })['catch'](function () {
      // reload last preferences in DB
      mapOptions = userService.user.preferences.mapOptions;
      definedAreaService.refreshMap();
    });
  };

  this.updateAreas = function (areas) {
    this.areas = areas;
  };
}]);
