App.factory('restService', ['$http', function ($http) {

    const DEFAULT_ERROR_MSG = 'Error executing request.';

    const DEFAULT_PARAMS = {
        withCredentials: true,
        responseType: 'json',
        headers: {
            'Content-Type': 'application/json'
        }
    };

    let successCallback = function (response) {
        return response.data;
    };

    let createErrorCallback = function (errorMessage) {
        return function errorCallback(response) {
            if (response.data !== null && typeof response.data.code !== 'undefined') {
                return Error(errorMessage + ' Message: ' + ErrorCode.getMessage(response.data.code));
            } else {
                return Error(errorMessage + ' Message: ' + response.status + ' ' + response.statusText);
            }
        };
    };

    return {

        executeRequest: function (request) {
            return $http(Object.assign({}, request.params || DEFAULT_PARAMS, {
                url: request.url,
                method: request.httpMethod,
                data: request.body
            }))
                .then(
                    successCallback,
                    createErrorCallback(request.errorMsg || DEFAULT_ERROR_MSG)
                );
        },

    };

}]);