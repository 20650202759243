App.factory('routeRestService', ['$http', function($http) {

  return {
      getRoutes: function(origin, destination, registration) {
          return $http({
              method: 'GET',
              url: window.backendPrefix + '/routes' + '?origin=' + origin + '&destination=' + destination + '&registration=' + registration,
              withCredentials: true,
              responseType: 'json',
          }).then(function successCallback(response) {
              return response.data;
          }, function errorCallback(response) {
              if (response.data !== null && typeof response.data.code !== 'undefined') {
                  return Error('Error fetching routes. Message: ' + response.data.values[0]);
              } else {
                  return Error('Error fetching routes. Error: ' + response.status + ' ' + response.statusText);
              }
          });
      }
  };
  }]);