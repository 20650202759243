/*jslint browser: true, plusplus: true, vars: true */
/*global ol */

import {point} from "@turf/helpers";
import turf_bearing from "@turf/bearing";

(function (global) {
  'use strict';

  const EARTH_RADIUS_IN_METERS = 6378137;
  const EARTH_RADIUS_IN_KM     = 6371;

  function degToRad(degrees) {
    var degreeToRadiansFactor = Math.PI / 180;
    return degrees * degreeToRadiansFactor;
  }

  function distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
  
    var dLat = degToRad(lat2-lat1);
    var dLon = degToRad(lon2-lon1);
  
    lat1 = degToRad(lat1);
    lat2 = degToRad(lat2);
  
    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    return EARTH_RADIUS_IN_KM * c;
  }

  function radToDeg(radians) {
    var radianToDegreesFactor = 180 / Math.PI;
    return radians * radianToDegreesFactor;
  }

  function msToKt(ms) {
    var msToKtFactor = 1 / 0.514444444;
    return Math.round(ms * msToKtFactor);
  }

  function grahamScan(coordinates) {

    if (coordinates.length < 3) {
      return coordinates;
    }

    var minimum = function (Q) {
      // Find minimum y point (in case of tie select leftmost)         
      // Sort by y coordinate to ease the left most finding
      Q.sort(function (a, b) {
        return a[1] - b[1];
      });

      var y_min = 1000000;
      var smallest = 0;
      var i;
      for (i = 0; i < Q.length; ++i) {
        var p = Q[i];
        if (p[1] < y_min) {
          y_min = p[1];
          smallest = i;
        } else if (p[1] === y_min) { // Select left most 
          if (Q[i - 1][0] > p[0]) {
            smallest = i;
          }
        }
      }
      return smallest;
    };

    var distance = function (a, b) {
      return (b[0] - a[0]) * (b[0] - a[0]) + (b[1] - a[1]) * (b[1] - a[1]);
    };

    var filter_equal_angles = function (p0, Q) {
      // => If two coordinates have same polar angle remove the closet to p0
      // Distance can be calculated with vector length...
      var i;
      for (i = 1; i < Q.length; i++) {
        if (Q[i - 1].polar === Q[i].polar) {
          var d1 = distance(p0, Q[i - 1]);
          var d2 = distance(p0, Q[i]);
          if (d2 < d1) {
            Q.splice(i, 1);
          } else {
            Q.splice(i - 1, 1);
          }
        }
      }
    };

    var cartesian_angle = function (x, y) {
      if (x > 0 && y > 0) {
        return Math.atan(y / x);
      } else if (x < 0 && y > 0) {
        return Math.atan(-x / y) + Math.PI / 2;
      } else if (x < 0 && y < 0) {
        return Math.atan(y / x) + Math.PI;
      } else if (x > 0 && y < 0) {
        return Math.atan(-x / y) + Math.PI / 2 + Math.PI;
      } else if (x === 0 && y > 0) {
        return Math.PI / 2;
      } else if (x < 0 && y === 0) {
        return Math.PI;
      } else if (x === 0 && y < 0) {
        return Math.PI / 2 + Math.PI;
      } else {
        return 0;
      }
    };

    var calculate_angle = function (p1, p2) {
      return cartesian_angle(p2[0] - p1[0], p2[1] - p1[1]);
    };

    var calculate_polar_angles = function (p0, Q) {
      var i;
      for (i = 0; i < Q.length; i++) {
        Q[i].polar = calculate_angle(p0, Q[i]);
      }
    };

    // Three coordinates are a counter-clockwise turn 
    // if ccw > 0, clockwise if ccw < 0, and collinear if ccw = 0 
    var ccw = function (p1, p2, p3) {
      return (p2[0] - p1[0]) * (p3[1] - p1[1]) - (p2[1] - p1[1]) * (p3[0] - p1[0]);
    };

    // Find minimum point 
    var Q = coordinates.slice(); // Make copy 
    var minIndex = minimum(Q);
    var p0 = Q[minIndex];
    Q.splice(minIndex, 1); // Remove p0 from Q

    // Sort by polar angle to p0              
    calculate_polar_angles(p0, Q);
    Q.sort(function (a, b) {
      return a.polar - b.polar;
    });

    // Remove all with same polar angle but the farthest. 
    filter_equal_angles(p0, Q);

    // Graham scan 
    var S = [];
    S.push(p0);
    S.push(Q[0]);
    S.push(Q[1]);
    var i;

    for (i = 2; i < Q.length; ++i) {
      var pi = Q[i];
      while (S.length > 1 && ccw(S[S.length - 2], S[S.length - 1], pi) <= 0) {
        S.pop();
      }
      S.push(pi);
    }
    return S;
  }


  function adjustLongitudeIfCrossedDateLine(startLongitudeDegrees, endLongitudeDegrees) {
    if (Math.abs(startLongitudeDegrees - endLongitudeDegrees) > 180) {
      let longitudeAdjustment = startLongitudeDegrees < endLongitudeDegrees ? -360 : 360;
      endLongitudeDegrees += longitudeAdjustment;
    }
    return endLongitudeDegrees;
  }

  function distanceBetweenPoints(coord1, coord2) {
    var wgs84Sphere = new ol.Sphere(EARTH_RADIUS_IN_METERS);
    var convertedCoord1 = ol.proj.transform(coord1, 'EPSG:3857', 'EPSG:4326');
    var convertedCoord2 = ol.proj.transform(coord2, 'EPSG:3857', 'EPSG:4326');
    var distance = wgs84Sphere.haversineDistance(convertedCoord1, convertedCoord2);

    return distance;
  }

  function bearingFromPointToPoint(coord1, coord2) {
    var convertedCoord1 = ol.proj.transform(coord1, 'EPSG:3857', 'EPSG:4326');
    var convertedCoord2 = ol.proj.transform(coord2, 'EPSG:3857', 'EPSG:4326');

    var bearing = turf_bearing(point(convertedCoord1), point(convertedCoord2));

    return (bearing + 360) % 360;
  }

  function metersToKilometers(meters) {
    return Math.round(meters / 1000 * 100) / 100;
  }

  function metersToNauticalMiles(meters) {
      return Math.round(meters / 1852);
  }


  window.GeometryUtils = {
    grahamScan: grahamScan,
    degToRad: degToRad,
    radToDeg: radToDeg,
    msToKt: msToKt,
    adjustLongitudeIfCrossedDateLine: adjustLongitudeIfCrossedDateLine,
    distanceBetweenPoints: distanceBetweenPoints,
    bearingFromPointToPoint: bearingFromPointToPoint,
    metersToKilometers: metersToKilometers,
    metersToNauticalMiles: metersToNauticalMiles,
    distanceInKmBetweenEarthCoordinates: distanceInKmBetweenEarthCoordinates
  };

}(window));
