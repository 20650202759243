/*jslint browser: true */
/*global App, $ */


App.controller('LoginPageCtrl', ['$scope', '$state', '$stateParams', '$uibModal', 'userService','favoriteDefaultService', 'serverInstance', function ($scope, $state, $stateParams, $uibModal, userService, favoriteDefaultService, serverInstance) {
  'use strict';

  $scope.credentials = {
    username: '',
    password: '',
    remember: false
  };
  $scope.error = null;

  serverInstance.findServerInstances().then(function (instances) {
    $scope.instances = instances;
    instances.forEach(function (instance) {
      if (instance.isCurrent) {
        $scope.instance = instance;
      }
    });
    $scope.$apply();
  });

  function handleError(msg, status) {

    $scope.error = {
      msg: msg,
      type: 'danger'
    };
    userService.logout();
    $scope.$digest();
    $('#formLogin input').add('#formLogin button').attr('disabled', false);
    $('#formLogin .spinner').addClass('hide');
  }

  if ($stateParams.logout) {
    $scope.error = {
      msg: 'You\'ve been successfully logged out',
      type: 'success'
    };
    userService.logout();
    //$scope.$apply();
  }

  $scope.submit = function (user) {
    $scope.error = null;
    favoriteDefaultService.setIsDefaultFavoriteApplied(true);

    $('#formLogin input').add('#formLogin button').attr('disabled', true);
    $('#formLogin .spinner').removeClass('hide');
    $('#formLogin .alert').remove();
    userService.login($scope.credentials)
      .then(function (user) {
        var returnTo = $stateParams.returnTo || 'map',
          params = $stateParams.returnToParams || {};
        if (user.roles.indexOf(App.SUPER_ROLE) !== -1) {
          returnTo = 'super.customer';
        }
        $state.go(returnTo, params);
      })['catch'](function (error) {
        if (error.status === 401) {
          handleError('Incorrect login or password. Please enter your credentials again');
        } else if (error.status === 402) {
          handleError('Too many sessions. Please try again in a few minutes.');
        } else if (error.status === 429) {
          handleError('User is blocked, please contact admin for more informations');
        } else {
          handleError(error);
        }
      });
  };

  $scope.$watch('instance', function (newInstance) {
    if (newInstance) {
      window.backendPrefix = '//' + newInstance.host + '/v1';
    }
  });

  // Reset Password
  $scope.resetPassword = function () {
    $uibModal.open({
      templateUrl: 'views/partials/reset-password.html',
      controller: 'ResetPasswordFormCtrl'
    }).result['finally'](function () {
      $state.go('login');
    });
  };
}]);
