/*jslint browser: true, plusplus:true, nomen:true*/
/*global _, angular, App, $ */

const { assign } = require("lodash");

App.controller("DeskAssignmentPageCtrl", [
  "$rootScope",
  "$scope",
  "deskAssignmentNgStore",
  "flightNgStore",
  "flightSearchService",
  "generalConstants",
  function ($rootScope, $scope, deskAssignmentNgStore, flightNgStore, flightSearchService, generalConstants) {
    "use strict";

    const ALLDESKNAME = "All Flights";
    var observer;

    this.init = function () {
      this.loaded = false;
      $rootScope.selectedDeskName = $rootScope.selectedDeskName !== undefined ? $rootScope.selectedDeskName : ALLDESKNAME;
      waitUntilFlightsAreLoaded().then(() => {
        this.syncFlights();
        this.filterFlightsByDesk($rootScope.selectedDeskName);
        this.loaded = true;
      });
    };

    this.syncFlights = function() {
      this.allFlights = getAllFlights();
      this.deskAssignments = getDeskAssignments(deskAssignmentNgStore.deskAssignments, this.allFlights);
      this.assignedFlights = getAssignedFlights(this.deskAssignments);
      this.unassignedFlights = getUnassignedFlights(this.allFlights, this.assignedFlights);
    };

    this.filterFlightsByDesk = function(selectedDeskName) {
      if (!selectedDeskName) {
        return;
      }
      $rootScope.selectedDeskName = selectedDeskName;
      if (selectedDeskName === ALLDESKNAME) {
        flightSearchService.removeSearchFilter(generalConstants.deskFieldName);
        return;
      } else {
        flightSearchService.addSearchFilter(generalConstants.deskFieldName, selectedDeskName);
      }
    }

    this.addObserver = function() {
      let targetNode = document.querySelector('#desk-drop-btn');
      let  config = {attributes: true};
      if (!observer) {
        observer = new MutationObserver(this.updateIconColor);
      }
      observer.observe(targetNode, config);
    }

    this.updateIconColor = function () {
      if ($('#desk-drop-btn').attr('aria-expanded') === "true") {
        $('.circle').addClass('desk-openned');
        $('.half-circle').addClass('desk-openned');
        $('.icon-flight').addClass('flight-openned');
      } else {
        $('.circle').removeClass('desk-openned');
        $('.half-circle').removeClass('desk-openned');
        $('.icon-flight').removeClass('flight-openned');
      }
    }

    function filterNonUniqueElements(list) {
      return [...new Set(list)];
    }

    function getAllFlights() {
      return flightNgStore.allFlights.filter((flight) => flight.isMine);
    }

    function getDeskAssignments(deskAssignments, flights) {
      if (!deskAssignments || flights.length === 0) {
        return;
      }

      return deskAssignments.map((deskAssignment) => {
        deskAssignment.flights = deskAssignment.flights
          .map((a) => {
             if (a.flightId !== null) {
               let checkId = a.id !== undefined ? a.id : a.flightId;
               let flight = findFlightByFlightId(flights, checkId);
               if (flight) {
                 flight.desk = (flight.desk === undefined || flight.desk === "") ? deskAssignment.name : flight.desk + ", " + deskAssignment.name;
              }
              return flight;
            } else {
              let flightPlan = findFlightByFlightPlanId(flights, a.flightPlanId);
              if (flightPlan) {
                flightPlan.desk = (flightPlan.desk === undefined || flightPlan.desk === "") ? deskAssignment.name : flightPlan.desk + ", " + deskAssignment.name;
              }
              return flightPlan;
            }
          })
          .filter((flight) => flight !== undefined)
          .sort((a, b) => a.flightId > b.flightId);
          deskAssignment.flights = filterNonUniqueElements(deskAssignment.flights);
          return deskAssignment;
      });
    }

    function getAssignedFlights(deskAssignments) {
      if (!deskAssignments) {
        return;
      }
      let assignedFlights = [];
      deskAssignments.forEach((deskAssignment) => {
        assignedFlights.push(...deskAssignment.flights);
      });
      assignedFlights.sort((a, b) => a.flightId > b.flightId);
      return filterNonUniqueElements(assignedFlights);
    }

    function getUnassignedFlights(flights, assignedFlights) {
      let unassignedFlights = [];
      if (flights) {
        unassignedFlights = flights
          .filter((a) => !assignedFlights.find((b) => a.id === b.id))
          .sort((a, b) => a.flightId > b.flightId);
          unassignedFlights.forEach(function(flight) {
            flight.desk = "";
          });
      }

      return filterNonUniqueElements(unassignedFlights);
    }

    function findFlightByFlightId(flights, flightId) {
      if (flights && flightId) {
        return flights.find((flight) => flight.id == flightId);
      }
    }

    function findFlightByFlightPlanId(flights, flightPlanId) {
      if (flights && flightPlanId) {
        return flights.find((flight) => {
          if (flight.id.startsWith(flightPlanId)) {
            const parsedFlightPlanId = flight.id.substring(0, flight.id.indexOf(flight.flightNumber));
            return flightPlanId == parsedFlightPlanId;
          } else {
            return false;
          }
        });
      }
    }

    function waitUntilFlightsAreLoaded() {
      return waitUntil(() => flightNgStore.flights.length > 0, 100, 10000);
    }

    function waitUntil(condition, interval, timeout) {
      var elapsed = 0;
      return new Promise((resolve) => {
        const intervalId = setInterval(() => {
          elapsed += interval;
          if (condition() || elapsed > timeout) {
            clearInterval(intervalId);
            resolve();
          }
        }, interval);
      });
    }

    this.init();

    if (this){
      deskAssignmentNgStore.on('sync', this.syncFlights, this);
      deskAssignmentNgStore.refresh();
    }

    $scope.$on('$destroy', function () {
      if (this) {
        deskAssignmentNgStore.off('sync', this.syncFlights, this);
      }

      if (observer) {
        observer.disconnect();
      }
    });
  },
]);
