/*jslint browser: true, nomen: true */
/*global App, Plumbing, ol, Promise, GenericStore, angular, console */

App.provider('customerNgStore', [function customerNgStoreProvider() {
  'use strict';

  function CustomerNgStore() {
    GenericStore.call(this);
    this._useItemId = false;
  }

  CustomerNgStore.prototype = Object.create(GenericStore.prototype);

  angular.extend(CustomerNgStore.prototype, {
    //
    // Constructor

    constructor: CustomerNgStore,

    //
    // Overridable

    newInstance: function (values) {
      var customer = {
        id: values.id,
      };
      this.updateInstance(customer, values);
      this.backupCustomer(customer);
      return customer;
    },

    updateInstance: function (entity, values) {
      entity.name = values.name;
      entity.parameters = values.parameters || '{}';
      entity.airlines = values.airlines;
      entity.airports = values.airports;
      entity.options = values.options;
      entity.customerWeatherPackage = values.customerWeatherPackage;
      entity.params = entity.parameters;
      this.backupCustomer(entity);
    },

    backupCustomer: function (customer) {
      localStorage.setItem('customer', JSON.stringify(customer));
    },

    restoreCustomer: function () {
      var customerString = localStorage.getItem('customer');
      return (angular.isString(customerString) && JSON.parse(customerString));
    },

    saveTemplates : function(templates){
      return this._store.saveTemplates(templates); 
    },

    getTemplates : function(){
      return this._store.getTemplates();
    },

    getDefaultTemplate : function(){
      return this._store.getDefaultTemplate();
    },

    getTemplateAssociation : function(){
      return this._store.getTemplateAssociation();
    },

    saveTemplateAllocation : function(allocations){
      var associations = _.mapValues(allocations, 'id');
      return this._store.saveTemplateAllocation(associations);
    }, 

    saveDefaultTemplate : function(defaultTemplate){
      return this._store.saveDefaultTemplate(defaultTemplate);
    }

  });

  

  Object.defineProperties(CustomerNgStore.prototype, {
    customer: {
      get: function () {
        return (this._entities.length && this._entities[0]) || this.restoreCustomer();
      }
    }
  });

  var customerNgStore = new CustomerNgStore();

  this.$get = ['customerStore', function customerNgStoreFactory(customerStore) {
    customerNgStore.store = customerStore;
    return customerNgStore;
  }];

}]);
