/*jslint browser: true, plusplus:true*/
/*global App, $, angular */

(function (global) {
  'use strict';

  function SelectAircraftFormCtrl($scope, $state, $stateParams, $uibModal, aircraftInfoNgStore) {

    // Aircraft list
    $scope.aircrafts = aircraftInfoNgStore.aircrafts;
    $scope.selectedAircrafts = [];

    $scope.isSelected = function isSelected(plane) {
      return $scope.selectedAircrafts.indexOf(plane) >= 0;
    };

    // Select Element
    $scope.toggleElement = function toggleElement(plane) {
      if (!$scope.isSelected(plane)) {
        $scope.selectedAircrafts.push(plane);
        $("#" + plane.registration).removeClass("select-box").addClass("select-box selected");
      } else {
        $scope.selectedAircrafts.splice($scope.selectedAircrafts.indexOf(plane), 1);
        $("#" + plane.registration).removeClass("selected");
      }
    };

    // Valid action
    $scope.closeAction = function closeAction() {
      var form = document.getElementById("formStatsAlerts"),
        elements = form.elements,
        i;
      for (i = 0; i < elements.length; ++i) {
        elements[i].required = false;
      }
      $uibModal.close();
    };
  }

  App
    .controller('SelectAircraftFormCtrl', ['$scope', '$state', '$stateParams', '$uibModalInstance', 'aircraftInfoNgStore', SelectAircraftFormCtrl]);

}(window));
