/*jslint browser: true, nomen: true */
/*global App, Plumbing, _ */

App.provider('deskAssignmentStore', ['customerId', function DeskAssignmentStoreProvider(customerId) {
  'use strict';
  
  var opts = {
    path: {
      customer: '/customer/',
      desk: '/desk-assignments'
    }
  };

  var getPath = function () {
    if (_.isFinite(customerId.get())) {
      return window.backendPrefix + opts.path.customer + customerId.get() + opts.path.desk;
    }
  };

  var store = new Plumbing({
    headers: opts.headers,
    url: getPath,
    delay: 30000
  });

  customerId.on('update', function (oldId, newId) {
      if (oldId && oldId !== newId) {
        if (_.isUndefined(newId)) {
          store.adapter.stop();
        } else {
          store.refresh();
        }
      }
    }, this);

    this.setCustomerPart = function (customer) {
      opts.path.customer = customer;
    };

  this.$get = [function deskAssignmentStoreFactory() {
    return store;
  }];
}]);
