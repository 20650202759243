/*jslint browser: true, plusplus:true*/
/*global angular, ol, App */

(function () {
  'use strict';

  function SettingsPageCtrl($rootScope, $scope, $state, userProfileNgStore) {
    userProfileNgStore.refresh();
    if (!$rootScope.isLogged()) {
      var currentState = $state.current.name,
        currentParam = $state.params;
      $state.go('login', {
        returnTo: currentState,
        returnToParams: currentParam
      });
      return;
    }
  }

  App
    .controller('SettingsPageCtrl', ['$rootScope', '$scope', '$state', 'userProfileNgStore', SettingsPageCtrl]);
}());
