App.controller('navLayersSelectorController', ['navLayers', '$scope', '$uibModal', 'userService', 'customerNgStore', function (navLayers, $scope, $uibModal, userService, customerNgStore) {
  'use strict';

  var vm = this;
  var map = window.map;
  var userParameters;
  this.resolution = 0;
  this.previousSelection = [];

  customerNgStore.refresh();
  var customer = customerNgStore.customer,
    superParameters = customer.parameters;
  this.$onInit = function () {

    vm.mapOptions = userService.getUserPreference('mapOptions');
    userParameters = vm.mapOptions;
    var maxResolutionKeys = Object.keys(vm.mapOptions.maxResolutions);

    maxResolutionKeys.forEach(function (layer) {
      if (layer in navLayers.staticLayers) {
        navLayers.staticLayers[layer].maxResolution = vm.mapOptions.maxResolutions[layer].maxResolution;
      }

      if (layer in navLayers.highLowLayers) {
        navLayers.highLowLayers[layer].maxResolution = vm.mapOptions.maxResolutions[layer].maxResolution;
      }
    });

    vm.staticLayers = _.values(navLayers.staticLayers);
    vm.highLowLayers = _.values(navLayers.highLowLayers);

    map.getView().on('change:resolution', this.updateResolution, this);
    this.updateResolution();
    this.initLayers();
  };

  this.$onDestroy = function () {
    map.getView().un('change:resolution', this.updateResolution, this);
    _.forEach(_.concat(vm.staticLayers, vm.highLowLayers), function (layer) {
      if (this.isLayerSelected(layer)) {
        this.onLayerUnselected({
          layer: layer,
          fullSelection: this.selectedLayers
        });
      }
    }.bind(this));
    map = null;
  };

  this.$onChanges = function (changesObj) {
    //listen for preference changes for auto update ?
  };

  this.updateResolution = function () {
    this.resolution = map.getView().getResolution();
  };

  this.initLayers = function () {
    _.forEach(_.concat(vm.staticLayers, vm.highLowLayers), function (layer) {
      if (this.isLayerSelected(layer)) {
        if (this.customerHasParameter(layer.requiresSuperParameter, layer.requiresUserParameter)) {
          this.onLayerSelected({
            layer: layer,
            fullSelection: this.selectedLayers
          });
        } else {
          this.selectedLayers.splice(this.selectedLayers.indexOf(layer.key), 1);
        }
      }
    }.bind(this));
    this.previousSelection = _.concat(this.selectedLayers);
  };

  this.editLayer = function editLayer(layer) {
    if (layer.key == 'favouriteAirports') {
      $uibModal.open({
        templateUrl: 'views/partials/map-favorite-airports.html',
        resolve: {
          favoriteAirports: function () {
            return vm.userFavoriteAirport;
          }
        },
        controller: 'FavoriteAirportsFormCtrl'
      });
    }
  };

  this.isLayerSelected = function isLayerSelected(layer) {
    return this.selectedLayers.indexOf(layer.key) !== -1;
  };

  this.changeLevel = function (level) {
    this.onLayerLevelChange({
      level: level
    });
  };

  this.isLayerSelectable = function (layer) {
    return this.resolution > layer.maxResolution;
  };

  this.layerSelectionChanged = function (layer) {
    var addToSelection = _.difference(this.selectedLayers, this.previousSelection);
    //var removedFromSelection = _.difference(this.previousSelection, this.selectedLayers);
    this.previousSelection = _.concat(this.selectedLayers);
    if (addToSelection.length > 0) {
      this.onLayerSelected({
        layer: layer,
        fullSelection: this.selectedLayers
      });
    } else {
      this.onLayerUnselected({
        layer: layer,
        fullSelection: this.selectedLayers
      });
    }
  }

  this.customerHasParameter = function (requiresSuperParam, requiresUserParam) {

    var bSuperSet = false;
    var bUserSet = false;

    // If Required Super Parameter is present for Nav Layer
    //
    if (requiresSuperParam) {
      if (superParameters[requiresSuperParam])
        bSuperSet = superParameters[requiresSuperParam];
    }
    else {
      bSuperSet = true;
    }

    if (requiresUserParam) {
      if (userParameters[requiresUserParam][requiresUserParam])
        bUserSet = userParameters[requiresUserParam][requiresUserParam];
    }
    else {
      bUserSet = true;
    }

    return bSuperSet && bUserSet;

  }

}]);

App.component('navLayersSelectorComponent', {
  bindings: {
    onLayerSelected: '&',
    onLayerUnselected: '&',
    onLayerLevelChange: '&',
    selectedLayers: '<',
    selectedLevel: '<',
    userFavoriteAirport: '<'
  },
  controller: 'navLayersSelectorController',
  controllerAs: 'ctrl',
  templateUrl: "views/partials/map/quick-access/nav-layers-selector.template.html",
});
