App.directive('longitudeValidate', function() {
  'use strict';
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, element, attrs, ngModelCtrl) {

      // longitude should be a number with up to 10 digits of precision

      let latLongPattern = /^(-|)\d{1,3}(\.\d{1,10}|)$/;

      function testLongitude(longitude) {
        let parsedLongitude = parseFloat(longitude);
        return !Number.isNaN(parsedLongitude);
      }

      function validateLongitude(value) {
        let matchesPattern = latLongPattern.test(value);
        let withinRange = testLongitude(value);

        ngModelCtrl.$setValidity('longitudeValidatePattern', matchesPattern);
        ngModelCtrl.$setValidity('longitudeValidateRange', withinRange);
        ngModelCtrl.$setValidity('longitudeValidate', (matchesPattern && withinRange));

        return (matchesPattern && withinRange) ? value : undefined;
      }

      ngModelCtrl.$parsers.unshift(function(value) {
        return validateLongitude(value);
      });

      ngModelCtrl.$formatters.unshift(function(value) {
        return validateLongitude(value);
      });
    }
  };
});
