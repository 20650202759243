/*jslint browser: true, plusplus:true, nomen:true*/
/*global _, App, Promise */

(function (global) {
  'use strict';

  function SettingsFlightDetailsCtrl($rootScope, $scope, $state, userService, defaultPreferences, flightfieldDefinitions) {

    var tabs = [
        { name: 'altitude', desc: 'Altitude tab'},
        { name: 'eta', desc: 'ETA tab'},
        { name: 'notes', desc: 'Notes tab'},
        { name: 'pax', desc: 'Passenger Info tab'},
        { name: 'crew', desc: 'Crew Info tab'}
    ];

    // Flight details model
    $scope.settingsFlightDetails = $scope.user.preferences.displayedData;

    // Tabs displayed model
    $scope.settingsFlightTabs = $scope.user.preferences.displayedTabs;


    $scope.availableFlightfieldDefinitions = [];
    $scope.displayedFlightfieldDefinitions = [];

    $scope.displayedTabs = [];
    $scope.availableTabs = [];

    $scope.submitFlightDetailsSettings = function () {
      return new Promise(function (resolve, reject) {
        userService.savePreferences({
          displayedData: _.map($scope.displayedFlightfieldDefinitions, 'key'),
          displayedTabs: _.map($scope.displayedTabs, 'key')
        }).then(resolve, function () {
          //reload last preferences in DB
          $scope.settingsFlightDetails = $scope.user.preferences.displayedData;
          $scope.settingsFlightTabs = $scope.user.preferences.displayedTabs;
          $scope.$apply();
          reject(arguments);
        });
      });
    };

    // Flight details list
    _.forEach(flightfieldDefinitions, function (def, key) {
      if (def.displayInDetails && (_.isUndefined(def.roles) || $rootScope.hasAnyRole(def.roles)) && (_.isUndefined(def.options) || $rootScope.hasAnyOption(def.options))) {
        var keyIndex = $scope.settingsFlightDetails.indexOf(key);
        def.key = key;
        def.type = 'flight-field';
        if (keyIndex > -1) {
          $scope.displayedFlightfieldDefinitions[keyIndex] = def;
        } else {
          $scope.availableFlightfieldDefinitions.push(def);
        }
      }
    });
    _.sortBy($scope.availableFlightfieldDefinitions, 'label');
    $scope.displayedFlightfieldDefinitions = $scope.displayedFlightfieldDefinitions.filter(function(n) { return n != undefined });

    // Tab list
    _.forEach(tabs, function (tab, index) {
      var tabObj,
        keyIndex = $scope.settingsFlightTabs.indexOf(tab.name);
      tabObj = {
        key : tab.name,
        type: 'tab',
        name: tab.name.toUpperCase(),
        description: tab.desc
      };
      if (keyIndex > -1) {
        $scope.displayedTabs[keyIndex] = tabObj;
      } else {
        $scope.availableTabs.push(tabObj);
      }
    });

  }

  App
    .controller('SettingsFlightDetailsCtrl', ['$rootScope', '$scope', '$state', 'userService', 'defaultPreferences', 'flightfieldDefinitions', SettingsFlightDetailsCtrl]);
}(window));
