/*jslint browser: true, nomen: true */
/*global App, Plumbing, ol, Promise, GenericStore, angular, _ */

App.provider('userProfileNgStore', [function userProfileNgStoreProvider() {
  'use strict';

  function UserProfileNgStore() {
    GenericStore.call(this);
    this._useItemId = false;
  }

  UserProfileNgStore.prototype = Object.create(GenericStore.prototype);

  angular.extend(UserProfileNgStore.prototype, {
    //
    // Constructor

    constructor: UserProfileNgStore,

    //
    // Overridable

    newInstance: function (values) {
      var profile = {};
      this.updateInstance(profile, values);
      return profile;
    },

    updateInstance: function (entity, values) {
      entity.id = values.id;
      entity.login = values.login;
      entity.isBlocked = values.isBlocked;
      entity.customerId = values.customerId;
      entity.preferencesJson = _.isEmpty(values.preferencesJson) ? null : values.preferencesJson;
      entity.configJson = _.isEmpty(values.configJson) ? null : values.configJson;
      entity.defaultLabelTemplate = values.defaultLabelTemplate;
      entity.specificLabelTemplates = values.specificsLabelTemplate;
      entity.roles = values.roles || [];
      entity.assignedAircrafts = values.assignedAircrafts || [];
      this.backupProfile(entity);
    },

    getId: function (entity) {
      return 'me'; //entity.login;
    },

    backupProfile: function (profile) {
      localStorage.setItem('profile', JSON.stringify(profile));
    },

    restoreProfile: function () {
      var profileString = localStorage.getItem('profile');
      return (angular.isString(profileString) && JSON.parse(profileString));
    }

  });

  Object.defineProperties(UserProfileNgStore.prototype, {
    userProfile: {
      get: function () {
        return (this._entities.length && this._entities[0]) || this.restoreProfile();
      }
    }
  });

  var userProfileNgStore = new UserProfileNgStore();

  this.$get = ['userProfileStore', function userProfileNgStoreFactory(userProfileStore, userService) {
    userProfileNgStore.store = userProfileStore;
    return userProfileNgStore;
  }];

}]);
