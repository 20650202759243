/*jslint browser: true, nomen:true */
/*global App, $, _ */


App.controller('ChatDiscutionsCtrl', [
  '$scope',
  'chatService',
  'flightNgStore',
  function ($scope, chatService, flightNgStore) {
    'use strict';

    $scope.displayedNumber = 2;
    $scope.discutions = chatService.openedWindows;

    $scope.getFlight = function (id) {
      return flightNgStore.find(id);
    };

    $scope.close = function (chatId) {
      chatService.closeChat(chatId);
    };
    $scope.open = function (chatId) {
      chatService.openChat(chatId);
    };

    $scope.isMinimised = function (chatId) {
      return chatService.states[chatId] === chatService.MINIMISED;
    };

    $scope.toggleMinimise = function (chatId) {
      var opened = chatService.states[chatId] === chatService.OPEN;
      if (opened) {
        chatService.reduceChat(chatId);
      } else {
        chatService.maximiseChat(chatId);
      }
    };
    $scope.unreadMessages = function (id) {
      var flight = $scope.getFlight(id),
        msgs = (flight && flight.messages) || [];
      return _.filter(msgs, function (m) {
        return _.isEmpty(m.sender) && _.size(m.readBy) === 0;
      });
    };

    $scope.totalUnreadMessages = function () {
      return _.chain($scope.discutions)
        .take($scope.discutions.length - 2)
        .reduce(function (total, id) {
          return total + _.size($scope.unreadMessages(id));
        }, 0)
        .value();

    };

    $scope.flights = flightNgStore.allFlights;

    //watch active flights to close inactive chat windows.
    $scope.$watchCollection('flights', function () {
      chatService.openedWindows.forEach(function (id) {
        if (!flightNgStore.find(id)) {
          chatService.closeChat(id);
        }
      });
    });

  }
]);
