
App.controller('areaWarningController', ['$scope', '$uibModalInstance', 'area', function ($scope, $uibModalInstance, area) {
  'use strict';

  this.area = area;

  this.cancel = function () {
    $uibModalInstance.close();
    console.log('cancel delete area');
  }

  this.deleteArea = function () {
    // TODO Call this from the Confirm button on the Warning Window
    // TODO Send a DELETE request to the endpoint
    // TODO Remove the area from the model/Update the model
    $uibModalInstance.close();
    var deleteNotice = 'actually delete area ' + this.area.name + ' (REMOVE ME)';
    console.log(deleteNotice);
  };

}]);
