/*jslint browser: true, plusplus:true, nomen: true */
/*global $, angular*/

(function (global) {
  'use strict';



  var eventSplitter = /\s+/,
    PubSub = function () {};
  PubSub.prototype = Object.create(Object.prototype);
  PubSub.prototype.constructor = PubSub;


  /**
   * Bind a callback function to an object.
   * The callback will be invoked whenever the event is fired.
   * If you have a large number of different events on a page, the convention is to use colons to namespace them: "poll:start", or "change:selection".
   * To supply a context value for this when the callback is invoked, pass the optional third argument: model.subscribe('change', this.render, this)
   *
   * Callbacks bound to the special "all" event will be triggered when any event occurs, and are passed the name of the event as the first argument.
   * For example, to proxy all events from one object to another:
   */
  PubSub.prototype.subscribe = PubSub.prototype.on = function (name, callback, context, once) {
    var names = [name],
      i = 0,
      events;
    this._events = this._events || {};
    //if we have multiple events in name
    if (name && eventSplitter.test(name)) {
      names = name.split(eventSplitter);
    }
    for (i = 0; i < names.length; i++) {
      name = names[i];
      events = this._events[name] || [];
      events.push({
        callback: callback,
        context: context,
        once: once || false,
        ctx: context || this
      });
      this._events[name] = events;
    }
    return this;
  };

  PubSub.prototype.once = function (name, callback, context) {
    return this.on(name, callback, context, true);
  };

  /**
   * Remove a previously-bound callback function from an object.
   * If no context is specified, all of the versions of the callback with different contexts will be removed.
   * If no callback is specified, all callbacks for the event will be removed.
   * If no event is specified, callbacks for all events will be removed.
   */
  PubSub.prototype.unsubscribe = PubSub.prototype.off = function (name, callback, context) {
    this._events = this._events || {};
    if (!name && !callback && !context) {
      //remove all listeners from all context
      this._events = {};
      return this;
    }
    // only event with the given name or all event if name is undefined
    var names = name ? [name] : Object.keys(this._events),
      events,
      retain,
      ev,
      i,
      j,
      k,
      l;

    for (i = 0, l = names.length; i < l; i++) {
      name = names[i];
      events = this._events[name];
      if (events) {
        this._events[name] = retain = [];
        if (callback || context) {
          for (j = 0, k = events.length; j < k; j++) {
            ev = events[j];
            //keep only events that do not have the given callback or context has params
            if ((callback && callback !== ev.callback && callback !== ev.callback._callback) || (context && context !== ev.context)) {
              retain.push(ev);
            }
          }
        }
        if (!retain.length) {
          //remove the registration if nobody is listening
          delete this._events[name];
        }
      }
    }

    return this;
  };

  /**
   * Trigger callbacks for the given event.
   * Subsequent arguments to trigger will be passed along to the event callbacks.
   */
  PubSub.prototype.publish = PubSub.prototype.trigger = function (name) {
    if (!this._events) {
      return this;
    }
    //remove first argument (ie. name)
    var args = [],
      events = this._events[name],
      allEvents = this._events.all,
      ev,
      l,
      i = -1,
      a, al,
      stopPropagation;
    for (a = 1, al = arguments.length; a < al; a++) {
      args[a - 1] = arguments[a];
    }
    if (events) {

      l = events.length;
      while (++i < l && !stopPropagation) {
        ev = events[i];
        stopPropagation = ev.callback.apply(ev.ctx, args) === false;
        if (ev.once) {
          events.splice(i--, 1);
          l--;
        }
      }
    }
    if (allEvents) {
      events = allEvents;
      l = events.length;
      i = -1;
      //call listeners on "all"
      while (++i < l && !stopPropagation) {
        ev = events[i];
        stopPropagation = ev.callback.apply(ev.ctx, arguments) === false;
        if (ev.once) {
          events.splice(i--, 1);
          l--;
        }
      }
    }
    return this;
  };

  global.PubSub = PubSub;

}(window));
