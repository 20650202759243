App.factory('userRestService', ['$http', function ($http) {
  return {
    getUsers: function (customerId) {
      return $http({
        method: 'GET',
        url: window.backendPrefix + '/customer/' + customerId + '/user',
        withCredentials: true,
        responseType: 'json'
      }).then(function successCallback (response) {
        return response.data;
      }, function errorCallback (response) {
        console.error(response);
        return Error('Error fetching users. Error: ' + response.status + ' ' + response.statusText);
      });
    },
    createUser: function (customerId, userDto) {
      return $http({
        method: 'POST',
        url: window.backendPrefix + '/customer/' + customerId + '/user',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'json',
        data: userDto
      }).then(function successCallback (response) {
        return response.data;
      }, function errorCallback (response) {
        console.error(response);
        return Error('Error creating user. Error: ' + response.status + ' ' + response.statusText);
      });
    },
    updateUser: function (customerId, userDto) {
      return $http({
        method: 'PUT',
        url: window.backendPrefix + '/customer/' + customerId + '/user/' + userDto.id,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'json',
        data: userDto
      }).then(function successCallback (response) {
        return response.data;
      }, function errorCallback (response) {
        console.error(response);
        return Error('Error updating user. Error: ' + response.status + ' ' + response.statusText);
      });
    }
  };
}]);
