// Vendor CSS
require("../vendor/ol/ol.css");
require("../node_modules/angular-ui-bootstrap/dist/ui-bootstrap-csp.css");
require("../node_modules/angular-rangeslider/angular.rangeSlider.css");
require("../node_modules/angular-loading-bar/build/loading-bar.min.css");
require("../node_modules/perfect-scrollbar/dist/css/perfect-scrollbar.css");
require("../node_modules/angular-bootstrap-colorpicker/css/colorpicker.css");
require("../node_modules/ng-tags-input/build/ng-tags-input.min.css");
require("../node_modules/ng-tags-input/build/ng-tags-input.bootstrap.min.css");
require("../node_modules/ui-select/dist/select.min.css");
require("../node_modules/bootstrap-daterangepicker/daterangepicker.css");
require("../node_modules/angular-moment-picker/dist/angular-moment-picker.min.css");

// Vendor JS
import 'less'
import 'drag-drop-webkit-mobile'
import 'scrollmonitor'
import 'angular'
import 'angular-sanitize'
import 'angular-touch'
import 'angular-ui-bootstrap'
import 'angular-rangeslider'
import 'angular-ui-router'
import 'angular-animate'
import 'angular-smart-table'
import 'angular-loading-bar'
import 'angular-viewport-watch'
import 'bootstrap'
import 'checklist-model'
import 'angular-perfect-scrollbar'
import 'angular-bootstrap-colorpicker'
import 'angular-drag-and-drop-lists'
import 'toastr'
import 'd3'
import 'papaparse'
import 'ng-tags-input'
import 'ui-select'
import 'bootstrap-daterangepicker'
import 'angular-moment-picker'

// Our CSS

require('./css/app.less');

// Our JS
import 'config' // WARNING: Magic alias that webpack will resolve. This is a kludge we should remove.
import './js/config/browsermobile'
import './js/app'
import './js/config/flight-field-definitions'
import './js/config/default-preferences'
import './js/config/maps'
import './js/config/schneider-layer'
import './js/config/nav-layers'
import './js/config/general-constants'
import './js/plumbing/plumbing'
import './js/map/map-style-provider'
import './js/map/aircraft-tooltip-provider'
import './js/map/default-colors-const'
import './js/map/zoom-infos-control'
import './js/helpers/error-code'
import './js/helpers/validation'
import './js/helpers/pubsub'
import './js/helpers/font-check'
import './js/helpers/filter-flight'
import './js/helpers/display-filters'
import './js/helpers/flight-level-formatter'
import './js/helpers/deviation'
import './js/helpers/map-computation'
import './js/helpers/mime-type'
import './js/helpers/tooltip-generator'
import './js/helpers/icon-generator'
import './js/helpers/opacity-generator'
import './js/helpers/tile-grid-generator'
import './js/helpers/scroll-to'
import './js/helpers/server-time'
import './js/helpers/geometry-utils'
import './js/helpers/utils'
import './js/helpers/arc'
import './js/helpers/service-worker-registration'
import './js/helpers/format/airport-feature-formatter'
import './js/helpers/format/waypoint-feature-formatter'
import './js/helpers/format/airway-feature-formatter'
import './js/helpers/format/airspace-feature-formatter'
import './js/helpers/format/navaid-feature-formatter'
import './js/helpers/format/firuir-feature-formatter'
import './js/helpers/format/restrictiveAirspaces-feature-formatter'
import './js/helpers/format/holdings-feature-formatter'
import './js/providers/generics/generic-store'
import './js/providers/generics/generic-filtered-store'
import './js/providers/customer-id'
import './js/providers/flight-store-provider'
import './js/providers/flight-ng-store-provider'
import './js/providers/aircraft-info-store-provider'
import './js/providers/aircraft-info-ng-store-provider'
import './js/providers/airport-data-store-provider'
import './js/providers/airport-data-ng-store-provider'
import './js/providers/alert-store-provider'
import './js/providers/alert-ng-store-provider'
import './js/providers/airline-store-provider'
import './js/providers/user-store-provider'
import './js/providers/user-ng-store-provider'
import './js/providers/user-profile-store-provider'
import './js/providers/user-profile-ng-store-provider'
import './js/providers/data-store-provider'
import './js/providers/customer-store-provider'
import './js/providers/customer-ng-store-provider'
import './js/providers/super-customer-store-provider'
import './js/providers/super-customer-ng-store-provider'
import './js/providers/flight-replay-store-provider'
import './js/providers/flight-replay-ng-store-provider'
import './js/providers/notification-store-provider'
import './js/providers/notification-ng-store-provider'
import './js/providers/feature-store-provider'
import './js/providers/feature-ng-store-provider'
import './js/providers/server-instance-provider'
import './js/providers/intersection-store-provider'
import './js/providers/intersection-ng-store-provider'
import './js/providers/desk-assignment-store-provider'
import './js/providers/desk-assignment-ng-store-provider'
import './js/services/rest-request-factory'
import './js/services/rest-service'
import './js/services/user-service'
import './js/services/airline-rest-service'
import './js/services/user-rest-service'
import './js/services/chat-service'
import './js/services/flight-filter-service'
import './js/services/alert-service'
import './js/services/nav-layers-service'
import './js/services/aircraft-rest-service'
import './js/services/airport-capacity-service'
import './js/services/defined-area-factory'
import './js/services/defined-area-service'
import './js/services/defined-area-rest-service'
import './js/services/favorite-factory'
import './js/services/favorite-rest-service'
import './js/services/favorite-default-service'
import './js/services/route-factory'
import './js/services/route-leg-factory'
import './js/services/route-rest-service'
import './js/services/customer-nfp-config-rest-service'
import './js/services/support-rest-service'
import './js/services/map-position-service'
import './js/services/ground-vehicle-service'
import './js/directives/sh-table-autorefresh'
import './js/directives/utc-timepicker'
import './js/directives/date-input.directive'
import './js/directives/defined-area-name.directive'
import './js/directives/latitude-input.directive'
import './js/directives/longitude-input.directive'
import './js/components/settings-panel'
import './js/components/areas/area-list.component'
import './js/components/areas/defined-area-edit.component'
import './js/components/map/favorites-list.component'
import './js/components/map/route-list.component'
import './js/components/map/active-weather-layers.component'
import './js/controllers/sysadmin/super-page-controller'
import './js/controllers/sysadmin/super-customer-edit-controller'
import './js/controllers/sysadmin/super-customer-list-controller'
import './js/controllers/sysadmin/super-airport-controller'
import './js/controllers/sysadmin/super-support-controller'
import './js/controllers/admin/admin-page-controller'
import './js/controllers/admin/admin-user-list-controller'
import './js/controllers/admin/admin-user-form-controller'
import './js/controllers/admin/admin-aircraft-list-controller'
import './js/controllers/admin/admin-aircraft-form-controller'
import './js/controllers/admin/admin-alert-list-controller'
import './js/controllers/admin/admin-alert-form-controller'
import './js/controllers/admin/admin-alert-sms-form-controller'
import './js/controllers/admin/admin-alert-mail-form-controller'
import './js/controllers/admin/admin-color-form-controller'
import './js/controllers/admin/admin-acars-form-controller'
import './js/controllers/admin/admin-map-form-controller'
import './js/controllers/admin/admin-template-list-controller'
import './js/controllers/admin/admin-template-allocation-controller'
import './js/controllers/admin/admin-flightplan-form-controller'
import './js/controllers/login-page-controller'
import './js/controllers/reset-password-controller'
import './js/controllers/settings/settings-page-controller'
import './js/controllers/settings/settings-aircrafts-controller'
import './js/controllers/settings/settings-airlines-controller'
import './js/controllers/settings/settings-airports-controller'
import './js/controllers/settings/settings-flight-details-controller'
import './js/controllers/settings/settings-map-controller'
import './js/controllers/settings/settings-password-controller'
import './js/controllers/settings/settings-timezone-controller'
import './js/controllers/settings/settings-tags-controller'
import './js/controllers/settings/settings-airspace-controller'
import './js/controllers/settings/settings-template-list-controller'
import './js/controllers/settings/settings-template-allocation-controller'
import './js/controllers/settings/settings-zoom-levels-controller.js'
import './js/controllers/map/quick-access/nav-layers-selector.component'
import './js/controllers/map/widgets/side-layers-info.component'
import './js/controllers/map/widgets/nav-search.component'
import './js/controllers/map/widgets/active-weather-layers-controller'
import './js/controllers/map/areas/defined-area-edit-controller'
import './js/controllers/map/map-page-controller'
import './js/controllers/map/map-view-controller'
import './js/controllers/map/map-search-controller'
import './js/controllers/map/map-toolbox-panel-controller'
import './js/controllers/map/favorite-aiports-form-controller'
import './js/controllers/map/favorites-add-form-controller'
import './js/controllers/map/favorites-list-controller'
import './js/controllers/map/flights-table-controller'
import './js/controllers/map/flight-detail-controller'
import './js/controllers/map/flight-manual-alert-controller'
import './js/controllers/map/flight-detail-altitude-graph-controller'
import './js/controllers/map/flight-detail-eta-graph-controller'
import './js/controllers/map/flight-detail-notes-controller'
import './js/controllers/map/flight-detail-add-manual-report-controller'
import './js/controllers/map/flight-detail-link-flight-controller'
import './js/controllers/map/flight-detail-pax-controller'
import './js/controllers/map/flight-detail-crew-controller'
import './js/controllers/map/areas/area-list-controller'
import './js/controllers/map/areas/area-warning-controller'
import './js/controllers/map/route-list-controller'
import './js/controllers/map/route-info-controller'
import './js/controllers/notifications-center-controller'
import './js/controllers/statistics/statistics-page-controller'
import './js/controllers/statistics/statistics-taxitime-controller'
import './js/controllers/statistics/statistics-alerts-controller'
import './js/controllers/statistics/statistics-alerts-select-aircraft-controller'
import './js/controllers/statistics/statistics-holding-controller'
import './js/controllers/statistics/statistics-airportdelays-controller'
import './js/controllers/airport/airport-page-controller'
import './js/controllers/airport/airport-details-controller'
import './js/controllers/airport/airport-capacity-controller'
import './js/controllers/airport/airport-taxi-time-controller'
import './js/controllers/airport/airport-surface-tracking-controller'
import './js/controllers/chat/chat-discutions-controller'
import './js/controllers/chat/chat-notification-controller'
import './js/controllers/chat/chat-window-controller'
import './js/controllers/replay/replay-page-controller'
import './js/controllers/replay/replay-search-controller'
import './js/controllers/replay/replay-map-controller'
import './js/controllers/replay/replay-control-controller'
import './js/controllers/airspace/airspace-page-controller'
import './js/controllers/airspace/airspace-capacity-controller'
import './js/controllers/airspace/airspace-map-controller'
import './js/controllers/desk-assignment/desk-assignment-form-controller'
import './js/controllers/sysadmin/super-aircraft-summary-controller.js'
