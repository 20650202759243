/*jslint browser: true, plusplus:true, nomen:true*/
/*global angular, ol, App, Promise, _ */

(function () {
  'use strict';

  function AirportPageCtrl($rootScope, $scope, $state, $stateParams, $q, $filter, airportDataNgStore, customerNgStore) {
    var currentState, currentParam, tafUrl, metarUrl, filter, notamUrl;

    let customer = customerNgStore.customer;
    let customerParameters = customer.parameters;

    if(customerParameters.surfaceTracking) {
        $scope.showSurfaceTracking = customerParameters.surfaceTracking;
    } else {
        $scope.showSurfaceTracking = false;
    }

    if (!$rootScope.isLogged()) {
      currentState = $state.current.name;
      currentParam = $state.params;
      $state.go('login', {
        returnTo: currentState,
        returnToParams: currentParam
      });
      return;
    }
    filter = $filter('filter');

    //
    // NOTAMs
    // ------------------------------------------------------------------------

    // https://pilotweb.nas.faa.gov
    notamUrl = window.notamHost + '/PilotWeb/notamRetrievalByICAOAction.do?method=displayByICAOs&retrieveLocId={ICAO}&reportType=RAW&formatType=DOMESTIC&actionType=notamRetrievalByICAOs';

    $scope.refreshNotams = function () {
      var url, fetchNotam;
      // NOTAM
      $scope.notams = [];
      url = notamUrl.replace('{ICAO}', $scope.airport.icao);
      fetchNotam = window.fetch(url).then(function (response) {
        if (response.ok) {
          return response.text();
        } else if (response.status === 404) {
          $scope.notams.push({
            raw: 'No data for this airport.'
          });
        } else {
          $scope.notams.push({
            raw: response.statusText
          });
        }
        $scope.$applyAsync();
        return Promise.reject(response);
      }).then(function (text) {
        var notamRegExp, notam;
        // Notams are between <PRE> tags
        notamRegExp = /<PRE>([^<]*)<\/PRE>/gi;
        while ((notam = notamRegExp.exec(text)) !== null) {
          if (notam.index === notamRegExp.lastIndex) {
            notamRegExp.lastIndex++;
          }
          $scope.notams.push({
            raw: notam[1]
          });
        }
        if ($scope.notams.length === 0) {
          $scope.notams.push({
            raw: 'No active NOTAMs for this location.'
          });
        }
        $scope.$applyAsync();
      })['catch'](function (error) {
        //console.error(error);
        $scope.notams.push('Error while retrieving data.');
        $scope.$applyAsync();
      });
      return fetchNotam;
    };

    //
    // Weather
    // ------------------------------------------------------------------------

    // template URLs
    tafUrl = window.weatherHost + '/pub/data/forecasts/taf/stations/{ICAO}.TXT';
    metarUrl = window.weatherHost + '/pub/data/observations/metar/stations/{ICAO}.TXT';

    $scope.weather = {
      taf: '',
      metar: ''
    };

    $scope.refreshWeather = function () {
      var url, fetchMetar, fetchTaf;
      // METAR
      url = metarUrl.replace('{ICAO}', $scope.airport.icao);
      fetchMetar = window.fetch(url).then(function (response) {
        if (response.ok) {
          return response.text();
        } else if (response.status === 404) {
          $scope.weather.metar = 'No data for this airport.';
        } else {
          $scope.weather.metar = response.statusText;
        }
        $scope.$applyAsync();
        return Promise.reject(response);
      }).then(function (text) {
        $scope.weather.metar = text;
        $scope.$applyAsync();
      })['catch'](function () {
        $scope.weather.metar = 'No data for this airport.';
        $scope.$applyAsync();
      });
      // TAF
      url = tafUrl.replace('{ICAO}', $scope.airport.icao);
      fetchTaf = window.fetch(url).then(function (response) {
        if (response.ok) {
          return response.text();
        } else if (response.status === 404) {
          $scope.weather.taf = 'No data for this airport.';
        } else {
          $scope.weather.taf = response.statusText;
        }
        $scope.$applyAsync();
        return Promise.reject(response);
      }).then(function (text) {
        $scope.weather.taf = text;
        $scope.$applyAsync();
      })['catch'](function () {
        $scope.weather.taf = 'No data for this airport.';
        $scope.$applyAsync();
      });
      return Promise.all([fetchMetar, fetchTaf]);
    };

    //
    // Runways
    // ------------------------------------------------------------------------
    $scope.refreshRunways = function () {
      return airportDataNgStore.get($scope.airport.icao).then(function (airport) {
        $scope.airport = airport;
        return Promise.resolve(airport);
      })['catch'](function (error) {
        return Promise.reject();
      });
    };

    //
    // Current airport
    // ------------------------------------------------------------------------

    $scope.iataOrIcaoUpdate = function (iataOrIcao) {
      if (!!iataOrIcao) {
        // try to find details
        airportDataNgStore.loadData(true).then(function (airports) {
          var airport;
          //try to find it by icao first
          airport = airportDataNgStore.findByIcao(iataOrIcao);
          if (!airport) {
            //if not found try by iata
            airport = _.find(airports, {
              iata: iataOrIcao
            });
          }
          if (_.isUndefined(airport)) {
            //we did not find a match
            // Search state
            $scope.airport = null;
            $state.go('airport.search');
          } else {
            $scope.airport = airport;
            $scope.refreshWeather();
            $scope.refreshNotams();
            $scope.refreshRunways();
          }
        });

      } else {
        // Search state
        $scope.airport = null;
        $state.go('airport.search');
      }
    };

    //
    // Search Airport
    // ------------------------------------------------------------------------

    // fetch airports for autocomplete
    $scope.airports = function (query) {
      return $q(function (resolve, reject) {
        airportDataNgStore.loadData(true).then(function (airports) {
          var filtered = _.sortBy(filter(airports, query), 'icao');
          resolve(filtered);
        }, reject);
      });
    };

    $scope.userAirports = [];

    airportDataNgStore.loadData(true).then(function (airports) {
      $scope.user.configuration.displayedAirports.forEach(function (icao) {
        var airport = airportDataNgStore.findByIcao(icao);
        if (airport) {
          $scope.userAirports.push(airport);
        }
      });
      $scope.$applyAsync();
    });

    // on select
    $scope.selectAirport = function (airport) {
      $scope.airport = airport;
      $scope.refreshWeather();
      $scope.refreshRunways();
      $state.go('airport.details.weather', {
        iataOrIcao: airport.icao
      });
    };

    // airport coordinates on map
    $scope.showOnMap = function () {
      // set airport coordinates
      localStorage.setItem('mapCenter', JSON.stringify(airportDataNgStore.airportsByIcao[$scope.airport.icao].olFeature.getGeometry().getCoordinates()));
      localStorage.setItem('mapResolution', $scope.airport.type === 'LARGE' ? 20 : 10);
      // go to map
      $state.go('map');
    };
  }

  App
    .controller('AirportPageCtrl', ['$rootScope', '$scope', '$state', '$stateParams', '$q', '$filter', 'airportDataNgStore', 'customerNgStore', AirportPageCtrl]);
}());
