/*jslint browser: true, plusplus:true */
/*global ol, App */

(function (global) {
  'use strict';
  App.constant('seLayers', {
    'stormEchoTopsUS': {
      name: 'ECHO_TOPS',
      depth: 11,
      group: false,
      opacity: 0.5,
      displayName: 'Storm Echo Tops US',
      zIndexLayer: true
    },
    'lightningCloudStrokes': {
      name: function (t, v) {
        return 'LTGCTC_' + t + 'MIN';
      },
      depth: 10,
      group: true,
      opacity: 0.5,
      displayName: 'Lightning Cloud Strokes',
      zIndexLayer: true
    },
    'lightningGroundStrokes': {
      name: function (t, v) {
        return 'LTGCTG_' + t + 'MIN';
      },
      depth: 10,
      group: true,
      opacity: 0.5,
      displayName: 'Lightning Ground Strokes',
      zIndexLayer: true
    },
    'lightningGroundStrokesDensity': {
      name: 'LTG_DENSITY',
      depth: 5,
      group: true,
      opacity: 0.5,
      displayName: 'Lightning Ground Strokes Density',
      zIndexLayer: true
    },
    'radarUS': {
      name: function (t, v) {
        return 'RADAR_US' + t;
      },
      depth: 5,
      group: false,
      opacity: 0.5,
      displayName: 'Radar US'
    },
    'radarEU': {
      name: function (t, v) {
        return 'RADAR_EU' + t;
      },
      depth: 5,
      group: false,
      opacity: 0.5,
      displayName: "Radar EU"
    },
    'radarCN': {
      name: function (t, v) {
        return 'RADAR_CN' + t;
      },
      depth: 5,
      group: false,
      opacity: 0.5,
      displayName: 'Radar CN'
    },
    'radarAU': {
      name: function (t, v) {
        return 'RADAR_AU' + t;
      },
      depth: 5,
      group: false,
      opacity: 0.5,
      displayName: 'Radar AU'
    },
    'radarJA': {
      name: function (t, v) {
        return 'RADAR_JA' + t;
      },
      depth: 5,
      group: false,
      opacity: 0.5,
      displayName: 'Radar JA'
    },
    'radarUsAnimated': {
      name: 'Radar_US',
      layerToHide: 'radarUS',
      animate: true, 
      depth: 5,
      group: false,
      opacity: 0.5,
      displayName: 'Radar US Animated'
    },
    'radarEuAnimated': {
      name: "Radar_EU",
      layerToHide: 'radarEU', 
      animate: true, 
      depth: 5,
      group: false,
      opacity: 0.5,
      displayName: "Radar EU Animated"
    },
    'radarCnAnimated': {
      name: 'Radar_CN',
      layerToHide: 'radarCN',
      animate: true, 
      depth: 5,
      group: false,
      opacity: 0.5,
      displayName: 'Radar CN Animated'
    },
    'radarAuAnimated': {
      name: 'Radar_AU',
      layerToHide: 'radarAU',
      animate: true, 
      depth: 5,
      group: false,
      opacity: 0.5,
      displayName: 'Radar AU Animated'
    },
    'radarJaAnimated': {
      name: 'Radar_JA',
      layerToHide: 'radarJA',
      animate: true, 
      depth: 5,
      group: false,
      opacity: 0.5,
      displayName: 'Radar JA Animated'
    },
    'radarCoverageArea': {
      name: 'RADAR_COVERAGE_AREAS',
      depth: 8,
      group: false
    },
    'nwsBulletinWarn': {
      name: 'NWS_WARNINGS',
      depth: 2,
      opacity: 0.5,
      displayName: 'NWS Bulletin Warnings'
    },
    'nwsBulletinAdvisories': {
      name: 'NWS_ADVISORIES',
      depth: 2,
      opacity: 0.5,
      displayName: 'NWS Bulletin Advisories'
    },
    'nwsBulletinWatches': {
      name: 'NWS_WATCHES',
      depth: 2,
      opacity: 0.5,
      displayName: 'NWS Bulletin Watches'
    },
    'nwsBulletinWarnAMI': {
      name: 'NWS_WARNINGS_AMI',
      depth: 5,
      opacity: 0.5,
      displayName: 'NWS Bulletin Warnings AMI'
    },
    'nwsBulletinAdvisoriesAMI': {
      name: 'NWS_ADVISORIES_AMI',
      depth: 5,
      opacity: 0.5,
      displayName: 'NWS Bulletin Advisories AMI'
    },
    'meteoAlarmVeryDangerousToday': {
      name: 'METEOALARM_VERYDANGEROUS_TODAY',
      depth: 2,
      opacity: 0.5,
      displayName: 'EUMetNet MeteoAlarm Bulletins - Very Dangerous - Today'
    },
    'meteoAlarmDangerousToday': {
      name: 'METEOALARM_DANGEROUS_TODAY',
      depth: 2,
      opacity: 0.5,
      displayName: 'EUMetNet MeteoAlarm Bulletins - Dangerous - Today'
    },
    'meteoAlarmPotentiallyDangerousToday': {
      name: 'METEOALARM_POTENTIALLYDANGEROUS_TODAY',
      depth: 2,
      opacity: 0.5,
      displayName: 'EUMetNet MeteoAlarm Bulletins - Potentially Dangerous - Today'
    },
    'meteoAlarmVeryDangerousTomorrow': {
      name: 'METEOALARM_VERYDANGEROUS_TOMORROW',
      depth: 2,
      opacity: 0.5,
      displayName: 'EUMetNet MeteoAlarm Bulletins - Very Dangerous - Tomorrow'
    },
    'meteoAlarmDangerousTomorrow': {
      name: 'METEOALARM_DANGEROUS_TOMORROW',
      depth: 2,
      opacity: 0.5,
      displayName: 'EUMetNet MeteoAlarm Bulletins - Dangerous - Tomorrow'
    },
    'meteoAlarmPotentiallyDangerousTomorrow': {
      name: 'METEOALARM_POTENTIALLYDANGEROUS_TOMORROW',
      depth: 2,
      opacity: 0.5,
      displayName: 'EUMetNet MeteoAlarm Bulletins - Potentially Dangerous - Tomorrow'
    },
    'infraredSatN1': {
      layer: "_CURRENT",
      label: "Current",
      name: function (t, v) {
        return 'INFRAREDSAT_N1' + t;
      },
      depth: 1,
      group: true,
      opacity: 0.5,
      displayName: 'Infrared Satellite - North Pacific'
    },
    'infraredSatN2': {
      name: function (t, v) {
        return 'INFRAREDSAT_N2' + t;
      },
      depth: 1,
      group: true,
      opacity: 0.5,
      displayName: 'Infrared Satellite - North America'
    },
    'infraredSatN3': {
      name: function (t, v) {
        return 'INFRAREDSAT_N3' + t;
      },
      depth: 1,
      group: true,
      opacity: 0.5,
      displayName: 'Infrared Satellite - Europe'
    },
    'infraredSatN4': {
      name: function (t, v) {
        return 'INFRAREDSAT_N4' + t;
      },
      depth: 1,
      group: true,
      opacity: 0.5,
      displayName: 'Infrared Satellite - India'
    },
    'infraredSatN5': {
      name: function (t, v) {
        return 'INFRAREDSAT_N5' + t;
      },
      depth: 1,
      group: true,
      opacity: 0.5,
      displayName: 'Infrared Satellite - Japan'
    },
    'infraredSatS1': {
      name: function (t, v) {
        return 'INFRAREDSAT_S2' + t;
      },
      depth: 1,
      group: true,
      opacity: 0.5,
      displayName: 'Infrared Satellite - South America'
    },
    'infraredSatS2': {
      name: function (t, v) {
        return 'INFRAREDSAT_S1' + t;
      },
      depth: 1,
      group: true,
      opacity: 0.5,
      displayName: 'Infrared Satellite - South Pacific'
    },
    'infraredSatS3': {
      name: function (t, v) {
        return 'INFRAREDSAT_S3' + t;
      },
      depth: 1,
      group: true,
      opacity: 0.5,
      displayName: 'Infrared Satellite - Africa'
    },
    'infraredSatS4': {
      name: function (t, v) {
        return 'INFRAREDSAT_S4' + t;
      },
      depth: 1,
      group: true,
      opacity: 0.5,
      displayName: 'Infrared Satellite - Indian Ocean'
    },
    'infraredSatS5': {
      name: function (t, v) {
        return 'INFRAREDSAT_S5' + t;
      },
      depth: 1,
      group: true,
      opacity: 0.5,
      displayName: 'Infrared Satellite - Australia'
    },
    'visibleSatN1': {
      layer: "_CURRENT",
      label: "Current",
      name: function (t, v) {
        return 'VISIBLESAT_N1' + t;
      },
      depth: 1,
      group: true,
      opacity: 0.5,
      displayName: 'Visible Satellite - North Pacific'
    },
    'visibleSatN2': {
      layer: "_CURRENT",
      label: "Current",
      name: function (t, v) {
        return 'VISIBLESAT_N2' + t;
      },
      depth: 1,
      group: true,
      opacity: 0.5,
      displayName: 'Visible Satellite - North America'
    },
    'visibleSatN3': {
      layer: "_CURRENT",
      label: "Current",
      name: function (t, v) {
        return 'VISIBLESAT_N3' + t;
      },
      depth: 1,
      group: true,
      opacity: 0.5,
      displayName: 'Visible Satellite - Europe'
    },
    'visibleSatN5': {
      layer: "_CURRENT",
      label: "Current",
      name: function (t, v) {
        return 'VISIBLESAT_N5' + t;
      },
      depth: 1,
      group: true,
      opacity: 0.5,
      displayName: 'Visible Satellite - Japan'
    },
    'tropopauseHeight': {
      name: function (t, v) {
        return 'TROPOPAUSE_HEIGHT_FCST_' + t + 'HR';
      },
      depth: 1,
      group: true,
      opacity: 0.5,
      displayName: 'Tropopause Heights'
    },
    'tropopauseLow': {
      name: function (t, v) {
        return 'TROPOPAUSE_LOW_FCST_' + t + 'HR';
      },
      depth: 1,
      group: true,
      opacity: 0.5,
      displayName: 'Tropopause Lows'
    },
    'tropicalForecastAllModels': {
      name: 'TROP_ALL_MODEL_TRACKS_FCST',
      depth: 8,
      group: true,
      opacity: 0.5,
      displayName: 'Tropical Forecast - All Models'
    },
    'tropicalForecastOfficial': {
      name: 'TROP_OFFICIAL_TRACK_FCST',
      depth: 8,
      group: true,
      opacity: 0.5,
      displayName: 'Tropical Forecast - Official Track'
    },
    'tropicalWindSpeedForecast': {
      name: 'TROP_WINDSPEED_KTS_FCST',
      depth: 8,
      group: true,
      opacity: 0.5,
      displayName: 'Tropical Forecast - Wind Speed'
    },
   'tropicalRainForecast': {
      name: 'TROP_RAIN_MM_FCST',
      depth: 8,
      group: true,
      opacity: 0.5,
      displayName: 'Tropical Forecast - Rain'
    },
    'tropicalWaveForecast': {
      name: 'TROP_WAVE_HEIGHT_M_FCST',
      depth: 8,
      group: true,
      opacity: 0.5,
      displayName: 'Tropical Forecast - Wave'
    },
    'tropicalForecastProbability': {
      name: 'TROP_TRACK_PROB_FCST',
      depth: 8,
      group: true,
      opacity: 0.5,
      displayName: 'Tropical Forecast - Track Probability'
    },
    'forecastWindsTempAloft': {
      name: function (t, v) {
        return 'WINDTEMP_FL' + t + '_FCST_' + v;
      },
      depth: 8,
      group: true,
      opacity: 1,
      displayName: 'Forecast Winds & Temps - Aloft',
      zIndexLayer: true
    },
    'forecastWindsSpeed': {
      name: function (t, v) {
        return 'WINDSPEED_FL' + t + '_FCST_' + v;
      },
      depth: 8,
      group: true,
      opacity: 0.5,
      displayName: 'Forecast Winds & Temps - Wind Areas'
     },
     'currentWindsTempAloft': {
       name: function (t, v) {
         return 'WINDTEMP_FL' + t + '_FCST';
       },
       depth: 8,
       group: true,
       opacity: 1,
       displayName: 'Current Winds & Temps - Aloft',
       zIndexLayer: true
      },
      'currentWindsSpeed': {
        name: function (t, v) {
          return 'WINDSPEED_FL' + t + '_FCST';
        },
        depth: 8,
        group: true,
        opacity: 0.5,
        displayName: 'Current Winds & Temps - Wind Areas'
      },
    'currentVolcanoes': {
      name: 'VOLCANO_ADVISORY',
      depth: 8,
      group: false,
      opacity: 0.5,
      displayName: 'Current Volcanoes Forecast'
    },
    'airmetsUsIcing': {
      name: 'AIRMET_ICING',
      depth: 8,
      group: false,
      opacity: 0.5,
      displayName: 'Airmets US Icing'
    },
    'airmetsUsTurbulence': {
      name: 'AIRMET_TURBULENCE',
      depth: 8,
      group: false,
      opacity: 0.5,
      displayName: 'Airmets US Turbulence'
    },
    'sigmetsUsConvective': {
      name: 'CONVECTIVE_SIGMETS',
      depth: 8,
      group: false,
      opacity: 0.5,
      displayName: 'Sigmets US Convective'
    },
    'sigmetsGlobal': {
      name: 'SIGMETS',
      depth: 2,
      group: false,
      opacity: 0.5,
      displayName: 'Sigmets Global'
    },
    'metar': {
      name: 'WEATHER_DEPICTION',
      depth: 9,
      group: false,
      opacity: 0.5,
      displayName: 'METAR'
    },
    'taf': {
      name: 'TAFS',
      depth: 9,
      group: false,
      displayName: 'TAF'
    },
    'currentSurfaceFronts': {
      name: 'FRONTS_CURRENT',
      depth: 9,
      group: true,
      displayName: 'Current Surface Fronts'
    },
    'highLowSurfaceFronts': {
      name: 'HIGH_LOW_CURRENT',
      depth: 9,
      group: true,
      displayName: 'High and low pressure centers'
    },
    'currentMETARWeatherDepiction': {
      name: 'WEATHER_DEPICTION',
      depth: 9,
      group: false,
      displayName: 'Current METAR Weather Depiction'
    },
    'pireps': {
      name: 'PIREPS',
      depth: 10,
      group: false,
      opacity: 0.5,
      displayName: 'PIREPS'
    },
    'edrTurbulenceGlobal': {
      name: 'GBL_TURB_FCST',
      depth: 2,
      group: true,
      opacity: 0.5,
      displayName: 'EDR Turbulence Global',
      apiVersion: 2
    },
    'edrTurbulenceNam': {
      name: 'NAM_TURB_FCST',
      depth: 2,
      group: true,
      opacity: 0.5,
      displayName: 'EDR Turbulence NAM',
      apiVersion: 2
    },
    'icingForecastGlobal': {
      name: 'GBL_ICING_FCST',
      depth: 2,
      group: true,
      opacity: 0.5,
      displayName: 'Icing Forecast Global',
      apiVersion: 2
    },
    'icingForecastNam': {
      name: 'NAM_ICING_FCST',
      depth: 2,
      group: true,
      opacity: 0.5,
      displayName: 'Icing Forecast NAM',
      apiVersion: 2
    },  
   'jetStreamForeCast': {
      name: function (t, v) {
  	    return 'JETSTREAM_FCST_' + t + 'HR';
  	  },
  	  depth: 2,
  	  group : true,
  	  opacity: 0.5,
      displayName: 'Jetstream Forecast'
    },
    'forecastCeiling': {
       name: function (t, v) {
    	  return 'CLOUD_CEILING_' + t + 'HR';
       },
       depth: 2,
       group : true,
       opacity: 0.5,
       displayName: 'Forecast Ceiling'
    },
    'forecastSurfaceFronts': {
        name: function (t, v) {
     	  return 'FRONT_FCST_' + t + 'HR';
     	},
     	depth: 2,
     	group : true,
     	opacity: 0.5,
         displayName: 'Forecast Surface Fronts'
     },      
    'surfaceVisibilityForeCast': {
      name: function (t, v) {
        return 'SURFACE_VISIBILITY_' + t + 'HR';
      },
      depth: 2,
      group : true,
      opacity: 0.5,
      displayName: 'Surface Visibility Forecast'
    },
    'ashForeCast': {
  	  name: function (t, v) {
		return  (t == 'Current')? 'VOLCANIC_ASH' :  'VOLCANIC_ASH_' + t + 'HR';
  	  },
  	  depth: 2,
  	  group : true,
  	  opacity: 0.5,
      displayName: 'Ash Forecast'
    }, 
    'edrThunderstormGlobal': {
      name: 'GBL_THUNDERSTORM_FCST',
      depth: 2,
      group: true,
      opacity: 0.5,
      displayName: 'EDR Thunderstorm Global',
      apiVersion: 2
    },
    'edrThunderstormNam': {
      name: 'NAM_THUNDERSTORM_FCST',
      depth: 2,
      group: true,
      opacity: 0.5,
      displayName: 'EDR Thunderstorm NAM',
      apiVersion: 2
    },
    'ozoneForecast': {
      name: function (t, v) {
        return 'OZONE_FCST_' + t + 'HR';
      },
      depth: 2,
      group : true,
      opacity: 0.5,
      displayName: 'Ozone Forecast'
    },
    'tfrs': {
      name: 'TFRS',
      depth: 2,
      group : false,
      opacity: 1,
      displayName: 'TFRs',
      zIndexLayer: true
    }
  });

}(window));
