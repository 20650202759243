/*jslint browser:true, vars: true */

(function (global) {
  'use strict';

  var canvas, ctx, x, y, lineHeight;

  lineHeight = 12;
  canvas = document.createElement('canvas');
  canvas.width = 94;
  canvas.height = 34;
  ctx = canvas.getContext('2d');
  ctx.mozImageSmoothingEnabled = false;
  ctx.webkitImageSmoothingEnabled = false;
  ctx.msImageSmoothingEnabled = false;
  ctx.imageSmoothingEnabled = false;

  function drawTooltipBg(color, isAlert, numberOfLine, position) {
    // canvas.width = 64;
    canvas.height = lineHeight * numberOfLine + 24;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.fillStyle = color;
    ctx.strokeStyle = 'black';

    ctx.lineCap = 'round';
    ctx.lineWidth = '1';
    x = 7;
    y = 6;
    ctx.moveTo(x, y);
    ctx.beginPath();
    if (position ==1) {
        drawRightLablelTooltipBg(numberOfLine, x, y);
    } else if (position ==2) {
        drawBottomLablelTooltipBg(numberOfLine, x, y);
    } else if (position ==3) {
        drawLeftLablelTooltipBg(numberOfLine, x , y);
    } else {
        drawTopLablelTooltipBg(numberOfLine, x, y);
    }
    ctx.closePath();
    ctx.fill();
    ctx.stroke();

    if (isAlert) {
      x += 64;
      y = lineHeight * numberOfLine + 6;
      ctx.beginPath();
      ctx.arc(x, y, 6, 0, 2 * Math.PI);
      ctx.fill();
      ctx.stroke();

      ctx.fillStyle = 'black';
      ctx.font = "10px Monospace";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText("!", 71, lineHeight * numberOfLine + 6);
    }
    return canvas.toDataURL();
  }

  function drawTopLablelTooltipBg(numberOfLine, x, y) {
       x += 0;
       y += (lineHeight * numberOfLine) + 4;
       ctx.lineTo(x, y);
       x += 10;
       y += 0;
       ctx.lineTo(x, y);
       x += 6;
       y += 6;
       ctx.lineTo(x,y);
       x += 6;
       y -= 6;
       ctx.lineTo(x,y);
       x += 46;
       y += 0;
       ctx.lineTo(x, y);
       x += 0;
       y = 6;
       ctx.lineTo(x, y);
       x += -69;
       y += 0;
       ctx.lineTo(x, y);
     }

  function drawBottomLablelTooltipBg(numberOfLine,x, y) {
       x += 0;
       y += (lineHeight * numberOfLine) + 4;
       ctx.lineTo(x, y);
       x += 68;
       y += 0;
       ctx.lineTo(x, y);
       x += 0;
       y = 6;
       ctx.lineTo(x, y);
       x += -47;
       y += -0;
       ctx.lineTo(x, y);
       x += -6;
       y -= 6;
       ctx.lineTo(x, y);
       x += -6;
       y += 6;
       ctx.lineTo(x, y);
       x += -10;
       y += 0;
       ctx.lineTo(x, y);
     }

  function drawRightLablelTooltipBg(numberOfLine,x, y) {
       x += 0;
       y += (lineHeight * numberOfLine/2) - 4;
       ctx.lineTo(x, y);
       x -= 6;
       y += 6;
       ctx.lineTo(x,y);
       x += 6;
       y += 6;
       ctx.lineTo(x,y);
       x += 0;
       y += (lineHeight * numberOfLine/2) - 4;
       ctx.lineTo(x, y);
       x += 68;
       y += 0;
       ctx.lineTo(x, y);
       x += 0;
       y = 6;
       ctx.lineTo(x, y);
       x += -69;
       y += 0;
       ctx.lineTo(x, y);
     }

  function drawLeftLablelTooltipBg(numberOfLine,x, y) {
       x += 0;
       y += (lineHeight * numberOfLine) + 4;
       ctx.lineTo(x,y);
       x += 68;
       y += 0;
       ctx.lineTo(x, y);
       x += 0;
       y -= (lineHeight * numberOfLine/2) -2;
       ctx.lineTo(x,y);
       x += 6;
       y -= 6;
       ctx.lineTo(x,y);
       x -= 6;
       y -= 6;
       ctx.lineTo(x,y);
       x -= 0;
       y =4;
       ctx.lineTo(x,y);
       x += -69;
       y += 0;
       ctx.lineTo(x, y);
     }


  function drawTooltipText(textLines, labelFont, textColor) {
    var n = textLines.length;
    // canvas.width = 64;
    var labelPixel = labelFont == "small" ? "12px" : labelFont == "medium" ? "15px" : "17px";
    canvas.height = lineHeight * n + 24;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.font = 'normal '+ labelPixel +' "Ubuntu Mono", "Segoe UI Symbol"';//use segoe symbol to prevent emoji redering @see https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/11635143/
    ctx.textAlign = "left";
    ctx.textBaseline = "top";
    var textBorderColor = textColor == 'white' ? 'black' : 'white';
    for (var i = 0; i < n; i++) {
      ctx.fillStyle = textBorderColor;
      ctx.fillText(textLines[i], 11, lineHeight * i + 9);
      ctx.fillStyle = textColor;
      ctx.fillText(textLines[i], 10, lineHeight * i + 8);
    }
    return canvas.toDataURL();
  }


  global.drawTooltipBg = drawTooltipBg;
  global.drawTooltipText = drawTooltipText;

}(window));
