/*jslint browser: true, nomen: true */
/*global App, Plumbing, ol, Promise, GenericStore, angular, console */

App.provider('customerSuperNgStore', [function customerSuperNgStoreProvider() {
  'use strict';

  function CustomerSuperNgStore() {
    GenericStore.call(this);
  }

  CustomerSuperNgStore.prototype = Object.create(GenericStore.prototype);

  angular.extend(CustomerSuperNgStore.prototype, {
    //
    // Constructor

    constructor: CustomerSuperNgStore,
    //
    // Overridable

    newInstance: function (values) {
      var customer = {
        id: values.id,
        name: values.name,
        parameters: values.parameters || '{}',
        airlines: values.airlines,
        managerEmail: values.managerEmail,
        airports: values.airports,
        options: values.options || [],
        customerWeatherPackage:values.customerWeatherPackage || [],
        maxSessions: values.maxSessions,
        userSessions: values.userSessions
      };
      return customer;
    },

    updateInstance: function (entity, values) {
      entity.name = values.name;
      entity.parameters = values.parameters || '{}';
      entity.airlines = values.airlines;
      entity.airports = values.airports;
      entity.managerEmail = values.managerEmail;
      entity.options = values.options;
      entity.customerWeatherPackage = values.customerWeatherPackage;
      entity.maxSessions = values.maxSessions;
      entity.userSessions = values.userSessions;
    },
    getCustomerWeatherPackage: function () {
      return this._store.fetchCustomerWeatherPackage();
    },	
    getAircrafts: function (customer) {
      return this._store.fetchAircrafts(customer.id);
    },
    addAircraft: function (customer, aircraft) {
      return this._store.addAircraft(customer.id, aircraft);
    },
    updateAircraft: function (customer, aircraft) {
      return this._store.updateAircraft(customer.id, aircraft);
    },
    deleteAircraft: function (customer, aircraft) {
      return this._store.deleteAircraft(customer.id, aircraft);
    },
    bulkAddAircrafts: function (customer, formData) {
      return this._store.bulkAddAircrafts(customer.id, formData);
    },
    getUsers: function (customer) {
      return this._store.fetchUsers(customer.id);
    },
    updateUser: function (customer, user) {
      return this._store.updateUser(customer.id, user);
    },
    addUser: function (customer, user) {
      return this._store.addUser(customer.id, user);
    },
    deleteUser: function (customer, user) {
      return this._store.deleteUser(customer.id, user);
    }
  });

  Object.defineProperties(CustomerSuperNgStore.prototype, {
    customers: {
      get: function () {
        return this._entities;
      }
    }
  });

  var customerSuperNgStore = new CustomerSuperNgStore();

  this.$get = ['customerSuperStore', function customerNgStoreFactory(customerSuperStore) {
    customerSuperNgStore.store = customerSuperStore;
    return customerSuperNgStore;
  }];

}]);
