/*jslint browser: true, node: true, plusplus: true */
/*global HTMLFormElement */

(function (global) {
  'use strict';

  /**
   *
   */
  function Validator(form) {
    if (form instanceof HTMLFormElement) {
      this.form = form;
    } else {
      var error = new Error('form must be an instanceof HTMLFormElement');
      throw error;
    }
  }
  Validator.prototype = Object.create(Object.prototype);
  Validator.prototype.constructor = Validator;

  /**
   * @return if the current form inputs are all valid.
   */
  Validator.prototype.isValid = function () {
    return this.form.checkValidity();
  };

  /**
   *
   */
  Validator.prototype.checkValidity = function (check) {
    var defaultFn = function () {
        // force input as valid
        this.setCustomValidity('');
      },
      checkFn = check || defaultFn,
      i = this.form.length;
    while (i--) {
      checkFn.call(this.form[i]);
    }
  };

  /**
   *
   */
  Validator.prototype.updateInputStatus = function (valid, invalid, novalid) {
    var defaultFn = function () {},
      validFn = valid || defaultFn,
      invalidFn = invalid || defaultFn,
      novalidFn = novalid || defaultFn,
      i = this.form.length,
      input;
    while (i--) {
      input = this.form[i];
      if (input.willValidate) {
        if (input.validity.valid) {
          validFn.call(input);
        } else {
          invalidFn.call(input);
        }
      } else {
        novalidFn(input);
      }
    }
  };

  global.Validator = Validator;

}(window));
