/*jslint browser: true, nomen:true */
/*global App, Promise, GenericStore, angular, ol, _ */

App.provider('airportDataNgStore', [function airportDataNgStoreProvider() {
  'use strict';

  var airportDataNgStore,
    transform = ol.proj.getTransform('EPSG:4326', 'EPSG:3857');

  function AirportDataNgStore() {
    GenericStore.call(this);
    this.airportsByType = {};
    this.isLoaded = false;
    this.airportsByIcao = {};
    this.airportsByIata = {};
  }

  AirportDataNgStore.prototype = Object.create(GenericStore.prototype);

  angular.extend(AirportDataNgStore.prototype, {
    //
    // Constructor

    constructor: AirportDataNgStore,

    //
    // Overridable
    getId: function (entity) {
      return entity.icao.toString();
    },

    newInstance: function (values) {
      var entity,
        airportsByType;
      entity = {
        icao: values.icao,
        iata: values.iata,
        name: values.name,
        type: values.type,
        city: values.city,
        country: values.country,
        scheduledService: values.scheduledService,
        latitude: values.latitude,
        longitude: values.longitude,
        altitude: values.altitude,
        timezone: values.timezone
      };
      Object.defineProperty(entity, 'runways', {
        value: values.runways,
        enumerable: false,
        configurable: true
      });

      airportsByType = this.airportsByType[entity.type];
      if (!_.isArray(airportsByType)) {
        airportsByType = this.airportsByType[entity.type] = [];
      }
      airportsByType.push(entity);
      this.airportsByIcao[entity.icao] = entity;
      this.airportsByIata[entity.iata] = entity;
      this.isLoaded = true;
      return entity;
    },

    updateInstance: function (entity, values) {
      // Update runways only
      Object.defineProperty(entity, 'runways', {
        value: values.runways,
        enumerable: false,
        configurable: true
      });
    },

    findByIcao: function (icao) {
      return this.airportsByIcao[icao];
    },

    findByIataOrIcao: function (value) {
      if (!value) {
        return null;
      } else if (value.length === 4) {
        return this.airportsByIcao[value.toUpperCase()];
      } else if (value.length === 3) {
        return this.airportsByIata[value.toUpperCase()];
      } else {
        return null;
      }
    },

    findAirportsFromIcaoIataList: function (list) {
      return _.chain(list).map(this.findByIataOrIcao.bind(this)).filter(function (x) {
        return !!x;
      }).uniq().value();
    },

    loadData: function (force) {
      return new Promise(function (resolve, reject) {
        if (this.isLoaded === true) {
          resolve(this.allAirports);
        } else {
          this.on('sync', function () {
            if (this.isLoaded) {
              this.off('sync', null, this);
              resolve(this.allAirports);
            }
          }, this);
          if (force === true) {
            this.refresh();
          }
        }
      }.bind(this));
    },

    getTaxiTimes: function (airportIcao) {
      return new Promise(function (resolve, reject) {
        this._store.getTaxiTimes(airportIcao).then(function (taxitimes) {
          resolve(taxitimes);
        }.bind(this), reject);
      }.bind(this));
    },

    setTaxiTimes: function (airportIcao, taxiTimes) {
      return new Promise(function (resolve, reject) {
        this._store.setTaxiTimes(airportIcao, taxiTimes).then(function (taxiTimes) {
          resolve(taxiTimes);
        }.bind(this), reject);
      }.bind(this));
    }

  });

  Object.defineProperties(AirportDataNgStore.prototype, {
    allAirports: {
      get: function () {
        return this._entities;
      }
    },
    mediumAirports: {
      get: function () {
        return this.airportsByType.MEDIUM;
      }
    },
    largeAirports: {
      get: function () {
        return this.airportsByType.LARGE;
      }
    },
    notLargeAirports: {
      get: function () {
        return _.filter(this._entities, function (airport) {
          return airport.type !== 'LARGE';
        });
      }
    }
  });

  airportDataNgStore = new AirportDataNgStore();

  this.$get = ['airportDataStore', 'userService', function airportDataNgStoreFactory(airportDataStore, userService) {
    airportDataNgStore.store = airportDataStore;
    userService.off('login', airportDataNgStore.refresh, airportDataNgStore);
    userService.on('login', airportDataNgStore.refresh, airportDataNgStore);
    return airportDataNgStore;
  }];

}]);
//Force preloading of airports on application launch. Don't wait for the user to be loggged or controller to be instanciated
App.run(['airportDataNgStore', function (airportDataNgStore) {
  'use strict';
  //Do nothing, just force airportDataNgStore instantiation. It will fetch airport on first creation.
  //airportDataNgStore.refresh();
}]);
