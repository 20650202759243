/*jslint browser: true, nomen: true */
/*global App, Plumbing, _ */

App.provider('alertStore', ['customerId', function AlertStoreProvider(customerId) {
  'use strict';
  
  var opts, getPath, store;
  
  opts = {
    path: {
      customer: '/customer/',
      alert: '/alert-rule'
    }
  };
  
  getPath = function () {
    if (_.isFinite(customerId.get())) {
      return window.backendPrefix + opts.path.customer + customerId.get() + opts.path.alert;
    }
  };
  
  store = new Plumbing({
    headers: opts.headers,
    url: getPath,
    delay: 60000
  });
  
  customerId.on('update', function (oldId, newId) {
    if (oldId && oldId !== newId) {
      if (_.isUndefined(newId)) {
        store.adapter.stop();
      } else {
        store.refresh();
      }
    }
  }, this);

  this.setCustomerPart = function (customer) {
    opts.path.customer = customer;
  };
  
  this.setAlertPart = function (alert) {
    opts.path.alert = alert;
  };

  this.$get = [function alertStoreFactory() {
    return store;
  }];
  
}]);