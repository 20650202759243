/*jslint browser: true, plusplus:true*/
/*global App, $, angular */

(function (global) {
  'use strict';

  function AdminAlertSmsFormCtrl($scope, $state, $stateParams, $uibModalInstance, sms, smsList) {
    
    var defaultNewSms = function () {
      return {
        name: '',
        contactInfo: ''
      };
    };

    $scope.newSms = defaultNewSms();
    
    $scope.editedSms = null;
    // Sms to edit (if edition mode)
    $scope.sms = _.clone(sms);

    //modify to the real data holder
    $scope.modifyItem = function modifyItem(smsToAdd) {

      $scope.editedSms = smsToAdd;

      // check if all informations are filled
      smsToAdd.name = document.getElementById('name').value;
      smsToAdd.contactInfo = document.getElementById('value').value;

      if ((document.getElementById('value') !== null && document.getElementById('value').value === "") ||
          (document.getElementById('name') !== null && document.getElementById('name').value === "")) {
        $('.modal-dialog').addClass('shake animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
          $('.modal-dialog').removeClass('shake animated');
        });
        return;
      }
      
      //TODO RBE REGEX VERIF PHONE NUMBER FORMAT V 1.X
      
      _.assign(sms, $scope.editedSms);
      $uibModalInstance.close();
    };
    
    //add to the real data holder
    $scope.addItem = function addItem() {

      // check if all informations are filled
      var smsToAdd = {
        name: document.getElementById('name').value,
        contactInfo: document.getElementById('value').value
      };

      if ((document.getElementById('name') !== null && document.getElementById('name').value === "") ||
          (document.getElementById('value') !== null && document.getElementById('value').value === "")) {
        $('.modal-dialog').addClass('shake animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
          $('.modal-dialog').removeClass('shake animated');
        });
        return;
      }
      
      //TODO RBE REGEX VERIF PHONE NUMBER FORMAT V 1.X

      smsList.push(smsToAdd);
      $uibModalInstance.close();
    };

    // Cancel action
    $scope.cancelItem = function cancelItem() {
      var form = document.getElementById("formAdminAlert"),
        elements = form.elements,
        i;
      for (i = 0; i < elements.length; ++i) {
        elements[i].required = false;
      }
      $uibModalInstance.close();
    };
  }

  App
    .controller('AdminAlertSmsFormCtrl', ['$scope', '$state', '$stateParams', '$uibModalInstance', 'sms', 'smsList', AdminAlertSmsFormCtrl]);

}(window));
