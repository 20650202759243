/*jslint browser: true, plusplus:true, nomen:true*/
/*global _, angular, ol, App, $, Validator, UserService */

App
  .controller('AdminUserFormCtrl', [
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    '$uibModalInstance',
    'userInForm',
    'allUsers',
    'isUpdate',
    'customer',
    'alertService',
    'userProfileNgStore',
    function AdminUserFormCtrl($rootScope, $scope, $state, $stateParams, $uibModalInstance, userInForm, allUsers, isUpdate, customer, alertService, userProfileNgStore) {
      'use strict';
      // Airlines list
      $scope.customer = customer;

      // User to edit (if edition mode)
      $scope.isUpdate = isUpdate;
      $scope.userInForm = angular.copy(userInForm);
      $scope.oldUser = userInForm;
      // When in creation mode all airports and all airlines are selected, userInForm is null if in creation mode

      $scope.userInForm = _.defaults($scope.userInForm, {
        roles: [],
        config: {
          displayedAirlines: userInForm ? [] : $scope.customer.airlines.slice(),
          displayedAirports: userInForm ? [] : $scope.customer.airports.slice()
        }
      });

      function isPasswordValid(password, login, isUpdate) {
        var valid = false;

        function check1CharAnd1Num(newPassword) {
          return /\d/.test(newPassword) && /[a-z]/i.test(newPassword);
        }

        function checkNotEmail(newPassword) {
          var reg = /^[a-z0-9._-]+@[a-z0-9.-]{2,}[.][a-z]{2,3}$/;
          return !reg.test(newPassword);
        }

        function checkNotLogin(newPassword, login) {
          return _.isString(login) && login.toLowerCase() !== newPassword.toLowerCase();
        }

        if (isUpdate && password == null) {
          valid = true;
        } else {
          valid = check1CharAnd1Num(password) && checkNotEmail(password) && checkNotLogin(password, login) && password.length > 7;
        }

        if (!valid) {
          alertService.error('Password Format Invalid', 'Must contain 1 letter and 1 number. At least 8 characters long. Not be an email. Not be the login.');
        }
        return valid;
      }

      $scope.labelBlocked = '';

      function isUserValid(user) {
        var valid = true;
        _.each(allUsers, function (u) {
          if (user.id !== u.id) {
            if (user.login === u.login) {
              alertService.error('Duplicated user', 'An user with the same login already exists');
              valid = false;
            }
            if (user.email === u.email) {
              alertService.error('Duplicated user', 'An user with the same email already exists');
              valid = false;
            }
            return valid; // break forEach loop
          }
        });
        return valid;
      }

      $scope.hasSuperRole = function (user) {
        return user.roles.indexOf(App.SUPER_ROLE) !== -1;
      };

      $scope.hasAdminRole = function (user) {
        return user.roles.indexOf(App.ADMIN_ROLE) !== -1;
      };

      $scope.toggleAdminRole = function () {
        var roles = $scope.userInForm.roles;
        if (roles.indexOf(App.ADMIN_ROLE) === -1) {
          roles = _.union(roles, [App.ADMIN_ROLE]);
        } else {
          roles = _.difference(roles, [App.ADMIN_ROLE]);
        }
        $scope.userInForm.roles = roles;
      };

      $scope.hasReporterRole = function (user) {
        return user.roles.indexOf(App.REPORTER_ROLE) !== -1;
      };
      $scope.toggleReporterRole = function () {
        var roles = $scope.userInForm.roles;
        if (roles.indexOf(App.REPORTER_ROLE) === -1) {
          roles = _.union(roles, [App.REPORTER_ROLE]);
        } else {
          roles = _.difference(roles, [App.REPORTER_ROLE]);
        }
        $scope.userInForm.roles = roles;
      };

      $scope.validateAndSendAdminUserForm = function () {
        // Validation
        var formAdminUser = document.getElementById('formAdminUser');
        var validator = new Validator(formAdminUser);

        validator.checkValidity();
        validator.updateInputStatus();

        if (validator.isValid() && isUserValid($scope.userInForm) && isPasswordValid($scope.userInForm.password, $scope.userInForm.login, $scope.isUpdate)) {
          $scope.$close($scope.userInForm);
        } else {
          $('.modal-dialog').addClass('shake animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
            $('.modal-dialog').removeClass('shake animated');
          });
        }
      };

      // verify if that user is me
      $scope.isThisUserIsMe = function (userId) {
        var currentUserId = $scope.user.id;
        return currentUserId === userId;
      };

      // Cancel action
      $scope.cancelItem = function cancelItem() {
        var form = document.getElementById('formAdminUser');
        var elements = form.elements;
        var i;
        for (i = 0; i < elements.length; ++i) {
          elements[i].required = false;
        }
        userInForm = $scope.oldUser;
        $scope.$dismiss();
      };
      $scope.isSuperUser = function () {
        return $scope.user.roles.indexOf('SUPER') > -1;
      };
      $scope.isAdminUser = function () {
        return $scope.user.roles.indexOf('ADMIN') > -1;
      };

      $scope.isPossibleToBlockThisUser = function (userInForm) {
        // Super user can always block/unblock
        if ($scope.isSuperUser()) return true;

        var isPossibleToBlockThisUser = !$scope.hasSuperRole(userInForm);
        $scope.labelBlocked = (isPossibleToBlockThisUser) ? '' : "You can't block/unblock a super account. Contact NavBlue support for assistance.";
        return isPossibleToBlockThisUser;
      };
    }
  ]);
