/*jslint browser: true, nomen: true */
/*global App, Plumbing, GenericStore, angular */

App.provider('alertNgStore', [function alertNgStoreProvider() {
  'use strict';

  function AlertNgStore() {
    GenericStore.call(this);
  }

  AlertNgStore.prototype = Object.create(GenericStore.prototype);

  angular.extend(AlertNgStore.prototype, {

    //
    // Constructor

    constructor: AlertNgStore,

    //
    // Overridable

    newInstance: function (values) {
      var alert = {};
      this.updateInstance(alert, values);
      return alert;
    },

    updateInstance: function (entity, values) {
      entity.id = values.id;
      entity.level = values.level;
      entity.customerId = values.customerId;
      entity.reasonMessage = values.reasonMessage;
      entity.criteria = values.criteria;
      entity.parameter = values.parameter;
      entity.unit = values.unit;
      entity.isMailActive = values.isMailActive;
      entity.emails = values.emails;
      entity.isSmsActive = values.isSmsActive;
      entity.phones = values.phones;
      entity.isActive = values.isActive;
      entity.parametrable = values.parametrable;
    }
  });

  Object.defineProperties(AlertNgStore.prototype, {
    alerts: {
      get: function () {
        return this._entities;
      }
    }
  });

  var alertNgStore = new AlertNgStore();

  this.$get = ['alertStore', function alertNgStoreFactory(alertStore) {
    alertNgStore.store = alertStore;
    return alertNgStore;
  }];

}]);
