/*jslint browser: true, nomen: true */
/*global _, App, Plumbing, ol, Promise, GenericStore, angular */

App.provider('userNgStore', [function userNgStoreProvider() {
  'use strict';


  function UserNgStore() {
    GenericStore.call(this);
  }

  UserNgStore.prototype = Object.create(GenericStore.prototype);

  

  angular.extend(UserNgStore.prototype, {
    //
    // Constructor

    constructor: UserNgStore,

    //
    // Overridable

    newInstance: function (values) {
      return {
        id: values.id,
        login: values.login,
        isBlocked:values.isBlocked,
        email: values.email,
        roles: values.roles || [],
        camelCaseLogin : _.trim(_.camelCase(values.login)),
        //configJson: values.configJson,
        config: _.isEmpty(values.configJson) ? undefined : JSON.parse(values.configJson)
      };
    },

    updateInstance: function (entity, values) {
      entity.login = values.login;
      entity.isBlocked = values.isBlocked;
      entity.email = values.email;
      entity.roles = values.roles || [];
      entity.camelCaseLogin = _.trim(_.camelCase(values.login));
      //entity.configJson = values.configJson;
      entity.config = _.isEmpty(values.configJson) ? undefined : JSON.parse(values.configJson);
    },

    set: function (entity) {
      entity.configJson = JSON.stringify(entity.config);
      return GenericStore.prototype.set.call(this, _.omit(entity, 'config'));
    },

    add: function (entity) {
      entity.configJson = JSON.stringify(entity.config);
      return GenericStore.prototype.add.call(this, _.omit(entity, 'config'));
    },

    saveUserTemplates : function(templates){
      return this._store.saveUserTemplates(templates); 
    },

    getUserTemplates : function(){
      return this._store.getUserTemplates();
    },

    getUserTemplateAssociation : function(){
      return this._store.getUserTemplateAssociation();
    },

    getDefaultTemplate : function(){
      return this._store.getDefaultTemplate();
    },

    saveTemplateAllocation : function(allocations){
      var associations = _.mapValues(allocations, 'id');
      return this._store.saveTemplateAllocation(associations);
    }, 

    saveDefaultTemplate : function(defaultTemplate){
      return this._store.saveDefaultTemplate(defaultTemplate);
    }


  });

  Object.defineProperties(UserNgStore.prototype, {
    users: {
      get: function () {
        return this._entities;
      }
    }
  });

  var userNgStore = new UserNgStore();

  this.$get = ['userStore', function userNgStoreFactory(userStore) {
    userNgStore.store = userStore;
    return userNgStore;
  }];
  

}]);
