/*jslint browser:true, vars: true */

(function (global) {
    'use strict';
    var tileSize = 1024;

    function generator(viewExtent) {
        // set up a tile grid to fetch tiles with custom sizes
        // see OpenLayers example:
        // https://openlayers.org/en/latest/examples/wms-custom-tilegrid-512x256.html
        var startResolution = ol.extent.getWidth(viewExtent) / tileSize;
        var resolutions = new Array(22);
        for (var i = 0; i < resolutions.length; ++i) {
          resolutions[i] = startResolution / Math.pow(2, i);
        }
        return new ol.tilegrid.TileGrid({
          extent: viewExtent,
          resolutions: resolutions,
          tileSize: [tileSize, tileSize]
        });
    }

    global.generateTileGrid = generator;
}(window));
