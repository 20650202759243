/*jslint browser: true, nomen: true, plusplus:true */
/*global _, App, Plumbing, Promise, ol, angular, GenericFilteredStore, moment, FlightLevelFormatter, Deviation */

App.provider('flightNgStore', ['flightSectionDefinitions', 'generalConstants', function FlightNgStoreProvider(flightSectionDefinitions, generalConstants) {
  'use strict';

  var transform, flightNgStore, DEG2RAD = 0.017453292519943295,
    KNOT2MPERMS = 5.14444444e-4;

  var AIRPORT_VICINITY_IN_KM = 5; //km

  transform = ol.proj.getTransform('EPSG:4326', 'EPSG:3857');

  //Generic definition
  function FlightNgStore() {
    GenericFilteredStore.call(this);
  }
  FlightNgStore.prototype = Object.create(GenericFilteredStore.prototype);
  flightNgStore = new FlightNgStore();

  // Flight Model object
  function FlightNg(properties) {
    angular.extend(this, properties);
  }

  FlightNg.prototype.matchPlannedFlights = function(flightNGMissingInfo, missingFieldName)
  {
    var matchedLocationField = undefined;
    var numberOfMatches = 0;

      _.forEach(flightNgStore.flights, function (flight) {
          if(flight !== undefined && flight.addrModeS === flightNGMissingInfo.addrModeS && flight.isPlanned)
          {
            if (flight.ori !== undefined) {

              var distance = GeometryUtils.distanceInKmBetweenEarthCoordinates(flightNGMissingInfo.lat, flightNGMissingInfo.long, flight.originAirport.latitude, flight.originAirport.longitude);

              // If tracked flight without ORIGIN Airport is in Vicinity of Planned Flight origin Airport
              // it considered as a match
              if(distance < AIRPORT_VICINITY_IN_KM)
              {
                if(missingFieldName === 'dest')
                {
                  matchedLocationField = flight.dst;
                }
                else if(missingFieldName === 'orig')
                {
                  matchedLocationField = flight.ori;
                }
                numberOfMatches++;
              }
            }           
          }
      });
      
      if(numberOfMatches == 1 && matchedLocationField !== undefined)
      {
        return matchedLocationField;
      } 
      else
      {
        return undefined;
      }
      
  }

  FlightNg.prototype.computedProperties = {
    lastUpdateDate: {
      enumerable: false,
      get: function () {
        return moment(this.lastUpdate);
      }
    },
    flightDuration: {
      //estimated time duration ETA- takeoff time
      get: function () {
        var landingTime = this.on || this.eta || this.sta;
        return landingTime && this.off && (Math.floor(landingTime.diff(this.off) / 60000) * 60000);
      }
    },
    timeElapsed: {
      //estimated elapsed time : between takeoff and now (or landing)
      get: function () {
        var end = this.on || this.lastUpdateDate;
        return end && this.off && (Math.round(end.diff(this.off) / 60000) * 60000);
      }
    },
    timeRemaining: {
      //estimated remaining time : between now and ETA
      get: function () {
        return this.eta && this.lastUpdate && (Math.round(this.eta.diff(this.lastUpdateDate) / 60000) * 60000);
      }
    },
    delayEstimated: {
      //estimated delta between EIBT (Estimated In Block Time) and STA (standard time of Arrival)
      get: function () {
        if (!!this.inGate) {
          return this.inGate && this.sta && Math.floor(this.inGate.diff(this.sta) / 60000);
        } else {
          return this.eibt && this.sta && Math.floor(this.eibt.diff(this.sta) / 60000);
        }

      }
    },
    landingTime: {
      //landing time effective or estimated
      get: function () {
        return this.on || this.eta || this.sta;
      }
    },
    progress: {
      //estimated progress in percentile (0-1)
      get: function () {
        return this.flightDuration && this.timeElapsed && Math.min(1, Math.max(0, (this.timeElapsed / this.flightDuration)));
      }
    },
    destinationAirport: {
      hasDependencies: true,
      enumerable: false,
      get: function () {

        var destination = this.dst;

        if(this.dst === undefined && this.isTracked)
        {
          destination = this.matchPlannedFlights(this, "dest");
          this.dst = destination;
        }

        return flightNgStore.airportDataNgStore.findByIcao(destination);
      }
    },
    originAirport: {
      hasDependencies: true,
      enumerable: false,
      get: function () {

        var orig = this.ori;

        if(this.ori === undefined && this.isTracked)
        {
          orig = this.matchPlannedFlights(this, "orig");
          this.ori = orig; 
        }

        return flightNgStore.airportDataNgStore.findByIcao(orig);
      }
    },
    aircraft: {
      hasDependencies: true,
      get: function () {
        var addF = this.addrModeS,
          aircraftList = flightNgStore.aircraftInfoNgStore.aircrafts,
          i = aircraftList.length;

        while (i--) {
          if (aircraftList[i].addrModeS === addF) {
            return aircraftList[i];
          }
        }
        return null;
      }
    },
    cat: {
      enumerable: true,
      hasDependencies: true,
      get: function () {
        var aircraftModel,
          models = flightNgStore.dataStore.aircraftModels,
          defaultAircraftModel = (models['*'] && models['*'].size) || 'M';
        aircraftModel = defaultAircraftModel;
        if (angular.isDefined(models[this.acType])) {
          aircraftModel = models[this.acType].size;
        }
        return aircraftModel;
      }
    },
    isClosed: {
      //Return true, if this flight aircraft is NOT from my customer or is from an hidden airline
      enumerable: false,
      hasDependencies: true,
      get: function () {
        return this.state === 'GROUND_PARK';
      }
    },
    isMine: {
      //Return true, if this flight aircraft is from my customer
      enumerable: false,
      hasDependencies: true,
      get: function () {
        return !this.isClosed && this.aircraft !== null && !this.isOther;
      }
    },
    isInMyFleet: {
      enumerable: false,
      hasDependencies: true,
      get: function () {
        return _.includes(flightNgStore.assignedAircrafts, this.acn);
      }
    },
    // isOther: {
    //   //Return true, if this flight aircraft is NOT from my customer or is from an hidden airline
    //   enumerable: false,
    //   hasDependencies: true,
    //   get: function () {
    //     return !this.isClosed && (!this.isMine || this.isOther);
    //   }
    // },
    isIntersection: {
      //Return true, if this flight aircraft is NOT from my customer or is from an hidden airline
      enumerable: false,
      hasDependencies: true,
      get: function () {
        return !this.isClosed && !!this.intersections;
      }
    },
    hasMessages: {
      //Return true, if this flight aircraft is NOT from my customer or is from an hidden airline
      enumerable: false,
      hasDependencies: true,
      get: function () {
        return !this.isOther && _.size(this.messages) > 0;
      }
    },
    phase: {
      enumerable: true,
      get: function () {
        return Deviation.phase(this.state, this.vspeed);
      }
    },
    flightLevel: {
      enumerable: true,
      get: function () {
        return FlightLevelFormatter.format(this.state, this.alt);
      }
    },
    userTags: {
      enumerable: true,
      hasDependencies: true,
      get: function () {
        return flightNgStore.userTags[this.acn];
      }
    },
    airspaces: {
      enumerable: true,
      hasDependencies: true,
      get: function () {
        return _.map(this.intersections, 'airspaceZoneId');
      }
    },
    nextIAA: {
      enumerable: true,
      hasDependencies: true,
      get: function () {
        var nextIAA;
        _.forEach(this.intersections, function (intersection) {
          _.forEach(intersection.points, function (point) {
            if (!point.observed && (point.orientation === 'IN' || point.orientation === 'LEFT')) {
              nextIAA = !nextIAA ? point.intersectionDate : Math.min(nextIAA, point.intersectionDate);
            }
          });
        });
        return nextIAA;
      }
    },
    nextOAA: {
      enumerable: true,
      hasDependencies: true,
      get: function () {
        var nextIAA;
        _.forEach(this.intersections, function (intersection) {
          _.forEach(intersection.points, function (point) {
            if (!point.observed && (point.orientation === 'OUT' || point.orientation === 'RIGHT')) {
              nextIAA = !nextIAA ? point.intersectionDate : Math.min(nextIAA, point.intersectionDate);
            }
          });
        });
        return nextIAA;
      }
    }
  };


  //Flight store definition

  angular.extend(FlightNgStore.prototype, {
    _super: GenericFilteredStore.prototype,

    //
    // Constructor

    constructor: FlightNgStore,

    //
    // new
    newInstance: function (values) {
      var id = values.id.toString(),
        openLayerFeature = new ol.Feature({
          acn: values.acn,
          addrModeS: values.addrModeS,
          geometry: new ol.geom.Point([0, 0]),
          id: id
        }),
        f = new FlightNg({
          id: id,
          lastAlertTime: 0
        });
      Object.defineProperty(f, 'olFeature', {
        value: openLayerFeature,
        enumerable: false
      });
      openLayerFeature.setId(f.id);
      this.updateInstance(f, values, true);

      return f;
    },

    setPropertiesValues: function (entity, values) {
      var airlineIcao, airlineInfo;
      entity.acn = values.acn;
      /* SMHUB-69 - Other Flights - Flight Details - A/C Type and A/C registration missing */
      entity.acType = values.acType;
      entity.addrModeS = values.addrModeS;
      if (values.airline) {
        entity.airline = values.airline;
      } else {
        airlineIcao = values.flightId && values.flightId.slice(0, 3);
        airlineInfo = this.airlineDataStore.airlines[airlineIcao];
        entity.airline = !!airlineInfo ? airlineInfo.icao : '---';
      }
      entity.alt = values.alt;
      entity.dst = values.dst;
      entity.eta = values.eta && moment(values.eta).tz(flightNgStore.timeZone);
      entity.off = values.off && moment(values.off).tz(flightNgStore.timeZone);
      entity.inGate = values.inGate && moment(values.inGate).tz(flightNgStore.timeZone);
      entity.on = values.on && moment(values.on).tz(flightNgStore.timeZone);
      entity.out = values.out && moment(values.out).tz(flightNgStore.timeZone);
      entity.eibt = values.eibt && moment(values.eibt).tz(flightNgStore.timeZone);
      entity.sta = values.sta && moment(values.sta).tz(flightNgStore.timeZone);
      entity.std = values.std && moment(values.std).tz(flightNgStore.timeZone);
      entity.flightId = values.flightId;
      entity.flightNumber = values.flightNumber;
      entity.lastUpdate = values.lastUpdate;
      entity.lastEstimatedUpdate = values.lastEstimatedUpdate;
      entity.lat = values.lat;
      entity.long = values.long;
      entity.estimateLat = values.estimateLat;
      entity.estimateLong = values.estimateLong;
      entity.ori = values.ori;
      entity.speed = values.speed;
      entity.squawk = values.squawk;
      entity.src = values.src;
      entity.state = values.state;
      entity.track = values.track;
      entity.vspeed = values.vspeed;
      entity.scheduledOnly = values.scheduledOnly;
      entity.destinationRunway = values.destinationRunway;
      entity.intersections = values.intersections;
      entity.altGps = values.altGps;
      entity.altMcp = values.altMcp;
      entity.indAirspeed = values.indAirspeed;
      entity.mach = values.mach;
      entity.qnh = values.qnh;
      entity.trueAirspeed = values.trueAirspeed;
      entity.trueTrack = values.trueTrack;
      entity.altFms = values.altFms;
      entity.autopilot = values.autopilot;
      entity.temperature = values.temperature;
      entity.windDir = values.windDir;
      entity.windSpeed = values.windSpeed;
      entity.category = values.category;
      entity.precision = values.precision;
      entity.fr24Source = values.fr24Source;
      entity.lastCrossedWaypoint = values.lastCrossedWaypoint;
      entity.crossedTrackPointLat = values.crossedTrackPointLat;
      entity.crossedTrackPointLon = values.crossedTrackPointLon;
      entity.ci = values.ci;
      entity.tow = values.tow;
      entity.gw = values.gw;
      entity.hasFp = values.hasFp;
      entity.isOther = values.isOther;
      entity.isTracked=values.isTracked;
      entity.isPlanned = values.isPlanned;
      entity.flightOriginDate = values.flightOriginDate;
      entity.paxInfo = angular.fromJson(values.paxInfo);
      entity.destinationAirport = angular.fromJson(values.destinationAirport);
      entity.originAirport = angular.fromJson(values.originAirport);
    },

    /**
     * Find the flight section according to its alertLvl
     */
    updateSection: function (entity) {
      var section = _.find(flightSectionDefinitions, function (section) {
        return section.testFn(entity);
      }).name;
      entity.section = section;
    },

    updateOpenLayerFeature: function (entity) {
      var point;
      if (entity.lastUpdate !== null && entity.lastEstimatedUpdate !== null && entity.lastUpdate < entity.lastEstimatedUpdate) {
        point = transform([entity.estimateLong, entity.estimateLat]);
      } else {
        point = transform([entity.long, entity.lat]);
      }
      entity.olFeature.getGeometry().setCoordinates(point);
      this.definitionsDisplayedInTemplate.forEach(function(field){
         entity.olFeature.set(field.key,entity[field.key]);
      });
      // entity.olFeature.set('track', entity.track);
      entity.olFeature.set('name', generalConstants.planeFeatureName);
      entity.olFeature.set('alertLvl', entity.alertLvl);
      entity.olFeature.set('alt', entity.alt);
      entity.olFeature.set('state', entity.state);
      //entity.olFeature.set('flightId', entity.flightId);
      // entity.olFeature.set('vspeed', entity.vspeed);
      // entity.olFeature.set('flightNumber', entity.flightNumber);
      entity.olFeature.set('lastUpdate', entity.lastUpdate);
      // entity.olFeature.set('flightLevel', entity.flightLevel);
      entity.olFeature.set('phase', entity.phase);
      entity.olFeature.set('customerAircraft', !entity.isOther);
      entity.olFeature.set('closed', entity.isClosed);
      // fix cat
      entity.olFeature.set('cat', entity.cat);
      entity.olFeature.set('lastCrossedWaypoint', entity.lastCrossedWaypoint);
      entity.olFeature.set('crossedTrackPointLat', entity.crossedTrackPointLat);
      entity.olFeature.set('crossedTrackPointLon', entity.crossedTrackPointLon);
      entity.olFeature.set('tracked', entity.isTracked);

    },

    //
    // update
    updateInstance: function (entity, values, valuesWereUpdated) {
      if (valuesWereUpdated) {
        this.setPropertiesValues(entity, values);
      } else {
        entity.eta = values.eta && moment(values.eta).tz(flightNgStore.timeZone);
        entity.off = values.off && moment(values.off).tz(flightNgStore.timeZone);
        entity.inGate = values.inGate && moment(values.inGate).tz(flightNgStore.timeZone);
        entity.on = values.on && moment(values.on).tz(flightNgStore.timeZone);
        entity.out = values.out && moment(values.out).tz(flightNgStore.timeZone);
        entity.eibt = values.eibt && moment(values.eibt).tz(flightNgStore.timeZone);
        entity.sta = values.sta && moment(values.sta).tz(flightNgStore.timeZone);
        entity.std = values.std && moment(values.std).tz(flightNgStore.timeZone);
      }
      this.updatedComputedAttribute(entity, valuesWereUpdated);
      //check for new alerts and update alertLvl
      this.checkNewAlert(entity, entity.alerts, values.alerts || []);
      this.checkNewMessages(entity, entity.messages, values.messages || []);
      this.checkNewNotes(entity, entity.notes, values.notes || []);
      this.updateSection(entity);
      this.updateOpenLayerFeature(entity);
      // this.updateEstimatedPosition(entity);
      return entity;
    },

    checkNewMessages: function (entity, currentMsgs, newMsgs) {

      Object.defineProperty(entity, 'messages', {
        value: newMsgs || [],
        enumerable: false,
        configurable: true
      });
      if (_.size(currentMsgs) !== _.size(newMsgs)) {
        _.chain(newMsgs).filter(function (m) {
          //filter unread
          return _.isEmpty(m.sender) && _.size(m.readBy) === 0;
        }).forEach(function (m) {
          this.trigger('updated:newchat', entity, m);
        }.bind(this));
      }

    },
    checkNewNotes: function (entity, currentNotes, newNotes) {
      Object.defineProperty(entity, 'notes', {
        value: newNotes || [],
        enumerable: false,
        configurable: true
      });
      if (_.size(currentNotes) !== _.size(newNotes)) {
        _.chain(newNotes).filter(function (m) {
          //filter unread
          return _.isEmpty(m.sender) && _.size(m.readBy) === 0;
        }).forEach(function (m) {
          this.trigger('updated:newnote', entity, m);
        }.bind(this));
      }
    },
    //
    // check new alert
    checkNewAlert: function (entity, currentAlerts, newAlerts, silent) {
      //do diff between both alerts list
      if (entity.isOther === false) {
        var currentAlertsId = [],
          newAlertsId = [],
          diffAlerts;

        entity.alertLvl = 0;
        if (newAlerts && newAlerts.length > 0) {
          _.forEach(newAlerts, function (alert) {
            alert.manual = _.isString(alert.creator); // alert is manual if it has a creator
            if (alert.startDate > entity.lastAlertTime) {
              entity.lastAlertTime = alert.startDate;
            }
            if (!alert.endDate) {
              alert.isActive = true;
              entity.alertLvl = Math.max(entity.alertLvl, alert.lvl);
            } else {
              alert.isActive = false;
              if (alert.endDate > entity.lastAlertTime) {
                entity.lastAlertTime = alert.endDate;
              }
            }
          });
        }
        if (!silent) {
          currentAlertsId = _.map(currentAlerts, 'id');

          /* [DAB] For alert reminder do not reject old alerts anymore
          //reject old alerts
          diffAlerts = _.reject(newAlerts, function (alert) {
            return _.includes(currentAlertsId, alert.id);
          });
          */
          diffAlerts = newAlerts;

          //if new alert
          if (_.isArray(diffAlerts) && diffAlerts.length !== 0) {
            this.trigger('sync', true, true);
            _.forEach(diffAlerts, function (alert, key) {
              this.trigger('alert', alert, entity);
              if (this._searchEntities.indexOf(entity) !== -1) {
                this.trigger('alert:search', alert, entity);
              }
              if (this._filteredEntities.indexOf(entity) !== -1) {
                this.trigger('alert:filtered', alert, entity);
              }
            }.bind(this));
          }
        }

      }
      Object.defineProperty(entity, 'alerts', {
        value: newAlerts || [],
        enumerable: false,
        configurable: true
      });
    },

    // updateEstimatedPosition: function (entity) {
    //   if (entity.state === 'IN_FLIGHT') {
    //     var long, lat, trackInRad, now, delay, point, dist;

    //     now = Date.now();
    //     delay = now - entity.lastUpdate;
    //     if (delay >= 0) {
    //       trackInRad = entity.track * DEG2RAD;
    //       dist = entity.speed * KNOT2MPERMS * delay;

    //       point = transform([entity.long, entity.lat]);
    //       long = Math.sin(trackInRad) * dist + point[0];
    //       lat = Math.cos(trackInRad) * dist + point[1];

    //       entity.olFeature.getGeometry().setCoordinates([long, lat]);
    //       entity.olFeature.set('estimated', true);
    //       entity.olFeature.set('lastUpdate', now);
    //     }
    //   }
    // },

    updatedComputedAttribute: function (entity, all) {
      _.forIn(entity.computedProperties, function (prop, key) {
        if (all || prop.hasDependencies) {
          //Update only properties that depends on other stores
          Object.defineProperty(entity, key, {
            value: prop.get.call(entity),
            enumerable: prop.enumerable,
            configurable: true
          });
        }
      });
    },

    isEntityUpdated: function (id, values, entity) {
      var vAlerts = values.alerts || [],
        alertChanged = (entity.alerts.length !== vAlerts.length);
      return entity.lastUpdate !== values.lastUpdate || entity.isOther != values.isOther || (values.lastEstimatedUpdate !== null && entity.lastEstimatedUpdate !== null && values.lastEstimatedUpdate !== entity.lastEstimatedUpdate); /* || alertChanged*/
    },

    getAircraftPositionTrail: function (id) {
      return this._store.fetchAircraftPositionsHistory(id);
    },

    getAircraftPostionsForAirport: function (airportIcao) {
      return this._store.fetchAircraftPositionsForAirport(airportIcao);
    },

    getAircraftFlightPlan: function (id) {
      return this._store.fetchAircraftFlightPlan(id);
    },

    getAircraftPlannedFlightPlans: function (flight) {
      return this._store.fetchAircraftPlannedFlightPlans(flight);
    },

    getAircraftFlightEtaHistory: function (id) {
      return this._store.fetchAircraftFlightEtaHistory(id);
    },

    getAirportFlightsOnArrival: function (airportIcao) {
      //we cannot use active flights from the store because we also want past flights.
      return this._store.fetchAirportFlightsOnArrival(airportIcao)
        .then(function (flights) {
          return flights.map(function (f) {
            var flightNg = new FlightNg({
              id: f.id
            });
            this.setPropertiesValues(flightNg, f);
            this.updatedComputedAttribute(flightNg, true);
            this.checkNewAlert(flightNg, f.alerts, f.alerts, true);
            this.updateSection(flightNg);
            return flightNg;
          }, this);
        }.bind(this));
    },

    addManualAlert: function (flight, level, reasonMsg) {
      return new Promise(function (resolve, reject) {
        this._store.addFlightManualAlert(flight.id, {
          lvl: level,
          msg: reasonMsg
        }).then(function (alert) {
          //direct update, don't wait for sync
          this.checkNewAlert(flight, flight.alerts, _.union(flight.alerts, [alert]), true);
          this.updateSection(flight);
          this.updateOpenLayerFeature(flight);
          this.trigger('update', flight);
          this.trigger('sync', true, true);
          resolve(alert);
        }.bind(this), reject);
      }.bind(this));

    },
    closeManualAlert: function (flight, alert) {
      return new Promise(function (resolve, reject) {
        this._store.closeFlightManualAlert(flight.id, alert.id)
          .then(function (response) {
            if (response.ok) {
              //direct update, don't wait for sync
              _.find(flight.alerts, {
                'id': alert.id
              }).endDate = Date.now();
              this.checkNewAlert(flight, flight.alerts, flight.alerts, true);
              this.updateSection(flight);
              this.updateOpenLayerFeature(flight);
              this.trigger('update', flight);
              this.trigger('sync', true, true);
              resolve(response);
            } else {
              reject(response);
            }
          }.bind(this), reject);
      }.bind(this));

    },
    acknowledgeAlert: function (flight, alert) {
      return this._store.acknowledgeAlert(flight.id, alert);
    },
    sendMessage: function (flight, msg) {
      return new Promise(function (resolve, reject) {
        this._store.sendMessage(flight.id, msg).then(function (msg) {
          //direct update, don't wait for sync
          this.checkNewMessages(flight, flight.messages, _.union(flight.messages, [msg]));
          resolve(msg);
        }.bind(this), reject);
      }.bind(this));
    },
    acknowledgeMessage: function (flight, msg) {
      return this._store.acknowledgeMessage(flight.id, msg)
        .then(function (amsg) {
          msg.readBy = amsg.readBy;
          return amsg;
        });
    },

    setNote: function (flight, note) {
      return new Promise(function (resolve, reject) {
        this._store.setNote(flight.id, note).then(function (note) {
          //direct update, don't wait for sync
          this.checkNewNotes(flight, flight.notes, _.union(flight.notes, [note]));
          resolve(note);
        }.bind(this), reject);
      }.bind(this));
    },
    addNote: function (flight, note) {
      return new Promise(function (resolve, reject) {
        this._store.addNote(flight.id, note).then(function (note) {
          //direct update, don't wait for sync
          this.checkNewNotes(flight, flight.notes, _.union(flight.notes, [note]));
          resolve(note);
        }.bind(this), reject);
      }.bind(this));
    },
    removeNote: function (flight, note) {
      return new Promise(function (resolve, reject) {
        this._store.removeNote(flight.id, note).then(function (note) {
          //direct update, don't wait for sync
          //this.checkNewNotes(flight, flight.notes, _.difference(flight.notes, [note]));
          resolve(note);
        }.bind(this), reject);
      }.bind(this));
    },
    updateCostIndex: function (flightId, newCostIndex) {
      return this._store.editParam(flightId, newCostIndex, 'ci');
    },
    updateTakeOffWeight: function (flightId, newTakeOffWeight) {
      return this._store.editParam(flightId, newTakeOffWeight, 'tow');
    },
    updateGrossWeight: function (flightId, newGrossWeight) {
      return this._store.editParam(flightId, newGrossWeight, 'gw');
    },
    showClosedFlights: function (showClosedFlights) {
      this._store.showClosedFlights(showClosedFlights);
    },

    closeFlight: function (flight) {
      this._store.closeFlight(flight.id);
    },
    addManualReport: function (flightReport) {
      return this._store.addManualReport(flightReport);
    }
  });

  Object.defineProperties(FlightNgStore.prototype, {
    flights: {
      get: function () {
        return this.searchEntities;
      }
    },
    allFlights: {
      get: function () {
        return this.filteredEntities;
      }
    }
  });


  this.$get = [
    '$filter',
    'flightStore',
    'aircraftInfoNgStore',
    'dataStore',
    'airlineDataStore',
    'userService',
    'airportDataNgStore',
    'flightfieldDefinitions',
    'mapPositionService',
    function flightNgStoreFactory($filter,
      flightStore,
      aircraftInfoNgStore,
      dataStore,
      airlineDataStore,
      userService,
      airportDataNgStore,
      flightfieldDefinitions,
      mapPositionService) {

      flightNgStore.$filter = $filter('flightFilter');
      var updateFromUserPref = function () {
        var airports;
        if (userService.user && userService.user.customerId) {
          airports = userService.getUserPreference('displayedAirports');
          flightNgStore.displayedAirlines = userService.getUserPreference('displayedAirlines');
          flightNgStore.userTags = userService.getUserPreference('userTags');
          flightNgStore.assignedAircrafts = userService.user.assignedAircrafts;
          flightNgStore.timeZone = userService.getUserPreference('timeZone');
          flightNgStore.store = flightStore;
        } else {
          airports = [];
          flightNgStore.unload();
          flightNgStore.displayedAirlines = [];
        }
        flightStore.setAirlinePart(flightNgStore.displayedAirlines);
      };
      updateFromUserPref();
      userService.off('update', null, flightNgStore);
      userService.off('logout', null, flightNgStore);
      userService.on('update', updateFromUserPref, flightNgStore);
      userService.on('logout', flightNgStore.unload, flightNgStore);

      var updateBoundingBox = function() {
        flightStore.setBoundingBoxPart(mapPositionService.getBoundingBoxString());
      };
      mapPositionService.off('updateBoundingBox', null, flightNgStore);
      mapPositionService.on('updateBoundingBox', updateBoundingBox, flightNgStore);

      var updateFetchOthers = function() {
        flightStore.setFetchOthers(mapPositionService.fetchOthers());
      };
      mapPositionService.off('updateFetchOthers', null, flightNgStore);
      mapPositionService.on('updateFetchOthers', updateFetchOthers, flightNgStore);

      flightNgStore.aircraftInfoNgStore = aircraftInfoNgStore;
      flightNgStore.airportDataNgStore = airportDataNgStore;
      flightNgStore.dataStore = dataStore;
      flightNgStore.airlineDataStore = airlineDataStore;
      flightNgStore.definitionsDisplayedInTemplate = _.chain(flightfieldDefinitions).values()
          .filter({
            displayInTemplate: true
          }).value();

      // force aircraft refresh
      aircraftInfoNgStore.refresh();
      // airportDataNgStore.refresh();
      //flightNgStore.filter = undefined;
      return flightNgStore;
    }
  ];

}]);
