/*jslint browser: true, plusplus:true, nomen:true*/
/*global _, App, Promise */
(function () {
  'use strict';

  function SettingsAirportsCtrl($rootScope, $scope, $state, userService, airportDataNgStore,flightNgStore) {

    // Not displayed airports saved
    var hiddenAirports = $scope.user.preferences.hiddenAirports,
      // Airports list
      mappingAirports = $scope.user.configuration.displayedAirports || [];

    // Displayed airports checked
    $scope.settingsAirports = _.difference(mappingAirports, hiddenAirports);

    // Airport settings list
    $scope.mappingAirports = mappingAirports;

    $scope.getAirport = function (icao) {
      return airportDataNgStore.findByIcao(icao);
    };

    $scope.submitAirportsSettings = function () {
      // Getting not displayed airports
      var hiddenAirports = _.difference(mappingAirports, $scope.settingsAirports);
      return new Promise(function (resolve, reject) {
        userService.savePreferences({
          hiddenAirports: hiddenAirports
        }).then(resolve, function () {
          //reload last preferences in DB
          $scope.settingsAirports = _.difference(mappingAirports, hiddenAirports);
          $scope.$apply();
          reject(arguments);
        }).then(function(){
          flightNgStore.refresh();
        })
      });
    };
  }

  App
    .controller('SettingsAirportsCtrl', ['$rootScope', '$scope', '$state', 'userService', 'airportDataNgStore', 'flightNgStore', SettingsAirportsCtrl]);
}());
