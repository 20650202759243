/*jslint browser: true, nomen: true */
/*global App, Plumbing, _ */

App.provider('userStore', ['customerId', function userStoreProvider(customerId) {
  'use strict';

  var opts, getPath, store;
  var userServiceInstance;
  opts = {
    path: {
      customer: '/customer/',
      user: '/user',
      userLabelTemplate: '/user-label-templates/',
      userTemplateAllocation: '/aircraft-user-template-allocation/',
      userDefaultTemplate: '/user-default-template/'
    },
	headers: {
      'Cache-Control': 'no-cache'
	}
  };

  getPath = function () {
    if (_.isFinite(customerId.get())) {
      return window.backendPrefix + opts.path.customer + customerId.get() + opts.path.user;
    }
  };

  store = new Plumbing({
    headers: opts.headers,
    url: getPath,
    delay: 120000
  });

  store.getUserTemplates = function (templates) {
    var user = userServiceInstance.user.id;
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + user + opts.path.userLabelTemplate, {
        method: 'get',
        credentials: 'include'
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, reject);
        } else {
          reject(response.statusText);
        }
      }, reject);
    }.bind(this));
  };

  store.getUserTemplateAssociation = function () {
    var user = userServiceInstance.user.id;
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + user + opts.path.userTemplateAllocation, {
        method: 'get',
        credentials: 'include'
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, reject);
        } else {
          reject(response.statusText);
        }
      }, reject);
    }.bind(this));
  };

  store.getDefaultTemplate = function () {
    var user = userServiceInstance.user.id;
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + user + opts.path.userDefaultTemplate, {
        method: 'get',
        credentials: 'include'
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, reject);
        } else {
          reject(response.statusText);
        }
      }, reject);
    }.bind(this));
  };

  

  store.saveUserTemplates = function (templates) {
    var user = userServiceInstance.user.id;
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + user + opts.path.userLabelTemplate, {
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(templates)
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, reject);
        } else {
          reject(response.statusText);
        }
      }, reject);
    }.bind(this));
  };

  store.saveTemplateAllocation = function (input) {
    var user = userServiceInstance.user.id;
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + user + opts.path.userTemplateAllocation, {
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(input)
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, reject);
        } else {
          reject(response.statusText);
        }
      }, reject);
    }.bind(this));
  };

  store.saveDefaultTemplate = function (input) {
    var user = userServiceInstance.user.id;
    return new Promise(function (resolve, reject) {
      window.fetch(getPath() + '/' + user + opts.path.userDefaultTemplate, {
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(input.id)
      }).then(function (response) {
        if (response.ok) {
          response.json().then(function (json) {
            resolve(json);
          }, reject);
        } else {
          reject(response.statusText);
        }
      }, reject);
    }.bind(this));
  };

  this.setCustomerPart = function (customer) {
    opts.path.customer = customer;
  };

  this.setUserPart = function (user) {
    opts.path.user = user;
  };

  this.$get = ['userService', function userStoreFactory(userService) {
    userServiceInstance = userService;
    return store;
  }];

}]);
