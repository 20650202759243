App.factory('restRequestFactory', [function () {
    'use strict';

    class RestRequest {

        constructor(url, params, errorMsg, httpMethod, body) {
            this.url = url;
            this.params = params;
            this.errorMsg = errorMsg;
            this.httpMethod = httpMethod;
            this.body = body;
        }
    }

    return {
        restRequest: function (url, params, errorMsg, httpMethod, body) {
            return new RestRequest(url, params, errorMsg, httpMethod, body);
        }
    };

}]);
