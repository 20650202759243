/*jslint browser: true, plusplus:true, nomen:true */
/*global $, _, angular, App, moment, ServerTime */

(function (global) {
  'use strict';


  /**
   * Convert null values to double dashes for display
   */
  App.filter('dash', function () {
    return function (input, format) {
      if (_.isNull(input) || _.isUndefined(input)) {
        return (format === 'time' ? '--:--' : '--');
      } else {
        return input;
      }
    };
  });

  App.filter('time', function () {
    return function (input, format) {
      if (_.isNull(input) || _.isUndefined(input)) {
        return format.replace(/[a-zA-Z]/g, '-');
      } else {
        if (!moment.isMoment(input)) {
          input = moment(input);
        }
        return input.format(format);
      }
    };
  });

  App.filter('since', function () {
    return function (input, format) {
      if (_.isNull(input) || _.isUndefined(input)) {
        return 'unkown';
      } else {
        if (!moment.isMoment(input)) {
          input = moment(input);
        }
        return input.from(ServerTime.now());
      }
    };
  });

  App.filter('flightData', function () {
    return function (input, definition) {
      var formatted = null;
      if (!_.isNull(input) && !_.isUndefined(input)) {
        formatted = input;
        if (_.isFunction(definition.formatFn)) {
          formatted = definition.formatFn.call(input, input);
        }
        if (!_.isUndefined(definition.unit)) {
          formatted += definition.unit;
        }
        return formatted;
      } else {
        return '--';
      }
    };
  });

  App.filter('iso6709', function () {
    return function (input, format) {
      var formatted = input,
        val = Math.abs(input),
        sign,
        deg,
        min,
        sec,
        l;
      if (_.isNumber(input)) {
        if (format === 'lat' || format === 'long') {
          if (input !== 0) {
            sign = input < 0 ? -1 : 1;
            deg = Math.floor(val);
            min = (val - deg) * 60;
            sec = Math.round((min - Math.floor(min)) * 60);
            min = Math.floor(min);
            formatted = deg + '\u00B0';
            l = min.toString().length;
            formatted += (l === 0) ? '00' : (l === 1) ? '0' : '';
            formatted += min + '\u0027';
            l = sec.toString().length;
            formatted += (l === 0) ? '00' : (l === 1) ? '0' : '';
            formatted += sec + '\u0022';
            formatted += (format === 'lat') ? (sign > 0 ? 'N' : 'S') : (sign < 0 ? 'W' : 'E');
          } else {
            formatted = '0\u00B0';
          }
        } else if (format === 'alt') {
          formatted = Math.round(input * 10) / 10 + 'ft';
        }
      }
      return formatted;
    };
  });

  App.filter('yesno', function () {
    return function (input) {
      return !!input ? 'yes' : 'no';
    };
  });

  App.filter('utcOffset', function () {
    return function (input) {
      return !!input && moment().tz(input).format('UTCZ');
    };
  });
  App.filter('list', function () {
    return function (list, refList) {
      if (refList && list.length === refList.length) {
        return 'All';
      } else if (list.length === 0) {
        return 'None';
      }
      return list.join(', ');
    };
  });

  App.filter('mapList', function () {
    return function (list, property) {
      var labels = _.map(list, property);
      return labels.join(', ');
    };
  });

  App.filter('tendency', function () {
    return function (tendency) {
      var s = ''; // '\u279d\ufe0e';
      if (_.isFinite(tendency)) {
        if (tendency === 0) {
          s = '=';
        } else if (tendency > 0) {
          s = '\u2197\ufe0e';
        } else {
          s = '\u2198\ufe0e';
        }
      }
      return s;
    };
  });

  App.filter('truncate', function () {
    return function (truncate, pattern) {
      pattern = pattern ||  '/';
      var i = truncate ? truncate.indexOf(pattern) : -1;
      if (i >= 0) {
        return truncate.substr(0, i);
      }
      return truncate;
    };
  });

  App.filter('booleans', function () {
    return function (booleans, trueRep, falseRep) {
      return booleans ? trueRep : falseRep;
    };
  });

  App.filter('decimal', function () {
    return function (decimal, offset) {
      var decimalSplit = decimal.toString().split('.');
      if (decimalSplit.length > 1) {
        while (decimalSplit[1].length < offset) {
          decimalSplit[1] = decimalSplit[1] + '0';
        }
      }
      return decimalSplit.join('.');
    };
  });

} (window));
