/*jslint browser: true */
/*global jQuery*/
(function ($) {
  'use strict';

  $.fn.scrollTo = function (target, opts) {
    var defaults = {
      toTop: false,
      duration: 50,
      offset: {
        top: 0,
        bottom: 0
      }
    };
    opts = $.extend(true, {}, defaults, opts);
    return this.each(function () {
      // Null target yields nothing, just like jQuery does
      if (target === null) {
        return;
      }
      var parent = $(this),
        element,
        elemHeight,
        parentTop,
        scrollTop,
        visible_area_end,
        visible_area_start,
        offset;

      element = parent.find(target);
      scrollTop = parent.scrollTop();
      if (element.length) {
        elemHeight = element.outerHeight();
        offset = element.offset().top + scrollTop;

        parentTop = parent.offset().top;
        visible_area_start = parentTop + scrollTop - opts.offset.bottom;
        visible_area_end = visible_area_start + parent.outerHeight();

        if (offset - elemHeight - opts.offset.top < visible_area_start) {
          //element is too high, scroll up.
         
          parent.animate({
            scrollTop: (offset - parentTop - opts.offset.top)
          }, opts.duration);
        } else if (offset + elemHeight > visible_area_end) {
         
          //element is below scroll down.
          parent.animate({
            scrollTop: opts.toTop ? (offset - parentTop) : (scrollTop + elemHeight + offset - visible_area_end)
          }, opts.duration);
        }
        //else do nothing, element is visible
      }
    });

  };
}(jQuery));
