/*jslint browser: true, nomen: true */
/*global $, angular, ol, moment, _, drawTooltipBg, FlightLevelFormatter */

(function (global) {
	//'use strict';

	var maxScale = 0.5,
	minScale = 0.15,
	reductionFactor = 500,
	helper,
	showFlightInfo = true;

	var anchor_array = [];
	var label_array = [];

	function aircraftTextStyle(color, size) {
		return new ol.style.Text({
			text: '\ue604',
			font: 'normal ' + size + ' AirFlight-Pictos',
			// textBaseline: 'Bottom',
			fill: new ol.style.Fill({
				color: color
			}),
			stroke: new ol.style.Stroke({
				color: 'black',
				width: 3
			})
		});
	}

	function closedAircraftTextStyle(color, size) {
		return new ol.style.Text({
			//      text: '\ue64c',
			text: '\ue604',
			font: 'normal ' + size + ' AirFlight-Pictos',
			// textBaseline: 'Bottom',
			fill: new ol.style.Fill({
				color: [0, 0, 0, 0.8]
			}),
			stroke: new ol.style.Stroke({
				color: 'white',
				width: 3
			})
		});
	}

	function groundVehicleTextStyle(color, size) {
		return new ol.style.Text({
			text: '\ue900',
			font: 'normal ' + size + ' AirFlight-Pictos',
			fill: new ol.style.Fill({
				color: color
			})
		});
	}


	var ID = 0;

	function StyleProvider(colors) {
		// this.init(opts);
		this.id = ID++;
		this.defaultColors = colors || {};
	}

	StyleProvider.prototype = Object.create({
		/*
		 *Feature styles provider
		 */
		updateColors: function (opts) {
			this.opts = opts || {};

			if (!isFontAvailable('AirFlight-Pictos') || !isFontAvailable('Ubuntu Mono')) {
				//if all font are not available, we schedule a new init
			}

			var
				sizeFactor = 2.5,
				smallSize = 16,
				mediumSize = 22,
				largeSize = 30,
				myfleeticonL = aircraftTextStyle(opts.myfleetColor || this.defaultColors.myfleet, (smallSize * sizeFactor) + 'px'),
				myfleeticonM = aircraftTextStyle(opts.myfleetColor || this.defaultColors.myfleet, (mediumSize * sizeFactor) + 'px'),
				myfleeticonH = aircraftTextStyle(opts.myfleetColor || this.defaultColors.myfleet, (largeSize * sizeFactor) + 'px'),
				trackediconL = aircraftTextStyle(opts.trackedColor || this.defaultColors.tracked, (smallSize * sizeFactor) + 'px'),
				trackediconM = aircraftTextStyle(opts.trackedColor || this.defaultColors.tracked, (mediumSize * sizeFactor) + 'px'),
				trackediconH = aircraftTextStyle(opts.trackedColor || this.defaultColors.tracked, (largeSize * sizeFactor) + 'px'),
				cautioniconL = aircraftTextStyle(opts.cautionColor || this.defaultColors.caution, (smallSize * sizeFactor) + 'px'),
				cautioniconM = aircraftTextStyle(opts.cautionColor || this.defaultColors.caution, (mediumSize * sizeFactor) + 'px'),
				cautioniconH = aircraftTextStyle(opts.cautionColor || this.defaultColors.caution, (largeSize * sizeFactor) + 'px'),
				warningiconL = aircraftTextStyle(opts.warningColor || this.defaultColors.warning, (smallSize * sizeFactor) + 'px'),
				warningiconM = aircraftTextStyle(opts.warningColor || this.defaultColors.warning, (mediumSize * sizeFactor) + 'px'),
				warningiconH = aircraftTextStyle(opts.warningColor || this.defaultColors.warning, (largeSize * sizeFactor) + 'px'),
				othericonL = aircraftTextStyle(opts.othersColor || this.defaultColors.other, (smallSize * sizeFactor) + 'px'),
				othericonM = aircraftTextStyle(opts.othersColor || this.defaultColors.other, (mediumSize * sizeFactor) + 'px'),
				othericonH = aircraftTextStyle(opts.othersColor || this.defaultColors.other, (largeSize * sizeFactor) + 'px'),
				closediconL = closedAircraftTextStyle(opts.closedColor || this.defaultColors.closed, (smallSize * sizeFactor) + 'px'),
				closediconM = closedAircraftTextStyle(opts.closedColor || this.defaultColors.closed, (mediumSize * sizeFactor) + 'px'),
				closediconH = closedAircraftTextStyle(opts.closedColor || this.defaultColors.closed, (largeSize * sizeFactor) + 'px'),

				groundVehicleIcon = groundVehicleTextStyle(opts.groundVehicleColor || this.defaultColors.groundVehicle, (smallSize * sizeFactor) + 'px'),

				realTrackedFlightPositionTrail = [new ol.style.Style({
					stroke: new ol.style.Stroke({
						color: this.defaultColors.external,
						width: 5
					})
				}), new ol.style.Style({
					stroke: new ol.style.Stroke({
						color: this.defaultColors.trajectory,
						width: 4
					})
				})],
				realOtherFlightPositionTrail = [new ol.style.Style({
					stroke: new ol.style.Stroke({
						color: this.defaultColors.external,
						width: 5
					})
				}), new ol.style.Style({
					stroke: new ol.style.Stroke({
						color: this.defaultColors.other,
						width: 2
					})
				})],
				realMyfleetFlightPositionTrail = [new ol.style.Style({
					stroke: new ol.style.Stroke({
						color: this.defaultColors.external,
						width: 5
					})
				}), new ol.style.Style({
					stroke: new ol.style.Stroke({
						color: this.defaultColors.trajectory,
						width: 4
					})
				})],
				lastTrackedFlightPosition = [new ol.style.Style({
					image: new ol.style.Circle({
						radius: 5,
						fill: new ol.style.Fill({
							color: this.defaultColors.trajectory
						}),
						stroke: new ol.style.Stroke({
							color: 'black',
							width: 1
						})
					})
				})],
				lastOtherFlightPosition = [new ol.style.Style({
					image: new ol.style.Circle({
						radius: 5,
						fill: new ol.style.Fill({
							color: this.defaultColors.other
						}),
						stroke: new ol.style.Stroke({
							color: 'black',
							width: 1
						})
					})
				})],
				warningLastFlightPosition = [new ol.style.Style({
					image: new ol.style.Circle({
						radius: 5,
						fill: new ol.style.Fill({
							color: opts.warningColor || this.defaultColors.warning
						}),
						stroke: new ol.style.Stroke({
							color: 'black',
							width: 1
						})
					})
				})],
				cautionLastFlightPosition = [new ol.style.Style({
					image: new ol.style.Circle({
						radius: 5,
						fill: new ol.style.Fill({
							color: opts.cautionColor || this.defaultColors.caution
						}),
						stroke: new ol.style.Stroke({
							color: 'black',
							width: 1
						})
					})
				})],
				otherLastFlightPosition = [new ol.style.Style({
					image: new ol.style.Circle({
						radius: 5,
						fill: new ol.style.Fill({
							color: opts.othersColor || this.defaultColors.other
						}),
						stroke: new ol.style.Stroke({
							color: 'black',
							width: 1
						})
					})
				})],
				closedLastFlightPosition = [new ol.style.Style({
					image: new ol.style.Circle({
						radius: 5,
						stroke: new ol.style.Stroke({
							color: 'black',
							width: 1
						})
					})
				})],
				lastMyfleetFlightPosition = [new ol.style.Style({
					image: new ol.style.Circle({
						radius: 5,
						fill: new ol.style.Fill({
							color: this.defaultColors.trajectory
						}),
						stroke: new ol.style.Stroke({
							color: 'black',
							width: 1
						})
					})
				})],
				acarsFlightPosition = [new ol.style.Style({
					image: new ol.style.Circle({
						radius: 4,
						stroke: new ol.style.Stroke({
							color: 'white',
							width: 1
						})
					})
				})],
				estimatedTrackedFlightPositionTrail = [new ol.style.Style({
					stroke: new ol.style.Stroke({
						color: this.defaultColors.external,
						width: 5,
						lineDash: [8, 12]
					})
				}), new ol.style.Style({
					stroke: new ol.style.Stroke({
						color: this.defaultColors.trajectory,
						width: 4,
						lineDash: [8, 12]
					})
				})],
				estimatedCautionFlightPositionTrail = [new ol.style.Style({
					stroke: new ol.style.Stroke({
						color: this.defaultColors.external,
						width: 5,
						lineDash: [8, 12]
					})
				}), new ol.style.Style({
					stroke: new ol.style.Stroke({
						color: opts.cautionColor || this.defaultColors.caution,
						width: 4,
						lineDash: [8, 12]
					})
				})],
				estimatedWarningFlightPositionTrail = [new ol.style.Style({
					stroke: new ol.style.Stroke({
						color: this.defaultColors.external,
						width: 5,
						lineDash: [8, 12]
					})
				}), new ol.style.Style({
					stroke: new ol.style.Stroke({
						color: opts.warningColor || this.defaultColors.warning,
						width: 4,
						lineDash: [8, 12]
					})
				})],
				estimatedOtherFlightPositionTrail = [new ol.style.Style({
					stroke: new ol.style.Stroke({
						color: this.defaultColors.external,
						width: 5,
						lineDash: [8, 12]
					})
				}), new ol.style.Style({
					stroke: new ol.style.Stroke({
						color: this.defaultColors.other,
						width: 4,
						lineDash: [8, 12]
					})
				})],
				estimatedMyfleetFlightPositionTrail = [new ol.style.Style({
					stroke: new ol.style.Stroke({
						color: this.defaultColors.external,
						width: 5,
						lineDash: [8, 12]
					})
				}), new ol.style.Style({
					stroke: new ol.style.Stroke({
						color: this.defaultColors.trajectory,
						width: 4,
						lineDash: [8, 12]
					})
				})],
				projectionCap = [new ol.style.Style({
					image: new ol.style.Circle({
						radius: 3,
						fill: new ol.style.Fill({
							color: 'white'
						}),
						stroke: new ol.style.Stroke({
							color: 'black',
							width: 1
						})
					})
				})],
				projectionFlightPositionTrail = [new ol.style.Style({
					stroke: new ol.style.Stroke({
						color: 'white',
						width: 1,
						lineDash: [6, 4]
					})
				})],
				waypointiconcrossed = new ol.style.Text({
					text: '\ue66a',
					font: 'normal 14px AirFlight-Pictos',
					// textBaseline: 'Bottom',
					fill: new ol.style.Fill({
						color: this.defaultColors.waypoint.fill
					}),
					stroke: new ol.style.Stroke({
						color: this.defaultColors.waypoint.normal,
						width: 3
					})
				}),
				waypointiconreallycrossed = new ol.style.Text({
					text: '\ue66a',
					font: 'normal 14px AirFlight-Pictos',
					// textBaseline: 'Bottom',
					fill: new ol.style.Fill({
						color: this.defaultColors.waypoint.fill
					}),
					stroke: new ol.style.Stroke({
						color: this.defaultColors.waypoint.crossed,
						width: 3
					})
				}),
				waypointiconfuture = new ol.style.Text({
					text: '\ue66a',
					font: 'normal 14px AirFlight-Pictos',
					// textBaseline: 'Bottom',
					fill: new ol.style.Fill({
						color: this.defaultColors.waypoint.fill
					}),
					stroke: new ol.style.Stroke({
						color: this.defaultColors.waypoint.normal,
						width: 3
					})
				}),
				waypointiconblur = new ol.style.Text({
					text: '\ue66a',
					font: 'normal 14px AirFlight-Pictos',
					textAlign: 'center',
					stroke: new ol.style.Stroke({
						color: [0, 0, 0, 0.3],
						width: 7
					})
				}),

				runway = [new ol.style.Style({
					fill: new ol.style.Fill({
						color: [255, 255, 0, 0.2]
					}),
					stroke: new ol.style.Stroke({
						color: [255, 255, 0, 1],
						width: 2
					}),
					text: new ol.style.Text({
						text: 'RUNWAY',
						textAlign: 'left',
						font: 'normal 12px Ubuntu Mono',
						fill: new ol.style.Fill({
							color: [255, 255, 0, 1]
						}),
						stroke: new ol.style.Stroke({
							color: 'black',
							width: 3
						})
					})
				})],
				measure = [new ol.style.Style({
					fill: new ol.style.Fill({
						color: [255, 255, 0, 0.4]
					}),
					stroke: new ol.style.Stroke({
						color: [255, 255, 0, 0.4],
						width: 3
					})
				})],
				shape = [new ol.style.Style({
					text: new ol.style.Text({
						text: 'Shape',
						font: 'normal 12px Ubuntu Mono',
						fill: new ol.style.Fill({
							color: [255, 255, 255, 1]
						}),
						stroke: new ol.style.Stroke({
							color: [0, 0, 0, 0.4],
							width: 3
						})
					}),
					fill: new ol.style.Fill({
						color: [255, 255, 0, 0.4]
					}),
					stroke: new ol.style.Stroke({
						color: [255, 255, 0, 1],
						width: 3
					})
				})],
				locationIcon = new ol.style.Text({
					text: 'A',
					font: 'normal 16px "Source Sans Pro"',
					// textBaseline: 'Bottom',
					fill: new ol.style.Fill({
						color: 'white'
					}),
					offsetY: -20
				}),
				locationPin = new ol.style.Text({
					text: '\ue610',
					font: 'normal 32px AirFlight-Pictos',
					// textBaseline: 'Bottom',
					fill: new ol.style.Fill({
						color: '#74D2E7'
					}),
					stroke: new ol.style.Stroke({
						color: 'black',
						width: 2
					}),
					offsetY: -16
				}),
				hidden = [new ol.style.Style({})];

			this.styles = {
				level: {
					// 0: tracked, 1:caution, 2:warning, 3:others, 4:closed
					'L': [
						new ol.style.Style({
							//text: trackediconL,
							image: generateTextIcon(trackediconL),
							zIndex: 1
						}),
						new ol.style.Style({
							//text: cautioniconL,
							image: generateTextIcon(cautioniconL),
							zIndex: 2
						}),
						new ol.style.Style({
							//text: warningiconL,
							image: generateTextIcon(warningiconL),
							zIndex: 3
						}),
						new ol.style.Style({
							//text: myfleeticonL,
							image: generateTextIcon(myfleeticonL),
							zIndex: 1
						}),
						new ol.style.Style({
							// text: othericonL,
							image: generateTextIcon(othericonL),
							zIndex: 0
						}),
						new ol.style.Style({
							// text: closediconL,
							image: generateTextIcon(closediconL),
							zIndex: 0
						})
					],
					'M': [
						new ol.style.Style({
							//text: trackediconM,
							image: generateTextIcon(trackediconM),
							zIndex: 1
						}),
						new ol.style.Style({
							//text: cautioniconM,
							image: generateTextIcon(cautioniconM),
							zIndex: 2
						}),
						new ol.style.Style({
							//text: warningiconM,
							image: generateTextIcon(warningiconM),
							zIndex: 3
						}),
						new ol.style.Style({
							//text: myfleeticonM,
							image: generateTextIcon(myfleeticonM),
							zIndex: 1
						}),
						new ol.style.Style({
							//text: othericonM,
							image: generateTextIcon(othericonM),
							zIndex: 0
						}),
						new ol.style.Style({
							//text: closediconM,
							image: generateTextIcon(closediconM),
							zIndex: 0
						})
					],
					'H': [
						new ol.style.Style({
							//text: trackediconH,
							image: generateTextIcon(trackediconH),
							zIndex: 1
						}),
						new ol.style.Style({
							//text: cautioniconH,
							image: generateTextIcon(cautioniconH),
							zIndex: 2
						}),
						new ol.style.Style({
							//text: warningiconH,
							image: generateTextIcon(warningiconH),
							zIndex: 3
						}),
						new ol.style.Style({
							//text: myfleeticonH,
							image: generateTextIcon(myfleeticonH),
							zIndex: 1
						}),
						new ol.style.Style({
							//text: othericonH,
							image: generateTextIcon(othericonH),
							zIndex: 0
						}),
						new ol.style.Style({
							//text: closediconH,
							image: generateTextIcon(closediconH),
							zIndex: 0
						})
					]
				},
				waypoint: [
					new ol.style.Style({
						text: waypointiconcrossed,
						zIndex: 1
					}),
					new ol.style.Style({
						text: waypointiconreallycrossed,
						zIndex: 1
					}),
					new ol.style.Style({
						text: waypointiconfuture,
						zIndex: 1
					}),
					new ol.style.Style({
						text: waypointiconblur,
						zIndex: 0
					})
				],
				flightPlanFlightAware: [
					new ol.style.Style({
						stroke: new ol.style.Stroke({
							color: 'white',
							width: 3
						})
					}),
					new ol.style.Style({
						stroke: new ol.style.Stroke({
							color: '#1DB1D2',
							width: 2
						})
					})
				],
				flightPlanGreatCircle: [
					new ol.style.Style({
						stroke: new ol.style.Stroke({
							color: 'white',
							width: 2,
							lineDash: [8, 10]
						})
					})
				],
				flightPlanCrossed: [
					new ol.style.Style({
						stroke: new ol.style.Stroke({
							color: this.defaultColors.external,
							width: 3
						})
					}),
					new ol.style.Style({
						stroke: new ol.style.Stroke({
							color: '#B3B3B3',
							width: 2
						})
					})
				],
				flightPlanFuture: [new ol.style.Style({
					stroke: new ol.style.Stroke({
						color: this.defaultColors.external,
						width: 3,
						lineDash: [8, 10]
					})
				}),
				new ol.style.Style({
					stroke: new ol.style.Stroke({
						color: '#B3B3B3',
						width: 2,
						lineDash: [8, 10]
					})
				})
				],

				groundVehicle: [
					new ol.style.Style({
						image: generateTextIcon(groundVehicleIcon),
						zIndex: 0
					})
				],

				runway: runway,

				realFlightPositionTrail: [
					realTrackedFlightPositionTrail,
					realOtherFlightPositionTrail,
					realMyfleetFlightPositionTrail
				],
				lastFlightPositions: [
					lastTrackedFlightPosition,
					lastOtherFlightPosition,
					lastMyfleetFlightPosition,
				],
				estimatedFlightPositionTrail: [
					estimatedTrackedFlightPositionTrail,
					estimatedCautionFlightPositionTrail,
					estimatedWarningFlightPositionTrail,
					estimatedMyfleetFlightPositionTrail,
					estimatedOtherFlightPositionTrail,
				],
				projectionFlightPositionTrail: projectionFlightPositionTrail,
				acarsFlightPosition: acarsFlightPosition,
				projectionCap: projectionCap,
				drawing: {
					measure: measure,
					location: [new ol.style.Style({
						text: locationPin
					}), new ol.style.Style({
						text: locationIcon
					})],
					shape: shape
				},
			};
		},

		waypointTooltipStyle: function (feature, crossed) {
			var styles, waypointName, background, waypointNameTextShadowBottom, waypointNameTextBottom;
			waypointName = feature.get('name') || '--';
			waypointNameTextShadowBottom = new ol.style.Text({
				text: waypointName,
				font: 'normal 12px Ubuntu Mono',
				textAlign: 'left',
				fill: new ol.style.Fill({
					color: 'black'
				}),
				offsetX: -16,
				offsetY: -26
			});
			waypointNameTextBottom = new ol.style.Text({
				text: waypointName,
				font: 'normal 12px Ubuntu Mono',
				textAlign: 'left',
				fill: new ol.style.Fill({
					color: crossed ? this.defaultColors.waypoint.crossed : this.defaultColors.waypoint.normal
				}),
				offsetX: -16,
				offsetY: -27
			});

			styles = [];
			styles.push(new ol.style.Style({
				image: background
			}));
			styles.push(new ol.style.Style({
				text: waypointNameTextShadowBottom
			}));
			styles.push(new ol.style.Style({
				text: waypointNameTextBottom
			}));
			return styles;
		},

		planeStyle: function (feature, resolution) {
			var features = feature.get('features'),
				f = (features && features[0]) || feature,
				isInMyfleet = _.includes(_.map(userProfileStore.userProfile.assignedAircrafts, 'registration'), f.get('acn')),
				//#238 revoir cette condition
				state = !!f.get('closed') ? 5 : !!f.get('customerAircraft') ? !!f.get('alertLvl') ? f.get('alertLvl') : isInMyfleet ? 3 : 0 : 4,
				cat = f.get('cat'),
				featureStyle = this.styles.level[cat][state] || this.styles.level[cat][0],
				tooltips = [],
				icon = featureStyle.getText() || featureStyle.getImage();
			icon.setRotation(GeometryUtils.degToRad(f.get('track')));

			// flight id & level tooltip are hidden when the zoom level is lower than 5, the tooltips of aircrafts with alert is always shown
			var resolutionAndAlertFlag = resolution < 9800 || f.get('alertLvl') > 0;
			var stateAndFeatureFlag = state < 4 && !features;
			if (showFlightInfo && resolutionAndAlertFlag && stateAndFeatureFlag) {
				tooltips = this.aircraftTooltipProvider.getTooltipOLStyle(f, userProfileStore.userProfile);
			} else {
				f.tooltip = [];
			}
			return tooltips.concat(featureStyle);
		},

		surfaceTrackingPlaneStyle: function (feature) {
			var featureStyle = new ol.style.Style({
				image: generateTextIcon(new ol.style.Text({
					text: '\ue604',
					font: 'normal ' + '50px' + ' AirFlight-Pictos',
					fill: new ol.style.Fill({
						color: feature.get('planeColor')
					}),
					stroke: new ol.style.Stroke({
						color: 'black',
						width: 1
					})
				})
				)
			}),
				tooltips = [],
				icon = featureStyle.getImage();
			icon.setRotation(GeometryUtils.degToRad(feature.get('track')));
			if (feature.get('showPlaneLabel')) {
				tooltips = this.aircraftTooltipProvider.getSurfaceTrackingTooltipOLStyle(feature, userProfileStore.userProfile);
			} else {
				tooltips = [];
			}
			return tooltips.concat(featureStyle);
		},


		getLabelArray: function () {
			return label_array;
		},
		getAnchorArray: function () {
			return anchor_array;
		},


		trailStyle: function (feature, resolution) {
			var style, icon, type = feature.get('name'),
				hide = feature.get('hidden'),
				state = feature.get('state'),
				cat = 'M';
			if ('trail' === type) {
				style = this.styles.realFlightPositionTrail[state];
			} else if ('lastRealPosition' === type) {
				style = this.styles.lastFlightPositions[state];
			} else if ('acarsPosition' === type) {
				style = this.styles.acarsFlightPosition;
			} else if ('projectionCap' === type) {
				style = this.styles.projectionCap;
			} else if ('aircraftReplayPosition' === type) {
				style = this.styles.level[cat][0];
				icon = style.getText() || style.getImage();
				icon.setRotation(GeometryUtils.degToRad(feature.get('track')));
			} else if ('estimatedTrail' === type) {
				if (hide) {
					style = this.styles.hidden;
				} else {
					style = this.styles.estimatedFlightPositionTrail[state];
				}
			} else if ('projectionTrail' === type) {
				style = this.styles.projectionFlightPositionTrail;
			}
			return style;
		},

		waypointStyle: function (feature, resolution) {
			var features = feature.get('features'),
				state = feature.get('state'),
				crossed = feature.get('crossed'),
				f = (features && features[0]) || feature,
				styles = [];
			if (resolution <= 612) {
				styles = styles.concat(this.waypointTooltipStyle(f, crossed));
			}
			if (state === 'crossed') {
				if (!crossed) {
					styles.push(this.styles.waypoint[0]);
					styles.push(this.styles.waypoint[3]);
				} else {
					styles.push(this.styles.waypoint[1]);
					styles.push(this.styles.waypoint[3]);
				}
			} else {
				styles.push(this.styles.waypoint[2]);
				styles.push(this.styles.waypoint[3]);
			}
			return styles;
		},

		flightPlanStyle: function (feature, resolution) {
			let source = feature.get('source') || '';

			if (source === 'FLIGHT_AWARE') {
				return this.styles.flightPlanFlightAware;
			}
			else {
				var type = feature.get('name');
				if (type === 'flightPlanCrossed') {
					return this.styles.flightPlanCrossed;
				} else if (type === 'flightPlanFuture') {
					return this.styles.flightPlanFuture;
				} else if (type === 'flightPlanGreatCircle') {
					return this.styles.flightPlanGreatCircle;
				}
			}
		},

		groundVehicleStyle: function (feature, resolution) {
			featureStyle = this.styles.groundVehicle[0];
			featureStyle.getImage().setRotation(GeometryUtils.degToRad(feature.get('track')));
			return featureStyle;
		},

		runwayStyle: function (feature, resolution) {
			this.styles.runway[0].getText().setText(feature.get('leIdent') + '/' + feature.get('heIdent'));
			return this.styles.runway;
		},

		selectedPlaneStyle: function (feature, resolution, userProfile) {
			return this.planeStyle(feature, resolution, userProfile);
		},

		measureStyle: function (feature, resolution) {
			var classValue = feature.get('class'),
				styles = this.styles.drawing[classValue],
				name;
			if (classValue === 'location') {
				name = feature.get('name');
				styles[1].getText().setText(name);
				if (name === 'A') {
					styles[0].getText().getFill().setColor('#74D2E7');
				} else {
					styles[0].getText().getFill().setColor('#48A9C5');
				}
			}
			return styles;
		},
		drawStyle: function (feature, resolution) {
			var classValue = feature.get('class'),
				colorValue = feature.get('color'),
				name = feature.get('name'),
				styles = _.clone(this.styles.drawing[classValue]),
				previous = feature.getGeometry().getFirstCoordinate(),
				lastCoordinate = feature.getGeometry().getLastCoordinate();
			if (classValue === 'location') {
				styles[1].getText().setText(name);
				if (name === 'A') {
					styles[0].getText().getFill().setColor('#74D2E7');
				} else {
					styles[0].getText().getFill().setColor('#48A9C5');
				}
			} else {
				styles[0].getText().setText(name);
				if (colorValue) {
					colorValue = ol.color.asArray(colorValue);
					colorValue[3] = 0.4;
					styles[0].getFill().setColor(colorValue);
					styles[0].getStroke().setColor(colorValue);
				} else {
					styles[0].getFill().setColor([255, 255, 0, 0.4]);
					styles[0].getStroke().setColor([255, 255, 0, 0.4]);
				}
				if (feature.getGeometry().getType() === 'LineString') {
					if (!colorValue) {
						colorValue = [255, 255, 0, 0.4];
					}
					feature.getGeometry().forEachSegment(function (start, end) {
						var dx, dy, r;
						dx = end[0] - previous[0];
						dy = end[1] - previous[1];
						previous = _.clone(start);
						r = Math.atan2(dy, dx);
						styles.push(new ol.style.Style({
							geometry: new ol.geom.Point(start),
							image: new ol.style.Icon({
								anchor: [0.5, 0.65],
								rotateWithView: false,
								rotation: -r,
								src: 'img/line_arrow.png',
								size: [16, 16],
								scale: 1.3,
								color: [0, 0, 0, 0]
							})
						}));
						styles.push(new ol.style.Style({
							geometry: new ol.geom.Point(start),

							image: new ol.style.Icon({
								anchor: [0.5, 0.75],
								rotateWithView: false,
								rotation: -r,
								src: 'img/line_arrow.png',
								color: colorValue
							})
						}));

						//End point of last segment case
						if (_.isEqual(end, lastCoordinate)) {
							dx = end[0] - start[0];
							dy = end[1] - start[1];
							r = Math.atan2(dy, dx);
							styles.push(new ol.style.Style({
								geometry: new ol.geom.Point(end),
								image: new ol.style.Icon({
									anchor: [0.5, 0.65],
									rotateWithView: false,
									rotation: -r,
									src: 'img/line_arrow.png',
									size: [16, 16],
									scale: 1.3,
									color: [0, 0, 0, 0]
								})
							}));
							styles.push(new ol.style.Style({
								geometry: new ol.geom.Point(end),
								image: new ol.style.Icon({
									anchor: [0.5, 0.75],
									rotateWithView: false,
									rotation: -r,
									src: 'img/line_arrow.png',
									color: colorValue
								})
							}));
						}
					});
				}
			}
			return styles;
		},

		//#238 remove two next setter
		setFlightInfoVisible: function (visible) {
			showFlightInfo = visible;
		},
		setDefaultColors: function (colors) {
			this.defaultColors = colors;
		}
	});


	var mapStyleInstance = null;
	var userProfileStore = null;
	var fieldDefinitions = null;

	App.factory('mapStyle', ['userProfileNgStore', 'flightfieldDefinitions', 'aircraftTooltipProvider', 'defaultColors', function mapStyleFactory(userProfileNgStore, flightfieldDefinitions,
		aircraftTooltipProvider, defaultColors) {
		mapStyleInstance = mapStyleInstance || new StyleProvider(defaultColors);
		userProfileStore = userProfileNgStore;
		fieldDefinitions = flightfieldDefinitions;
		mapStyleInstance.aircraftTooltipProvider = aircraftTooltipProvider;
		// mapStyleInstance.setDefaultColors(defaultColors);
		return mapStyleInstance;
	}]);
}(window));
