/*jslint browser: true, plusplus:true, nomen:true*/
/*global _, angular, ol, App, Promise */

(function () {
  'use strict';

  function SettingsAirlinesCtrl($rootScope, $scope, $state, userService, airlineDataStore, flightNgStore) {

    // Not displayed airlines saved
    var hiddenAirlines = $scope.user.preferences.hiddenAirlines,
      // Airlines list
      mappingAirlines = $scope.user.configuration.displayedAirlines || [];

    // Displayed airlines checked
    $scope.settingsAirlines = _.difference(mappingAirlines, hiddenAirlines);

    // Airline settings list
    $scope.mappingAirlines = mappingAirlines;

    $scope.getAirline = function (code) {
      return airlineDataStore.airlines[code];
    };
    
    $scope.submitAirlinesSettings = function () {
      // Getting not displayed airlines
      var hiddenAirlines = _.difference(mappingAirlines, $scope.settingsAirlines);
      return new Promise(function (resolve, reject) {
        userService.savePreferences({
          hiddenAirlines: hiddenAirlines
        }).then(resolve, function () {
          //reload last preferences in DB
          $scope.settingsAirlines = _.difference(mappingAirlines, hiddenAirlines);
          $scope.$apply();
          reject(arguments);
        }).then(function(){
          flightNgStore.refresh();
        });
      });
    };

    $scope.getLogoUrl = function (icao) {
        return window.backendPrefix + '/airline/logo/' + icao;
    };
  }

  App
    .controller('SettingsAirlinesCtrl', ['$rootScope', '$scope', '$state', 'userService', 'airlineDataStore', 'flightNgStore', SettingsAirlinesCtrl]);
}());
