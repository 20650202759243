/*jslint browser: true, nomen: true */
/*global App, Promise, GenericStore, angular, _, ol */

App.provider('featureNgStore', [function featureNgStoreProvider() {
  'use strict';

  var _super, featureNgStore, RwyCondEnum, olFormats;

  olFormats = {
    geojson: new ol.format.GeoJSON()
  };

  function FeatureNgStore() {
    GenericStore.call(this);
    this.$rootScope = {};
    this.featuresById = {};
    this.isLoaded = false;
  }

  _super = GenericStore.prototype;

  FeatureNgStore.prototype = Object.create(_super);

  angular.extend(FeatureNgStore.prototype, {
    //
    // Constructor

    constructor: featureNgStore,

    RwyCondEnum: RwyCondEnum,

    //
    // Overridable

    newInstance: function (values) {
      var feature = {
        id: values.id
      };
      this.updateInstance(feature, values);
      this.featuresById[feature.id] = feature;
      this.isLoaded = true;
      return feature;
    },

    updateInstance: function (entity, values) {
      entity.name = values.name;
      entity.customerId = values.customerId;
      entity.authorId = values.authorId;
      entity.shared = values.shared;
      entity.airspace = values.airspace;
      if (!!this.$rootScope.user) {
        entity.isVisible = !this.$rootScope.user.preferences.mapOptions.hiddenDrawing[entity.id];
      }
      if (entity.data !== values.data || entity.type !== values.type) {
        entity.type = values.type;
        entity.data = values.data;
        Object.defineProperty(entity, 'olFeature', {
          configurable: true,
          enumerable: false,
          value: olFormats[entity.type].readFeature(entity.data, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
          })
        });
      }
      entity.olFeature.set('name', entity.name);
    },

    findById: function (id) {
      return this.featuresById[id];
    },

    loadData: function (force) {
      return new Promise(function (resolve, reject) {
        if (this.isLoaded === true) {
          resolve(this.featureAll);
        } else {
          this.on('sync', function () {
            if (this.isLoaded) {
              this.off('sync', null, this);
              resolve(this.featureAll);
            }
          }, this);
          if (force === true) {
            this.refresh();
          }
        }
      }.bind(this));
    }
  });

  Object.defineProperties(FeatureNgStore.prototype, {
    featureAll: {
      get: function () {
        return this._entities;
      }
    },
    features: {
      get: function () {
        if (!!this.$rootScope.user) {
          return _.filter(this._entities, function (entity) {
            return entity.shared || entity.authorId === this.$rootScope.user.id;
          }.bind(this));
        }
        return [];
      }
    },
    myFeatures: {
      get: function () {
        if (!!this.$rootScope.user) {
          return _.filter(this._entities, function (entity) {
            return entity.authorId === this.$rootScope.user.id;
          }.bind(this));
        }
        return [];
      }
    },
    othersFeatures: {
      get: function () {
        if (!!this.$rootScope.user) {
          return _.filter(this._entities, function (entity) {
            return entity.shared && entity.authorId !== this.$rootScope.user.id;
          }.bind(this));
        }
        return [];
      }
    },
    availableFeatures: {
      get: function () {
        if (!!this.$rootScope.user) {
          return _.filter(this._entities, function (entity) {
            return entity.shared && !entity.airspace;
          }.bind(this));
        }
        return [];
      }
    },
    airspaceFeatures: {
      get: function () {
        if (!!this.$rootScope.user) {
          return _.filter(this._entities, function (entity) {
            return entity.shared && entity.airspace;
          }.bind(this));
        }
        return [];
      }
    }
  });

  //
  // Store instance

  featureNgStore = new FeatureNgStore();

  this.$get = ['featureStore', '$rootScope', function featureNgStoreFactory(featureStore, $rootScope) {
    featureNgStore.store = featureStore;
    featureNgStore.$rootScope = $rootScope;
    return featureNgStore;
  }];

}]);
