/*jslint browser: true, nomen:true */
/*global App, angular, Promise, PubSub, fetch, $, _ */
App.factory('flightSearchService', ['flightfieldDefinitions', 'flightNgStore', 'userService', 'generalConstants',
            function FlightSearchServiceFactory(flightfieldDefinitions, flightNgStore, userService, generalConstants) {
  'use strict';
  var flightSearchService;
  //
  // Class FlightService extend PubSub
  function FlightSearchService() {
    PubSub.apply(this, arguments);
    // define attributes
    this.displayedBySection = {
      myfleet: true,
      tracked: true,
      other: true
    };

    //Number of flights in that section
    this.hiddenBySection = {
      myfleet: 0,
      tracked: 0,
      other: 0
    };

    //Number of flights filtered in that section
    this.filteredBySection = {
      myfleet: 0,
      tracked: 0,
      other: 0
    };

    this.isFiltererd = false;
    this.isFilteredDeskOnly = false;
    this.searchFilters = [];
    this.available = _.pickBy(flightfieldDefinitions, _.partial(_.has, _, 'filter'));
  }

  FlightSearchService.prototype = Object.create(PubSub.prototype);
  //
  // Methods addition
  _.assign(FlightSearchService.prototype, {
    // override constructor
    constructor: FlightSearchService,


    addSearchFilter: function (property, predicate, displayed) {
      let filter;
      let key = property || '$';
      if (predicate === '') {
        this.removeSearchFilter(key);
      } else {
        if (key === '$') {
          filter = {
            key: key,
            label: 'Global',
            wanted: predicate
          };
        } else {
          filter = {
            key: key,
            label: flightfieldDefinitions[key].label,
            displayed: displayed,
            wanted: predicate
          };
        }
        // remove any existent filter on this property
        _.remove(this.searchFilters, {
          key: key
        });
        this.searchFilters.push(filter);
        this.trigger('filter:add', filter);
        flightNgStore.search(predicate, property);
      }
      this.isFilteredDeskOnly = this.searchFilters.length === 1 && this.searchFilters[0].key === generalConstants.deskFieldName;
    },
    removeSearchFilter: function (property) {
      var key = property || '$';
      flightNgStore.removeSearch(property);
      _.remove(this.searchFilters, {
        key: key
      });
      this.isFilteredDeskOnly = this.searchFilters.length === 1 && this.searchFilters[0].key === generalConstants.deskFieldName;
      this.trigger('filter:remove', key);
    },
    refresh: function () {
      flightNgStore.updateFilteredEntities(true, true);
    },
    clear: function () {
      while (this.searchFilters.length) {
        this.removeSearchFilter(this.searchFilters[0].key);
      }
    },
    replaceFilters: function (newFlightFilters) {
      this.clear();
      if (!newFlightFilters) {
        return;
      }

      newFlightFilters.forEach(filter => {
        this.addSearchFilter(filter.key, filter.wanted, filter.displayed);
      });
    },
    getActiveFilters: function () {
      return this.searchFilters;
    },
    getAvailableFilters: function () {
      return this.available;
    },
    getActiveToggles: function () {
       return this.displayedBySection;
    }
  });

  flightSearchService = new FlightSearchService();
  userService.on('logout', flightSearchService.clear, flightSearchService);
  return flightSearchService;

}]);
