App.controller('routeInfoController', [
    '$scope',
    'title',
    'routeInfo',
    function ($scope, title, routeInfo) {
      'use strict';
  
      var vm = this;

      $scope.title = title;
      $scope.routeInfo = routeInfo;

      vm.copyRouteStringtoClipboard = function (routeString) {
        Utils.copyToClipboard(routeString);
      };
    
    }
  ]);