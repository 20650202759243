/*jslint browser: true, plusplus:true*/
/*global angular, ol, App, ErrorCode */


App
  .controller('SuperAirportPageCtrl', [
    '$rootScope',
    '$scope',
    function AirportPageCtrl($rootScope, $scope) {
      'use strict';

      function readBody(xhr) {
          var data;
          if (!xhr.responseType || xhr.responseType === "text") {
              data = xhr.responseText;
          } else if (xhr.responseType === "document") {
              data = xhr.responseXML;
          } else {
              data = xhr.response;
          }
          return data;
      }

      $scope.upload = function () {

        var jsonFile = document.getElementById("ai");
        // the file is the first element in the files property
        var file = jsonFile.files[0];
        var formData = new FormData();
        formData.append('data', file, file.name);
        // Set up the request.
        var xhr = new XMLHttpRequest();
        
        xhr.open('POST', '/v1/airport', true);
        xhr.onload = function () {
          if (xhr.status === 200) {
            var airports = JSON.parse(readBody(xhr));
            var airportsIcao = new Array();
            _.forEachRight(airports, function(value) {
              airportsIcao.push(value.icao);
            });

            var message = 'airports with icao '+airportsIcao+' have been updated succesfully';
           alert(message);
          } else {

            var error = JSON.parse(readBody(xhr));
            console.log(readBody(xhr));
            console.log(error);
            alert("error : "+error[0].values[0]);
          }
        };

        xhr.send(formData);
      };
    }
  ]);
