App.factory('aircraftRestService', ['$http', function($http) {

    return {
    associatePlannedFlightPlansWithFlight: function(customerId, flightId, flightPlans) {
            return $http({
            method: 'POST',
            url: window.backendPrefix + '/customer/' + customerId + '/aircraft-flight/associate-planned-flight-plans',
            withCredentials: true,
            params: { 'flight-plan-ids' : flightPlans.join(), 'flight-id' : flightId }
            }).then( function successCallback( response ) {
                return response.data;
            }, function errorCallback( response ) {
                console.log( response );
                return Error( 'Error linking planned flight. Error: ' + response.status + ' ' + response.statusText );
            });
      },

        unassociatePlannedFlightPlansWithFlight : function(customerId, flightPlanIds, flightId) {
        return $http({
          method: 'POST',
          url: window.backendPrefix + '/customer/' + customerId + '/aircraft-flight/unassociate-planned-flight-plans',
          withCredentials: true,
          params: {'flight-plan-ids' : flightPlanIds.join(), 'flight-id' : flightId }
        }).then( function successCallback( response ) {
            return response.data;
        }, function errorCallback( response ) {
            console.log( response );
            return Error( 'Error unlinking planned flight. Error: ' + response.status + ' ' + response.statusText );
        });
       }
    }
  }]);
  