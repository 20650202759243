App.factory('favoriteModelFactory', [function() {
  'use strict';

  class Favorite {

    constructor(id, name, ownerId, centerLatitude, centerLongitude, resolution, shared, mapLayers, definedAreaIds, flightToggles) {
      this.id = id;
      this.name = name;
      this.ownerId = ownerId;
      this.centerLatitude = centerLatitude;
      this.centerLongitude = centerLongitude;
      this.resolution = resolution;
      this.shared = shared;
      this.mapLayers = mapLayers;
      this.definedAreaIds = definedAreaIds;
      this.flightToggles = flightToggles;
    }
  }

  class MapLayers {

    constructor(weather, se, fir, uir, navLayers) {
      this.weather = weather;
      this.se = se;
      this.fir = fir;
      this.uir = uir;
      this.navLayers = navLayers;
    }
  }

  return {
    favorite: function (id, name, ownerId, centerLatitude, centerLongitude, resolution, shared, mapLayers, definedAreaIds, flightToggles) {
      return new Favorite(id, name, ownerId, centerLatitude, centerLongitude, resolution, shared, mapLayers, definedAreaIds, flightToggles);
    },
    mapLayers: function (weather, se, fir, uir, navLayers) {
      return new MapLayers(weather, se, fir, uir, navLayers);
    }
  };

}]);
