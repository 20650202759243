/*jslint browser: true, vars: true */
/*global App, $, angular, ol, moment */

(function () {
  'use strict';

  App.directive('shTableAutorefresh', ['$timeout', '$injector', '$parse', function ($timeout, $injector, $parse) {
    return {
      require: 'stTable',
      restrict: 'A',
      link: {
        pre: function ($scope, element, $attrs, ctrl) {
          var storeName = $attrs.shTableAutorefresh,
            store = $injector.get(storeName),
            event = !!$attrs.refreshOnFiltered ? 'sync:search' : 'sync';
          // when created
          store.on(event, function (someUpdated, insertedOrRemoved) {
            if (someUpdated || insertedOrRemoved) {
              $scope.$applyAsync();
              ctrl.pipe(ctrl);
            }
          }, ctrl);
          // when destroyed
          $scope.$on('$destroy', function () {
            store.off(event, null, ctrl);
          });
        },
        post: function (scope, element, attrs, ctrl) {
          //ctrl.pipe();
        }
      }
    };
  }]);

}());
