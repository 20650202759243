App.factory('customerNfpConfigRestService', ['$http', 'restService', 'restRequestFactory', function ($http, restService, restRequestFactory) {

    function getBaseUrl(customerId) {
        return window.backendPrefix + '/customer/' + customerId + '/nfp-service-config';
    }

    return {
        getCustomerNfpConfig: function (customerId) {
            let errorMsg = 'Error fetching nfp service configuration.';
            return restService.executeRequest(restRequestFactory.restRequest(getBaseUrl(customerId), null, errorMsg, 'GET', null));
        },

        createCustomerNfpConfig: function (customerId, customerNfpServiceConfig) {
            let errorMsg = 'Error creating nfp service configuration.';
            return restService.executeRequest(restRequestFactory.restRequest(getBaseUrl(customerId), null, errorMsg, 'POST', customerNfpServiceConfig));
        },

        updateCustomerNfpConfig: function (customerId, customerNfpServiceConfig) {
            let errorMsg = 'Error updating nfp service configuration.';
            let url = getBaseUrl(customerId) + '/' + customerNfpServiceConfig.id;
            return restService.executeRequest(restRequestFactory.restRequest(url, null, errorMsg, 'PUT', customerNfpServiceConfig));
        }
    };
}]);
