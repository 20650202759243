/*jslint browser: true, plusplus:true*/
/*global angular, ol, App, ErrorCode */


App.controller('SuperSupportPageCtrl', ['$rootScope','$scope', 'alertService', 'supportRestService','$http', 'restService', 'restRequestFactory', function SuperSupportPageCtrl($rootScope, $scope, alertService, supportRestService,$http, restService, restRequestFactory) {
      'use strict';
    var dateFormat = "DD-MM-YYYY h:mm:ss";
    supportRestService.getAcarsMessages().then((result) => {
          if (result instanceof Error) {
            alertService.error(result.name, result.message);
          } else {
            $scope.rowCollection = result;
            $scope.rowCollection.forEach(acarsMsg => {
                                acarsMsg.messageDate = moment(acarsMsg.messageDate).format(dateFormat);
                                acarsMsg.parseDate = moment(acarsMsg.parseDate).format(dateFormat);
                                acarsMsg.processDate = moment(acarsMsg.processDate).format(dateFormat);
                                acarsMsg.truncatedRawMessage = Utils.truncateLongName(acarsMsg.rawMessage, 21);
                            });
          }
    });

    function getMessageFromRow(messageId) {
        var row = $scope.rowCollection.find(obj => {
            return obj.id === messageId
        });

        return row.rawMessage;
    }

    $scope.itemsByPage=15;

    $scope.openRawMessage = function(messageId) {
        alertService.message('Raw Message', getMessageFromRow(messageId));
    }

    $http.get("/server/version")
        .then(function(response){
            $scope.server_version = response.data;
        });
    $http.get("/client/version")
        .then(function(response){
            $scope.client_version = response.data;
        });
    $http.get("/broker/version")
        .then(function(response){
            $scope.broker_version = response.data;
        });

}]);
