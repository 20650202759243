/*jslint browser: true, nomen: true */
/*global App, Plumbing, _ */

App.provider('aircraftInfoStore', ['customerId', function AircraftInfoStoreProvider(customerId) {
  'use strict';

  var opts, getPath, store, storeUserService;

  opts = {
    path: {
      customer: '/customer/',
      aircraft: '/aircraft'
    }
  };

  getPath = function () {
    if (_.isFinite(customerId.get())) {
      return window.backendPrefix + opts.path.customer + customerId.get() + opts.path.aircraft;
    }
  };

  store = new Plumbing({
    headers: opts.headers,
    url: getPath,
    delay: 120000
  });

  customerId.on('update', function (oldId, newId) {
    if (oldId && oldId !== newId) {
      if (_.isUndefined(newId)) {
        store.adapter.stop();
      } else {
        store.refresh();
      }
    }
  }, this);

  this.setPrefixPart = function (prefix) {
    opts.path.prefix = prefix;
  };

  this.setCustomerPart = function (customer) {
    opts.path.customer = customer;
  };

  this.setAircraftPart = function (aircraft) {
    opts.path.aircraft = aircraft;
  };

  this.$get = [function aircraftInfoStoreFactory() {
    return store;
  }];

}]);
