/*jslint browser: true, plusplus:true*/
/*global angular, ol, App, UserService, ErrorCode */

App
  .controller('SettingsTemplateListCtrl', [
    '$rootScope',
    '$scope',
    '$state',
    '$uibModal',
    'userNgStore',
    'customerNgStore',
    'alertService',
    'userService',
    'userProfileNgStore',
    'flightfieldDefinitions',
    'flightNgStore',
    function SettingsTemplateListCtrl($rootScope, $scope, $state, $uibModal, userNgStore, customerNgStore, alertService, userService, userProfileNgStore, flightfieldDefinitions, flightNgStore) {

      'use strict';

      $scope.isLoading=true;
      var mapOptions = $scope.mapOptions = $scope.user.preferences.mapOptions;

      function getTemplates(){
        var templates = userNgStore.getUserTemplates().then(function(templates){
          templates.forEach(function(t){
              t.fields = _.map(t.fields, function(key){
                var def = flightfieldDefinitions[key]; 
                return _.assign({ key: key }, def);
              });
            });
            $scope.templates = templates;
            $scope.isLoading = false;
            $scope.availableFlightfieldDefinitions = [];
            $scope.$applyAsync(); // fix loading time
        });
      };

      $scope.checkNumberOfTags = function($query){
        if($query && $query.length >= 10){
          return false;
        }
        return true;
      }

      getTemplates();
      /*$scope.templates = getTemplates();
      $scope.availableFlightfieldDefinitions = [];*/

      $scope.addTemplate = function () {
        $scope.templates = _.compact($scope.templates);
        $scope.templates.push({
          name: '',
          labels: ''
        });
      };

     $scope.isFormValid = function () {
        return $scope.manageTemplateForm.$valid;
      };

      $scope.loadAutoCompleteLabels = function ($query) { 
        return  _.chain(flightfieldDefinitions)
          .map(function (def, key) {
            return _.assign({ key: key }, def);
          })
          .filter(function (def, key) {
            return ((def.label.toLowerCase().indexOf($query.toLowerCase()) != -1) && def.displayInTemplate && (_.isUndefined(def.roles) || $rootScope.hasAnyRole(def.roles)) && (_.isUndefined(def.options) || $rootScope.hasAnyOption(def.options)))
          })
          .values()
          .value();
      }

      $scope.deleteTemplate = function (index) {
        $scope.templates.splice(index, 1);
      };

      $scope.submitTemplateChange = function () {
        $scope.isLoading = true;
        var templates = $scope.templates.concat();
        var fontSize = $scope.mapOptions.labels.font;

       if (fontSize) {
           mapOptions.labels.font = fontSize;
           userService.savePreferences({
               mapOptions: mapOptions
           })['catch'](function() {
               // reload last preferences in DB
               mapOptions = $scope.user.preferences.mapOptions;
           });

           flightNgStore.flights.forEach(function(element) {
            element.olFeature.tooltip = undefined;
          });
       }
        //{name:'', tags:[]}
        templates.forEach(function(t){
          t.fields = _.map(t.fields, 'key');
        })
        return userNgStore.saveUserTemplates(templates).then(
          function (templates) {
            templates.forEach(function (t) {
              t.fields = _.map(t.fields, function (key) {
                var def = flightfieldDefinitions[key];
                return _.assign({ key: key }, def);
              });
            })
             $scope.templates = templates;
             $scope.isLoading = false;
             $scope.$applyAsync();
          },
          function (response) {
            $scope.isLoading = false;
            return response.json()
              .then(
              function (errors) {
                var error = errors.length && errors[0],
                  msg = (error && ErrorCode.getMessage(error.code, error.values)) || "";
                alertService.error('Modification failed', msg);
                reject(msg);
              },
              function () {
                alertService.error('Update failed', "Server Internal");
                reject("Unknow error");
              }
              );
          }
        );
      };
    }
  ]);
