/*jslint browser: true, plusplus:true, nomen:true */
/*global FastClick, $, _, angular, ol, moment, toastr, less */

(function (global) {
  'use strict';

  FastClick.attach(document.body);
  moment.tz.setDefault("Etc/UTC");
  moment.locale('en', {
    relativeTime : {
        future: "in %s",
        past:   "%s ago",
        s  : 'a few secs',
        ss : '%d sec',
        m:  "a min",
        mm: "%d mins",
        h:  "an h",
        hh: "%d h",
        d:  "a d",
        dd: "%d d",
        M:  "a mth",
        MM: "%d mths",
        y:  "a yr",
        yy: "%d yrs"
    }
});
  //
  // TOASTR
  toastr.options = {
    progressBar: true,
    // Also defined in DefinedAreaSteps.java in functional test project
    timeOut: '5000',
    closeButton: true,
    newestOnTop: false,
    closeHtml: '<button type="button" class="close" aria-label="Close"><span aria-hidden="true">&times;</span></button>'
  };

  var App = global.App = angular.module('SmartHub', [
    'ui.router',
    'ui.bootstrap',
    'ui-rangeSlider',
    'ui.select',
    'smart-table',
    'ngSanitize',
    'ngAnimate',
    'ngTouch',
    'checklist-model',
    'perfect_scrollbar',
    'angular-loading-bar',
    'colorpicker.module',
    'dndLists',
    'ngTagsInput',
    'moment-picker'
  ])
    .config(['momentPickerProvider', function (momentPickerProvider) {
      momentPickerProvider.options({
        minutesStep:1
      });
    }]);


  angular.extend(App, {
    ADMIN_ROLE: 'ADMIN',
    SUPER_ROLE: 'SUPER',
    REPORTER_ROLE: 'REPORTER',
    TEST_ROLE: 'TEST'
  });


  App
    .controller('AppCtrl', ['$rootScope', '$scope', '$state', 'customerNgStore', 'featureNgStore', 'mapPreferences', "seWeatherRefs", 'aircraftInfoNgStore', 'userService',
      function ($rootScope, $scope, $state, customerNgStore, featureNgStore, mapPreferences, seWeatherRefs, aircraftInfoNgStore, userService) {
        var customerColors, hasAircraftsHandler; // $rootScope.user = {};
        $rootScope.isLogged = function () {
          return $rootScope.user !== null;
        };
        $rootScope.isAdmin = function () {
          return $rootScope.hasRole(App.ADMIN_ROLE);
        };
        $rootScope.isReporter = function () {
          return $rootScope.hasRole(App.REPORTER_ROLE);
        };
        $rootScope.hasRole = function (role) {
          return $rootScope.user && $rootScope.user.roles && $rootScope.user.roles.indexOf(role) >= 0;
        };
        $rootScope.hasAnyRole = function (roles) {
          return $rootScope.user && _.intersection($rootScope.user.roles, roles).length > 0;
        };

        $rootScope.hasOption = function (option) {
          return customerNgStore.customer && customerNgStore.customer.options && customerNgStore.customer.options.indexOf(option) >= 0;
        };
        
        $rootScope.hasAnyWeatherPackage = function (options) {
        	for(var option = 0; option < options.length; option++) {
        		if (customerNgStore.customer && customerNgStore.customer.customerWeatherPackage && customerNgStore.customer.customerWeatherPackage.indexOf(options[option]) >= 0) {
                	return true;
                }
        	}
        };
        $rootScope.hasWeatherPackage = function (option) {
        	return customerNgStore.customer && customerNgStore.customer.customerWeatherPackage && customerNgStore.customer.customerWeatherPackage.indexOf(option) >= 0;
        };
        
        $rootScope.hasNotWeatherPackage = function (option) {
            return customerNgStore.customer && customerNgStore.customer.customerWeatherPackage && customerNgStore.customer.customerWeatherPackage.indexOf(option) < 0;
        };

        $rootScope.hasAnyOption = function (options) {
          return customerNgStore.customer && _.intersection(customerNgStore.customer.options, options).length > 0;
        };

        $rootScope.getAirspaceFeatures = function (option) {
          return featureNgStore.airspaceFeatures;
        };

        $rootScope.openWidget = function () {
          FreshworksWidget('open');
        };

        window.fwSettings = {
          'widget_id': 35000001681
        };

        !function () {
          if ("function" != typeof window.FreshworksWidget) {
            var n = function () { n.q.push(arguments) }; n.q = [], window.FreshworksWidget = n
          }
        }()

        $rootScope.App = App;
        $rootScope.gitinfo = global.GITINFO;

        $rootScope.browsermobile = window.browsermobile;


        function updateCustomStyle() {
          var customColors,
            defaultColors = mapPreferences.defaultColors,
            paramColors = defaultColors,
            customStyleTpl = document.querySelector('#customStyleTpl').textContent,
            customStyle = document.querySelector('#customStyle'),
            customer = customerNgStore.customer,
            parameters = customer && customer.parameters;
          if (angular.isDefined(parameters) && angular.isDefined(parameters.customColors)) {
            paramColors = parameters.customColors;
          }
          if (!_.isEqual(customerColors, paramColors)) {
            customerColors = paramColors;
            customColors = '@sh-flight-myfleet: ' + (customerColors.myfleet || defaultColors.myfleet) + ';';
            customColors += '@sh-flight-tracked: ' + (customerColors.tracked || defaultColors.tracked) + ';';
            customColors += '@sh-flight-caution: ' + (customerColors.caution || defaultColors.caution) + ';';
            customColors += '@sh-flight-warning: ' + (customerColors.warning || defaultColors.warning) + ';';
            customColors += '@sh-flight-other: ' + (customerColors.other || defaultColors.other) + ';';
            customColors += '@sh-flight-closed: ' + (customerColors.closed || defaultColors.closed) + ';';
            customColors += '@sh-flight-planned: ' + (customerColors.planned || defaultColors.planned) + ';';
            less.render(customColors + customStyleTpl, function (error, result) {
              customStyle.textContent = result.css;
            });
          }
        }
        updateCustomStyle();
        customerNgStore.on('update', updateCustomStyle, this);

        //
        // My Aircrafts list (visible all aircrafts and replay button)
        hasAircraftsHandler = function () {
          var aircrafts = aircraftInfoNgStore.aircrafts,
            displayedAirlines = userService.getUserPreference('displayedAirlines');
          $scope.hasAircrafts = displayedAirlines.length && _.some(aircrafts, function (aircraft) {
            return _.includes(displayedAirlines, aircraft.operatorIcao);
          });
          $scope.$applyAsync();
        };
        aircraftInfoNgStore.on('sync', hasAircraftsHandler);
        userService.on('update', hasAircraftsHandler);

      }
    ]);

  App
    .config(['$stateProvider', '$urlRouterProvider', '$uibTooltipProvider', function ($stateProvider, $urlRouterProvider,
      $uibTooltipProvider) {
      $uibTooltipProvider.options({
        placement: 'bottom',
        animation: false,
        popupDelay: 0,
        trigger: window.browsermobile ? 'none' : undefined
      });
      //
      // For any unmatched url, redirect to /login
      $urlRouterProvider.otherwise(function ($injector, $location) {
        var $state = $injector.get('$state');
        $state.go('login');
      });

      //
      // Now set up the states
      $stateProvider
        .state('login', {
          url: '',
          controller: 'LoginPageCtrl',
          templateUrl: 'views/login.html',
          params: {
            logout: null,
            returnTo: {
              value: null
            },
            returnToParams: {
              value: null
            }
          }
        })
        .state('login.resetpassword', {
          url: '/resetpassword',
          controller: 'formResetPassword',
          templateUrl: 'views/partials/reset-password.html'
        })
        .state('super', {
          url: '/super',
          // abstract: true,
          controller: 'SuperPageCtrl',
          templateUrl: 'views/super.html',
          data: {
            authenticationRequired: true,
            roles: [App.SUPER_ROLE]
          }
        })
        .state('super.customer', {
          url: '/customer',
          controller: 'CustomerListPageCtrl',
          templateUrl: 'views/partials/sysadmin/super-customer-list.html',
          data: {
            title: 'Customer Management',
            authenticationRequired: true,
            roles: [App.SUPER_ROLE]
          }
        })
        .state('super.customeredit', {
          url: '/customer/{customer}',
          templateUrl: 'views/partials/sysadmin/super-customer-edit.html',
          data: {
            title: 'Customer Management',
            authenticationRequired: true,
            roles: [App.SUPER_ROLE]
          }
        })
        .state('super.airport', {
          url: '/airport',
          controller: 'SuperAirportPageCtrl',
          templateUrl: 'views/partials/sysadmin/super-airport.html',
          data: {
            title: 'Customer Management',
            authenticationRequired: true,
            roles: [App.SUPER_ROLE]
          }
        })
        .state('super.support', {
          url: '/support',
          controller: 'SuperSupportPageCtrl',
          templateUrl: 'views/partials/sysadmin/super-support.html',
          data: {
            title: 'Support Management',
            authenticationRequired: true,
            roles: [App.SUPER_ROLE]
          }
        })
        .state('super.aircraftSummary', {
          url: '/aircraftsummary',
          controller: 'SuperAircraftSummaryCtrl as SASC',
          templateUrl: 'views/partials/sysadmin/super-aircraft-summary.html',
          data: {
            title: 'Aircraft Summary',
            authenticationRequired: true,
            roles: [App.SUPER_ROLE]
          }
        })
        .state('map', {
          url: '/map',
          controller: 'MapPageCtrl',
          templateUrl: 'views/map.html',
          data: {
            authenticationRequired: true
          }
        })
        .state('map.flight', {
          url: '/{flightId}',
          params: {
            tab: null,
            flightDbId: null
          },
          controller: 'FlightDetailCtrl',
          templateUrl: 'views/partials/flight-detail/flight-detail-pane.html',
          data: {
            title: '{flightId}',
            authenticationRequired: true
          }
        })
        .state('admin', {
          url: "/admin",
          controller: 'AdminPageCtrl',
          templateUrl: "views/admin.html",
          data: {
            authenticationRequired: true,
            roles: [App.ADMIN_ROLE]
          }
        })
        .state('admin.user', {
          url: '/user',
          controller: 'AdminUserListCtrl',
          templateUrl: 'views/partials/admin/admin-user-list.html',
          data: {
            authenticationRequired: true,
            roles: [App.ADMIN_ROLE]
          }
        })
        .state('admin.aircraft', {
          url: '/aircraft',
          controller: 'AdminAircraftCtrl',
          templateUrl: 'views/partials/admin/admin-aircraft-list.html',
          data: {
            authenticationRequired: true,
            roles: [App.ADMIN_ROLE]
          }
        })
        .state('admin.alert', {
          url: '/alert',
          controller: 'AdminAlertCtrl',
          templateUrl: 'views/partials/admin/admin-alert-list.html',
          data: {
            authenticationRequired: true,
            roles: [App.ADMIN_ROLE]
          }
        })
        .state('admin.template', {
          url: '/template',
          controller: 'AdminTemplateListCtrl',
          templateUrl: 'views/partials/admin/admin-template-list.html',
          data: {
            authenticationRequired: true,
            roles: [App.ADMIN_ROLE]
          }
        })
        .state('admin.templatesAllocation', {
          url: "/labels-allocation",
          templateUrl: "views/partials/admin/admin-template-allocation.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('admin.color', {
          url: '/color',
          controller: 'AdminColorCtrl',
          templateUrl: 'views/partials/admin/admin-color-form.html',
          data: {
            authenticationRequired: true,
            roles: [App.ADMIN_ROLE]
          }
        })
        .state('admin.acars', {
          url: '/acars',
          controller: 'AdminAcarsCtrl',
          templateUrl: 'views/partials/admin/admin-acars-form.html',
          data: {
            authenticationRequired: true,
            roles: [App.ADMIN_ROLE]
          }
        })
        .state('admin.flightPlan', {
          url: '/flightplan',
          controller: 'AdminFlightPlanCtrl',
          templateUrl: 'views/partials/admin/admin-flightplan-form.html',
          data: {
            authenticationRequired: true,
            roles: [App.ADMIN_ROLE]
          }
        })        
        .state('admin.map', {
          url: '/map',
          controller: 'AdminMapCtrl',
          templateUrl: 'views/partials/admin/admin-map-form.html',
          data: {
            authenticationRequired: true,
            roles: [App.ADMIN_ROLE]
          }
        })
        .state('deskAssignment', {
          url: "/deskassignment",
          controller: 'DeskAssignmentPageCtrl',
          templateUrl: "views/desk-assignment.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('settings', {
          url: "/settings",
          controller: 'SettingsPageCtrl',
          templateUrl: "views/settings.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('settings.map', {
          url: "/map",
          templateUrl: "views/partials/settings/settings-map.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('settings.airlines', {
          url: "/airlines",
          templateUrl: "views/partials/settings/settings-airlines.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('settings.airports', {
          url: "/airports",
          templateUrl: "views/partials/settings/settings-airports.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('settings.aircrafts', {
          url: "/aircrafts",
          templateUrl: "views/partials/settings/settings-aircrafts.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('settings.flightdetails', {
          url: "/flightdetails",
          templateUrl: "views/partials/settings/settings-flight-details.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('settings.aircraftsTags', {
          url: "/tags",
          templateUrl: "views/partials/settings/settings-tags.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('settings.templatesAllocation', {
          url: "/labels-allocation",
          templateUrl: "views/partials/settings/settings-template-allocation.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('settings.labelsTemplates', {
          url: "/labels-templates",
          templateUrl: "views/partials/settings/settings-label-template-list.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('settings.zoomLevels', {
            url: "/zoom-levels",
            templateUrl: "views/partials/settings/settings-zoom-levels.html",
            data: {
              authenticationRequired: true
            }
          })       
        .state('settings.password', {
          url: "/password",
          templateUrl: "views/partials/settings/settings-password.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('settings.airspace', {
          url: '/airspace',
          templateUrl: 'views/partials/settings/settings-airspace.html',
          data: {
            authenticationRequired: true
          }
        })
        .state('stats', {
          url: "/stats",
          controller: 'StatisticsPageCtrl',
          templateUrl: "views/statistics.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('stats.taxitime', {
          url: "/taxitime",
          controller: 'StatisticsTaxitimeCtrl',
          templateUrl: "views/partials/statistics/statistics-taxitime.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('stats.alerts', {
          url: "/alerts",
          controller: 'StatisticsAlertsCtrl',
          templateUrl: "views/partials/statistics/statistics-alerts.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('stats.alerts.aircraft', {
          url: "/aircraft",
          controller: 'SelectAircraftFormCtrl',
          templateUrl: "views/partials/statistics/select-aircraft-form.html"
        })
        .state('stats.holding', {
          url: "/holding",
          controller: 'StatisticsHoldingCtrl',
          templateUrl: "views/partials/statistics/statistics-holding.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('stats.airportdelays', {
          url: "/airportdelays",
          controller: 'StatisticsAirportDelaysCtrl',
          templateUrl: "views/partials/statistics/statistics-airportdelays.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('airport', {
          url: "/airport",
          abstract: true,
          controller: 'AirportPageCtrl',
          templateUrl: "views/airport.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('airport.search', {
          url: "",
          templateUrl: "views/partials/airport/airport-search.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('airport.details', {
          url: "/{iataOrIcao}",
          controller: 'AirportDetailsCtrl',
          templateUrl: "views/partials/airport/airport-details.html",
          data: {
            title: '{iataOrIcao}',
            authenticationRequired: true
          }
        })
        .state('airport.details.notam', {
          url: "/notam",
          templateUrl: "views/partials/airport/airport-notam-tab.html",
          data: {
            title: '{iataOrIcao} - NOTAM',
            authenticationRequired: true
          }
        })
        .state('airport.details.weather', {
          url: "/weather",
          templateUrl: "views/partials/airport/airport-weather-tab.html",
          data: {
            title: '{iataOrIcao} - WEATHER',
            authenticationRequired: true
          }
        })
        .state('airport.details.runways', {
          url: "/runways",
          templateUrl: "views/partials/airport/airport-runways-tab.html",
          data: {
            title: '{iataOrIcao} - RUNWAYS',
            authenticationRequired: true
          }
        })
        .state('airport.details.taxi', {
          url: "/taxi",
          templateUrl: "views/partials/airport/airport-taxi-time-tab.html",
          data: {
            title: '{iataOrIcao} - TAXI TIME',
            authenticationRequired: true
          }
        })
        .state('airport.details.capacity', {
          url: "/capacity",
          templateUrl: "views/partials/airport/airport-capacity-tab.html",
          data: {
            title: '{iataOrIcao} - CAPACITY',
            authenticationRequired: true
          }
        })
        .state('airport.details.surface-tracking', {
            url: "/surfacetracking",
            templateUrl: "views/partials/airport/airport-surface-tracking-tab.html",
            data: {
              title: '{iataOrIcao} - MAP',
              authenticationRequired: true
            }
          })
        .state('replay', {
          url: "/replay",
          controller: 'ReplayPageCtrl',
          templateUrl: "views/replay.html",
          data: {
            title: 'Flight replay page',
            authenticationRequired: true
          }
        }).state('replay.search', {
          url: "/search",
          controller: 'ReplaySearchCtrl as ctrl',
          templateUrl: "views/partials/replay/replay-search.html",
          data: {
            title: 'Flight replay search',
            authenticationRequired: true
          }
        }).state('replay.map', {
          url: '/map/{id}',
          //controller: 'ReplayMapCtrl',
          templateUrl: "views/partials/replay/replay-map.html",
          data: {
            title: 'Flight replay map',
            authenticationRequired: true
          }
        })
        .state('airspace', {
          url: "/airspace",
          abstract: true,
          controller: 'AirspacePageCtrl',
          templateUrl: "views/airspace.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('airspace.search', {
          url: "",
          templateUrl: "views/partials/airspace/airspace-search.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('airspace.capacity', {
          url: "/{id}",
          controller: 'AirspaceCapacityCtrl',
          templateUrl: "views/partials/airspace/airspace-capacity.html",
          data: {
            authenticationRequired: true
          }
        })
        .state('settings.timezone', {
        url: "/timezones",
        templateUrl: "views/partials/settings/settings-timezone.html",
        data: {
          authenticationRequired: true
        }
      });
    }]);

  App.run(['$rootScope', '$state', '$stateParams', '$transitions', 'userService', function ($rootScope, $state,
    $stateParams, $transitions, userService) {

    // It's very handy to add references to $state and $stateParams to the $rootScope
    // so that you can access them from any scope within your applications.For example,
    // <li ng-class="{ active: $state.includes('contacts.list') }"> will set the <li>
    // to active whenever 'contacts.list' or one of its decendents is active.
    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;

    var appTitle = document.title;
    userService.updateUserProfile(); //read localstorage previous profile

    $transitions.onStart({
      to: function (state) {
        return state.data !== undefined;
      }
    }, function (transition) {
      var to = transition.to(),
        from = transition.from(),
        fromParam = transition.params('from'),
        toP = transition.params('to'),
        authRequired = to.data.authenticationRequired,
        roles = to.data.roles || [],
        user = $rootScope.user,
        authorized = !authRequired,
        title;
      if (roles && !angular.isArray(roles)) {
        roles = [roles];
      }
      if (roles.length > 0) {
        if (user) {
          authorized = roles.reduce(function (hasrole, role, index, array) {
            return hasrole || user.roles.indexOf(role) >= 0;
          }, authorized);
        } else {
          authorized = false;
        }
      } else {
        authorized = !!user; //check only if user in logged in
      }
      if (user && authorized) {
        if (to.data.title) {
          title = to.data.title.replace(/\{(\w*)\}/g, function (m, p) {
            return _.get(toP, p);
          });
          document.title = title + ' | ' + appTitle;
        } else {
          document.title = appTitle;
        }
        return;
      } else {


        //redirect user to its previous page !
        if (from && from.name.length > 0) {
          //console.log(from.name);
          return transition.router.stateService.target(from.name, fromParam);
          //$state.go(from.name, fromParam);
        } else {
          window.console.warn('Unauthorized page access. Return to login page', to.name, toP);
          //else bring the user back to login
          return transition.router.stateService.target("login", {
            returnTo: to.name,
            returnToParams: toP
          });
        }
      }
    });

  }]);


}(window));
