/*jslint browser: true, plusplus:true*/
/*global angular, ol, App */

(function () {
  'use strict';

  function StatisticsPageCtrl($rootScope, $scope, $state) {
    if (!$rootScope.isLogged()) {
      $state.go('login');
      return;
    }
  }

  App
    .controller('StatisticsPageCtrl', ['$rootScope', '$scope', '$state', StatisticsPageCtrl]);
}());
