/*jslint browser: true, plusplus:true, nomen: true */
/*global angular, ol, App, map, toastr, $, moment, MimeTypeHelper, _, less, config, ServerTime */

(function () {
  'use strict';

  function MapPageCtrl($rootScope, $scope, $parse, $filter, $state, $uibModal, $transitions, flightNgStore, customerNgStore, userService, flightfieldDefinitions, mapPreferences, flightSearchService,
    $timeout) {
    var refreshTimer,
      currentState = $state.current.name,
      currentParam = $state.params,
      self = this,
      unregisterHandleRouteStateChange;

    self.$onInit =  function() {
        let customer = customerNgStore.customer;
        let parameters = customer.parameters;

      if(parameters.routeAnalysis) {
        $scope.routeAnalysisEnabled = parameters.routeAnalysis;
      } else {
        $scope.routeAnalysisEnabled = false;
      }
      $scope.restoreCheckBoxState();
      $scope.restoreFlightPlanToggleState();
    };

    if (!$rootScope.isLogged()) {
      $state.go('login', {
        returnTo: currentState,
        returnToParams: currentParam
      });
      return;
    }
    $scope.flights = flightNgStore.flights;
    $scope.allFlights = flightNgStore.allFlights;
    $scope.selectedFlight = null;
    $scope.tableExpanded = false;
    $scope.tableMinimised = false;
    $scope.fromClickOnMap = false;
    //Define the list of displayed sections
    $scope.shownSections = {
      warning: true,
      caution: true,
      myfleet: true,
      tracked: true,
      other: false,
      closed: true,
      planned: true
    };

    $scope.disabledSections = {
      warning: false,
      caution: false,
      myfleet: false,
      tracked: false,
      other: false,
      closed: false,
      planned: false
    };

    $scope.disableFlightPlanToggle = {
      warning: !$scope.shownSections.warning,
      caution: !$scope.shownSections.caution,
      myfleet: !$scope.shownSections.myfleet,
      tracked: !$scope.shownSections.tracked,
      other: !$scope.shownSections.other,
      closed: !$scope.shownSections.closed
    };

    $scope.flightPlanToggleState = {
      warning: false,
      caution: false,
      myfleet: false,
      tracked: false,
      other: false,
      closed: false
    };

    $scope.ToggleRouteListWithValues = function(ori, dst, registration) {
      $rootScope.$emit("ToggleRouteListWithValues", ori, dst, registration);
    };

    $scope.restoreCheckBoxState = function() {
        if(sessionStorage.getItem("shownSectionsValues")) {
            $scope.shownSections = angular.fromJson(sessionStorage.getItem("shownSectionsValues"));
        }
    };

    $scope.restoreFlightPlanToggleState = function() {
      if(sessionStorage.getItem("flightPlanToggleStateValue")) {
        $scope.flightPlanToggleState = angular.fromJson(sessionStorage.getItem("flightPlanToggleStateValue"));
      }
    };

    $scope.toggleFlightPlanSelection = function(flightId) {
      $rootScope.$emit("updateFlightPlanToggledList", flightId);
    };

    $scope.toggleSectionFlightPlanSelection = function(flights, sectionName) {
      setTimeout(function() {
        var json = angular.toJson($scope.flightPlanToggleState);
        sessionStorage.setItem('flightPlanToggleStateValues', json);
        $rootScope.$emit("updateFlightPlanToggledListBySection", flights, sectionName);
      }, 0);
    };

    $scope.toggleFlightsAndFlightPlans = function(sectionName) {
      setTimeout(function() {
        //toggle flights and disable|enable the flight plan toggle
        $scope.saveCheckboxState();

        //when toggling flights off remove flight plans as well
        if (!$scope.shownSections[sectionName]) {
          $scope.flightPlanToggleState[sectionName] = false;
          $scope.disableFlightPlanToggle[sectionName] = true;
          $rootScope.$emit("removeFlightPlansBySection", sectionName);
        }
        else {
          $scope.disableFlightPlanToggle[sectionName] = false;
        }
      }, 0);
    };

    $scope.saveCheckboxState = function() {
        var json = angular.toJson($scope.shownSections);
        sessionStorage.setItem('shownSectionsValues', json);
     };
    $scope.expandTable = function (expanded) {
      $scope.tableExpanded = expanded;
      $scope.tableMinimised = !expanded && $(window).width() < 810;
    };
    $scope.minimiseTable = function (minimised) {
      $scope.tableMinimised = minimised;
      $scope.tableExpanded = false;
    };
    $scope.activeFilters = [];
    $scope.colDefs = flightfieldDefinitions;

    $scope.mapLocked = {
      state: false
    };

    $scope.mapLocked.toggle = function () {
      $scope.mapLocked.state = !$scope.mapLocked.state;
    };

    $scope.search = {
      open: function () {
        var availableFilters = _.clone(flightSearchService.getAvailableFilters()),
          activeFilters = _.map(flightSearchService.getActiveFilters(), 'key'),
          possibleFilters;

        possibleFilters = _(availableFilters).map(function (value, key) {
          value.key = key;
          return value;
        }).reject(function (item) {
          //Remove the ones already active
          return _.includes(activeFilters, item.key);
        }).value();

        $uibModal.open({
          size: 'sm', //small size modal
          templateUrl: 'views/partials/map-search.html',
          controller: 'MapSearchCtrl',
          resolve: {
            possibleFilters: _.constant(possibleFilters)
          }
        }).result.then(function (newFilter) {
          flightSearchService.addSearchFilter(newFilter.property, newFilter.value, newFilter.displayed);
        });
      },
      remove: function (filterName) {
        flightSearchService.removeSearchFilter(filterName);
      }
    };
    $scope.refreshFilters = function () {
      var definitions = flightSearchService.getAvailableFilters();
      $scope.activeFilters = _.map(flightSearchService.getActiveFilters(), function (v, k) {
        if (_.isObject(v.displayed)) {
          v.values = _.values(v.displayed);
        } else if (_.isObject(v.wanted)) {
          v.values = _.values(v.wanted);
        } else if (!_.isArray(v.wanted)) {
          //convert single value to array
          v.values = [v.wanted];
        } else {
          v.values = v.wanted;
        }
        if (definitions[v.key]) {
          if (_.isString(definitions[v.key].formatFilter)) {
            //format values that need to be formatted.
            v.values = _.map(v.values, $filter(definitions[v.key].formatFilter));
          } else if (_.isFunction(definitions[v.key].formatFn)) {
            v.values = _.map(v.values, definitions[v.key].formatFn, v);
          }
        }
        return v;
      });
    };

    flightSearchService.on('filter:add filter:remove', function () {
      $scope.refreshFilters();
    }, self);

    $scope.refreshFilters();

    function setSelectedPlane(flightDbId) {
      var selectedFlight = null,
        i;
      for (i = 0; i < $scope.flights.length; i++) {
        if ($scope.flights[i].id === flightDbId) {
          selectedFlight = $scope.flights[i];
          break;
        }
      }
      $scope.selectedFlight = selectedFlight;
      $scope.$applyAsync();
      $scope.mapLocked.state = false;
      if (!selectedFlight) {
        //if we could not find it, return to map.
        $state.go('map');
      }
      $timeout(function () {
        $rootScope.$broadcast('scrollToFlightinTable');
      }, 400);
    }

    function handleRouteStateChange(transition) {
      var flightDbId;
      if (transition.to().name === 'map.flight') {
        flightDbId = transition.params('to') && transition.params('to').flightDbId;
        if (flightDbId && flightDbId.length) {
          if (flightNgStore.flights && flightNgStore.flights.length > 0) {
            setSelectedPlane(flightDbId);
          } else {
            //if flights were not loaded yet, wait for them and retry
            flightNgStore.once('sync:filtered', function () {
              handleRouteStateChange(transition);
            });
          }
        }
      } else {
        $scope.selectedFlight = null;
      }
    }

    //Listen for URL changes for plane selection
    unregisterHandleRouteStateChange = $transitions.onSuccess({}, handleRouteStateChange);

    handleRouteStateChange($state.transition);
    $scope.selectPlane = function (flightId, flightDbId, clickOnMap) {
      if (flightId && flightId.length) {
        if (clickOnMap) {
          $scope.fromClickOnMap = true;
        } else {
          $scope.fromClickOnMap = false;
        }
        $state.go('map.flight', {
          flightId: flightId,
          tab: 'altitude',
          flightDbId: flightDbId
        });
      } else {
        $scope.fromClickOnMap = false;
        $state.go('map');
      }
    };

    function updateTime() {
      var timeZone = userService.getUserPreference('timeZone');
      $('.clock .timezone').html(moment(ServerTime.now()).tz(timeZone).format('HH:mm z'));
      $timeout.cancel(refreshTimer);
      refreshTimer = $timeout(updateTime, 2000);
    }

    flightNgStore.on('remove', function (e) {
      if ($scope.selectedFlight === e) {
        $scope.selectedFlight = null;
      }
    }, this);

    flightNgStore.on('error', function (response) {
      if (response.status < 500) {
        // [400-499] like
        var mimeType = MimeTypeHelper.parse(response.headers.get('Content-Type'));
        if (mimeType.baseType === 'application/json') {
          return response.json().then(function (error) {
            $scope.error = error;
            window.console.error(error);
          });
        } else {
          // JSON was expected
          window.console.error('Wrong Content-Type for error');
        }
      } else {
        // Unexpected error
        window.console.error('Unexpected error', response);
      }
      // stop propagation, to avoid multiple error messgae.
      return false;
    }, this);

    refreshTimer = $timeout(updateTime, 200);
    $scope.$on('$destroy', function () {
      $timeout.cancel(refreshTimer);
      flightNgStore.off(null, null, self);
      customerNgStore.off(null, null, self);
      flightSearchService.off(null, null, self);
      unregisterHandleRouteStateChange();
    });

    function playNotificationSound() {
      var audioNotification = document.getElementById("audio-notification");
      audioNotification.play();
    };

    var playNotificationSoundThrolled = _.throttle(playNotificationSound, 1500);

    //
    // ALERT CHECKING
    flightNgStore.on('alert:filtered', function (alert, entity) {
      var onclick = function () {
        $scope.selectPlane(entity.flightId, false);
      };
      if (!alert.ackBy && !alert.endDate && ((ServerTime.now().getTime() - alert.startDate) % 1800000 <= 5000)) {
        if (alert.lvl === 2) {
          toastr.error(alert.msg, entity.flightId, {
            onclick: onclick
          });
          playNotificationSoundThrolled();
        } else if (alert.lvl === 1) {
          toastr.warning(alert.msg, entity.flightId, {
            onclick: onclick
          });
        } else if (alert.lvl === 0 && ((ServerTime.now().getTime() - alert.startDate) <= 5000)) {
          toastr.success(alert.msg, entity.flightId, {
            onclick: onclick
          });
          playNotificationSoundThrolled();
        } else if (alert.lvl === 0 && ((ServerTime.now().getTime() - alert.startDate) <= 5000)) {
          toastr.success(alert.msg, entity.flightId, {
            onclick: onclick
          });
          playNotificationSoundThrolled();
        }
      }
    }, this);

    $rootScope.$on("UpdateFlightToggles", function(event, updateToggles) {
        $scope.shownSections = (!_.isEmpty(updateToggles)) ? updateToggles : $scope.shownSections;
        $scope.saveCheckboxState();
    });

    $scope.filterPlannedFlightDistance = function(flightDetails) {
        if((flightDetails == 'remainingDistanceKm' || flightDetails == 'remainingDistanceNm') && $scope.selectedFlight.isPlanned){
            return false;
        }
        return true;
    };

  }
  App
    .controller('MapPageCtrl', ['$rootScope', '$scope', '$parse', '$filter', '$state', '$uibModal', '$transitions', 'flightNgStore', 'customerNgStore', 'userService', 'flightfieldDefinitions',
      'mapPreferences',
      'flightSearchService', '$timeout', MapPageCtrl
    ]);

}());
